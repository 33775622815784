import { ValueLabelObj } from '@/shared/types/commonTypes';
import { getContactName } from '@/shared/utils/getContactName';
import { GetHeadOfHouseHoldOptions } from '../../types';

import {
  HEAD_OF_HOUSEHOLD_PLACEHOLDER,
  NEW_CONTACT_HEAD_OF_HOUSEHOLD_VALUE
} from '../../data';

export const getHeadOfHouseHoldOptions = ({
  currentContact,
  existedContact,
  firstNameValue,
  lastNameValue,
}: GetHeadOfHouseHoldOptions): Array<ValueLabelObj>=>  {

  const existedContactFullName =  existedContact
    ? `${existedContact.firstName} ${existedContact.lastName}`
    : null;

  const newContactFullName = firstNameValue && lastNameValue
    ? `${firstNameValue} ${lastNameValue}`
    : null;

  return [
    {
      value: currentContact.id,
      label: getContactName(currentContact)
    },
    {
      value: existedContact?.id || NEW_CONTACT_HEAD_OF_HOUSEHOLD_VALUE,
      label: newContactFullName || existedContactFullName || HEAD_OF_HOUSEHOLD_PLACEHOLDER
    },
  ];
};

import React from 'react';

import CoreClassificationIcon from '@components/CoreClassificationIcon';

import { CoreClassificationSelectOptionProps } from './types';

import { IconWrapper, Wrapper } from './styles';

export const CoreClassificationSelectOption = ({
  value,
  label
}: CoreClassificationSelectOptionProps) => (
  <Wrapper>
    <IconWrapper>
      <CoreClassificationIcon classificationType={ value as string }/>
    </IconWrapper>
    { label }
  </Wrapper>
);

import React from 'react';
import { observer } from 'mobx-react';
// @ts-ignore
import { v4 } from 'uuid';

import { ACCOUNT_AND_PLANS_TYPE_SCHEME } from './data';
import { numberStringToLocaleString } from '@/shared/utils/numberStringToLocaleString';

import { ComponentHeader } from '@modules/ContactComponents';
import TextWithTooltip from '@components/TextWithTooltip';

import { AccountAndPlansWrapper, Content, Count, ContentWrapper, Type, Divider } from './styles';

import { AccountAndPlansProps, BalanceData } from './types';


export const AccountAndPlans = observer(({
  data,
  headerTitle,
  onViewAllClick,
}: AccountAndPlansProps) => {

  const formatNumberToAmount = (value: string) => {
    const withDecimal = Number(value).toFixed();
    return `$${numberStringToLocaleString({ value: withDecimal.toString(), separator: ',' })}`;
  };

  return(
    <AccountAndPlansWrapper>
      <ComponentHeader
        onViewAllClick={ onViewAllClick }
        title={ headerTitle }
      />
      <ContentWrapper>
        { data.map((balanceData: BalanceData, index: number) => {
          return (
            <Content key={ v4() }>
              <Count textColor={ ACCOUNT_AND_PLANS_TYPE_SCHEME[index].colorText }>
                { balanceData.count }
              </Count>
              <Type textColor={ ACCOUNT_AND_PLANS_TYPE_SCHEME[index].colorText }>
                { ACCOUNT_AND_PLANS_TYPE_SCHEME[index].type }
              </Type>
              <Divider textColor={ ACCOUNT_AND_PLANS_TYPE_SCHEME[index].colorText }/>
              <Count>
                <TextWithTooltip text={ formatNumberToAmount(balanceData.value) }/>
              </Count>
              <Type>
                { ACCOUNT_AND_PLANS_TYPE_SCHEME[index].totalAmountText }
              </Type>
            </Content>
          );
        })
        }
      </ContentWrapper>
    </AccountAndPlansWrapper>
  );
});

import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import SideFilters from '@modules/SideFilters';
import SearchField from '@components/SearchField';
import CheckboxDropDown from '@components/CheckboxDropDown';

import { DropDownFilterDataType, ValueOf } from '@/shared/types/commonTypes';
import { addFilterOptions } from '@/shared/utils/filterUtils';
import {
  CLASSIFICATION,
  TYPE,
  STATUS,
  USERS
} from './data';
import { CREATED_BY_CHECKBOX_FILTER_MAX_HEIGHT, LIST_CONTACTS_TABLE_FILTER_NAMES } from '@constants/lists';

export const ListContactsSideFilters = observer(() => {

  const listContactsStore = useStore().ListsStore.ListContactsStore;
  const {
    filters,
    filtersCount,
    filtersData,
    isLoad,
    resetFilters,
  } = listContactsStore;

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      USERS,
      filtersData.officePrimaryAdvisor
    )
  ), [filtersData]);

  const classificationDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      CLASSIFICATION,
      filtersData.classification
    )
  ), [filtersData]);

  const typeDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      TYPE,
      filtersData.type
    )
  ), [filtersData]);

  const statusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      STATUS,
      filtersData.status
    )
  ), [filtersData]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    listContactsStore.setFilterState(
      fieldName as ValueOf<typeof LIST_CONTACTS_TABLE_FILTER_NAMES>,
      newValue
    );
  }, [listContactsStore]);

  const onNameChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    listContactsStore.setFilterState(
      LIST_CONTACTS_TABLE_FILTER_NAMES.name,
      value
    );
  }, [listContactsStore]);

  return (
    <SideFilters
      count={ filtersCount }
      isNonePointerEvents={ isLoad }
      onClearClick={ resetFilters }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onNameChange }
          placeholder={ 'Search Contacts' }
          value={ filters?.name || '' }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.officePrimaryAdvisor || [] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ classificationDropDownData.label }
          name={ classificationDropDownData.name }
          setState={ onChangeDrop }
          state={ filters.classification || [] }
          values={ classificationDropDownData.values }
        />
        <CheckboxDropDown
          label={ typeDropDownData.label }
          name={ typeDropDownData.name }
          setState={ onChangeDrop }
          state={ filters.type || [] }
          values={ typeDropDownData.values }
        />
        <CheckboxDropDown
          label={ statusDropDownData.label }
          name={ statusDropDownData.name }
          setState={ onChangeDrop }
          state={ filters.status|| [] }
          values={ statusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { useSnackbar } from 'notistack';

export const NotificationRender = observer(() => {
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();
  const { isTriggerCloseAll,notifications,closeAll, setNotiStackCloseFunc } = useStore().NotificationsStore;


  useEffect(() => {
    setNotiStackCloseFunc(closeSnackbar);
  }, [closeSnackbar, setNotiStackCloseFunc]);


  useEffect(() => {
    if(isTriggerCloseAll){
      closeAll();
    }
  },[closeAll, closeSnackbar, isTriggerCloseAll, notifications]);

  // useEffect(() => {
  //   window.addEventListener('click', closeAll);
  //
  //   return () => {
  //     window.removeEventListener('click', closeAll);
  //   };
  // }, [closeAll, closeSnackbar]);

  useEffect(() => {
    notifications.forEach((notification, index) => {
      enqueueSnackbar(notification?.message, {
        variant: notification.variant,
        key: notification.key,
        autoHideDuration: 'autoHideDuration' in notification &&
        (
          notification.autoHideDuration === null ||
          typeof notification.autoHideDuration === 'number'
        )
          ? notification.autoHideDuration
          : 2000,
      });
    });
  }, [notifications, enqueueSnackbar, closeSnackbar]);

  return null;
});

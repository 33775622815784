import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { Link, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import { CONTACT_TYPES } from '@constants/common';
import { ContactItem } from '@/shared/types/contact';

import CustomTabs from '@components/CustomTabs';
import Surface from '@components/BasicLayoutComponents/Surface';
import {
  FormChangeWatcher
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { BusinessContentTabs, HouseholdContentTabs, IndividualContentTabs } from './subtabs';

import { ContactDetailsProfileParamsType, ContactDetailsProfileProps } from './types';

import { CONTACT_DETAILS_PROFILE_TABS } from './data/data';

import { validationSchema } from './validationScheme';

export const ContactDetailsProfile = observer(({
  contactType,
  contactId
}: ContactDetailsProfileProps) => {
  const contactDetailsProfileStore = useStore().ContactDetailsProfileStore;
  const { params: { subTab } } = useRouteMatch<ContactDetailsProfileParamsType>();

  const methods: UseFormReturn<ContactItem> = useForm<ContactItem>({
    resolver: yupResolver(validationSchema),
  });

  const tabs = useMemo(() => CONTACT_DETAILS_PROFILE_TABS[contactType],[contactType]);

  useEffect(() => {
    return () => { contactDetailsProfileStore.resetState(); };
    // eslint-disable-next-line
  },[]);

  return (
    <Surface>
      <Surface.Content $flexDirection={ 'column' }>
        <CustomTabs
          $hideIndicator={ true }
          $overFlow={ 'unset' }
          value={ subTab }
        >
          {
            tabs.map((item, index) => (
              <CustomTabs.RealWorldTab
                $isFirst={ index === 0 }
                $maxZIndex={ tabs.length }
                $zIndex={ tabs.length - index }
                component={ Link }
                key={ item.value }
                label={ item.label }
                replace={ true }
                to={ item.getLink(contactId) }
                value={ item.value }
              />
            ))
          }
        </CustomTabs>
        <FormProvider { ...methods }>
          <FormChangeWatcher />
          {
            contactType === CONTACT_TYPES.BUSINESS &&
            <BusinessContentTabs
              contactId={ contactId }
              subTab={ subTab }
            />
          }
          {
            contactType === CONTACT_TYPES.HOUSEHOLD &&
            <HouseholdContentTabs
              contactId={ contactId }
              subTab={ subTab }
            />
          }
          {
            contactType === CONTACT_TYPES.INDIVIDUAL &&
            <IndividualContentTabs
              contactId={ contactId }
              subTab={ subTab }
            />
          }
        </FormProvider>
      </Surface.Content>
    </Surface>
  );
});

import React from 'react';

import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Container,
  Group,
  Wrapper
} from './styles';

const CANCEL_BUTTON_STYLES = {
  marginRight: '20px'
};

const NAVIGATION_BUTTONS_STYLES = {
  minWidth: 'auto',
  padding: 0,
  marginRight: '30px'
};

function ModalFooter({
  onAttentionCancelClick,
  cancelHandler,
  currentStep,
  saveHandler,
  stepChangeHandler,
  isAttentionMode,
  isButtonsDisabled,
  onAttentionSaveClick
}) {
  const backChangeHandler = () => {
    if (currentStep > 1) {
      stepChangeHandler(currentStep - 1);
    }
  };

  const nextChangeHandler = () => {
    if (currentStep < 4) {
      stepChangeHandler(currentStep + 1);
    }
  };

  const onCancelClickHandler = isAttentionMode ? onAttentionCancelClick : cancelHandler;
  const saveLabel = isAttentionMode ? 'Okay' : 'Save';
  const onSaveClickHandler = isAttentionMode ? onAttentionSaveClick : saveHandler;

  return (
    <Container>
      {
        isAttentionMode ?
          <Wrapper>If you switch to another type, all the published information will be lost. Continue?</Wrapper>
          :
          <Group>
            <BlockButton
              $hoverBgColor={ 'transparent' }
              color='primary'
              disabled={ currentStep === 1 || isButtonsDisabled }
              onClick={ backChangeHandler }
              startIcon={ <ChevronLeftIcon /> }
              style={ NAVIGATION_BUTTONS_STYLES }
            >
              Back
            </BlockButton>
            <BlockButton
              $hoverBgColor={ 'transparent' }
              disabled={ currentStep === 4 || isButtonsDisabled }
              endIcon={ <ChevronRightIcon /> }
              onClick={ nextChangeHandler }
              style={ NAVIGATION_BUTTONS_STYLES }
            >
              Next
            </BlockButton>
          </Group>
      }
      <Group>
        <BlockButton
          color='primary'
          colorSchema={ COLOR_SCHEME_TYPES.outlined }
          disabled={ isButtonsDisabled }
          onClick={ onCancelClickHandler }
          style={ CANCEL_BUTTON_STYLES }
          variant='outlined'
        >
          Cancel
        </BlockButton>
        <BlockButton
          color='primary'
          colorSchema={ COLOR_SCHEME_TYPES.contained }
          disabled={ isButtonsDisabled }
          onClick={ onSaveClickHandler }
          variant='contained'
        >
          {saveLabel}
        </BlockButton>
      </Group>
    </Container>
  );
}

export default ModalFooter;

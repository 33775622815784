import { CONTACT_TYPES } from '@constants/common';

export const getContactName = (contact: any, type?: CONTACT_TYPES) => {
  const contactType = type ?? contact?.type;
  
  if(contactType === CONTACT_TYPES.HOUSEHOLD) {
    const householdData = contact.householderContacts;
    const firstPersonFullName = `${householdData?.[0]?.firstName} ${householdData?.[0]?.lastName}`;
    const secondPersonFullName = `${householdData?.[1]?.firstName} ${householdData?.[1]?.lastName}`;
    return `${firstPersonFullName} & ${secondPersonFullName}`;
  }

  if(contactType === CONTACT_TYPES.BUSINESS){
    return contact?.occupationCompany;
  }

  if(contactType === CONTACT_TYPES.INDIVIDUAL) {
    return  `${contact?.firstName} ${contact?.lastName}`;
  }

  return '';
};

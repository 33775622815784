import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { RELATION_TYPES } from '@constants/common';

import { ValueLabelObj } from '@/shared/types/commonTypes';

export const RELATION_OPTIONS: Array<ValueLabelObj> = optionsFromValue(Object.values(RELATION_TYPES));

export const RELATION_OPTIONS_WITHOUT_SPOUSE: Array<ValueLabelObj> = optionsFromValue(
  Object.values(RELATION_TYPES).filter(item => item !== RELATION_TYPES.SPOUSE)
);

export const RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE: Array<ValueLabelObj> = optionsFromValue(
  Object.values(RELATION_TYPES).filter(item => item !== RELATION_TYPES.SPOUSE && item !== RELATION_TYPES.EX_SPOUSE)
);

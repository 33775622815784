import React from 'react';

import { LabelWrapper } from './styles';

import { LabelProps } from './types';

export const Label = ({
  StyleProps = {},
  $bgColor,
  $color,
  children,
}: LabelProps) => {
  return (
    <LabelWrapper
      $bgColor={ $bgColor }
      $color={ $color }
      style={ StyleProps }
    >
      { children }
    </LabelWrapper>
  );
};

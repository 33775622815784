import * as yup from 'yup';
import { validationMessages } from '@constants/validationMessages';

import { TAG_FILTERS_FIELDS_NAMES } from '@constants/userSettingsUserCustomFields';

export const validationSchema = yup.object().shape({
  [TAG_FILTERS_FIELDS_NAMES.from]: yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .min(0, validationMessages.invalid)
    .max(99999, validationMessages.invalid)
    .test(
      'from-to',
      validationMessages.invalid,
      (value, context) => {
        const { to } = context.parent;
        if(typeof value !== 'number' || typeof to !== 'number'){
          return true;
        }

        return value <= to;
      }),
  [TAG_FILTERS_FIELDS_NAMES.to]: yup.number()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .min(0, validationMessages.invalid)
    .max(99999, validationMessages.invalid)
    .test(
      'to-from',
      validationMessages.invalid,
      (value, context) => {
        const { from } = context.parent;
        if(typeof value !== 'number' || typeof from !== 'number'){
          return true;
        }

        return value >= from;
      })
});

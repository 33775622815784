import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { IconWrapperProps, InputProps } from './types';

export const Input = styled.input<InputProps>`
  flex-grow: 1;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: transparent;
  color: inherit;
  
  ${({ $inputLength, $isDynamicInputWidth }) => 
    typeof $inputLength === 'number' && $isDynamicInputWidth && css`
    width: ${$inputLength}ch;
  `}
  
  ${({ $isEdit }) => !$isEdit && css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};
  
    &:focus{
      outline: none;
    }
`;

export const ButtonsWrapper = styled.div`
  right: 15px;
  top:0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ $save, $onEditCancel }) => ($save || $onEditCancel) && css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${COLORS.tagBorder};
    background-color: ${COLORS.white};

    & > svg {
      width: 8px;
      height: 8px;
    }

    ${ $save && css`
      & > svg > path {
        fill: ${COLORS.green} !important;
      } 
    `}
    
    ${ $onEditCancel && css`
      & > svg > path {
        fill: ${COLORS.red} !important;
      }
    `}
  `};

  ${({ $delete }) => $delete && css`
    & > svg > path {
      fill: ${COLORS.tagText};
    }
  `};
`;

// if we get string, format is "yyyy-mm-dd"
// minimal date can be `1970/01/01` and max date can bee `2050/01/01
export const isYearOfDateValid = (dateString: null | string) => {
  if(!dateString) {
    return false;
  }

  let yearFromStr = parseInt(dateString.slice(0, 4), 10);

  if(isNaN(yearFromStr)){
    return false;
  }

  return yearFromStr >= 1970 && yearFromStr <= 2050;
};

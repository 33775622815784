import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import {
  FILTER_OPTIONS,
  FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES,
  FORM_NAMES,
  SEARCH_BUTTON_MODE,
} from '@modules/NotesAndHistory/components/Notes/components/GeneralFilters/data';

import { Close } from '@/assets';
import BlockButton from '@components/BlockButton';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  CloseIcon,
  DatePickersWrapper,
  Divider,
  Wrapper,
  StyledPaper
} from './styles';

import { SearchPanelProps } from './types';

const SELECT_PROPS = {
  style: {
    fontSize: 12,
    width: '70px',
  }
};

const MENU_LIST_PROPS = {
  style: {
    fontSize: 12,
    padding: 0,
  }
};

const TEXT_FIELD_PROPS = {
  style: {
    padding: 0,
    flex: 1
  }
};

export const SearchPanel = ({
  changeFilters,
  control,
  isVisibleCloseIconButton,
  reset,
  setIsVisibleCloseIconButton,
  setSearchIconButtonMode,
}: SearchPanelProps) => {

  const [ date_from, date_to, text, selectFilter ] = useWatch({
    control,
    name: [
      FORM_NAMES.date_from,
      FORM_NAMES.date_to,
      FORM_NAMES.text,
      FORM_NAMES.selectFilter
    ]
  });

  const onSearchButtonClick = useCallback(() => {
    changeFilters((prev) => ({
      ...prev,
      date_from,
      date_to,
      text,
      selectFilter
    }));
    setIsVisibleCloseIconButton(true);
    setSearchIconButtonMode(SEARCH_BUTTON_MODE.withResult);
  }, [date_from, date_to, text, selectFilter]);

  const isSearchButtonDisable = useMemo(() => {
    return Boolean(date_from || date_to || text);
  }, [date_from, date_to, text]);

  const onCloseIconButtonClick = useCallback(() => {
    reset();
    changeFilters((prev) => ({
      ...prev,
      ...FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES
    }));
    setIsVisibleCloseIconButton(false);
    setSearchIconButtonMode(SEARCH_BUTTON_MODE.active);
  }, []);

  return(
    <Wrapper>
      <StyledPaper>
        <HFSelect
          control={ control }
          options={ FILTER_OPTIONS }
          name={ FORM_NAMES.selectFilter }
          SelectProps={ SELECT_PROPS }
          MenuListProps={ MENU_LIST_PROPS }
        />
        <Divider $height={ '16px' }/>
        <HFTextInput
          TextFieldProps={ TEXT_FIELD_PROPS }
          control={ control }
          placeholder={ 'Enter Search Keywords Here' }
          name={ FORM_NAMES.text }
        />
      </StyledPaper>
      <DatePickersWrapper>
        <HFDatePicker
          control={ control }
          name={ FORM_NAMES.date_from }
          DatePickerProps={ {
            placeholder: 'From'
          } }
        />
        -
        <HFDatePicker
          control={ control }
          name={ FORM_NAMES.date_to }
          DatePickerProps={ {
            placeholder: 'To'
          } }
        />
      </DatePickersWrapper>
      <BlockButton
        onClick={ onSearchButtonClick }
        disabled={ !isSearchButtonDisable }
        style={ {
          minWidth: '60px'
        } }
      >
        Search
      </BlockButton>
      { isVisibleCloseIconButton &&
        <>
          <CloseIcon>
            <Close onClick={ onCloseIconButtonClick }/>
          </CloseIcon>
        </>
      }
    </Wrapper>
  );
};
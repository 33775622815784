import { action, flow, makeAutoObservable } from 'mobx';
import { omit } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import {
  GetUserCustomFieldItemBackedResponse,
  UserCustomFieldFormFields,
  UserCustomFieldNamesOnSaveCallback,
} from '@/shared/types/userSettingsUserCustomFields';
import { CloseModal } from '@/shared/types/commonTypes';
import { OptionsAlertType } from '@modules/UserSettings/UserCustomFieldPopup/types';

import { getUserCustomFieldItem } from '@services/api/userSettings/userSettingsUserCustomFields';
import { USER_CUSTOM_FIELD_FORM_FIELDS } from '@constants/userSettingsUserCustomFields';


type UseFormMethods = UseFormReturn<UserCustomFieldFormFields>

class UserCustomFieldPopupStore {
  alertCancelCallback: null | (() => void) = null;
  alertConfirmCallback: null | (() => void) = null;
  alertType: OptionsAlertType | null = null;
  closeTrigger: CloseModal | null = null;
  isOptionsInEditMode: boolean = false;
  isLoad: boolean = false;
  onSaveCallback: UserCustomFieldNamesOnSaveCallback | null = null;
  useFormMethods: UseFormMethods = {} as UseFormMethods;


  constructor() {
    makeAutoObservable(this, {
      init: flow.bound,
      onCancel: action.bound,
      onConfirm: action.bound,
      onError: action.bound,
      resetStore: action.bound,
      setAlertData: action.bound,
      setIsOptionsInEditMode: action.bound,
    });
  }

  *init(
    useFormMethods: UseFormMethods,
    closeTrigger: CloseModal,
    onSaveCallback: UserCustomFieldNamesOnSaveCallback,
    id?: UserCustomFieldFormFields['id']
  ) {
    try {
      this.useFormMethods = useFormMethods;
      this.onSaveCallback = onSaveCallback;
      this.closeTrigger = closeTrigger;

      if(id){
        this.isLoad = true;

        const resp: GetUserCustomFieldItemBackedResponse = yield getUserCustomFieldItem({ id });
        this.useFormMethods.reset(resp.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (id) {
        this.isLoad = false;
      }
    }
  }

  setIsOptionsInEditMode(state: boolean){
    this.isOptionsInEditMode = state;
  }

  setAlertData(type: OptionsAlertType, onConfirm: () => void, onCancel: () => void){
    this.alertType = type;
    this.alertConfirmCallback = onConfirm;
    this.alertCancelCallback = onCancel;
  }

  resetAlertData(){
    this.alertType = null;
    this.alertConfirmCallback = null;
    this.alertCancelCallback = null;
  }

  onConfirm(){
    if(this.alertType){
      this.alertConfirmCallback && this.alertConfirmCallback();
      this.resetAlertData();
    } else {
      this.useFormMethods.handleSubmit(async (data) => {
        if(this.onSaveCallback && this.closeTrigger){
          const clearData = omit(data, 'clientId', 'createdBy');
          this.isLoad = true;
          await this.onSaveCallback(clearData, this.onError);
          this.isLoad = false;
        }
      })();
    }
  }

  onError(message: string) {
    this.useFormMethods.setError(USER_CUSTOM_FIELD_FORM_FIELDS.name, {
      type: 'custom',
      message,
    });
  }

  onCancel(){
    if(this.alertType){
      this.alertCancelCallback && this.alertCancelCallback();
      this.resetAlertData();
    } else {
      this.closeTrigger && this.closeTrigger();
    }
  }

  resetStore() {
    this.alertType = null;
    this.closeTrigger = null;
    this.isLoad = false;
    this.useFormMethods = {} as UseFormMethods;
    this.onSaveCallback = null;
    this.isOptionsInEditMode = false;

    this.resetAlertData();
  }
}

export default UserCustomFieldPopupStore;

type Props = {
  value: string
  separator?: string,
  fraction?: string,
}

const DEFAULT_FRACTION = '.';
const DEFAULT_SEPARATOR = '.';

export const numberStringToLocaleString = ({
  value,
  separator = DEFAULT_SEPARATOR,
  fraction = DEFAULT_FRACTION,
}: Props) => {
  let string = value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  const parts = string.split('.');
  if (parts.length > 1) {
    string = parts.slice(0, -1).join(' ') + fraction + parts[parts.length - 1];
  }

  return string;
};


import {
  parse,
  format,
  isValid
} from 'date-fns';

import {
  YEAR_MONTH_DAY,
} from '@constants/dateFormats';

import { getOrdinal } from './getOrdinal';

export const getRecurringOptions = (dateString, source) => {
  const parsedDateString = parse(dateString, YEAR_MONTH_DAY, new Date());
  const targetDate = isValid(parsedDateString) ? parsedDateString : new Date();
  const data = format(targetDate, 'EEE do LLL d');

  const [dayOfWeek, dayOfMonth, month, dayNumberAtMonth] = data.split(' ');
  const nthOfWeekDayAtMonthNumber = Math.ceil(dayNumberAtMonth / 7);

  const nthOfWeekDayAtMonth = `${nthOfWeekDayAtMonthNumber}${getOrdinal(nthOfWeekDayAtMonthNumber)}`;

  return source?.map(item => {
    const newLabel = item.label.replace(':dayofweek', dayOfWeek)
      .replace(':dayofmonth', dayOfMonth)
      .replace(':month/',`${month} `)
      .replace(':occurrencepermonth',`${nthOfWeekDayAtMonth} `);

    return {
      value: item.value,
      label: newLabel
    };
  });
};

import { SortDirection } from '@/shared/types/commonTypes';

export const compareDates = (direction: SortDirection) => {
  return (a: string, b: string): number => {
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();

    return direction === 'asc' ? dateA - dateB : dateB - dateA;
  };
};

import { CLIENT_SPECIFIC_TASKS_PATH, OTHER_TASKS_PATH, TO_DO_TABS } from '@constants/routes/toDos';

import { OtherTasks, ClientsTasks } from '@/assets';

export const TO_DOS_TABS_DATA = [
  {
    value: TO_DO_TABS.clientSpecificTasks,
    label: 'Client-Specific Tasks',
    link: CLIENT_SPECIFIC_TASKS_PATH,
    icon: ClientsTasks
  },
  {
    value: TO_DO_TABS.otherTasks,
    label: 'Other Tasks',
    link: OTHER_TASKS_PATH,
    icon: OtherTasks
  },
];

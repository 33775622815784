import React from 'react';

import { ActionButtons, ContactsSearch } from './components';

import { ActionsWrapper, ChildrenWrapper, ToolbarSearchWrapper, Wrapper } from './styles';

import { AppHeaderProps } from './types';

export const AppHeader = ({
  children,
  $isSticky
}: AppHeaderProps) => {

  return (
    <Wrapper $isSticky={ $isSticky }>
      <ChildrenWrapper>
        { children }
      </ChildrenWrapper>
      <ToolbarSearchWrapper>
        <ContactsSearch />
      </ToolbarSearchWrapper>
      <ActionsWrapper>
        <ActionButtons/>
      </ActionsWrapper>
    </Wrapper>
  );
};

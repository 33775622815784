import styled from 'styled-components';

export const LeftColumnWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 48px;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 26px;
`;

import { action, makeAutoObservable } from 'mobx';

import { ProviderContext } from 'notistack';

import { NotificationItem } from '@/shared/types/notifications';

class NotificationsStore {

  notifications: Array<NotificationItem> = [];
  isTriggerCloseAll: boolean = false;
  notiStackCloseFunc: ProviderContext['closeSnackbar'] | null = null;

  constructor() {
    makeAutoObservable(this, {
      closeAll: action.bound,
      removeNotification: action.bound,
      showNotification: action.bound,
      triggerCloseAll: action.bound,
      setNotiStackCloseFunc: action.bound,
    });
  }

  showNotification(notification: NotificationItem){
    this.notifications = [...this.notifications, notification];
  }

  setNotiStackCloseFunc(func: ProviderContext['closeSnackbar']) {
    if(!this.notiStackCloseFunc){
      this.notiStackCloseFunc = func;
    }
  }

  removeNotification(key: NotificationItem['key']) {
    this.notifications = this.notifications.filter(notification => notification.key !== key);
    this.notiStackCloseFunc && this.notiStackCloseFunc(key);
  }

  closeAll(){
    if(this.notifications.length === 0){
      return;
    }

    this.notifications.forEach(notification => {
      this.notiStackCloseFunc && this.notiStackCloseFunc(notification.key);
    });

    this.notifications = [];

    this.isTriggerCloseAll = false;
  }

  triggerCloseAll(){
    this.isTriggerCloseAll = true;
  }
}

export default NotificationsStore;

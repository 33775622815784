import React, { ComponentType, ReactElement, ElementType } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@store';
import { DataType, ModalType } from '@services/store/modalStore/types';

function ModalRenderer() {
  const store = useStore().ModalStore;
  const renderModal = (
    modalType: ModalType,
    modalData?: DataType[ModalType]
  ): ReactElement | null => {
    if (!modalData) {
      return null;
    }

    const { component,...props } = modalData;
    const ModalComponent = component as ElementType;

    if (!modalType || !ModalComponent) {
      return null;
    }

    const closeModal = () => store.closeModal(modalType);

    return (
      <ModalComponent
        closeModal={ closeModal }
        key={ modalType }
        minimized={ props.minimized }
        modalProps={ props.modalProps }
        modalType={ modalType }
      />
    );
  };

  return (
    <>
      {Object.keys(store.data).map((modalType: string) =>
        renderModal(
          modalType as ModalType,
          store.data[modalType as ModalType]
        )
      )}
    </>
  );
}

export default observer(ModalRenderer);

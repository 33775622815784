import { AccountSettingsInformationInputNamesType } from '@/pages/NewUserSettings/types';

export const AccountSettingsInformationInputNames: AccountSettingsInformationInputNamesType = {
  timezone: 'timezone',
  defaultLoginTab: 'defaultLoginTab',
  fullNameOrder: 'fullNameOrder',
  organizationId: 'organizationId',
  automaticLogout: 'automaticLogout',
  defaultCalendarView: 'defaultCalendarView',
  activityDefaultTime: 'activityDefaultTime',
  calendarStartTime: 'calendarStartTime',
  calendarEndTime: 'calendarEndTime',
};
import { ColumnsType } from '@components/Table/components/Header/types';

export const LINK_NEW_CUSTOM_FIELD = 'Add Custom Field';

export const COLUMNS: ColumnsType = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '33%'
    },
    sortable: true,
  },
  {
    id: 'value',
    label: 'Value',
    style: {
      width: '66%'
    },
    sortable: true,
    separator: true,
  },
];

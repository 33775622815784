import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  UserDeletePopupInputNames
} from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/data';

import {
  BlockWithCircle,
  WrapperPopupChildren,
  WrapperText
} from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/styles';


export const PopupChildWIthReplacingAndSave = () => {

  const { control } = useFormContext();

  const [ firstName, lastName, replacingUser ] = useWatch({
    control,
    name: [
      UserDeletePopupInputNames.firstName,
      UserDeletePopupInputNames.lastName,
      UserDeletePopupInputNames.replacingUser,
    ],
  });
  
  return (
    <WrapperPopupChildren>
      <BlockWithCircle>
        { `${firstName} ${lastName} will be replaced with ${replacingUser.fullName}` }
      </BlockWithCircle>
      <BlockWithCircle>
        { `${firstName} ${lastName} will be deleted.` }
      </BlockWithCircle>

      <WrapperText>
        Do you want to continue? This action is irreersible.
      </WrapperText>
    </WrapperPopupChildren>

  );
};


import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import { hexToRgba } from '@/shared/utils/hexToRgba';

import { OutOfSightActivityWrapperProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${hexToRgba(COLORS.light, 0.8)};
  cursor: pointer;
`;

export const Item = styled.div`
  display: flex;
  margin: auto;
  padding: 5px 10px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.dividingLines};
  border-radius: 4px;
`;

export const ActivityItem = styled.div`
  display: flex;
  margin: auto;
  padding: 0px;
`;

export const ItemIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export const ActivityItemIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 7px;
`;

export const ItemText = styled.span`
  line-height: 1.3;
  font-size: 14px;
  color: ${COLORS.primaryText};
`;

export const ActivityItemText = styled.span`
  line-height: 18px;
  font-size: 12px;
  color: ${COLORS.primaryText};
`;

export const OutOfSightTDWrapper = styled.td`
  display: flex;
  align-items: stretch;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  cursor: pointer;
  pointer-events: auto;
  
    & > *{
      flex-grow: 1;
    }
`;

const OutOfSightWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  & > *{
    flex-grow: 1;
  }
`;

export const OutOfSightActivityWrapper = styled(OutOfSightWrapper)<OutOfSightActivityWrapperProps>`
  align-items: stretch;
  z-index: auto;
  cursor: pointer;
  pointer-events: auto;
  
  ${({ $marginLeft }) => $marginLeft && 'margin-left: 3px'};
`;

export const OutOfSightCommonWrapper = styled(OutOfSightWrapper)``;
import React from 'react';
import { COLORS_MAP_BY_EVENT_TYPE } from '@/shared/constants/colorsByEventTypes';

import { Wrapper } from './styles';

export const EventInnerWrapper = ({
  type,
  children,
  ref,
}) => {
  const {
    indicatorColor,
    bgColor,
    fontColor
  } = COLORS_MAP_BY_EVENT_TYPE[type] || {};

  return (
    <Wrapper
      $bgColor={ bgColor }
      $fontColor={ fontColor }
      $indicatorColor={ indicatorColor }
      ref={ ref }
    >
      { children }
    </Wrapper>
  );
};

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { TWO_LEVEL_FILTER_STYLE_SCHEME_CONFIG } from '@/modules/TwoLevelFilter/data';

import BlockButton from '@components/BlockButton';
import TextEllipsis from '@components/TextEllipsis';

import { CaretDown, CaretUp } from '@/assets';

import {
  ArrowIconWrapper,
  FilterName,
  FilterWrapper,
  CategoryLabel,
} from './styles';

import { FilterButtonProps } from './types';

export const FilterButton = observer(({
  $disabled,
  $isAfterLine,
  $isBeforeLine,
  colorSchema,
  isListOpen,
  isSelected,
  onButtonClick,
  selectedSubcategory,
  StyleBlockButtonProps,
  startAdornment,
  categoryName,
  colorSchemaSelected,
  allButtonLabel
}: FilterButtonProps) => {

  const buttonColorScheme = useMemo(() => (
    isSelected
      ? TWO_LEVEL_FILTER_STYLE_SCHEME_CONFIG[colorSchemaSelected]
      : TWO_LEVEL_FILTER_STYLE_SCHEME_CONFIG[colorSchema]
  ), [colorSchema, colorSchemaSelected, isSelected]);

  const ShowMoreCaretIcon = isListOpen ? CaretUp : CaretDown as React.ElementType;

  const categoryLabel = allButtonLabel && isSelected && selectedSubcategory === allButtonLabel ? '' : categoryName;

  return (
    <FilterWrapper
      $borderRadius={ buttonColorScheme.$borderRadius }
      $disabled={ $disabled }
      $isAfterLine={ $isAfterLine }
      $isBeforeLine={ $isBeforeLine }
      $isSelected={ isSelected }
    >
      <BlockButton
        $iconSize={ 'small' }
        disabled={ $disabled }
        onClick={ onButtonClick }
        style={ StyleBlockButtonProps }
        { ...buttonColorScheme }
      >
        { startAdornment }
        <FilterName
          $isSelected={ isSelected }
          $secondColor={ buttonColorScheme.$secondColor }
        >
          <CategoryLabel>
            { categoryLabel }
          </CategoryLabel>
          <TextEllipsis
            $maxWidth={ 90 }
          >
            { isSelected && selectedSubcategory } 
          </TextEllipsis>
        </FilterName>
        <ArrowIconWrapper>
          <ShowMoreCaretIcon />
        </ArrowIconWrapper>
      </BlockButton>
    </FilterWrapper>
  );
});

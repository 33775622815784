import React, { useCallback } from 'react';

import { useStore } from '@store';

import BlockButton from '@components/BlockButton';

import { Restore, Trash } from '@/assets';
import { getIsAbleToSetUserPermission } from '@/pages/NewUserSettings/utils';
import { getTypeNameOfCurrentView } from '@pages/Trash/utils';
import { capitalizeEachWord } from '@/shared/utils/capitalizeEachWord';

import { ActionButtonsProps } from '@/pages/Trash/types';


export const ActionButtons = ({
  onPermanentlyDeleteClick,
  onRestoreClick,
  selectedItemsCount
}: ActionButtonsProps) => {
  const { roles } = useStore().SettingsStore;

  const trashStore = useStore().TrashStore;
  const { currentTrashView } = trashStore;

  const isAbleRole = getIsAbleToSetUserPermission(roles);
  const { one, many } = getTypeNameOfCurrentView(currentTrashView);

  const onPermanentlyDeleteClickWrapper = useCallback(() => {
    onPermanentlyDeleteClick();
  }, [ onPermanentlyDeleteClick ]);

  const onRestoreClickWrapper = useCallback(() => {
    onRestoreClick();
  }, [ onRestoreClick ]);

  return (
    <>
      { isAbleRole &&
        <BlockButton
          onClick={ onPermanentlyDeleteClickWrapper }
          startIcon={ <Trash/> }
          style={ {
            marginRight: '10px',
          } }
        >
          { `Permanently Delete Selected ${capitalizeEachWord(selectedItemsCount > 1 ? many : one)}` }
        </BlockButton>
      }
      <BlockButton
        onClick={ onRestoreClickWrapper }
        startIcon={ <Restore/> }
      >
        { `Restore Selected ${capitalizeEachWord(selectedItemsCount > 1 ? many : one)}` }
      </BlockButton>
    </>
  );
};

import { getHouseholdLabel } from '@/shared/utils/getHouseholdLabel';
import { LINKED_ITEM_TYPE_SCHEME } from '@constants/linkedItem';

export const usersResponseConverter = (response) => {
  if(response.data.data) {
    return response.data.data.data.reduce((acc, item) => {
      const householdData = item?.householderContacts || [];

      if(householdData?.length > 0) {
        acc.push({
          ...item,
          fullName: getHouseholdLabel(householdData)
        });
      }

      if(item?.firstName && item?.lastName){
        acc.push({
          ...item,
          abbr: item.firstName.charAt(0) + item.lastName.charAt(0),
          fullName: `${item.firstName} ${item.lastName}`
        });
      }

      if(item?.occupationCompany){
        acc.push({
          ...item,
          abbr: item.occupationCompany.slice(0,2),
          fullName: item.occupationCompany
        });
      }

      return acc;
    },[]);
  }
  return [];
};

export const responseLinkedItemDataConverter = (responseData) => {
  return Object.entries(responseData).reduce((acc, [key, value]) => {
    const linkedContactType = LINKED_ITEM_TYPE_SCHEME[key].type;

    if(key !== LINKED_ITEM_TYPE_SCHEME.contactData.dataName) {
      const data = value.map((item) => {
        return {
          ...item,
          linkedContactType
        };
      });
      acc.push(...data);  
    } else {
      const data = value.map((item) => {
        return {
          contactData: item,
          linkedContactType
        };
      });
      acc.push(...data);  
    }
    
    return acc;
  }, []);
};

export const linkedItemResponseConverter = (response) => {
  if(response.data.data.data) {
    return response.data.data.data;
  }
  return [];
};

export const getFullName = (item) => {
  const householdData = item?.householderContacts || [];
  if(householdData?.length > 0) {
    return getHouseholdLabel(householdData);
  }

  if(item?.firstName && item?.lastName){
    return `${item.firstName} ${item.lastName}`;
  }

  if(item?.occupationCompany){
    return  item.occupationCompany;
  }
};

export const getNameParam = (queryString) => ({
  'filters[where][name][0]': queryString,
});

export const getContactAccountPlanOpportunitiesNameParam = (queryString) => ({
  'filters[where][name][]': queryString,
});

export const getSearchParamsWithName = (obj) => (queryString) => ({
  ...obj,
  ...getNameParam(queryString),
});

export const getAdditionalParamsToNameParam = (paramsObj = {}) => (querystring) => {
  const additionalParams = Object.entries(paramsObj).reduce((acc,[key, value]) => {
    acc[key] = value;
    return acc;
  },{});
  
  return {
    ...additionalParams,
    ...getNameParam(querystring)
  };
};
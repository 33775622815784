import { ColorScheme } from '@modules/ConfirmationPopup/types';
import { COLOR_SCHEME_TYPES } from '@components/BlockButton/data';

export const POPUP_TYPES = {
  WARNING: 'warning',
  INFO: 'info',
  CONFIRM: 'confirm',
  TIMER: 'timer'
} as const;

export const COLOR_SCHEME: ColorScheme = {
  [POPUP_TYPES.WARNING]: {
    cancelColorSchemeType: COLOR_SCHEME_TYPES.warningOutlined,
    confirmColorSchemeType: COLOR_SCHEME_TYPES.warningContained,
  },
  [POPUP_TYPES.CONFIRM]: {
    cancelColorSchemeType: COLOR_SCHEME_TYPES.outlined,
    confirmColorSchemeType: COLOR_SCHEME_TYPES.contained
  },
  [POPUP_TYPES.INFO]: {
    confirmColorSchemeType: COLOR_SCHEME_TYPES.infoContained,
    cancelColorSchemeType: COLOR_SCHEME_TYPES.infoOutlined,
  },
  [POPUP_TYPES.TIMER]: {
    confirmColorSchemeType: COLOR_SCHEME_TYPES.infoContained,
    cancelColorSchemeType: COLOR_SCHEME_TYPES.infoOutlined,
  },
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { LayoutWrapperProps } from '../types';

export const LayoutWrapper = styled.div<LayoutWrapperProps>`
  display: flex;
  gap: 20px;
  flex-direction: column;
  height: ${({ $height }) => $height};

  & > div {
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px #101C5B12;
    background: ${COLORS.white};
  }
`;

export const ActivityTasksLayoutWrapper = styled(LayoutWrapper)<LayoutWrapperProps>`
  min-height: 430px;

  & > div:nth-child(1) {
    height: 35%;
  }

  & > div:nth-child(2) {
    height: 65%;
  }
`;

export const AccountsTagsLayoutWrapper = styled(LayoutWrapper)<LayoutWrapperProps>`
  min-height: 450px;
  
  & > div {
    height: 50%;
  }
`;

export const MainContactInformationLayoutWrapper = styled(LayoutWrapper)<LayoutWrapperProps>`
  & > div {
    height: 100%;
  }
`;

import React, { ChangeEvent, useCallback, useState } from 'react';

import CustomAutocomplete from '@components/CustomAutocomplete';

import { useAutoCompleteFetch, useEffectOnce } from '@customHooks';

import { FetchAutoCompleteProps } from './types';
import { AutocompleteInputChangeReason } from '@material-ui/lab';

export const FetchAutoComplete = <T,>({
  AutocompleteProps,
  FetchProps,
  inputProps = {},
  InputProps = {},
  PopperStyledProps,
  TextFieldProps = {},
  disabled = false,
  onChange = () => {},
  outSideInputValue,
  placeholder = 'Start Typing to Search...',
  refreshData,
  setOutSideInputValue,
  showError,
  withoutQueryMode,
}: FetchAutoCompleteProps<T>) => {

  const [localInputValue, setLocalInputValue] = useState('');

  const {
    data: options,
    setQuery,
    isLoad,
    triggerLoadWithoutQuery,
  } = useAutoCompleteFetch<T>({
    delay: FetchProps.delay || 500,
    getParams: FetchProps.getParams,
    initData: FetchProps.initData || [],
    requestSender: FetchProps.requestSender,
    responseConverter: FetchProps.responseConverter,
    withoutQueryMode,
    refreshData,
  });


  const onChangeInput = useCallback((value: string) => {
    if(setOutSideInputValue){
      setOutSideInputValue(value);
    } else {
      setLocalInputValue(value);
    }
  }, [setOutSideInputValue]);

  const handleInputChange = useCallback((
    event: ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if(!withoutQueryMode) {
      if (event && event.type === 'blur') {
        onChangeInput('');
        setQuery('');
      } else if (reason !== 'reset') {
        onChangeInput(value);
        setQuery(value);
      }
    } else {
      if(reason !== 'reset') {
        onChangeInput(value);
        if(refreshData) {
          setQuery(value);
        }
      }
    }

  }, [withoutQueryMode, onChangeInput, setQuery]);
  
  const onFocus = useCallback(() => {
    if(!refreshData && withoutQueryMode && options.length === 0){
      triggerLoadWithoutQuery();
    } else if(refreshData) {
      options.length = 0;
      triggerLoadWithoutQuery();
    }
  },[ withoutQueryMode, refreshData, options, triggerLoadWithoutQuery ]);

  const handleChange = useCallback((event: ChangeEvent<{}>, value: any) => onChange(value), [ onChange ]);

  return (
    <CustomAutocomplete
      AutocompleteProps={ {
        ...AutocompleteProps,
        disabled,
        inputValue: outSideInputValue || localInputValue,
        loading: isLoad,
        loadingText: 'Loading...',
        noOptionsText: 'No results',
        onChange: handleChange,
        onInputChange: handleInputChange,
        onFocus,
        options,
      } }
      inputProps={ inputProps }
      InputProps={ InputProps }
      PopperStyledProps={ PopperStyledProps }
      TextFieldProps={ {
        placeholder,
        ...TextFieldProps,
      } }
      showError={ showError }
    />
  );
};


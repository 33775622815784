import { parse, format, startOfHour, differenceInMinutes } from 'date-fns';

import { COLORS } from '@constants/colors';
import { HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

const START_OF_HOUR_IN_MINUTES = 0;
const HALF_AN_HOUR_IN_MINUTES = 30;

export const getFormats = () => ({
  timeGutterFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'h:mm aaa', culture) : '',
  dayFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE, MMM dd', culture) : '',
  weekdayFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE', culture) : '',
  dayHeaderFormat: (date, culture, calendarLocalizer) =>
    calendarLocalizer ? calendarLocalizer.format(date, 'EEEE, LLLL dd, uuuu', culture) : '',
  dayRangeHeaderFormat: ({ start, end }, culture, calendarLocalizer) =>
    calendarLocalizer
      ? `${calendarLocalizer.format(start, 'MMMM dd', culture)} - ${calendarLocalizer.format(end, 'MMMM dd', culture)}`
      : '',
});

export const getCustomSlotProp = (timeSlot, workingStartTime, workingEndTime) => {
  const customStyles = {
    className: 'custom-style-slot',
    style: {}
  };

  const startOfHourTimeSlot = startOfHour(timeSlot);
  const differenceInMin = differenceInMinutes(timeSlot, startOfHourTimeSlot);

  if(differenceInMin === START_OF_HOUR_IN_MINUTES || differenceInMin === HALF_AN_HOUR_IN_MINUTES) {
    customStyles.style = {
      ...customStyles.style,
      borderBottom: `1px dashed ${COLORS.grey}`
    };
  }

  const startWorkingDate = parse(workingStartTime, HOURS_MINUTES_SECONDS, new Date());
  const endWorkingDate = parse(workingEndTime, HOURS_MINUTES_SECONDS, new Date());
  const formattedTimeSlot = format(timeSlot, HOURS_MINUTES_SECONDS);
  const parsedTimeSlot = parse(formattedTimeSlot, HOURS_MINUTES_SECONDS, new Date());

  if(parsedTimeSlot < startWorkingDate || parsedTimeSlot > endWorkingDate) {
    customStyles.style = {
      ...customStyles.style,
      color: COLORS.grey,
    };
  }

  return customStyles;
};

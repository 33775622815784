import { COLORS } from '@constants/colors';
import { css } from 'styled-components';

export const BOX_SHADOW = '0px 1px 5px rgba(16, 28, 91, 0.07);';

export const SIDEMENU_WIDTHES = {
  open: '260px',
  closed: '62px'
};

export const INPUT_HELPER_TEXT_STYLE = css`
  margin: 0;
  padding-top: 8px;
  color: ${COLORS.helpText};
  font-size: 12px;
  line-height: 1.2;
`;

export const TABLE_CELL_LEFT_PADDING = '20px';
export const TABLE_CELL_HEIGHT = '40px';

export const TABLE_CELL_COMMON_STYLE = css`
  height: ${TABLE_CELL_HEIGHT};
  padding-left: ${TABLE_CELL_LEFT_PADDING};
  border-color: ${COLORS.dividingLines};

  &:last-child {
    padding-left: ${TABLE_CELL_LEFT_PADDING} !important;
  }
`;

export const TABLE_CELL_WITH_CONTROLS = css`
  width: 18px;
  ${TABLE_CELL_COMMON_STYLE};
`;

export const DATA_CUSTOM_STYLE_ATTRIBUTE_NAME = 'data-custom-style';

export const CSS_VARIABLE_NAMES = {
  menuWidth: '--menu-width'
};
export const ERROR_SPACE_VALUE = 15;

export const ERROR_CONTAINER_STYLES = css`
  height: auto !important;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  margin-top: 0;
  color: ${COLORS.red};
  font-size: 10px;
`;

export const INPUT_HEIGHT = 30;

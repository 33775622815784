import React, { useCallback, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';

import { useStore } from '@store';

import FormBlock from '@components/FormBlock';
import Modal from '@components/Modal';
import PopupButtonPanelItems from '@components/PopupButtonPanelItems';

import { CONTACT_CLASSIFICATION, CONTACT_STATUSES_OPTIONS } from '@constants/common';
import { ACTION_TYPES, ACTION_TYPES_BUTTONS, ADD_SPOUSE_FIELD_NAMES, ADD_SPOUSE_TYPES } from './data';

import {
  ExistedContact,
  Footer,
  NewContact,
} from './components';

import { TypeButtonOnClickType } from '@components/TypeButton/types';
import { ContactItem } from '@/shared/types/contact';
import { AddSpousePopupProps, FieldsType, ExistedContactType } from './types';

import { convertData } from './utils';

import { getValidationSchema } from './getValidationSchema';

export const AddSpousePopup = ({
  closeModal,
  modalProps: {
    currentUserId
  }
}: AddSpousePopupProps) => {
  const { currentContact } = useStore().ContactDetailsStore;
  const contactDetailsProfileStore= useStore().ContactDetailsProfileStore;
  const { optionsForIndividual } = contactDetailsProfileStore;

  const [ actionTypeToSwitch, setActionTypeToSwitch ] = useState<null | string | number>(null);
  const [ existedContactData, setExistedContactData ] = useState<null | ExistedContactType>(null);

  const methods = useForm<FieldsType>({
    mode: 'onSubmit',
    defaultValues: {
      [ADD_SPOUSE_FIELD_NAMES.spouseType]: ADD_SPOUSE_TYPES.combineHousehold
    },
    resolver: yupResolver(getValidationSchema),
  });

  const {
    field: {
      onChange,
      value,
    }
  } = useController({
    control: methods.control,
    name: ADD_SPOUSE_FIELD_NAMES.addType,
    defaultValue: ACTION_TYPES.new
  });

  const onTypeChange: TypeButtonOnClickType = useCallback((event, value) => {
    const dirtyFieldsWithoutAddType = omit(
      methods.formState.dirtyFields,
      ADD_SPOUSE_FIELD_NAMES.addType
    );

    if(Object.keys(dirtyFieldsWithoutAddType).length > 0){
      setActionTypeToSwitch(value);
    } else {
      onChange(value);
    }
  }, [methods.formState.dirtyFields, onChange]);

  const onAttentionCancel = useCallback(() => {
    setActionTypeToSwitch(null);
  }, []);

  const onAttentionConfirm = useCallback(() => {

    if(actionTypeToSwitch) {
      methods.reset({
        // @ts-ignore
        [ADD_SPOUSE_FIELD_NAMES.addType]: actionTypeToSwitch,
        // @ts-ignore
        [ADD_SPOUSE_FIELD_NAMES.spouseType]: ADD_SPOUSE_TYPES.combineHousehold
      });

      if(actionTypeToSwitch === ACTION_TYPES.new && existedContactData) {
        setExistedContactData(null);
      }

      setActionTypeToSwitch(null);
    }
  }, [actionTypeToSwitch, existedContactData, methods]);

  const onSave = useCallback(() => {
    methods.handleSubmit((data) => {
      closeModal();
      contactDetailsProfileStore.addSpouse(convertData(
        data,
        existedContactData,
        currentContact!.officePrimaryAdvisor
      ));
    })();
  },[methods, closeModal, contactDetailsProfileStore, existedContactData, currentContact]);

  return (
    <Modal
      onClose={ closeModal }
      title={ 'Create Household or Link Spouse' }
    >
      <Modal.TopPanel>
        <PopupButtonPanelItems
          ButtonProps={ {
            disabled: Boolean(actionTypeToSwitch)
          } }
          currentActionType={ value }
          onClick={ onTypeChange }
          options={ ACTION_TYPES_BUTTONS }
        />
      </Modal.TopPanel>
      <FormProvider { ...methods }>
        <Modal.Content
          $disabled={ Boolean(actionTypeToSwitch) }
          padding={ '30px 30px 15px 30px' }
          width={ '790px' }
        >
          <FormBlock isBorderBottom={ false }>
            {
              value === ACTION_TYPES.new &&
              <NewContact
                classificationOptions={ CONTACT_CLASSIFICATION }
                contactId={ currentContact!.id }
                currentContact={ currentContact as ContactItem }
                currentUserId={ currentUserId }
                existedContact={ existedContactData }
                genderOptions={ optionsForIndividual?.gender || [] }
                sourceCategoryOptions={ optionsForIndividual?.sourceCategory || [] }
                sourceOptions={ optionsForIndividual?.source || [] }
                statusOptions={ CONTACT_STATUSES_OPTIONS }
              />
            }
            {
              value === ACTION_TYPES.existed &&
              <ExistedContact
                contactId={ currentContact!.id }
                currentContact={ currentContact as ContactItem }
                existedContact={ existedContactData }
                onExistedContactChange={ setExistedContactData }
              />
            }
          </FormBlock>
        </Modal.Content>
      </FormProvider>
      <Modal.Footer>
        <Footer
          closeModal={ closeModal }
          isAttentionMode={ Boolean(actionTypeToSwitch) }
          onAttentionCancel={ onAttentionCancel }
          onAttentionConfirm={ onAttentionConfirm }
          onSave={ onSave }
        />
      </Modal.Footer>
    </Modal>
  );
};

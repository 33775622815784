import styled, { css } from 'styled-components';

import Button from '@material-ui/core/Button';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 273px;
    height: 100%;
    max-height: calc(100vh - ${({ isHeaderExists }) => isHeaderExists ? '165px' : '104px'});
    box-sizing: border-box;
    border: 1px solid ${ COLORS.dividingLines };
    border-radius: 10px;
    background-color: ${ COLORS.white };
    overflow: hidden;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 15px 18px 15px;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid ${ COLORS.orange };
    color: ${ COLORS.header };
    background-color: ${COLORS.white};
`;

export const ChildrenWrapper = styled.div`
   overflow-y: auto;
   height: 100%;
`;

export const Title = styled.div`
    margin-right: 8px;
`;


export const ClearBtn = styled(Button)`
    margin-left: auto;
    min-width: inherit;
    padding: 0;

    &:hover {
      cursor: pointer;
      background-color: transparent;
    }
`;

export const InnerWrapper = styled.div`
  ${({ $topPadding }) => {
    if($topPadding) {
      return css`padding: ${$topPadding } 15px 15px;`;
    } else {
      return css`padding: 0 15px 15px;`;
    }
  }}
`;

export const ItemWrapperWithDelimiter = styled.div`
  padding: 6px 15px;
  border-bottom: 1px solid ${ COLORS.dividingLines };
`;

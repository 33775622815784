import { action, makeAutoObservable } from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

import { NoteItem } from '@/shared/types/note';
import { LinkedContact } from '@/shared/types/linkedContact';

import { convertContactToSelectOptions, getNoteLinkedContactAction } from './utils';
import { NoteLinkedContactActionValue } from './types';
import { NOTE_LINKED_CONTACT_ACTIONS } from '@modules/NotesAndHistory/data';
import { ValueLabelObjWidthDisable } from '@/shared/types/commonTypes';

type ContactType = LinkedContact | null;
export class NotesUtilsStore {
  alertType: NoteLinkedContactActionValue = null;
  prevLinkedContacts: Array<LinkedContact> = [];
  currentLinkedContact: ContactType = null;
  isNotesPanelInAddOrEditMode: boolean = false;

  constructor() {
    makeAutoObservable(this,{
      setPrevLinkedContact: action.bound,
      setCurrentLinkedContact: action.bound,
      prevLinkedContacts: true
    });
  }

  convertNotesByAlertType(notes: Array<NoteItem>, linkedContactFullData: LinkedContact | null): Array<NoteItem>{
    const data = cloneDeep(notes);

    if(this.alertType === NOTE_LINKED_CONTACT_ACTIONS.disconnect) {
      data.forEach((item: NoteItem, index: number) => {
        data[index].noteContactId = null;
      });
    }

    if (this.alertType === NOTE_LINKED_CONTACT_ACTIONS.reconnect && linkedContactFullData) {
      data.forEach((item: NoteItem, index: number) => {
        data[index].noteContactId = linkedContactFullData.id;
      });
    }

    return data;
  }

  detectAndSetAlert(notes: Array<NoteItem>, linkedContactFullData: LinkedContact | null): NoteLinkedContactActionValue {
    const alertType = getNoteLinkedContactAction(notes, linkedContactFullData);
    this.alertType = alertType;
    return alertType;
  }

  get noteContactOptions (){
    const currentContactOption = convertContactToSelectOptions(this.currentLinkedContact);
    const prevLinkedOptions = this.prevLinkedContacts.reduce((acc: Array<ValueLabelObjWidthDisable>, item) => {
      const options = convertContactToSelectOptions(item, true);
      options.forEach(option => {
        const indexInAcc = acc.findIndex(item => item.value === option.value);
        const indexInCurrent = currentContactOption.findIndex(item => item.value === option.value);
        if(indexInAcc >= 0 || indexInCurrent >= 0){
          return;
        }

        acc.push(option);
      });
      return acc;
    }, []);


    return [...currentContactOption, ...prevLinkedOptions];
  }

  resetAlertType() {
    this.setAlertType(null);
  }

  resetNotesState = () => {
    this.alertType = null;
    this.prevLinkedContacts = [];
    this.currentLinkedContact = null;
  };
  setAlertType(newAlertType: NoteLinkedContactActionValue){
    this.alertType = newAlertType;
  }

  setCurrentLinkedContact(contact: ContactType){
    this.currentLinkedContact = contact;
  }

  setPrevLinkedContact(contact: ContactType){
    if(contact){
      this.prevLinkedContacts.push(contact);
    }
  }

  toggleNotesPanelStateOn = () => {
    this.isNotesPanelInAddOrEditMode = true;
  };

  toggleNotesPanelStateOff = () => {
    this.isNotesPanelInAddOrEditMode = false;
  };
}

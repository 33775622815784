import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import BlockButton from '@components/BlockButton';
import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';

import {
  ImportantInfo,
  Rules,
  ServicingDetails,
  Source,
  SplitToIndividuals,
  Status,
  TabContentWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';
import { CommunicationPreferences } from './components';
import {
  HouseholdContactDetailsWrapper
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/ContactDetails/components';

import { MODAL_TYPE } from '@constants/modalTypes';

import {
  COMMON_FIELDS,
  COMMON_INDIVIDUAL_AND_HOUSEHOLD_FIELDS_NAMES,
  HOUSEHOLD_CONTACT_DETAILS_PARENT_FIELDS,
  HOUSEHOLD_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';


export const HouseholdIndividualDetails = observer(() => {
  const { contact, householdMembers, optionsForHousehold, initAdvisors } = useStore().ContactDetailsProfileStore;
  const modalStore = useStore().ModalStore;

  const renderContactTypeBlock = useCallback(() => {
    const onClick = () => {
      modalStore.openModal({
        modalProps: {
          householdMembers,
          primaryAdviser: initAdvisors?.primary
        },
        modalType: MODAL_TYPE.SPLIT_TO_INDIVIDUALS,
        component: SplitToIndividuals
      });
    };

    const blockedMember = householdMembers.find(member => !member.editAllow);
    
    return (
      <>
        <BlockButton
          disabled={ Boolean(blockedMember) }
          onClick={ onClick }
          style={ {
            transform: 'translate(10px, -1px)',
          } }
        >
          Convert to Individuals
        </BlockButton>
        { blockedMember &&
          <InfoIcon
            colorScheme={ INFO_COLOR_SCHEME.onlyRed }
            helpText={ 
              `You cannot convert to individuals because you don't have permissions to contact 
              ${blockedMember.firstName} ${blockedMember.lastName}`
            }
            type={ INFO_ICON_TYPES.alertInfo }
          />
        }
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [householdMembers, modalStore]);

  const headOfHouseholdOptions = useMemo(() => (
    householdMembers.map(member => ({
      value: member.id,
      label: `${member.firstName} ${member.lastName}`
    }))
  ), [householdMembers]);

  if(householdMembers.length === 0){
    return null;
  }

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item $noBottomPadding={ true }>
        <ImportantInfo name={ COMMON_INDIVIDUAL_AND_HOUSEHOLD_FIELDS_NAMES.importantInformation } />
        <HouseholdContactDetailsWrapper
          headOfHouseholdOptions={ headOfHouseholdOptions }
          householdMembers={ householdMembers }
          parentNames={ HOUSEHOLD_CONTACT_DETAILS_PARENT_FIELDS }
        />
        <CommunicationPreferences />
        <Status
          classificationName={ COMMON_FIELDS.classification }
          contactType={ contact?.type }
          headOfHouseHoldName={ HOUSEHOLD_FIELDS.householdOwnerId }
          headOfHouseHoldOptions={ headOfHouseholdOptions }
          isHousehold={ true }
          ratingName={ COMMON_FIELDS.rating }
          renderContactTypeBlock={ renderContactTypeBlock }
          statusName={ COMMON_FIELDS.status }
        />
        <Rules/>
        <Source
          sourceCategoryName={ COMMON_FIELDS.sourceCategory }
          sourceCategoryOptions={ optionsForHousehold?.sourceCategory || [] }
          sourceName={ COMMON_FIELDS.sourceId }
          sourceOptions={ optionsForHousehold?.source || [] }
        />
        <ServicingDetails
          clientCreationDateName={ COMMON_FIELDS.clientCreationDate }
          officeLocationName={ COMMON_FIELDS.officeLocation }
          primaryAdviserName={ COMMON_FIELDS.officePrimaryAdvisor }
          primaryCsrName={ COMMON_FIELDS.officePrimaryCsr }
          secondaryAdviserName={ COMMON_FIELDS.officeSecondaryAdvisor }
          secondaryCsrName={ COMMON_FIELDS.officeSecondaryCsr }
        />
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

import React from 'react';

import { StyledBtn } from './styles';

import { BlockButtonType } from './types';

import {
  COLOR_SCHEME_CONFIG,
  COLOR_SCHEME_TYPES,
  VARIANTS,
} from './data';

export const BlockButton = ({
  colorTest,
  hoverColor,
  bgColor,
  hoverBgColor,
  colorSchema = COLOR_SCHEME_TYPES.text,
  $iconSize,
  children,
  $isNoneFill = false,
  variant,
  ...props
}: BlockButtonType) => {
  const {
    $hoverColor,
    $bgColor,
    $color,
    $borderHoverColor,
    $borderColor,
    $bgHoverColor,
    $variant,
  } = COLOR_SCHEME_CONFIG[colorSchema];

  return (
    <StyledBtn
      $bgColor={ bgColor || $bgColor }
      $bgHoverColor={ hoverBgColor || $bgHoverColor }
      $borderColor={ $borderColor }
      $borderHoverColor={ $borderHoverColor }
      $color={ colorTest || $color }
      $hoverColor={ hoverColor || $hoverColor }
      $iconSize={ $iconSize }
      $isNoneFill={ $isNoneFill }
      color={ 'primary' }
      size={ 'medium' }
      variant={ variant || $variant || VARIANTS.contained }
      { ...props }
    >
      { children }
    </StyledBtn>
  );
};

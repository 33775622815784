import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { FieldDetailsProps } from './types';

import { UserCustomFieldFormFields } from '@/shared/types/userSettingsUserCustomFields';

import {
  USER_CUSTOM_FIELD_FORM_FIELDS,
  USER_CUSTOM_FIELDS_ENTITY_OPTIONS, USER_CUSTOM_FIELDS_TYPES,
  USER_CUSTOM_FIELDS_TYPES_OPTIONS
} from '@constants/userSettingsUserCustomFields';
import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from '../../data';

import { RightColumnWrapper, LeftColumnWrapper } from '../../styles';


export const FieldDetails = ({ isEdit, isUsedBefore }: FieldDetailsProps) => {
  const { control } = useFormContext<UserCustomFieldFormFields>();

  const typeValue = useWatch({
    control,
    name: USER_CUSTOM_FIELD_FORM_FIELDS.type
  });

  return (
    <FormBlock
      isBorderBottom={ typeValue === USER_CUSTOM_FIELDS_TYPES.dropDownList }
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Field Details' } />
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Name' }
          >
            <HFTextInput
              inputProps={ {
                placeholder: 'Enter Name',
                maxLength: 100,
              } }
              control={ control }
              name={ USER_CUSTOM_FIELD_FORM_FIELDS.name }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Field Type' }
          >
            <HFSelect
              control={ control }
              disabled={ isUsedBefore }
              name={ USER_CUSTOM_FIELD_FORM_FIELDS.type }
              options={ USER_CUSTOM_FIELDS_TYPES_OPTIONS }
              placeholder={ 'Select Field Type' }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Used For' }
          >
            <HFSelect
              control={ control }
              disabled={ isEdit }
              name={ USER_CUSTOM_FIELD_FORM_FIELDS.entity }
              options={ USER_CUSTOM_FIELDS_ENTITY_OPTIONS }
              placeholder={ 'Select Facility' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getClientByName } from '@services/api/common/common';

import FetchAutoComplete from '@modules/FetchAutoComplete';
import UserItem from '@components/NewUserItem';

import {
  UserDeletePopupInputNames
} from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/data';

import {
  WrapperSelectUser
} from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/styles';

import { ClientWithCalendarData } from '@/shared/types/commonTypes';

const LISTBOX_PROPS = {
  style: {
    maxHeight: '290px',
    paddingLeft: '5px'
  },
};

export const PopupChildWIthReplacing = observer(() => {

  const { getValues, setValue } = useFormContext();

  const idDeleteUser = getValues([UserDeletePopupInputNames.id])[0];

  const [ linkedContact, setLinkedContact ] = useState<ClientWithCalendarData | null>(null);

  const onAutoCompleteItemClick = useCallback((user: ClientWithCalendarData) => {
    setLinkedContact(user);
    setValue(UserDeletePopupInputNames.replacingUser, user);
  }, [ setValue ]);

  const onDeleteContact = useCallback(() => {
    setLinkedContact(null);
    setValue(UserDeletePopupInputNames.replacingUser, null);
  },[ setValue ]);

  const responseConverter = useCallback((response: AxiosResponse) => {
    const usersData = usersResponseConverter(response);
    return usersData.filter((user: ClientWithCalendarData) => user.id !== idDeleteUser);
  }, [ idDeleteUser ]);

  return (
    <WrapperSelectUser>
      { linkedContact &&
        <UserItem
          labelWidth={ 280 }
          onClose={ onDeleteContact }
          user={ linkedContact }
        />
      }
      { !linkedContact &&
        <FetchAutoComplete<ClientWithCalendarData>
          AutocompleteProps={ {
            disableCloseOnSelect: true,
            ListboxProps: LISTBOX_PROPS,
            getOptionLabel: (option: any) => option.fullName || '',
            renderOption: (option: any) => (
              <UserItem
                labelWidth={ 280 }
                user={ option }
              />
            )
          } }
          FetchProps={ {
            getParams: getNameParam,
            requestSender: getClientByName,
            responseConverter,
          } }
          InputProps={ {
            endAdornment: null
          } }
          onChange={ onAutoCompleteItemClick }
          placeholder={ 'Please select' }
          withoutQueryMode={ true }
        />
      }
    </WrapperSelectUser>
  );
});


import { ContactItem } from '@/shared/types/contact';
import { CONTACT_TYPES } from '@constants/common';
import {
  HOUSEHOLD_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

export const contactNormalizer = (contact: ContactItem): ContactItem => { 
  if (contact.type === CONTACT_TYPES.HOUSEHOLD) {
    const extractData = contact.householderContacts.reduce((acc, item) => {
      if(item.householdOwner === 'Yes'){
        acc[HOUSEHOLD_FIELDS.householdOwnerId] = item.id as string;
      }

      if(item.sendEmail === 'Yes'){
        acc[HOUSEHOLD_FIELDS.sendEmailToIds] = acc[HOUSEHOLD_FIELDS.sendEmailToIds].length > 0
          ? acc[HOUSEHOLD_FIELDS.sendEmailToIds] + `,${item.id}`
          :`${item.id}`;
      }

      if(item.sendMail === 'Yes'){
        acc[HOUSEHOLD_FIELDS.sendMailToIds] = acc[HOUSEHOLD_FIELDS.sendMailToIds].length > 0
          ? acc[HOUSEHOLD_FIELDS.sendMailToIds] + `,${item.id}`
          :`${item.id}`;
      }

      if(item.communicateWith === 'Yes'){
        acc[HOUSEHOLD_FIELDS.communicationWithIds] = acc[HOUSEHOLD_FIELDS.communicationWithIds].length > 0
          ? acc[HOUSEHOLD_FIELDS.communicationWithIds] + `,${item.id}`
          :`${item.id}`;
      }

      return acc;
    }, {
      [HOUSEHOLD_FIELDS.sendMailToIds]: '',
      [HOUSEHOLD_FIELDS.sendEmailToIds]: '',
      [HOUSEHOLD_FIELDS.communicationWithIds]: '',
      [HOUSEHOLD_FIELDS.householdOwnerId]: '',
    });

    return {
      ...extractData,
      ...contact,
    };
  }
  
  return  contact;
};


import React, { useCallback } from 'react';

import { POPUP_RADIO_VALUES } from '@constants/common';
import { RADIO_BUTTONS } from './data';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import RadioGroup, { useRadioBtn } from '@components/RadioGroup';

import { Wrapper } from './styles';

import { TodoMassDeleteConfirmationPopupProps } from './types';


export const TodoMassDeleteRecurringConfirmationPopup = ({
  closeModal,
  modalProps: {
    deleteData,
    onConfirm
  }
}: TodoMassDeleteConfirmationPopupProps) => {
  const { radioValue, onChange } = useRadioBtn(POPUP_RADIO_VALUES.current);

  const onConfirmClick = useCallback(() => {
    closeModal();

    const withRecurring = POPUP_RADIO_VALUES.all === radioValue;
    if(!withRecurring) {
      const deleteRecurringData = deleteData.map(item => {
        item.withRecurring = withRecurring;
        return item;
      });
      onConfirm(deleteRecurringData);
    } else {
      onConfirm(deleteData);
    }
  }, [closeModal, radioValue, onConfirm]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'No' }
      confirmLabel={ 'Yes' }
      isOpen={ true }
      message={ 'Are you sure you want to delete task?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    >
      <Wrapper>
        {/* @ts-ignore TS migrate */}
        <RadioGroup
          onChange={ onChange }
          options={ RADIO_BUTTONS }
          value={ radioValue }
        />
      </Wrapper>
    </ConfirmationPopup>

  );
};

import { parse, isValid, isEqual, startOfDay, isAfter } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const isFutureDate = (dueDate: string | null): boolean => {
  if(!dueDate) {
    return false;
  }
  
  const today = startOfDay(new Date());
  const parsedDate = parse(dueDate, YEAR_MONTH_DAY, today);
  
  if(!isValid(parsedDate)) {
    return false;
  }

  const dateEqualToday = isEqual(parsedDate, today);
  
  if(isAfter(parsedDate, today) && !dateEqualToday){
    return true;
  }

  return false;
};

import React, { useEffect, useMemo, useState } from 'react';
import 'chartjs-adapter-date-fns';
import { ChartType, TooltipItem } from 'chart.js';
import { format, getMonth } from 'date-fns';
import { Line } from 'react-chartjs-2';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getMaxValue, getMinValue, getQuarterLabels, getYearLabels } from './utils';

import { PERIOD_FILTER_NAMES } from '@constants/dashboard';
import {
  DATASETS_CONFIG,
  GENERAL_PLUGINS_CONFIG,
  GENERAL_TOOLTIP_CONFIG,
  GENERAL_X_SCALES_CONFIG,
  GENERAL_Y_SCALES_CONFIG, 
  HOVER_CONFIG,
  QUARTER_PERIOD_X_SCALES_CONFIG,
  YEAR_PERIOD_X_SCALES_CONFIG,
} from './chartConfig';

import { Wrapper } from './styles';


export const Chart = observer(() => {

  const { dataset, quarter, year, period } = useStore().DashboardStore;

  const [ monthLabels, setMonthLabels ] = useState<Array<string>>([]);
  const [ chartData, setChartData ] = useState<Array<number>>([]);

  const yScalesMaxValue = useMemo(() => getMaxValue(chartData), [period, quarter, chartData]);

  const yScalesMinValue = useMemo(() => getMinValue(chartData), [period, quarter, chartData]);

  const xScalesConfig = useMemo(() => {
    if(period === PERIOD_FILTER_NAMES.year) {
      return YEAR_PERIOD_X_SCALES_CONFIG;
    }
    return QUARTER_PERIOD_X_SCALES_CONFIG;
  }, [period]);

  const tooltipLabelCallback = useMemo(() => {
    if(period === PERIOD_FILTER_NAMES.year) {
      return (context: TooltipItem<ChartType>) => format(new Date(context.parsed.x), 'LLLL, y');
    }
    return (context: TooltipItem<ChartType>) => format(new Date(context.parsed.x), 'd LLLL');
  }, [period]);

  useEffect(() => {
    if(period === PERIOD_FILTER_NAMES.year) {
      setMonthLabels(getYearLabels(year));
    } else if(period === PERIOD_FILTER_NAMES.quarter) {
      setMonthLabels(getQuarterLabels(year, quarter));
    }
  }, [period, quarter, year]);

  useEffect(() => {
    if(period === PERIOD_FILTER_NAMES.year) {
      const data = monthLabels.map((date: string) => dataset 
        .filter((data) => getMonth(new Date(date)) === getMonth(new Date(data.dataBalance.updated)))
        .reduce((acc, item) => Number(acc) + Number(item.dataBalance.value), 0));
      setChartData(data);
    }
    if(period === PERIOD_FILTER_NAMES.quarter) {
      const data = monthLabels.map((date: string) => dataset
        .filter((data) => date === data.dataBalance.updated)
        .reduce((acc, item) => Number(acc) + Number(item.dataBalance.value), 0));
      setChartData(data);
    }
  }, [dataset, period, quarter]);

  return (
    <Wrapper>
      <Line
        options={ {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          hover: HOVER_CONFIG,
          plugins: {
            ...GENERAL_PLUGINS_CONFIG,
            tooltip: {
              ...GENERAL_TOOLTIP_CONFIG,
              callbacks: {
                title: (context) => `$${context[0].formattedValue}`,
                label: tooltipLabelCallback
              }
            }
          },
          scales: {
            x: {
              ...GENERAL_X_SCALES_CONFIG,
              ...xScalesConfig
            },
            y: {
              ...GENERAL_Y_SCALES_CONFIG,
              min: yScalesMinValue,
              max: yScalesMaxValue,
            }
          }
        } }
        data={ {
          labels: monthLabels,
          datasets: [{
            data: chartData,
            ...DATASETS_CONFIG
          }],
        } }
      />
    </Wrapper>
  );
});
import { buildPath } from '@/shared/utils/buildPath';
import { COMMON_PATH_PART } from '@constants/routes/common';

import { ValueOf } from '@/shared/types/commonTypes';
import { LISTS_TYPES } from '@constants/lists';

export const CONTACT_DETAILS_TAB = {
  accounts: 'accounts',
  activities: 'activities',
  overview: 'overview',
  profile: 'profile',
  toDos: 'toDos',
} as const;

// CONTACT DETAILS PROFILE
const COMMON_PROFILE_LINKS = {
  customFields: 'custom-fields',
  systemDetails: 'system-details'
} as const;

export const BUSINESS_PROFILE_LINKS = {
  ...COMMON_PROFILE_LINKS,
  businessDetails: 'business-details',
  employees: 'employees-contacts'
} as const;

const COMMON_INDIVIDUAL_AND_HOUSEHOLD_PROFILE_LINKS = {
  personalDetails: 'personal-details',
  relatedContacts: 'related-contacts'
} as const;

export const HOUSEHOLD_PROFILE_LINKS = {
  householdDetails: 'household-details',
  ...COMMON_INDIVIDUAL_AND_HOUSEHOLD_PROFILE_LINKS,
  ...COMMON_PROFILE_LINKS,
} as const;

export const INDIVIDUAL_PROFILE_LINKS = {
  individualDetails: 'individual-details',
  ...COMMON_INDIVIDUAL_AND_HOUSEHOLD_PROFILE_LINKS,
  ...COMMON_PROFILE_LINKS,
} as const;

export type ContactDetailsRouteType = ValueOf<typeof CONTACT_DETAILS_TAB>;
export type BusinessProfileLinksType = ValueOf<typeof BUSINESS_PROFILE_LINKS>;
export type HouseHoldProfileLinksType = ValueOf<typeof HOUSEHOLD_PROFILE_LINKS>;
export type IndividualProfileLinksType = ValueOf<typeof INDIVIDUAL_PROFILE_LINKS>;

export const CONTACTS_COMMON_PATH = buildPath([COMMON_PATH_PART, 'contacts']);

export const ALL_CONTACTS_PATH = buildPath([CONTACTS_COMMON_PATH, 'all-contacts']);

export const CONTACT_DETAILS_PATH = buildPath([ALL_CONTACTS_PATH,':id',':contactType',':tab']);

export const LISTS_COMMON_TABS = {
  contacts: 'contacts',
  setup: 'setup',
} as const;

export const LISTS_DYNAMIC_TABS = {
  ...LISTS_COMMON_TABS,
  criteria: 'criteria'
} as const;

export const LISTS_MANUAL_TABS = {
  ...LISTS_COMMON_TABS,
} as const;

export const LISTS_PATH = buildPath([CONTACTS_COMMON_PATH, 'lists']);
export const LISTS_FOLDER_PATH = buildPath([LISTS_PATH, LISTS_TYPES.Folder]);
export const LISTS_FOLDER_ROUTE = buildPath([LISTS_FOLDER_PATH, ':folderId']);
export const LISTS_DYNAMIC_ITEM_PATH = buildPath([LISTS_PATH, LISTS_TYPES.Dynamic]);
export const LISTS_MANUAL_ITEM_PATH = buildPath([LISTS_PATH, LISTS_TYPES.Manual]);

const LISTS_DETAILS_COMMON_PARAMS = [':id', ':subTab'];
export const LISTS_DETAILS_ROUTE = buildPath([LISTS_PATH, ':listType', ...LISTS_DETAILS_COMMON_PARAMS]);
export const LISTS_DYNAMIC_ITEM_ROUTE = buildPath([LISTS_DYNAMIC_ITEM_PATH, ...LISTS_DETAILS_COMMON_PARAMS]);
export const LISTS_MANUAL_ITEM_ROUTE = buildPath([LISTS_MANUAL_ITEM_PATH, ...LISTS_DETAILS_COMMON_PARAMS]);

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@/components/FormBlock';
import HFPasswordInput from '@/modules/HookFormComponents/HFPasswordInput';
import NewPassword from '@components/NewPassword';

import {
  PasswordFormInputNames
} from '@pages/NewUserSettings/components/Tabs/Password/data';
import { LABELS_WIDTH } from '@/pages/NewUserSettings/data';

import { LeftColumnWrapperPassword } from './styles';

export const ChangePassword = () => {

  const { control } = useFormContext();
  
  const newPassword = useWatch({
    control,
    name: PasswordFormInputNames.newPassword
  });

  return (
    <>
      <LeftColumnWrapperPassword>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.passwordLeftLabelWidth }
            label={ 'Old Password' }
          >
            <HFPasswordInput
              control={ control }
              name={ PasswordFormInputNames.oldPassword }
              placeholder={ 'Enter Old Password' }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </LeftColumnWrapperPassword>

      <LeftColumnWrapperPassword>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.passwordLeftLabelWidth }
            label={ 'New Password' }
          >
            <NewPassword
              control={ control }
              name={ PasswordFormInputNames.newPassword }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </LeftColumnWrapperPassword>

      { newPassword &&
      <LeftColumnWrapperPassword>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.passwordLeftLabelWidth }
            label={ 'Confirm Password' }
          >
            <HFPasswordInput
              control={ control }
              name={ PasswordFormInputNames.confirmPassword }
              placeholder={ 'Confirm Password' }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </LeftColumnWrapperPassword>
      }
    </>
  );
};
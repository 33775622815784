import React, { useCallback } from 'react';

import { COMMON_TABS_VALUES } from '@modules/AccountsAndPlansPopup/data/data';

import {
  AccountAndPlansNotesAndHistory,
  ContentWrapperWithTabs
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { PlanFormBasicInformation } from './components';

import { PlanFormProps } from './types';


export const PlanForm = ({
  currentTab,
  tabs,
  onTabChange
}: PlanFormProps) => {

  const renderMainContent = useCallback(() => {
    if (currentTab === COMMON_TABS_VALUES.BASIC_INFORMATION) {
      return <PlanFormBasicInformation />;
    }
  }, [currentTab]);

  const renderRightColumn = useCallback(() => {
    return (
      <AccountAndPlansNotesAndHistory
        deleteConfirmationText={ `The Note will be deleted if you save the changes for the Plan. 
          Are you sure you want to delete note?`
        }
      />
    );
  }, []);

  return (
    <ContentWrapperWithTabs
      currentTab={ currentTab }
      onTabChange={ onTabChange }
      renderMiddleColumnContent={ renderMainContent }
      renderRightColumnContent={ renderRightColumn }
      tabs={ tabs }
    />
  );
};

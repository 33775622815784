import React, { useCallback, useMemo } from 'react';

import { getPathAndDataForNewInitial, getFieldsNames } from './utils';

import Table from '@components/Table';

import { CommonBalanceHistoryRowProps, } from './types';

import {
  BALANCE_HISTORY_TYPES,
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

import { BalanceHistoryRowView, BalanceHistoryRowEdit } from './components';

export const CommonBalanceHistoryRow = ({
  control,
  disable,
  disableDelete,
  disableEdit,
  field,
  fieldPath,
  fields,
  getSiblingPath,
  index,
  isEdit,
  onDelete,
  onEdit,
  onRowClick,
  onSave,
  originalIndex,
  setValue,
}: CommonBalanceHistoryRowProps) => {
  const fieldNames = useMemo(() => getFieldsNames(fieldPath), [fieldPath]);
  
  const onDeleteWrapper = useCallback(async() => {
    const isDeleteInitialType = field.type === BALANCE_HISTORY_TYPES.initialValue;
    const prevSiblingIndex = index - 1;

    if(isDeleteInitialType && fields && prevSiblingIndex >= 0){
      const prevSiblingOriginalIndex = fields[prevSiblingIndex].originalIndex;
      const prevSiblingValue = fields[prevSiblingIndex].data;
      const [ fieldPath, newFieldValue ] = getPathAndDataForNewInitial({
        getSiblingPath,
        originalIndex: prevSiblingOriginalIndex,
        itemToConvert: prevSiblingValue
      });

      await setValue(fieldPath, newFieldValue);
    }
    onDelete(originalIndex);
  },[field, fields, getSiblingPath, index, onDelete, originalIndex, setValue]);

  const onEditWrapper = useCallback(() => onEdit(originalIndex),[onEdit, originalIndex]);

  const onSaveWrapper = useCallback(() => onSave(fieldPath), [onSave, fieldPath]);

  const onClick = useCallback(() => {
    if(!isEdit){
      onRowClick(originalIndex);
    }
  },[onRowClick,originalIndex, isEdit]);

  return (
    <Table.Row
      $preventHover={ true }
      onClick={ onClick }
    >
      {
        !isEdit &&
        <BalanceHistoryRowView
          control={ control }
          disableDelete={ disableDelete }
          disableEdit={ disableEdit }
          field={ field }
          fields={ fields }
          fieldsNames={ fieldNames }
          index={ index }
          onDeleteClick={ onDeleteWrapper }
          onEditClick={ onEditWrapper }
        />
      }
      {
        isEdit &&
        <BalanceHistoryRowEdit
          control={ control }
          disable={ disable }
          field={ field }
          fieldPath={ fieldPath }
          fields={ fields }
          fieldsNames={ fieldNames }
          getSiblingPath={ getSiblingPath }
          index={ index }
          onDeleteClick={ onDeleteWrapper }
          onSaveClick={ onSaveWrapper }
          originalIndex={ originalIndex }
          setValue={ setValue }
        />
      }

    </Table.Row>
  );
};

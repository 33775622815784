import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import FilterButton from '@components/FilterButton';
import PageHeaderWrapper from '@components/BasicLayoutComponents/PageHeaderWrapper';

import { FiltersBtnWrapper } from './styles';

import { PageHeaderProps } from './types';

export const PageHeader = observer(({
  isFiltersBtnActive,
  onFiltersClick
}: PageHeaderProps) => {
  const contactsStore = useStore().ContactsStore;

  const badgeClickHandler = useCallback(() => {
    contactsStore.resetFilters();
  }, [contactsStore]);

  return (
    <PageHeaderWrapper>
      <FiltersBtnWrapper>
        <FilterButton
          badgeClickHandler={ badgeClickHandler }
          badgeCount={ contactsStore.selectedFiltersCount }
          buttonCLickHandler={ onFiltersClick }
          enableFilterMode={ !isFiltersBtnActive }
        />
      </FiltersBtnWrapper>
    </PageHeaderWrapper>
  );
});
import React from 'react';

import { CustomTabsProps } from '@components/CustomTabs/types';

import { StyledRealWorldTab, StyledTab, StyledTabs } from './styles';

export const CustomTabs = (props: CustomTabsProps) => {
  const {
    onChange = () => {},
    value,
    children,
    ...rest
  } = props;

  return (
    <StyledTabs
      onChange={ onChange }
      value={ value }
      { ...rest }
    >
      {children}
    </StyledTabs>
  );
};

CustomTabs.Tab = StyledTab;
CustomTabs.RealWorldTab = StyledRealWorldTab;

import {
  COMMON_FIELDS,
  INDIVIDUAL_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

import { FieldNames } from './types';

export enum ADD_SPOUSE_TYPES  {
  combineHousehold = 'combineHousehold',
  linkedSeparateRecord = 'linkedSeparateRecord',
}

export enum ACTION_TYPES  {
  new = 'new',
  existed = 'existed',
}

export const ACTION_TYPES_BUTTONS = [
  {
    value: ACTION_TYPES.new,
    label: 'Create New Spouse',
  },
  {
    value: ACTION_TYPES.existed,
    label: 'Add Existing Contact',
  },
];

export const SPOUSE_TYPE_OPTIONS = [
  {
    value: ADD_SPOUSE_TYPES.combineHousehold,
    label: 'Combine Household',
  },
  {
    value: ADD_SPOUSE_TYPES.linkedSeparateRecord,
    label: 'Linked Separate Record',
  },
];

export const LEFT_LABEL_WIDTH = 125;
export const RIGHT_LABEL_WIDTH = 157;

export const LEFT_LABEL_WIDTH_WIDE = 160;

export const NEW_CONTACT_HEAD_OF_HOUSEHOLD_VALUE = 'notCurrent';
export const HEAD_OF_HOUSEHOLD_PLACEHOLDER = 'Wait for your Data';

export const ADD_SPOUSE_FIELD_NAMES: FieldNames = {
  addType: 'addType',
  birthdate: INDIVIDUAL_FIELDS.birthdate,
  classification: COMMON_FIELDS.classification,
  firstName: INDIVIDUAL_FIELDS.firstName,
  gender: INDIVIDUAL_FIELDS.gender,
  headOfHouseHoldId: 'headOfHouseHoldId',
  lastName: INDIVIDUAL_FIELDS.lastName,
  middleName: INDIVIDUAL_FIELDS.middleName,
  nickname: INDIVIDUAL_FIELDS.nickname,
  officePrimaryAdvisor: COMMON_FIELDS.officePrimaryAdvisor,
  rating: COMMON_FIELDS.rating,
  sourceCategory: COMMON_FIELDS.sourceCategory,
  sourceId: COMMON_FIELDS.sourceId,
  spouseId: 'spouseId',
  spouseType: 'spouseType',
  status: COMMON_FIELDS.status,
};

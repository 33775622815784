import * as yup from 'yup';

import { validationMessages } from '@constants/validationMessages';
import {
  facebookProfilePattern, linkedInProfilePattern,
  simpleEmailPattern,
  twitterProfilePattern
} from '@constants/validationPatterns';
import { isPhoneValid } from '@modules/HookFormComponents/HFPhoneField/validation';

import {
  CONTACTS_DATA_FIELD_NAMES,
  CONTACTS_EMAIL_IDS,
  CONTACTS_LINK_IDS,
  CONTACTS_PHONE_IDS, FIELD_IDS
} from '@constants/contactsData';

import {
  COMMON_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';


export const CONTACTS_DETAILS_SCHEMA = {
  [COMMON_FIELDS.contacts]: yup.array().of(yup.object().shape({
    [CONTACTS_DATA_FIELD_NAMES.value]: yup.string()
      .nullable()
      .notRequired()
      .test(
        'invalid',
        'contacts validation with value',
        function(value, context){
          if(!value){
            return true;
          }
          const fieldId = context.parent?.fieldId;

          if(fieldId && CONTACTS_LINK_IDS.includes(fieldId)){
            if(fieldId === FIELD_IDS.FACEBOOK_LINK_ID && !facebookProfilePattern.test(value)){
              return this.createError({ message: validationMessages.facebook });
            }

            if(fieldId === FIELD_IDS.TWITTER_LINK_ID && !twitterProfilePattern.test(value)){
              return this.createError({ message: validationMessages.twitter });
            }

            if(fieldId === FIELD_IDS.LINKEDIN_LINK_ID && !linkedInProfilePattern.test(value)){
              return this.createError({ message: validationMessages.linkedin });
            }
          }

          if(fieldId && CONTACTS_PHONE_IDS.includes(fieldId)){
            // TODO by default the phone number must be set NULL if only the country code is entered
            if(!isPhoneValid(value)){
              return this.createError({ message:  validationMessages.invalidPhone });
            }
          }

          if(fieldId && CONTACTS_EMAIL_IDS.includes(fieldId)){
            if(!simpleEmailPattern.test(value)){
              return this.createError({ message:  validationMessages.invalidEmail });
            }
          }
          return true;
        }
      ),
    [CONTACTS_DATA_FIELD_NAMES.street]: yup.string()
      .nullable()
      .notRequired(),
    [CONTACTS_DATA_FIELD_NAMES.street2]: yup.string()
      .nullable()
      .notRequired(),
    [CONTACTS_DATA_FIELD_NAMES.city]: yup.string()
      .nullable()
      .notRequired(),
    [CONTACTS_DATA_FIELD_NAMES.state]: yup.string()
      .nullable()
      .notRequired(),
    [CONTACTS_DATA_FIELD_NAMES.zip]: yup.string()
      .nullable()
      .notRequired(),
  })),
};

export const OWNER_SCHEMA = {
  [COMMON_FIELDS.contacts]: yup.array().of(yup.object().shape({
    [CONTACTS_DATA_FIELD_NAMES.ownerId]: yup.number()
      .nullable()
      .notRequired()
      .transform((value) => Number.isNaN(value) ? null : value)
      .test(
        'invalid',
        validationMessages.required,
        function(value, context) {
          const fieldValue = context.parent.value;
          if(fieldValue && !value && CONTACTS_DATA_FIELD_NAMES.ownerId in context.parent) {
            return false;
          }
          return true;
        })
  })),
};
import { getQuarter, getYear, setYear } from 'date-fns';
import { makeAutoObservable, flow, action, reaction, IReactionDisposer } from 'mobx';

import { Store } from '@store';
import {
  getDashboardForContactsGraph,
  getDashboardTodayActivityTask,
  rebaseDashboardCache
} from '@services/api/dashboard/dashboard';

import { dashboardDataNormalizer, getAdviserItemsParams, getPeriodFilters, getSummary } from './utils';

import {
  DASHBOARD_ACCOUNT_AND_PLANS_TYPES,
  DASHBOARD_LINE_TABS, PERIOD_FILTER_NAMES,
} from '@constants/dashboard';

import {
  DashboardAccountAndPlans,
  DashboardActivityAndTaskCountResponse,
  DashboardGraphResponse,
  Dataset,
  Filters,
  Period,
  TabsBalanceData
} from '@/shared/types/dashboard';
import { ValueLabelObj } from '@/shared/types/commonTypes';

const FIRST_QUARTER_NUMBER = 1;
const LAST_QUARTER_NUMBER = 4;

export default class DashboardStore {
  year: Date = new Date();
  quarter: number = getQuarter(new Date());

  activityCount: number = 0;
  annuities: Array<Dataset> = [];
  aum: Array<Dataset> = [];
  coreStore: Store;
  currentTab: DashboardAccountAndPlans = DASHBOARD_LINE_TABS[0].value;
  dataset: Array<Dataset> = [];
  filters: Filters = {};
  isFetching: boolean = false;
  isPageActive: boolean = false;
  lifeInsurance: Array<Dataset> = [];
  period: Period = PERIOD_FILTER_NAMES.year;
  primaryAdvisors: Array<ValueLabelObj> = [];
  range: string | Date = this.year;
  tabsBalanceData: TabsBalanceData = {} as TabsBalanceData;
  taskCount: number = 0;

  onFilterChangeReaction: IReactionDisposer;
  onPeriodChangeReaction: IReactionDisposer;
  onQuarterChangeReaction: IReactionDisposer;
  onTabChangeReaction: IReactionDisposer;
  onYearChangeReaction: IReactionDisposer;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getActivityAndTaskCount: flow.bound,
      init: flow.bound,
      reset: action.bound,
      setCurrentTab: action.bound,
      setFilters: action.bound,
      setPeriod: action.bound,
      setRange: action.bound,
    });

    this.coreStore = coreStore;

    this.onPeriodChangeReaction = this.createOnPeriodChangeReaction();
    this.onYearChangeReaction = this.createOnYearChangeReaction();
    this.onQuarterChangeReaction = this.createOnQuarterChangeReaction();
    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onTabChangeReaction = this.createOnTabChangeReaction();
  }

  *init() {
    this.isPageActive = true;
    yield this.getActivityAndTaskCount();
  }

  *getActivityAndTaskCount() {
    if(!this.isPageActive){
      return;
    }
    this.setIsFetching(true);

    try {
      yield rebaseDashboardCache();

      const resp: DashboardActivityAndTaskCountResponse = yield getDashboardTodayActivityTask();

      const profileId = this.coreStore.SettingsStore.profile.id;
      const { activityCount, taskCount, primaryAdvisers } = dashboardDataNormalizer(resp.data.data, profileId);

      this.activityCount = activityCount;
      this.taskCount = taskCount;
      this.primaryAdvisors = primaryAdvisers;

      yield this.getDashboard();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *getDashboard() {
    this.setIsFetching(true);

    try {
      const resp: DashboardGraphResponse = yield getDashboardForContactsGraph({
        ...getPeriodFilters(this.period, this.year, this.quarter),
        ...getAdviserItemsParams(this.filters.adviserId)
      });
      const data = resp.data.data;

      this.annuities = data[DASHBOARD_ACCOUNT_AND_PLANS_TYPES.annuities];
      this.aum = data[DASHBOARD_ACCOUNT_AND_PLANS_TYPES.aum];
      this.lifeInsurance = data[DASHBOARD_ACCOUNT_AND_PLANS_TYPES.lifeInsurance];

      this.setDataset();
      this.tabsBalanceData = {
        [DASHBOARD_ACCOUNT_AND_PLANS_TYPES.annuities]: getSummary(this.annuities),
        [DASHBOARD_ACCOUNT_AND_PLANS_TYPES.aum]: getSummary(this.aum),
        [DASHBOARD_ACCOUNT_AND_PLANS_TYPES.lifeInsurance]: getSummary(this.lifeInsurance),
      };
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  createOnFilterChangeReaction() {
    return reaction(
      () => this.filters,
      () => {
        if (this.isPageActive) {
          this.getDashboard();
        }
      },
    );
  }

  createOnTabChangeReaction() {
    return reaction(
      () => this.currentTab,
      () => {
        this.setDataset();
      },
    );
  }

  createOnPeriodChangeReaction() {
    return reaction(
      () => this.period,
      () => {
        this.getDashboard();
      },
    );
  }

  createOnQuarterChangeReaction() {
    return reaction(
      () => this.quarter,
      () => {
        this.getDashboard();
      },
    );
  }

  createOnYearChangeReaction() {
    return reaction(
      () => this.year,
      () => {
        if (this.isPageActive) {
          this.getDashboard();
        }
      },
    );
  }
  
  setDataset() {
    if(this.currentTab === DASHBOARD_LINE_TABS[0].value) {
      this.dataset = this.annuities;
    }
    if(this.currentTab === DASHBOARD_LINE_TABS[1].value) {
      this.dataset = this.aum;
    }
    if(this.currentTab === DASHBOARD_LINE_TABS[2].value) {
      this.dataset = this.lifeInsurance;
    }
  }

  setRange(direction: number) {
    if(this.period === PERIOD_FILTER_NAMES.year) {
      this.year = setYear(this.year, getYear(this.year) + direction);
    } else {
      if(this.quarter === FIRST_QUARTER_NUMBER && direction < 0) {
        this.quarter = LAST_QUARTER_NUMBER;
        this.year = setYear(this.year, getYear(this.year) + direction);
      } else if(this.quarter === LAST_QUARTER_NUMBER && direction > 0) {
        this.quarter = FIRST_QUARTER_NUMBER;
        this.year = setYear(this.year, getYear(this.year) + direction);
      } else {
        this.quarter = this.quarter + direction;
      }
    }
  }

  setPeriod(period: Period) {
    this.period = period;
  }

  setIsFetching(state: boolean) {
    this.isFetching = state;
  }

  setCurrentTab(newTab: DashboardAccountAndPlans) {
    this.currentTab = newTab;
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  reset() {
    this.isPageActive = false;
    this.setCurrentTab(DASHBOARD_LINE_TABS[0].value);

    this.activityCount = 0;
    this.taskCount = 0;

    this.annuities = [];
    this.aum = [];
    this.dataset = [];
    this.filters = {};
    this.lifeInsurance = [];
    this.primaryAdvisors = [];
    this.tabsBalanceData = {} as TabsBalanceData;

    this.period = PERIOD_FILTER_NAMES.year;
    this.quarter = getQuarter(new Date());
    this.range = this.year;
    this.year = new Date();
  }
}

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import {
  DEFAULT_SELECTED,
  PLAN_DEFAULT_SELECTED,
} from '@modules/AccountsAndPlansPopup/data/data';

import {
  AccountType,
  BalanceHistoryType,
  CommonType,
  GeneralBasicInformationType,
  PlanBasicInformationType,
  PlanType,
  PolicyType,
  StandingInstructionsType,
} from '@modules/AccountsAndPlansPopup/types';

const COMMON: Omit<CommonType, 'accountType'> = {
  name: '',
  contactId: null,
  accountPlansNotes: []
};

const BALANCE_HISTORY: BalanceHistoryType = {
  balanceHistory: []
};

const STANDING_INSTRUCTIONS: StandingInstructionsType = {
  standingInstructions: [],
  standingInstructionsNotes: ''
};

const getGeneralBasicInformation = (accountType: ACCOUNTS_AND_PLANS_TYPES):GeneralBasicInformationType => ({
  accountNumber: '',
  applicationDate: '',
  beneficiaries: [],
  deadlineDate: '',
  deliveryDate: '',
  // discretionary: '',
  effectiveDate: '',
  heldAwayStatus: DEFAULT_SELECTED.heldAwayStatus,
  institutionName: '',
  managedStatus: DEFAULT_SELECTED.managedStatus,
  productModel: '',
  registrationType: DEFAULT_SELECTED.registrationType,
  reviewDate: '',
  // eslint-disable-next-line no-restricted-globals
  status: DEFAULT_SELECTED.status,
  subTypeAnnuities: '',
  subTypeCashEquivalent: '',
  submittedDate: '',
  taxQualification: DEFAULT_SELECTED.taxQualification,
  type: (
    accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT
      ? DEFAULT_SELECTED.typeForAccount
      : DEFAULT_SELECTED.typeForPolicy
  ),
});

const POLICY_BASIC_INFORMATION: PlanBasicInformationType = {
  feeStatus: PLAN_DEFAULT_SELECTED.feeStatus,
  feeAmount: '',
  feeFrequency: PLAN_DEFAULT_SELECTED.feeFrequency,
  completionDate: '',
  basicPlanDescription: '',
  startDate: '',
};

const ACCOUNT_DEFAULT_STATE: AccountType = {
  ...COMMON,
  ...BALANCE_HISTORY,
  ...STANDING_INSTRUCTIONS,
  ...getGeneralBasicInformation(ACCOUNTS_AND_PLANS_TYPES.ACCOUNT),
  accountType: ACCOUNTS_AND_PLANS_TYPES.ACCOUNT,
};

const PLAN_DEFAULT_STATE: PlanType = {
  ...COMMON,
  ...POLICY_BASIC_INFORMATION,
  accountType: ACCOUNTS_AND_PLANS_TYPES.PLAN,
};

const POLICY_DEFAULT_STATE: PolicyType = {
  ...COMMON,
  ...BALANCE_HISTORY,
  ...STANDING_INSTRUCTIONS,
  ...getGeneralBasicInformation(ACCOUNTS_AND_PLANS_TYPES.POLICY),
  accountType: ACCOUNTS_AND_PLANS_TYPES.POLICY,
};

export const getDefaultState = (accountType: ACCOUNTS_AND_PLANS_TYPES) => {
  if(accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT){
    return ACCOUNT_DEFAULT_STATE;
  }
  if(accountType === ACCOUNTS_AND_PLANS_TYPES.PLAN){
    return PLAN_DEFAULT_STATE;
  }

  if(accountType === ACCOUNTS_AND_PLANS_TYPES.POLICY){
    return POLICY_DEFAULT_STATE;
  }
};

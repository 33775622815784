import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import NotificationsStore from '@services/store/notificationsStore';
import { ENTITY_NAMES } from '@constants/common';
import { Props } from '@/shared/utils/NotificationHelper/types';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { convertArrayOfStringToHtmlLines } from '@/shared/utils/NotificationHelper/generateMessage';

type CustomListNotifierProps = Pick<
  Props,
  'status' | 'uniqueKey' | 'autoHideDuration'
>

export class ListsNotificationHelper extends NotificationHelper{
  constructor(notificationsStore: NotificationsStore) {
    super(
      notificationsStore,
      ENTITY_NAMES.list
    );
  }

  clone({
    autoHideDuration,
    uniqueKey,
    status,
  }: CustomListNotifierProps) {
    this.notificationsStore.showNotification({
      variant: status,
      autoHideDuration,
      key: `${status} list clone ${uniqueKey || ''}`,
      message: status === NOTIFICATION_TYPES.success
        ? 'The List has been cloned.'
        : convertArrayOfStringToHtmlLines([
          'The List cloning has failed.',
          'Please try again'
        ])
    });
  }

  addContactsToList({ status }: Pick<CustomListNotifierProps, 'status'>){
    this.update({ status });
  }

  deleteFolder({
    autoHideDuration,
    uniqueKey,
    status,
  }: CustomListNotifierProps) {
    this.notificationsStore.showNotification({
      variant: status,
      autoHideDuration,
      key: `${status} folder delete ${uniqueKey || ''}`,
      message: status === NOTIFICATION_TYPES.success
        ? 'The Folder has been deleted.'
        : convertArrayOfStringToHtmlLines([
          'The deleting of Folder has failed.',
          'Please try again.'
        ])
    });
  }

  moveToFolder({
    autoHideDuration,
    uniqueKey,
    status,
  }: CustomListNotifierProps) {
    this.notificationsStore.showNotification({
      variant: status,
      autoHideDuration,
      key: `${status} move folder ${uniqueKey || ''}`,
      message: status === NOTIFICATION_TYPES.success
        ? 'The List has been moved to the folder.'
        : convertArrayOfStringToHtmlLines([
          'The move of List has failed.',
          'Please try again.'
        ])
    });
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useStore } from '@store';
import { useToggle } from '@customHooks';

import { omit } from 'lodash';

import { NotificationBar } from '@components/NotificationBar';
import {
  ListSetupConfirmationPopup
} from '@pages/NewContacts/subpages/Lists/subpages/ListsDetails/components/components/ListSetup/components';

import { LIST_SETUP_FIELDS_NAMES } from '@constants/lists';

import { SaveListData } from '@/shared/types/lists';
import { MODAL_TYPE } from '@constants/modalTypes';

const TYPE_CHANGE_MESSAGE = `Are you sure you want to change the list type? 
  Changing the list type may change who is currently displayed in the list`;
const SPLIT_MESSAGE_ON = 'Household Contacts will be split into Household Members. Are you sure you want to proceed?';
const SPLIT_MESSAGE_OFF = 'Household Members will be replaced by their Households. Are you sure you want to proceed?';

export const FormChangeWatcher = observer(() => {
  const [isTypeConfirmedByUser, setIsTypeConfirmedByUser] = useToggle(false);
  const [isSplitHouseHoldConfirmedByUser, setIsSplitHouseHoldConfirmed] = useToggle(false);

  const listSetupStore = useStore().ListsStore.ListSetupStore;
  const modalStore= useStore().ModalStore;
  const {
    isSaveBarOpen,
    toggleSaveBarState,
    resetFormToLastSavedState,
    formState: lastSavedFormState,
    isLoad,
  } = listSetupStore;

  const {
    control,
    formState,
    handleSubmit,
  } = useFormContext<SaveListData>();

  const resetNotificationFlags = useCallback(() => {
    setIsSplitHouseHoldConfirmed(false);
    setIsTypeConfirmedByUser(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onSaveWrapper = useCallback(() => {
    handleSubmit((data) => {
      resetNotificationFlags();
      listSetupStore.onSave(data);
    })();
  }, [handleSubmit, listSetupStore, resetNotificationFlags]);

  const onCancelWrapper = useCallback(() => {
    resetNotificationFlags();
    listSetupStore.onCancel();
  }, [listSetupStore, resetNotificationFlags]);

  const allFields = useWatch({ control });

  useEffect(() => {
    const currentValue = allFields[LIST_SETUP_FIELDS_NAMES.typeList];
    const savedValue = lastSavedFormState[LIST_SETUP_FIELDS_NAMES.typeList];

    if(!isTypeConfirmedByUser && !isLoad && currentValue !== savedValue){
      modalStore.openModal({
        component: ListSetupConfirmationPopup,
        modalProps: {
          message: TYPE_CHANGE_MESSAGE,
          onCancel: () => resetFormToLastSavedState({ field: LIST_SETUP_FIELDS_NAMES.typeList }),
          onConfirm: () => {
            setIsTypeConfirmedByUser(true);
            listSetupStore.toggleSaveBarState(true);
          }
        },
        modalType: MODAL_TYPE.CHANGE_LIST_TYPE_CONFIRMATION,
      });
    }
  }, [
    allFields, formState.dirtyFields, isLoad, isTypeConfirmedByUser, lastSavedFormState,
    listSetupStore, modalStore, resetFormToLastSavedState, setIsTypeConfirmedByUser
  ]);

  useEffect(() => {
    const currentValue = allFields[LIST_SETUP_FIELDS_NAMES.splitHousehold];
    const savedValue = lastSavedFormState[LIST_SETUP_FIELDS_NAMES.splitHousehold];

    if(!isSplitHouseHoldConfirmedByUser &&!isLoad && currentValue !== savedValue) {
      modalStore.openModal({
        component: ListSetupConfirmationPopup,
        modalProps: {
          message: currentValue ? SPLIT_MESSAGE_ON : SPLIT_MESSAGE_OFF,
          onCancel: () => resetFormToLastSavedState({ field: LIST_SETUP_FIELDS_NAMES.splitHousehold }),
          onConfirm: () => {
            setIsSplitHouseHoldConfirmed(true);
            listSetupStore.toggleSaveBarState(true);
          }
        },
        modalType: MODAL_TYPE.CHANGE_LIST_HOUSEHOLD_SPLIT,
      });
    }
  }, [
    allFields, formState.dirtyFields, isLoad, isSplitHouseHoldConfirmedByUser, lastSavedFormState,
    listSetupStore, modalStore, resetFormToLastSavedState, setIsSplitHouseHoldConfirmed
  ]);

  useEffect(() => {
    const fieldsWithoutTypeAndHouseholdSplit = omit(
      formState.dirtyFields,
      [LIST_SETUP_FIELDS_NAMES.splitHousehold, LIST_SETUP_FIELDS_NAMES.typeList]
    );

    if (Object.keys(fieldsWithoutTypeAndHouseholdSplit).length > 0) {
      listSetupStore.toggleSaveBarState(true);
    }

  },[allFields, formState.dirtyFields, listSetupStore, modalStore, resetFormToLastSavedState, toggleSaveBarState]);

  useEffect(() => {
    return () => {
      resetNotificationFlags();
    };
  }, [resetNotificationFlags]);

  return (
    <NotificationBar
      isOpen={ isSaveBarOpen }
      onCancelButtonClick={ onCancelWrapper }
      onConfirmButtonClick={ onSaveWrapper }
    />
  );
});

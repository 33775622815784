import React from 'react';

import { Bookmark, BookmarkFilled } from '@/assets';

import { IconWrapper } from './styles';

import { BookmarkIconProps } from './types';

export const BookmarkIcon = ({
  isActive,
  onStateChange,
  boxProps
}: BookmarkIconProps) => {
  const Icon = isActive ? BookmarkFilled : Bookmark;

  const onClick = () => {
    onStateChange(!isActive);
  };
  return (
    <IconWrapper
      onClick={ onClick }
      { ...boxProps }
    >
      <Icon/>
    </IconWrapper>
  );
};

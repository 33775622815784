export const MODAL_TYPE = {
  ACCOUNTS_AND_PLANS_MODAL: 'ACCOUNTS_AND_PLANS_MODAL',
  ADD_CALENDAR_ACTIVITY: 'ADD_CALENDAR_ACTIVITY',
  ADD_CLIENT_OPPORTUNITY: 'ADD_CLIENT_OPPORTUNITY',
  ADD_CONTACT_CUSTOM_FIELD: 'ADD_CONTACT_CUSTOM_FIELD',
  ADD_CONTACT_CUSTOM_FIELD_TO_CONTACT: 'ADD_CONTACT_CUSTOM_FIELD_TO_CONTACT',
  ADD_CONTACT_MODAL: 'ADD_CONTACT_MODAL',
  ADD_CONTACT_TO_LIST: 'ADD_CONTACT_TO_LIST',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  ADD_LISTS_FOLDER: 'ADD_LISTS_FOLDER',
  ADD_LISTS_ITEM: 'ADD_LISTS_ITEM',
  ADD_RELATED_CONTACT: 'ADD_RELATED_CONTACT',
  ADD_SALES_ACTIVE_CYCLE: 'ADD_SALES_ACTIVE_CYCLE',
  ADD_SPOUSE: 'ADD_SPOUSE',
  ADD_USER: 'ADD_USER',
  ADD_USER_CUSTOM_FIELD : 'ADD_USER_CUSTOM_FIELD',
  BLOCK_LINK_TRANSITION_MODAL: 'BLOCK_LINK_TRANSITION_MODAL',
  CHANGE_LIST_HOUSEHOLD_SPLIT: 'CHANGE_LIST_TYPE',
  CHANGE_LIST_TYPE_CONFIRMATION: 'CHANGE_LIST_TYPE_CONFIRMATION',
  CONFIRM_BUSINESS_EMPLOYEE_PRIMARY_CONTACT_CHANGE: 'CONFIRM_BUSINESS_EMPLOYEE_PRIMARY_CONTACT_CHANGE',
  CONFIRM_CANCEL_MODAL: 'CONFIRM_CANCEL_MODAL',
  CONFIRM_RELATED_CONTACT_DELETE: 'CONFIRM_RELATED_CONTACT_DELETE',
  CONFIRM_RELATED_CONTACT_SPOUSE_CHANGE: 'CONFIRM_RELATED_CONTACT_SPOUSE_CHANGE',
  CONTACT_LIMIT_ACCESS: 'CONTACT_LIMIT_ACCESS',
  CONVERT_TO_INDIVIDUALS: 'CONVERT_TO_INDIVIDUALS',
  DELETE_ACCOUNT_AND_PLANS_CONFIRMATION: 'DELETE_ACCOUNT_AND_PLANS_CONFIRMATION',
  DELETE_ACTIVITY_CONFIRMATION: 'DELETE_ACTIVITY_CONFIRMATION',
  DELETE_CONTACTS_POPUP: 'DELETE_CONTACTS_POPUP',
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD_FROM_CONTACT: 'DELETE_CUSTOM_FIELD_FROM_CONTACT',
  DELETE_EMPLOYEE_CONFIRMATION: 'DELETE_EMPLOYEE_CONFIRMATION',
  DELETE_LIST_FOLDER: 'DELETE_LIST_FOLDER',
  DELETE_NOTES_FROM_OVERVIEW: 'DELETE_NOTES_FROM_OVERVIEW',
  DELETE_SALES_ACTIVE_CYCLE : 'DELETE_SALES_ACTIVE_CYCLE',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_CUSTOM_FIELD : 'DELETE_USER_CUSTOM_FIELD',
  EDIT_ACTIVITY_CONFIRMATION: 'EDIT_ACTIVITY_CONFIRMATION',
  EXPORT_LIST_CONTACTS: 'EXPORT_LIST_CONTACTS',
  KANBAN_IS_UPDATED: 'KANBAN_IS_UPDATED',
  LOGOUT_POPUP: 'LOGOUT_POPUP',
  MASS_DELETE_POPUP: 'MASS_DELETE_POPUP',
  MOVE_LIST_TO_ANOTHER_FOLDER: 'MOVE_LIST_TO_ANOTHER_FOLDER',
  REMOVE_CLIENT_OPPORTUNITY : 'REMOVE_CLIENT_OPPORTUNITY',
  REMOVE_CONTACTS_FROM_LIST: 'REMOVE_CONTACTS_FROM_LIST',
  REMOVE_LISTS: 'REMOVE_LISTS',
  SPLIT_TO_INDIVIDUALS: 'SPLIT_TO_INDIVIDUALS',
  TODO_DELETE_CONFIRMATION: 'TODO_DELETE_CONFIRMATION',
  TODO_EDIT_CONFIRMATION: 'TODO_EDIT_CONFIRMATION',
  TODO_TASK_MODAL: 'TODO_TASK_MODAL',
  USER_CUSTOM_FIELD_ALERT : 'USER_CUSTOM_FIELD_ALERT',
} as const;

export const MODAL_TYPES_TO_HIDE = [];

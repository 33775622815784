import { COLORS } from '@constants/colors';

export const VALUES_STATUS = {
  increase: 'increase',
  decrease: 'decrease'
} as const;

export const VALUE_STATUS_COLOR_MAP = {
  [VALUES_STATUS.increase]: COLORS.green,
  [VALUES_STATUS.decrease]: COLORS.red
};
import React, { useEffect, } from 'react';
import { observer } from 'mobx-react';
import { FormProvider,useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from '@components/Modal';
import Preloader from '@components/Preloader';

import { Actions, BtnPanel, Content } from './components';

import { Spacer } from './styles';

import { validationSchema } from './validationScheme';

import { AccountsAndPlansPopupProps, FormFieldsValues } from './types';

import { useAccountAndPlansPopupLocalStore } from './store';

export const AccountsAndPlansPopup = observer(({
  closeModal,
  modalProps
}: AccountsAndPlansPopupProps) => {
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const {  isLoad, notesUtilsStore, reset, init } = accountsAndPlansLocalStore;

  const { id, outerOnSubmit, linkedContact } = modalProps || {};
  const header = `${id ? 'Edit' : 'Add'} Accounts & Plans`;

  const methods:UseFormReturn<FormFieldsValues> = useForm<FormFieldsValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    init({
      id,
      linkedContact,
      closeModal,
      outerOnSubmit,
      useFormMethods: methods
    });
    return () => reset();
  }, [init, id, linkedContact, methods, outerOnSubmit]);

  return (
    <FormProvider { ...methods }>
      <Modal
        isCloseDisabled={ isLoad }
        onClose={ closeModal }
        title={ header }
      >
        <Modal.TopPanel>
          <BtnPanel/>
        </Modal.TopPanel>
        <Modal.Content
          $disabled={ Boolean(notesUtilsStore.alertType) }>
          {
            isLoad
              ? (
                <Spacer>
                  <Preloader isWithoutFullPageWrapper={ true }/>
                </Spacer>
              )
              : (
                <Content/>
              )
          }
        </Modal.Content>
        <Modal.Footer>
          <Actions/>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});


import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { useStore } from '@store';

import { getContactDetailsLink } from '@/shared/utils/contactRoutesUtils';

import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';
import { AppPage } from '@modules/AppPage';
import {
  BackButtonContent,
  ContactDetailsAccountsAndPlans,
  ContactDetailsActivities,
  ContactDetailsOverview,
  ContactDetailsPageHeader,
  ContactDetailsProfile,
  ContactDetailsToDos,
} from './components';

import { ContactDetailsParamsType } from './types';

export const ContactsDetails = observer(() => {
  const routerStore = useStore().RouterStore;
  const contactDetailsStore = useStore().ContactDetailsStore;
  const { isLoad } = contactDetailsStore;

  // TODO params validation
  const match = useRouteMatch<ContactDetailsParamsType>();
  const { params: { id, tab, contactType } } = match;

  const onBackClick = useCallback(() => {
    routerStore.browserHistory.goBack();
  }, [routerStore]);

  useEffect(() => {
    return () => contactDetailsStore.resetState();
  }, [contactDetailsStore]);

  return (
    <AppPage>
      <AppPage.AppHeader $isSticky={ true }>
        <AppPage.AppBackButton onClick={ onBackClick }>
          <BackButtonContent />
        </AppPage.AppBackButton>
      </AppPage.AppHeader>
      <Surface>
        {
          isLoad && <Preloader />
        }
        <ContactDetailsPageHeader
          contactId={ id }
          contactType={ contactType }
          currentTab={ tab }
        />
        <Switch>
          <Route path={ getContactDetailsLink(id, contactType).accounts() }>
            <ContactDetailsAccountsAndPlans
              contactId={ id }
            />
          </Route>
          <Route path={ getContactDetailsLink(id, contactType).activities() }>
            <ContactDetailsActivities
              contactId={ id }
            />
          </Route>
          <Route path={ getContactDetailsLink(id, contactType).overview() }>
            <ContactDetailsOverview
              contactId={ id }
            />
          </Route>
          <Route path={ `${getContactDetailsLink(id, contactType).profile()}/:subTab` }>
            <ContactDetailsProfile
              contactId={ id }
              contactType={ contactType }
            />
          </Route>
          <Route path={ getContactDetailsLink(id, contactType).toDos() }>
            <ContactDetailsToDos
              contactId={ id }
            />
          </Route>
        </Switch>
      </Surface>
    </AppPage>
  );
});

import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useToggle } from '@/shared/customHooks';

import { StyledPasswordTextField } from './styles';

import { PasswordInputProps } from './types';

export const PasswordInput = ({
  error,
  helperText = '',
  onChange,
  placeholder = '',
  showError = true,
  TextFieldProps,
  value,
}: PasswordInputProps) => {

  const [ isShowPassword, setShowPassword ] = useToggle(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const actionHandler = useCallback((event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    document.addEventListener('click', (event: Event) => {
      const target = event.target as Node;
      if (buttonRef.current?.contains(target)) {
        setShowPassword();
      } else if (target !== inputRef.current) {
        setShowPassword(false);
      }
    });
  }, [ setShowPassword ]);

  const getEndAdornment = useMemo(() => {
    return (
      <InputAdornment position='end'>
        <IconButton
          edge='end'
          ref={ buttonRef }
        >
          { isShowPassword ? <Visibility /> : <VisibilityOff /> }
        </IconButton>
      </InputAdornment>
    );
  }, [ isShowPassword ]);

  return (
    <StyledPasswordTextField
      $showError = { showError }
      InputProps={ {
        endAdornment: getEndAdornment
      } }
      autoComplete={ 'new-password' }
      error={ error }
      helperText={ helperText }
      inputRef={ inputRef }
      onChange={ onChange }
      onCopy={ actionHandler }
      onCut={ actionHandler }
      onPaste={ actionHandler }
      placeholder={ placeholder }
      type={ isShowPassword ? 'text' : 'password' }
      value={ value }
      variant='outlined'
      { ...TextFieldProps }
    />
  );
};
import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';

import { Store } from '@store';
import {
  ContactsByType,
  ListDataDetailsType,
  ListOwnerData,
  ListsContactsGroupTypes,
  ListSetupResponse,
  MailChimpList,
  SaveListData,
  SyncMailChimpParams,
} from '@/shared/types/lists';
import { FormState, UseFormMethods } from './types';

import { LISTS_TYPES } from '@constants/lists';
import {
  deleteContactsFromListByGroupType,
  getListSetup,
  saveList,
  syncMailChimp,
  getMailchimpLists,
  disconnectMailChimp
} from '@services/api/lists/lists';
import { getListContactsSetupFilterParams } from '@/shared/utils/getListContactsSetupFilterParams';
import { getListItemLink } from '@/shared/utils/listsRoutesUtils';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { BackendCommonResponse, IdType } from '@/shared/types/commonTypes';


class ListSetupStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  contactsByType:ContactsByType | null = null;
  coreStore: Store;
  currentListDetails: ListDataDetailsType | null = null;
  formState: FormState = {} as FormState;
  initListOwner: ListOwnerData | null = null;
  isLoad: boolean = false;
  isPageActive: boolean = false;
  isSaveBarOpen: boolean = false;
  listCreationDate: string = '';
  listId: IdType | null = null;
  mailChimpListData: MailChimpList | null = null;
  mailChimpLists: Array<MailChimpList> | null = null;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      disconnectMailChimp: flow.bound,
      getListSetup: flow,
      getMailchimpLists: flow.bound,
      init: flow,
      onSave: flow,
      removeContactsByGroupType: flow,
      syncMailChimp: flow.bound,
    });

    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.list
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *getListSetup() {
    if(this.isPageActive){
      try {
        const start = async () => {
          const { data: { data:responseData } }: ListSetupResponse = await getListSetup({
            id: this.listId
          });

          this.coreStore.ListsStore.setListName(responseData.listData.name);

          this.contactsByType = responseData.contactData;
          this.initListOwner = responseData.listOwnerData;
          this.listCreationDate = responseData.createdDate;
          this.currentListDetails = responseData?.listData;

          this.mailChimpListData = {
            mailChimpListId: responseData.mailChimpListId,
            mailchimpListName: responseData.mailchimpListName,
            mailChimpLastSyncDate: responseData.mailChimpLastSyncDate,
            syncWithMailChimp: responseData.syncWithMailChimp,
            syncWithMailChimpStatus: responseData.syncWithMailChimpStatus
          };
          
          let formState = {
            description: responseData.description,
            filter: responseData.filter,
            id: responseData.id,
            listOwner: responseData.listOwner,
            name: responseData.name,
            parentId: responseData.parentId,
            splitHousehold: responseData.splitHousehold,
            typeField: responseData.typeField,
            typeList: responseData.typeList,
          } as FormState;

          this.formState = formState;
          this.useFormMethods.reset(formState);
        };

        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: start,
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
        });

      } catch (error) {
        console.log(error);
      }
    }
  }

  *init(listId: string, useFormMethods: UseFormMethods){
    try {
      this.isPageActive = true;
      this.listId = listId;
      this.isLoad = true;
      this.useFormMethods = useFormMethods;

      yield this.getListSetup();
      yield this.getMailchimpLists();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  toggleSaveBarState = (forceState?: boolean) => {
    this.isSaveBarOpen = forceState || !this.isSaveBarOpen;
  };

  onCancel = () => {
    this.useFormMethods.reset(this.formState);
    this.toggleSaveBarState(false);
  };

  onAlertConfirm = () => {
    this.toggleSaveBarState(true);
  };

  *onSave(data: SaveListData) {
    const isTypeChanged = data.typeList !== this.formState.typeList;

    try {
      this.isLoad = true;
      this.isSaveBarOpen = false;

      const dynamicFilter = this.formState.filter || [];

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => (
          saveList({
            ...data,
            filter: data.typeList === LISTS_TYPES.Dynamic ? dynamicFilter : null
          } as FormState)
        ),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
      });

      if(isTypeChanged) {
        const newLink = getListItemLink(data.typeList, String(data.id))!.setup;
        this.coreStore.RouterStore.browserHistory.replace(newLink);
      } else {
        yield this.getListSetup();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if(!isTypeChanged) {
        this.isLoad = false;
        this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      }
    }
  }

  resetFormToLastSavedState = (resetData: {field: keyof SaveListData, value?: any}) =>{
    if(resetData){
      this.useFormMethods.reset({
        ...this.useFormMethods.getValues(),
        [resetData.field]: resetData.value ? resetData.value : this.formState[resetData.field]
      });

    } else {
      this.useFormMethods.reset(this.formState);
    }
  };

  *syncMailChimp(mailChimpListId: IdType | null = null) {
    try {
      this.isLoad = true;
      this.isSaveBarOpen = false;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => syncMailChimp({
          id: this.listId!,
          mailChimpListId: mailChimpListId || ''
        }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
      });
      yield this.getListSetup();

    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *disconnectMailChimp() {
    try {
      this.isLoad = true;
      this.isSaveBarOpen = false;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => disconnectMailChimp({ id: this.listId! }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
      });
      yield this.getListSetup();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getMailchimpLists() {
    if(!this.mailChimpLists) {
      try {
        this.isLoad = true;
        this.isSaveBarOpen = false;
  
        const response: AxiosResponse<BackendCommonResponse<Array<MailChimpList>>> = yield getMailchimpLists({
          id: this.listId!
        });
        this.mailChimpLists = response.data.data;
        
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }  
    }
  }

  resetStore = () => {
    this.isPageActive = false;
    this.listId = null;
    this.isSaveBarOpen = false;
    this.currentListDetails = null;
    this.contactsByType = null;
    this.formState = {} as FormState;
    this.initListOwner = null;
    this.listId = null;
    this.listCreationDate = '';
    this.mailChimpLists = null;
  };

  *removeContactsByGroupType(contactGroupTypes: Array<ListsContactsGroupTypes>) {
    try {
      this.isLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => (
          deleteContactsFromListByGroupType({
            id: String(this.listId),
            ...getListContactsSetupFilterParams(contactGroupTypes)
          })
        ),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
      });

      yield this.getListSetup();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
}

export default ListSetupStore;

import React from 'react';
import { useController } from 'react-hook-form';

import { NOTE_TYPES } from '@constants/common';

import RowFilter from '@components/RowFilter';
import Tooltip from '@components/Tooltip';
import TypeButton from '@components/TypeButton';

import { GeneralNoteTypesProps } from './types';

const TYPE_BUTTON_STYLE = {
  style: {
    width: '100%',
    minWidth: 'fit-content',
    padding: '5px 10px',
  }
};

const BOX_PROPS = {
  gap: '10px'
};

export const GeneralNoteTypes = ({
  name,
  control
}: GeneralNoteTypesProps) => {
  const {
    field: {
      value,
      onChange
    }
  } = useController({
    name,
    control,
  });

  return (
    <RowFilter
      boxProps={ BOX_PROPS }
      initState={ value }
      items={ Object.values(NOTE_TYPES) }
      limit={ 5 }
      onChange={ onChange }
      renderItem={ ({
        content,
        isSelected,
        isSelectedFromMore,
        onClick,
        indexForKey,
      }) => (
        <TypeButton
          BtnProps={ TYPE_BUTTON_STYLE }
          isActive={ isSelected || isSelectedFromMore }
          key={ indexForKey }
          onClick={ onClick }
          value={ content as string }
        >
          { content === NOTE_TYPES.voiceMail ? 
            <Tooltip title={ 'Voice Mail' }>{ content }</Tooltip> :
            content
          }
        </TypeButton>
      ) }
    />
  );
};

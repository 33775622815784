import styled, { css } from 'styled-components';
import TableRow from '@material-ui/core/TableRow';

import { getRowBackground } from './utils';

import { COLORS } from '@/shared/constants/colors';

import { RowProps } from './types';

export const StyledRow = styled(TableRow)<RowProps>`
    position: relative;
    isolation: isolate;
    border-bottom: 1px solid ${ COLORS.dividingLines };
  
    ${({ $preventPointerEvents }) => $preventPointerEvents && css`
        pointer-events: none;
    `};

    ${({ $preventHover }) => !$preventHover && css`
      &.MuiTableRow-root:hover {
        cursor: pointer;
        background-color: ${ COLORS.select };
      }
    `}

    ${({ selected, $typeSelectedRow, $backgroundColor }) => selected && $typeSelectedRow && css`
      &.MuiTableRow-root.Mui-selected {
        background: ${getRowBackground($typeSelectedRow, $backgroundColor)};
      }
    `} 
  }
}`;

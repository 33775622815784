import {
  Eye,
  Trash,
  PencilWrite
} from '@/assets';

import { ListsFolderDotsMenuItemTypes } from './types';
import { TemplatesType } from '@components/DotsMenu/types';
import { DOTS_MENU_COMMON_COLORS, DOTS_MENU_DELETE_COLORS } from '@components/DotsMenu';

export const LIST_FOLDER_DOTS_MENU_TYPES = {
  delete: 'DELETE',
  rename: 'RENAME',
  view: 'VIEW',
} as const ;

const OPTIONS_CONFIG: TemplatesType<ListsFolderDotsMenuItemTypes> = {
  [LIST_FOLDER_DOTS_MENU_TYPES.delete]: {
    icon: Trash,
    label: 'Delete Folder',
    topSeparator: true,
    type: LIST_FOLDER_DOTS_MENU_TYPES.delete,
    ...DOTS_MENU_DELETE_COLORS,
  },
  [LIST_FOLDER_DOTS_MENU_TYPES.rename]: {
    icon: PencilWrite,
    label: 'Rename',
    type: LIST_FOLDER_DOTS_MENU_TYPES.rename,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_FOLDER_DOTS_MENU_TYPES.view]: {
    icon: Eye,
    label: 'View',
    type: LIST_FOLDER_DOTS_MENU_TYPES.view,
    ...DOTS_MENU_COMMON_COLORS,
  }
};

export const MENU_OPTIONS = [
  OPTIONS_CONFIG.RENAME,
  OPTIONS_CONFIG.VIEW,
  OPTIONS_CONFIG.DELETE
];


import React, { useCallback } from 'react';

import { useStore } from '@store';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { DeletePopupProps } from './types';

export const DeletePopup = ({
  closeModal,
  modalProps
}: DeletePopupProps) => {
  const contactCustomFields = useStore().ContactCustomFields;
  
  const onConfirmationClick = useCallback(() => {
    contactCustomFields.deleteCustomField(modalProps.id);
    closeModal();
  },[closeModal, contactCustomFields, modalProps.id]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ 'Are you sure you want to remove custom field?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmationClick }
    />
  );
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: ${COLORS.header}
`;

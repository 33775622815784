import React, { memo, MouseEventHandler, useCallback, useMemo } from 'react';

import { COLORS } from '@constants/colors';

import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import Rank from '@components/Rank';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import { OutOfSightTD } from '@components/OutOfSight';
import LinkedContactCell from '@modules/LinkedContactCell';

import { CustomTableRowProps } from './types';
import { DotsMenuItemTypes } from '@components/DotsMenu/types';

export const CustomTableRow = memo(({
  data,
  deleteContactHandler,
  goToContactDetails,
  isCheckboxActive,
  onCheckboxClickCb,
  onLimitedClick,
  onRatingChangeCb,
  onRowBookmarkCb,
}: CustomTableRowProps) => {
  const isBlocked =  typeof data?.editAllow === 'number' && !data?.editAllow;

  const onBookmarkClick = useCallback((state: boolean) => (
    onRowBookmarkCb(data.id, state)
  ),[onRowBookmarkCb, data]);

  const onCheckboxClick = useCallback(() => (
    onCheckboxClickCb(data)
  ),[onCheckboxClickCb, data]);

  const goToContactDetailsWrapper = useCallback(() => {
    goToContactDetails(data.id, data.type);
  },[data.id, data.type, goToContactDetails]);

  const onRatingChange = useCallback((rating: number) => (
    onRatingChangeCb(data.id, rating)
  ), [data.id, onRatingChangeCb]);

  const onBlockedClick: MouseEventHandler<HTMLTableDataCellElement> = useCallback((event) => {
    onLimitedClick();
  }, [onLimitedClick]);

  const onMenuSelect = useCallback((action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.edit) {
      goToContactDetailsWrapper();
    }
    if(action === DOTS_MENU_ITEM_TYPES.delete) {
      deleteContactHandler([ data.id ]);
    }
  }, [goToContactDetailsWrapper, deleteContactHandler, data.id]);

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.delete ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ), []);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ goToContactDetailsWrapper }
      role="checkbox"
      tabIndex={ -1 }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: Boolean(data.flagged),
          onStateChange: onBookmarkClick,
        } }
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClick
        } }
      />
      <LinkedContactCell
        $color={ COLORS.orange }
        alternativeClickHandler={ goToContactDetailsWrapper }
        contact={ data }
      />
      <Table.Cell>
        { data.type }
      </Table.Cell>
      <Table.Cell>
        <Rank
          initRank={ data.rating }
          onRankChange={ onRatingChange }
          resettable
        />
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={  data?.primaryEmail || '-' }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip
          $marginRight={ 20 }
          text={ data?.primaryPhone || '-' }
        />
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
      {
        isBlocked &&
        <OutOfSightTD onClick={ onBlockedClick } />
      }
    </Table.Row>
  );
});

CustomTableRow.displayName = 'CustomTableRow';

import React, { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { NotificationBar } from '@components/NotificationBar';
import RouteGuard from '@modules/RouteGuard';

import { openDetectLinkTransitionPopup } from '@/shared/utils/modalHelpers';

import { ContactFormFields } from '@services/store/contactDetailsProfileStore/types/common';

export const FormChangeWatcher = observer(() => {
  const contactDetailsProfileStore = useStore().ContactDetailsProfileStore;
  const { showSaveBar } = contactDetailsProfileStore;
  const { formState, control, handleSubmit } = useFormContext<ContactFormFields>();
  const isDirty = Object.keys(formState.dirtyFields).length > 0;

  const allFields = useWatch({ control });

  const onSave = useCallback(() => {
    handleSubmit((data) => {
      contactDetailsProfileStore.onConfirmSave(data);
    }, (e) => console.info('error', e))();
  },[contactDetailsProfileStore, handleSubmit]);
  const onCancel = useCallback(() => contactDetailsProfileStore.onCancel(),[contactDetailsProfileStore]);

  useEffect(() => {
    if(isDirty) {
      contactDetailsProfileStore.toggleShowBarVisibility(true);
    }
  },[allFields, contactDetailsProfileStore, isDirty]);

  return (
    <>
      <RouteGuard
        triggerModalRender={ openDetectLinkTransitionPopup }
        when={ isDirty }
      />
      <NotificationBar
        barText='You need to save the information'
        isOpen={ showSaveBar }
        onCancelButtonClick={ onCancel }
        onConfirmButtonClick={ onSave }
      />
    </>
  );
});

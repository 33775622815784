import { LISTS_CONTACTS_GROUP_KEYS, ListsContactsGroupTypes } from '@/shared/types/lists';
import { getFilterParams } from '@/shared/utils/filterUtils';

export const getListContactsSetupFilterParams = (contactGroupTypes?: Array<ListsContactsGroupTypes>): any => {
  const arrayToIterate = contactGroupTypes || [];

  const objectWithParams = arrayToIterate.reduce((acc: any, item) => {
    if(item === LISTS_CONTACTS_GROUP_KEYS.Active || item === LISTS_CONTACTS_GROUP_KEYS.Deceased){
      acc.status = item;
    } else {
      acc[item] = 0;
    }

    return acc;
  }, {});

  return getFilterParams(objectWithParams);
};


import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ActivityPopupFieldsName, LABEL_WIDTH } from '@/modules/ActivityPopup/data';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@/modules/HookFormComponents/HFTextInput';

export const Description = () => {

  const { control } = useFormContext();

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABEL_WIDTH.common }
          label={ 'Description' }
        >
          <HFTextInput
            inputProps={ {
              placeholder: 'Enter Description',
              maxLength: 250,
              rows: 4,
              style: {
                margin: '-10px -5px'
              }
            } }
            TextFieldProps={ {
              multiline: true
            } }
            control={ control }
            name={ ActivityPopupFieldsName.description }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

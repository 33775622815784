export const LABEL_PLACEMENT = {
  start: 'start',
  end: 'end',
  top: 'top',
  bottom: 'bottom',
} as const;

export const LABEL_MARGIN_DIRECTION = {
  [LABEL_PLACEMENT.start]: 'right',
  [LABEL_PLACEMENT.end]: 'left',
  [LABEL_PLACEMENT.top]: 'bottom',
  [LABEL_PLACEMENT.bottom]: 'top',
};

export const SIZES = {
  small: 'small',
  medium: 'medium'
} as const ;

export const SIZES_MAP = {
  [SIZES.small]: 16,
  [SIZES.medium]: 18
};
import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getContactNameWithNickname } from '@/shared/utils/getContactNameWithNickname';
import { openAddSousePopup } from '@/shared/utils/modalHelpers';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

import { CONTACT_TYPES, RELATION_TYPES, ImageUploaderCategories } from '@constants/common';
import {
  COMMON_FIELDS,
  INDIVIDUAL_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';
import { MODAL_TYPE } from '@constants/modalTypes';

import { IdType } from '@/shared/types/commonTypes';
import { ContactItem, IndividualContactItem } from '@/shared/types/contact';

import {
  BriefInfo,
  ImportantDates,
  KeyInformation,
  MiscInfo,
  NameGender,
  Occupations,
  SpouseBtn,
  TabContentWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';
import LimitAccessPopup from '@modules/LimitAccessPopup';

const spouseButtonStyle = {
  marginTop: 'auto',
  flexGrow: 1,
};

export const IndividualPersonalDetails = observer(() => {
  const { optionsForIndividual, contact } = useStore().ContactDetailsProfileStore;
  const { states: statesOptions } = useStore().SettingsStore;
  const routerStore = useStore().RouterStore;
  const { id: currentUserId } = useStore().SettingsStore.profile;
  const modalStore = useStore().ModalStore;

  const spouse = useMemo(() => {
    if(!contact?.relatedContact){
      return null;
    }
    return contact.relatedContact.find(item => item.relationType === RELATION_TYPES.SPOUSE);
  }, [contact?.relatedContact]);

  const onSpouseClick = useCallback(() => {
    if(spouse) {
      if(spouse.editAllow) {
        routerStore.moveToContact({
          id: spouse.relativeId as IdType,
          contactType: CONTACT_TYPES.INDIVIDUAL,
          tab: CONTACT_DETAILS_TAB.overview,
        });
      } else {
        modalStore.openModal({
          modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
          component: LimitAccessPopup,
          modalProps: {
            withPushToContacts: false
          }
        });
      }
    }
  }, [routerStore, spouse, modalStore]);

  const onAddSpouseClick = useCallback(() => {
    openAddSousePopup(currentUserId);
  }, [currentUserId]);

  const getSsnState = (contact: ContactItem | null) => (
    contact && 'ssnReadOnly' in contact ? Boolean(contact.ssnReadOnly) : false
  );

  const isBlocked = typeof spouse?.editAllow === 'number' && !spouse.editAllow;

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item $noBottomPadding={ true }>
        <BriefInfo
          briefDescriptionName={ COMMON_FIELDS.briefDescription }
          contactTitle={ getContactNameWithNickname(contact as IndividualContactItem) }
          headshotBackgroundType={ ImageUploaderCategories.DefaultContact }
          photoName={ COMMON_FIELDS.photo }
        >
          <SpouseBtn
            isBlocked={ isBlocked }
            onAddClick={ onAddSpouseClick }
            onItemClick={ onSpouseClick }
            spouseData={ spouse }
            style={ spouseButtonStyle }
          />
        </BriefInfo>
        <NameGender
          emailNameToUseName={ INDIVIDUAL_FIELDS.emailNameToUse }
          firstNameName={ INDIVIDUAL_FIELDS.firstName }
          genderName={ INDIVIDUAL_FIELDS.gender }
          genderOptions={ optionsForIndividual?.gender || [] }
          lastNameName={ INDIVIDUAL_FIELDS.lastName }
          maidenNameName={ INDIVIDUAL_FIELDS.maidenName }
          mailNameToUseName={ INDIVIDUAL_FIELDS.mailNameToUse }
          middleNameName={ INDIVIDUAL_FIELDS.middleName }
          nicknameName={ INDIVIDUAL_FIELDS.nickname }
          prefixName={ INDIVIDUAL_FIELDS.prefix }
          prefixOptions={ optionsForIndividual?.prefix || [] }
          suffixName={ INDIVIDUAL_FIELDS.suffix }
          suffixOptions={ optionsForIndividual?.suffix || [] }
        />
        <ImportantDates
          anniversaryDateName={ INDIVIDUAL_FIELDS.anniversaryDate }
          birthdateName={ INDIVIDUAL_FIELDS.birthdate }
          retirementDateName={ INDIVIDUAL_FIELDS.retirementDate }
          retirementStatusName={ INDIVIDUAL_FIELDS.retirementStatus }
          retirementStatusOptions={ optionsForIndividual?.retirementStatus || [] }
        />
        <KeyInformation
          birthplaceName={ INDIVIDUAL_FIELDS.birthplace }
          citizenshipCountryName={ INDIVIDUAL_FIELDS.citizenshipCountry }
          countriesOptions={ optionsForIndividual?.countries || [] }
          disableSsn={ getSsnState(contact) }
          dlExpirationDateName={ INDIVIDUAL_FIELDS.dlExpirationDate }
          dlNumberName={ INDIVIDUAL_FIELDS.dlNumber }
          dlStateName={ INDIVIDUAL_FIELDS.dlState }
          ethnicityName={ INDIVIDUAL_FIELDS.ethnicity }
          ethnicityOptions={ optionsForIndividual?.ethnicity || [] }
          maritalStatusName={ INDIVIDUAL_FIELDS.maritalStatus }
          maritalStatusOptions={ optionsForIndividual?.maritalStatus || [] }
          ssnName={ INDIVIDUAL_FIELDS.ssn }
          stateOptions={ statesOptions || [] }
          taxIdNumberName={ INDIVIDUAL_FIELDS.taxIdNumber }
        />
        <Occupations name={ INDIVIDUAL_FIELDS.occupations }/>
        <MiscInfo petsName={ INDIVIDUAL_FIELDS.pets } />
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

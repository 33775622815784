import { SalesActiveCycleBookmarkByStageByStage, SalesActiveCycleByStage } from '@/shared/types/salesActiveCycle';

export const getMassFlagsParams = (stageColumns:SalesActiveCycleByStage, newState: number) => {
  return Object.keys(stageColumns).reduce((
    newCycleByStage: SalesActiveCycleBookmarkByStageByStage,
    stageKey
  ) => {
    const stage = stageKey as keyof SalesActiveCycleByStage;
    const  column =  stageColumns[stage];

    for (const index in column) {
      const state = newState;
      const item = column[index];

      if(item.flagged !== state){
        const data = {
          id: item.id,
          flagged: state
        } ;
        if(Array.isArray(newCycleByStage?.[stage])){
          newCycleByStage[stage].push(data);
        } else {
          newCycleByStage[stage] = [data];
        }
      }
    }

    return newCycleByStage;
  }, {} as SalesActiveCycleBookmarkByStageByStage);
};

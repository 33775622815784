import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

import { ROUTES } from '@constants/routes';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';

export const LimitAccessPopup = ({
  closeModal,
  modalProps,
}) => {
  const history = useHistory();
  const { withPushToContacts = true } = modalProps;

  const closeAndPushToContacts = useCallback(() => {
    if(withPushToContacts){
      history.push(ROUTES.contactsAll);
    }
    closeModal();
  },[closeModal, history, withPushToContacts]);

  useEffect(() => {
    const timer = setTimeout(() => {
      closeAndPushToContacts();
    }, 5000);

    return () => clearTimeout(timer);
  }, [closeAndPushToContacts]);

  return (
    <ConfirmationPopup
      confirmLabel={ 'Okay' }
      isOpen={ true }
      message={ 'The Contact is not available for previewing. Please refer to your administrator' }
      onClose={ closeAndPushToContacts }
      onConfirmClick={ closeAndPushToContacts }
      type={ POPUP_TYPES.INFO }
    />
  );
};

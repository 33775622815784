import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;


export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-weight: 400;
    font-size: 14px;
    color: ${COLORS.primaryText};
    transform: translate(12px,8px);

    ${(props) => props.$active && css`
      color: ${COLORS.white};
      background-color: ${COLORS.orange};
    `}

    ${(props) => props.$isOffRange && css`
      color: ${COLORS.borders};
    `}

`;

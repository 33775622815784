import { DimensionsMap } from './types';
export enum USER_ICON_SIZES {
  exSmall='exSmall',
  small= 'small',
  medium = 'medium',
  large = 'large',
}

export const USER_ICON_DIMENSIONS: DimensionsMap = {
  [USER_ICON_SIZES.exSmall]: 14,
  [USER_ICON_SIZES.small]: 20,
  [USER_ICON_SIZES.medium]: 30,
  [USER_ICON_SIZES.large]: 40,
};

export const USER_ICON_FONT_SIZES: DimensionsMap = {
  [USER_ICON_SIZES.exSmall]: 8,
  [USER_ICON_SIZES.small]: 10,
  [USER_ICON_SIZES.medium]: 13,
  [USER_ICON_SIZES.large]: 16,
};

import React, { forwardRef } from 'react';

import { InnerWrapper, Wrapper, Item } from './styles';

import { ItemWrapperProps, TabContentWrapperProps } from './types';

export const ItemWrapper = forwardRef<HTMLDivElement, ItemWrapperProps>(
  (props, ref) => {
    const {
      $noBottomPadding = false,
      $withPadding = true,
      children,
      style = {}
    } = props;

    return (
      <Item
        $noBottomPadding={ $noBottomPadding }
        $withPadding={ $withPadding }
        ref={ ref }
        style={ style }
      >
        { children }
      </Item>
    );
  });

ItemWrapper.displayName = 'ItemWrapper';

export const TabContentWrapper = ({
  children,
  isFluid
}: TabContentWrapperProps) => {
  return (
    <Wrapper>
      <InnerWrapper isFluid={ isFluid }>
        { children }
      </InnerWrapper>
    </Wrapper>
  );
};

TabContentWrapper.Item = ItemWrapper;

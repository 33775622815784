import React from 'react';

import { StatusChipProps } from './types';

import { StyledChip } from './styles';

export const StatusChip = ({
  label,
  icon,
  $color,
  $bgColor,
  ...restProps
}: StatusChipProps) => {
  return (
    <StyledChip
      $bgColor={ $bgColor }
      $color={ $color }
      clickable={ false }
      icon={ icon }
      label={ label }
      { ...restProps }
    />
  );
};

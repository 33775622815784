import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { useStore } from '@store';
import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';

import NotesAndHistory, { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';

import { NotesAndHistoryWrapper } from './styles';


export const Notes = observer(() => {
  const store = useContactDetailsSideBarPanelLocalStore();
  const { getNotes, predefinedTags, notes } = store;

  const methods =  useForm();
  const { profile } = useStore().SettingsStore;

  useEffect(() => {
    getNotes();
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    if(notes) {
      methods.reset({ [CONTACT_NOTES_AND_HISTORY_INPUT_NAMES.notes]: notes });
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  return (
    <NotesAndHistoryWrapper>
      <NotesAndHistory
        control={ methods.control }
        currentUser={ profile }
        isAbleToEdit={ false }
        isAddNoteBtnDisabled={ false }
        linkedContactData={ null }
        names={ CONTACT_NOTES_AND_HISTORY_INPUT_NAMES }
        noteAndHistoryType={ NOTE_AND_HISTORY_TYPE.general }
        predefinedTags={ predefinedTags }
        title={ 'Notes & History Timeline ' }
      />
    </NotesAndHistoryWrapper>
  );
});

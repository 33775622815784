import { CORE_CLASSIFICATIONS } from '@constants/common';

import { EditActivityItemResponse } from '@/modules/ActivityPopup/store/types';
import { ClientWithCalendarData, IdType } from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';

export enum ActivityStatusOptions {
  Scheduled = 'Scheduled',
  Rescheduled = 'Rescheduled',
  Tentative = 'Tentative',
  Cancelled = 'Cancelled',
  Confirmed = 'Confirmed',
  Unconfirmed = 'Unconfirmed',
  Completed = 'Completed',
}

export type ClientConverter = Omit<ClientWithCalendarData, 'headshot'> & {
  classification: CORE_CLASSIFICATIONS,
  photo: ClientWithCalendarData['headshot'],
};

export type ConvertActivityData = EditActivityItem['formData'] & EditActivityItem['renderData'];

export interface IncomingStore {
  changeActivity(): void;
}

export type EditActivityItem = {
  formData: Omit<EditActivityItemResponse, 'allDay' | 'recurring' | 'contact' | 'clients'> & {
    allDay: boolean,
    recurring: boolean,
    clients: Array<{ userId: IdType}>
  },
  renderData: {
    primaryContact: LinkedContact,
    clients: Array<ClientConverter>,
  }
};

export type StartEndDateAndTime = {
  endDate: string;
  endTime?: string;
  startDate: string;
  startTime?: string;
};
export type TimeData = {
  duration: number,
  startDateObj: Date,
  view?: string,
}
export type RemoveData = {
  isRecurring?: boolean,
  activityData: {
    id: IdType,
    recurringChangeDate?: string,
  }
};
export type ActivityPopupProps = {
  editMode: boolean
  forDay?: string
  id: IdType,
  initData?: StartEndDateAndTime & {
    primaryContact: LinkedContact
  },
  onConfirm: (data: any) => void
  onDelete: (data: any) => void
}

import React, { useCallback, useContext } from 'react';

import { Cross } from '@/assets';

import CustomIconButton, { SIZES } from '@components/CustomIconButton';

import { COLORS } from '@constants/colors';

import { HeaderProps } from '../../types';

import { CloseBtnWrapper, Wrapper } from './styles';

import { NotificationWithActionContext } from '../../context';

export const Header = ({
  children,
  withCloseButton = true
}: HeaderProps) => {
  const { action } = useContext(NotificationWithActionContext);

  return (
    <Wrapper>
      {children}
      {
        withCloseButton &&
        <CloseBtnWrapper>
          <CustomIconButton
            $iconColor={ COLORS.white }
            $iconHoverColor={ COLORS.orange }
            $size={ SIZES.exSm }
            icon={ Cross }
            onClick={ action }
          />
        </CloseBtnWrapper>
      }
    </Wrapper>
  );
};

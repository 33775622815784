import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  color: ${COLORS.header};
  margin-right: auto;
`;

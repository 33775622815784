import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { createPassword } from '@services/api/common/common';
import { useStore } from '@store';

import { Content } from '../components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import HFPasswordInput from '@modules/HookFormComponents/HFPasswordInput';
import NewPassword from '@components/NewPassword';

import { FORM_DEFAULTS_VALUES, FORM_FIELD_NAMES } from './data';
import { ROUTES } from '@constants/routes';
import { validationSchema } from './validationSchema';


export const CreatePassword = () => {

  const routerStore = useStore().RouterStore;

  let { token } = useParams<{ token: string; }>();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: FORM_DEFAULTS_VALUES,
  });

  const { control, handleSubmit } = methods;

  const onSubmitClick = useCallback(() => {
    handleSubmit(async (data) => {
      const { newPassword } = data;
      try {
        await createPassword({ token, password: newPassword });
        routerStore.customPush(ROUTES.login);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [ routerStore, token, handleSubmit ]);

  return (
    <Content>
      <Content.Header
        isVisibleLogInButton={ false }
        isVisibleSignUpButton={ false }
      />
      <Content.Main>
        <Content.Title>Create Your Password</Content.Title>
        <Content.Label>New Password</Content.Label>
        <NewPassword
          control={ control }
          name={ FORM_FIELD_NAMES.newPassword }
        />

        <Content.Label>Confirm Password</Content.Label>
        <HFPasswordInput
          control={ control }
          name={ FORM_FIELD_NAMES.confirmPassword }
          placeholder={ 'Confirm Password' }
        />

        <BlockButton
          colorSchema={ COLOR_SCHEME_TYPES.contained }
          onClick={ onSubmitClick }
          style={ {
            marginTop: '20px'
          } }
          variant='contained'
        >
          Submit
        </BlockButton>
      </Content.Main>
    </Content>
  );
};

import React, { useCallback, useState } from 'react';

import { useDidUpdate } from '@customHooks';

import { ShowMore } from './components';

import { Wrapper } from './styles';

import { Item, RowFilterProps } from './types';

export const RowFilter = ({
  $showMoreListMarginBottom,
  IconClose,
  IconOpen,
  allButtonLabel,
  boxProps,
  children,
  initState,
  items,
  limit,
  onChange = () => {},
  renderItem,
  showMoreLabel = 'More',
}: RowFilterProps) => {
  const [ currentSelected, setCurrentSelected ] = useState<Item | null>(initState || allButtonLabel || null);
  const unLimitedItems = items.slice(0, limit);
  const limitedItems = items.slice(limit, items.length);

  const onClickWrapper = useCallback((value: Item) => {
    setCurrentSelected(value);
    onChange(value);
  },[onChange]);

  const onClick = useCallback((value: Item) => () => {
    onClickWrapper(value);
  }, [onClickWrapper]);

  useDidUpdate(() => {
    if (initState){
      setCurrentSelected(initState as string);
    } else {
      setCurrentSelected(allButtonLabel as string);
    }
  },[initState]);

  return (
    <Wrapper { ...boxProps }>
      {
        allButtonLabel &&
        renderItem({
          content: allButtonLabel,
          isSelected: currentSelected === allButtonLabel,
          onClick: onClick(allButtonLabel),
          isUnlimited: false,
          isSelectedFromMore: false,
          isShowMore: false,
          indexForKey: allButtonLabel,
        })
      }
      {
        unLimitedItems.map((unLimitedItem, index) => (
          renderItem({
            content: unLimitedItem,
            isSelected: currentSelected === unLimitedItem,
            onClick: onClick(unLimitedItem),
            isUnlimited: true,
            isSelectedFromMore: false,
            isShowMore: false,
            indexForKey: index,
            setCurrentSelected: setCurrentSelected,
            currentSelected: currentSelected
          })
        ))
      }

      <ShowMore
        $showMoreListMarginBottom={ $showMoreListMarginBottom }
        IconClose={ IconClose }
        IconOpen={ IconOpen }
        currentSelected={ currentSelected }
        items={ limitedItems }
        onItemClickCb={ onClickWrapper }
        renderItem={ renderItem }
        showMoreLabel={ showMoreLabel }
      />
      { children }
    </Wrapper>
  );
};

import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';
import { LinkedContact } from '@/shared/types/linkedContact';
import {
  // @ts-ignore
  ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES
} from '@modules/AccountsAndPlansPopup/data/data';
import { CONTACT_TYPES } from '@constants/common';

type ProcessPopupTableDataProps = {
  source?: Array<any>
  state: {
    index: number,
    preEditState?: any
  } | null
}

export const processPopupTableData = ({
  source,
  state
}:ProcessPopupTableDataProps): Array<any> => {
  if(!source) {
    return [];
  }

  if (!state) {
    return source;
  }

  if(state.preEditState) {
    const sourceCopy = [...source];
    sourceCopy[state.index] = state.preEditState;
    return sourceCopy;
  } else {
    return source.filter((item, itemIndex) => itemIndex !== state.index);
  }
};

// to Delete
// export const getNoteLinkedContactAction = (
//   linkedContactData: LinkedContact | null,
//   formFields: FormFieldsValues
// ): ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES | null => {
//   const isNotesNotExist = !formFields?.accountPlansNotes || formFields?.accountPlansNotes.length === 0;
//
//   if(isNotesNotExist) {
//     return null;
//   }
//
//   if (linkedContactData?.type === CONTACT_TYPES.HOUSEHOLD) {
//     const memberOfHouseHoldIds = linkedContactData.householderContacts.map(item => item.id);
//     const arrayOfAvailableIds = [ linkedContactData.id, ...memberOfHouseHoldIds ];
//
//     const isSomeOfNotesLinkedToAnotherContact = formFields?.accountPlansNotes?.some(item => {
//       const noteContactId = item.noteContactId as number;
//       return noteContactId !== null && !arrayOfAvailableIds.includes(noteContactId);
//     });
//
//     if (isSomeOfNotesLinkedToAnotherContact) {
//       return ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES.reconnect;
//     }
//     return null;
//   }
//
//   const isSomeOfNotesLinkedToAnotherContact = formFields?.accountPlansNotes?.some(item => {
//     const noteContactId = item.noteContactId;
//     return noteContactId !== null && noteContactId !== '' && noteContactId !== linkedContactData?.id;
//   });
//
//   if (linkedContactData && isSomeOfNotesLinkedToAnotherContact) {
//     return ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES.reconnect;
//   }
//
//   return null;
// };
//
// // to delete
// export const getProcessedData = (
//   data: FormFieldsValues,
//   actionType: ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES
// ): FormFieldsValues => {
//   let account = data;
//
//   if(actionType === ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES.reconnect){
//     account!.accountPlansNotes!.slice().forEach((item, index) => {
//       account!.accountPlansNotes![index].noteContactId = account.contactId;
//     });
//   }
//
//   return account;
// };

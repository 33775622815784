import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  height: 55px;
  justify-content: space-between;

  & > svg {
    width: 120px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { AppPage } from '@modules/AppPage';
import Surface from '@components/BasicLayoutComponents/Surface';
import Preloader from '@components/Preloader';

import { SalesCycleHeader, SalesActiveCycles, SalesClientOpportunities } from './components';

import { SALES_ACTIVE_CYCLE_PATH, SALES_CLIENT_OPPORTUNITIES_PATH } from '@/shared/constants/routes/salesCycle';
import { SalesCycleParamsType } from '@/shared/types/salesActiveCycle';

export const SalesCycle = observer(() => {
  const { isLoad, resetStore, setCurrentTab } = useStore().SalesCycleStore;

  const { params: { tab } } = useRouteMatch<SalesCycleParamsType>();

  useEffect(() => {
    setCurrentTab(tab);
  }, [setCurrentTab, tab]);

  useEffect(() => {
    return resetStore;
  }, [resetStore]);

  return (
    <AppPage>
      <AppPage.AppHeader>
        <AppPage.AppPageTitle title={ 'Sales Pipeline' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isLoad && <Preloader />
        }
        <SalesCycleHeader />
        <Switch>
          <Route path={ SALES_ACTIVE_CYCLE_PATH }>
            <SalesActiveCycles />
          </Route>
          <Route path={ SALES_CLIENT_OPPORTUNITIES_PATH }>
            <SalesClientOpportunities/>
          </Route>
        </Switch>

      </Surface>
    </AppPage>
  );
});



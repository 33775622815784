import React from 'react';

import GrowElement from '@components/GrowElement';
import Tooltip from '@components/Tooltip';

import { CloseIcon, HeaderText, StyledHeader } from './styles';

function ModalHeader({ maxWidth, minimizeButton, title, onClose }) {
  const titleElement =
    typeof title === 'string' ? (
      <Tooltip displayOnlyOnOverflow title={ title }>
        <HeaderText>{title}</HeaderText>
      </Tooltip>
    ) : (
      <HeaderText>{title}</HeaderText>
    );
  return (
    <StyledHeader $maxWidth={ maxWidth }>
      {titleElement}
      <GrowElement />
      {minimizeButton}
      <CloseIcon onClick={ onClose } />
    </StyledHeader>
  );
}

export default ModalHeader;

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import { AppHeaderWrapperProps } from './types';

export const Wrapper = styled.div<AppHeaderWrapperProps>`
  display: flex;
  padding: 0 25px;
  min-height: 64px;
  align-items: center;
  position: relative;
  z-index: 1;
  
  background-color: ${COLORS.white};

  box-shadow: 0px 1px 5px rgb(16 28 91 / 7%);
  
  ${({ $isSticky }) => $isSticky && css`
    position: sticky;
    top: 0;
    left: 0;
    z-index: 500;
  `}
`;

export const ToolbarSearchWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`;


export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 64px;
  margin-left: auto;
`;

export const ChildrenWrapper = styled.div`
  margin-right: auto;
  max-width: 30%;
  
  flex-grow: 1;
  
  @media screen and (max-width: 1600px){
    max-width: 25%;
  }
`;

import React from 'react';

import AvatarLine, { Items } from '@components/AvatarLine';
import FormBlock from '@components/FormBlock';
import {
  Toolbar
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/ContactDetails/components/Toolbar';

import { CONTACTS_LOCATION_IDS, FIELD_IDS } from '@constants/contactsData';
import { COMMON_FIELDS_WIDTH, LOCATION_FIELDS_WIDTH } from './data';
import {
  DETAILS_DATA_MAP
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/ContactDetails/data';

import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getHouseHoldPrimaryName, getPrimaryName } from '@/shared/utils/getPrimaryName';

import { Field } from './components';

import { LabelWrapper } from './styles';

import { FieldsFactoryProps } from './types';


export const FieldsFactory = ({
  fields,
  headOfHouseholdOptions = [],
  householdMembers = null,
  isHousehold = false,
  labelWidth,
  onDeleteClick,
  onPrimaryClick,
  parentName,
}: FieldsFactoryProps) => {

  return(
    <>
      {
        fields.map((field, index) => {
          return (
            <FormBlock.RowWrapper key={ field.useFormContactsId }>
              <FormBlock.FormField
                $labelWidth={ labelWidth }
                isWrap={ false }
                label={ (isHousehold && !field.id) || !isHousehold ? DETAILS_DATA_MAP[field.fieldId].label : '' }
                renderLabelContent={ () => {
                  if(isHousehold && field.id && field.ownerId) {
                    const contactAvatarData = householdMembers ? 
                      getAvatarData(householdMembers.filter(member => member.id === field.ownerId)[0]) :
                      {} as Items;
  
                    return (
                      <LabelWrapper>
                        <AvatarLine
                          isHideCounter={ true }
                          items={ [contactAvatarData] }
                        />
                        { DETAILS_DATA_MAP[field.fieldId].label }
                      </LabelWrapper>
                    );
                  } else {
                    return null;
                  }
                } }
              >
                <Toolbar
                  $maxWidth={
                    !field.id && isHousehold ? 230 : CONTACTS_LOCATION_IDS.includes(field.fieldId)
                      ? LOCATION_FIELDS_WIDTH
                      : COMMON_FIELDS_WIDTH
                  }
                  getPrimaryName={ isHousehold ? getHouseHoldPrimaryName : getPrimaryName }
                  headOfHouseholdOptions={ headOfHouseholdOptions }
                  icon={ DETAILS_DATA_MAP[field.fieldId].icon }
                  index={ index }
                  isHousehold={ isHousehold && !field.id }
                  onDeleteClick={ onDeleteClick }
                  onPrimaryClick={ onPrimaryClick }
                  parentName={ parentName }
                  withPrimary={ field.fieldId !== FIELD_IDS.CONTACT_PREFERENCE_ID }
                >
                  <Field 
                    field={ field } 
                    index={ index } 
                    parentName={ parentName }
                  />
                </Toolbar>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
          );
        })
      }
    </>
  );
};

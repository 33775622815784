import { useCallback, useState } from 'react';

import { EventType, UseTextFieldStateProps, UseTextFieldStateReturnType } from '@components/CustomTextField/types';

export const useTextFieldState = ({
  initValue = '',
  onChange
}: UseTextFieldStateProps):UseTextFieldStateReturnType => {
  const [value, setValue] = useState<string>(initValue);

  const setTextFieldValue = useCallback((event: EventType) => {
    const { value } = event.target;
    setValue(value);
    if(onChange) {
      onChange({ value, e: event });
    }
  },[onChange]);

  const setForceTextFieldValue = useCallback((value: string) => {
    setValue(value);
  },[]);

  return {
    textFieldValue: value,
    setTextFieldValue,
    setForceTextFieldValue,
  };
};

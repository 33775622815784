import { InputBaseComponentProps, TextFieldProps } from '@material-ui/core';

export const generalInputProps: InputBaseComponentProps  = {
  placeholder: '0.00',
  maxLength: 25,
  step: '1.00',
};

export const generalTextFieldProps: TextFieldProps  = {
  type: 'number',
};


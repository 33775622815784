import styled, { css } from 'styled-components';

import { WrapperProps } from './types';


export const Wrapper = styled.div<WrapperProps>`
  ${({ $color }) => $color && css`
    & fieldset {
      border-color: ${$color};
    }
    
    & input {
      color: ${$color};
    }
  `}
`;

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table from '@components/Table';

import { UserCustomFieldsTableRow } from './components';

import { UserCustomFieldsTableProps } from './types';

import { COLUMNS } from './data';

export const UserCustomFieldsTable = observer(({
  onRemove,
  onAddEdit,
}: UserCustomFieldsTableProps) => {
  const { isFetching } = useStore().UserSettingsStore;
  const { table } = useStore().UserSettingsUserCustomFieldsStore;

  const onAddWrapper = useCallback(() =>  onAddEdit() ,[onAddEdit]);

  return (
    <Table>
      <Table.Container>
        <Table.Header
          columns={ COLUMNS }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
        />
        <Table.Body>
          {
            !isFetching && table.items.length > 0 &&
            table.items.map(item => (
              <UserCustomFieldsTableRow
                item={ item }
                key={ item.id }
                onEdit={ onAddEdit }
                onRemove={ onRemove }
              />
            ))
          }

        </Table.Body>
        {
          !isFetching && table.items.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              addActionButtonClickHandler={ onAddWrapper }
              addActionButtonLabel={ 'Add Custom Field' }
              withAction={ true }
            />
          </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});

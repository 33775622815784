import styled from 'styled-components';

export const OnboardingWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 10px;
  padding-left: 5px;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
`;

import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';

export const Wrapper = styled(MuiBox)`
  width: 100%;
  height: 100%;
  
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  
  padding: 20px 20px 10px 20px;
  
  box-sizing: border-box;
  overflow-x: scroll;
`;

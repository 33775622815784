import React, { useCallback, useMemo } from 'react';

import { useStore } from '@store';

import { RelatedContactDeleteConfirmationProps } from './types';

import { RELATION_TYPES } from '@constants/common';

import ConfirmationPopup from '@modules/ConfirmationPopup';

const UNLINK_COMMON_MESSAGE_TEXT = 'Are you sure you want to unlink related contact?';
const UNLINK_HOUSEHOLD_MESSAGE_TEXT = `${UNLINK_COMMON_MESSAGE_TEXT} The Household record will 
  be permanently deleted with all linked Activities, Tasks and ets.`;

export const RelatedContactDeleteConfirmation = ({
  closeModal,
  modalProps,
}: RelatedContactDeleteConfirmationProps) => {
  const { id, relationType, isContactInHouseHold } = modalProps;
  const relatedContactsStore = useStore().RelatedContactsStore;

  const onConfirm = useCallback(() => {
    closeModal();
    relatedContactsStore.unlinkRelatedContact(id);
  },[closeModal, id, relatedContactsStore]);

  const message = useMemo(() => {
    if(isContactInHouseHold && relationType === RELATION_TYPES.SPOUSE) {
      return UNLINK_HOUSEHOLD_MESSAGE_TEXT;
    }
    return UNLINK_COMMON_MESSAGE_TEXT;
  }, [relationType, isContactInHouseHold]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirm }
    />
  );
};

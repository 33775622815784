import styled, { css } from 'styled-components';

import SquareIconButton from '@components/SquareIconButton';

import { COLORS } from '@constants/colors';

import { IconContainerProps, SquareIconButtonWrapperProps } from './types';


export const IconContainer = styled.div<IconContainerProps>`
  margin-right: 10px;

  ${ ({ isBorder }) => isBorder && css`
    border-right: 1px solid ${COLORS.borders};
  `}
`;

export const ButtonsWrapper = styled.div`
  height: 80px;
  padding-left: 26px;
  display: flex;
  align-items: center;
`;

export const SquareIconMoveButtonWrapper = styled(SquareIconButton)<SquareIconButtonWrapperProps>`
  margin-right: 10px;

  &:hover svg {
    fill: ${COLORS.orange} !important;
  }

  &:hover {
    border-color: ${COLORS.orange};
    background-color: ${COLORS.select};
  }

  ${ ({ isCropMode }) => isCropMode && css`
    color: ${COLORS.orange};
    background-color: ${COLORS.select};
    border-color: ${COLORS.orange};
  `}
`;

export const SquareIconButtonWrapper = styled(SquareIconButton)`
  margin-right: 10px;
  
  & svg path {
    fill: ${COLORS.primaryText};
  }

  &.Mui-disabled svg {
    fill: rgba(0, 0, 0, 0.26);
  }

  &:focus {
    border-color: ${COLORS.orange};
    background-color: ${COLORS.select};
  }

  &:focus svg {
    fill: ${COLORS.orange} !important;
  }

  &:hover {
    border-color: ${COLORS.orange};
    background-color: ${COLORS.select};
  }

  &:hover svg path {
    fill: ${COLORS.orange} !important;
  }
`;

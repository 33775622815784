import 'react-international-phone/style.css';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { COLORS } from '@constants/colors';


export const MENU_STYLES = {
  height: '300px',
  top: '120px',
  padding: 0,
};

export const MENU_LIST_STYLES = {
  style: {
    padding: 0,
  }
};

export const INPUT_PROPS_STYLES = {
  style: {
    paddingLeft: 0,
  },
};

export const PhoneInputWrapper = styled.div`
  width: 100%;
  height: 45px;

  & .MuiSelect-select {
    display: flex;
    align-items: center;
    width: 35px;
    height: 30px;
    padding: 0 0 0 10px;
    background-color: transparent;

    & img {
      width: 17px;
    }
  }

  & svg {
    top: auto;
    right: 0px;
    color: ${COLORS.primaryText};
  }

  & .MuiInputAdornment-root .MuiOutlinedInput-root fieldset {
    height: 30px;
    border: none;
    box-sizing: border-box;
  }
`;

export const CountryListItem = styled(MenuItem)`
  display: flex;
  gap: 8px;
  padding-left: 10px;
  font-size: 14px;
`;

export const CountryCode = styled.span`
  width: 40px;
  color: ${COLORS.grey};
`;

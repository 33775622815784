import styled, { css } from 'styled-components';

import { DeleteStatusTextProps } from './types';

export const DeleteStatusText = styled.div<DeleteStatusTextProps>`
    ${({ $color }) => $color && css`
        color: ${$color};
    `};
  
    margin-right: auto;
`;

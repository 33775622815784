import React from 'react';

import { TextWithoutSpaceReservationProps } from './types';

import { InnerWrapper, Wrapper } from './styles';

export const TextWithoutSpaceReservation = ({ 
  text,
  $disabled,
  $bottom,
}: TextWithoutSpaceReservationProps) => (
  <Wrapper>
    <InnerWrapper
      $bottom={ $bottom }
      $disabled={ $disabled }
    >
      { text }
    </InnerWrapper>
  </Wrapper>
);

import styled from 'styled-components';

export const ContactInformationWrapper = styled.div`
  width: 22%;
  min-width: 330px;
  min-height: 900px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GroupIcons = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
`;

export const ContactDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
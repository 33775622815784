import { buildPath } from '@/shared/utils/buildPath';

import { COMMON_PATH_PART } from '@constants/routes/common';

import {
  USER_SETTINGS_ACCOUNT_SETTINGS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_ROUTE,
  USER_SETTINGS_MY_PROFILE_PATH,
  USER_SETTINGS_PASSWORD_PATH,
  USER_SETTINGS_USERS_PATH,
  USER_SETTINGS_ROUTE,
} from './userSettings';

import {
  ALL_CONTACTS_PATH,
  CONTACTS_COMMON_PATH,
  CONTACT_DETAILS_PATH,
  LISTS_FOLDER_ROUTE,
  LISTS_PATH,
  LISTS_DETAILS_ROUTE,
  LISTS_DYNAMIC_ITEM_ROUTE,
  LISTS_MANUAL_ITEM_ROUTE,
} from './contacts';

import {
  TRASH_ACCOUNT_AND_PLANS_PATH,
  TRASH_ACTIVITIES_PATH,
  TRASH_CLIENT_OPPORTUNITIES_PATH,
  TRASH_CONTACTS_PATH,
  TRASH_LISTS_PATH,
  TRASH_ROUTE,
  TRASH_SALES_CYCLES_PATH,
  TRASH_TASKS_PATH,
} from './trash';

import { SALES_CYCLE_ROUTE } from './salesCycle';
import { TO_DOS_ROUTE } from './toDos';

export const ACCOUNTS_AND_PLANS_PATH = buildPath([COMMON_PATH_PART, 'accounts-and-plans']);

export const CALENDAR_PATH = buildPath([COMMON_PATH_PART, 'calendar']);

const DASHBOARD_PATH = '/dashboard';

const HOME_PATH = '/';
const LOGIN_PATH = '/login';
const REGISTER_PATH = '/register';
const RESET_PASSWORD_PATH = '/reset-password';
const RESTORE_PASSWORD_PATH = '/restore-password-token';
const VERIFIED_EMAIL_BY_HASH = '/client/verifiedEmailByHash/:hash';
const CREATE_PASSWORD = '/create-new-password/:token';

const TEST_PATH = '/test';

export const ROUTES = {
  accountsAndPlans: ACCOUNTS_AND_PLANS_PATH,
  calendar: CALENDAR_PATH,
  contactDetails: CONTACT_DETAILS_PATH,
  contacts: CONTACTS_COMMON_PATH,
  contactsAll: ALL_CONTACTS_PATH,
  createPassword: CREATE_PASSWORD,
  dashboard: DASHBOARD_PATH,
  home: HOME_PATH,
  lists: LISTS_PATH,
  listsDetails: LISTS_DETAILS_ROUTE,
  listsDetailsDynamicList: LISTS_DYNAMIC_ITEM_ROUTE,
  listsDetailsManualList: LISTS_MANUAL_ITEM_ROUTE,
  listsFolder: LISTS_FOLDER_ROUTE,
  login: LOGIN_PATH,
  register: REGISTER_PATH,
  resetPassword: RESET_PASSWORD_PATH,
  restorePasswordToken: RESTORE_PASSWORD_PATH,
  salesCyclePath: SALES_CYCLE_ROUTE,
  test: TEST_PATH,
  toDos: TO_DOS_ROUTE,
  trash: TRASH_ROUTE,
  trashAccountsAndPlans: TRASH_ACCOUNT_AND_PLANS_PATH,
  trashActivities: TRASH_ACTIVITIES_PATH,
  trashClientOpportunities: TRASH_CLIENT_OPPORTUNITIES_PATH,
  trashContacts: TRASH_CONTACTS_PATH,
  trashLists: TRASH_LISTS_PATH,
  trashSalesCycles: TRASH_SALES_CYCLES_PATH,
  trashTodos: TRASH_TASKS_PATH,
  userSettings: USER_SETTINGS_ROUTE,
  userSettingsAccountSettings: USER_SETTINGS_ACCOUNT_SETTINGS_PATH,
  userSettingsCustomFieldsAndTags: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_ROUTE,
  userSettingsMyProfile: USER_SETTINGS_MY_PROFILE_PATH,
  userSettingsPassword: USER_SETTINGS_PASSWORD_PATH,
  userSettingsUsers: USER_SETTINGS_USERS_PATH,
  verifiedEmailByHash: VERIFIED_EMAIL_BY_HASH,
};

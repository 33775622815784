import * as yup from 'yup';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

import { validationMessages } from '@constants/validationMessages';
import {
  dateOutputValidation,
  onlyNumberAndCharacterPattern,
  onlyNumberAndCharacterWithSpacePattern
} from '@constants/validationPatterns';


import {
  COMMON_FIELDS,
  INDIVIDUAL_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

import { OCCUPATION_SCHEMA } from './occupationSchema';
import { imageValidation } from '@components/ImageUploader';

export const INDIVIDUAL_CONTACT_DETAILS_SCHEMA = {
  [COMMON_FIELDS.briefDescription]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.anniversaryDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidAnniversaryDate),
  [INDIVIDUAL_FIELDS.birthdate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidBirthday)
    .test(
      'mast be equal or less than Today',
      validationMessages.invalidBirthday,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [INDIVIDUAL_FIELDS.birthplace]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.dlExpirationDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate),
  [INDIVIDUAL_FIELDS.dlNumber]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.firstName]: yup.string()
    .required(validationMessages.required),
  [INDIVIDUAL_FIELDS.lastName]: yup.string()
    .required(validationMessages.required),
  [INDIVIDUAL_FIELDS.maidenName]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.middleName]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.nickname]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.occupations]: yup.array().of(OCCUPATION_SCHEMA),
  [INDIVIDUAL_FIELDS.pets]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.retirementDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidRetirementDate),
  [INDIVIDUAL_FIELDS.ssn]: yup.string()
    .nullable(),
  [INDIVIDUAL_FIELDS.taxIdNumber]: yup.string()
    .nullable(),
  [COMMON_FIELDS.photo]: imageValidation,
};

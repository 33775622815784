import React from 'react';
import { observer } from 'mobx-react';

import { useAddUserPopupLocalStore } from '../Content/store';

import PopupActionsButtons from '@/components/PopupActionsButtons';

import { ButtonsWrapper, Wrapper } from './styles';

export const Actions = observer(() => {

  const addUserPopupLocalStore = useAddUserPopupLocalStore();

  return (
    <Wrapper>
      <ButtonsWrapper>
        <PopupActionsButtons
          onCancelClick={ addUserPopupLocalStore.closeModal.bind(addUserPopupLocalStore) }
          onSaveClick={ addUserPopupLocalStore.onSave.bind(addUserPopupLocalStore) }
        />
      </ButtonsWrapper>
    </Wrapper>
  );
});
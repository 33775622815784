import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';

import { CriteriaFormWrapper } from '@/shared/types/lists';
import { TypeWhereProps } from './types';

import { OPERAND_OPTIONS } from '@constants/lists';

import { Wrapper, Delimiter, Label } from './styles';

export const ListCriteriaOperandField = ({
  name,
  value,
  $paddingBottom,
  $paddingTop,
  $fontSize = 14
}: TypeWhereProps) => {
  const { control } = useFormContext<CriteriaFormWrapper>();

  const label = useMemo(() => {
    const index = OPERAND_OPTIONS.findIndex((data) => data.value === value);

    return index >= 0 ? OPERAND_OPTIONS[index].label : '';
  }, [value]);

  return (
    <Wrapper
      $paddingBottom={ $paddingBottom }
      $paddingTop={ $paddingTop }
    >
      <Delimiter />
      <Label $fontSize={ $fontSize }>
        {label}
      </Label>
      <HFHiddenInput
        control={ control }
        defaultValue={ value }
        name={ name }
      />
    </Wrapper>
  );
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const TextRowWrapper = styled.div`
  padding-bottom: 15px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.white};
  opacity: 0.5;
`;
import { ColorSchemaItem, ColorSchemaType } from './types';
import { ButtonProps } from '@material-ui/core/Button';
import { COLORS } from '@constants/colors';

export const COLOR_SCHEME_TYPES = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text',
  warningContained: 'warningContained',
  warningOutlined: 'warningOut',
  infoContained: 'infoContained',
  infoOutlined: 'infoOutline',
} as const;

export const VARIANTS: Record<'outlined' | 'contained' | 'text', ButtonProps['variant']> = {
  outlined: 'outlined',
  contained: 'contained',
  text: 'text'
};

export const COLOR_SCHEME_CONFIG: Record<ColorSchemaType, ColorSchemaItem> = {
  [COLOR_SCHEME_TYPES.contained]: {
    $color: COLORS.white,
    $bgColor: COLORS.orange,
    $bgHoverColor: COLORS.orangeBgc,
    $borderColor: null,
    $hoverColor: '',
    $borderHoverColor: null,
    $variant: VARIANTS.contained
  },
  [COLOR_SCHEME_TYPES.outlined]: {
    $color: COLORS.orange,
    $bgColor: COLORS.white,
    $bgHoverColor: COLORS.select,
    $borderColor: COLORS.orange,
    $hoverColor: COLORS.orange,
    $borderHoverColor: null,
    $variant: VARIANTS.outlined
  },
  [COLOR_SCHEME_TYPES.text]: {
    $color: COLORS.orange,
    $bgColor: 'transparent',
    $bgHoverColor: COLORS.select,
    $borderColor: null,
    $hoverColor: COLORS.orange,
    $borderHoverColor: null,
    $variant: VARIANTS.text
  },
  [COLOR_SCHEME_TYPES.warningContained]: {
    $color: COLORS.white,
    $bgColor: COLORS.red,
    $bgHoverColor: COLORS.redSecond,
    $borderColor: null,
    $hoverColor: null,
    $borderHoverColor: null,
    $variant: VARIANTS.contained
  },
  [COLOR_SCHEME_TYPES.warningOutlined]: {
    $color: COLORS.red,
    $bgColor: COLORS.white,
    $bgHoverColor: COLORS.redBg,
    $borderColor: COLORS.red,
    $hoverColor: '',
    $borderHoverColor: null,
    $variant: VARIANTS.outlined
  },
  [COLOR_SCHEME_TYPES.infoContained]: {
    $color: COLORS.white,
    $bgColor: COLORS.info,
    $bgHoverColor: COLORS.info,
    $borderColor: null,
    $hoverColor: null,
    $borderHoverColor: null,
    $variant: VARIANTS.contained
  },
  [COLOR_SCHEME_TYPES.infoOutlined]: {
    $color: COLORS.info,
    $bgColor: COLORS.white,
    $bgHoverColor: COLORS.white,
    $borderColor: COLORS.info,
    $hoverColor: null,
    $borderHoverColor: null,
    $variant: VARIANTS.outlined
  },
};


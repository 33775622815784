import styled from 'styled-components';

export const ValueWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ValueTextWrapper = styled.div``;

export const ValueIndicatorWrapper = styled.div`
  margin-left: 8px;
`;

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const BookmarkIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    min-width: 27px;
    height: 27px;
    margin-right: 20px;
    border: 1px solid ${ COLORS.borders };
    border-radius: 4px;

    & > div {
        margin-top: 4px;
        margin-right: 1px;
    }
`;

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { hexToRgba } from '@/shared/utils/hexToRgba';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;

  background-color: ${hexToRgba(COLORS.notificationWithAction, 0.15)};
`;

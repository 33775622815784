import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
    max-width: 350px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const RelationWrapper = styled.div`
    max-width: 150px;
`;

import React from 'react';

import { DatePickerMain } from './components';

import { CONVERT_DATE_FORMAT, DATE_FORMAT, MASK, PLACEHOLDER_TEXT } from './data';

import { CustomDatePickerProps } from './types';

// TODO Expand with custom hook logic

//Note if input equal output, just pass convertFormat with null;
//Note if initValue format different than dateFormat
//you need pass convertFormat to parse date and make format on output
//by default: dateFormat - MM/dd/yyyy, convertFormat: yyyy-MM-dd
export const CustomDatePicker = ({
  DatePickerProps,
  convertFormat = CONVERT_DATE_FORMAT,
  dateViewFormat = DATE_FORMAT,
  disabled = false,
  errorMessage,
  isDateWithTime,
  onChange,
  placeholder = PLACEHOLDER_TEXT,
  preventMinDateAutoSet = false,
  showError = false,
  value,
}: CustomDatePickerProps) => {
  const outputFormat = convertFormat || dateViewFormat;
  return (
    <DatePickerMain
      DatePickerProps={ DatePickerProps }
      dateFormat={ dateViewFormat }
      disabled={ disabled }
      errorMessage={ errorMessage }
      isDateWithTime={ isDateWithTime }
      mask={ MASK }
      onChange={ onChange }
      outputFormat={ outputFormat }
      placeholder={ placeholder }
      preventMinDateAutoSet={ preventMinDateAutoSet }
      showError={ showError }
      value={ value }
    />
  );
};

import styled from 'styled-components';

import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

export const Container = styled(MuiBox)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 30px;
    background: ${COLORS.background};
`;

export const Tab = styled(MuiBox)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    border: ${({ selected }) => `1px solid ${selected ? COLORS.orange : COLORS.borders}`};
    border-radius: 6px;
    background-color: ${({ selected }) => selected ? COLORS.select : COLORS.white};
    color: ${({ selected }) => selected ? COLORS.orange : COLORS.primaryText};
    font-weight:${({ selected }) => selected ? 400 : 300};
    cursor: pointer;
`;

export const Text = styled.span`
    font-size: 14px;
    line-height: 140%;
`;

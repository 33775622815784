import { Chart } from 'chart.js/dist/types/index';
import { endOfQuarter, format, getYear, startOfMonth, startOfQuarter } from 'date-fns';

import { COLORS } from '@constants/colors';
import { QUARTERS_SCHEME } from '@constants/dashboard';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';


export const getQuarterLabels = (year: Date, quarter: number) => {
  const start = startOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].firstMonthNumber));
  const end = endOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].endMonthNumber));
  const labels = [];

  while(start <= end) {
    labels.push(format(new Date(start), YEAR_MONTH_DAY));
    start.setDate(start.getDate() + 1);
  }
  return labels;
};

export const getYearLabels = (year: Date) => {
  const labels = [];
  for(let i = 0; i < 12; i++) {
    const firstDayOfMonth = startOfMonth(new Date(getYear(year), i));
    labels.push(format(firstDayOfMonth, YEAR_MONTH_DAY));
  }
  return labels;
};

export const getGradient = (chart: Chart) => {
  const { ctx, chartArea } = chart;
  const gradientFill = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
  gradientFill.addColorStop(0, COLORS.gradientChart);
  gradientFill.addColorStop(1, COLORS.white);
  return gradientFill;
};

const MAX_DOLLAR_AMOUNT = 100;
const PERCENT = 0.1;

export const getMinValue = (data: Array<number>) => {
  const chartDataMinValue = Math.min.apply(null, data);
  const chartDataMinValuePlusTenPercent = chartDataMinValue * PERCENT + chartDataMinValue;
  const minValue = Math.floor(chartDataMinValuePlusTenPercent / MAX_DOLLAR_AMOUNT) * MAX_DOLLAR_AMOUNT;
  return chartDataMinValue < 0 ? minValue : 0;
};

export const getMaxValue = (data: Array<number>) => {
  const chartDataMaxValue = Math.max.apply(null, data);
  const chartDataMaxValuePlusTenPercent = chartDataMaxValue * PERCENT + chartDataMaxValue;
  const maxValue = Math.ceil(chartDataMaxValuePlusTenPercent / MAX_DOLLAR_AMOUNT) * MAX_DOLLAR_AMOUNT;
  return chartDataMaxValue > 0 ? maxValue : MAX_DOLLAR_AMOUNT;
};
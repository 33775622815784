import styled from 'styled-components';

export const StateWrapper = styled.div`
  width: 352px;
  padding-right: 40px;
  box-sizing: border-box;
`;

export const CityWrapper = styled.div`
  width: 281px;
  padding-right: 40px;
  box-sizing: border-box;
`;

export const ZipWrapper = styled.div`
  width: 200px;
  box-sizing: border-box;
`;

export const LeftColumnWrapper = styled.div`
  width: 50%;
  padding-right: 37px;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  padding-left: 26px;
  box-sizing: border-box;
`;


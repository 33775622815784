import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const AddWrapper = styled.div`
  margin-left: 10px;
`;

export const BubbleFiltersWrapper = styled.div`
  margin-left: 20px;
  margin-right: auto;

  & > div > button {
    margin-right: 5px;
  }
`;


export const ViewSwitcherWrapper = styled.div`
  margin-left: 20px;
`;

import React from 'react';
import { Popover } from '@material-ui/core';

import { anchorOriginProps, ListItem, paperProps, transformOriginProps } from './styles';

import { ListProps } from './types';


export const List = ({
  anchorEl,
  mailChimpLists,
  onClickListItem,
  onClose,
  selectedListItem,
}: ListProps) => {

  const isOpen = Boolean(anchorEl);

  return (
    <Popover
      PaperProps={ paperProps }
      anchorEl={ anchorEl }
      anchorOrigin={ anchorOriginProps }
      onClose={ onClose }
      open={ isOpen }
      transformOrigin={ transformOriginProps }
    >
      {
        mailChimpLists.map(list => {
          return (
            <ListItem
              $isActive={ selectedListItem?.mailChimpListId === list.mailChimpListId }
              key={ list.mailChimpListId }
              onClick={ () => onClickListItem(list) }
            >
              { list.mailchimpListName }
            </ListItem>
          );
        })
      }
    </Popover>
  );
};

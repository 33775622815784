import React from 'react';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { LIST_NAME_INPUT_PROPS } from './data';
import { validationMessages } from '@constants/validationMessages';

import { ListNameProps } from './types';

export const ListName = ({
  name,
  label = 'List Name',
  control,
  labelWidth,
}: ListNameProps) => {

  return (
    <FormBlock.FormField
      $labelWidth={ labelWidth }
      isRequired={ true }
      label={ label }
    >
      <HFTextInput
        ControllerProps={ {
          rules: {
            required: validationMessages.required
          }
        } }
        inputProps={ LIST_NAME_INPUT_PROPS }
        control={ control }
        name={ name }
        placeholder={ 'Enter List Name' }
      />
    </FormBlock.FormField>
  );
};

import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import PopupButtonPanelItems from '@components/PopupButtonPanelItems';

import { EVENT_TYPES_BUTTONS } from '@pages/NewCalendar/components/Calendar/data';

import { BtnPanelProps } from './types';
import { EVENT_TYPES } from '@constants/colorsByEventTypes';
import { TypeButtonOnClickType } from '@components/TypeButton/types';

export const BtnPanel = ({
  $preventClicks,
}: BtnPanelProps) => {

  const { control } = useFormContext();

  const {
    field: {
      value,
      onChange
    }
  } = useController({
    name: 'type',
    control,
    defaultValue: EVENT_TYPES.generalActivity,
  });

  const onClick: TypeButtonOnClickType = useCallback((event, value) => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopupButtonPanelItems
      currentActionType={ value }
      elemMarginRight={ '10px' }
      onClick={ onClick }
      options={ EVENT_TYPES_BUTTONS }
      wrapperStyles={ {
        pointerEvents: $preventClicks ? 'none' : 'auto',
      } }
    />
  );
};

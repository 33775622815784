export const SESSION_WORKER_MESSAGE_TYPES = {
  lastMinuteBeforeLogout: 'lastMinuteBeforeLogout',
  logout: 'logout',
  start: 'start',
  stop: 'stop',
  updateExpiredTime: 'updateExpiredTime'
};

export const AUTH_WORKER_MESSAGE_TYPES = {
  logout: 'logout',
  update: 'update',
};
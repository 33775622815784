import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

export const ModalDialogWrapper = styled(Dialog)`
  display: ${({ $isMinimizedModal }) =>
    $isMinimizedModal ? 'none' : 'block'};
  opacity: ${({ $isHiddenModal, $isMinimizedModal }) =>
    $isMinimizedModal ? 1 : $isHiddenModal && 0};
  transition: opacity 0.35s linear;
`;

export const ModalFooterPortal = styled.div`
  min-height: 74px;
  width: 100%;
  background: #fff;
`;

export const getPaperStyles = ({
  stickToTop = false,
  fullHeight = false,
}) => ({
  style: {
    alignSelf: stickToTop ? 'start' : 'center',
    height: fullHeight ? '100%' : 'auto',
    overflow: 'hidden',
  },
});

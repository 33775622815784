import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { WrapperType } from './types';
import { hexToRgba } from '@/shared/utils/hexToRgba';

export const Wrapper = styled.span<WrapperType>`
  color: ${({ $isPast, $disabled }) => {
    if($disabled){
      return hexToRgba(COLORS.helpText, 0.8);
    }

    return $isPast ? COLORS.red : COLORS.header;
  }};
`;

import React, { ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { formattedNumberToContactAmount } from '@/shared/utils/formattedNumberToContactAmount';

import { DASHBOARD_LINE_TABS } from '@constants/dashboard';

import CustomTabs from '@components/CustomTabs';
import TextWithTooltip from '@components/TextWithTooltip';

import { Amount, DataWrapper, TabsWrapper, Title, TabWrapper } from './styles';
import { DashboardAccountAndPlans } from '@/shared/types/dashboard';

const TAB_STYLES = {
  width: 'auto',
  minWidth: 80,
  padding: '0 16px',
};

export const Tabs = observer(() => {

  const { currentTab, setCurrentTab, tabsBalanceData } = useStore().DashboardStore;

  const onDashboardTabsChange = useCallback((e: ChangeEvent<{}>, value: DashboardAccountAndPlans) => {
    setCurrentTab(value);
  }, [setCurrentTab]);

  return (
    <TabsWrapper>
      <CustomTabs
        value={ currentTab }
        onChange={ onDashboardTabsChange }
      >
        {
          DASHBOARD_LINE_TABS.map(tab => {
            const Icon = tab.icon as React.ElementType;
            return (
              <CustomTabs.Tab
                $minHeight={ '60px' }
                key={ tab.label }
                label={ 
                  <TabWrapper>
                    <Title>{ tab.label }</Title>
                    <DataWrapper>
                      <Amount>
                        <TextWithTooltip text={ formattedNumberToContactAmount(tabsBalanceData[tab.label] || 0) }/>
                      </Amount>
                      <Icon/>
                    </DataWrapper>
                  </TabWrapper>
                }
                value={ tab.label }
                style={ TAB_STYLES }
              />
            );
          })
        }
      </CustomTabs>
    </TabsWrapper>
  );
});

import React, { useCallback, useState, DragEvent, ChangeEvent, MouseEvent, useMemo } from 'react';

import UploadImagePopup from '@components/UploadImagePopup';

import { ImageUploaderCategories, ImageUploaderTypes } from '@constants/common';

import {
  DragEnter,
  ImageUploaderBusinessContactBgrnd,
  ImageUploaderDefaultContactBgrnd,
  ImageUploaderUserBgrnd,
  Trash,
  Upload,
} from '@/assets';

import { ACCEPTABLE_FORMATS, MAX_SIZE_OF_IMAGE } from './data';

import {
  AvatarLogoImageUploader,
  AvatarPlaceholder,
  AvatarTitle,
  ErrorMessage,
  IconButton,
  ImageUploaderWrapper,
  ListItemIconDragEnter,
  ListItemIconUpload,
  LogoPlaceholder,
  LogoTitle,
  PlaceholderInnerWrapper,
  StyledInput,
} from './styles';

import { ImageUploaderProps } from './types';

export const ImageUploader = ({
  error,
  headshotBackgroundType,
  imageType,
  img,
  isAvatar,
  successCallback,
  title,
  onInValidFile
}: ImageUploaderProps) => {
  const [upImg, setUpImg] = useState<string | ArrayBuffer | null>(null);
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(null);

  const [uploadImagePopupOpened, setUploadImagePopupOpened] = useState<boolean>(false);

  const handleSelectFile = useCallback((files: FileList | null, event?: ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      const file = files[0];
      const isImage = ACCEPTABLE_FORMATS.includes(file.type);
      const isOverSize = !isImage
        ? false
        : file.size >= MAX_SIZE_OF_IMAGE;

      if(isImage && !isOverSize){
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setUpImg(reader.result);
          setUploadImagePopupOpened(true);
        });
        reader.readAsDataURL(file);
      } else {
        onInValidFile(file);
      }
    }
    if(event) {
      event.target.value = '';
    }
  }, [onInValidFile]);

  const successCallbackWrapper = useCallback(async (croppedImg: string | ArrayBuffer) => {
    let convertedImage;
    if(typeof croppedImg !== 'string') {
      const buffer = Buffer.from(croppedImg);
      convertedImage = buffer.toString('base64');
    } else {
      convertedImage = croppedImg;
    }

    return fetch(convertedImage)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        successCallback(croppedImg, blob);
        setUploadImagePopupOpened(false);
      });
  }, [ successCallback ]);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    let files = event.dataTransfer.files;
    handleSelectFile(files);
  }, [handleSelectFile]);

  const handleDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onCloseImagePopup = useCallback(() => {
    setUploadImagePopupOpened(false);
  }, []);

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    handleSelectFile(event.target.files, event);
  }, [handleSelectFile]);

  const setFileInputRefFromInput = useCallback((file: HTMLInputElement) => {
    setFileInputRef(file);
  }, []);

  const onClickImageUploader = useCallback(() => {
    fileInputRef?.click();
  }, [fileInputRef]);

  const backgroundImage = useMemo(() => typeof img !== 'string' ? '' : img, [ img ]);

  const avatarBackgroundImage = useMemo(() => {
    const img = backgroundImage; 
    if(!img) {
      switch (headshotBackgroundType) {
      case ImageUploaderCategories.BusinessContact:
        return ImageUploaderBusinessContactBgrnd;
      case ImageUploaderCategories.DefaultContact:
        return ImageUploaderDefaultContactBgrnd;
      default:
        return ImageUploaderUserBgrnd;
      }
    }
    return img;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ img ]);

  const onDelete = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    successCallback('', '' as unknown as Blob);
  }, [ successCallback ]);

  return(
    <ImageUploaderWrapper>
      <UploadImagePopup
        img={ upImg }
        isOpen={ uploadImagePopupOpened }
        onClose={ onCloseImagePopup }
        successCallback={ successCallbackWrapper }
      />
      <StyledInput
        accept='image/*'
        onChange={ onChangeInput }
        ref={ setFileInputRefFromInput }
        type='file'
      />

      <AvatarLogoImageUploader
        img={ backgroundImage }
        isAvatar={ isAvatar }
        onClick={ onClickImageUploader }
        onDragOver={ handleDrag }
        onDrop={ handleDrop }
      >

        { imageType === ImageUploaderTypes.Headshot &&
          <AvatarPlaceholder
            $isError={ Boolean(error) }
            img={ avatarBackgroundImage }
          >
            <PlaceholderInnerWrapper>
              <ListItemIconDragEnter>
                <DragEnter/>
              </ListItemIconDragEnter>
              <AvatarTitle>
                Drag & Drop or Click
              </AvatarTitle>
            </PlaceholderInnerWrapper>
          </AvatarPlaceholder>
        }

        { (imageType === ImageUploaderTypes.Signature || imageType === ImageUploaderTypes.Logo) &&
          <LogoPlaceholder
            $isError={ Boolean(error) }
            img={ backgroundImage }
          >
            <PlaceholderInnerWrapper>
              <ListItemIconUpload>
                <Upload/>
              </ListItemIconUpload>
              <LogoTitle>
                Upload {title}
              </LogoTitle>
              <AvatarTitle>
                Drag & Drop or Click
              </AvatarTitle>
            </PlaceholderInnerWrapper>
          </LogoPlaceholder>
        }

        <IconButton
          onClick={ onDelete }
          size={ 'small' }
        >
          <Trash/>
        </IconButton>
        
      </AvatarLogoImageUploader>

      { error && <ErrorMessage>{ error.message }</ErrorMessage> }
    </ImageUploaderWrapper>
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  font-size: 18px;

  & svg {
    width: 14px;
    height: 14px;

    & path {
      fill: ${COLORS.header};
    }
  }
`;

export const CloseIconButton = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

export const SubTitle = styled.div`
  margin-bottom: 12px;
  font-size: 10px;
  color: ${COLORS.helpText};
`;

export const InfoText = styled.div`
  display: inline-block;
  margin-left: 4px;
  color: ${COLORS.systems};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
  background: ${COLORS.borders};
`;

export const AdminTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  max-height: 235px;
  margin-bottom: 15px;
  overflow-y: auto;

  & > div {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
  }
`;
import * as yup from 'yup';

import {
  confirmPasswordValidation,
  newPasswordValidation,
} from '@modules/HookFormComponents/HFPasswordInput';
import { FORM_FIELD_NAMES } from './data';

export const validationSchema = yup.object().shape({
  [FORM_FIELD_NAMES.newPassword]: newPasswordValidation,
  [FORM_FIELD_NAMES.confirmPassword]: confirmPasswordValidation(FORM_FIELD_NAMES.newPassword),
});

import { buildPath } from '@/shared/utils/buildPath';

import { COMMON_PATH_PART } from '@constants/routes/common';

export const TO_DO_TABS = {
  clientSpecificTasks: 'client-specific-tasks',
  otherTasks: 'other-tasks',
} as const;

export const TO_DOS_COMMON_PATH = 'to-dos';

export const TO_DOS_PATH = buildPath([COMMON_PATH_PART, TO_DOS_COMMON_PATH]);

export const TO_DOS_ROUTE = buildPath([COMMON_PATH_PART, TO_DOS_COMMON_PATH, ':tab']);

export const CLIENT_SPECIFIC_TASKS_PATH = buildPath([TO_DOS_PATH, TO_DO_TABS.clientSpecificTasks]);
export const OTHER_TASKS_PATH = buildPath([TO_DOS_PATH, TO_DO_TABS.otherTasks]);

import styled, { css } from 'styled-components';

import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import MuiBox from '@material-ui/core/Box';
import MuiTextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import { COLORS } from '@constants/colors';

export const Container = styled(MuiBox)`
    flex: 1;
`;

export const Autocomplete = styled(MuiAutocomplete)`
    & .MuiAutocomplete-inputRoot {
        height: 30px;

        & .MuiChip-root {
            display: none !important;
        }

        & .MuiAutocomplete-input {
            padding: 0 0 0 5px !important;
        }
    }
`;

export const TextField = styled(MuiTextField)``;

export const TagWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    height: 28px;
    padding: 0px 10px;
    ${({ checked }) => checked && css` background-color: ${COLORS.select};`};
    font-size: 14px;
    box-sizing: border-box;
`;

export const StyledPaper = styled(Paper)`
    border-radius: 4px;

    & .MuiAutocomplete-option {
        padding: 0;
    }
`;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { TagColumn } from './components';

import { Wrapper } from './styles';


export const ManageTags = observer(() => {
  const {
    init,
    general,
    interest,
    resetState
  } = useStore().UserSettingsManageTagsStore;

  const { isFetching } = useStore().UserSettingsStore;

  useEffect(() => {
    init();
    return resetState;
  }, [init, resetState]);

  return (
    <Wrapper>
      <TagColumn
        isLoad={ isFetching }
        store={ general }
        title={ 'General Tags' }
      />
      <TagColumn
        isLoad={ isFetching }
        store={ interest }
        title={ 'Interest Tags' }
      />
    </Wrapper>
  );
});

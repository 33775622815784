import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
    min-height: 60px;
    align-items: center;
    padding: 13px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid ${ COLORS.orange };
    background-color: ${ COLORS.white };
`;

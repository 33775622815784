import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { AvatarData, getAvatarData } from '@/shared/utils/getAvatarData';
import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

import { LISTS_TYPES } from '@constants/common';

import AvatarLine from '@components/AvatarLine';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import ViewAllContacts from '@modules/Lists/ViewAllContacts';

import { ListsNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';


export const ListsCells = observer(({
  item,
}: TrashCellsProps<ListsNormalizerItem['item']>) => {

  const contactAvatarData = useMemo(() => {
    if(item.type === LISTS_TYPES.Dynamic || item.type === LISTS_TYPES.Manual){
      return convertObjectWithNumericKeysToArray<AvatarData>(item.contactData, getAvatarData);
    }
    return [];
  }, [ item ]);

  return (
    <>
      <Table.Cell>
        <TextWithTooltip text={ item.name || '' } />
      </Table.Cell>

      <Table.Cell>{ item.type || '' }</Table.Cell>

      <Table.Cell>
        {
          !Array.isArray(item.contactData) &&
            (
              <>
                <AvatarLine
                  count={ item.contactData.count }
                  isHideCounter={ item.contactData.count <= 0 && contactAvatarData.length > 0 }
                  items={ contactAvatarData }
                />
                {
                  item.contactData.count > 0 &&
                  <ViewAllContacts
                    listId={ String(item.id) }
                  />
                }
              </>
            )
        }
      </Table.Cell>
    </>
  );
});

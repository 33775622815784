import styled from 'styled-components';

export const LeftWrapper = styled.div`
  width: 50%;
  padding-right: 19px;
  box-sizing: border-box;
`;

export const RightWrapper = styled.div`
  width: 50%;
  padding-left: 35px;
  box-sizing: border-box;
`;

import React from 'react';
import { useController } from 'react-hook-form';

import ArrowStepper from '@components/ArrowStepper';

import { HFArrowStepperProps } from './types';

export const HFArrowStepper = ({
  name,
  control,
  options,
  disabled,
  ControllerProps
}: HFArrowStepperProps) => {
  const { field: { onChange, value } } = useController({
    name,
    control,
    ...ControllerProps
  });

  return (
    <ArrowStepper
      currentSelected={ value }
      disabled={ disabled }
      onChange={ onChange }
      options={ options }
    />
  );
};

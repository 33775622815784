import { AvatarProps } from '@components/Avatar/types';
import { getAbbr } from '@/shared/utils/getAbbr';
import { CONTACT_TYPES } from '@constants/common';
import { SimplePerson } from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';
import { ContactItem } from '@/shared/types/contact';

export type AvatarData = Pick<AvatarProps, 'mainImageData' | 'subImageData'>

type Data = LinkedContact | SimplePerson | ContactItem


export const getAvatarData = (data: Data): AvatarData => {
  if('type' in data && data.type){
    if(data.type === CONTACT_TYPES.BUSINESS){
      return  {
        mainImageData: {
          abbr: getAbbr(data.occupationCompany),
          src: data.photo,
        }
      };
    }

    if(data.type === CONTACT_TYPES.HOUSEHOLD){
      return  {
        mainImageData: {
          abbr: getAbbr(data?.householderContacts[0]?.firstName, data?.householderContacts[0]?.lastName),
          src: data.householderContacts[0]?.photo,
        },
        subImageData: {
          abbr: getAbbr(data?.householderContacts[1]?.firstName, data?.householderContacts[1]?.lastName),
          src: data.householderContacts[1]?.photo,
        }
      };
    }
  }
  // this return support INDIVIDUAL CONTACT Item and simple users
  return  {
    mainImageData: {
      abbr: getAbbr(data.firstName, data.lastName),
      src: 'headshot' in data ? data.headshot : data?.photo || null,
    }
  };
};

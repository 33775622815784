import React from 'react';

import { LogoFull, LogoSmall, MenuChevronLeft } from '@/assets';

import {
  LogoFullWrapper,
  LogoSmallWrapper,
  ToggleSizeButton,
  Wrapper,
} from './styles';

import { HeaderProps } from './types';

export const Header = ({
  $isOpen,
  toggleIsOpenWrapper,
}: HeaderProps) => (
  <Wrapper $isOpen={ $isOpen }>
    {
      $isOpen &&
      <>
        <LogoFullWrapper>
          <LogoFull />
        </LogoFullWrapper>
        <ToggleSizeButton onClick={ toggleIsOpenWrapper } >
          <MenuChevronLeft />
        </ToggleSizeButton>
      </>
    }
    {
      !$isOpen &&
      <LogoSmallWrapper onClick={ toggleIsOpenWrapper }>
        <LogoSmall/>
      </LogoSmallWrapper>
    }
  </Wrapper>
);

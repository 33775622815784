import styled, { css } from 'styled-components';

import { ItemsWrapperProps } from './types';

export const ItemsWrapper = styled.div<ItemsWrapperProps>`
    display: flex;
    flex-direction: column;
    padding: 6px 0;

    ${({ $maxHeightDropDownHeight }) =>  $maxHeightDropDownHeight && css`
        max-height: ${$maxHeightDropDownHeight}px;
        overflow-y: scroll;
    `}
    
    ${({ 
    $maxHeightDropDownHeight,
    $isPlaceScrollOut
  }) => $maxHeightDropDownHeight &&  $isPlaceScrollOut && css` 
        margin-right: -6px;
    `}
`;

export const Item = styled.div`
    display: flex;
    padding: 4px 0;
    font-weight: 400;
`;

export const Label = styled.label`
    flex-grow: 1;
`;

export const CheckboxWrapper = styled.div`
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
`;

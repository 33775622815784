import React, { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { DatePickerWrapper } from './styles';

import {
  DATE_FIELDS,
  INPUTS_TYPE_NUMBER_FIELDS,
  INPUTS_TYPE_TEXT_FIELDS,
  MULTIPLE_SELECTS,
  OPERAND_OPTIONS,
  SINGLE_SELECTS,
} from '@constants/listsCriteria';

import { CriteriaFormWrapper } from '@/shared/types/lists';
import { ValueFieldsProps } from './types';

import { DelimiterText, FieldPlaceholderWrapper, ValueFieldsWrapper } from './styles';
import { LIST_VALUE_NESTED_KEYS } from '@constants/lists';

export const ValueFields = memo(({
  operand,
  field,
  options = [],
  namePath,
  maxChars,
  isMultiple
}: ValueFieldsProps) => {
  const { control } = useFormContext<CriteriaFormWrapper>();

  const content = useMemo(() => {
    if(!operand || operand === OPERAND_OPTIONS.Is_Empty || operand === OPERAND_OPTIONS.Is_Not_Empty){
      return (
        <FieldPlaceholderWrapper>
          -
        </FieldPlaceholderWrapper>
      );
    }

    if(DATE_FIELDS.includes(field as any)){
      if(operand === OPERAND_OPTIONS.In_Between){
        return (
          <>
            <DatePickerWrapper>
              <HFDatePicker
                ControllerProps={ {
                  shouldUnregister: true
                } }
                control={ control }
                name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
              />
            </DatePickerWrapper>
            <DelimiterText>
              and
            </DelimiterText>
            <DatePickerWrapper>
              <HFDatePicker
                ControllerProps={ {
                  shouldUnregister: true
                } }
                control={ control }
                name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.last}` }
              />
            </DatePickerWrapper>
          </>
        );
      }else {
        return (
          <HFDatePicker
            ControllerProps={ {
              shouldUnregister: true
            } }
            control={ control }
            name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
          />
        );
      }
    }

    if(INPUTS_TYPE_NUMBER_FIELDS.includes(field as any)){
      if(operand === OPERAND_OPTIONS.In_Between){
        return (
          <>
            <HFTextInput
              ControllerProps={ {
                shouldUnregister: true
              } }
              TextFieldProps={ {
                type: 'number'
              } }
              control={ control }
              inputProps={ {
                maxLength: maxChars
              } }
              name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
              placeholder={ 'Please Input' }
            />
            <DelimiterText>
              and
            </DelimiterText>
            <HFTextInput
              ControllerProps={ {
                shouldUnregister: true
              } }
              TextFieldProps={ {
                type: 'number'
              } }
              control={ control }
              inputProps={ {
                maxLength: maxChars
              } }
              name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.last}` }
              placeholder={ 'Please Input' }
            />
          </>
        );
      }else {
        return (
          <HFTextInput
            ControllerProps={ {
              shouldUnregister: true
            } }
            TextFieldProps={ {
              type: 'number'
            } }
            control={ control }
            inputProps={ {
              maxLength: maxChars
            } }
            name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
            placeholder={ 'Please Input' }
          />
        );
      }
    }

    if(INPUTS_TYPE_TEXT_FIELDS.includes(field as any)){
      return (
        <HFTextInput
          ControllerProps={ {
            shouldUnregister: true
          } }
          control={ control }
          inputProps={ {
            maxLength: maxChars
          } }
          name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
          placeholder={ 'Please Input' }
        />
      );
    }

    if(MULTIPLE_SELECTS.includes(field as any)){
      return (
        <HFSelect
          ControllerProps={ {
            shouldUnregister: true
          } }
          SelectProps={ {
            multiple: isMultiple
          } }
          control={ control }
          name={ namePath }
          options={ options }
        />
      );
    }

    if(SINGLE_SELECTS.includes(field as any)){
      return (
        <HFSelect
          ControllerProps={ {
            shouldUnregister: true
          } }
          control={ control }
          name={ `${namePath}.${LIST_VALUE_NESTED_KEYS.first}` }
          options={ options }
        />
      );
    }

    return null;
  },[control, field, isMultiple, maxChars, namePath, operand, options]);

  return (
    <ValueFieldsWrapper>
      {content}
    </ValueFieldsWrapper>
  );
});

ValueFields.displayName = 'ValueFields';

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import FormBlock from '@components/FormBlock';
import { AddContact, LinkContact, Notification } from './components';

import { TextWrapper } from './styles';


export const PrimaryPoint = observer(() => {

  const [ isNewContact, setNewContact ] = useState<boolean>(false);

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Primary Point-Of-Contact' }/>

      <Notification/>

      <LinkContact disabled={ isNewContact }/>

      <TextWrapper>or</TextWrapper>

      <AddContact setNewContact={ setNewContact }/>
    </FormBlock>
  );
});

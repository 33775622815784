import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  ${({ $width }) => $width && css`
    width: ${$width};
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ $marginBottom }) => $marginBottom};

  & ${Image} {
    max-width: 955px;
    max-height: 310px;
  }
  

  @media screen and (max-height: 1080px) {
    & ${Image} {
      max-width: 700px;
      max-height: 2000px;
    }
  }

  @media screen and (max-height: 900px) {
    margin-bottom: 10px;

    & ${Image} {
      max-width: 500px;
      max-height: 120px;
    }
  }
`;



export const TextWrapper = styled.p`
  font-size: ${({ $fontSize }) => $fontSize};
  color: ${COLORS.grey};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin:0 0 25px 0;
`;
export const TextChunk = styled.span``;

export const MarkedTextChunk = styled.span`
  color: ${COLORS.helpText};
`;
export const ActionBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InfoWrapper = styled.div`
  margin-top: 40px
`;
export const InfoText = styled.p`
  margin: 0 0 8px 0;
  text-align: center;
  color: ${COLORS.grey};
  
  &:last-child {
    marign: 0;
  }
`;
export const InfoLink = styled.a`
  color: ${COLORS.orange};
`;

import * as yup from 'yup';

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import { COMMON_FIELD_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';

import { accountValidationSchema } from './accountValidationSchema';
import { planValidationSchema } from './planValidationSchema';
import { policyValidationSchema } from './policyValidationSchema';

export const validationSchema = yup.lazy((fields) => {
  const accountType: ACCOUNTS_AND_PLANS_TYPES = fields[COMMON_FIELD_NAMES.accountType];

  if(accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT) {
    return accountValidationSchema;
  } else if (accountType === ACCOUNTS_AND_PLANS_TYPES.PLAN) {
    return planValidationSchema;
  } else {
    return policyValidationSchema;
  }
});

import React, { ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@services/store/store';

import { getOverdueFilterParamsObject } from '@/shared/utils/getOverdueFilterParamsObject';

import {
  INIT_STAGE_FILTER_STATE,
  OFFICIAL_REQUEST_SUBCATEGORIES,
  TODOS_FILTER_NAMES
} from '@constants/todosData';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { TodosPreTablePanel } from '@modules/TodosPreTablePanel';
import { useCustomToggleState } from '@components/CustomToggle';

import { Item } from '@components/RowFilter/types';
import { PreTablePanelWrapperProps } from './types';

export const PreTablePanelWrapper = observer(({
  onAddTodoClick
}: PreTablePanelWrapperProps) => {

  const { isLoad } = useStore().ContactDetailsStore;
  const { profileId } = useStore().SettingsStore;

  const contactDetailsTodosStore = useStore().ContactDetailsTodosStore;
  const {
    table,
    filterData,
    filters,
    overdueFilter,
    previousTodoToggleState,
    setCategoryFilter
  } = contactDetailsTodosStore;

  const onOverdueFilterChange = useCallback((
    event: ChangeEvent<{name?: string | undefined, value: unknown}>
  ) => {
    const { target: { value } } = event;
    contactDetailsTodosStore.setOverdueFilter(
      value as string,
      getOverdueFilterParamsObject(value as string)
    );
  },[ contactDetailsTodosStore ]);

  const onUserFilterChange = useCallback((value: Array<string>) => {
    contactDetailsTodosStore.setFilters({
      [TODOS_FILTER_NAMES.users]: value
    });
  },[ contactDetailsTodosStore ]);

  const onTypeFilterChange = useCallback((value: Item) => {
    const isRequest = Object.values(OFFICIAL_REQUEST_SUBCATEGORIES).some((subCategory) => subCategory === value);
    const filterName = isRequest ? TODOS_FILTER_NAMES.request : TODOS_FILTER_NAMES.category;
    const newValue = value === FILTER_ALL_BTN_LABEL
      ? null
      : {
        [filterName]: value
      };
    setCategoryFilter(newValue);
  }, [ setCategoryFilter ]);

  const onAddTodoClickWrapper = useCallback(() => onAddTodoClick(), [onAddTodoClick]);

  const { checked, onChange } = useCustomToggleState({
    initValue: previousTodoToggleState,
    onChangeCb: ({ isChecked }) => {
      const filterValue = isChecked ? filterData.stage : INIT_STAGE_FILTER_STATE;

      contactDetailsTodosStore.setPreviousTodoToggleState(isChecked);
      contactDetailsTodosStore.setFilters({
        [TODOS_FILTER_NAMES.stage]: filterValue,
      });
    }
  });

  return (
    <TodosPreTablePanel
      checkedToggle={ checked }
      currentUserId={ profileId }
      filters={ filters }
      filtersData={ filterData }
      isLoad={ isLoad }
      isShowPreviousToggle={ true }
      onAddTodoClick={ onAddTodoClickWrapper }
      onCategoryFilterChange={ onTypeFilterChange }
      onChangeToggle={ onChange }
      onOverdueFilterChange={ onOverdueFilterChange }
      onUserFilterChange={ onUserFilterChange }
      overdueFilterValue={ overdueFilter.type }
      toDosTableData={ table }
    />
  );
});

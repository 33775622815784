import React, { useMemo } from 'react';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';

import { Wrapper } from './styles';

import { DueDateProps, AttentionColor } from './types';

import { getDueDateColor } from '@/shared/utils/getDueDateColor';

export const DueDate = ({
  name,
  control,
  isDisabled,
  dateValue
}: DueDateProps) => {
  const color = useMemo<AttentionColor>(() => getDueDateColor(dateValue), [dateValue]);

  return (
    <Wrapper $color={ color }>
      <HFDatePicker
        ControllerProps={ {
          shouldUnregister: true
        } }
        control={ control }
        disabled={ isDisabled }
        name={ name  }
        showError={ false }
      />
    </Wrapper>
  );
};

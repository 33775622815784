import React, { useMemo } from 'react';

import { useStore } from '@store';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFRank from '@modules/HookFormComponents/HFRank';
import HFUserSingleSelect from '@modules/HookFormComponents/HFUserSingleSelect';
import {
  LeftColumnWrapper,
  RightColumnWrapper,
  RightColumnWrapperVariantThree
} from '@modules/AddContactModal/components/Columns';

import {
  CONTACT_TYPE,
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH_V_THREE,
  RIGHT_COLUMN_WIDTH,
} from '@modules/AddContactModal/data';


function ProfileDetails({ properties, header, type, isHouseHold }) {
  const { profile } = useStore().SettingsStore;

  const { control, options } = properties;

  const businessType = type === CONTACT_TYPE.Business;

  const rightColumnSize = useMemo(() => (
    isHouseHold ? RIGHT_COLUMN_WIDTH_V_THREE : RIGHT_COLUMN_WIDTH
  ),[isHouseHold]);

  const RightWrapper = useMemo(() => (
    isHouseHold ? RightColumnWrapperVariantThree : RightColumnWrapper
  ),[isHouseHold]);


  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ header }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Core Classification' }
          >
            <HFSelect
              control={ control }
              name={ '1.classification' }
              options={ options.classification }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            label={ 'Rank' }
          >
            <FormBlock.VerticalCenteredWrapper>
              <HFRank
                control={ control }
                name='1.rating'
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Primary Advisor' }
          >
            <HFUserSingleSelect
              control={ control }
              initLinkedContact={ profile }
              name={ '1.officePrimaryAdvisor' }
              placeholder={ 'Select Primary Advisor' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            isRequired={ true }
            label={ 'Status' }
          >
            <HFSelect
              control={ control }
              name={ '1.status' }
              options={ options.statuses }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Source' }
          >
            <HFSelect
              control={ control }
              name={ '1.sourceId' }
              options={ options.sources }
              placeholder={ 'Select Source' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ rightColumnSize }
            label={ 'Source Category' }
          >
            <HFSelect
              control={ control }
              name={ '1.sourceCategory' }
              options={ options.sourcesCategories }
              placeholder={ 'Select Source' }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>

      {
        businessType &&
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Date Founded' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disableFuture: true
                } }
                control={ control }
                name={ '1.birthdate' }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
      }
    </FormBlock>
  );
}

export default ProfileDetails;

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { ActivityPopup } from '@modules/ActivityPopup';
import ContactActivitiesRow from '../ContactActivitiesRow';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table, { useGlobalCheckbox, useRowCheckbox } from '@components/Table';

import {
  COLUMNS
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsActivities/data';

import { ActivitiesTableProps } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { MODAL_TYPE } from '@constants/modalTypes';

export const ContactActivitiesTable = observer(({
  onAddActivityClick,
  onDeleteActivityClick,
}: ActivitiesTableProps) => {

  const modalStore = useStore().ModalStore;
  const contactDetailsActivitiesStore = useStore().ContactDetailsActivitiesStore;
  const { isLoad } = useStore().ContactDetailsStore;
  const { table, saveOrUpdateActivity, deleteActivity, isPageActive  } = contactDetailsActivitiesStore;

  const onEditActivity = useCallback((id: IdType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        id,
        editMode: true,
        onConfirm: saveOrUpdateActivity,
        onDelete: deleteActivity
      },
      component: ActivityPopup,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ modalStore ]);

  const {
    getCheckboxState,
    onRowCheckboxClick
  } = useRowCheckbox({
    onGlobalCheckboxClickCallback: table.setSelectedIds,
    selectedIds: table.selectedIDs,
  });

  const {
    isGlobalCheckboxChecked,
    isGlobalCheckboxIndeterminate,
    onGlobalCheckboxClick
  } = useGlobalCheckbox({
    pageItems: table.items,
    selectedIds: table.selectedIDs,
    onGlobalCheckboxClickCallback: table.setSelectedIds
  });

  const onRowBookmark = useCallback((id: IdType, state: boolean) => {
    contactDetailsActivitiesStore.updateFlags([{ id }], state);
  },[ contactDetailsActivitiesStore ]);

  return (

    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: isGlobalCheckboxChecked,
            indeterminate: isGlobalCheckboxIndeterminate,
            onChange: onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isLoad && table.items.length > 0 &&
            table.items.map(item => (
              <ContactActivitiesRow
                data={ item }
                isCheckboxActive={ getCheckboxState(item) }
                key={ item.id }
                onCheckboxClickCb={ onRowCheckboxClick }
                onDeleteActivity={ onDeleteActivityClick }
                onEditActivity={ onEditActivity }
                onRowBookmarkCb={ onRowBookmark }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isLoad &&
          table.items.length === 0 && (
            <ContentCenter>
              <NoDataItemsView
                addActionButtonClickHandler={ onAddActivityClick }
                addActionButtonLabel={ 'Add Activity' }
                withAction={ true }
              />
            </ContentCenter>
          )
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>

  );
});

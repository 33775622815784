import React from 'react';

import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';

import { TextWithTooltipProps } from './types';

import { DATA_CUSTOM_STYLE_ATTRIBUTE_NAME } from '@constants/style';

export const TextWithTooltip = ({
  $fontColor,
  $fontSize,
  $lineHeight,
  $marginLeft,
  $marginRight,
  $maxWidth,
  $paddingLeft,
  $paddingRight,
  dataCustomStyleAttributeName = DATA_CUSTOM_STYLE_ATTRIBUTE_NAME,
  dataCustomStyleAttributeValue,
  onClick,
  text,
}: TextWithTooltipProps) => (
  <Tooltip
    displayOnlyOnOverflow={ true }
    title={ text }
  >
    <TextEllipsis
      $fontColor={ $fontColor }
      $fontSize={ $fontSize }
      $lineHeight={ $lineHeight }
      $marginLeft={ $marginLeft }
      $marginRight={ $marginRight }
      $maxWidth={ $maxWidth || 'unset' }
      $paddingLeft={ $paddingLeft }
      $paddingRight={ $paddingRight }
      {
        ...(dataCustomStyleAttributeValue)
          ? { [dataCustomStyleAttributeName]: dataCustomStyleAttributeValue }
          : null
      }
      onClick={ onClick }
    >
      { text }
    </TextEllipsis>
  </Tooltip>

);

import { TrashTabValues } from '@pages/Trash/types';

export const getTypeNameOfCurrentView = (currentTrashView: TrashTabValues) => {
  switch (currentTrashView) {
  case TrashTabValues.Contacts:
    return { one: 'contact', many: 'contacts' };
  case TrashTabValues.Lists:
    return { one: 'list', many: 'lists' };
  case TrashTabValues.Activities:
    return { one: 'activity', many: 'activities' };
  case TrashTabValues.Tasks:
    return { one: 'task', many: 'tasks' };
  case TrashTabValues.SalesCycles:
    return { one: 'sales cycle', many: 'sales cycles' };
  case TrashTabValues.AccountsAndPlans:
    return { one: 'account', many: 'accounts' };
  case TrashTabValues.ClientOpportunities:
    return { one: 'opportunity', many: 'opportunities' };
  default: {
    const unexpected: never = currentTrashView;
    throw new TypeError(`Unexpected status: ${unexpected}`);
  }
  }
};
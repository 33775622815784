import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useToggle } from '@customHooks';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@/modules/HookFormComponents/HFTextInput';
import Tooltip from '@components/Tooltip/Tooltip';

import { ActivityPopupFieldsName, LABEL_WIDTH } from '@/modules/ActivityPopup/data';

const THRESHOLD_OF_SYMBOLS_NUMBER_TO_ELLIPSIS = 82;

export const ActivityNameInput = () => {

  const { control, getValues } = useFormContext();

  const [ isShowTooltip, toggleShowTooltip ] = useToggle(false);
  const [ tooltipContent, setTooltipContent ] = useState('');

  const onMouseEnter = useCallback(() => {
    const values = getValues();
    const inputValue = values?.[ActivityPopupFieldsName.name] || '';
    if(inputValue.length >= THRESHOLD_OF_SYMBOLS_NUMBER_TO_ELLIPSIS) {
      setTooltipContent(inputValue);
      toggleShowTooltip(true);
    }
  }, [getValues, toggleShowTooltip]);

  const onMouseLeave = useCallback(() => {
    toggleShowTooltip(false);
  }, [toggleShowTooltip]);

  const onFocus = useCallback(() => {
    toggleShowTooltip(false);
  }, [toggleShowTooltip]);

  return (
    <FormBlock.FormField
      $labelWidth={ LABEL_WIDTH.common }
      isRequired={ true }
      label={ 'Activity Name' }
    >
      <Tooltip
        PopperProps={ {
          popperOptions: {
            modifiers: {
              flip: { enabled: false },
              offset: {
                enabled: true,
                offset: '0, -5px'
              }
            }
          }
        } }
        open={ isShowTooltip }
        title={ tooltipContent }
      >
        <HFTextInput
          inputProps={ {
            placeholder: 'Enter Activity Name',
            maxLength: 150,
            style: {
              textOverflow: 'ellipsis'
            },
            onMouseEnter,
            onMouseLeave,
          } }
          control={ control }
          name={ ActivityPopupFieldsName.name }
          onFocus={ onFocus }
        />
      </Tooltip>
    </FormBlock.FormField>
  );
};

import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { useStore } from '@store';

import { Content } from '../components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import HFPasswordInput from '@modules/HookFormComponents/HFPasswordInput';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Preloader from '@components/Preloader';

import { COMMON_ERROR_MESSAGE, FORM_DEFAULTS_VALUES, FORM_FIELD_NAMES } from './data';
import { ROUTES } from '@constants/routes';
import { validationSchema } from './validationSchema';

import { ErrorSubTitle, ForgotPasswordWrapper } from './styles';

import { LoginErrorData } from './types';


export const Login = () => {

  const { RouterStore, SessionStore, AuthStore, SettingsStore } = useStore();

  const [ isInvalidData, setInvalidData ] = useState(false);
  const [ isLoading, setLoading ] = useState(false);
  const [ responseError, setResponseError ] = useState('');
  const [ showInfoBar, setShowInfoBar ] = useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: FORM_DEFAULTS_VALUES,
  });

  const { control, handleSubmit, setError } = methods;

  const onSubmitClick = useCallback(() => {
    handleSubmit(async (data) => {
      const { email, password } = data;

      try {
        setLoading(true);
        await AuthStore.login(email, password);

        await SettingsStore.getAppSettings();

        SessionStore.setDuration(SettingsStore.settings.automaticLogout);
        SessionStore.startSession();

        RouterStore.customPush(ROUTES.dashboard);
      }
      catch (error) {
        if(axios.isAxiosError(error)) {
          console.log(error);

          setInvalidData(true);

          setError(FORM_FIELD_NAMES.email, { type: 'custom', message: COMMON_ERROR_MESSAGE });
          setError(FORM_FIELD_NAMES.password, { type: 'custom', message: COMMON_ERROR_MESSAGE });

          setLoading(false);

          const { message } = error.response?.data as LoginErrorData;

          setResponseError(message.join('<br>'));
          setShowInfoBar(true);
        }
      }
    })();
  }, [ AuthStore, RouterStore, SessionStore, SettingsStore, handleSubmit, setError ]);

  const onCloseClick = useCallback(() => {
    setShowInfoBar(false);
  }, []);

  const onForgotPasswordClick = useCallback(() => {
    RouterStore.customPush(ROUTES.resetPassword);
  }, [ RouterStore ]);

  return (
    <Content>
      <Content.Header/>
      <Content.InfoBar
        barText={ responseError }
        isOpen={ showInfoBar }
        isSideMenu={ false }
        isVisibleCancelButton={ false }
        nameOfConfirmButton={ 'Close' }
        onCancelButtonClick={ () => {} }
        onConfirmButtonClick={ onCloseClick }
      />

      <Content.Main>
        <Content.Title>Member login</Content.Title>

        { isInvalidData &&
          <ErrorSubTitle>
            The supplied email address and password combination is invalid.
            <br/>Your account will be locked temporarily after 4 more failed attempts.
          </ErrorSubTitle>
        }

        <Content.Label>Email Address</Content.Label>
        <HFTextInput
          TextFieldProps={ {
            autoComplete: FORM_FIELD_NAMES.email
          } }
          control={ control }
          name={ FORM_FIELD_NAMES.email }
          placeholder={ 'Enter Email Address' }
        />

        <Content.Label>Password</Content.Label>
        <HFPasswordInput
          TextFieldProps={ {
            autoComplete: FORM_FIELD_NAMES.password
          } }
          control={ control }
          name={ FORM_FIELD_NAMES.password }
          placeholder={ 'Enter Password' }
        />

        <ForgotPasswordWrapper
          onClick={ onForgotPasswordClick }
        >
          Forgot your password?
        </ForgotPasswordWrapper>

        <BlockButton
          colorSchema={ COLOR_SCHEME_TYPES.contained }
          onClick={ onSubmitClick }
          style={ {
            marginTop: '20px'
          } }
          variant='contained'
        >
          LOGIN
        </BlockButton>
      </Content.Main>

      { isLoading && <Preloader/> }
    </Content>
  );
};

import { css } from 'styled-components';

import { ERROR_CONTAINER_STYLES, ERROR_SPACE_VALUE } from '@constants/style';



export const getInputsErrorStyles = (showError: boolean) => {
  if(showError) {
    return css`
        padding-bottom: ${ERROR_SPACE_VALUE}px;

        & .MuiFormHelperText-root {
          ${ERROR_CONTAINER_STYLES}
        }
    `;
  } else {
    return css`
        & .MuiFormHelperText-root {
          display: none;
        }
    `;
  }
};

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from '@constants/colors';

import { ItemProps, ItemTextProps, SubmenuLinkProps, SubmenuWrapperProps, WrapperProps } from './types';

const linkTextStyle = css`
  font-weight: 400;
  font-size: 16px;
`;

const activeIndicatorStyle = css`
  position: relative;
  
  &::after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: ${COLORS.orange};
  }
`;

const getItemStyles = ({ $isActive, $padding }: ItemProps) => css`
    display: flex;
    position: relative;
    align-items: center;
  
    padding: ${ $padding || '15px 23px 15px 20px'};
    color: ${COLORS.grey};
  
    ${$isActive && css`
      background-color: ${COLORS.menuSelect};
      ${activeIndicatorStyle};
    `}
  
    &:hover {
      ${!$isActive && css`
        background-color: ${COLORS.menuItemHover};
      `}
      cursor: pointer;
      color: ${COLORS.white};
    }
  `;

export const RootItem = styled.div<ItemProps>`
  ${(props) => getItemStyles(props)}
`;

export const RootItemLink = styled(Link)<ItemProps>`
  ${(props) => getItemStyles(props)}
  text-decoration: none;
`;

export const RootItemText = styled.div<ItemTextProps>`
  margin-left: ${({ $marginLeft }) => $marginLeft || '15px' };
  ${linkTextStyle}
`;

export const RootItemIcon = styled.div`
  width: 20px;
  height: 20px;
  
  & svg > path {
    fill: currentColor;
  }
`;

export const RootItemToggle = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: auto;
`;

export const SubMenuWrapper = styled.ul<SubmenuWrapperProps>`
  margin: 0;
  padding: 0;
  
  ${({ $isOpen }) => !$isOpen && css`
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 10000;
  `}
`;

export const SubMenuItem = styled.li`
  list-style: none;
  background-color: ${COLORS.menuBckSecond};
`;

export const SubMenuItemLink = styled(Link)<SubmenuLinkProps>`
  display: flex;
  padding: ${({ $isOpen }) => $isOpen 
    ? '15px 23px 15px 55px'
    : '15px 32px 15px 20px'
};
  color: ${COLORS.grey};
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 1px solid ${COLORS.menuLines};

  ${linkTextStyle};
  
  ${({ $isActive }) => $isActive && css`
    ${activeIndicatorStyle};
  `}
  
  &:hover {
    cursor: pointer;
    color: ${COLORS.white};
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  
  ${({ $isOpen }) => !$isOpen && css`
    &:hover ${SubMenuWrapper} {
      display: block;
    }
  `}

  ${({ $withoutSubmenu }) => !$withoutSubmenu && css`
    &:hover ${RootItemLink}::after {
      display: none;
    }
  `}
`;


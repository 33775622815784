import React from 'react';

import { OutOfSight } from './OutOfSight';

import { OutOfSightCommonWrapper } from './styles';

import { OutOfSightCommonProps } from './types';

export const OutOfSightCommon = ({ onClick }: OutOfSightCommonProps) => (
  <OutOfSightCommonWrapper onClick={ onClick }>
    <OutOfSight/>
  </OutOfSightCommonWrapper>
);
import styled, { css } from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

import { InnerWrapperProps, CustomCellProps } from './types';

import { TABLE_CELL_COMMON_STYLE } from '@constants/style';

export const TableRowContentWrapper = styled.div<InnerWrapperProps>`
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  
  ${({ $alignItems }) => $alignItems && css`
    align-items ${ $alignItems };
  ` }


  ${({ $paddingLeft }) => $paddingLeft && css`
    padding-left ${ $paddingLeft };
  ` }

  ${({ $height }) => $height && css`
    height:  ${ $height };
  ` }
`;

export const StyledTableRowCell = styled(TableCell)<CustomCellProps>`
  ${TABLE_CELL_COMMON_STYLE};
  
  ${({ dataCustomStyleAttributeName, dataCustomStyleAttributeValue }) =>
    dataCustomStyleAttributeName && dataCustomStyleAttributeValue && css`
      &:hover {
        & [${dataCustomStyleAttributeName}=${dataCustomStyleAttributeValue}] {
          text-decoration: underline;
        }
      }
  `}
`;

import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 30px 30px 10px 30px;
  width: 740px;
`;

export const NamesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  & > * {
    width: calc(50% - 10px);
  }
`;

export const LeftColumnWrapper = styled.div`
  width: 50%;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 60px;
`;

export const LeftLabelWrapper = styled.div`
  width: 140px;

  & > div {
    padding-bottom: 0;
  }
`;

import { isValid, parse } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const getTimeStampInMs = (
  date: string | Date,
  format: string = YEAR_MONTH_DAY
): number => {
  const isDateTypeString =  typeof date === 'string';
  const parsedDate = isDateTypeString
    ? parse(date, format, new Date())
    : date;

  if(!isDateTypeString){
    return date.getTime();
  }
  
  if(isValid(parsedDate)) {
    return parsedDate.getTime();
  } else {
    return 0;
  }
};
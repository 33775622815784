import React from 'react';

import { useToggle } from '@customHooks';

import { CrossIcon, FilterIcon, StyledBadge, StyledButton } from './styles';


function FilterButton({
  badgeClickHandler,
  buttonCLickHandler,
  withIcon = true,
  badgeCount,
  enableFilterMode,
  label = 'Filters',
}) {
  const [ hoverState, setHoverState ] = useToggle(false);

  const buttonClickHandler = (event) => {
    event.stopPropagation();
    buttonCLickHandler();
  };

  return (
    <StyledBadge
      $hoverState={ hoverState }
      badgeContent={ hoverState && badgeCount ? <CrossIcon/> : badgeCount }
      onClick={ () => badgeClickHandler() }
      onMouseEnter={ setHoverState }
      onMouseLeave={ setHoverState }
    >
      <StyledButton
        $enableFilterMode={ enableFilterMode }
        color="primary"
        disableElevation
        onClick={ buttonClickHandler }
        startIcon={ withIcon ? <FilterIcon $enableFilterMode={ enableFilterMode }/> : null }
        variant={ enableFilterMode ? 'outlined' : 'contained' }
      >
        { label }
      </StyledButton>
    </StyledBadge>
  );
}

export default FilterButton;

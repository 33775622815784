import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';

import { MenuItemProps, SelectStyleProps, StyledFormControlProps } from '@components/CustomSelect/types';

import { getInputsErrorStyles } from '@/shared/utils/getInputsErrorStyles';
import { INPUT_HELPER_TEXT_STYLE } from '@constants/style';

export const StyledFormControl= styled(FormControl)<StyledFormControlProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ $showError }) => getInputsErrorStyles(Boolean($showError))};
`;

export const StyledSelect = styled(Select)<SelectStyleProps>`
  & .MuiSelect-root {
    height: 30px;
    flex-grow: 1;
    box-sizing: border-box;
    background-color: ${COLORS.white};
    
    font-size: ${({ $fontSize }) => $fontSize || '14px' };
  }

	& .MuiSelect-icon{
		margin-top: 5px;
	}
  
  &.Mui-disabled{
    & .MuiSelect-icon{
      color: currentColor;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)<MenuItemProps>`
  position: relative;
  font-size: ${({ $menuItemFontSize }) => $menuItemFontSize || '14px'};

  &.Mui-selected {
    background-color: ${ COLORS.select };
    color: ${ COLORS.orange };
    
      &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        top: 0;
        width: 2px;
        background-color: ${ COLORS.orange };
      }
  }
;
`;

export const StyledFormErrorText = styled(FormHelperText)``;

export const StyledFormHelperText = styled.p`
    ${INPUT_HELPER_TEXT_STYLE}
`;

export const Placeholder = styled.span`
	color: ${COLORS.grey};
`;

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LABEL_MARGIN_DIRECTION, SIZES_MAP } from './data';

import { StyledFormControlLabelProps, StyledCheckboxProps } from './types';

export const StyledFormControlLabel = styled(FormControlLabel)<StyledFormControlLabelProps>`
  position: relative;
  align-items: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  
  & .MuiIconButton-label{
    position: relative;
    z-index: 1;
    
      &:hover::after {
          position: absolute;
          content: '';
          width: 34px;
          height: 34px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${COLORS.select};
          z-index: -1;
          border-radius: 50%;
        }
      }
  }

  & .MuiIconButton-root {
    padding: 0;
    width: 18px;
    height: 18px;
  }
  
  & .MuiFormControlLabel-label {
    line-height: 1 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${ COLORS.primaryText };
    
    ${ ({ $disabled }) => $disabled && 'opacity: 0.3' };

    ${({ $distanceFromLabel, labelPlacement }) => $distanceFromLabel && labelPlacement && css`
        margin-${LABEL_MARGIN_DIRECTION[labelPlacement]}: ${$distanceFromLabel};
    `}
}
`;

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  ${({ $withPaddings }) => !$withPaddings && css`
    padding: 0 !important;
  `}
  
  & .MuiIconButton-label {
    ${({ $size }) => $size && css`
      width: ${SIZES_MAP[$size]}px;
      height: ${SIZES_MAP[$size]}px;
    `}
    
      & > svg {
        width: inherit;
        height: inherit;
      }
  }
`;


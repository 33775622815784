import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const HeaderWrapper = styled.div`
  padding: 0 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  background-color: ${COLORS.white};
  
  &:nth-child(2n){
    background-color: ${COLORS.background};
  }
`;

export const RowTitle = styled.div`
  width: 180px;
  font-size: 14px;
  color: ${COLORS.helpText};
`;

export const Delimiter = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${COLORS.borders};
`;



import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 64px;
  min-height: 64px;
  padding: 0 20px;
`;

export const NextAppointment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 50%;
  margin-left: auto;
  line-height: 140%;
`;

export const StyledTaskNameAndStatus = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledDate = styled.div`
  width: 100%;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.helpText};
  text-align: right;
`;

export const UserItemWrapper = styled.div`
  & > div {
    cursor: pointer;
  }
`;

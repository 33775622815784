import React from 'react';
import { observer } from 'mobx-react';
import { Link, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import CustomTabs from '@components/CustomTabs';
import Surface from '@components/BasicLayoutComponents/Surface';

import { TO_DOS_TABS_DATA } from './data';

const PRE_HEADER_STYLE_PROPS = {
  paddingLeft: '5px !important'
};

export const PageHeader = observer(() => {
  const {
    isFiltersOpen,
    resetFilters,
    selectedFiltersCount,
    toggleFiltersIsOpen,
  } = useStore().ToDosStore;

  const { params: { tab } } = useRouteMatch<{ 'tab': string }>();

  const onBadgeClick = () => resetFilters();

  return (
    <Surface.PreHeader
      $height={ '64px' }
      badgeClickHandler={ onBadgeClick }
      badgeCount={ selectedFiltersCount }
      buttonCLickHandler={ toggleFiltersIsOpen }
      enableFilterMode={ !isFiltersOpen }
      restBoxProps={ PRE_HEADER_STYLE_PROPS }
      withFilterButton={ true }
    >
      <CustomTabs value={ tab }>
        {
          TO_DOS_TABS_DATA.map((tab, index) => {
            return (
              <CustomTabs.Tab
                $fontSize={ '16px' }
                $minHeight={ '64px' }
                component={ Link }
                icon={ <tab.icon/> }
                key={ tab.value }
                label={ tab.label }
                replace={ true }
                to={ tab.link }
                value={ tab.value }
              />
            );
          })
        }
      </CustomTabs>
    </Surface.PreHeader>
  );
});

import styled, { css } from 'styled-components';

import TextField from '@material-ui/core/TextField';
import { StyledTextFieldProps } from '@components/CustomTextField/types';

import { getInputsErrorStyles } from '@/shared/utils/getInputsErrorStyles';

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  position: relative;
  width: 100%;
  
  ${({ $showError }) => getInputsErrorStyles(Boolean($showError))};
`;

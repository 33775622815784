import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const FirstRow = styled.div`
  margin-bottom: 15px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CriteriaLabel = styled.span`
  margin-right: 5px;
`;

export const SecondRow = styled.div`
  display: flex;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  
  margin-left: 10px;
  margin-right: auto;
`;

export const ContactsLabel = styled.span`
  margin-right: 15px;
  color: ${COLORS.helpText}
`;

export const ActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const ToggleWrapper = styled.div`
  margin-right: 20px;
`;

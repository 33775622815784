import React, { Fragment, useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { ListCriteriaHeader } from '../ListCriteriaHeader';

import {
  ListCriteriaFieldsBlock,
  ListCriteriaOperandField
} from './components';

import { CriteriaFormWrapper } from '@/shared/types/lists';
import { CRITERIA_FIELD_BLOCK_NAMES, LIST_CRITERIA_BLOCK_KEY_NAME, LIST_CRITERIA_ROOT_NAME } from '@constants/lists';
import { DevTool } from '@hookform/devtools';


export const ListCriteriaElementsWrapper = () => {
  const { control, getValues } = useFormContext<CriteriaFormWrapper>();
  const { append, remove, fields, update, } = useFieldArray({
    control,
    name: LIST_CRITERIA_ROOT_NAME,
    keyName: LIST_CRITERIA_BLOCK_KEY_NAME
  });


  const allFields = useWatch({
    control,
    //@ts-ignore
    name: LIST_CRITERIA_ROOT_NAME
  });

  const onDelete = useCallback(async (index: number) => {
    if (index === 0 && allFields?.length >= 2) {
      const updateIndex = index + 1;
      const nextData = await getValues(`${LIST_CRITERIA_ROOT_NAME}.${updateIndex}` as any);
      delete nextData?.operand;
      await update(updateIndex, nextData);
      remove(index);
    } else {
      remove(index);
    }
  }, [allFields?.length, getValues, remove, update]);

  return (
    <>
      <ListCriteriaHeader append={ append }/>
      {
        fields.map((field, index) => (
          <Fragment key={ field?.[LIST_CRITERIA_BLOCK_KEY_NAME] }>
            {
              field.operand &&
              <ListCriteriaOperandField
                $paddingBottom={ 30 }
                $paddingTop={ 30 }
                name={ `${LIST_CRITERIA_ROOT_NAME}.${index}.${CRITERIA_FIELD_BLOCK_NAMES.operand}` }
                value={ field.operand }
              />
            }
            <ListCriteriaFieldsBlock
              index={ index }
              name={ `${LIST_CRITERIA_ROOT_NAME}.${index}.${CRITERIA_FIELD_BLOCK_NAMES.criteria}` }
              onBlockDelete={ onDelete }
            />

          </Fragment>
        ))
      }
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';

import { UseCustomToggleStateProps, UseCustomToggleStateReturn } from './types';

export const useCustomToggleState = ({
  initValue = false,
  onChangeCb
}:UseCustomToggleStateProps): UseCustomToggleStateReturn => {
  const [ checked, setValue ] = useState(initValue);

  useEffect(() => {
    setValue(initValue);
  }, [ initValue ]);

  const onChange = useCallback((e: React.ChangeEvent<{}>, isChecked: boolean) => {
    setValue(isChecked);
    onChangeCb({
      event: e,
      value: (e.target as HTMLInputElement).value,
      isChecked,
    });
  }, [onChangeCb]);

  return {
    checked,
    onChange
  };
};

import React from 'react';

import { ModalProps } from './types';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';

import { FooterContentWrapper } from './components';

import {
  Content,
  Footer,
  Header,
  HeaderTitleWrapper,
  StyledDialog,
  TopPanel
} from './styles';

export const Modal = ({
  $isHide,
  children,
  headerProps,
  isCloseDisabled,
  onClose,
  title,
}: ModalProps) => {
  return (
    <StyledDialog
      $isHide={ $isHide }
      onEscapeKeyDown={ onClose }
      open={ true }
    >
      <Header { ...headerProps }>
        <HeaderTitleWrapper>
          { title }
        </HeaderTitleWrapper>
        <CustomIconButton
          disabled={ Boolean(isCloseDisabled) }
          onClick={ onClose }
          type={ ICON_BUTTON_TYPES.cross }
        />
      </Header>
      { children }
    </StyledDialog>
  );
};

Modal.Footer = Footer;
Modal.FooterContentWrapper = FooterContentWrapper;
Modal.Content = Content;
Modal.TopPanel = TopPanel;

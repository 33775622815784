import React, { Fragment } from 'react';

import { InfoText, StyledButton, StyledMuiToolbar } from './styles';
import { SaveBarProps } from './types';

export const NotificationBar = ({
  barText = 'You need to save the information',
  isOpen = false,
  isSideMenu = true,
  isVisibleCancelButton = true,
  nameOfCancelButton = 'Cancel',
  nameOfConfirmButton = 'Save',
  onCancelButtonClick,
  onConfirmButtonClick,
}: SaveBarProps): JSX.Element => {
  return (
    <Fragment>
      { isOpen &&
        <StyledMuiToolbar
          disableGutters
          isSideMenu={ isSideMenu }
        >
          <InfoText>
            { barText }
          </InfoText>
          { isVisibleCancelButton &&
            <StyledButton
              color='secondary'
              onClick={ onCancelButtonClick }
              variant='outlined'
            >
              { nameOfCancelButton }
            </StyledButton>
          }
          <StyledButton
            color='primary'
            onClick={ onConfirmButtonClick }
            variant='contained'
          >
            { nameOfConfirmButton }
          </StyledButton>
        </StyledMuiToolbar>}
    </Fragment>
  );
};

import React from 'react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import TextWithTooltip from '@components/TextWithTooltip';

import { LabelWrapper, WrapperWithLabel } from './styles';

import { EntityProps } from './types';
import { LinkedAccountAndPolicy } from '@/shared/types/accountsAndPlan';


export const AccountPolicy = ({ data }: EntityProps<LinkedAccountAndPolicy>) => {

  const amountConverted = (data?.linkedContactType === LINKED_ITEM_TYPES.account ||
    data?.linkedContactType === LINKED_ITEM_TYPES.policy) && data.value ?
    formatNumberToAmount(data.value) : '-';
  
  return (
    <>
      <WrapperWithLabel>
        <LabelWrapper>Institution</LabelWrapper>
        <TextWithTooltip text={ data.institutionName || '-' }/>
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Acct #</LabelWrapper>
        <TextWithTooltip
          text={ data.accountNumber ? `#${data.accountNumber}` : '-' }
        />
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Amount</LabelWrapper>
        <TextWithTooltip text={ amountConverted }/>
      </WrapperWithLabel>        
    </>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';

import CustomTabs from '@components/CustomTabs';
import Surface from '@components/BasicLayoutComponents/Surface';
import {
  ListContacts,
  ListSetup
} from '@pages/NewContacts/subpages/Lists/subpages/ListsDetails/components/components';

import { getListItemLink } from '@/shared/utils/listsRoutesUtils';

import { LISTS_TYPES } from '@constants/lists';
import { MANUAL_LIST_TABS } from './data';
import { LISTS_DYNAMIC_TABS } from '@constants/routes/contacts';

import { ListsItemsRouteParams } from '@pages/NewContacts/subpages/Lists/subpages/ListsDetails/types';


export const ManualList = observer(() => {
  const listContactsStore = useStore().ListsStore.ListContactsStore;
  const { isListContactsFiltersOpen } = listContactsStore;
  const { params: { id, subTab } } = useRouteMatch<ListsItemsRouteParams>();

  return (
    <>
      <Surface.PreHeader
        $height={ '56px' }
        badgeClickHandler={ listContactsStore.resetFilters }
        badgeCount={ listContactsStore.filtersCount }
        buttonCLickHandler={ listContactsStore.toggleFiltersState }
        enableFilterMode={ !isListContactsFiltersOpen }
        withFilterButton={ subTab === LISTS_DYNAMIC_TABS.contacts }
      >
        <Surface.TabWrapper>
          <CustomTabs
            $height={ '56px' }
            value={ subTab }
          >
            {
              MANUAL_LIST_TABS.map((tab) => {
                const Icon = tab.icon as React.ElementType;
                return (
                  <CustomTabs.Tab
                    $minHeight={ '56px' }
                    component={ Link }
                    icon={ <Icon /> }
                    key={ tab.value }
                    label={ tab.label }
                    replace={ true }
                    to={ tab.getLink(id) }
                    value={ tab.value }
                  />
                );
              })
            }
          </CustomTabs>
        </Surface.TabWrapper>
      </Surface.PreHeader>
      <Switch>
        <Route path={ getListItemLink(LISTS_TYPES.Manual, id)!.contacts }>
          <ListContacts
            listId={ parseInt(id,10) }
            listType={ LISTS_TYPES.Manual }
          />
        </Route>
        <Route path={ getListItemLink(LISTS_TYPES.Manual, id)!.setup }>
          <ListSetup
            listId={ id }
            listType={ LISTS_TYPES.Dynamic }
          />
        </Route>
      </Switch>
    </>
  );
});

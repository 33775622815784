import React from 'react';

import FilterButton from '@components/FilterButton';

import { PreHeaderProps, WITH_FILTER_BTN_PROP_NAME } from './types';

import { FiltersBtnWrapper, Wrapper } from './styles';

export const PreHeader = (props: PreHeaderProps) => {
  const { $height, children, restBoxProps } = props;

  return (
    <Wrapper
      $height={ $height }
      { ...restBoxProps }
    >
      { children }
      {
        WITH_FILTER_BTN_PROP_NAME in props && props[WITH_FILTER_BTN_PROP_NAME] &&
        <FiltersBtnWrapper>
          <FilterButton
            badgeClickHandler={ props.badgeClickHandler }
            badgeCount={ props.badgeCount }
            buttonCLickHandler={ props.buttonCLickHandler }
            enableFilterMode={ props.enableFilterMode }
          />
        </FiltersBtnWrapper>
      }
    </Wrapper>
  );
};

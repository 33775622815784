import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { DueDateStylesProps } from './types';

export const CompletedIconWrapper = styled.div`
  display: flex;
  align-items: center;

  & svg path {
    fill: ${COLORS.green};
  }
`;

export const TaskIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 100%;

  & svg path {
    fill: ${COLORS.orange};
  }
`;

export const TextCellWrapper = styled.div`
  display: grid;
`;

export const DueDate = styled.div<DueDateStylesProps>`
  ${({ $color }) => $color && `color: ${$color};`}
`;
import { DeleteUserOption, UserDeletePopupInputNamesType } from './types';
import { CustomRadioLabelProps } from '@components/CustomRadio/types';

const COMMON_LABEL_OPTIONS = {
  marginRight: '16px',
  alignItems: 'flex-start'
};

export const OPTIONS_NAME_TO_DELETE_USER: Array<CustomRadioLabelProps> = [{
  value: DeleteUserOption.Replace,
  label: DeleteUserOption.Replace,
  style: {
    marginBottom: '20px',
    ...COMMON_LABEL_OPTIONS
  }
},
{
  value: DeleteUserOption.Unassigned,
  label: DeleteUserOption.Unassigned,
  style: {
    ...COMMON_LABEL_OPTIONS
  }
}];

export const UserDeletePopupInputNames: UserDeletePopupInputNamesType = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  deleteOption: 'deleteOption',
  replacingUser: 'replacingUser',
};

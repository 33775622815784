import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Notes, Tabs, TabsComponentRender } from './components';

import { useNotesAndHistoryLocalState } from './NotesAndHistoryLocalState';
import { NOTE_AND_HISTORY_TYPE } from './data';

import { NewNoteAndHistoryProps } from './types';

import { OnlyNotesWrapper, Wrapper, ContentWrapper } from './styles';

export const NotesAndHistory = observer(({
  $maxHeight,
  components,
  control,
  creationDateFieldName,
  currentUser,
  deleteConfirmationText,
  disableAddNoteTooltipText = '',
  isAbleToEdit = true,
  isAddNoteBtnDisabled,
  linkedContactData,
  names,
  noteAndHistoryType = NOTE_AND_HISTORY_TYPE.module,
  noteContactOptions,
  onAddEditEnd,
  onAddEditStart,
  onDeleteNote,
  onEntityIdClick,
  onLoadNote,
  onSaveNote,
  predefinedTags,
  tabs,
  title,
}: NewNoteAndHistoryProps) => {
  const { resetState } = useNotesAndHistoryLocalState();

  const renderNotes = useCallback(() => {
    return (
      <Notes
        $padding={ '24px 20px 0px 20px' }
        control={ control }
        creationDateFieldName={ creationDateFieldName }
        currentUser={ currentUser }
        deleteConfirmationText={ deleteConfirmationText }
        disableAddNoteTooltipText={ disableAddNoteTooltipText }
        isAbleToEdit={ isAbleToEdit }
        isAddNoteBtnDisabled={ isAddNoteBtnDisabled }
        isShowRowWithTitleAndAddButton={ !tabs }
        linkedContactData={ linkedContactData }
        names={ names }
        noteAndHistoryType={ noteAndHistoryType }
        noteContactOptions={ noteContactOptions }
        onAddEditEnd={ onAddEditEnd }
        onAddEditStart={ onAddEditStart }
        onDeleteNote={ onDeleteNote }
        onEntityIdClick={ onEntityIdClick }
        onLoadNote={ onLoadNote }
        onSaveNote={ onSaveNote }
        predefinedTags={ predefinedTags }
        title={ title }
      />
    );
  },[
    control, creationDateFieldName, currentUser, disableAddNoteTooltipText, isAddNoteBtnDisabled, linkedContactData,
    names, noteContactOptions, onAddEditEnd, onAddEditStart, predefinedTags, tabs, title,
  ]);

  useEffect(() => {
    return resetState;
  }, [resetState]);

  if(tabs){
    return (
      <Wrapper $maxHeight={ $maxHeight }>
        <Tabs
          disableTooltipText={ disableAddNoteTooltipText }
          isAddButtonDisabled={ isAddNoteBtnDisabled }
          tabs={ tabs }
        />
        <ContentWrapper>
          <TabsComponentRender
            components={ components }
            renderNotes={ renderNotes }
          />
        </ContentWrapper>
      </Wrapper>
    );
  }

  return (
    <OnlyNotesWrapper $maxHeight={ $maxHeight }>
      { renderNotes() }
    </OnlyNotesWrapper>
  );
});

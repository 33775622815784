import React, { ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useAddUserPopupLocalStore } from './store';

import CustomTabs from '@/components/CustomTabs';
import { AddUser, UserProfile } from './components';

import { ADD_USER_POPUP_TABS } from '@pages/NewUserSettings/components/Tabs/Users/components/AddUserPopup/data';

import { TabsValues } from '@pages/NewUserSettings/components/Tabs/Users/components/AddUserPopup/types';

import { LeftColumn, MiddleColumn, Wrapper } from './styles';


export const Content = observer(() => {
  
  const addUserPopupLocalStore = useAddUserPopupLocalStore();
  const { currentTab } = addUserPopupLocalStore;

  const handleChange = useCallback((e: ChangeEvent<{}>, value: any) => {
    addUserPopupLocalStore.setCurrentTab(value);
  }, [ addUserPopupLocalStore ]);

  return (
    <Wrapper>
      <LeftColumn>
        <CustomTabs
          onChange={ handleChange }
          orientation={ 'vertical' }
          value={ currentTab }
        >
          {
            ADD_USER_POPUP_TABS.map(({ value, label }) => (
              <CustomTabs.Tab
                $minHeight={ '40px' }
                key={ value }
                label={ label }
                value={ value }
              />
            ))
          }
        </CustomTabs>
      </LeftColumn>
      <MiddleColumn>
        {
          currentTab === TabsValues.AddUser && <AddUser/>
        }
        {
          currentTab === TabsValues.UserProfile && <UserProfile/>
        }
      </MiddleColumn>
    </Wrapper>
  );
});    
import React from 'react';

import { Controller } from 'react-hook-form';

import { HFTextInputProps } from '@modules/HookFormComponents/HFTextInput/types';

import CustomTextField from '@components/CustomTextField';

export const HFTextInput = ({
  ControllerProps = {},
  inputProps = {},
  TextFieldProps = {},
  control,
  disableErrorStyle = false,
  disabled,
  name,
  onFocus = () => {},
  onlyNumbers,
  placeholder = '',
  showErrorText = true,
}: HFTextInputProps) => (
  <Controller
    control={ control }
    defaultValue={ '' }
    name={ name }
    render={ ({
      field: {
        value,
        onChange,
        onBlur,
        ref,
      },
      fieldState:{ invalid, error } }
    ) => (
      <CustomTextField
        inputProps={ inputProps }
        TextFieldProps={ {
          ...TextFieldProps,
          innerRef: ref,
        } }
        disabled={ disabled }
        error={ !disableErrorStyle && invalid }
        fullWidth={ true }
        helperText={ !disableErrorStyle && invalid && error?.message }
        name={ name }
        onBlur={ onBlur }
        onChange={ onChange }
        onFocus={ onFocus }
        onlyNumbers={ onlyNumbers }
        placeholder={ placeholder }
        showErrorText={ showErrorText }
        value={ value || '' }
      />
    ) }
    { ...ControllerProps }
  />
);

import React from 'react';

import { BodyProps } from './types';

import { StyledTableBody } from './styles';

export const Body = ({ children }: BodyProps) => (
  <StyledTableBody>
    { children }
  </StyledTableBody>
);

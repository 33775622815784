import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CONTACT_RANKS } from '@constants/common';
import { ACTIVE_RANK_COLORS } from './data';
import { Item, Wrapper } from './styles';

// TODO Delete from _test ContactRank and stories too
// TODO Delete ContactRank and stories too

const Rank = ({
  initRank,
  onRankChange,
  ranksOptions = CONTACT_RANKS,
  disabled = false,
  drawSingle,
}) => {
  const isInitRankPass = initRank === 0 || initRank;
  const activeRankColor = ACTIVE_RANK_COLORS[initRank];
  const [isClicked, setIsClicked] = useState(false);

  const onRankClick = useCallback((rankValue) => () => {
    if(!isClicked){
      if (rankValue === initRank) {
        onRankChange(0);
      } else {
        onRankChange(rankValue);
      }

      setIsClicked(true);
    }
  },[initRank, isClicked, onRankChange]);

  useEffect(() => {
    if(isClicked){
      let timerId;
      timerId = setTimeout(() => {
        setIsClicked(false);
        clearTimeout(timerId);
      }, 250);
    }
  }, [isClicked]);

  if(drawSingle && isInitRankPass) {
    const { label, value } = ranksOptions.find(item => item.value === initRank) || {};
    const color = ACTIVE_RANK_COLORS[value];

    if(label && value) {
      return (
        <Item
          activeColor={ color }
          isActive={ initRank === value }
          key={ label }
          onClick={ () => {} }
        >
          { label }
        </Item>
      );
    }

    return null;
  }

  return (
    <Wrapper>
      {
        ranksOptions.map(({ value, label }) => {
          return (
            <Item
              activeColor={ activeRankColor }
              disabled={ disabled }
              isActive={ initRank === value }
              key={ label }
              onClick={ onRankClick(value) }
            >
              { label }
            </Item>
          );
        })
      }
    </Wrapper>
  );
};

Rank.propTypes = {
  initRank: PropTypes.number,
  onRankChange: PropTypes.func,
  ranksOptions: PropTypes.array,
  resettable: PropTypes.bool,
  disabled: PropTypes.bool,
  drawSingle: PropTypes.bool,
};

export default Rank;

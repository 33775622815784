import React, { useRef, useState, useMemo, useCallback } from 'react';
import { useDidUpdate } from '@customHooks';

import { swapArrayElementsByPivot } from '@utils/swapArrayElementsByPivot';

import {
  TimePickerInput,
  TimePickerPopover,
} from '@components/BasicFormComponents/TimePicker/components';

import {
  MERIDIAN,
  MERIDIAN_OPTIONS,
  HOURS_OPTIONS,
  MINUTES_OPTIONS
} from '@components/BasicFormComponents/TimePicker/data';

import {
  convertTwentyFourFormat,
  getMeridian,
  getSeparatedPopoverValues
} from '@components/BasicFormComponents/TimePicker/utils';
import { convertToTwelveFormat } from '@/shared/utils/timeConverter';

import { Wrapper } from './styles';

export const TimePickerMain = ({
  onChange,
  initValue,
  errorMessage,
  disabled,
  forcedState,
  $width,
}) => {
  const inputRef = useRef(null);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const [ time, setTime ] = useState(convertToTwelveFormat(initValue));
  const [ meridian, setMeridian ] = useState(getMeridian(initValue));

  const open = Boolean(anchorEl);
  const popoverHours = getSeparatedPopoverValues(time).hours;
  const popoverMinutes = getSeparatedPopoverValues(time).minutes;
  const popoverHoursOptions = useMemo(() => swapArrayElementsByPivot(popoverHours,HOURS_OPTIONS),[ anchorEl ]);
  const popoverMinutesOptions = useMemo(() => swapArrayElementsByPivot(popoverMinutes,MINUTES_OPTIONS),[ anchorEl ]);

  const setStateFromPopover = ({ hoursValue, minutesValue, meridianValue }) => {
    setTime(`${hoursValue}:${minutesValue}`);
    setMeridian(meridianValue);
  };

  useDidUpdate(() => {
    if(!meridian) {
      setMeridian(MERIDIAN.am);
    }

    if(!time) {
      setMeridian(null);
    }

    const convertedTime = convertTwentyFourFormat(time, meridian);
    if(convertedTime) {
      onChange(convertTwentyFourFormat(time, meridian));
    } else {
      onChange('invalid time');
    }
  }, [time, meridian]);


  useDidUpdate(() => {
    if(forcedState) {
      setTime(convertToTwelveFormat(forcedState));
      setMeridian(getMeridian(forcedState));
    }
  }, [forcedState]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event?.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Wrapper
      $width={ $width }
      ref={ inputRef }
    >
      <TimePickerInput
        disabled={ disabled }
        errorMessage={ errorMessage }
        isOpen={ open }
        meridian={ meridian }
        onArrowClick={ handleClick }
        setTime={ setTime }
        time={ time }
      />
      <TimePickerPopover
        anchorEl={ inputRef?.current }
        hourOptions={ popoverHoursOptions }
        hours={ popoverHours }
        isOpen={ open }
        meridian={ meridian || MERIDIAN.am }
        meridianOptions={ MERIDIAN_OPTIONS }
        minutes={ popoverMinutes }
        minutesOptions={ popoverMinutesOptions }
        onClickAway={ handleClose }
        setState={ setStateFromPopover }
      />
    </Wrapper>
  );
};

import styled, { css } from 'styled-components';

import { ELEM_SIZES } from './data';
import { ButtonPropsType, IconWrapperPropsType } from '@components/CustomIconButton/types';

export const Button = styled.button<ButtonPropsType>`
  display: flex;
  position: relative;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${({ disabled, $isActive }) => disabled && !$isActive && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  &:hover{
    &:after {
      ${({ $size, $substrateBgColor }) => $size && $substrateBgColor && css`
        position: absolute;
        content: '';
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: ${ELEM_SIZES[$size].substrate};
        height: ${ELEM_SIZES[$size].substrate};
        background-color: ${$substrateBgColor || 'transparent'} ;
    `}
    }
  }
`;

export const IconWrapper = styled.div<IconWrapperPropsType>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${({ $size }) => css`
    width: ${ELEM_SIZES[$size].iconWrapper};
    height: ${ELEM_SIZES[$size].iconWrapper};
  `}

  color: ${({ $iconColor, $iconActiveColor, $isActive }) => {
    const color = $isActive
      ? $iconActiveColor
      : $iconColor;

    return color || 'black';
  }};

  &:hover {
    color: ${({ $iconHoverColor, $iconActiveHoverColor, $isActive }) => {
    const color = $isActive
      ? $iconActiveHoverColor
      : $iconHoverColor;

    return color || 'black';
  }};
  }

  & > svg{
    color: currentColor;
    width: ${({ $iconSize }) => $iconSize || 'inherit'};
    height: ${({ $iconSize }) => $iconSize || 'inherit'};

    & > path {
      fill: currentColor;
    }
  }

`;

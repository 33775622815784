import React from 'react';

import MuiBox from '@material-ui/core/Box';

import { HelperTextProps } from './types';

import { COLORS } from '@constants/colors';

export const HelperText = ({ text, ...boxProps }: HelperTextProps) => (
  <MuiBox
    color={ boxProps?.color || COLORS.helpText }
    fontSize={ boxProps?.fontSize || '14px' }
    { ...boxProps }
  >
    {text}
  </MuiBox>
);

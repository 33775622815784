import React, { useCallback, useMemo } from 'react';

import DeletePopupWithControlPhrase from '@modules/DeletePopupWithControlPhrase';
import { DeletePopupProps } from './types';

export const DeleteAccountsAndPlansPopup = ({
  closeModal,
  modalProps: {
    arrayOfIds,
    deleteAccountsAndPlans
  }
}: DeletePopupProps) => {

  const onConfirmClick = useCallback(() => {
    deleteAccountsAndPlans(arrayOfIds);
    closeModal();
  }, [ closeModal ]);

  const message = useMemo(() => {
    const selectedLength = arrayOfIds.length;
    const pluralItem = selectedLength > 1 ? 'Financial Accounts' : 'Financial Account';
    return (
      <>
        <div>{ `${ selectedLength } ${ pluralItem } will be deleted.` }</div>
        <div>{ 'Write the word "delete" to delete the account' }</div>
      </>
    );
  }, [arrayOfIds]);

  return (
    <DeletePopupWithControlPhrase
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    />
  );
};

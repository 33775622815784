import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';

import { useStore } from '@store';

import { CategoryLabel } from '@components/CategoryLabel';
import CheckboxDropDown from '@components/CheckboxDropDown';
import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';
import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import SearchField from '@components/SearchField';
import SideFilters from '@modules/SideFilters';

import { addFilterOptions } from '@/shared/utils/filterUtils';

import {
  ACCOUNT_TYPE,
  ASSIGNED_TO,
  CATEGORY,
  CLASSIFICATION,
  CLIENT_OPPORTUNITIES_MEETING_STATUS,
  CLIENT_OPPORTUNITIES_SALE_TYPE,
  CLIENT_OPPORTUNITIES_STATUS,
  FilterTypes,
  LIST_TYPE,
  SALES_CYCLES_MEETING_STATUS,
  SALES_CYCLES_STATUS,
  TYPE,
  USERS,
} from '@pages/Trash/components/Filters/data';

import { TrashSearchFiltersNames, TrashTabValues } from '@pages/Trash/types';
import { TrashFiltersNames } from '@pages/Trash/types';


export const Filters = observer(() => {
  const trashStore = useStore().TrashStore;
  const {
    selectedFiltersCount,
    filtersData,
    filters,
    currentTrashView
  } = trashStore;

  const usersDropDownData: FilterTypes = useMemo(() => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      return addFilterOptions(ASSIGNED_TO, filtersData.clients || []);
    }
    return addFilterOptions(USERS, filtersData.user || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData.user, filtersData.clients]);

  const classificationDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(CLASSIFICATION, filtersData.classification ?? []);
  }, [filtersData.classification]);

  const listTypeDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(LIST_TYPE, filtersData.typeList ?? []);
  }, [filtersData.typeList]);

  const typeDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(TYPE, filtersData.type ?? []);
  }, [filtersData.type]);

  const accountTypeDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(ACCOUNT_TYPE, filtersData.type ?? []);
  }, [filtersData.type]);

  const taskTypeDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(CATEGORY, filtersData.type ?? []);
  }, [filtersData.type]);

  const salesCyclesStatusDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(SALES_CYCLES_STATUS, filtersData.salesCycleStatus ?? []);
  }, [filtersData.salesCycleStatus]);

  const salesCyclesMeetingStatusDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(SALES_CYCLES_MEETING_STATUS, filtersData.meetingStatus ?? []);
  }, [filtersData.meetingStatus]);

  const clientOpportunitiesSaleTypeDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(CLIENT_OPPORTUNITIES_SALE_TYPE, filtersData.saleType ?? []);
  }, [filtersData.saleType]);

  const clientOpportunitiesStatusDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(CLIENT_OPPORTUNITIES_STATUS, filtersData.opportunityStatus ?? []);
  }, [filtersData.opportunityStatus]);

  const clientOpportunitiesMeetingStatusDropDownData: FilterTypes = useMemo(() => {
    return addFilterOptions(CLIENT_OPPORTUNITIES_MEETING_STATUS, filtersData.meetingStatus ?? []);
  }, [filtersData.meetingStatus]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    trashStore.setFilters({
      [fieldName]: newValue,
    });
  }, [ trashStore ]);

  const onNameChangeWrapper = useCallback(({ target: { value } }: any) => {
    const filterName = TrashSearchFiltersNames[currentTrashView];
    trashStore.setFilters({
      [filterName]: value,
    });
  }, [ trashStore, currentTrashView ]);

  const onClearClick = useCallback(() => {
    trashStore.resetFilters();
  }, [ trashStore ]);

  return (
    <SideFilters
      count={ selectedFiltersCount }
      isNonePointerEvents={ false }
      onClearClick={ onClearClick }
    >
      <SideFilters.ItemWrapperWithDelimiter>

        { currentTrashView === TrashTabValues.Contacts &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Contacts' }
            value={ filters?.[TrashFiltersNames.Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.Lists &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Contacts' }
            value={ filters?.[TrashFiltersNames.List_Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.Activities &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Activities' }
            value={ filters?.[TrashFiltersNames.Activity_Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.Tasks &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Tasks' }
            value={ filters?.[TrashFiltersNames.Task_Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.AccountsAndPlans &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Accounts' }
            value={ filters?.[TrashFiltersNames.Account_Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.SalesCycles &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Contacts' }
            value={ filters?.[TrashFiltersNames.Sales_Cycles_Name] || '' }
          />
        }

        { currentTrashView === TrashTabValues.ClientOpportunities &&
          <SearchField
            onChange={ onNameChangeWrapper }
            placeholder={ 'Search Contacts' }
            value={ filters?.[TrashFiltersNames.Opportunities_Name] || '' }
          />
        }

      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[usersDropDownData.name] || [] }
          values={ usersDropDownData.values }
        />

        { currentTrashView === TrashTabValues.Contacts &&
          <CheckboxDropDown
            label={ classificationDropDownData.label }
            name={ classificationDropDownData.name }
            setState={ onChangeDrop }
            state={ filters?.[classificationDropDownData.name] || [] }
            values={ classificationDropDownData.values }
          />
        }

        { currentTrashView === TrashTabValues.Lists &&
          <CheckboxDropDown
            label={ listTypeDropDownData.label }
            name={ listTypeDropDownData.name }
            setState={ onChangeDrop }
            state={ filters?.[listTypeDropDownData.name] || [] }
            values={ listTypeDropDownData.values }
          />
        }

        { currentTrashView === TrashTabValues.Activities &&
          <CheckboxDropDown
            label={ typeDropDownData.label }
            name={ typeDropDownData.name }
            renderItemLabel={ (label: any, value: any) => (
              <CategoryLabel
                label={ label }
                value={ value }
              />
            ) }
            setState={ onChangeDrop }
            state={ filters?.[typeDropDownData.name] || [] }
            values={ typeDropDownData.values }
          />
        }

        { currentTrashView === TrashTabValues.AccountsAndPlans &&
          <CheckboxDropDown
            label={ accountTypeDropDownData.label }
            name={ accountTypeDropDownData.name }
            setState={ onChangeDrop }
            state={ filters?.[accountTypeDropDownData.name] || [] }
            values={ accountTypeDropDownData.values }
          />
        }

        { currentTrashView === TrashTabValues.Tasks &&
          <CheckboxDropDown
            label={ taskTypeDropDownData.label }
            name={ taskTypeDropDownData.name }
            setState={ onChangeDrop }
            state={ filters?.[taskTypeDropDownData.name] || [] }
            values={ taskTypeDropDownData.values }
          />
        }

        { currentTrashView === TrashTabValues.SalesCycles &&
          <>
            <CheckboxDropDown
              label={ salesCyclesStatusDropDownData.label }
              name={ salesCyclesStatusDropDownData.name }
              renderItemLabel={ (label, value) => (
                <SaleCycleStatusOption
                  label={ label }
                  value={ value }
                />
              ) }    
              setState={ onChangeDrop }
              state={ filters?.[salesCyclesStatusDropDownData.name] || [] }
              values={ salesCyclesStatusDropDownData.values }
            />
            <CheckboxDropDown
              label={ salesCyclesMeetingStatusDropDownData.label }
              name={ salesCyclesMeetingStatusDropDownData.name }
              setState={ onChangeDrop }
              state={ filters?.[salesCyclesMeetingStatusDropDownData.name] || [] }
              values={ salesCyclesMeetingStatusDropDownData.values }
            />
          </>
        }

        { currentTrashView === TrashTabValues.ClientOpportunities &&
          <>
            <CheckboxDropDown
              label={ clientOpportunitiesSaleTypeDropDownData.label }
              name={ clientOpportunitiesSaleTypeDropDownData.name }
              setState={ onChangeDrop }
              state={ filters?.[clientOpportunitiesSaleTypeDropDownData.name] || [] }
              values={ clientOpportunitiesSaleTypeDropDownData.values }
            />
            <CheckboxDropDown
              label={ clientOpportunitiesStatusDropDownData.label }
              name={ clientOpportunitiesStatusDropDownData.name }
              renderItemLabel={ (label, value) => (
                <SalesClientOpportunitiesStatusOption
                  label={ label }
                  value={ value }
                />
              ) }
              setState={ onChangeDrop }
              state={ filters?.[clientOpportunitiesStatusDropDownData.name] || [] }
              values={ clientOpportunitiesStatusDropDownData.values }
            />
            <CheckboxDropDown
              label={ clientOpportunitiesMeetingStatusDropDownData.label }
              name={ clientOpportunitiesMeetingStatusDropDownData.name }
              setState={ onChangeDrop }
              state={ filters?.[clientOpportunitiesMeetingStatusDropDownData.name] || [] }
              values={ clientOpportunitiesMeetingStatusDropDownData.values }
            />
          </>
        }
        
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});

import styled, { css } from 'styled-components';

import Chip from '@material-ui/core/Chip';

import { StatusChipProps } from './types';

export const StyledChip = styled(Chip)<StatusChipProps>`
  height: 24px;
  padding-left: 5px;
  
  ${({ $bgColor }) =>  $bgColor && css`
    background-color: ${$bgColor};
  `}
  
  ${({ $color }) =>  $color && css`
    color: ${$color};
  `}
  
  & .MuiChip-icon{
      & > path {
        ${({ $color }) =>  $color && css`
           fill: ${$color};
        `}
      }
  }
`;

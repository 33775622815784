import React, { useCallback } from 'react';

import { useStore } from '@store';

import DeletePopupWithControlPhrase from '@modules/DeletePopupWithControlPhrase';

import { getTypeNameOfCurrentView } from '@pages/Trash/utils';

import { PopupProps } from '@pages/Trash/types';

export const DeletePopup = ({
  closeModal,
  modalProps: {
    arrayOfIds
  }
}: PopupProps) => {
  const trashStore = useStore().TrashStore;

  const { table, currentTrashView } = trashStore;

  const getConfirmationMessage = () => {
    const selectedLenght = arrayOfIds?.length || table.selectedIDs.length;
    const { one, many } = getTypeNameOfCurrentView(currentTrashView);
    return (
      `${selectedLenght} removed ${selectedLenght > 1 ? many : one} 
            will be deleted from the system permanently. 
            You will not be able to restore ${selectedLenght > 1 ? 'them' : 'it'}.`
    );
  };

  const onConfirmClick = useCallback(() => {
    closeModal();
    trashStore.deleteItems(arrayOfIds);
  }, [ arrayOfIds, closeModal, trashStore ]);

  return (
    <DeletePopupWithControlPhrase
      confirmLabel='Permanently Delete'
      maxLength={ 6 }
      message={ getConfirmationMessage() }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    />
  );
};

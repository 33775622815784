import React, { useCallback } from 'react';

import {
  AccountAndPlansNotesAndHistory, CommonBalanceHistory,
  CommonBasicInformation, CommonStandingInstructions,
  ContentWrapperWithTabs
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { PolicyFormProps } from './types';

import { COMMON_TABS_VALUES } from '@modules/AccountsAndPlansPopup/data/data';

export const PolicyForm = ({
  currentTab,
  tabs,
  onTabChange
}: PolicyFormProps) => {

  const renderMainContent = useCallback(() => {
    if (currentTab === COMMON_TABS_VALUES.BASIC_INFORMATION) {
      return (
        <CommonBasicInformation/>
      );
    }
    if(currentTab === COMMON_TABS_VALUES.BALANCE_HISTORY) {
      return <CommonBalanceHistory />;
    }

    if(currentTab === COMMON_TABS_VALUES.STANDING_INSTRUCTIONS) {
      return <CommonStandingInstructions/>;
    }
  }, [currentTab]);

  const renderRightColumn = useCallback(() => {
    return (
      <AccountAndPlansNotesAndHistory
        deleteConfirmationText={ `The Note will be deleted if you save the changes for the Policy. 
          Are you sure you want to delete note?`
        }
      />
    );
  }, []);

  return (
    <ContentWrapperWithTabs
      currentTab={ currentTab }
      onTabChange={ onTabChange }
      renderMiddleColumnContent={ renderMainContent }
      renderRightColumnContent={ renderRightColumn }
      tabs={ tabs }
    />
  );
};

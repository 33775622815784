import React from 'react';

import { HeaderProps } from './types';

import { Wrapper, TitleWrapper } from './styles';

export const Header = ({ title }: HeaderProps) => {

  return (
    <Wrapper>
      <TitleWrapper>
        {title}
      </TitleWrapper>
    </Wrapper>
  );
};

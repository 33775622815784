import { RULES_LABELS } from '@constants/contactRules';

import { ContactItem } from '@/shared/types/contact';

export const getContactRules = (contact: ContactItem) => {
  const { allowCall, allowEmail, allowMail, allowText } = contact;
  const rules = { allowCall, allowEmail, allowMail, allowText };
  const contactRules: string = Object.entries(rules)
    .filter(([key, value]) => Boolean(!value))
    .map(([key, value]) => RULES_LABELS[key])
    .join(', ');

  return contactRules || '-';
};

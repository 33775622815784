import styled, { css } from 'styled-components';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';

import { COLORS } from '@/shared/constants/colors';
import {
  TABLE_CELL_HEIGHT,
  TABLE_CELL_LEFT_PADDING,
  TABLE_CELL_WITH_CONTROLS
} from '@constants/style';

export const TableHeadCellContentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${TABLE_CELL_HEIGHT};
`;

export const HeadCellSeparator = styled.div`
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 33px;
  margin-right: 20px;
  background-color: ${ COLORS.borders };
`;

export const TableSortLabel = styled(MuiTableSortLabel)`
  &.MuiTableSortLabel-root {
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;

    & svg {
      visibility: hidden;
    }

    &:hover {
      svg {
        visibility: visible;
      }
    }
  }
  
  &.MuiTableSortLabel-active {
    & svg {
      visibility: visible;
    }
  }
`;

export const GlobalCheckboxWrapper = styled.div``;


export const StyledTableCell = styled(TableCell)`
  ${TABLE_CELL_WITH_CONTROLS};
  
  &:last-child {
    padding-left: ${({ style }) => (
    style?.width === '0px' 
      ? '0px !important'
      :`${TABLE_CELL_LEFT_PADDING} !important`
  ) };
  }
`;

export const StyledTableCellWithControls = styled(TableCell)`
  ${TABLE_CELL_WITH_CONTROLS}
`;





import React from 'react';

import MuiBox from '@material-ui/core/Box';
import { RowProps } from './types';


export const Row = ({ children, ...boxProps }: RowProps) => (
  <MuiBox
    display={ boxProps?.display || 'flex' }
    marginBottom={ boxProps?.marginBottom || '5px' }
  >
    {children}
  </MuiBox>
);

import { isNull, omitBy, pick } from 'lodash';

import { ContactFormFields } from '@services/store/contactDetailsProfileStore/types/common';
import { CONTACT_TYPES } from '@constants/common';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { CONTACTS_LOCATION_IDS, CONTACTS_PHONE_IDS } from '@constants/contactsData';
import {
  COMMON_FIELDS
//eslint-disable-next-line
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';
import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';
import { HouseHoldContactItem } from '@/shared/types/contact';


const convertHouseHoldData = (household: HouseHoldContactItem): HouseHoldContactItem  => {
  const processedHouseholderContacts = household.householderContacts.slice().map((item) => {
    return {
      ...item,
      communicateWith: booleanToYesNo(household.communicationWithIds.includes(String(item.id))),
      householdOwner: booleanToYesNo(String(household.householdOwnerId) === String(item.id)),
      sendEmail: booleanToYesNo(household.sendEmailToIds.includes(String(item.id))),
      sendMail: booleanToYesNo(household.sendMailToIds.includes(String(item.id))),
    };
  });

  return {
    ...household,
    householderContacts: processedHouseholderContacts
  };
};

const validContact = (contact: any) => {
  if(CONTACTS_LOCATION_IDS.includes(contact.fieldId)) {
    const fieldValues = [ contact.city, contact.street, contact.street2, contact.zip, contact.state ];

    return fieldValues
      .filter(Boolean)
      .some((item: string) => Boolean(item.trim()));
  }

  if(CONTACTS_PHONE_IDS.includes(contact.fieldId)) {
    // TODO by default the phone number must be set NULL if only the country code is entered
    if(getIsOnlyCountryCode(contact.value)) {
      return '';
    }
  }

  return Boolean(contact.value.trim());
};

export const convertToFormData = (data: ContactFormFields) => {
  const formData = new FormData();

  const advisorFields = pick(data, [
    COMMON_FIELDS.officePrimaryAdvisor,
    COMMON_FIELDS.officeSecondaryAdvisor,
    COMMON_FIELDS.officePrimaryCsr,
    COMMON_FIELDS.officeSecondaryCsr
  ]);
  let sanitizedData = omitBy(data, isNull) as ContactFormFields;
  sanitizedData = { ...sanitizedData, ...advisorFields };

  sanitizedData.contacts = sanitizedData.contacts.filter(validContact);

  if(sanitizedData.type !== CONTACT_TYPES.HOUSEHOLD) {
    if(typeof sanitizedData.photo !== 'string' && sanitizedData.photo !== null) {
      formData.append(`photo[${sanitizedData.id}]`, sanitizedData.photo);
      // @ts-ignore
      delete sanitizedData.photo;
    }
    // @ts-ignore
    delete sanitizedData.relatedContact;
  }
  
  if(sanitizedData.type === CONTACT_TYPES.BUSINESS) {
    // @ts-ignore
    delete sanitizedData.relatedEmployees;
  }
  
  if(sanitizedData.type === CONTACT_TYPES.HOUSEHOLD){
    sanitizedData = convertHouseHoldData(sanitizedData);

    sanitizedData.householderContacts.forEach(member => {
      if(typeof member.photo !== 'string' && member.photo !== null) {
        formData.append(`photo[${member.id}]`, member.photo || '');
        // @ts-ignore
        delete member.photo;
      }
    });
  }

  formData.append('request', JSON.stringify(sanitizedData));
  return formData;
};

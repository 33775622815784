import React, { useCallback, useMemo } from 'react';

import DeletePopupWithControlPhrase from '@modules/DeletePopupWithControlPhrase';

import { DeletePopupProps } from './types';

export const DeleteContactsPopup = ({
  closeModal,
  modalProps: {
    arrayOfIds,
    onSave,
  }
}: DeletePopupProps) => {

  const confirmationMessage = useMemo(() => {
    const contactsToDeleteLength = arrayOfIds?.length;
    return (
      `${contactsToDeleteLength > 1 ? 'Contacts' : 'Contact'} will be moved to the Trash. You have 90 days to restore
      ${contactsToDeleteLength > 1 ? 'these contacts' : 'this contact'}. Write the word "delete" to delete
      ${contactsToDeleteLength} ${contactsToDeleteLength > 1 ? 'contacts.' : 'contact.'}`
    );
  }, [ arrayOfIds ]);

  const onConfirmClick = useCallback(() => {
    closeModal();
    onSave(arrayOfIds);
  }, [onSave, closeModal, arrayOfIds ]);

  return (
    <DeletePopupWithControlPhrase 
      maxLength={ 6 }
      message={ confirmationMessage }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    />
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const InfoSubTitle = styled.div`
  width: 500px;
  position: relative;
  bottom: 30px;
  text-align: center;
  line-height: 150%;
  color: ${ COLORS.primaryText };
`;

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import { AppContentWrapperProps, AppSidebarWrapperProps } from './types';

export const Wrapper = styled.main<AppSidebarWrapperProps>`
  width: 100vw;
  height: 100vh;
  display: grid;
  background-color: ${COLORS.background};
  
  ${({ withSidebar }) => {
    if(withSidebar) {
      return withSidebar && css`
          grid-template-columns: min-content 1fr;
      `;
    }
    
    return css` 
      grid-template-columns: 1fr;
    `;
  }}
  
  grid-template-rows: 1fr;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
`;

export const ContentWrapper = styled.div<AppContentWrapperProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  
  ${({ withSidebar }) => {
    if(withSidebar) {
      return withSidebar && css`
          grid-template-columns: minmax(62px, 260px) 1fr;
      `;
    }

    return css` 
      grid-template-columns: 1fr;
    `;
  }}
`;
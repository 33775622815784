import React from 'react';

import CustomCheckbox from '@components/CustomCheckbox';

import { CheckboxWrapper, ItemWrapper } from './styles';

export const SelectItemWithCheckbox = ({
  checked,
  onClick,
  children
}) => (
  <ItemWrapper
    $selected={ checked }
    onClick={ onClick }
  >
    <CheckboxWrapper>
      <CustomCheckbox
        checked={ checked }
        onChange={ () => {} }
      />
    </CheckboxWrapper>
    {children}
  </ItemWrapper>
);

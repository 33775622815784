import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
export const Wrapper = styled.div`
  display: flex;
  padding: 10px 20px 0 20px;
`;

export const ItemWrapper = styled.div`
  flex-grow: 1;

  max-width: 790px;
  padding-right: 10px;
`;

export const DeleteWrapper = styled.div`
  padding-left: 10px;
  margin-top: 6px;
  margin-left: auto;
`;

export const ValueFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const DelimiterText = styled.div`
  padding-top: 6px;
  font-size: 14px;
  color: ${COLORS.helpText};
  margin: 0 10px
`;

export const FieldPlaceholderWrapper = styled.div`
  margin-top: 5px;
`;

export const DatePickerWrapper = styled.div`
  & .MuiInputBase-input::placeholder {
    font-size: 11px;
  }
`;

import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '@store';

import { verifiedEmailByHash } from '@services/api/common/common';
import { ROUTES } from '@constants/routes';

import Preloader from '@components/Preloader';

export const VerifiedEmailByHash = () => {
  const routerStore = useStore().RouterStore;

  const { hash } = useParams<{ 'hash': string; }>();

  const verifiedEmailByHashWrapper = useCallback(async () => {
    try {
      await verifiedEmailByHash({ hash });
      routerStore.customPush(ROUTES.dashboard);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    verifiedEmailByHashWrapper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO
  return(
    <Preloader />
  );
};
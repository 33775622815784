import React, { useCallback, useMemo } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { SalesActiveCycleDeletePopupProps } from './types';

export const SalesActiveCycleDeletePopup = ({
  closeModal,
  modalProps
}: SalesActiveCycleDeletePopupProps) => {
  const { deleteData, onConfirm } = modalProps;

  const onSaveClick = useCallback(() => {
    closeModal();
    onConfirm(deleteData);
  }, [closeModal, deleteData, onConfirm]);

  const message = useMemo(() => {
    const numberToDelete = Array.isArray(deleteData) ? deleteData.length : 1;
    return `
    ${numberToDelete } Sales ${ numberToDelete > 1 ? 'Cycles' : 'Cycle'}
    will be deleted to the Trash. Do you want to proceed?`;
  }, [deleteData]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onSaveClick }
    />
  );
};

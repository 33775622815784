import ApiService from '@services/api/api.service';
import {
  DeleteUserCustomItemParams, GetListOfUserCustomFieldsParams,
  GetUserCustomFieldItemParams,
  UserCustomFieldFormFields
} from '@/shared/types/userSettingsUserCustomFields';

export const getUserCustomFields = (params: any) => {
  return ApiService.get('/api/customField/list', { params });
};

export const saveUserCustomField = (params: UserCustomFieldFormFields) => {
  return ApiService.post('/api/customField/create', params);
};

export const updateUserCustomField = (params: UserCustomFieldFormFields) => {
  return ApiService.patch('/api/customField/update', params);
};

export const getUserCustomFieldItem = (params: GetUserCustomFieldItemParams) => {
  return ApiService.get('/api/customField/getCustomFieldById', { params });
};

export const deleteUserCustomFieldItem = (params: DeleteUserCustomItemParams) => {
  return ApiService.delete('/api/customField/delete', { params });
};

export const getCustomFieldByTypeId = (params: GetListOfUserCustomFieldsParams) => {
  return ApiService.get('/api/customField/getCustomFieldByType', { params });
};


import React, { useCallback } from 'react';

import { useStore } from '@store';
import { MODAL_TYPE } from '@constants/modalTypes';

import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';
import PopupActionsButtons from '@components/PopupActionsButtons';
import { DeleteActivityConfirmPopup } from '../DeleteActivityConfirmPopup';

import {
  NotificationText,
  NotificationTextItem,
  InnerWrapper,
  Wrapper
} from './styles';

import { ActionsProps } from './types';

export const Actions = ({
  $preventClicks,
  close,
  editMode,
  isOutOfWorkHours,
  onCancelClick,
  onSaveClick,
  removeData,
  onDelete,
}: ActionsProps) => {
  const modalStore = useStore().ModalStore;
  const modeSaveLabel = editMode ? 'Apply' : 'Save';
  const saveLabel = isOutOfWorkHours ? 'Okay' : modeSaveLabel;

  const onClick = useCallback(() => {
    close();
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_ACTIVITY_CONFIRMATION,
      modalProps: {
        removeData,
        onDelete
      },
      component: DeleteActivityConfirmPopup
    });
  }, [close, modalStore, onDelete, removeData]);

  return (
    <Wrapper $preventClicks={ $preventClicks }>
      <InnerWrapper>
        {
          editMode && !isOutOfWorkHours &&
          <PopupSingleActionButton
            onClick={ onClick }
            type={ POPUP_BUTTON_TYPES.delete }
            style={ {
              marginLeft: '-8px',
            } }
          >
            Delete
          </PopupSingleActionButton>
        }
        {
          isOutOfWorkHours &&
          <NotificationText>
            <NotificationTextItem>
              You scheduled an activity outside of business hours of some users.
            </NotificationTextItem>
            <NotificationTextItem>Do you want to continue?</NotificationTextItem>
          </NotificationText>
        }
      </InnerWrapper>
      <PopupActionsButtons
        onCancelClick={ onCancelClick }
        onSaveClick={ onSaveClick }
        saveLabel={ saveLabel }
      />
    </Wrapper>
  );
};

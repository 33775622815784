import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { ItemNameWrapperType, ItemWrapperType } from './types';

const ROOT_PADDING = 30;

export const ContentWrapper  = styled.div`
  height: 345px;
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div`
  padding: 25px 30px;
  color: ${COLORS.header};
  font-size: 16px;
`;

export const ItemsWrapper = styled.div``;

export const ItemWrapper = styled.div<ItemWrapperType>`
    display: flex;
    position: relative;
    height: 34px;
    align-items: center;
    isolation: isolate;
    cursor: pointer;

    ${({ $level }) => $level > 0 && css`
      padding-left: ${ROOT_PADDING + $level * ROOT_PADDING}px;
    `}

    ${({ $level }) => $level === 0 && css`
      padding-left: ${ROOT_PADDING}px;
    `}

    ${({ $isDisabled }) => $isDisabled && css`
        pointer-events: none;
        
        &:after{
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background-color: ${COLORS.white};
            opacity: 0.5;
        }
      
    `}
    
    ${({ $isActive }) => $isActive && css`
        color: ${COLORS.orange};
        background-color: ${COLORS.select};
        
        &:after{
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            width: 2px;
            z-index: 1;
            background-color: ${COLORS.orange};
        }
      
    `}
`;

export const ItemIconWrapper = styled.div`
  margin-right: 10px;
  color: inherit;
    
  & svg path {
      fill: currentColor;
  }
`;

export const ItemNameWrapper = styled.div<ItemNameWrapperType>`
  color: inherit;
  outline: 1px saddlebrown;
    
    ${({ $isEditable }) => $isEditable && css`
      cursor: pointer;
    `}
`;

export const InputWrapper = styled.div`
    
  & .MuiInputBase-input {
     padding: 4px 10px !important;   
  }
`;

export const ScrollTarget = styled.div`
  padding-top: 20px;
`;

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import { TextProps, WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  background-color: ${ COLORS.background };
  border-radius: 4px;
  
  padding: ${ ({ $padding }) => $padding || '16px 55px 16px 16px'};
  
  ${({ $withLeftLine }) => $withLeftLine && css`
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      background-color: ${ COLORS.orange };
    }
  `}
`;

export const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
`;

export const Text = styled.div<TextProps>`
  margin-left: 19px;
  color: ${ COLORS.helpText };
  
  ${({ $lineHeight }) => $lineHeight && css`
    line-height: ${$lineHeight};
  `}
  
`;

export const IntroText = styled.strong`
  color: ${ COLORS.primaryText }
`;



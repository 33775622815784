import React from 'react';
import { useFormContext } from 'react-hook-form';

import AttentionBlock from '@components/AttentionBlock';
import FormBlock from '@components/FormBlock';
import HFRadioGroup from '@modules/HookFormComponents/HFRadioGroup';

import { RADIO_OPTIONS } from './data';

import {
  ROOT_FIELD_NAMES
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/SplitToIndividuals/data';

import { Question, QuestionWrapper, RadioGroupWrapper } from './styles';

export const Details = () => {
  const { control } = useFormContext();

  return (
    <FormBlock>
      <FormBlock.Header title={ 'Conversion Details' } />
      <FormBlock.RowWrapper>
        <QuestionWrapper>
          <Question>
            {'Do you want to keep these spouses linked or separate? '}
          </Question>
          <RadioGroupWrapper>
            <HFRadioGroup
              control={ control }
              name={ ROOT_FIELD_NAMES.createSpouse }
              options={ RADIO_OPTIONS }
            />
          </RadioGroupWrapper>
        </QuestionWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <AttentionBlock
          $withLeftLine={ true }
          text={ 
            `Keeping contacts linked together as
            spouses (but separated as individual records) can be useful when
            managing the financial relationship of each spouse separately. If
            you do not want them to be linked as spouses (but separated into
            individual records), then choose Separate.` 
          }
        />
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

type AsyncFunc<R extends any> = (...args: any[]) => Promise<R>;

interface AsyncOperationProps<R extends any> {
  func: AsyncFunc<R>;
  context?: object;
  args?: any[];
  continueOnError?: boolean;
  onError?: (error: any) => void; // TODO
  onSuccess?: () => void;
}
export class AsyncRequestExecutor {
  finallyCallbacks: Array<() => void> = [];

  async  wrapAsyncOperation<R extends any>({
    func,
    context,
    args = [],
    continueOnError = true,
    onError,
    onSuccess
  }: AsyncOperationProps<R>): Promise<R | undefined> {
    let result: R | undefined;

    try {
      result = context
        ? await func.apply(context, args)
        : await func(...args);

      if (onSuccess) {
        this.finallyCallbacks.push(onSuccess);
      }
    } catch (error) {
      if (onError) {
        this.finallyCallbacks.push(() => onError(error));
      }

      if (!continueOnError) {
        throw error;
      }
    }

    return result;
  }

  clearCallbacks() {
    this.finallyCallbacks = [];
  }

  executeFinallyCallbacksAndClear(){
    if(this.finallyCallbacks.length > 0) {
      this.finallyCallbacks.forEach(callback => callback());
      this.clearCallbacks();
    }
  }
}

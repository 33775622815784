import { EDIT_MODE_STAGES_OPTIONS, MAIN_STAGES_OPTIONS } from '@constants/todosData';

export const LISTBOX_PROPS = {
  style: {
    maxHeight: '200px'
  }
};

export const ASSIGNED_TO_CURRENT_USER_SELECT_PROPS = {
  options: MAIN_STAGES_OPTIONS,
  placeholder: 'Select Stage',
  resetLabel: 'Select Stage',
  resettable: true,
  resetValue: '',
};

export const ASSIGNED_TO_CURRENT_USER_EDIT_MODE_SELECT_PROPS = {
  ...ASSIGNED_TO_CURRENT_USER_SELECT_PROPS,
  options: EDIT_MODE_STAGES_OPTIONS,
};

export const ASSIGNED_TO_OTHER_USER_SELECT_PROPS = {
  options: MAIN_STAGES_OPTIONS,
  showError: false,
};

export const ASSIGNED_TO_OTHER_USER_EDIT_MODE_SELECT_PROPS = {
  options: EDIT_MODE_STAGES_OPTIONS,
  showError: false,
};

import { TextFieldProps } from '@material-ui/core';
import { TagFilters } from '@/shared/types/tags';

import { TAG_FILTERS_FIELDS_NAMES } from '@constants/userSettingsUserCustomFields';

export const FROM_INPUT_PROP = {
  placeholder: 'From',
  maxLength: 5,
  step: '1.00',
};

export const TO_INPUT_PROP = {
  placeholder: 'To',
  maxLength: 5,
  step: '1.00',
};

export const COMMON_TEXT_FIELD_PROP: TextFieldProps  = {
  type: 'number',
};

export const DEFAULT_VALUES: TagFilters = {
  [TAG_FILTERS_FIELDS_NAMES.from]: null,
  [TAG_FILTERS_FIELDS_NAMES.to]: null,
};

export const BUTTON_STYLE = {
  minWidth: 'auto',
  marginBottom: '15px'
};

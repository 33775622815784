import { BalanceHistoryItem } from '@modules/AccountsAndPlansPopup/types';

import { BALANCE_HISTORY_TYPES } from './data';

export const generateNewBalanceHistoryItem = (
  newSortNum: number,
  fields: Array<BalanceHistoryItem>
): BalanceHistoryItem => {
  return {
    newBusinessAmount: '',
    type: fields.length === 0
      ? BALANCE_HISTORY_TYPES.initialValue
      : BALANCE_HISTORY_TYPES.updatedValue,
    updated: '',
    value: '',
    sort: newSortNum,
  };
};
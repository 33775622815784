import MuiBox from '@material-ui/core/Box';
import styled from 'styled-components';

export const Wrapper = styled(MuiBox)`
  position: relative;
`;
export const PreloaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const styles = (theme) => ({
  '@global': {
    '.tox-notifications-container': {
      display: 'none',
    },
    '.tox-tinymce': {
      borderColor: '#E1E3EC !important',
      borderRadius: '5px !important',
    },
    '.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)' :{
      borderColor: '#E1E3EC !important',
    },
    '.tox': {
      '& .upload-image-dialog': {
        '& .tox-dialog': {
          maxWidth: 800,
          maxHeight: 373,
          borderRadius: '5px !important',
          borderColor: '#fff !important',
          '&__header': {
            height: 65,
            boxShadow: '0px 1px 5px rgba(16, 28, 91, 0.12)',
            '& .tox-button--naked': {
              color: '#4E4E61',
              border: 'none',
              '&:hover': {
                color: `${theme.palette.primary.main} !important`,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                border: 'none',
                borderRadius: '50%'
              }
            }
          },
          '&__body': {
            height: 140
          },
          '&__body-content>:last-child': {
            position: 'absolute',
            top: 70
          },
          '&__footer': {
            boxShadow: '0px 1px 5px rgba(16, 28, 91, 0.2)',
            borderTop: 'none !important',
            background: '#FDFDFE',
            height: 74
          },
          '&__footer-end': {
            '& .tox-button': {
              width: 89,
              height: 34,
              backgroundColor: `${theme.palette.primary.main} !important`,
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: 6,
              '&:hover': {
                border: '1px solid #b15400 !important',
                backgroundColor: '#b15400 !important'
              }
            },
            '& .tox-button.tox-button--secondary': {
              width: 89,
              height: 34,
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: 6,
              background: '#FDFDFE !important',
              color: theme.palette.primary.main,
              marginRight: 10,
              '&:hover': {
                border: `1px solid ${theme.palette.primary.main} !important`,
                backgroundColor: 'rgba(234, 130, 19, 0.04) !important',
                color: `${theme.palette.primary.main} !important`,
              }
            }
          },
          '& .tox-form': {
            marginTop: '44px !important',
            '&__group': {
              display: 'flex !important',
              marginTop: 5,

              '& .tox-label': {
                display: 'flex',
                alignItems: 'center',
                color: '#838390',
                fontSize:  14,
                marginRight: 50
              },
              '& .tox-textfield': {
                borderRadius: 4,
                marginRight: 6,
                '&::placeholder': {
                  color: '#C6C7CF',
                  fontSize: 14,
                  fontFamily: 'Roboto'
                }
              },

              '&.source': {
                '& .tox-textfield': {
                  width: 210,
                  height: 30,
                  border: '1px solid #E1E3EC',
                  '&:hover': {
                    borderColor: theme.palette.primary.main
                  }
                },
                '& .tox-label': {
                  marginRight: 23
                },
                '& .invalidError': {
                  color: '#E24141',
                  fontSize: 10,
                  marginBottom: -8
                },
                '& .tox-browse-url': {
                  border: 'none',
                  '&:hover': {
                    color: `${theme.palette.primary.main} !important`,
                    border: 'none',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }
              },

              '&.title': {
                position: 'absolute',
                right: 16,
                '& .tox-textfield': {
                  width: 210,
                  height: 30,
                  marginRight: 6,
                  border: '1px solid #E1E3EC',
                  '&:hover': {
                    borderColor: theme.palette.primary.main
                  }
                },
                '& .tox-label': {
                  marginRight: 23
                }
              },

              '&.description': {
                position: 'absolute',
                display: 'none !important',
                bottom: 95,
                '& .tox-textfield': {
                  width: 661,
                  height: 50,
                  border: '1px solid #E1E3EC',
                  '&:hover': {
                    borderColor: theme.palette.primary.main
                  }
                },
                '& .tox-label': {
                  marginRight: 21
                }
              },

              '&.height': {
                display: 'none !important',
                '& .tox-label': {
                  display: 'none'
                },
                '& .tox-textfield': {
                  width: 100,
                  height: 30,
                  border: '1px solid #E1E3EC',
                  '&:hover': {
                    borderColor: theme.palette.primary.main
                  }
                }
              },

              '&.width': {
                display: 'none !important',
                '& .tox-label': {
                  marginRight: 10
                },
                '& .tox-textfield': {
                  width: 100,
                  height: 30,
                  marginRight: 5,
                  border: '1px solid #E1E3EC',
                  '&:hover': {
                    borderColor: theme.palette.primary.main
                  }
                }
              },

              '& .lock-icon': {
                display: 'none !important',
                marginLeft: '4px !important',
                '& .tox-label': {
                  display: 'none'
                },
              }
            }
          }
        }
      }
    }
  } });

import { CONTACT_TYPES } from '@constants/common';
import { ContactNote } from '@/shared/types/note';
import { IdType } from '@/shared/types/commonTypes';

// TODO after customer's answer
export const spouseNormalizer = (contact: any): any | null => {
  if(!contact) {
    return null;
  }
  if(contact.type === CONTACT_TYPES.BUSINESS) {
    if(contact.contactEmployeeData && contact.contactEmployeeData.length > 0) {
      return {
        editAllow: contact.contactEmployeeData.editAllow,
        firstName: contact.contactEmployeeData.firstName,
        id: contact.contactEmployeeData.id,
        lastName: contact.contactEmployeeData.lastName,
        photo: contact.contactEmployeeData.photo,
        relationType: contact.contactEmployeeData.title,
      };  
    }

    return null;
  }

  if(contact.type === CONTACT_TYPES.HOUSEHOLD) {
    const spouse = contact.householderContacts.filter((contact: any) => contact.householdOwner === 'No')[0];
    return {
      editAllow: spouse.editAllow,
      firstName: spouse.firstName,
      id: spouse.contactId,
      lastName: spouse.lastName,
      photo: spouse.photo,
      relationType: 'Spouse',
    };
  }

  if(contact.type === CONTACT_TYPES.INDIVIDUAL) {
    const { householderContacts, relatedContact } = contact;
    if(householderContacts.length > 0) {
      const spouse = contact.householderContacts.filter((household: any) => (
        household.contactId !== contact.id)
      )[0];
      return spouse ? {
        editAllow: spouse.editAllow,
        firstName: spouse.firstName,
        id: spouse.contactId,
        lastName: spouse.lastName,
        photo: spouse.photo,
        relationType: 'Spouse',
      } : null;
    }
    if(relatedContact.length > 0) {
      const spouse = contact.relatedContact.filter((contact: any) => contact.relationType === 'Spouse')[0];
      return spouse ? {
        editAllow: spouse.editAllow,
        firstName: spouse.firstName,
        id: spouse.relativeId,
        lastName: spouse.lastName,
        photo: spouse.photo,
        relationType: spouse.relationType,
      } : null;
    }
  }

  return null;
};

// TODO after customer's answer
export const contactNormalizer = (data: any): any | null => {
  if(!data) {
    return null;
  }

  if(data.type === CONTACT_TYPES.HOUSEHOLD) {
    const headOfHousehold = data.householderContacts.filter((contact: any) => (
      contact.householdOwner === 'Yes')
    )[0];
    return {
      ...data,
      firstName: headOfHousehold.firstName,
      lastName: headOfHousehold.lastName,
      birthdate: headOfHousehold.birthdate,
      occupations: headOfHousehold.occupations,
      classification: headOfHousehold.classification,
      rating: headOfHousehold.rating,
      photo: headOfHousehold.photo,
      contacts: headOfHousehold.contacts,
    };
  }

  if(data.type === CONTACT_TYPES.INDIVIDUAL) {
    return {
      ...data,
      householderContacts: []
    };
  }
  return data;
};

export const getParamsToSaveContactNote = (id: IdType, note: ContactNote) => ({
  id,
  contactNotes: [{
    noteType: note.noteType,
    title: note.title,
    noteText: note.noteText,
    tags: note.tags,
  }]
});
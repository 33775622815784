import React from 'react';
import { Popover } from '@material-ui/core';

import { ANCHOR_ORIGIN_PROPS, PAPER_PROPS, TRANSFORM_ORIGIN_PROPS } from './data';

import UserItem from '@components/NewUserItem';

import { RecentContactsListsProps } from './types';


export const RecentContactsLists = ({
  anchorEl,
  contactsList,
  onClose,
}: RecentContactsListsProps) => (
  <Popover
    anchorEl={ anchorEl }
    anchorOrigin={ ANCHOR_ORIGIN_PROPS }
    onClose={ onClose }
    open={ true }
    PaperProps={ PAPER_PROPS }
    transformOrigin={ TRANSFORM_ORIGIN_PROPS }
  >
    {
      contactsList && contactsList.map((contact) => (
        <UserItem
          key={ contact.id }
          user={ contact }
        />
      ))
    }
  </Popover>
);

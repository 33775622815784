import styled, { css } from 'styled-components';
import { INDICATOR_TYPES } from './data';

const {
  round,
  square
} = INDICATOR_TYPES;

const getCssByType = ($indicatorType) => {
  if ($indicatorType === round || $indicatorType === square) {
    return css`
      width: 8px;
      height: 8px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    `;
  }

  return '';
};

export const Wrapper = styled.div`
  position: relative;
  padding-left: 16px;
  max-width: 100%;

  &:after {
    position: absolute;
    content: '';
    background-color: ${ ({ $indicatorBgColor }) => $indicatorBgColor || 'transparent' };

    ${ ({ $indicatorType }) => getCssByType($indicatorType) }

    ${ ({ $indicatorType }) => $indicatorType === round && css`
      border-radius: 50%;
    ` }
  }
`;

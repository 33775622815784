import { ItemType } from '@/shared/types/noteAndHistoryTask';
import { NoteLinkedContactAction } from './types';

export const VIEWS = {
  addEdit: 'ADD',
  grid: 'GRID'
} as const;

export const INIT_NOTES_VIEW = VIEWS.grid;

export const NOTE_LINKED_CONTACT_ACTIONS = {
  reconnect: 'reconnect',
  disconnect: 'disconnect'
} as const;

export const NOTES_TAB_VALUE = 'notes';

export const ALERT_TEXTS: Record<NoteLinkedContactAction, string> = {
  [NOTE_LINKED_CONTACT_ACTIONS.reconnect]: 'All the Notes from the Task will be reconnected to a new Linked Contact.',
  [NOTE_LINKED_CONTACT_ACTIONS.disconnect]: 'All the Notes will be disconnected from the Contact.',
};

export const NOTE_TYPES = {
  all: 'All Notes',
  task: 'Task',
  account: 'Account',
  opportunity: 'Opportunity',
  salesCycle: 'Sales Cycle',
} as const;

export const SUB_CATEGORIES_COMMUNICATION_TYPES: ItemType = {
  all: 'All Communications',
  note: 'Note',
  call: 'Call',
  voiceMail: 'Voice Mail',
  inPerson: 'In Person',
  text: 'Text',
  clientService: 'Client Service',
  socialMedia: 'Social Media',
  email: 'Email',  
} as const;

export const NOTE_AND_HISTORY_TYPE = {
  module: 'module',
  general: 'general'
};

export enum TYPE_FILTER {
  ALL = 'All',
  OPPORTUNITY = 'Opportunity',
  SALES_CYCLE = 'Sales Cycle',
  ACCOUNT = 'Account',
  POLICY = 'Policy',
  PLAN = 'Plan',
  TASK = 'Task',
}

export const TYPE_FILTER_OPTIONS = [{
  value: TYPE_FILTER.ALL,
  label: TYPE_FILTER.ALL
}, {
  value: TYPE_FILTER.OPPORTUNITY,
  label: TYPE_FILTER.OPPORTUNITY
}, {
  value: TYPE_FILTER.SALES_CYCLE,
  label: TYPE_FILTER.SALES_CYCLE
}, {
  value: TYPE_FILTER.ACCOUNT,
  label: TYPE_FILTER.ACCOUNT
}, {
  value: TYPE_FILTER.POLICY,
  label: TYPE_FILTER.POLICY
}, {
  value: TYPE_FILTER.PLAN,
  label: TYPE_FILTER.PLAN
}, {
  value: TYPE_FILTER.TASK,
  label: TYPE_FILTER.TASK
}];

export const LINKED_ITEM_PARAM_NAMES = {
  id: 'id',
  types: 'types',
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const InnerWrapper = styled.div`
  padding: 10px 10px 15px 15px;
`;

export const ToggleWrapper = styled.div`
  position: relative;
  padding: 10px 10px 12px 15px;
  
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 5px;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.borders};
  }
`;

import React from 'react';
import { Controller } from 'react-hook-form';

import PhoneInput from '@components/PhoneInput';

import { HFPhoneFieldProps } from './types';


export const HFPhoneField = ({
  control,
  ControllerProps = {},
  countryListWidth,
  disabled,
  name,
}: HFPhoneFieldProps) => (
  <Controller
    control={ control }
    defaultValue={ '' }
    name={ name }
    render={ ({ 
      field: {
        value,
        onChange
      }, 
      fieldState: {
        invalid,
        error
      } }
    ) => (
      <PhoneInput
        countryListWidth={ countryListWidth }
        disabled={ disabled }
        errorMessage={ error?.message }
        isError={ invalid }
        onChange={ onChange }
        value={ value || '' }
      />
    ) }
    { ...ControllerProps }
  />
);

/* eslint-disable */
import Preloader from '@components/Preloader';
import {Editor} from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import { COLORS } from '@/shared/constants/colors';

import { PreloaderWrapper, styles, Wrapper } from './styles';

function TextEditor(props) {
    const [isLoading, setIsLoading] = useState(true);
    const {onChange, toolbar, height} = props;
    const [value, setValue] = React.useState(props.value);

    React.useEffect(() => {
		const {value: newValue} = props;
		if (newValue !== value) {
			setValue(newValue);
		}
	});

    const handleEditorInit = () => {
        setIsLoading(false);
    };

    const handleEditorChange = (newValue) => {
        if(newValue !== value) {
            onChange(newValue);
        }
    };

    const addImgDialogTitle = () => {
        let dialogBody = document.getElementsByClassName('tox-dialog__body-content');

        let bodyDialogTitle = document.createElement("p");
        bodyDialogTitle.innerHTML = "<h1>Image Details</h1>";

        dialogBody[0].appendChild(bodyDialogTitle);
    }

    const setPlaceholder = () => {
        const placeholders = [
            'Enter Source',
            'Enter Alternative description',
            'Enter Image Title',
            'Enter Width',
            'Enter Height'
        ];
        let textFields = document.getElementsByClassName('tox-textfield');
        Array.from(textFields).forEach((field, index) => {
            field.placeholder = placeholders[index];
        })
    }

    const setClassName = () => {
        const toxFieldGroups = [
            {label: 'Source', className: 'source', newLabel: 'Source'},
            {label: 'Alternative description', className: 'description', newLabel: 'Description'},
            {label: 'Image title', className: 'title', newLabel: 'Image Title'},
            {label: 'Width', className: 'Width', newLabel: 'Width/Height'},
            {label: 'Height', className: 'Height'}
        ]

        let toxFormGroup = document.getElementsByClassName('tox-form__group');
        Array.from(toxFormGroup).forEach(group => {
            if(group.firstChild.childNodes.length > 1) {
                group.firstChild.childNodes.forEach((child, index) => {
                    let toxfield = toxFieldGroups
                        .filter(item => item.label === group.firstChild.childNodes[index].innerText)[0];
                    if(toxfield) {
                        group
                            .firstChild
                            .childNodes[index]
                            .classList.add(toxfield.className.toLowerCase());
                    }
                    else {
                        group.firstChild.childNodes[index].classList.add('lock-icon');
                    }
                })
            }
            else {
                let toxField = toxFieldGroups
                    .filter(item => item.label === group.firstChild.innerText)[0];
                if(toxField) {
                    group.classList.add(toxField.className.toLowerCase());
                    group.firstChild.innerText = toxField.newLabel;
                }
            }
        })
    }

    const removeValidation = () => {
        document
        .querySelectorAll(".invalidError")
        .forEach(el => el.remove());
    }

    // const changeDescriptionInputType = () => {
    //     let descriptionInput = document.querySelector('.tox-form__group.description .tox-textfield');
    //     let desciptionTextarea = document.createElement('textarea');
    //     descriptionInput.getAttributeNames()
    //         .forEach(attrName => {
    //         desciptionTextarea.setAttribute(attrName,
    //             (document.createAttribute(attrName).value = descriptionInput.getAttribute(attrName))
    //         );
    //     });
    //     desciptionTextarea.setAttribute('rows', '1');
    //     descriptionInput.replaceWith(desciptionTextarea);
    // }

    return (
        <Wrapper height={props?.init?.height || 'auto'}>
            {
                isLoading &&
                <PreloaderWrapper>
                    <Preloader isWithoutFullPageWrapper={ true }/>
                </PreloaderWrapper>
            }
        <Editor
            apiKey={ process.env.REACT_APP_TINYMCE_KEY }
            value={value}
            onInit={handleEditorInit}
            onEditorChange={handleEditorChange}
            init={{
                height,
                menubar: false,
                branding: false,
                statusbar: false,
                skin: 'bootstrap',
                placeholder: 'Enter Your Text Please',
                mode: "exact",
                automatic_uploads: true,
                image_title: true,
                file_picker_types: 'image',
                setup: (editor) => {
                    editor.on('OpenWindow', () => {
                        let uploadedImages = Array.from(editor.getBody().getElementsByTagName('img'));
                        let uploadImageButton = document.getElementsByClassName('tox-browse-url');
                        let size = 0;
                        uploadedImages.forEach(async (img, index) => {
                            await fetch(img.src).then(r => r.blob())
                            .then(fileImg => {
                                size += fileImg.size;
                                if(index === uploadedImages.length - 1) {
                                    uploadImageButton[0].setAttribute('UPLOADED_IMAGE_SIZE', size);
                                }
                            })
                        });
                        let isUploadImageDialog = document
                        .getElementsByClassName('tox-dialog__title')[0]
                            .innerText === 'Insert/Edit Image';
                        if(isUploadImageDialog) {
                            setClassName();
                            addImgDialogTitle();
                            setPlaceholder();
                            // changeDescriptionInputType();
                            document
                                .getElementsByClassName('tox-dialog-wrap')[0]
                                .classList.add('upload-image-dialog');
                        }
                    });
                },
                file_picker_callback: (callback, value, meta) => {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    if (meta.filetype == 'image') {
                        let uploadImageButton = document.getElementsByClassName('tox-browse-url')[0];
                        input.click();
                        input.onchange = function () {
                            var file = input.files[0];
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                if(uploadImageButton) {
                                    removeValidation();
                                    let uploadedImagesSizeInBytes = +uploadImageButton.getAttribute('UPLOADED_IMAGE_SIZE') || 0;
                                    uploadedImagesSizeInBytes += file.size;
                                    let sizeInMB = (uploadedImagesSizeInBytes / (1024*1024)).toFixed(2);
                                    if(sizeInMB < 3) {
                                        callback(e.target.result, {
                                            alt: file.name
                                        });
                                    }
                                    else {
                                        let sourceInputWrap = document.querySelector('.source .tox-control-wrap');
                                        let invalidMessageEl = document.createElement('p');
                                        invalidMessageEl.innerHTML = 'Max image size should be 3 Mb';
                                        invalidMessageEl.classList.add('invalidError');
                                        if(sourceInputWrap) {
                                            sourceInputWrap.append(invalidMessageEl);
                                        }
                                    }
                                }
                            };
                            reader.readAsDataURL(file);
                        };
                    }
                },
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help'
                ],
                toolbar,
                content_style: `
                    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                        color: ${COLORS.grey};
                        font-size: 14px;
                        font-weight: 400;
                    }
                `,
                ...props.init,
            }}
        />
        </Wrapper>
    )
}

export default withStyles(styles)(TextEditor);

TextEditor.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string,
    toolbar: PropTypes.string,
    height: PropTypes.oneOf([PropTypes.string, PropTypes.number])
};

TextEditor.defaultProps = {
    onChange: () => {},
    value: '',
    className: '',
    toolbar: 'bold italic strikethrough forecolor backcolor  | \
        alignleft aligncenter alignright alignjustify | \
        numlist bullist  outdent indent | link image | removeformat | code | help',
    height: '9.375rem',
};

import React from 'react';


import { PlusWithCircle } from '@/assets';

import BlockButton from '@components/BlockButton';
import MuiBox from '@material-ui/core/Box';

import { ActionRowProps } from './types';

const BUTTON_STYLE = {
  marginLeft: 'auto',
  minWidth: 'auto',
};

export const ActionRow = ({
  buttonText = 'Add',
  buttonDisabled,
  children,
  onClick,
  ...boxProps
}: ActionRowProps) => (
  <MuiBox
    alignItems={ 'center' }
    display={ 'flex' }
    marginBottom={ '5px' }
    { ...boxProps }
  >
    {children}

    <BlockButton
      $iconSize={ '16px' }
      disabled={ buttonDisabled }
      onClick={ onClick }
      startIcon={ <PlusWithCircle/> }
      style={ BUTTON_STYLE }
    >
      { buttonText}
    </BlockButton>
  </MuiBox>
);

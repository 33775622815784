import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';

export const InitType = ACCOUNTS_AND_PLANS_TYPES.ACCOUNT;
export const InitPopupSettings = {
  accountSubTypeCashEquivalent: [],
  accountSubTypeInvestments: [],
  accountType: [],
  discretionary: [],
  feeFrequency: [],
  feeStatus: [],
  generalType: [],
  heldAwayStatus: [],
  managedStatus: [],
  policySubTypeAnnuities: [],
  policySubTypeLifeInsurance: [],
  policyType: [],
  registrationType: [],
  status: [],
  taxQualification: [],
};
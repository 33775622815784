import React, { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import FormBlock from '@components/FormBlock';
import SelectItemWithCheckbox from '@components/SelectItemWithCheckbox';
import UserItem from '@components/NewUserItem';

import { SelectedHeader, SelectedItemWrapper, SelectedWrapper } from './styles';

import { FIELD_NAMES, LABEL_WIDTH } from '@modules/AddContactToList/data';

import { getAdditionalParamsToNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getContactsForAddToList } from '@services/api/lists/lists';

import { ContactOptionToListAdd } from '@/shared/types/lists';
import { UsersProps } from './types';

const KEY_NAME = 'userFieldArrayId' as const;

export const Users = ({
  listId
}: UsersProps) => {
  const [ selectedUsers, setSelectedUsers ] = useState<Array<ContactOptionToListAdd>>([]);

  const { control, getFieldState, clearErrors,formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: FIELD_NAMES.contacts,
    keyName: KEY_NAME,
  });

  const errorMessage = formState.errors?.[FIELD_NAMES.contacts]?.message;

  const deleteItem = useCallback((data: ContactOptionToListAdd,index?: number) => {
    // @ts-ignore;
    const selectedIndex = index || fields.findIndex((user) => String(user!.id) === String(data.id));
    remove(selectedIndex);
    setSelectedUsers(users => users.filter(user => String(user.id) !== String(data.id)));
  }, [fields, remove]);

  const onAutoCompleteItemClick = useCallback((data: ContactOptionToListAdd) => () => {
    // @ts-ignore;
    const selectedIndex = fields.findIndex((user) => String(user!.id) === String(data.id));
    if(selectedIndex >= 0) {
      return deleteItem(data);
    }
    append({
      id: data.id
    });
    setSelectedUsers(((prev) => [...prev, data]));

    if(errorMessage) {
      clearErrors(FIELD_NAMES.contacts);
    }
  },[append, clearErrors, deleteItem, errorMessage, fields]);

  const onRemove = useCallback((data: ContactOptionToListAdd) => () => deleteItem(data),[deleteItem]);

  const fetchProps = useMemo(() => (
    {
      getParams: getAdditionalParamsToNameParam({ id: listId }),
      requestSender: getContactsForAddToList,
      responseConverter: usersResponseConverter,
    }
  ), [listId]);

  const renderOption = useCallback((option: ContactOptionToListAdd) => {
    const index = selectedUsers.findIndex((user) => String(user!.id) === String(option.id));

    return (
      <SelectItemWithCheckbox
        checked={ index >= 0 }
        onClick={ onAutoCompleteItemClick(option) }
      >
        <UserItem
          labelWidth={ 500 }
          user={ option }
        />
      </SelectItemWithCheckbox>
    );
  },[onAutoCompleteItemClick, selectedUsers]);

  return (
    <>
      <ControlledHiddenInput
        control={ control }
        defaultValue={ fields }
        name={ FIELD_NAMES.contacts }
      />
      <FormBlock.RowWrapper marginBottom={ '30px' }>
        <FormBlock.FormField
          $labelWidth={ LABEL_WIDTH }
          label={ 'Add Contact' }
        >
          <FetchAutoComplete<ContactOptionToListAdd>
            AutocompleteProps={ {
              disableCloseOnSelect: true,
              ListboxProps: {
                style: {
                  maxHeight: '200px'
                }
              },
              getOptionLabel: (option: any) => option.fullName || 'label',
              // @ts-ignore
              renderOption: renderOption,
            } }
            FetchProps={ fetchProps }
            TextFieldProps={ {
              error: Boolean(errorMessage),
              helperText: errorMessage as string || ''
            } }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <SelectedWrapper>
          <SelectedHeader>{'Selected Contacts to Add'}</SelectedHeader>
          {
            selectedUsers.map(user => (
              <SelectedItemWrapper key={ user.id }>
                <UserItem
                  onClose={ onRemove(user) }
                  user={ user }
                  withCoreClassification={ true }
                />
              </SelectedItemWrapper>
            ))
          }
        </SelectedWrapper>
      </FormBlock.RowWrapper>
    </>
  );
};

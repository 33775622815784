import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useToggle } from '@/shared/customHooks';

import { FORM_DEFAULT_FILTERS_VALUES, SEARCH_BUTTON_MODE } from './data';

import { SearchPanel, GeneralRowFilter } from './components';
import { Search } from '@/assets';

import { FilterWrapper, RowFilterWrapper, SearchIconButton } from './styles';

import { GeneralFiltersProps, GeneralFiltersValue, IconButtonModeType } from './types';


export const GeneralFilters = ({
  numberOfCurrentStateNotes = 0,
  numberOfNotesToRender = 0,
  onChange,
}: GeneralFiltersProps) => {

  const [filters, changeFilters] = useState(FORM_DEFAULT_FILTERS_VALUES);

  const [ isOpenSearchFilter, setIsOpenSearchFilter ] = useState<boolean>(false);
  const [ isVisibleCloseIconButton, setIsVisibleCloseIconButton ] = useToggle(false);
  const [ searchIconButtonMode, setSearchIconButtonMode ] = useState<IconButtonModeType>(SEARCH_BUTTON_MODE.inactive);
  
  const { control, reset } = useForm<GeneralFiltersValue>({
    defaultValues: FORM_DEFAULT_FILTERS_VALUES
  });
  
  const onSearchIconClick = useCallback(() => {
    setIsOpenSearchFilter(!isOpenSearchFilter);
    setSearchIconButtonMode(() => {
      if(numberOfCurrentStateNotes > numberOfNotesToRender && isVisibleCloseIconButton) {
        return SEARCH_BUTTON_MODE.withResult;
      }
      return isOpenSearchFilter ? SEARCH_BUTTON_MODE.inactive : SEARCH_BUTTON_MODE.active;
    });
  }, [isOpenSearchFilter, numberOfCurrentStateNotes, numberOfNotesToRender, isVisibleCloseIconButton]);

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  return(
    <FilterWrapper>
      <RowFilterWrapper>
        <SearchIconButton
          variant={ 'outlined' }
          onClick={ onSearchIconClick }
          mode={ searchIconButtonMode }
        >
          <Search/>
        </SearchIconButton>
        <GeneralRowFilter
          changeFilters={ changeFilters }
        />
      </RowFilterWrapper>
      { isOpenSearchFilter &&
        <SearchPanel
          changeFilters={ changeFilters }
          control={ control }
          isVisibleCloseIconButton={ isVisibleCloseIconButton }
          reset={ reset }
          setIsVisibleCloseIconButton={ setIsVisibleCloseIconButton }
          setSearchIconButtonMode={ setSearchIconButtonMode }
        />
      }
    </FilterWrapper>
  );
};
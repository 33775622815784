import { VariantType } from './types';
import { COLORS } from '@constants/colors';

export enum VARIANTS {
  MAIN = 'MAIN',
  SECOND = 'SECOND'
}

const COMMON_DATA: Omit<VariantType, 'bgColor'> = {
  itemActiveBgColor: COLORS.orange,
  itemActiveColor: COLORS.white,
  itemBgColor: 'transparent',
  itemColor: COLORS.primaryText,
};

export const VARIANTS_DATA:Record<VARIANTS, VariantType> = {
  [VARIANTS.MAIN]: {
    bgColor: COLORS.background,
    ...COMMON_DATA,
  },
  [VARIANTS.SECOND]: {
    bgColor: COLORS.white,
    ...COMMON_DATA,
  }
};

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { MARGIN_RULES_MAP, MARGIN_FROM_SWITCH } from './data';

import { ControlLabelPropsWithCustomProps } from './types';

export const StyledFormControlLabel = styled(FormControlLabel)<ControlLabelPropsWithCustomProps>`
  ${({ $fluid }) => $fluid && css`
    width: 100%;
  `}
  
  margin-left: unset;
  justify-content: flex-start;
  align-items: center;
  color: ${COLORS.primaryText};
  
  & .MuiFormControlLabel-label{
    font-size: 14px;
    
    ${({ labelPlacement, $labelMargin }) => labelPlacement && css`
        ${MARGIN_RULES_MAP[labelPlacement]}: ${$labelMargin || MARGIN_FROM_SWITCH}
    `}
  }
`;

export const StyledSwitch = styled(Switch)`
  width: 38px;
  height: 20px;
  padding: 0;
  
  & .MuiSwitch-switchBase {
    padding: 1px;
    
    &.Mui-checked{
      transform: translateX(18px);
    }
  }

  .MuiSwitch-thumb{
    height: 18px;
    width: 18px;
  }
  
  .MuiSwitch-track{
    background-color: ${COLORS.borders};
    opacity: 1;
    border-radius: 12px;
  }
  
  & .Mui-checked {
    & .MuiSwitch-thumb{
      background-color: ${COLORS.white};
    }
    
    & ~ .MuiSwitch-track{
      background-color: ${COLORS.orange};
      opacity: 1;
    }
  }
  
  & .Mui-checked.Mui-disabled{
    & ~ .MuiSwitch-track {
      opacity: 0.2;
      background-color: ${COLORS.orange};
    }
  }

  & .Mui-disabled {
    
    & .MuiSwitch-thumb{
      background-color: ${COLORS.background};
    }

    & ~ .MuiSwitch-track{
      background-color: ${COLORS.greyLight};
      opacity: 1;
    }
  }
`;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

import { useStore } from '@store';

import Surface from '@components/BasicLayoutComponents/Surface';
import BaseBlockWrapper from '@components/BasicLayoutComponents/BaseBlockWrapper';

import { ListCriteriaElementsWrapper, FormWatcher } from './components';

import { ListCriteriaProps } from './types';
import { CriteriaFormWrapper } from '@/shared/types/lists';

import { validationSchema } from './validationScheme';

export const ListCriteria = observer(({
  listId
}: ListCriteriaProps) => {
  const listCriteriaStore = useStore().ListsStore.ListCriteriaStore;
  const { isLoad, listId: storeListId } = listCriteriaStore;
  const methods = useForm<CriteriaFormWrapper>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    listCriteriaStore.init(listId, methods);
    return () => listCriteriaStore.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listCriteriaStore]);

  return (
    <FormProvider { ...methods }>
      <Surface.Content
        $isWrap={ false }
      >
        <BaseBlockWrapper
          flexGrow={ 1 }
          height={ 'calc(100vh - 202px)' }
          key={ 'new' }
          minWidth={ 1040 }
          overflow={ 'auto' }
          padding={ '20px' }
        >
          <FormWatcher/>
          {
            !isLoad && storeListId &&
            <ListCriteriaElementsWrapper />
          }
        </BaseBlockWrapper>

      </Surface.Content>
    </FormProvider>
  );
});



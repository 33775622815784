import React from 'react';

import { Header, ActionRow, Row } from './components';

import { CustomFieldsAndTagsContentProps } from './types';

import { Wrapper, ContentWrapper } from './styles';

export const CustomFieldsAndTagsContent = ({
  children,
  wrapperProps,
  $withDelimetr = true,
}: CustomFieldsAndTagsContentProps) => {

  return (
    <Wrapper
      $withDelimetr={ $withDelimetr }
      { ...wrapperProps }
    >
      {children}
    </Wrapper>);
};

CustomFieldsAndTagsContent.ActionRow = ActionRow;
CustomFieldsAndTagsContent.Content = ContentWrapper;
CustomFieldsAndTagsContent.Header = Header;
CustomFieldsAndTagsContent.Row = Row;

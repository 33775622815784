import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const YearFilter = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${COLORS.header};
`;

export const Divider = styled.div`
  width: 1px;
  height: 28px;
  margin: 0 16px;
  background: ${COLORS.greyLight};
`;

export const AdvisorPeriodFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const PeriodWrapper = styled.div`
  width: 90px;
`;

export const AdvisorLabelWrapper = styled.div`
  width: 74px;
  color: ${COLORS.helpText};
`;

import styled from 'styled-components';

export const HouseHoldIconWrapper = styled.div`
  margin-left: 10px;
`;

export const StatusChipWrapper = styled.div`
  padding-left: 10px;
  margin-left: auto;
`;


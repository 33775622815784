import React from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import {
  LeftWrapper,
  RightWrapper
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

import {
  COMMON_FIELD_NAMES,
  PLAN_BASIC_INFORMATION_FIELDS_NAMES,
} from '@modules/AccountsAndPlansPopup/data/fieldNames';
import {
  LABEL_SIZES
} from '@modules/AccountsAndPlansPopup/components/Content/components/PlanForm/data';
import { basicPlanDescriptionInputProps, feeAmountInputProp, feeAmountTextFieldProp, nameInputProps } from './data';

import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getContactWithSpouseHousehold } from '@services/api/common/common';

const { leftLabelWidth, rightLabelWidth } = LABEL_SIZES;

const LinkedContactFetchProps = {
  getParams: getNameParam,
  requestSender: getContactWithSpouseHousehold,
  responseConverter: usersResponseConverter,
};

export const PlanFormBasicInformation = observer(() => {
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const {
    popupSettings: { feeStatus, feeFrequency },
    linkedContactData,
    notesUtilsStore: { isNotesPanelInAddOrEditMode }
  }  = accountsAndPlansLocalStore;

  const { control } = useFormContext<FormFieldsValues>();

  return (
    <>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Primary Details' }/>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ leftLabelWidth }
            isRequired={ true }
            label={ 'Plan Name' }
          >
            <HFTextInput
              inputProps={ nameInputProps }
              control={ control }
              disabled={ isNotesPanelInAddOrEditMode }
              name={ COMMON_FIELD_NAMES.name }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ leftLabelWidth }
            isRequired={ true }
            label={ 'Linked Contact' }
          >
            <HFLinkedContact
              FetchProps={ LinkedContactFetchProps }
              UserItemProps={ {
                disabled: isNotesPanelInAddOrEditMode,
              } }
              control={ control }
              disabled={ isNotesPanelInAddOrEditMode }
              initLinkedContact={ linkedContactData }
              name={ COMMON_FIELD_NAMES.contactId }
              onLinkedContactChange={ accountsAndPlansLocalStore.setLinkedContactData.bind(accountsAndPlansLocalStore) }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Planning Fee' }/>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Fee Status' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.feeStatus }
                options={ feeStatus }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Fee Amount ($)' }
            >
              <HFTextInput
                inputProps={ feeAmountInputProp }
                TextFieldProps={ feeAmountTextFieldProp }
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.feeAmount }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Fee Frequency' }
            >
              <HFSelect
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.feeFrequency }
                options={ feeFrequency }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>

          </RightWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Additional Details' }/>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Start Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.startDate }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Completion Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.completionDate }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>

      </FormBlock>
      <FormBlock
        isBorderBottom={ false }
        marginBottom={ 20 }
      >
        <FormBlock.Header title={ 'Basic Plan Description' }/>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ leftLabelWidth }
            label={ 'Description' }
          >
            <HFTextInput
              inputProps={ basicPlanDescriptionInputProps }
              TextFieldProps={ {
                multiline: true
              } }
              control={ control }
              disabled={ isNotesPanelInAddOrEditMode }
              name={ PLAN_BASIC_INFORMATION_FIELDS_NAMES.basicPlanDescription }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});

//

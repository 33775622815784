import { COLORS } from '@/shared/constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  max-height: calc(100vh - 140px - 64px);
  background-color: ${ COLORS.background };
  padding-right: 5px;
`;

export const LeftColumn = styled.div`
  width: 200px;
  padding-top: 13px;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};
`;

export const MiddleColumn = styled.div`
  width: 740px;
  overflow: auto;
  padding: 25px 25px 20px 30px;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};
`;
import { InfoIcon } from './InfoIcon';
import {
  INFO_ICON_TYPES,
  INFO_COLOR_SCHEME
} from './data';

export {
  INFO_ICON_TYPES,
  INFO_COLOR_SCHEME,
};

export default InfoIcon;

import React from 'react';

import { Controller } from 'react-hook-form';

import CustomDatePicker from '@components/CustomDatePicker';

import { HFDatePickerProps } from '@modules/HookFormComponents/HFDatePicker/types';

export const HFDatePicker = ({
  ControllerProps,
  DatePickerProps,
  control,
  convertFormat,
  dateViewFormat,
  disabled,
  isDateWithTime,
  name,
  preventMinDateAutoSet,
  showError = true,
}: HFDatePickerProps) => {
  return (
    <Controller
      control={ control }
      name={ name }
      render={ ({
        field: {
          value,
          onChange
        },
        fieldState: { error }
      }) => (
        <CustomDatePicker
          DatePickerProps={ DatePickerProps }
          convertFormat={ convertFormat }
          dateViewFormat={ dateViewFormat }
          disabled={ disabled }
          errorMessage={ error?.message }
          isDateWithTime={ isDateWithTime }
          onChange={ onChange }
          preventMinDateAutoSet={ preventMinDateAutoSet }
          showError={ showError }
          value={ value }
        />
      ) }
      { ...ControllerProps }
    />
  );
};

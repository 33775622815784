import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

type getDateContentProps = {
  dateString: string | null | undefined,
  fallBackContent?: string,
  formatTemplate?: string
}

export const getDateContent = ({
  dateString,
  fallBackContent = '',
  formatTemplate = MONTH_DAY_YEAR_BACKSLASH,
}: getDateContentProps) => {

  if(!dateString){
    return fallBackContent;
  }

  const date = new Date(dateString);

  if(isValid(date)){
    return format(date, formatTemplate);
  } else {
    return fallBackContent;
  }
};

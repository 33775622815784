import { AxiosResponse } from 'axios';
import { BackendGridResponse, Client, IdType, ValueLabelObj } from '@/shared/types/commonTypes';

export type GridResponse = AxiosResponse<BackendGridResponse<ContactActivityData>>;

export type ContactActivity = {
  allDay: string,
  clients: Array<ActivityClient>,
  contact: Client,
  endDate: string,
  flagged: boolean,
  id: IdType,
  // eslint-disable-next-line no-restricted-globals
  name: string,
  recurring: boolean,
  startDate: string,
  startTime: string,
  statusId: IdType,
  type: string,
};

export type ActivityClient = Client

export type Filters = {
  [ContactActivityFiltersNames.Clients]?: Array<string> | null,
  [ContactActivityFiltersNames.StatusId]?: Array<string | number>,
};

export type ActivityTypeFilter = {
  [ContactActivityFiltersNames.Type]?: string | null,
}

export type FilterData = {
  clients: Array<Client>,
};

export type ContactActivityData = {
  [key: string]: ContactActivity,
} & {
  filterData: FilterData,
};

export type FilterDataConverter = {
  clients: Array<ValueLabelObj>;
  type?: Array<ValueLabelObj>;
};

export enum CalendarPeriod {
  all = 'All',
  month = 'Month',
  week = 'Week',
  day = 'Day',
}

export enum ContactActivityFiltersNames {
  Type = 'type',
  Clients = 'clients',
  StatusId = 'statusId'
}
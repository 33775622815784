import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';

import { NotesAndHistoryNotificationTextProps } from './types';

import { ALERT_TEXTS } from '../../data';

export const NotesAndHistoryNotificationWrapper = ({
  alertType,
  alertTexts,
  boxProps,
  children,
}: NotesAndHistoryNotificationTextProps) => {
  const alertText = useMemo(() => {
    if(alertType && alertTexts && alertTexts?.[alertType]){
      return alertTexts?.[alertType];
    }

    if(alertType){
      return ALERT_TEXTS[alertType];
    }
    return '';
  }, [alertType, alertTexts]);

  if(alertText){
    return (
      <Box
        alignItems={ 'center' }
        display={ 'flex' }
        { ...boxProps }
      >
        {alertText}
      </Box>
    );
  }

  return (
    <>
      {children || null}
    </>
  );
};

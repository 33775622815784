import { ColumnsType } from '@components/Table/components/Header/types';

import { FieldNames } from './types';

export const LINK_NEW_CONTACT_LABEL = 'Link New Related Contact';

export const FIELD_NAMES:FieldNames  = {
  birthdate: 'birthdate',
  firstName: 'firstName',
  id: 'id',
  lastName: 'lastName',
  referral: 'referral',
  referralDate: 'referralDate',
  relationType: 'relationType',
  relativeDescription: 'relativeDescription',
  relativeId: 'relativeId',
};

export const BUTTON_STYLES = {
  marginLeft: 'auto'
};

export const COLUMNS: ColumnsType = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '23%',
    },
    sortable: true,
  },
  {
    id: 'relationType',
    label: 'Relation',
    style: {
      width: '9%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'age',
    label: 'Age',
    style: {
      width: '5%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'birthdate',
    label: 'Birthdate',
    style: {
      width: '7%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'referal',
    label: 'Referral',
    style: {
      width: '7%'
    },
    separator: true,
  },
  {
    id: 'referalDate',
    label: 'Referral Date',
    style: {
      width: '8%'
    },
    separator: true,
  },
  {
    id: 'description',
    label: 'Description',
    style: {
      width: '37%'
    },
    separator: true,
  },
];

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { ItemWrapperProps, WrapperProps } from './types';
import { VARIANTS_DATA } from '@components/ToggleWithInnerLabels/data';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  padding: 1px;
  border-radius: 20px;
  border: 1px solid ${COLORS.borders};
  
  ${({ $variant }) => $variant && css`
    background-color: ${VARIANTS_DATA[$variant].bgColor};
  `}
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  padding: 0 12px;
  height: 28px;
  border-radius: 20px;
  transition: 
          background-color 0.3s linear,
          color 0.3s linear;

  ${({ $variant }) => $variant && css`
    background-color: ${VARIANTS_DATA[$variant].itemBgColor};
    color: ${VARIANTS_DATA[$variant].itemColor};
  `}
  
  ${({ $isActive, $variant  }) => $isActive && css`
    background-color: ${VARIANTS_DATA[$variant].itemActiveBgColor};
    color: ${VARIANTS_DATA[$variant].itemActiveColor};
  `}
`;

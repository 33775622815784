import styled from 'styled-components';

export const LeftColumnWrapper = styled.div`
  width: 50%;
  padding-right: 30px;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  padding-left: 16px;
`;
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

export const SURFACE_CONTENT_WRAPPER_STYLE_PROPS = {
  overflow: 'auto',
  width: '100%',
  height: 'calc(100vh - 200px)',
  paddingRight: '0 20px 20px 20px',
  margin: 0
};

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0 20px 0px 20px;
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const NotesAndHistoryWrapper = styled.div`
  width: 40%;
  min-width: 450px;
  min-height: 900px;
  border-radius: inherit;
  box-sizing: border-box;

  & > div {
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px #101C5B12;
    background: ${COLORS.white};
  }
  & > div > div {
    padding: 20px;
  }
`;
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

import { COLORS } from '@constants/colors';

import { StyledBtnProps } from './types';
import { VARIANTS } from './data';

export const StyledBtn = styled(Button)<StyledBtnProps>`
  &.MuiButton-root {
    ${({ 
    $color,
    $bgColor,
    $borderColor
  }) => css`
      ${$color && css`color: ${$color};`}
      ${$bgColor && css`background-color: ${$bgColor};`}
      ${$borderColor && css`border: 1px solid ${$borderColor};`}
    `}

    &:hover {
      ${({ 
    $hoverColor,
    $bgHoverColor,
    $borderHoverColor
  }) => css`
        ${$hoverColor && css`color: ${$hoverColor};`}
        ${$bgHoverColor && css`background-color: ${$bgHoverColor};`}
        ${$borderHoverColor && css`border: 1px solid ${$borderHoverColor};`}
      `}
    }

    ${({ disabled, variant
  }) => disabled && css`
      opacity: 0.8;
      
      ${variant === VARIANTS.contained &&
        css`
          background-color: ${COLORS.search};
          color: ${COLORS.white};
        `}

      ${variant ===  VARIANTS.text &&
      css`
          color: ${COLORS.search};
        `}

      ${variant === VARIANTS.outlined &&
      css`
        border: 1px solid ${COLORS.grey};
        color: ${COLORS.grey};
      `}
    `}
    
    

    & svg {
      width: ${({ $iconSize }) => $iconSize || '21px'};
      height: ${({ $iconSize }) => $iconSize || '21px'};

      ${({ $isNoneFill }) => !$isNoneFill && css`
        fill: currentColor;

        & path{
          fill: currentColor;
        }
      `};
    }
  }
`;


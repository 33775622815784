import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors'; 

export const Wrapper = styled.div`
    display: flex;
`;
export const Item = styled.div`
    ${({ disabled }) => disabled && css `pointer-events: none;`};

    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 50%;
    line-height: 1;
    border: 1px solid ${props => props.isActive ? props.activeColor : COLORS.borders};
    background-color: ${props => props.isActive ? props.activeColor : COLORS.white};
    color: ${props => props.isActive ? COLORS.white : COLORS.borders};
    font-size: 12px;
    text-align: center;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      cursor: pointer;
    }

    ${props => props.isActive ? '' : css`
      &:hover {
        border: 1px solid ${COLORS.logoOrange};
        color: ${COLORS.logoOrange};
      }
    `}
`;

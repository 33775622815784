import styled from 'styled-components';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  pointer-events: ${({ $preventClicks }) => $preventClicks ? 'none' : 'auto'};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: -8px;

  & button {
    min-width: auto;
  }
`;

export const NotificationText = styled.div`
  display: flex;
  align-items: center;
`;

import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';
import { EmployeeOwnFields } from '@/shared/types/employee';
import { Contact } from '@/shared/types/contactsData';

export const employeeNormalizers = (data: EmployeeOwnFields) => {

  const { phoneType, emailType, phone, email, contacts, ...restEmployeeData } = data;

  const phoneNumber = getIsOnlyCountryCode(phone || '') ? null : phone;
  if(phoneNumber && phoneType) {
    contacts.push({
      fieldId: phoneType,
      value: phoneNumber,
    } as Contact);
  }

  if(email && emailType) {
    contacts.push({
      fieldId: emailType,
      value: email
    } as Contact);
  }

  const newContacts = [] as Array<Contact>;

  // TODO type Contact Details
  const contactsToSave = contacts.filter(item => {
    //@ts-ignore
    if (newContacts.indexOf(item.value) === -1) {
      //@ts-ignore
      newContacts.push(item.value);
      return true;
    }
    return false;  
  });

  return {
    ...restEmployeeData,
    contacts: contactsToSave
  };
};

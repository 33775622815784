import get from 'lodash/get';

type Direction = 'asc' | 'desc'

type ExtendItemType = {
  [K : string]: any
  sort: number | string
}
type WithData = {
  data: ExtendItemType
  [K : string]: any
}

type ExtendType = WithData | ExtendItemType

type Params = {
  direction: Direction
  isReturnFirstIfEqual?: boolean
  isReturnLastIfEqual?: boolean
}
export const sortCompareFn =
  <T extends ExtendType>({
    direction = 'asc',
    isReturnFirstIfEqual = false,
    isReturnLastIfEqual = false
  }:Params) => (
    a: T,
    b:T
  ): number => {
    const path = 'data' in a && 'data' in b ? 'data.sort' : 'sort';
    const aSortValue = get(a, path);
    const bSortValue = get(b, path);
    if(aSortValue > bSortValue){
      return direction === 'asc' ? 1 : -1;
    }

    if(aSortValue < bSortValue){
      return direction === 'asc' ? -1 : 1;
    }

    if(aSortValue === bSortValue && isReturnFirstIfEqual){
      return 1;
    }

    if(aSortValue === bSortValue && isReturnLastIfEqual){
      return -1;
    }

    return 0;
  };
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import InfoIcon, { INFO_ICON_TYPES, INFO_COLOR_SCHEME } from '@components/InfoIcon';

import { getHeadOfHouseHoldOptions } from './utils';

import { ADD_SPOUSE_FIELD_NAMES } from '../../data';

import { ValueLabelObj } from '@/shared/types/commonTypes';
import { ContactItem } from '@/shared/types/contact';

import { HeadOfHouseHoldFieldProps } from './types';

const INFO_ICON_TEXT = `New created Individual Contact and Household Contact will keep 
Classification, Rank, Status, Primary advisor, Source and Source Category form the contact they are added to.`;

export const HeadOfHouseHoldField = ({
  labelWidth,
  contactId,
  currentContact,
  existedContact,
}: HeadOfHouseHoldFieldProps) => {
  const { control, setValue } = useFormContext();

  const [firstNameValue, lastNameValue, headOfHouseHoldValue] = useWatch({
    control: control,
    name: [ADD_SPOUSE_FIELD_NAMES.firstName, ADD_SPOUSE_FIELD_NAMES.lastName, ADD_SPOUSE_FIELD_NAMES.headOfHouseHoldId]
  });

  const headOfHouseHoldOptions: Array<ValueLabelObj> = useMemo(() => {
    return getHeadOfHouseHoldOptions({
      currentContact: currentContact as ContactItem,
      existedContact: existedContact,
      firstNameValue,
      lastNameValue,
    });
  },[currentContact, existedContact, firstNameValue, lastNameValue]);

  const renderLabelContent = useCallback(() => (
    <InfoIcon
      $tooltipMaxWidth={ '310px' }
      colorScheme={ INFO_COLOR_SCHEME.mainReversed }
      helpText={ INFO_ICON_TEXT }
      type={ INFO_ICON_TYPES.alertInfo }
      wrapperProps={ {
        marginLeft: '8px'
      } }
    />
  ),[]);

  const isNewUserFilled = firstNameValue && lastNameValue;

  useEffect(() => {
    if(!isNewUserFilled && !existedContact && headOfHouseHoldValue !== contactId){
      setValue(ADD_SPOUSE_FIELD_NAMES.headOfHouseHoldId, contactId);
    }
  }, [isNewUserFilled, existedContact, headOfHouseHoldValue, contactId, setValue]);

  return (
    <FormBlock.FormField
      $labelWidth={ labelWidth }
      label={ 'Head of Household' }
      renderLabelContent={ renderLabelContent }
    >
      <HFSelect
        ControllerProps={ {
          defaultValue: contactId
        } }
        control={ control }
        disabled={ !isNewUserFilled && !existedContact }
        name={ ADD_SPOUSE_FIELD_NAMES.headOfHouseHoldId }
        options={ headOfHouseHoldOptions }
      />
    </FormBlock.FormField>
  );
};

import React from 'react';

import AlertPopup from '@components/AlertPopup';
import BlockButton from '@components/BlockButton';

import { COLOR_SCHEME, POPUP_TYPES } from './data';

import { ButtonsWrapper, ButtonWrapper, Wrapper } from './styles';
import { ConfirmationPopupProps } from '@modules/ConfirmationPopup/types';

export const ConfirmationPopup = ({
  cancelLabel = 'Cancel',
  children,
  confirmLabel = 'Okay',
  isConfirmationDisabled = false,
  isOpen = false,
  message,
  onClose,
  onCancelClick,
  onConfirmClick,
  type = POPUP_TYPES.WARNING,
}: ConfirmationPopupProps) => {
  const { cancelColorSchemeType, confirmColorSchemeType } = COLOR_SCHEME[type] || {};

  return (
    // TODO Replace markup from AlertPopup
    // @ts-ignore
    <AlertPopup
      isOpen={ isOpen }
      message={ message }
      onClose={ onClose }
      type={ type }
    >
      <Wrapper>
        { children }
        <ButtonsWrapper>
          {
            onConfirmClick &&
            <ButtonWrapper>
              <BlockButton
                colorSchema={ confirmColorSchemeType }
                disabled={ isConfirmationDisabled }
                onClick={ onConfirmClick }
              >
                { confirmLabel }
              </BlockButton>
            </ButtonWrapper>
          }

          {
            onCancelClick &&
            <ButtonWrapper>
              <BlockButton
                colorSchema={ cancelColorSchemeType }
                onClick={ onCancelClick }
              >
                { cancelLabel }
              </BlockButton>
            </ButtonWrapper>
          }
        </ButtonsWrapper>
      </Wrapper>
    </AlertPopup>
  );
};

import { CONTACT_TYPES } from '@constants/common';

export const getUserTitle = (item, reversedNames = false) => {
  const householdData = item.type && item.type === CONTACT_TYPES.HOUSEHOLD ? item?.householderContacts : [];

  if (householdData?.length > 0) {
    const firstPersonFullName = reversedNames
      ? `${householdData?.[0]?.lastName}, ${householdData?.[0]?.firstName}`
      : `${householdData?.[0]?.firstName} ${householdData?.[0]?.lastName}`;

    const secondPersonFullName = reversedNames
      ? `${householdData?.[1]?.lastName}, ${householdData?.[1]?.firstName}`
      : `${householdData?.[1]?.firstName} ${householdData?.[1]?.lastName}`;

    return `${firstPersonFullName} & ${secondPersonFullName}`;
  }

  if (item?.firstName && item?.lastName) {
    return reversedNames
      ? `${item?.lastName}, ${item?.firstName}`
      : `${item?.firstName} ${item?.lastName}`;
  }

  if (item?.occupationCompany) {
    return item?.occupationCompany;
  }

  return '';
};

import React from 'react';
import { useController } from 'react-hook-form';

import LinkedItem from '@modules/LinkedItem';

import { HFLinkedItemProps } from './types';

export const HFLinkedItem = ({
  AutocompleteProps,
  ControllerProps,
  FetchProps,
  inputProps,
  PopperStyledProps,
  TextFieldProps,
  UserItemProps,
  control,
  disabled,
  initLinkedContact,
  isEntity = false,
  name,
  onLinkedContactChange,
  refreshData,
  showError = true,
  withEditButton,
  withoutQueryMode,
  withRemoveButton,
}: HFLinkedItemProps) => {
  const {
    field:{ onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    ...ControllerProps,
  });
  return(
    <LinkedItem
      AutocompleteProps={ AutocompleteProps }
      FetchProps={ FetchProps }
      inputProps={ inputProps }
      PopperStyledProps={ PopperStyledProps }
      TextFieldProps={ {
        ...TextFieldProps,
        error: Boolean(error?.message),
        helperText: error?.message || ''
      } }
      UserItemProps={ UserItemProps }
      disabled={ disabled }
      initLinkedContact={ initLinkedContact }
      isEntity={ isEntity }
      onChange={ onChange }
      onLinkedContactChange={ onLinkedContactChange }
      refreshData={ refreshData }
      showError={ showError }
      value={ value }
      withEditButton={ withEditButton }
      withoutQueryMode={ withoutQueryMode }
      withRemoveButton={ withRemoveButton }
    />
  );
};

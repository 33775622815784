import React, { useCallback, useState, useMemo, ChangeEvent } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import CustomTextField from '@components/CustomTextField';

import {
  TextContent,
  Wrapper
} from './styles';

import { DeletePopupWithControlPhraseProps } from '@modules/DeletePopupWithControlPhrase/types';

export const DeletePopupWithControlPhrase = ({
  cancelLabel,
  confirmLabel,
  maxLength,
  message,
  onCancelClick,
  onClose,
  onConfirmClick,
  confirmationPhrase = 'delete',
  placeholder = 'Enter word "delete"',
}: DeletePopupWithControlPhraseProps) => {
  const [ confirmationText, setConfirmationText ] = useState('');

  const onConfirmationTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(event.target.value);
  }, []);

  const memoizedInputProps = useMemo(() => ({
    ...(maxLength ? { maxLength } : {})
  }), [maxLength]);

  return (
    <ConfirmationPopup
      cancelLabel={ cancelLabel }
      confirmLabel={ confirmLabel }
      isConfirmationDisabled={ confirmationText !== confirmationPhrase }
      isOpen={ true }
      message={ <TextContent>{message}</TextContent> }
      onCancelClick={ onCancelClick }
      onClose={ onClose }
      onConfirmClick={ onConfirmClick }
    >
      <Wrapper>
        <CustomTextField
          inputProps={ memoizedInputProps }
          onChange={ onConfirmationTextChange }
          placeholder={ placeholder }
          value={ confirmationText }
        />
      </Wrapper>
    </ConfirmationPopup>
  );
};
import React from 'react';

import { AdditionalDetilas, PrimaryDetails } from './components';
export const Content = () => {

  return (
    <>
      <PrimaryDetails/>
      <AdditionalDetilas />
    </>
  );
};

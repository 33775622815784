import {
  ACCOUNT_DATA_SCHEME,
  LINKED_ITEM_ID_NAMES,
  OPPORTUNITY_DATA_SCHEME,
  PLAN_DATA_SCHEME,
  POLICY_DATA_SCHEME,
  SALES_PIPELINE_DATA_SCHEME,
  TASK_DATA_SCHEME
} from '@constants/linkedItem';

import { NoteLinkedItemSchemeType, SchemeDataName } from '@/shared/types/contactOverview';


export const NOTES_LINKED_ITEMS_IDS = {
  accountId: LINKED_ITEM_ID_NAMES.accountId,
  opportunityId: LINKED_ITEM_ID_NAMES.opportunityId,
  planId: LINKED_ITEM_ID_NAMES.financialPlanId,
  policyId: LINKED_ITEM_ID_NAMES.policyId,
  salesPipeLineId: LINKED_ITEM_ID_NAMES.salesPipelineId,
  taskId: LINKED_ITEM_ID_NAMES.taskId,
} as const;

export const NOTES_LINKED_ITEMS_TYPES_SCHEME: Record<SchemeDataName, NoteLinkedItemSchemeType> = {
  ...ACCOUNT_DATA_SCHEME,
  ...OPPORTUNITY_DATA_SCHEME,
  ...PLAN_DATA_SCHEME,
  ...POLICY_DATA_SCHEME,
  ...SALES_PIPELINE_DATA_SCHEME,
  ...TASK_DATA_SCHEME
};

import ApiService from '@services/api/api.service';

import { IdType } from '@/shared/types/commonTypes';
import { BackendTodoFormFields, DeleteTodoParams } from '@/shared/types/todos';

export const getPopupSettings = () => {
  return ApiService.get('api/setting/task/getTaskPopupSettings');
};

export const getTodoById = (params: { id: IdType }) => {
  return ApiService.get('api/task/getTaskById', { params });
};

export const updateTodo = (data: BackendTodoFormFields) => {
  return ApiService.patch('/api/task/update', data);
};

export const saveTodo = (data: BackendTodoFormFields) => {
  return ApiService.post('api/task/create', data);
};

export const deleteTodo = (data: DeleteTodoParams) => {
  return ApiService.delete('/api/task/delete', { data });
};


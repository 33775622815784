import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import SplitToggle from '@modules/Lists/SplitToggle';

import { ExportTypeButtons } from './components';

import { ExportData } from '@/shared/types/lists';
import { ExportsContactPopupProps } from './types';

import { EXPORT_CONTACTS_DEFAULT_FORM_STATE, EXPORT_FIELD_NAMES, LABEL_WIDTH, OPTIONS_FOR_FILE_TYPE } from './data';

import { SelectWrapper } from './styles';
import { getExportContacts } from '@services/api/lists/lists';

export const ExportListContactsPopup = ({
  closeModal,
  modalProps
}: ExportsContactPopupProps) => {
  const { item, onSave } = modalProps;

  const methods = useForm<ExportData>({
    defaultValues: {
      ...EXPORT_CONTACTS_DEFAULT_FORM_STATE,
      splitHousehold: item.splitHousehold,
    }
  });

  const { control,handleSubmit } = methods;

  const contactsCount = useMemo(() => (
    item.contactData.count + Object.keys(item.contactData).reduce((acc: number, item) => {
      if(isNaN(parseInt(item))){
        return  acc;
      }
      acc = acc + 1;
      return acc;
    },0)
  ),[item.contactData]);

  const onSaveClick = useCallback(() => {
    handleSubmit(async (data) => {
      closeModal();
      getExportContacts(data)
        .then(response => {
          const href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'contacts.zip'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    })();
  },[closeModal, handleSubmit]);

  return (
    <Modal
      onClose={ closeModal }
      title={ 'Export Contacts' }
    >
      <Modal.Content
        padding={ '30px 30px 30px 30px' }
        width={ '800px' }
      >
        <FormBlock isBorderBottom={ false }>
          <FormBlock.Header
            title={ `This export will run for ${contactsCount} 
              selected ${contactsCount > 1 ? 'contacts' : 'contact'}.`
            }
          />
          <HFHiddenInput
            control={ control }
            defaultValue={ item.id }
            name={ EXPORT_FIELD_NAMES.id }
          />
          <FormProvider { ...methods }>
            <FormBlock.RowWrapper marginBottom={ '15px' }>
              <FormBlock.FormField
                $labelHeight={ 74 }
                $labelWidth={ LABEL_WIDTH }
                label={ 'Export Type' }
              >
                <ExportTypeButtons />
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
            <FormBlock.RowWrapper>
              <FormBlock.FormField
                $labelWidth={ LABEL_WIDTH }
                label={ 'Export File' }
              >
                <SelectWrapper>
                  <HFSelect
                    control={ control }
                    name={ EXPORT_FIELD_NAMES.fileType }
                    options={ OPTIONS_FOR_FILE_TYPE }
                  />
                </SelectWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
            <FormBlock.RowWrapper>
              <SplitToggle
                control={ control }
                labelWidth={ LABEL_WIDTH }
                name={ EXPORT_FIELD_NAMES.splitHousehold }
              />
            </FormBlock.RowWrapper>
          </FormProvider>
        </FormBlock>
      </Modal.Content>
      <Modal.Footer>
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onSaveClick }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};

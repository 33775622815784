/* eslint-disable */
import { makeStyles} from "@material-ui/core";

import Box from "@material-ui/core/Box";

import styled from "styled-components";

export const CheckBoxContainer = styled(Box)`
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	padding: 0 2px;
`;

const useStyles = makeStyles( (theme) => ({
	wrapper: {
		'& .popupContent': {
			width: 800,
		},
	},
	popupContent: {
		width: 800,
		padding: 30,
		paddingRight: 19,
		overflowX: 'hidden',

		'& .pageTitle': {
			fontSize: 16,
			color: theme.palette.custom.tabTextGray,
			'&.secondTitle': {
				marginTop: 30,
			},
			'&.thirdTitle': {
				marginTop: 15,
			},
		},

		'& .contentPanel': {
			marginTop: 40,

			'& .linkedMembersSearch': {
				width: '100%',
				height: 44,
				marginTop: 15,
				padding: '7px 20px',
				display: 'flex',
				justifyContent: 'space-between',
				backgroundColor: theme.palette.custom.pageBackground,

				'& .membersSingleSearch': {
					width: 575,
				},

				'& .itemsSingleSearch': {
					width: 600,
				},

				'& .linkedMembersTitle': {
					marginTop: 6,
					fontSize: 14,
					color: theme.palette.custom.helperText,
				}
			},

			'& .primaryPointOfContactNote': {
				width: '100%',
				height: 74,
				marginTop: 15,
				padding: '12px 50px 12px 20px',
				display: 'flex',
				flexWrap: 'nowrap',
				justifyContent: 'space-between',
				backgroundColor: theme.palette.custom.pageBackground,
				borderRadius: 4,
				borderLeft: `2px solid ${theme.palette.primary.main}`,

				'& .noteText': {
					marginLeft: 16,
					marginTop: 5,
					fontSize: 14,
					color: theme.palette.custom.mainText,
				},
			},
		},
	},
	listItem: {
		width: 200,
		height: 40,
		paddingLeft: 25,
		'&.active': {
			width: 199,
			color: theme.palette.primary.main,
			borderRight: `2px solid ${theme.palette.primary.main}`,
			backgroundColor: theme.palette.custom.selectedMenuItem,
		},
	},
	listItemText: {
		'& span': {
			fontSize: 14,
		},
	},
	formFields: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		'& .field': {
			height: 30,
			display: 'flex',
			'& .MuiTypography-h2': {
				fontSize: '3.75rem',
			},
		},
		'& .textEditor': {
			marginTop: 15,
			width: 739,
			height: 300,
			'& .tox-tinymce': {
				'& .tox-toolbar__group:not(:last-of-type)': {
					borderRightColor: theme.palette.custom.borders,
				},
				borderRadius: 4,
				borderColor: theme.palette.custom.borders,
			},
		},
		'&.secondPage .field': {
			marginTop: 10,
		},
		'& .fullField': {
			width: 752,
			'&.topField': {
				marginTop: 30,
			},
			'&.topNextPartField': {
				marginTop: 20,
			},
			'&.userDataField': {
				height: 40,
				padding: '5px 0 5px 20px',
				marginTop: 0,
				borderBottom: `1px solid ${theme.palette.custom.borders}`,
			},
		},
		'& .textAreaField': {
			height: 50,
		},
		'& .leftField ': {
			width: 345,
		},
		'& .leftTimeField ': {
			width: 360,
		},
		'& .checkedTimeField': {
			width: 623,
			marginLeft: 115,
		},
		'& .marginTop10': {
			marginTop: 10,
		},
		'& .rightField ': {
			width: 345,
		},
		'& .centerField ': {
			marginLeft: 65,
			width: 290,
		},
		'& .lastField ': {
			width: 110,
			marginLeft: 20,
		},
		'& .fieldLabel': {
			fontSize: 14,
			color: theme.palette.custom.helperText,
			marginTop: 5,
		},
		'& .leftFieldLabel': {
			width: 109,
		},
		'& .leftFieldSelectLabel': {
			width: 135,
			marginRight: 10,
		},
		'& .checkedTimeFieldLabel': {
			marginLeft: 10,
			width: 83,
			cursor: 'pointer',
		},
		'& .rightFieldLabel': {
			width: 145,
		},
		'& .rightTimeFieldLabel': {
			width: 33,
		},
		'& .endDateFieldLabel': {
			width: 75,
		},
		'& .centerFieldLabel': {
			width: 40,
		},
		'& .lastFieldLabel': {
			width: 37,
		},
		'& .fieldInput': {
			marginTop: 0,
			'& .MuiFormHelperText-root': {
				marginTop: 2,
			},
		},
		'& .memberTypeSelect': {
			width: 200,
			marginRight: 110,
		},
		'& .textData': {
			marginTop: 6,
			width: 155,
		},
		'& .fullFieldInput': {
			width: 606,
			// width: 595,
			'&.textArea': {
				marginBottom: 10,
				'&.multilineTextArea .MuiInputBase-multiline': {
					padding: 7
				},
				'&.multilineTextArea textarea': {
					minHeight: 34
				}
			},
			'&.withoutLabel': {
				width: 739,
			},
		},
		'& .leftFieldInput': {
			width: 200,
		},
		'& .toggleInput': {
			marginLeft: 0,
		},
		'& .addContactFirstNameInput': {
			width: 295,
		},
		'& .addContactLastNameInput': {
			width: 295,
			marginLeft: 16,
		},
		'& .addContactLeftInput': {
			width: 380,
		},
		'& .addContactRightInput': {
			width: 213,
			marginLeft: 15,
		},
		'& .leftSelectFieldInput': {
			width: 246,
			marginRight: 50,
		},
		'& .rightFieldInput': {
			width: 200,
		},
		'& .centerFieldInput': {
			width: 230,
		},
		'& .lastFieldInput': {
			width: 73,
		},
		'& .emailFieldInput': {
			width: 207,
		},
		'& .emailStatus': {
			marginTop: 3,
			flexGrow: 1,
			'& .emailStatusElement': {
				float: 'right',
			},
		},
		'& .errorInput': {
			fontSize: 10,
			marginTop: -1,
			paddingBottom: 0,
			color: theme.palette.error.main,
			textAlign: 'left',
			marginLeft: 150,
			height: 0,
		},
		'& .relatedContactFirstName': {
			marginLeft: 5
		},
		'& .relatedContactLastName':{
			marginLeft: 20
		},
		'& .requiredLabel': {
			color: theme.palette.error.main,
		},
		'& .hidden': {
			display: 'none',
		},

		'& .userTags': {
			marginLeft: 109,
			width: 'calc(100% - 109px)',
			display: 'flex',
			flexWrap: 'nowrap',
			justifyContent: 'space-between',

			'& .tagArea': {
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				width: 'inherit',

				'& .userTag': {
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
					maxWidth: '500px',
					marginRight: 10,
					marginTop: 10,

					'& input': {
						fontSize: 14
					}
				},
			},

			'& .viewAll': {
				marginTop: 15,
				minWidth: 90,
				color: theme.palette.primary.main,
				fontSize: 14,
			},
		},

		'& .allTagArea': {
			marginLeft: 109,
			marginBottom: 15,
			display: 'flex',
			flexWrap: 'wrap',
			boxSizing: 'border-box',
			justifyContent: 'flex-start',

			'& .userTag': {
				marginRight: 10,
				marginTop: 10,
			},
		},

		'& .radioButton, & .closeButton': {
			marginTop: 7,
			cursor: 'pointer',
		},
	},
	hr: {
		position: 'relative',
		marginTop: 20,
		backgroundColor: theme.palette.custom.collapsedIcon,
		color: theme.palette.custom.collapsedIcon,
		height: 1,
		border: 0,
		width: '100%',
		'&.viewAllTagsHr': {
			marginLeft: 109,
			marginTop: 10,
		},
		'&.primary': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
	},
	searchInput: {
		height: 30,
		backgroundColor: theme.palette.primary.contrastText,
	},
	smallDatePicker: {
		width: 140,
		'& .MuiOutlinedInput-root': {
			width: 140,
		},
	},
	datePicker: {
		width: 200,
		'& .MuiOutlinedInput-root': {
			width: 200,
		},
	},
	endDatePicker: {
		width: 252,
		'& .MuiOutlinedInput-root': {
			width: 252,
		},
	},
	timePicker: {
		width: 100,
		marginLeft: 10,
		'& .MuiOutlinedInput-root': {
			width: 100,
		},
	},
	bottomLeftButton: {
		'&.MuiButton-root': {
			minWidth: 'inherit',
			paddingLeft: 0,
			paddingRight: 0,
		},
		'&:hover': {
			backgroundColor: 'inherit',
			color: theme.palette.primary.main,
		},
		'&.edgePage': {
			color: theme.palette.custom.borders,
		},
	},
	linkedSpouseSubtitle: {
		width: 720,
		marginTop: 20,
		fontSize: 12,
		color: theme.palette.custom.helperText,
	},

	userItem: {
		width: '100%',
		'&.userData': {
			width: 'calc(100% - 320px)'
		},
	},

	addTag: {
		display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 37,
		height: 24,
		marginTop: 10,
		borderRadius: 4,
		backgroundColor: theme.palette.custom.tagBackground,
		color: theme.palette.custom.tagText,
		cursor: 'pointer',
		'&.tag2': {
			justifyContent: 'start',
			width: 'auto',
			maxWidth: 285,
			padding: '0 10px',
			backgroundColor: theme.palette.custom.pageBackground,
			color: theme.palette.custom.helperText,
			fontSize: 12,
		},
	},

	iTooltip: {
		marginTop: 7,
		marginLeft: 10,
		color: theme.palette.custom.helperText,
	},
}));

export default useStyles;

import { TemplatesType } from '@components/DotsMenu/types';
import { DOTS_MENU_COMMON_COLORS, DOTS_MENU_DELETE_COLORS } from '@components/DotsMenu';

import { Close, Edit, Trash } from '@/assets';

import { ListContactsDotsMenuItemTypes } from './types';
import { ListTypes } from '@/shared/types/lists';
import { LISTS_TYPES } from '@constants/lists';

export const LIST_CONTACTS_DOTS_MENU_TYPES = {
  remove: 'REMOVE',
  delete: 'DELETE',
  view: 'VIEW',
} as const ;

const OPTIONS_CONFIG: TemplatesType<ListContactsDotsMenuItemTypes> = {
  [LIST_CONTACTS_DOTS_MENU_TYPES.remove]: {
    icon: Close,
    label: 'Remove from List',
    type: LIST_CONTACTS_DOTS_MENU_TYPES.remove,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_CONTACTS_DOTS_MENU_TYPES.delete]: {
    icon: Trash,
    label: 'Delete Contact',
    type: LIST_CONTACTS_DOTS_MENU_TYPES.delete,
    ...DOTS_MENU_DELETE_COLORS,
    topSeparator: true
  },
  [LIST_CONTACTS_DOTS_MENU_TYPES.view]: {
    icon: Edit,
    label: 'View/Edit Contact',
    type: LIST_CONTACTS_DOTS_MENU_TYPES.view,
    ...DOTS_MENU_COMMON_COLORS,
  },
};

export const getDotsMenuOptions =(listType: ListTypes, isContactManagement: boolean) => {
  if(listType === LISTS_TYPES.Dynamic){
    return [
      OPTIONS_CONFIG.VIEW,
      OPTIONS_CONFIG.DELETE,
    ];
  }
  // Options For Manual
  if(isContactManagement){
    return [
      OPTIONS_CONFIG.VIEW,
      OPTIONS_CONFIG.DELETE,
    ];
  } else {
    return [
      OPTIONS_CONFIG.VIEW,
      OPTIONS_CONFIG.REMOVE,
      OPTIONS_CONFIG.DELETE,
    ];
  }
}; 
import { COLORS } from '@constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  width: 125px;
  
  & .MuiInputBase-input::placeholder {
    font-size: 12px;
  }
`;

export const ApplyBtnWrapper = styled.div`
  margin-left: 10px;
`;

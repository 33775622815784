import React, { useCallback } from 'react';

import DotsMenu from '@components/DotsMenu';

import { SALES_ACTIVE_CYCLE_DOTS_MENU_OPTIONS, SALES_ACTIVE_CYCLE_DOTS_MENU_TYPES } from './data';
import { SalesActiveCycleItemDotsMenuTypes, SalesCycleDotsMenuProps } from './types';

export const SalesCycleDotsMenu = ({
  $marginBottom,
  $marginLeft,
  $marginRight,
  $marginTop,
  id,
  onDelete,
  onEdit,
}: SalesCycleDotsMenuProps) => {

  const onMenuSelect = useCallback((action: SalesActiveCycleItemDotsMenuTypes) => {
    if(action === SALES_ACTIVE_CYCLE_DOTS_MENU_TYPES.edit){
      onEdit(id);
    }

    if(action === SALES_ACTIVE_CYCLE_DOTS_MENU_TYPES.remove){
      onDelete(id);
    }
  }, [id, onDelete, onEdit]);

  return (
    <DotsMenu<SalesActiveCycleItemDotsMenuTypes>
      $marginBottom={ $marginBottom }
      $marginLeft={ $marginLeft }
      $marginRight={ $marginRight }
      $marginTop={ $marginTop }
      items={ SALES_ACTIVE_CYCLE_DOTS_MENU_OPTIONS }
      onSelect={ onMenuSelect }
    />
  );
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${ COLORS.dividingLines };
  border-radius: 6px;
  
  &:not(:last-child){
    margin-bottom: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 15px 20px 11px;
  background-color: ${ COLORS.background };
`;

export const Title = styled.div`
  margin-right: auto;
`;

export const FieldsWrapper = styled.div`
  padding: 20px;
`;

export const UseAsTitleWrapper = styled.div`
  margin-right: 20px;
`;

export const CurrentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomRowWrapper = styled.div`
  display: flex;
  padding-left: calc(50% + 52px);
`;
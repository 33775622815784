import {
  AccountSettingsType,
  LoginTabs,
  TimeInSeconds,
} from '@services/store/userSettingsStore/types';
import { CALENDAR_VIEWS } from '@constants/common';
import { FULL_NAME_ORDER } from '@constants/userSettings';

export const getAccountSettingsDefaultValues = (settings: AccountSettingsType) => ({
  timezone: settings.timezone,
  defaultLoginTab: LoginTabs.Crm,
  fullNameOrder: settings.fullNameOrder || FULL_NAME_ORDER.firstName,
  organizationId: settings.organizationId,
  automaticLogout: settings.automaticLogout || TimeInSeconds.Minutes15,
  defaultCalendarView: settings.defaultCalendarView || CALENDAR_VIEWS.month,
  activityDefaultTime: settings.activityDefaultTime || TimeInSeconds.Hour1,
  calendarStartTime: settings.calendarStartTime,
  calendarEndTime: settings.calendarEndTime,
});

import React, { ElementType } from 'react';

import Tooltip from '@components/Tooltip';

import {
  ICONS,
  COLOR_SCHEME_TEMPLATES,
  INFO_COLOR_SCHEME
} from './data';

import { Wrapper } from './styles';

import { InfoIconProps } from './types';

export const InfoIcon = ({
  $tooltipMaxWidth,
  colorScheme = INFO_COLOR_SCHEME.main,
  disabled = false,
  helpText,
  type,
  wrapperProps,
}: InfoIconProps) => {
  const Icon = ICONS[type] as ElementType;

  return (
    <Tooltip
      $maxWidth={ $tooltipMaxWidth }
      disabled={ disabled }
      title={ helpText }
    >
      <Wrapper
        $color={ COLOR_SCHEME_TEMPLATES[colorScheme].common }
        $hoverColor={ COLOR_SCHEME_TEMPLATES[colorScheme].hover }
        disabled={ disabled }
        { ...wrapperProps }
      >
        <Icon />
      </Wrapper>
    </Tooltip>
  );
};

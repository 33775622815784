import * as yup from 'yup';

import { FIELDS_NAMES } from './components/Content/data';
import { validationMessages } from '@constants/validationMessages';
import { phoneValidation } from '@modules/HookFormComponents/HFPhoneField/validation';

export const getValidationSchema = (isEditMode: boolean) => {
  return yup.object().shape({
    [FIELDS_NAMES.title]: yup.string().required(validationMessages.required),
    [FIELDS_NAMES.relationshipType]: yup.string().required(validationMessages.required),
    [FIELDS_NAMES.firstName]: yup.string()
      .test('error', validationMessages.required, function (value) {
        const { employeeId } = this.parent;
        if(value || employeeId || !employeeId === null) {
          return true;
        }
        return false;
      }),
    [FIELDS_NAMES.lastName]: yup.string()
      .test('error', validationMessages.required, function (value) {
        const { employeeId } = this.parent;
        if(value || employeeId || !employeeId === null) {
          return true;
        }
        return false;
      }),
    [FIELDS_NAMES.employeeId]: yup.string()
      .nullable()
      .test('error', validationMessages.required, function (value) {
        const { firstName, lastName } = this.parent;
        const isAtLeastOneFieldFilled = Boolean(firstName?.length || lastName?.length);
        if(value || isAtLeastOneFieldFilled) {
          return true;
        }
        return false;
      }),
    [FIELDS_NAMES.email]: yup.string()
      .nullable()
      .email(validationMessages.invalidEmail),
    [FIELDS_NAMES.phone]: phoneValidation
  });
};

import {
  Calendar,
  CheckCircle,
  MoneyBag,
  Profile,
  Overview,
} from '@/assets';

import { ContactDetailsTabData } from './types';

import { CONTACT_TYPES } from '@constants/common';
import { CONTACT_DETAILS_TAB, ContactDetailsRouteType } from '@constants/routes/contacts';

import { IdType } from '@/shared/types/commonTypes';
import { getContactDetailsLink } from '@/shared/utils/contactRoutesUtils';

const LABELS_MAP = {
  [CONTACT_DETAILS_TAB.accounts]: 'Accounts',
  [CONTACT_DETAILS_TAB.activities]: 'Activities',
  [CONTACT_DETAILS_TAB.overview]: 'Overview',
  [CONTACT_DETAILS_TAB.profile]: 'Profile',
  [CONTACT_DETAILS_TAB.toDos]: 'To-Dos',
};

const generateContactDetailsLinkWrapper = (tab: ContactDetailsRouteType) =>
  (id: IdType, contactType: CONTACT_TYPES) => {
    if(tab === CONTACT_DETAILS_TAB.profile) {

      return getContactDetailsLink(id, contactType)[tab]({ contactType });
    } else {
      return getContactDetailsLink(id, contactType)[tab]();
    }
  };

export const CONTACT_DETAILS_TABS: Array<ContactDetailsTabData> = [
  {
    value: CONTACT_DETAILS_TAB.overview,
    label: LABELS_MAP[CONTACT_DETAILS_TAB.overview],
    icon: Overview,
    getLink: generateContactDetailsLinkWrapper(CONTACT_DETAILS_TAB.overview)
  },
  {
    value: CONTACT_DETAILS_TAB.profile,
    label: LABELS_MAP[CONTACT_DETAILS_TAB.profile],
    icon: Profile,
    getLink: generateContactDetailsLinkWrapper(CONTACT_DETAILS_TAB.profile)
  },
  {
    value: CONTACT_DETAILS_TAB.activities,
    label: LABELS_MAP[CONTACT_DETAILS_TAB.activities],
    icon: Calendar,
    getLink: generateContactDetailsLinkWrapper(CONTACT_DETAILS_TAB.activities)
  },
  {
    value: CONTACT_DETAILS_TAB.toDos,
    label: LABELS_MAP[CONTACT_DETAILS_TAB.toDos],
    icon: CheckCircle,
    getLink: generateContactDetailsLinkWrapper(CONTACT_DETAILS_TAB.toDos)
  },
  {
    value: CONTACT_DETAILS_TAB.accounts,
    label: LABELS_MAP[CONTACT_DETAILS_TAB.accounts],
    icon: MoneyBag,
    getLink: generateContactDetailsLinkWrapper(CONTACT_DETAILS_TAB.accounts)
  },
];

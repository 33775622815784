import styled from 'styled-components';

import { ERROR_SPACE_VALUE } from '@constants/style';
import { InputWrapperProps, ItemWrapperProps, ToolbarActionsWrapperProps } from './types';

export const ToolbarActionsWrapper = styled.div<ToolbarActionsWrapperProps>`
  display: flex;
  flex: auto;
  margin-left: ${({ $isBigLeftMargin }) => $isBigLeftMargin ? '16px' : '15px' };
  align-items: center;
  height: 100%;

  & > * {
    margin-right: 10px;
    
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  visibility: ${({ $preventHidden }) => $preventHidden ? 'visible' : 'hidden'};
  margin-bottom: ${ERROR_SPACE_VALUE}px;
`;

export const Wrapper = styled.div`
  display: flex;

  &:hover {
    &  ${ItemWrapper} {
      visibility: visible !important;
    }
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  width: ${({ $maxWidth }) => $maxWidth}px;
`;

export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    margin-left: 10px;
`;

export const HouseholdWrapper = styled.div`
  width: 340px;
  margin: 0 16px 0 1px;

`;

export const AuxiliaryHiddenBlock = styled.div`
  flex: 1;
`;
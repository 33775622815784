import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import {
  BriefInfo,
  Status,
  Rules,
  Source,
  ContactDetails,
  ServicingDetails,
  TabContentWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { BusinessContactDetails } from './components';

import {
  BUSINESS_CONFIG
  // eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/ContactDetails/data';
import {
  COMMON_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';
import { ImageUploaderCategories } from '@constants/common';

import { getContactName } from '@/shared/utils/getContactName';

export const BusinessDetails = observer(() => {
  const { optionsForBusiness, contact } = useStore().ContactDetailsProfileStore;
  const contactTitle: string = useMemo(() => {
    if(!contact) {
      return '';
    }

    return `${getContactName(contact)} (Business)`;
  }, [contact]);

  if(!contact) {
    return null;
  }

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item $noBottomPadding={ true }>
        <BriefInfo
          briefDescriptionName={ COMMON_FIELDS.briefDescription }
          contactTitle={ contactTitle }
          headshotBackgroundType={ ImageUploaderCategories.BusinessContact }
          photoName={ COMMON_FIELDS.photo }
        />
        <BusinessContactDetails
          companyOptions={ optionsForBusiness?.companyType || [] }
          employeeOptions={ optionsForBusiness?.employees || [] }
        />
        <ContactDetails
          config={ BUSINESS_CONFIG }
          name={ COMMON_FIELDS.contacts }
          title={ 'Primary Business Details' }
        />
        <Status
          classificationName={ COMMON_FIELDS.classification }
          contactType={ contact.type }
          ratingName={ COMMON_FIELDS.rating }
          statusName={ COMMON_FIELDS.status }
        />
        <Rules/>
        <Source
          sourceCategoryName={ COMMON_FIELDS.sourceCategory }
          sourceCategoryOptions={ optionsForBusiness?.sourceCategory || [] }
          sourceName={ COMMON_FIELDS.sourceId }
          sourceOptions={ optionsForBusiness?.source || [] }
        />
        <ServicingDetails
          clientCreationDateName={ COMMON_FIELDS.clientCreationDate }
          officeLocationName={ COMMON_FIELDS.officeLocation }
          primaryAdviserName={ COMMON_FIELDS.officePrimaryAdvisor }
          primaryCsrName={ COMMON_FIELDS.officePrimaryCsr }
          secondaryAdviserName={ COMMON_FIELDS.officeSecondaryAdvisor }
          secondaryCsrName={ COMMON_FIELDS.officeSecondaryCsr }
        />
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

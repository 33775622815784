import { ColumnType } from '@components/Table/components/Header/types';

import {
  getColumnCommonFields
} from '@modules/AccountsAndPlansPopup/components/Content/utils';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'name',
    label: 'Name',
    ...getColumnCommonFields('240px')
  },
  {
    id: 'relation',
    label: 'Relation',
    ...getColumnCommonFields('140px')
  },
  {
    id: 'type',
    label: 'Type',
    ...getColumnCommonFields('110px')
  },
  {
    id: 'percent',
    label: '%',
    ...getColumnCommonFields('170px')
  }
];

export const BENEFICIARIES_RELATION_TYPES = {
  child: 'Child',
  parent: 'Parent',
  grandchild: 'Grandchild',
  grandparent: 'Grandparent',
  sibling: 'Sibling',
  other: 'Other',
  spouse: 'Spouse',
  exSpouse: 'Ex-Spouse',
  trust: 'Trust',
};

export const BENEFICIARIES_TYPES = {
  primary: 'Primary',
  contingent: 'Contingent',
};

export const BENEFICIARIES_TYPES_OPTIONS =  optionsFromValue(Object.values(BENEFICIARIES_TYPES));
export const BENEFICIARIES_RELATION_TYPES_OPTIONS =  optionsFromValue(Object.values(BENEFICIARIES_RELATION_TYPES));
import React from 'react';

import { useToggle } from '@customHooks';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import {
  AdornmentWrapper,
  Arrow,
  DayTime,
  StyledFormHelperText,
} from './styles';

const normalizeInput = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d)/, '$1')
    .replace(/^(\d{2})(\d)/, '$1:$2')
    .replace(/(:\d{2})\d+?$/, '$1');
};

export const TimePickerInput = ({
  isOpen,
  onArrowClick,
  setTime,
  time,
  meridian,
  errorMessage,
  disabled,
}) => {
  const [ isFocused, toggleFocused ] = useToggle();
  const isError = Boolean(errorMessage);

  const onChange = (e) => {
    const { target: { value } } = e;
    setTime(normalizeInput(value));
  };

  const arrowClickHandler = (event) => {
    if(disabled) {
      return;
    }

    onArrowClick(event);
  };

  return (
    <FormControl
      disabled={ disabled }
      error={ isError }
      focused={ isOpen || isFocused }
    >
      <OutlinedInput
        endAdornment={
          <AdornmentWrapper onClick={ arrowClickHandler }>
            <DayTime>
              { meridian || ''}
            </DayTime>
            <Arrow
              $disabled={ disabled }
              $isOpen={ isOpen }
            />
          </AdornmentWrapper>
        }
        inputProps={ {
          placeholder: '00:00 AM'
        } }
        onBlur={ toggleFocused }
        onChange={ onChange }
        onFocus={ toggleFocused }
        value={ time }
      />
      {
        isError && <StyledFormHelperText error={ isError }>{ errorMessage }</StyledFormHelperText>
      }
    </FormControl>
  );
};

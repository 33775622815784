import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import FormControlLabel from '@material-ui/core/FormControlLabel';

export const Label = styled(FormControlLabel)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;

    & .MuiFormControlLabel-label{
        font-size: 14px;
        line-height: 1;
        color: ${ COLORS.primaryText };
    }

    & .MuiRadio-root{
        padding: 0;
        margin-right: 8px;
    }

    &:last-child {
        margin-right: 0px;
    }
`;

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { useStore } from '@store';

import { getAbbr } from '@/shared/utils/getAbbr';
import { openDetectLinkTransitionPopup } from '@/shared/utils/modalHelpers';

import Avatar from '@components/Avatar';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';

import { OffSymbol } from '@/assets';

import { Header, MenuItem } from './components';

import { CSS_VARIABLE_NAMES, SIDEMENU_WIDTHES } from '@constants/style';
import { ROUTES } from '@constants/routes';
import { USER_SETTINGS_COMMON_PATH } from '@constants/routes/userSettings';
import { MENU_POINTS } from './data';

import {
  HeaderWrapper,
  SideMenuItems,
  SideMenuBottomItems,
  Wrapper,
} from './styles';

export const SideMenu = observer(() => {
  const authStore = useStore().AuthStore;
  const { isMenuOpen, toggleMenuState } = useStore().SettingsStore;
  const { isTransitionsBlocked, setTransitionBlockState } = useStore().RouterStore;
  const { user } = authStore;

  const routerStore = useStore().RouterStore;
  const { pathname }  = useLocation();


  useLayoutEffect(() => {
    window.document.documentElement.style.setProperty(
      CSS_VARIABLE_NAMES.menuWidth,
      isMenuOpen ? SIDEMENU_WIDTHES.open: SIDEMENU_WIDTHES.closed
    );
  },[isMenuOpen]);

  const renderAvatar = useCallback(() => {
    return (
      <Avatar
        $withFrame={ true }
        mainImageData={ {
          //TODO delete this condition after fix problem with routing
          abbr: user ? getAbbr(user.firstName, user.lastName) : '',
          src: user?.headshot || null,
        } }
      />
    );
  }, [user]);

  const renderUserName = useCallback(() => {
    const fullName = `${user?.firstName} ${user?.lastName}`;
    return (
      <Tooltip
        displayOnlyOnOverflow={ true }
        title={ fullName }
      >
        <TextEllipsis
          $maxWidth={ 180 }
        >
          { fullName }
        </TextEllipsis>
      </Tooltip>
    );
  }, [user]);

  const onLogoutClick = useCallback(() => {
    const logoutWrapper  = async () => {
      try {
        await authStore.workerLogout();
      } catch (error) {
        console.log(error);
      } finally {
        setTransitionBlockState(false);
        routerStore.customPush(ROUTES.login);
      }
    };

    if(isTransitionsBlocked){
      openDetectLinkTransitionPopup({
        onConfirm: logoutWrapper,
        onCancel: () => {}
      });
    } else {
      logoutWrapper();
    }

  },[authStore, isTransitionsBlocked, routerStore]);

  return (
    <Wrapper $isOpen={ isMenuOpen }>
      <HeaderWrapper $isOpen={ isMenuOpen }>
        <Header $isOpen={ isMenuOpen } toggleIsOpenWrapper={ toggleMenuState } />
      </HeaderWrapper>
      <SideMenuItems>
        {MENU_POINTS.map((item) => (
          <MenuItem
            $isOpen={ isMenuOpen }
            asLink={ true }
            currentPath={ pathname }
            icon={ item?.icon }
            key={ item.link }
            link={ item.link }
            rootLink={ item.rootLink }
            submenu={ item?.submenu }
            title={ item.title }
          />
        ))}
      </SideMenuItems>

      <SideMenuBottomItems>
        <MenuItem
          $isOpen={ isMenuOpen }
          $marginLeft={ '10px' }
          $padding={ '10px 23px 10px 15px' }
          asLink={ true }
          currentPath={ pathname }
          icon={ renderAvatar }
          link={ ROUTES.userSettingsAccountSettings  }
          rootLink={ USER_SETTINGS_COMMON_PATH }
          title={ renderUserName }
        />
        <MenuItem
          $isOpen={ isMenuOpen }
          asLink={ false }
          icon={ OffSymbol }
          onClick={ onLogoutClick }
          title={ 'Logout' }
        />
      </SideMenuBottomItems>
    </Wrapper>
  );
});

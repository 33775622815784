import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import FormBlock from '@components/FormBlock';
import SelectItemWithCheckbox from '@components/SelectItemWithCheckbox';
import UserItem from '@components/NewUserItem';

import { getActiveClientByName } from '@services/api/common/common';
import { getErrorMessage } from '@/shared/utils/useFormUtils';
import { getNameParam, usersResponseConverter, } from '@/shared/utils/fetchAutocompleteUtils';

import { ActivityPopupFieldsName, LABEL_WIDTH } from '@modules/ActivityPopup/data';

import { UserItemWrapper, UserList, UsersWrapper, } from './styles';

import { ClientConverter } from '@/shared/types/activityPopup';
import { IdType } from '@/shared/types/commonTypes';
import { UsersProps } from './types';

export const Users = ({
  initUsersData,
  setActivityUsers,
}: UsersProps) => {

  const { control, clearErrors, formState, setValue } = useFormContext();

  const [ usersToRender, setUsersToRender] = useState(initUsersData || []);
  const { fields, append, remove } = useFieldArray({
    control,
    name: ActivityPopupFieldsName.clients
  });

  const listboxRef = useRef<HTMLDivElement | null>(null);

  const onClose = useCallback(() => {
    if (listboxRef.current) {
      listboxRef.current.scrollTop = 0;
    }
  }, []);

  const errorMessage = getErrorMessage(formState)(ActivityPopupFieldsName.clients);
  const primaryClientIdValue = useWatch({ control, name: ActivityPopupFieldsName.primaryClientId });

  const deleteItem = (index: number, id: IdType) => {
    remove(index);

    if(primaryClientIdValue === id){
      const withoutDeleted = usersToRender.filter((item) => item.id !== id);
      const newPrimaryId = withoutDeleted?.[0]?.id || '';
      setValue(ActivityPopupFieldsName.primaryClientId, newPrimaryId);
    }

    setUsersToRender((prev) => prev.filter((item) => item.id !== id));
  };

  const onAutoCompleteItemClick = useCallback((data: ClientConverter) => () => {
    const selectedIndex = fields.findIndex((item: any) => item.userId === data.id);
    if(selectedIndex >= 0) {
      return deleteItem(selectedIndex, data.id);
    }
    if(fields.length === 0) {
      setValue(ActivityPopupFieldsName.primaryClientId, data.id);
    }
    append({ userId: data.id });
    setUsersToRender(((prev) => [...prev, data]));

    if(errorMessage) {
      clearErrors(ActivityPopupFieldsName.clients);
    }
  },[fields, usersToRender]);

  const onStar = useCallback((data: ClientConverter) => () => {
    setValue(ActivityPopupFieldsName.primaryClientId, data.id);
  },[]);

  const onDelete = useCallback((index: number, id: IdType) => () => {
    deleteItem(index, id);
  },[usersToRender, primaryClientIdValue]);

  useEffect(() => {
    setActivityUsers(usersToRender);
  }, [usersToRender]);

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.RowWrapper>
        <ControlledHiddenInput
          control={ control }
          defaultValue={ '' }
          name={ ActivityPopupFieldsName.primaryClientId }
        />
        <UsersWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH.common }
            isRequired={ true }
            label={ 'Users' }
          >
            <FetchAutoComplete<ClientConverter>
              AutocompleteProps={ {
                disableCloseOnSelect: true,
                onClose: onClose,
                ListboxProps: {
                  ref: listboxRef,
                  style: {
                    maxHeight: '200px'
                  }
                },
                getOptionLabel: (option: any) => option.fullName || 'label',
                getOptionSelected: (option: any) => {
                  return usersToRender[usersToRender.length - 1]?.firstName === option.firstName;
                },
                renderOption: (option: any) => {
                  const index = usersToRender?.findIndex((item: ClientConverter) => item.id === option.id);
                  return (
                    <SelectItemWithCheckbox
                      checked={ index >= 0 }
                      onClick={ onAutoCompleteItemClick(option) }
                    >
                      <UserItem
                        labelWidth={ 500 }
                        user={ option }
                      />
                    </SelectItemWithCheckbox>
                  );
                },
              } }
              FetchProps={ {
                getParams: getNameParam,
                requestSender: getActiveClientByName,
                responseConverter: usersResponseConverter,
              } }
              TextFieldProps={ {
                error: Boolean(errorMessage),
                helperText: errorMessage || ''
              } }
              withoutQueryMode={ true }
            />

            <UserList>
              {
                fields.map((field: any, index: number) => {
                  const userRenderData = usersToRender.find((user: ClientConverter) => user.id === field.userId);
                  if(userRenderData) {
                    return (
                      <Fragment key={ field.id }>
                        <ControlledHiddenInput
                          control={ control }
                          defaultValue={ field.userId }
                          name={ `${ActivityPopupFieldsName.clients}.${index}.userId` }
                        />
                        <UserItemWrapper>
                          <UserItem
                            isStarActive={ primaryClientIdValue === userRenderData?.id }
                            onClose={ onDelete(index, userRenderData?.id) }
                            onStar={ onStar(userRenderData) }
                            user={ userRenderData }
                          />
                        </UserItemWrapper>
                      </Fragment>
                    );
                  }
                }
                )}
            </UserList>
          </FormBlock.FormField>
        </UsersWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

import { RestMenuProps, SelectMenuListProps } from './types';
import { omit } from 'lodash';

export const getMenuProps = (
  MenuListProps: SelectMenuListProps | undefined,
  MenuProps: RestMenuProps | undefined,
  menuListWidth: null | number,
): RestMenuProps => {
  const menuListProps = MenuListProps || {};
  const menuProps = MenuProps || {};

  return {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    MenuListProps: {
      style: {
        marginTop: 3,
        padding: 0,
        maxHeight: menuListProps?.style?.maxHeight || 363,
        overflowY: 'auto',
        ...(menuListWidth? { maxWidth: menuListWidth } : {}),
      },
      ...omit(menuListProps, 'style'),
    },
    ...menuProps,
  };
};



import React, { useLayoutEffect } from 'react';
import { useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HousehooldHead from '@modules/AddContactModal/components/FormBlocks/HouseholdHead';
import {
  LeftColumnWrapperVariantThree,
  RightColumnWrapperVariantThree,
} from '@modules/AddContactModal/components/Columns';


import { maxInputLength } from '@/shared/utils/maxInputLength';

import { LEFT_COLUMN_WIDTH_V_THREE, RIGHT_COLUMN_WIDTH_V_THREE } from '@modules/AddContactModal/data';


function CreateMembers({ properties }) {
  const { control, formState, options, setValue } = properties;

  const [firstName, lastName, spouseFirstName, spouseLastName, communicateWithValue] = useWatch({
    control,
    name: ['1.main.firstName', '1.main.lastName', '1.spouse.firstName', '1.spouse.lastName', '2.communicateWith']
  });

  useLayoutEffect(() => {
    if(!firstName || !lastName || !spouseFirstName || !spouseLastName){
      if(communicateWithValue){
        setValue('2.communicateWith', 'Both');
      }
    }
  }, [communicateWithValue, firstName, lastName, setValue, spouseFirstName, spouseLastName]);

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Create Members' }/>
      <FormBlock.RowWrapper>
        <HousehooldHead
          control={ control }
          firstName='1.main.householdOwner'
          secondName='1.spouse.householdOwner'
          setValue={ setValue }
        />
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
            isRequired={ true }
            label={ 'First Name' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.main.firstName'
              placeholder='Enter First Name'
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantThree>
        <RightColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
            isRequired={ true }
            label={ 'Spouse First Name' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.spouse.firstName'
              placeholder='Enter First Name '
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantThree>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
            isRequired={ true }
            label={ 'Last Name' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.main.lastName'
              placeholder='Enter Last Name'
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantThree>
        <RightColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
            isRequired={ true }
            label={ 'Spouse Last Name' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.spouse.lastName'
              placeholder='Enter Last Name '
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantThree>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
            label={ 'Nickname' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.main.nickname'
              placeholder='Enter Nickname'
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantThree>
        <RightColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
            label={ 'Spouse Nickname' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.spouse.nickname'
              placeholder='Enter Nickname'
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantThree>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
            label={ 'Birthday' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              name='1.main.birthdate'
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantThree>
        <RightColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
            label={ 'Spouse Birthday' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              name='1.spouse.birthdate'
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantThree>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
            label={ 'Gender' }
          >
            <HFSelect
              control={ control }
              name='1.main.gender'
              options={ options.gender }
              placeholder='Select Gender'
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantThree>
        <RightColumnWrapperVariantThree>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
            label={ 'Spouse Gender' }
          >
            <HFSelect
              control={ control }
              name='1.spouse.gender'
              options={ options.gender }
              placeholder='Select Gender'
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantThree>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}

export default CreateMembers;

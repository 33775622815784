import React, { useMemo } from 'react';

import { EmailStatuses } from '@constants/common';

import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import StatusChip, { STATUS_TEMPLATES } from '@components/StatusChip';

import { VerifiedEmailButtonProps } from './types';

const STYLE = {
  width: 76,
  height: 30,
  padding: 0
};

export const VerifiedEmailButton = ({
  emailStatus,
  onClick,
  disabled = false,
}: VerifiedEmailButtonProps) => {

  const getStatusLabel = useMemo(() => {
    switch(emailStatus) {
    case EmailStatuses.Pending:
      return 'Send Again';
    case EmailStatuses.Unverified:
      return 'Verify';
    case EmailStatuses.Verified:
      return 'Verified';
    default: {
      const unexpected: never = emailStatus;
      throw new TypeError(`Unexpected status: ${unexpected}`);
    }
    }
  }, [ emailStatus ]);

  const Icon = useMemo(() => STATUS_TEMPLATES.verified.icon, []);

  return (
    <>
      {
        emailStatus === EmailStatuses.Verified &&
        <StatusChip
          $bgColor={ STATUS_TEMPLATES.verified.colorScheme.bgColor }
          $color={ STATUS_TEMPLATES.verified.colorScheme.color }
          icon={ <Icon /> }
          label={ getStatusLabel }
        />
      }

      {
        emailStatus !== EmailStatuses.Verified &&
          <BlockButton
            colorSchema={ COLOR_SCHEME_TYPES.outlined }
            disabled={ disabled }
            onClick={ onClick }
            style={ STYLE }
            variant='outlined'
          >
            { getStatusLabel }
          </BlockButton>
      }
    </>
  );
};

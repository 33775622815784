import { COLORS } from '@constants/colors';
import {
  AddNote,
  Clock,
  Close,
  Cross,
  DoneMarkWithCircle,
  Edit,
  FilledStar,
  Plus,
  Star,
} from '@/assets';
import { TemplatesType } from '@components/CustomIconButton/types';
export enum SIZES {
  exSm = 'exSm',
  small = 'small',
  medium = 'medium',
}

export const ELEM_SIZES = {
  [SIZES.exSm]: {
    iconWrapper: '12px',
    substrate: '32px'
  },
  [SIZES.small]: {
    iconWrapper: '18px',
    substrate: '30px'
  },
  [SIZES.medium]: {
    iconWrapper: '22px',
    substrate: '34px'
  }
};

const COMMON_COLOR_SCHEME = {
  iconColor: COLORS.primaryText,
  iconHoverColor: COLORS.orange
};

const ORANGE_COLOR_SCHEME = {
  iconColor: COLORS.orange,
  iconHoverColor: COLORS.orange
};

const RED_COLOR_SCHEME = {
  iconColor: COLORS.redLight,
  iconHoverColor: COLORS.red
};

const PINK_SUBSTRATE = {
  substrateBgColor: COLORS.select,
};

const RED_SUBSTRATE = {
  substrateBgColor: COLORS.redBg,
};

export enum ICON_BUTTON_TYPES  {
  clock = 'clock',
  // eslint-disable-next-line no-restricted-globals
  close = 'close',
  cross = 'cross',
  done = 'done',
  edit = 'edit',
  note = 'note',
  plus = 'plus',
  star = 'star',
}

export const TEMPLATES: TemplatesType = {
  [ICON_BUTTON_TYPES.clock]: {
    icon: Clock,
    ...COMMON_COLOR_SCHEME,
  },
  [ICON_BUTTON_TYPES.close]: {
    icon: Close,
    ...RED_COLOR_SCHEME,
    ...RED_SUBSTRATE,
  },
  [ICON_BUTTON_TYPES.cross]: {
    icon: Cross,
    iconSize: '12px',
    ...COMMON_COLOR_SCHEME,
    ...PINK_SUBSTRATE,
  },
  [ICON_BUTTON_TYPES.done]: {
    icon: DoneMarkWithCircle,
    ...ORANGE_COLOR_SCHEME,
    ...PINK_SUBSTRATE,
  },
  [ICON_BUTTON_TYPES.edit]: {
    icon: Edit,
    ...ORANGE_COLOR_SCHEME,
    ...PINK_SUBSTRATE,
  },
  [ICON_BUTTON_TYPES.note]: {
    icon: AddNote,
    ...ORANGE_COLOR_SCHEME,
    ...PINK_SUBSTRATE,
  },
  [ICON_BUTTON_TYPES.plus]: {
    icon: Plus,
    ...COMMON_COLOR_SCHEME,
  },
  [ICON_BUTTON_TYPES.star]: {
    icon: Star,
    activeIcon: FilledStar,
    iconActiveColor: COLORS.orange,
    iconActiveHoverColor: COLORS.orange,
    substrateBgColor: COLORS.select,
    ...ORANGE_COLOR_SCHEME,
  },
};

import { IdType } from '@/shared/types/commonTypes';

export type PageHeaderProps = {
  isFiltersBtnActive: boolean;
  onFiltersClick: () => void;
};

export type ModalProps = {
  arrayOfIds: Array<IdType>;
};

export type PopupProps = {
  closeModal: () => void;
  modalProps: ModalProps;
};

export type ActionButtonsProps = {
  onPermanentlyDeleteClick: () => void;
  onRestoreClick: () => void;
  selectedItemsCount: number;
};

export enum TrashTabValues {
  AccountsAndPlans = 'accounts-and-plans',
  Activities = 'activities',
  ClientOpportunities = 'client-opportunities',
  Contacts = 'contacts',
  Lists = 'lists',
  SalesCycles = 'sales-cycles',
  Tasks = 'tasks',
}

export enum TrashFiltersNames {
  Account_Name = 'name',
  Account_Type = 'accountType',
  Activity_Name = 'name',
  Assigned_To = 'assignedTo',
  Category = 'category',
  Clients = 'clients',
  Classification = 'classification',
  End_Date = 'endDate',
  List_Name = 'fullName',
  List_Type = 'typeList',
  Meeting_Status = 'meetingStatus',
  Name = 'contactName',
  Opportunities_Name = 'fullName',
  Opportunity_Status = 'opportunityStatus',
  Sales_Cycle_Status = 'salesCycleStatus',
  Sales_Cycles_Name = 'fullName',
  Sale_Type = 'saleType',
  Start_Date = 'startDate',
  Task_Name = 'name',
  Type = 'type',
  Users = 'user',
}

export const TrashSearchFiltersNames = {
  [TrashTabValues.AccountsAndPlans]: TrashFiltersNames.Account_Name,
  [TrashTabValues.Activities]: TrashFiltersNames.Activity_Name,
  [TrashTabValues.ClientOpportunities]: TrashFiltersNames.Opportunities_Name,
  [TrashTabValues.Contacts]: TrashFiltersNames.Name,
  [TrashTabValues.Lists]: TrashFiltersNames.List_Name,  
  [TrashTabValues.SalesCycles]: TrashFiltersNames.Sales_Cycles_Name,
  [TrashTabValues.Tasks]: TrashFiltersNames.Task_Name,
};

import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStore } from '@store';
import { NotificationBar } from '@components/NotificationBar';

import { LIST_CRITERIA_ROOT_NAME } from '@constants/lists';

import { CriteriaFormWrapper } from '@/shared/types/lists';

export const FormWatcher = observer(() => {
  const listCriteriaStore = useStore().ListsStore.ListCriteriaStore;
  const { isShowSaveBar, onSave, onCancel, toggleSaveBar, isLoad } = listCriteriaStore;
  const { formState, control,handleSubmit } = useFormContext<CriteriaFormWrapper>();

  const allFields = useWatch({
    control,
    name: LIST_CRITERIA_ROOT_NAME
  });


  useEffect(() => {
    if(!isLoad && Object.keys(formState.dirtyFields).length > 0) {

      toggleSaveBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allFields, formState]);


  if(isShowSaveBar) {
    return (
      <NotificationBar
        barText='You need to save the information'
        isOpen={ isShowSaveBar }
        onCancelButtonClick={ onCancel }
        onConfirmButtonClick={ onSave }
      />
    );
  }

  return null;
});

import React from 'react';

import { Pagination } from './components/Pagination';


import { Wrapper } from './styles';

import { FooterProps } from './types';

export const Footer = ({
  PaginationProps,
  children,
  currentPage,
  onPageChangeCb,
  pagesCount,
}: FooterProps) => {
  return (
    <Wrapper>
      { children }
      <Pagination
        count={ pagesCount }
        onHandleChange={ onPageChangeCb }
        page={ currentPage }
        { ...PaginationProps }
      />
    </Wrapper>
  );
};

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
import { hexToRgba } from '@/shared/utils/hexToRgba';

import { RowProps } from '../../types';

export const Row = styled.div<RowProps>`
  position: relative;
  
  text-align: center;
  padding: 15px 20px;
  color: currentColor;
  
  ${({ $isBorderTop = true }) => $isBorderTop && css`
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 20px;
      right: 20px;
      
      height: 1px;

      background-color: ${hexToRgba(COLORS.notificationWithAction, 0.1)};
    }
  `}
`;

import React  from 'react';

import TypeButton from '@components/TypeButton';

import { Wrapper } from './styles';

import { PopupButtonPanelItemsProps } from './types';

export const PopupButtonPanelItems = ({
  currentActionType,
  ButtonProps,
  onClick,
  options,
  wrapperStyles = {},
  elemMarginRight = '10px',
}: PopupButtonPanelItemsProps) => (
  <Wrapper
    $elemMarginRight={ elemMarginRight }
    style={ wrapperStyles }
  >
    {
      options.map(({ value, label }) => (
        <TypeButton
          BtnProps={ ButtonProps }
          isActive={ currentActionType === value }
          key={ value }
          onClick={ onClick }
          value={ value }
        >
          { label }
        </TypeButton>
      ))
    }
  </Wrapper>
);
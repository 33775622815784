import { PopoverOrigin } from '@material-ui/core';

export const transformOriginProps:PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
export const anchorOriginProps:PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export const paperProps =  {
  style: {
    marginTop: '5px',
    maxHeight: '300px'
  }
};
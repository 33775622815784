import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { getFilteredRoleOptions } from '@pages/NewUserSettings/utils';
import { useStore } from '@services/store/store';

import { LeftColumnWrapper, RightColumnWrapper } from '@pages/NewUserSettings/components/Layout';
import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';

import { FORM_BLOCK_MARGIN_BOTTOM, LABELS_WIDTH, USER_STATUS_OPTIONS } from '@pages/NewUserSettings/data';
import { Roles } from '@constants/common';
import { USER_STATUSES_COLOR_MAP } from '@pages/NewUserSettings/components/Tabs/Users/components/UsersRow/data';

import { UserRoleAndStatusProps } from './types';
import { ValueLabelObj } from '@/shared/types/commonTypes';

import { HelperTextWrapper } from '@pages/NewUserSettings/components/styles';

export const UserRoleAndStatus = observer(({
  control,
  inputNames,
}: UserRoleAndStatusProps) => {

  const { profile } = useStore().UserSettingsStore;
  
  const rolesOptions = useMemo(() => {
    const role = profile?.userRole;
    if(role && (role === Roles.Master || role === Roles.Owner)){
      return getFilteredRoleOptions();
    }
    return getFilteredRoleOptions([Roles.Master, Roles.Owner]);
    //eslint-disable-next-line
  }, []);

  const getHelperText = useMemo(() => {
    switch(profile?.userRole) {
    case Roles.Limited: 
      return 'User can only view the last four digits of contact social security numbers.';
    case Roles.Standart: 
      return 'User can view and edit contact social security numbers.';
    default: 
      return '';
    }
  }, [ profile?.userRole ]);

  return (
    <FormBlock marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileLeftLabelWidth }
            label={ 'User Role' }
          >
            <HFSelect
              control={ control }
              disabled={ profile?.userRole === Roles.Master || profile?.userRole === Roles.Owner }
              name={ inputNames.userRole }
              options={ rolesOptions }
            />
            <HelperTextWrapper>
              { getHelperText }
            </HelperTextWrapper>
          </FormBlock.FormField> 
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.profileRightLabelWidth }
            label={ 'User Status' }
          >
            <HFSelect
              control={ control }
              name={ inputNames.status }
              options={ USER_STATUS_OPTIONS }
              renderItem={ (option) => {
                const { value, label } = option as ValueLabelObj;
                return (
                  <ListItemWithColorIndicator
                    indicatorColor={ USER_STATUSES_COLOR_MAP[value] }
                    label={ label }
                  />
                );
              } }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>

    </FormBlock>
  );
});
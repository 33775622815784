import { omitBy } from 'lodash';
import { ROOT_FIELD_NAMES, FIELD_NAMES_TO_VALIDATE, FIELD_NAMES } from './data';
import { Fields, HouseholdMembers, HouseholdMembersItem, KeyChunks, PathToNestedFields } from './types';
import { SplitItem } from '@services/store/contactDetailsProfileStore/types/common';

export const getFieldName = (index: number, chunk: KeyChunks): PathToNestedFields => (
  `${ROOT_FIELD_NAMES.householderContacts}.${index}.${chunk}`
);

export const getHouseholderContactsDefaultState = (householdMembers: HouseholdMembers):Array<HouseholdMembersItem> => {
  return householdMembers.map(householdMembersItem => {
    return {
      id: householdMembersItem.id,
      sourceId: householdMembersItem?.sourceId || '',
      sourceCategory: householdMembersItem?.sourceCategory || '' ,
      nickname: householdMembersItem?.nickname || '' ,
      birthdate: householdMembersItem?.birthdate || '',
      firstName: householdMembersItem.firstName,
      gender: householdMembersItem?.gender || null,
      rating: householdMembersItem?.rating || 0,
      lastName: householdMembersItem.lastName,
      classification: householdMembersItem.classification,
      status: householdMembersItem.status,
      officePrimaryAdvisor: householdMembersItem.officePrimaryAdvisor
    };
  });
};

export const getFieldsToValidate = (index: number): Array<PathToNestedFields> => (
  FIELD_NAMES_TO_VALIDATE.map(field => getFieldName(index, field))
);

export const sanitizeData = (data:Fields): Fields => {
  const sanitizedHouseHolders = data.householderContacts.map((item) => {
    return omitBy<{} & SplitItem>(item, (value, key) => {
      if(key === FIELD_NAMES.rating){
        return false;
      }
      return !value;
    });
  });

  return {
    createSpouse: data.createSpouse,
    householderContacts: sanitizedHouseHolders as Array<SplitItem>,
  };
};
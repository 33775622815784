import React, { useCallback, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { ReactCropperElement } from 'react-cropper';

import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import { SettingsButtons } from '@components/UploadImagePopup/components/SettingsButtons';

import {
  ButtonsContainer,
  CropperWrapper,
  DialogWrapper,
  ImageContainer,
  ImageWrapper,
} from './styles';

import { UploadImagePopupProps } from './types';


export const UploadImagePopup = ({
  img,
  isOpen,
  onClose,
  successCallback,
}: UploadImagePopupProps) => {

  const cropperRef = useRef<ReactCropperElement>(null);

  const [croppedImg, setCroppedImg] = useState('');
  const [cropMode, setCropMode] = useState(true);

  const onAfterCloseHandler = useCallback(() => {
    setCroppedImg('');
  }, []);

  const getCroppedImgUrl = useCallback(() => {
    const imageElement = cropperRef.current;
    if(imageElement) {
      const { width, height } = imageElement.cropper.getContainerData();
      return cropperRef.current.cropper.getCroppedCanvas({ width, height }).toDataURL();
    }
  }, [ cropperRef ]);

  const onConfirmClick = useCallback(async () => {
    const image = cropMode ? getCroppedImgUrl() : img;
    if(image) {
      await successCallback(image);
      onAfterCloseHandler();
      setCropMode(true);
    }
  }, [ cropMode, img ]);

  const onCancelClick = useCallback(() => {
    onClose();
    onAfterCloseHandler();
    setCropMode(true);
  }, []);

  const readyCropper = useCallback(() => {
    setCropMode(true);
  }, [ setCropMode ]);

  return (
    isOpen ?
      <DialogWrapper open>
        <ImageContainer>
          { !cropMode &&
            <ImageWrapper src={ croppedImg || img as string }/>
          }

          { cropMode &&
          <CropperWrapper
            autoCropArea={ 1 }
            guides={ false }
            initialAspectRatio={ 1 }
            ready={ readyCropper }
            ref={ cropperRef }
            rotatable={ true }
            src={ croppedImg || img as string }
            viewMode={ 1 }
          />
          }
        </ImageContainer>

        <SettingsButtons
          cropMode={ cropMode }
          cropperRef={ cropperRef }
          getCroppedImgUrl={ getCroppedImgUrl }
          setCropMode={ setCropMode }
          setCroppedImg={ setCroppedImg }
        />

        <ButtonsContainer>
          <BlockButton
            colorSchema={ COLOR_SCHEME_TYPES.outlined }
            onClick={ onCancelClick }
            variant='outlined'
          >
            Cancel
          </BlockButton>

          <BlockButton
            colorSchema={ COLOR_SCHEME_TYPES.contained }
            onClick={ onConfirmClick }
            variant='contained'
          >
            Upload
          </BlockButton>
        </ButtonsContainer>
      </DialogWrapper> : null
  );
};

import ApiService from '@services/api/api.service';

import { ConvertActivityData } from '@/shared/types/activityPopup';
import { DeleteActivityData } from '@/modules/ActivityPopup/store/types';
import { IdType } from '@/shared/types/commonTypes';

export const getActivityCreationSettings = () => {
  return ApiService.get('/api/settings/activity/settings');
};

export const getActivityById = (params: { id: IdType, forDay?: string }) => {
  return ApiService.get('/api/activity/getById', { params });
};

export const saveActivity = (params: ConvertActivityData) => {
  return ApiService.post('/api/activity/create', params);
};

export const updateActivity = (params: ConvertActivityData) => {
  return ApiService.patch('/api/activity/update', params);
};

export const deleteActivityToTrash = (params: DeleteActivityData) => {
  return ApiService.delete('/api/activity/delete', { params });
};

import React, { useMemo } from 'react';
import { format, isValid, parse, isPast } from 'date-fns';

import { Wrapper } from './styles';

import { MONTH_DAY_YEAR_BACKSLASH, YEAR_MONTH_DAY } from '@constants/dateFormats';

import { ProcessedDataType, NextApptDateProps } from './types';


export const NextApptDate = ({
  date,
  disabled,
  fallBack,
}: NextApptDateProps) => {
  const { isDatePast, convertedData } = useMemo<ProcessedDataType>((): ProcessedDataType => {
    if(!date){
      return {
        isDatePast: false,
        convertedData: date || null
      };
    }

    const parsed = parse(date, YEAR_MONTH_DAY, new Date());
    const isDateValid = isValid(parsed);

    return {
      isDatePast: isDateValid ? isPast(parsed) : false,
      convertedData: isDateValid ? format(parsed, MONTH_DAY_YEAR_BACKSLASH) : null
    };
  }, [date]);

  if(!date && fallBack){
    return <span>{fallBack}</span>;
  }

  if(!date) {
    return null;
  }

  return (
    <Wrapper
      $disabled={ disabled }
      $isPast={ isDatePast }
    >
      {convertedData}
    </Wrapper>
  );
};

import { ValueLabelObj } from '@/shared/types/commonTypes';
import { EVENT_TYPES_BACKEND } from '@constants/colorsByEventTypes';


export const SOURCE_OPTIONS: Array<ValueLabelObj> = [
  {
    value: 'Client Event',
    label: 'Client Event',
  },
  {
    value: 'Course',
    label: 'Course',
  },
  {
    value: 'Direct Mail Lead',
    label: 'Direct Mail Lead',
  },
  {
    value: 'Internet Search',
    label: 'Internet Search',
  },
  {
    value: 'Referral',
    label: 'Referral',
  },
  {
    value: 'Seminar',
    label: 'Seminar',
  },
  {
    value: 'Walk-In',
    label: 'Walk-In',
  },
  {
    value: 'Website Lead',
    label: 'Website Lead',
  },
  {
    value: 'Workshop',
    label: 'Workshop',
  },
];

export const COMPANY_TYPE_OPTIONS = [
  {
    value:'LLC',
    label:'LLC',
  },
  {
    value:'LLP',
    label:'LLP',
  },
  {
    value:'S-Corp',
    label:'S-Corp',
  },
  {
    value:'C-Corp',
    label:'C-Corp',
  },
  {
    value:'Sole Proprietorship',
    label:'Sole Proprietorship',
  },
  {
    value:'General Partnership',
    label:'General Partnership',
  },
  {
    value:'Limited Partnership',
    label:'Limited Partnership',
  },
  {
    value:'Corporation',
    label:'Corporation',
  },
  {
    value:'Non-Profit Corporation',
    label:'Non-Profit Corporation',
  },
  {
    label: 'Non-Profit 501 (c)(3)',
    value: 'Non-Profit 501 (c)(3)',
  },
  {
    value:'Trust',
    label:'Trust',
  },
  {
    value:'Joint Venture',
    label:'Joint Venture',
  },
  {
    value:'Association',
    label:'Association',
  },
  {
    value:'Tenants in Common',
    label:'Tenants in Common',
  },
  {
    value:'Municipality',
    label:'Municipality',
  },
];

export const CONTACT_PREFERENCE_OPTIONS = [
  {
    value:'Call',
    label:'Call',
  },
  {
    value:'Email',
    label:'Email',
  },
  {
    value:'Mail',
    label:'Mail',
  },
  {
    value:'Text message',
    label:'Text message',
  },
];
export const CONTACT_RULES_OPTIONS = [
  {
    value:'Do Not Call',
    label:'Do Not Call',
  },
  {
    value:'Do Not Email',
    label:'Do Not Email',
  },
  {
    value:'Do Not Mail',
    label:'Do Not Mail',
  },
  {
    value:'Do Not Text message',
    label:'Do Not Text message',
  },
];

export const OPPORTUNITIES_OPTIONS = [
  {
    value:'Annuity',
    label:'Annuity',
  },
  {
    value:'AUM',
    label:'AUM',
  },
  {
    value:'Life insurance',
    label:'Life insurance',
  },
  {
    value:'Insurance',
    label:'Insurance',
  },
  {
    value:'LTC',
    label:'LTC',
  },
  {
    value:'Tax Prep',
    label:'Tax Prep',
  },
];

export const ACCOUNT_SUB_TYPE_OPTIONS = [
  {
    value: 'Cash & Equivalent - Other',
    label: 'Cash & Equivalent - Other',
  },
  {
    value: 'Cash & Equivalent - Cash Management',
    label: 'Cash & Equivalent - Cash Management',
  },
  {
    value: 'Cash & Equivalent - CDs',
    label: 'Cash & Equivalent - CDs',
  },
  {
    value: 'Cash & Equivalent - Checking',
    label: 'Cash & Equivalent - Checking',
  },
  {
    value: 'Cash & Equivalent - Money Market',
    label: 'Cash & Equivalent - Money Market',
  },
  {
    value: 'Cash & Equivalent - Savings',
    label: 'Cash & Equivalent - Savings',
  },
  {
    value: 'Cash & Equivalent - T-Bills',
    label: 'Cash & Equivalent - T-Bills',
  },
  {
    value: 'Investment - AUM',
    label: 'Investment - AUM',
  },
  {
    value: 'Investment - Brokerage',
    label: 'Investment - Brokerage',
  },
  {
    value: 'Investment - Funding Account',
    label: 'Investment - Funding Account',
  },
  {
    value: 'Investment - Individual Bonds',
    label: 'Investment - Individual Bonds',
  },
  {
    value: 'Investment - REIT',
    label: 'Investment - REIT',
  },
  {
    value: 'Investment - Retirement Plan',
    label: 'Investment - Retirement Plan',
  },
  {
    value: 'Investment - Self-Directed',
    label: 'Investment - Self-Directed',
  },
  {
    value: 'Investment - SMA',
    label: 'Investment - SMA',
  },
];

export const INSURANCE_POLICY_TYPE_OPTIONS = [
  {
    value: 'Annuities',
    label: 'Annuities'
  },
  {
    value: 'Life Insurance',
    label: 'Life Insurance'
  },
  {
    value: 'Long Term Care',
    label: 'Long Term Care'
  },
  {
    value: 'Other Insurance',
    label: 'Other Insurance'
  },
];

export const INSURANCE_POLICY_SUB_TYPE_OPTIONS = [
  {
    value: 'Annuity - Fixed Annuity',
    label: 'Annuity - Fixed Annuity'
  },
  {
    value: 'Annuity - Indexed Annuity',
    label: 'Annuity - Indexed Annuity'
  },
  {
    value: 'Annuity - MYGA',
    label: 'Annuity - MYGA'
  },
  {
    value: 'Annuity - SPIA',
    label: 'Annuity - SPIA'
  },
  {
    value: 'Annuity - Variable Annuity',
    label: 'Annuity - Variable Annuity'
  },
  {
    value: 'Annuity - Other',
    label: 'Annuity - Other'
  },
  {
    value: 'Life insurance - IUL',
    label: 'Life insurance - IUL'
  },
  {
    value: 'Life insurance - ROP Term',
    label: 'Life insurance - ROP Term'
  },
  {
    value: 'Life insurance - Term Life',
    label: 'Life insurance - Term Life'
  },
  {
    value: 'Life insurance - Universal',
    label: 'Life insurance - Universal'
  },
  {
    value: 'Life insurance - Variable',
    label: 'Life insurance - Variable'
  },
  {
    value: 'Life insurance - VUL',
    label: 'Life insurance - VUL'
  },
  {
    value: 'Life insurance - Whole Life',
    label: 'Life insurance - Whole Life'
  },
  {
    value: 'Long Term Care - IUL',
    label: 'Long Term Care - IUL'
  },
  {
    value: 'Long Term Care - ROP Term',
    label: 'Long Term Care - ROP Term'
  },
  {
    value: 'Long Term Care - Term Life',
    label: 'Long Term Care - Term Life'
  },
  {
    value: 'Long Term Care - Universal',
    label: 'Long Term Care - Universal'
  },
  {
    value: 'Long Term Care - Variable',
    label: 'Long Term Care - Variable'
  },
  {
    value: 'Long Term Care - VUL',
    label: 'Long Term Care - VUL'
  },
  {
    value: 'Long Term Care - Whole Life',
    label: 'Long Term Care - Whole Life'
  },
  {
    value: 'Other Insurance - IUL',
    label: 'Other Insurance - IUL'
  },
  {
    value: 'Other Insurance - ROP Term',
    label: 'Other Insurance - ROP Term'
  },
  {
    value: 'Other Insurance - Term Life',
    label: 'Other Insurance - Term Life'
  },
  {
    value: 'Other Insurance - Universal',
    label: 'Other Insurance - Universal'
  },
  {
    value: 'Other Insurance - Variable',
    label: 'Other Insurance - Variable'
  },
  {
    value: 'Other Insurance - VUL',
    label: 'Other Insurance - VUL'
  },
  {
    value: 'Other Insurance - Whole Life',
    label: 'Other Insurance - Whole Life'
  },
];

export const ASSET_TYPE_OPTIONS = [
  {
    value: 'Real Estate',
    label: 'Real Estate'
  },
  {
    value: 'Personal Property',
    label: 'Personal Property'
  },
  {
    value: 'Other Asset',
    label: 'Other Asset'
  },
];

export const EXPENSES_TYPE_OPTIONS = [
  {
    value: 'Real Estate',
    label: 'Real Estate'
  },
  {
    value: 'Education',
    label: 'Education'
  },
  {
    value: 'Charity/Gift',
    label: 'Charity/Gift'
  },
  {
    value: 'Vehicle',
    label: 'Vehicle'
  },
  {
    value: 'Vacation/Travel',
    label: 'Vacation/Travel'
  },
  {
    value: 'Insurance',
    label: 'Insurance'
  },
  {
    value: 'Debt Payoff',
    label: 'Debt Payoff'
  },
  {
    value: 'Business',
    label: 'Business'
  },
  {
    value: 'Health',
    label: 'Health'
  },
  {
    value: 'Other Expenses',
    label: 'Other Expenses'
  },

];


export const INCOME_TYPE_OPTIONS = [
  {
    value: 'Social Security',
    label: 'Social Security'
  },
  {
    value: 'Salary',
    label: 'Salary'
  },
  {
    value: 'Annuities',
    label: 'Annuities'
  },
  {
    value: 'Pension',
    label: 'Pension'
  },
  {
    value: 'Other Income',
    label: 'Other Income'
  },
];



export const LIABILITY_TYPE_OPTIONS = [
  {
    value: 'Mortgage',
    label: 'Mortgage'
  },
  {
    value: 'Credit Cards',
    label: 'Credit Cards'
  },
  {
    value: 'Personal Loan',
    label: 'Personal Loan'
  },
  {
    value: 'Business Loan',
    label: 'Business Loan'
  },
  {
    value: 'HELOC',
    label: 'HELOC'
  },
  {
    value: 'Other Debt',
    label: 'Other Debt'
  },
];

export const ATTENDED_EVENTS_OPTIONS = [
  {
    value: EVENT_TYPES_BACKEND.generalActivity,
    label: 'General Activity',
  },
  {
    value: EVENT_TYPES_BACKEND.salesAppointment,
    label: 'Sales Appointment',
  },
  {
    value: EVENT_TYPES_BACKEND.accountReview,
    label: 'Account Review',
  },
  {
    value: EVENT_TYPES_BACKEND.meeting,
    label: 'Meeting',
  },
  {
    value: EVENT_TYPES_BACKEND.event,
    label: 'Event',
  },
  {
    value: EVENT_TYPES_BACKEND.timeBlock,
    label: 'Time Block',
  },
];

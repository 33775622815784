import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 350px;
  margin: 20px 0 0 20px;
  padding: 20px;
  border-radius: 10px;
  background: ${COLORS.white};
  box-shadow: 0px 1px 5px 0px #101C5B12;
`;

import React from 'react';

import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';

import { InputWrapper, SelectLabel, SelectWrapper, Wrapper } from './styles';

const ELLIPSIS_TEXT_WRAPPER_STYLE = {
  minHeight: '14px'
};

const SELECT_MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  PaperProps: {
    style: {
      maxHeight: '300px',
      overflowY: 'auto'
    }
  }
};

export const TitleAndWith = ({
  control,
  titleName,
  noteContactIdName,
  noteContactOptions
}) => {

  return (
    <Wrapper>
      <InputWrapper>
        <HFTextInput
          inputProps={ {
            maxlength: 150,
            defaultValue: '',
          } }
          control={ control }
          disableErrorStyle={ true }
          name={ titleName }
          placeholder='Enter Note Title'
          showErrorText={ false }
        />
      </InputWrapper>
      <SelectLabel>
        With
      </SelectLabel>
      <SelectWrapper>
        <HFSelect
          MenuProps={ SELECT_MENU_PROPS }
          control={ control }
          name={ noteContactIdName }
          options={ noteContactOptions }
          placeholder='Select Contact'
          renderItem={ (data) => {
            const valueToRender = data?.label || ' ';
            return (
              <Tooltip
                displayOnlyOnOverflow={ true }
                title={ valueToRender }
              >
                <TextEllipsis
                  $maxWidth={ 250 }
                  style={ ELLIPSIS_TEXT_WRAPPER_STYLE }
                >
                  { valueToRender }
                </TextEllipsis>
              </Tooltip>
            );
          } }
        />
      </SelectWrapper>
    </Wrapper>
  );
};

import React, { useCallback, useEffect } from 'react';

import { Item, Header, Footer, Row } from './components';

import { NotificationWithActionProps } from './types';

import { Wrapper } from './styles';

import { NotificationWithActionContext } from './context';

export const NotificationWithAction = ({
  children,
  buttonAction,
  closeAction,
  duration
}: NotificationWithActionProps) => {

  const composedActions = useCallback(() => {
    closeAction();
    buttonAction();
  },[buttonAction, closeAction]);


  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;

    if(duration) {
      timerId = setTimeout(() => {
        composedActions();
      }, duration);
    }

    return () => {
      if(timerId) {
        clearTimeout(timerId);
      }
    };
  }, [composedActions, duration]);

  return (
    <NotificationWithActionContext.Provider
      value={ {
        action: composedActions
      } }
    >
      <Wrapper onClick={ composedActions }>
        {children}
      </Wrapper>
    </NotificationWithActionContext.Provider>
  );
};

NotificationWithAction.Footer = Footer;
NotificationWithAction.Header = Header;
NotificationWithAction.Item = Item;
NotificationWithAction.Row = Row;

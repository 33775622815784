import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 1;
  color: ${ COLORS.primaryText };
  background-color: ${ COLORS.dividingLines };
`;

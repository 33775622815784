import HFSelect from '@modules/HookFormComponents/HFSelect';
import React from 'react';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { LeftColumnWrapper, RightColumnWrapper } from '@modules/AddContactModal/components/Columns';

import { maxInputLength } from '@/shared/utils/maxInputLength';
import { getErrorMessage } from '@/shared/utils/useFormUtils';

import { LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH } from '@modules/AddContactModal/data';

function BasicInformation({ properties }) {
  const { control, formState, options } = properties;
  const getError = getErrorMessage(formState);

  return (
    <FormBlock
      isBorderBottom={ false }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Basic Information' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH  }
            isRequired={ true }
            label={ 'Business Name' }>
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name='1.occupationCompany'
              placeholder='Enter Business Name'
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH  }
            label={ '# Employees' }
          >
            <HFSelect
              control={ control }
              name={ '1.employeeCount' }
              options={ options.employeeCount }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Company Type' }
          >
            <HFSelect
              control={ control }
              name={ '1.companyType' }
              options={ options.companyTypes }
              placeholder={ 'Select Company Type' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Annual Revenue' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name={ '1.annualRevenue' }
              placeholder={ 'Enter Annual Revenue' }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}

export default BasicInformation;

import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { BOX_SHADOW } from '@/shared/constants/style';

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const UsersWrapper = styled.div`
  height: calc(100vh - 168px);
  display: flex;
  flex-grow: 1;
  margin: 0 20px;
  padding-bottom: 20px;
`;

export const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 500px;
  border-radius: 10px;
  background-color: ${ COLORS.white };
  box-shadow: ${ BOX_SHADOW };
  overflow: hidden;

  & table {
    table-layout: fixed;
  }
`;
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';
import { typeConverter } from '@/shared/utils/typeConverter';
import { EVENT_TYPES_BACKEND } from '@constants/colorsByEventTypes';
import {
  differenceInDays,
  parse,
  addDays,
  addHours,
  addMinutes,
  intervalToDuration
} from 'date-fns';

import {
  YEAR_MONTH_DAY,
  YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS
} from '@constants/dateFormats';
import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';


const calculateStartAndEnd = ({
  startDate,
  endDate,
  forDay,
  endTime,
  startTime,
}) => {
  const start = parse(startDate, YEAR_MONTH_DAY, new Date());
  const end = parse(endDate, YEAR_MONTH_DAY, new Date());

  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');
  const dayDiff = differenceInDays(end, start);

  const startDateBasedOnForDay = parse(forDay, YEAR_MONTH_DAY, new Date());
  const endBaseOnDiffAndForDay = addDays(startDateBasedOnForDay, dayDiff);

  let startDateBasedOnForDayWithTime = addHours(startDateBasedOnForDay,Number(startHours));
  startDateBasedOnForDayWithTime = addMinutes(startDateBasedOnForDayWithTime, Number(startMinutes));

  let endBaseOnDiffAndForDayWithTime = addHours(endBaseOnDiffAndForDay,Number(endHours));

  endBaseOnDiffAndForDayWithTime = addMinutes(endBaseOnDiffAndForDayWithTime,Number(endMinutes));

  return {
    start: startDateBasedOnForDayWithTime,
    end: endBaseOnDiffAndForDayWithTime
  };
};


const getStartAndEnd = (item) => {
  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime,
    isAllDay: yesNoToBoolean(item.allDay)
  });

  if(!yesNoToBoolean(item.recurring)){
    return {
      end: parse(`${endDate} ${endTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
      start: parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
    };
  }

  return calculateStartAndEnd({
    startDate,
    endDate,
    forDay: item.forDay,
    endTime,
    startTime,
  });
};

const getAllDay = item => {
  const booleanAllDay = yesNoToBoolean(item.allDay);
  if(booleanAllDay) {
    return booleanAllDay;
  }

  let allDay = false;

  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime
  });

  try {
    const { days, years, months } = intervalToDuration({
      end: parse(`${endDate} ${endTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
      start: parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
    });

    if(days >= 1 || months >= 1 || years >= 1) {
      allDay = true;
    }
  } catch (error) {
    console.error(error);
  }

  return allDay;
};

const findStatusLabelById = (arr, id) => {
  if(!Array.isArray(arr)) {
    return null;
  }
  const found = arr.find(item => item.id === id);
  return found ? found.option : null;
};

export const calendarNormalizer = (resp, statusData) => {
  if(!resp) {
    return [];
  }

  const result = resp.map(item => {
    const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
      startDate: item.startDate,
      startTime: item.startTime,
      endDate: item.endDate,
      endTime: item.endTime,
      isAllDay: yesNoToBoolean(item.allDay)
    });  
  
    return {
      ...getStartAndEnd(item),
      allDay: yesNoToBoolean(item.allDay),
      title: item.name,
      resource: {
        allDay: yesNoToBoolean(item.allDay),
        clients: item.clients,
        description: item.description,
        editAllow: item.editAllow,
        endDate,
        endTime,
        forDay: item?.forDay,
        id: item.id,
        isAllDayGrid: getAllDay(item),
        name: item.name,
        primaryClientId: item.primaryClientId,
        primaryContactId: item.primaryContactId,
        recurring: yesNoToBoolean(item.recurring),
        recurringEndDate: item.recurringEndDate,
        recurringFrequency: item.recurringFrequency,
        startDate,
        startTime,
        statusId: item.statusId,
        statusLabel: findStatusLabelById(statusData, item.statusId),
        subCategoryId: item.subCategoryId,
        isEditable: item.type !== EVENT_TYPES_BACKEND.holiday && item.editAllow,
        type: typeConverter({
          isBackend: true,
          type: item.type,
        }),
      }
    };
  });

  return result;
};

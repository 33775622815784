import { Items, ObjectLike } from '@/shared/types/commonTypes';
import ApiService from '@services/api/api.service';

export const getContactsForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/crm/contact/getTrashContactGrid', { params });
};

export const getListsForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/list/getListTrashGrid', { params });
};

export const getActivitiesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/activity/getActivityTrashGrid', { params });
};

export const getAccountsAndPlansForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/accountPlans/getTrashContactGrid', { params });
};

export const getTasksForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/task/getTaskTrashGrid', { params });
};

export const getSalesCyclesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/pipeline/getTrashSalesPipeLineGrid', { params });
};

export const getClientOpportunitiesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/opportunity/getTrashOpportunityGrid', { params });
};

export const deleteContactsFromTrash = (params: Items) => {
  return ApiService.delete('api/crm/contact/permanentDelete', { params });
};

export const deleteListsFromTrash = (params: Items) => {
  return ApiService.delete('api/list/permanentDelete', { params });
};

export const deleteActivitiesFromTrash = (params: Items) => {
  return ApiService.delete('api/activity/permanentDelete', { params });
};

export const deleteAccountAndPlansFromTrash = (params: Items) => {
  return ApiService.delete('api/accountPlans/deletePermanently', { params });
};

export const deleteTasksFromTrash = (params: Items) => {
  return ApiService.delete('api/task/deletePermanently', { params });
};

export const deleteSalesCyclesFromTrash = (params: Items) => {
  return ApiService.delete('api/pipeline/deletePermanently', { params });
};

export const deleteClientOpportunitiesFromTrash = (params: Items) => {
  return ApiService.delete('api/opportunity/deletePermanently', { params });
};

export const restoreContactsFromTrash = (params: Items) => {
  return ApiService.patch('api/crm/contact/restore', {}, { params });
};

export const restoreListsFromTrash = (params: Items) => {
  return ApiService.patch('api/list/restore', {}, { params });
};

export const restoreActivitiesFromTrash = (params: Items) => {
  return ApiService.patch('api/activity/restore', {}, { params });
};

export const restoreAccountAndPlansFromTrash = (params: Items) => {
  return ApiService.patch('api/accountPlans/restore', {}, { params });
};

export const restoreTasksFromTrash = (params: Items) => {
  return ApiService.patch('api/task/restore', {}, { params });
};

export const restoreSalesCyclesFromTrash = (params: Items) => {
  return ApiService.patch('api/pipeline/restore', {}, { params });
};

export const restoreClientOpportunitiesFromTrash = (params: Items) => {
  return ApiService.patch('api/opportunity/restore', {}, { params });
};

import { ColumnType } from '@components/Table/components/Header/types';
import { Padding } from '@material-ui/core';

export const getColumnCommonFields = (width: string, paddingLeft: string = '20px'): Partial<ColumnType> => ({
  padding: 'none' as Padding,
  style: {
    width,
    paddingLeft,
  }
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { PlusWithCircle, Trash } from '@/assets';

import BlockButton from '@components/BlockButton';
import BubbleButton from '@components/BubbleButton';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import RowFilter from '@components/RowFilter';
import { UserSelectFilter } from '@modules/UserSelectFilter';

import { ActionsWrapper, BubbleFiltersWrapper } from './styles';

import { SalesClientOpportunitiesPreHeaderProps } from './types';

import { OPPORTUNITY_FILTER_NAMES, OPPORTUNITY_STAGES } from '@constants/salesCycleOpportunity';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

export const SalesClientOpportunitiesPreHeader = observer(({
  onAdd,
  onRemove,
}: SalesClientOpportunitiesPreHeaderProps) => {
  const { isLoad, SalesClientOpportunitiesTable } = useStore().SalesCycleStore;
  const {
    table,
    filters,
    filterData,
    setFilterState,
    setStageFilter,
    stageFilter,
  } = SalesClientOpportunitiesTable;

  const onRemoveWrapper = useCallback(() => {
    onRemove(table.selectedIDs as Array<number>);
  },[onRemove, table.selectedIDs]);

  const onUserChange = useCallback((value: Array<string>) => {
    setFilterState(OPPORTUNITY_FILTER_NAMES.users, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onStageChange = useCallback((value: any) => {
    setStageFilter(value === FILTER_ALL_BTN_LABEL ? null : value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onAddWrapper = useCallback(() => onAdd(),[onAdd]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }
    >
      <UserSelectFilter
        closeTrigger={ isLoad }
        onChange={ onUserChange }
        usersOptions={ filterData.officePrimaryAdvisor }
        value={ filters.officePrimaryAdvisor }
        withAllUserOption={ true }
      />
      <BubbleFiltersWrapper>
        <RowFilter
          allButtonLabel={ FILTER_ALL_BTN_LABEL }
          initState={ stageFilter?.[OPPORTUNITY_FILTER_NAMES.stage] || null }
          items={ Object.values(OPPORTUNITY_STAGES) }
          limit={ 6 }
          onChange={ onStageChange }
          renderItem={ ({
            content,
            isSelected,
            isShowMore,
            onClick,
            isSelectedFromMore,
            indexForKey,
          }) => (
            <BubbleButton
              $isActive={ isSelected && !isShowMore }
              $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
              key={ indexForKey }
              onClick={ onClick }
            >
              { content }
            </BubbleButton>
          ) }
        />
      </BubbleFiltersWrapper>

      <ActionsWrapper>
        {
          table.selectedIDs.length > 0 &&
          <BlockButton
            onClick={ onRemoveWrapper }
            startIcon={ <Trash/> }
          >
            Delete All Selected
          </BlockButton>
        }
        <BlockButton
          $iconSize={ '16px' }
          onClick={ onAddWrapper }
          startIcon={ <PlusWithCircle/> }
        >
          Add Opportunity
        </BlockButton>
      </ActionsWrapper>
    </PreTableWrapper>
  );
});

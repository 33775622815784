import { COLORS } from '@constants/colors';

export const EVENT_TYPES = {
  accountReview: 'AccountReview',
  event: 'Event',
  generalActivity: 'GeneralActivity',
  meeting: 'Meeting',
  salesAppointment: 'SalesAppointment',
  timeBlock: 'TimeBlock',
  holiday: 'Holiday'
};

export const EVENT_STATUSES = {
  cancelled: 'Cancelled'
};

export const EVENT_TYPES_BACKEND = {
  accountReview: 'Account Review',
  event: 'Event',
  generalActivity: 'General Activity',
  meeting:  'Meeting',
  salesAppointment: 'Sales Appointment',
  timeBlock: 'Time Block',
  holiday: 'Holiday'
};

export const COLORS_MAP_BY_EVENT_TYPE = {
  [EVENT_TYPES.accountReview] : {
    indicatorColor: COLORS.categoryAccountReview,
    fontColor: COLORS.categoryAccountReview,
    bgColor: COLORS.categoryAccountReviewSecondary,
    dividerColor: COLORS.categoryAccountReviewThird
  },
  [EVENT_TYPES.event] : {
    indicatorColor: COLORS.categoryEvent,
    fontColor: COLORS.categoryEvent,
    bgColor: COLORS.categoryEventSecondary,
    dividerColor: COLORS.categoryEventThird
  },
  [EVENT_TYPES.generalActivity] : {
    indicatorColor: COLORS.categoryGeneral,
    fontColor: COLORS.categoryGeneral,
    bgColor: COLORS.categoryGeneralSecondary,
    dividerColor: COLORS.categoryGeneralThird
  },
  [EVENT_TYPES.meeting] : {
    indicatorColor: COLORS.categoryMeeting,
    fontColor: COLORS.categoryMeeting,
    bgColor: COLORS.categoryMeetingSecondary,
    dividerColor: COLORS.categoryMeetingThird
  },
  [EVENT_TYPES.salesAppointment] : {
    indicatorColor: COLORS.categorySalesAppointments,
    fontColor: COLORS.categorySalesAppointments,
    bgColor: COLORS.categorySalesAppointmentsSecondary,
    dividerColor: COLORS.categorySalesAppointmentsThird
  },
  [EVENT_TYPES.timeBlock] : {
    indicatorColor: COLORS.redLight,
    fontColor: COLORS.white,
    bgColor: COLORS.redLight,
    dividerColor: COLORS.white,
  },
  [EVENT_TYPES.holiday] : {
    indicatorColor: COLORS.green,
    fontColor: COLORS.white,
    bgColor: COLORS.green,
    dividerColor: COLORS.green,
  },
  [EVENT_STATUSES.cancelled] : {
    indicatorColor: COLORS.activityStatusCancelled,
    fontColor: COLORS.activityStatusCancelled,
    bgColor: COLORS.activityStatusCancelledSecondary,
    dividerColor: COLORS.activityStatusCancelled
  },
};

import styled, { css } from 'styled-components';
import { WrapperWithStylesToChildrenProps } from './types';

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
`;

export const WrapperWithStylesToChildren = styled.div<WrapperWithStylesToChildrenProps>`
  position: relative;

  ${({ $width }) => $width && css`
    width: ${$width};
  `}
  
  ${({ $height }) => $height && css`
    height: ${$height};
  `}
  
  & > * {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

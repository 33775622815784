import styled, { css } from 'styled-components';

import { TRIANGLE_TYPE } from './data';

import { WrapperProps } from './types';

const getHalfSize = (size: number) => `${size / 2}px`;
export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  
  ${({ $size }) => css`
    width: ${$size}px;
    height: ${$size}px;
  `}
 
  &:after {
    position: absolute;
    content: '';
    top: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    
    ${({ $type, $color, $size }) => {
    if($type === TRIANGLE_TYPE.TOP){
      return css`
        border-width: 0 ${getHalfSize($size)} ${getHalfSize($size)} ${getHalfSize($size)};
        border-color: transparent transparent ${$color} transparent;
      `;
    }
    if($type === TRIANGLE_TYPE.BOTTOM){
      return css`
        border-width: ${getHalfSize($size)} ${getHalfSize($size)} 0 ${getHalfSize($size)};
        border-color: ${$color} transparent transparent transparent;
      `;
    }
  }
}
`;

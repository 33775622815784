import React from 'react';

import ModalWrapper from '@modules/Modals/components/Modal/ModalWrapper';

import ModalContent from './components/ModalContent';

function AddContactModal({ closeModal, modalProps }) {
  return (
    <ModalWrapper
      closeModal={ closeModal }
      modalContent={ <ModalContent /> }
      modalProps={ modalProps }
    />
  );
}

export default AddContactModal;

import { COLORS } from '@constants/colors';

import { ColorSchemeType } from './types';

export const VARIANTS = {
  common: 'common',
  orange: 'orange'
} as const;

export const COLOR_SCHEME: ColorSchemeType = {
  [VARIANTS.common]: {
    bgColor: COLORS.bgActions,
    color: COLORS.header,
  },
  [VARIANTS.orange]: {
    bgColor: COLORS.bgActions,
    color: COLORS.orange
  }
};
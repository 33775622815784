import { UserStatuses } from '@constants/common';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { User, Users, Setting, Password, Layout } from '@/assets';
import { TimeInSeconds } from '@services/store/userSettingsStore/types';
import {
  ProfileInformationInputNamesType,
  UserProfileInformationInputNamesType
} from '@pages/NewUserSettings/types';

import {
  USER_SETTINGS_TABS,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH,
  USER_SETTINGS_ACCOUNT_SETTINGS_PATH,
  USER_SETTINGS_MY_PROFILE_PATH,
  USER_SETTINGS_PASSWORD_PATH,
  USER_SETTINGS_USERS_PATH
} from '@constants/routes/userSettings';

type IconType = User | Users | Setting | Password | Layout;

export type UserSettingsTabType = {
  label: string;
  value: string;
  link: string;
  icon: IconType;
}

export const USER_SETTINGS_TABS_CONFIG: UserSettingsTabType[] = [
  {
    label: 'Account Settings',
    value: USER_SETTINGS_TABS.accountSettings,
    icon: Setting,
    link: USER_SETTINGS_ACCOUNT_SETTINGS_PATH
  },
  {
    label: 'My Profile',
    value: USER_SETTINGS_TABS.myProfile,
    icon: User,
    link: USER_SETTINGS_MY_PROFILE_PATH
  },
  {
    label: 'Password',
    value: USER_SETTINGS_TABS.password,
    icon: Password,
    link: USER_SETTINGS_PASSWORD_PATH
  },
  {
    label: 'Users',
    value: USER_SETTINGS_TABS.users,
    icon: Users,
    link: USER_SETTINGS_USERS_PATH
  },
  {
    label: 'Custom Fields & Tags',
    value: USER_SETTINGS_TABS.customFieldsAndTags,
    icon: Layout,
    link: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH
  },
];

export enum AffiliatedOgranization {
  advisorsCircle = 'Advisors Circle',
  advisorsIgnite = 'Advisors Ignite',
  financialSecurityManagement = 'Financial Security Management',
  renegateNurtureMembers = 'Renegate Nurture Members',
  rtm2021 = 'RTM2021',
  sglFinancial = 'SGL Financial',
  visionAdvisors = 'Vision Advisors',
}

export const COMMON_UNIT_TIME_OPTIONS = [
  {
    value: TimeInSeconds.Minutes15,
    label: '15 minutes',
  },
  {
    value: TimeInSeconds.Minutes30,
    label: '30 minutes',
  },
  {
    value: TimeInSeconds.Minutes45,
    label: '45 minutes',
  },
  {
    value: TimeInSeconds.Hour1,
    label: '1 hour',
  },
  {
    value: TimeInSeconds.Hour1Minutes30,
    label: '1 hour 30 minutes',
  },
  {
    value: TimeInSeconds.Hours2,
    label: '2 hours',
  },
];

export const DEFAULT_STATE_VALUE: number = 14;//default state - Illinois

export const LABELS_WIDTH = {
  accountSettingLeftLabelWidth: 168,
  accountSettingRightLabelWidth: 181,
  addUserPopupAddUserTabLeftLabelWidth: 139,
  addUserPopupAddUserTabRightLabelWidth: 100,
  addUserPopupUserProfileTabLeftLabelWidth: 112,
  addUserPopupUserProfileTabRightLabelWidth: 122,
  profileLeftLabelWidth: 123,
  profileRightLabelWidth: 119,
  passwordLeftLabelWidth: 135,
};

export const FORM_BLOCK_MARGIN_BOTTOM = 20;

export const ProfileFormInputNames: ProfileInformationInputNamesType = {
  addressLine: 'addressLine',
  addressLine2: 'addressLine2',
  businessName: 'businessName',
  city: 'city',
  email: 'email',
  faxNumber: 'faxNumber',
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  state: 'state',
  title: 'title',
  website: 'website',
  zip: 'zip',
  headshot: 'headshot',
  logo: 'logo',
  signature: 'signature',
  compliance: 'compliance',
  emailSignature: 'emailSignature',
  emailVerified: 'emailVerified',
};

export const UserProfileFormInputNames: UserProfileInformationInputNamesType = {
  ...ProfileFormInputNames,
  advisors: 'advisors',
  clients: 'clients',
  status: 'status',
  userRole: 'userRole',
};

export const USER_STATUS_OPTIONS =  optionsFromValue(Object.values(UserStatuses));


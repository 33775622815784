export const numberToOption = (num: number) => num.toString().padStart(2, '0');

export const getSeparatedHourAndMinuteValues = (timeString: string) => {
  if(!timeString) {
    return null;
  }
  const [ hours, minutes] = timeString.split(':');

  if(!hours || !minutes) {
    return null;
  }

  return {
    hours,
    minutes
  };
};

export const convertToTwelveFormat = (timeString: string, withSeconds: boolean) => {
  const values = getSeparatedHourAndMinuteValues(timeString);
  if(!values) {
    return null;
  }

  let { hours, minutes } = values;
  let hoursToNumber = Number(hours);

  if (hoursToNumber === 0) {
    hours = '12';
  } else if (hoursToNumber > 12) {
    hours = numberToOption(hoursToNumber - 12);
  } else {
    hours = numberToOption(hoursToNumber);
  }

  return withSeconds ? `${hours}:${minutes}:00` : `${hours}:${minutes}`;
};

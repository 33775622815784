import { store } from '@services/store/store';

import { MODAL_TYPE } from '@constants/modalTypes';

import DetectLinkTransitionPopup from '@modules/DetectLinkTransitionPopup';
import AddSpousePopup from '@modules/Contacts/AddSpousePopup';

import { DetectLinkTransitionPopupProps } from '@modules/DetectLinkTransitionPopup/types';
import { IdType } from '@/shared/types/commonTypes';

export const openDetectLinkTransitionPopup = (args: {
  onConfirm: DetectLinkTransitionPopupProps['onConfirm'],
  onCancel: DetectLinkTransitionPopupProps['onCancel'],
  props?: Partial<Omit<DetectLinkTransitionPopupProps, 'onConfirm' | 'onCancel'>>
}) => {
  const modalStore = store.ModalStore;

  modalStore.openModal({
    modalType: MODAL_TYPE.BLOCK_LINK_TRANSITION_MODAL,
    modalProps: {
      onConfirm: args.onConfirm,
      onCancel: args.onCancel,
      ...args.props
    },
    component: DetectLinkTransitionPopup
  });
};

export const openAddSousePopup = (currentUserId: IdType) => {
  const modalStore = store.ModalStore;

  modalStore.openModal({
    modalProps: {
      currentUserId
    },
    modalType: MODAL_TYPE.ADD_SPOUSE,
    component: AddSpousePopup
  });
};

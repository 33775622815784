import * as yup from 'yup';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation } from '@constants/validationPatterns';


import {
  COMMON_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

export const PRIMARY_ADVISER_RULES = {
  [COMMON_FIELDS.officePrimaryAdvisor]: yup.string()
    .nullable()
    .required(validationMessages.required),
};

export const CLIENT_TIMESTAMP_RULES = {
  [COMMON_FIELDS.recordTimestamp]: yup.string()
    .nullable()
    .transform((value) => {
      const [date, time] = value.split(' ');
      return date;
    })
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than Today',
      validationMessages.invalidDate,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    )
};

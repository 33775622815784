import React, { ReactNode } from 'react';

import Table from '@components/Table';

type CellWrapperProps = {
  children: ReactNode
}

export const TABLE_CELL_PROPS = {
  style: {
    height: '56px'
  }
};

export const CellWrapper = ({ children } : CellWrapperProps) => (
  <Table.Cell
    $alignItems={ 'end' }
    TableCellProps={ TABLE_CELL_PROPS }
  >
    {children}
  </Table.Cell>
);

import { ContactsFieldsNames } from '@/shared/types/contactsData';
import { ValueLabelObj } from '@/shared/types/commonTypes';

export enum FIELD_IDS {
  MOBILE_PHONE_ID = 1,
  WORK_PHONE_ID = 11,
  HOME_PHONE_ID = 12,
  OTHER_PHONE_ID = 14,
  WORK_EMAIL_ID = 2,
  HOME_EMAIL_ID = 3,
  OTHER_EMAIL_ID = 17,
  WORK_LOCATION_ID= 4,
  HOME_LOCATION_ID = 10,
  MAILING_LOCATION_ID = 16,
  OTHER_LOCATION_ID = 15,
  FACEBOOK_LINK_ID= 5,
  TWITTER_LINK_ID = 6,
  LINKEDIN_LINK_ID = 7,
  CONTACT_PREFERENCE_ID = 9,
}

export const CONTACTS_DATA_FIELD_NAMES: ContactsFieldsNames & { ownerId: string } = {
  city: 'city',
  fieldId: 'fieldId',
  id: 'id',
  ownerId: 'ownerId',
  primary: 'primary',
  primaryToHousehold: 'primaryToHousehold',
  state: 'state',
  street: 'street',
  street2: 'street2',
  useToEmployee: 'useToEmployee',
  useToHousehold: 'useToHousehold',
  value: 'value',
  zip: 'zip',
};

export const WORK_EMAIL = {
  fieldName: 'Work Email',
  fieldId: FIELD_IDS.WORK_EMAIL_ID
} as const;

export const HOME_EMAIL = {
  fieldName: 'Home Email',
  fieldId: FIELD_IDS.HOME_EMAIL_ID
} as const;

export const OTHER_EMAIL = {
  fieldName: 'Other Email',
  fieldId: FIELD_IDS.OTHER_EMAIL_ID
} as const;

export const CONTACTS_EMAIL_IDS = [
  FIELD_IDS.WORK_EMAIL_ID,
  FIELD_IDS.OTHER_EMAIL_ID,
  FIELD_IDS.HOME_EMAIL_ID
];

export const FACEBOOK_LINK = {
  fieldName: 'Facebook Link',
  fieldId: FIELD_IDS.FACEBOOK_LINK_ID
} as const;

export const TWITTER_LINK = {
  fieldName: 'Twitter Link',
  fieldId: FIELD_IDS.TWITTER_LINK_ID
} as const;

export const LINKEDIN_LINK = {
  fieldName: 'LinkedIn Link',
  fieldId: FIELD_IDS.LINKEDIN_LINK_ID
} as const;

export const CONTACTS_LINK_IDS = [
  FIELD_IDS.FACEBOOK_LINK_ID,
  FIELD_IDS.TWITTER_LINK_ID,
  FIELD_IDS.LINKEDIN_LINK_ID,
];

export const WORK_LOCATION = {
  fieldName: 'Work Address',
  fieldId: FIELD_IDS.WORK_LOCATION_ID
} as const;

export const HOME_LOCATION = {
  fieldName: 'Home Address',
  fieldId: FIELD_IDS.HOME_LOCATION_ID
} as const;

export const OTHER_LOCATION = {
  fieldName: 'Other Address',
  fieldId: FIELD_IDS.OTHER_LOCATION_ID
} as const;

export const MAILING_LOCATION = {
  fieldName: 'Mailing Address',
  fieldId: FIELD_IDS.MAILING_LOCATION_ID
} as const;

export const CONTACTS_LOCATION_IDS = [
  FIELD_IDS.WORK_LOCATION_ID,
  FIELD_IDS.HOME_LOCATION_ID,
  FIELD_IDS.OTHER_LOCATION_ID,
  FIELD_IDS.MAILING_LOCATION_ID,
];

export const MOBILE_PHONE = {
  fieldName: 'Mobile Phone',
  fieldId: FIELD_IDS.MOBILE_PHONE_ID
} as const;

export const WORK_PHONE = {
  fieldName: 'Work Phone',
  fieldId: FIELD_IDS.WORK_PHONE_ID
} as const;

export const HOME_PHONE = {
  fieldName: 'Home Phone',
  fieldId: FIELD_IDS.HOME_PHONE_ID
} as const;

export const OTHER_PHONE = {
  fieldName: 'Other Phone',
  fieldId: FIELD_IDS.OTHER_PHONE_ID
} as const;

export const CONTACTS_PHONE_IDS = [
  FIELD_IDS.WORK_PHONE_ID,
  FIELD_IDS.HOME_PHONE_ID,
  FIELD_IDS.OTHER_PHONE_ID,
  FIELD_IDS.MOBILE_PHONE_ID
];

export const CONTACT_PREFERENCE = {
  fieldName: 'Contact Preference',
  fieldId: FIELD_IDS.CONTACT_PREFERENCE_ID
} as const;

export const CONTACTS_CONTACT_PREFERENCE_IDS = [
  FIELD_IDS.CONTACT_PREFERENCE_ID
];

export const GROUP_FIELDS_IDS = [
  CONTACTS_PHONE_IDS,
  CONTACTS_EMAIL_IDS,
  CONTACTS_LOCATION_IDS,
  CONTACTS_LINK_IDS,
  CONTACTS_CONTACT_PREFERENCE_IDS
];

export const CONTACT_PREFERENCE_OPTIONS: Array<ValueLabelObj> = [
  {
    value: 'Call',
    label: 'Call'
  },
  {
    value: 'Email',
    label: 'Email'
  },
  {
    value: 'Mail',
    label: 'Mail'
  },
  {
    value: 'Text message',
    label: 'Text message'
  },
];
import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { convert_string_YY_MM_DD_to_MM_DD_YY } from '@/shared/utils/convert_string_YY_MM_DD_to_MM_DD_YY';
import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import Table from '@components/Table';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';
import Triangle, { TRIANGLE_TYPE } from '@components/Triangle';
import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import {
  BALANCE_HISTORY_TYPES
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

import { VALUES_STATUS, VALUE_STATUS_COLOR_MAP } from './data';

import { ValueIndicatorWrapper, ValueTextWrapper, ValueWrapper } from './styles';

import { BalanceHistoryRowViewProps, ValueStatusType } from './types';

export const BalanceHistoryRowView = ({
  control,
  disableDelete,
  disableEdit,
  field,
  fields,
  fieldsNames,
  index,
  onDeleteClick,
  onEditClick,
}: BalanceHistoryRowViewProps) => {

  const [ newBusinessAmountValue, typeValue, valueValue, updatedValue ] = useWatch({
    control,
    name: [
      fieldsNames.newBusinessAmountFieldName,
      fieldsNames.typeFieldName,
      fieldsNames.valueFieldName,
      fieldsNames.updatedFieldName
    ]
  });

  const convertedValue = formatNumberToAmount(valueValue);
  const convertedNewBusinessAmount = typeValue !== BALANCE_HISTORY_TYPES.newBusiness
    ? '-'
    : formatNumberToAmount(newBusinessAmountValue);

  const valueStatus = useMemo<ValueStatusType>(() => {
    const prevSiblingIndex = index + 1;
    if(!fields || prevSiblingIndex >= fields.length) {
      return null;
    }
    const prevSiblingValue = fields[prevSiblingIndex].data.value;

    return Number(field.value) > Number(prevSiblingValue)
      ? VALUES_STATUS.increase
      : VALUES_STATUS.decrease;
  },[fields, field, index]);

  const onSaveClickStub = useCallback(() => {},[]);

  return (
    <>
      <Table.Cell>
        <HFHiddenInput
          control={ control }
          defaultValue={ field.updated }
          name={ fieldsNames.updatedFieldName }
        />
        <HFHiddenInput
          control={ control }
          defaultValue={ field.sort }
          name={ fieldsNames.sortFieldName }
        />
        {
          convert_string_YY_MM_DD_to_MM_DD_YY({
            dateString: updatedValue,
            separatorOut: '/',
          })
        }
      </Table.Cell>
      <Table.Cell>
        <HFHiddenInput
          control={ control }
          defaultValue={ field.value }
          name={ fieldsNames.valueFieldName }
        />
        <ValueWrapper>
          <ValueTextWrapper>
            <Tooltip
              displayOnlyOnOverflow={ true }
              title={ convertedValue }
            >
              <TextEllipsis $maxWidth={ 100 }>
                { convertedValue }
              </TextEllipsis>
            </Tooltip>
          </ValueTextWrapper>
          {
            valueStatus &&
            <ValueIndicatorWrapper>
              <Triangle
                $color={ VALUE_STATUS_COLOR_MAP[valueStatus] }
                $type={
                  valueStatus === VALUES_STATUS.increase
                    ? TRIANGLE_TYPE.TOP
                    : TRIANGLE_TYPE.BOTTOM
                }
              />
            </ValueIndicatorWrapper>
          }
        </ValueWrapper>
      </Table.Cell>
      <Table.Cell>
        <HFHiddenInput
          control={ control }
          defaultValue={ field.type }
          name={ fieldsNames.typeFieldName }
        />
        { typeValue }
      </Table.Cell>
      <Table.Cell>
        <HFHiddenInput
          control={ control }
          defaultValue={ field.newBusinessAmount }
          name={ fieldsNames.newBusinessAmountFieldName }
        />
        <Tooltip
          displayOnlyOnOverflow={ true }
          title={ convertedNewBusinessAmount }
        >
          <TextEllipsis $maxWidth={ 180 }>
            {convertedNewBusinessAmount}
          </TextEllipsis>
        </Tooltip>
        <PopupTable.Actions
          $alignSelf={ 'center' }
          $marginTop={ '0px' }
          disableDelete={ disableDelete }
          disableEdit={ disableEdit }
          isEdit={ false }
          onDeleteClick={ onDeleteClick }
          onEditClick={ onEditClick }
          onSaveClick={ onSaveClickStub }
        />
      </Table.Cell>
    </>
  );
};

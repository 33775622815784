import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: ${({ $width }) => $width ? $width : 'auto' };
  height: 52px;
  padding: 11px;
  border-radius: inherit;
  box-sizing: border-box;
  background: ${COLORS.white};
  box-shadow: 0px 1px 5px 0px #101C5B12;
  cursor: pointer;
`;

export const IndividualContactWrapper = styled.div`
  display: flex;
  gap: 20px;
  background: transparent !important;
  box-shadow: none !important;
`;

export const ContactType = styled.div`
  padding-top: 2px;
  font-size: 10px;
  color: ${COLORS.helpText};
`;

export const ContactInfo = styled.div`
  display: grid;
`;

export const ChevronRightIcon = styled.div`
  margin-left: auto;
  
  & path{
    fill: ${COLORS.helpText};
  }
`;

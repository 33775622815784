import React from 'react';
import { observer } from 'mobx-react';

import { Chart, Header, Tabs } from '@pages/Dashboard/components';

import { Wrapper } from './styles';

export const BusinessSnapshot = observer(() => {

  return (
    <Wrapper>
      <Header/>
      <Tabs/>
      <Chart/>
    </Wrapper>
  );
});

import React from 'react';

import { CustomRadio } from './CustomRadio';

import { Label } from './styles';

import { CustomRadioWithLabelProps } from './types';

export const CustomRadioWithLabel = ({ radioProps, labelProps }: CustomRadioWithLabelProps) => (
  <Label
    control={
      <CustomRadio
        { ...radioProps }
      />
    }
    labelPlacement={ 'end' }
    { ...labelProps }
  />
);

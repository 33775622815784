import styled from 'styled-components';

export const LeftColumnWrapper = styled.div`
  width: 330px;
  margin-right: 84px;
`;

export const RightColumnWrapper = styled.div`
  width: 350px;
`;

import React, { useCallback, useState } from 'react';

import { ItemProps, ToggleWithInnerLabelsProps } from './types';

import { VARIANTS } from './data';

import { ItemWrapper, Wrapper } from './styles';

const Item = ({
  data,
  onChange,
  value,
  $variant
}: ItemProps) => {
  const onChangeWrapper = useCallback(() => {
    onChange(data.value);
  }, [data.value, onChange]);

  return (
    <ItemWrapper
      $isActive={ data.value === value }
      $variant={ $variant }
      onClick={ onChangeWrapper }
    >
      {data.label}
    </ItemWrapper>
  );
};

export const ToggleWithInnerLabels = ({
  options,
  value,
  onChange,
  $variant = VARIANTS.MAIN
}: ToggleWithInnerLabelsProps) => (
  <Wrapper $variant={ $variant }>
    {
      options.map(options => (
        <Item
          $variant={ $variant }
          data={ options }
          key={ options.value }
          onChange={ onChange }
          value={ value }
        />
      ))
    }
  </Wrapper>
);

import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import omit from 'lodash/omit';

import { NotesUtilsStore } from '@modules/NotesAndHistory';

import { LinkedContact } from '@/shared/types/linkedContact';
import { UseFormMethods, InitProps } from './types';
import {
  SalesCyclePopupOnSaveCallback,
  SalesActiveCycleGetByIdResponse,
  SalesActiveCyclePopupSettingsResponse,
  SalesActiveCycleType,
  SalesActiveCycleStatusTypeWithoutActive,
  SalesCycleOnDeleteCallback
} from '@/shared/types/salesActiveCycle';
import { NoteTagItem } from '@/shared/types/tags';
import {
  CloseModal,
  GetNextAppointmentDateResponse,
  TagsBackendResponse,
  ValueLabelObj
} from '@/shared/types/commonTypes';

import { SALES_ACTIVE_CYCLE_FIELD_NAMES } from '@constants/salesActiveCycle';
import { SALES_ACTIVE_POPUP_DEFAULT_VALUES } from '../data';

import {
  getContactNextApptDate,
  getSalesCycleItem,
  getSalesCyclePopupSettings
} from '@services/api/salesPipeline/salesActiveCycle';

import { sourceConverter } from '@/shared/utils/getOptionsUtils';
import { getTags } from '@services/api/tags/tags';

class SalesActivePopupStore {
  closeTrigger: CloseModal | null = null;
  isLoad: boolean = false;
  itemId: SalesActiveCycleType['id'] | null = null;
  linkedContactData: LinkedContact | null  = null;
  nextAppointmentDate: string | null = null;
  notesUtilsStore: NotesUtilsStore;
  onDeleteCallback?: SalesCycleOnDeleteCallback | null = null;
  onLinkedContactDataChangeReaction: IReactionDisposer;
  onSaveCallback: SalesCyclePopupOnSaveCallback | null = null;
  predefinedTags: Array<NoteTagItem> = [];
  quickStatusSubmit: SalesActiveCycleStatusTypeWithoutActive | null = null;
  sourceOptions: Array<ValueLabelObj> = [];
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor() {
    makeAutoObservable(this, {
      init: flow.bound,
      onCancel: action.bound,
      onConfirm: action.bound,
      onDelete: action.bound,
      resetState: action.bound,
    });

    this.notesUtilsStore = new NotesUtilsStore();

    this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
  }

  *init({
    closeTrigger,
    id,
    onDeleteCallback,
    onSaveCallback,
    useFormMethods,
  }: InitProps){
    try {
      this.isLoad = true;

      this.useFormMethods = useFormMethods;
      this.onSaveCallback = onSaveCallback;
      this.onDeleteCallback = onDeleteCallback;
      this.closeTrigger = closeTrigger;

      const settingsResponse: SalesActiveCyclePopupSettingsResponse = yield getSalesCyclePopupSettings();
      this.sourceOptions = sourceConverter(settingsResponse.data.data.source);

      const tagsResp: TagsBackendResponse = yield getTags({
        type: 'Sales Pipe Line Note',
        searchPhrase: ''
      });
      this.predefinedTags = tagsResp.data.data;

      if(id) {
        this.itemId = id;
        this.onLinkedContactDataChangeReaction();

        const itemResponse: SalesActiveCycleGetByIdResponse = yield getSalesCycleItem({ id });
        this.linkedContactData = itemResponse.data.data.contactData;
        this.notesUtilsStore.setCurrentLinkedContact(itemResponse.data.data.contactData);

        this.nextAppointmentDate = itemResponse.data.data.nextAppointmentDate || null;
        this.useFormMethods.reset(
          omit(itemResponse.data.data, ['contactData', 'nextAppointmentDate'])
        );

        this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *onLinkedContactDataChange() {
    if(this.linkedContactData){
      try {
        this.isLoad = true;
        const nextAppointmentDateResponse:GetNextAppointmentDateResponse = yield getContactNextApptDate({
          id: this.linkedContactData.id
        });

        const nextAppointmentDate = nextAppointmentDateResponse.data.data.nextAppointmentDate;

        this.nextAppointmentDate = nextAppointmentDate;

        if(nextAppointmentDate){
          this.useFormMethods.setValue(
            SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus,
            SALES_ACTIVE_POPUP_DEFAULT_VALUES.meetingStatus
          );
        }

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }
    }else {
      this.nextAppointmentDate = null;
    }
  }

  createOnLinkedContactDataReaction() {
    return reaction(
      () => this.linkedContactData,
      () => this.onLinkedContactDataChange()
    );
  }

  onLinkedContactChange = (newLinkedContact: LinkedContact | null) => {
    this.notesUtilsStore.setPrevLinkedContact(this.linkedContactData);
    this.notesUtilsStore.setCurrentLinkedContact(newLinkedContact);

    this.linkedContactData = newLinkedContact;
  };

  onConfirm(status?: SalesActiveCycleStatusTypeWithoutActive) {
    this.useFormMethods.handleSubmit((data) => {
      if(typeof status ==='string'){
        this.quickStatusSubmit = status;
      }

      let calculatedData =  {
        ...data,
        [SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus]: this.quickStatusSubmit || data.salesCycleStatus
      };

      if(this.notesUtilsStore.alertType) {
        calculatedData = {
          ...calculatedData,
          [SALES_ACTIVE_CYCLE_FIELD_NAMES.notes]: this.notesUtilsStore.convertNotesByAlertType(
            calculatedData.notes,
            this.linkedContactData
          )
        };
      } else {
        if(this.notesUtilsStore.detectAndSetAlert(calculatedData.notes, this.linkedContactData)){
          return;
        }
      }

      if(this.onSaveCallback && this.closeTrigger){
        this.onSaveCallback(this.closeTrigger, calculatedData);
        this.quickStatusSubmit = null;
      }
    })();
  }

  onCancel() {
    if(this.notesUtilsStore.alertType){
      this.notesUtilsStore.resetAlertType();
      this.quickStatusSubmit = null;
    } else {
      this.closeTrigger && this.closeTrigger();
    }
  }

  onDelete() {
    if(this.itemId && this.onDeleteCallback) {
      this.closeTrigger && this.closeTrigger();
      this.onDeleteCallback(this.itemId);
    }
  }

  resetState() {
    this.linkedContactData = null;
    this.isLoad = false;
    this.predefinedTags = [];
    this.useFormMethods= {} as UseFormMethods;
    this.sourceOptions= [];
    this.nextAppointmentDate = null;
    this.itemId = null;
    this.onDeleteCallback = null;
    this.onSaveCallback = null;
    this.closeTrigger = null;

    this.notesUtilsStore.resetNotesState();
  }
}

export default SalesActivePopupStore;

import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import BlockButton from '@components/BlockButton';
import ContentCenter from '@components/ContentCenter';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import NoDataItemsView from '@modules/NoDataItemsView';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import Preloader from '@components/Preloader';
import Table from '@components/Table';
import { PlusWithCircle } from '@/assets';
import { RelatedContactAddEditPopup, RelatedContactDeleteConfirmation, RelatedContactRow } from './components';

import {
  TabContentWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { MODAL_TYPE } from '@constants/modalTypes';
import { BUTTON_STYLES, COLUMNS, LINK_NEW_CONTACT_LABEL } from './data';
import { CONTACT_DETAILS_TAB, INDIVIDUAL_PROFILE_LINKS } from '@constants/routes/contacts';

import { CommonRelatedContactsProps, DeleteHandler, OnAddEdit, OnRowAction } from './types';


export const CommonRelatedContacts = observer(({
  contactId
}:CommonRelatedContactsProps) => {
  const { isLoad, currentContact } = useStore().ContactDetailsStore;
  const relatedContactsStore = useStore().RelatedContactsStore;
  const routerStore = useStore().RouterStore;
  const modalStore = useStore().ModalStore;
  const { table } = relatedContactsStore;

  const onAddEditClick:OnAddEdit = useCallback((item, deleteHandler) => {
    modalStore.openModal({
      component: RelatedContactAddEditPopup,
      modalProps: {
        dataToEdit: item,
        deleteHandler
      },
      modalType: MODAL_TYPE.ADD_RELATED_CONTACT
    });
  },[modalStore]);

  const deleteHandler: DeleteHandler = useCallback((id, relationType) => {
    const isContactInHouseHold = currentContact && 'householderContacts' in currentContact &&
      !isEmpty(currentContact.householderContacts);

    modalStore.openModal({
      component: RelatedContactDeleteConfirmation,
      modalProps: {
        id,
        relationType,
        isContactInHouseHold
      },
      modalType: MODAL_TYPE.CONFIRM_RELATED_CONTACT_DELETE
    });
  },[modalStore, currentContact]);

  const onAddClick = useCallback(() => onAddEditClick(), [onAddEditClick]);

  const onDelete = useCallback<OnRowAction>((item) => deleteHandler(item.id, item.relationType), [deleteHandler]);

  const onEdit = useCallback<OnRowAction>((item) => {
    onAddEditClick(item, deleteHandler);
  },[onAddEditClick, deleteHandler]);

  const onView = useCallback<OnRowAction>((item) => {
    routerStore.moveToContact({
      id: item.clientContact.id,
      contactType: item.clientContact.type,
      tab: CONTACT_DETAILS_TAB.overview,
    });
  },[routerStore]);

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  useEffect(() => {
    relatedContactsStore.init(contactId);
    return () => relatedContactsStore.reset();
    // eslint-disable-next-line
  },[]);

  return (
    <TabContentWrapper isFluid={ true }>
      {
        isLoad &&
        <Preloader />
      }
      <TabContentWrapper.Item $withPadding={ false }>
        <PreTableWrapper>
          <BlockButton
            onClick={ onAddClick }
            startIcon={ <PlusWithCircle /> }
            style={ BUTTON_STYLES }
          >
            {LINK_NEW_CONTACT_LABEL}
          </BlockButton>
        </PreTableWrapper>

        <Table>
          <Table.Container triggerScrollToTop={ table.currentPage }>
            <Table.Header
              columns={ COLUMNS }
              onSortChangeCb={ table.setMultipleSorting }
              sortingState={ table.multipleSorting }
            />
            <Table.Body>
              {
                table.items.length > 0 && table.items.map(item => (
                  <RelatedContactRow
                    data={ item }
                    key={ item.id }
                    onDelete={ onDelete }
                    onEdit={ onEdit }
                    onOutOfSightClick={ onOutOfSightClick }
                    onView={ onView }
                  />
                ))
              }
            </Table.Body>
            {
              !isLoad && table.items.length === 0 && (
                <ContentCenter>
                  <NoDataItemsView
                    addActionButtonClickHandler={ onAddClick }
                    addActionButtonLabel={ LINK_NEW_CONTACT_LABEL }
                    withAction={ true }
                  />
                </ContentCenter>
              )}
          </Table.Container>
          <Table.Footer
            currentPage={ table.currentPage }
            onPageChangeCb={ table.setCurrentPage }
            pagesCount={ table.totalPages }
          >
            <Table.FooterCountInfo
              from={ table.itemsFrom }
              to={ table.itemsTo }
              total={ table.totalItems }
            />
          </Table.Footer>
        </Table>
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

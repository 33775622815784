import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStore } from '@store';
import { useDidUpdate } from '@customHooks';

import { CRITERIA_FIELD_NAMES, INIT_CRITERION_WITHOUT_VALUE } from '@constants/lists';
import { CRITERIA_CONFIG } from '@constants/listsCriteria';

import { CriteriaFormWrapper } from '@/shared/types/lists';
import { ValueLabelObj } from '@/shared/types/commonTypes';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { getAndSetValueForReset, getOptionsFromBackend } from './utils';


type Props = {
  namePath: string
}

type Return = {
  names: {
    type: string
    field: string
    operand: string
    values: string
  }
  fieldSettings: {
    valueOptions: Array<ValueLabelObj>
    isMultiple: boolean
    maxChars: number
  }
  fieldOptions: Array<ValueLabelObj>
  operandOptions: Array<ValueLabelObj>
  helperText: string
  isFieldDisabled:  boolean
  isOperandDisabled: boolean
  fieldValue: any
  operandValue: any
}

const getName = (path: string, chunk: string) => `${path}.${chunk}`;

export const useListCriteriaFieldsRowState = ({
  namePath
}: Props): Return => {
  const listCriteriaStore = useStore().ListsStore.ListCriteriaStore;
  const { control, setValue, clearErrors } = useFormContext<CriteriaFormWrapper>();

  const names = useMemo(() => {
    return {
      type: getName(namePath, CRITERIA_FIELD_NAMES.name),
      field: getName(namePath, CRITERIA_FIELD_NAMES.option),
      operand: getName(namePath, CRITERIA_FIELD_NAMES.optionValueFilter),
      values: getName(namePath, CRITERIA_FIELD_NAMES.value)
    };
  }, [namePath]);

  //@ts-ignore
  const [typeValue, fieldValue, operandValue ] = useWatch({
    control,
    //@ts-ignore  name always `root.${number}.${number}.${fieldName}``
    name: [names.type, names.field, names.operand,]
  });

  // here wrapped field values to prevent state race
  const [controlledFieldValue, setControlledFieldValue] = useState<string>(fieldValue);
  const [controlledOperandFieldValue, setControlledOperandFieldValue] = useState<string>(operandValue);


  const fieldOptions:Array<ValueLabelObj> = useMemo(() => {
    const fieldsOptionByType = CRITERIA_CONFIG?.[typeValue];
    if(!fieldsOptionByType){
      return [];
    }
    return Object.values(fieldsOptionByType).map((valueItem) => ({
      value: valueItem.fieldValue,
      label: valueItem.fieldLabel
    }));
  }, [typeValue]);

  const operandOptions:Array<ValueLabelObj> = useMemo(() => {
    const fieldsOptionByType= CRITERIA_CONFIG?.[typeValue];
    if(!fieldsOptionByType){
      return [];
    }
    const operandOptions = fieldsOptionByType?.[fieldValue];
    return operandOptions ? optionsFromValue(operandOptions.operandOptions) : [];
  }, [typeValue, fieldValue]);

  const helperText = useMemo(() => {
    const source = CRITERIA_CONFIG?.[typeValue]?.[fieldValue];
    if(!source){
      return '';
    }

    return source.applicability.join(', ');
  }, [typeValue, fieldValue]);

  const fieldSettings = useMemo(() => {
    const source = CRITERIA_CONFIG?.[typeValue]?.[fieldValue];

    return {
      valueOptions: source?.valueOptions || getOptionsFromBackend(listCriteriaStore, fieldValue),
      isMultiple: Boolean(source?.isMultiple),
      maxChars: source?.maxChars || 0
    };
    // eslint-disable-next-line
  }, [typeValue, fieldValue]);

  useDidUpdate(async () => {
    // @ts-ignore
    await clearErrors([names.field, names.operand, names.values ]);
    setControlledFieldValue(INIT_CRITERION_WITHOUT_VALUE.option);
    setControlledOperandFieldValue(INIT_CRITERION_WITHOUT_VALUE.optionValueFilter);

    setValue(names.field as any, INIT_CRITERION_WITHOUT_VALUE.option);
    setValue(names.operand as any, INIT_CRITERION_WITHOUT_VALUE.optionValueFilter);
  },[typeValue]);

  useDidUpdate(async () => {
    // @ts-ignore
    await clearErrors([ names.operand, names.values ]);
    setValue(names.operand as any, INIT_CRITERION_WITHOUT_VALUE.optionValueFilter);

    setControlledFieldValue(fieldValue);
    setControlledOperandFieldValue(INIT_CRITERION_WITHOUT_VALUE.optionValueFilter);

    getAndSetValueForReset(setValue, names.values, fieldValue, INIT_CRITERION_WITHOUT_VALUE.optionValueFilter);
  },[fieldValue]);

  useDidUpdate(async () => {
    // @ts-ignore
    await clearErrors([ names.values ]);

    setControlledOperandFieldValue(operandValue);

    getAndSetValueForReset(setValue, names.values, fieldValue, operandValue);
  },[operandValue]);

  return {
    fieldOptions,
    helperText,
    isFieldDisabled: !typeValue,
    isOperandDisabled: !controlledFieldValue,
    names,
    operandOptions,
    fieldSettings,
    fieldValue: controlledFieldValue,
    operandValue: controlledOperandFieldValue,
  };
};

import React from 'react';
import Box from '@material-ui/core/Box';

import { BaseBlockWrapperProps } from './types';
import { COLORS } from '@constants/colors';
import { BOX_SHADOW } from '@constants/style';

export const BaseBlockWrapper = ({ children, ...rest }: BaseBlockWrapperProps) => (
  <Box
    { ...rest }
    bgcolor={ COLORS.white }
    borderRadius={ '10px' }
    boxShadow={ BOX_SHADOW }
  >
    { children }
  </Box>
);

import { ColumnDimensions, ColumnType } from '@components/Table/components/Header/types';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES } from '@constants/salesActiveCycle';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'fullName',
    label: 'Linked Contact',
    style: {
      width: '23%'
    },
    sortable: true,
  },
  {
    id: SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus,
    label: 'Sales Cycle Status',
    style: {
      width: '150px'
    },
    separator: true,
    sortable: true,
  },
  {
    id: SALES_ACTIVE_CYCLE_FIELD_NAMES.stage,
    label: 'Stage',
    style: {
      width: '10%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus,
    label: 'Meeting Status',
    style: {
      width: '170px'
    },
    separator: true,
  },
  {
    id: SALES_ACTIVE_CYCLE_FIELD_NAMES.amount,
    label: 'Amount',
    style: {
      width: '8%'
    },
    separator: true,
    sortable: true,
  },
  //currently hiden
  // {
  //   id: 'tickets',
  //   label: '# Tickets',
  //     style: {
  //      width: '11%'
  //     },
  //     ...TICKETS_COLUMN_DIMENSIONS.style,
  //   },
  //   sortable: true,
  // },
  {
    id: SALES_ACTIVE_CYCLE_FIELD_NAMES.keyNote,
    label: 'Key Note / Next Step',
    style: {
      width: '41%'
    },
    separator: true,
  },
];

import { LISTS_TYPES, TYPE_FIELDS } from '@constants/lists';

import { GetDefaultState } from './types';



export const getDefaultState:GetDefaultState = ({
  parentId,
  listOwner,
})  => ({
  description: '',
  id: null,
  listOwner: listOwner,
  name: '',
  parentId: parentId,
  splitHousehold: 0,
  typeField: TYPE_FIELDS.List,
  typeList: LISTS_TYPES.Manual,
});

export const LEFT_LABEL_WIDTH = 90;
export const RIGHT_LABEL_WIDTH = 145;

import styled from 'styled-components';
import { ShowMoreListStyleProps } from '@components/RowFilter/types';

export const ItemsWrapper = styled.div<ShowMoreListStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 152px;
  padding: 10px;
  
  & > * {
    margin-bottom: ${({ $showMoreListMarginBottom }) => {
    return $showMoreListMarginBottom ? $showMoreListMarginBottom : '10px';
  }};
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const IconWrapper = styled.div`
  margin-left: 8px;
  margin-top: -1px;
  
  & > svg {
    fill: currentColor;
    
    & > path {
      fill: currentColor;
    }
  }
`;

import React, { CSSProperties } from 'react';

import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import { AppPage } from '@modules/AppPage';

import { COLORS } from '@constants/colors';

import { ButtonWrapper } from './styles';

import {
  ListsRowFolderWithLinkProps
} from '@pages/NewContacts/subpages/Lists/subpages/AllLists/components/ListsTable/components/ListsTableRow/types';

const ROW_STYLES: CSSProperties = {
  background: COLORS.white,
  zIndex: 10
};

export const ListsRowFolderWithLink = ({
  onLinkClick,
  name
}: ListsRowFolderWithLinkProps) => (
  <Table.Row
    $preventHover={ true }
    style={ ROW_STYLES }
  >
    <Table.Cell>
      <ButtonWrapper>
        <AppPage.AppBackButton
          onClick={ onLinkClick }
        />
      </ButtonWrapper>
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell >
      <TextWithTooltip text={ name } />
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
  </Table.Row>
);

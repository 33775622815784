/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {v4 as uuidv4} from "uuid";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";

import {
	NumberOne,
	NumberTwo,
	NumberThree,
	NumberFour,
	CheckedRadio,
	IndetermineRadio,
	UnCheckedRadio
} from '@/assets';


const NUMBERS = [
	null,
	NumberOne,
	NumberTwo,
	NumberThree,
	NumberFour,
];


const useStyles = makeStyles( (theme) => ({
	navigationPanel: {
		marginLeft: 40,
		color: theme.palette.custom.lightGray,
		cursor: 'pointer',

		'& .navigationLinkPanel': {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-between',

			'& .stageLink': {
				height: 38,
				display: 'flex',
				flexWrap: 'wrap',

				'& .linkText': {
					marginLeft: 6,
					height: 38,
					fontSize: 12,
					lineHeight: '150%',
				},

				'&.singleLine .linkText': {
					paddingTop: 9,
				},

				'&.lastPage': {
					display: 'flex',
					justifyContent: 'space-between',
				},
			},
			'& .active': {
				color: theme.palette.custom.mainText,
			},
		},

		'& .navigationProgressPanel': {
			width: 684,
			height: 16,
			marginTop: 5,
			marginLeft: 3,
			display: 'flex',
			flexWrap: 'wrap',
			'& .progressLine': {
				height: 16,
				'& .colorLine': {
					marginTop: 7,
					width: '100%',
					height: 2,
					border: 0,
					backgroundColor: theme.palette.primary.main,
					'&.grayLine': {
						backgroundColor: theme.palette.custom.borders,
					},
					'&.gradient': {
						background:
							`linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.custom.borders})`,
						backgroundColor: 'inherit',
					},
				},
			},
		},
	},
	number: {
		width: 18,
		height: 38,
		fill: '#C6C7CF'
	},
	numberActive: {
		fill: '#4E4E61'
	}
}));


export default function StageNavigationPanel(props) {
	const classes = useStyles();
	const {
		stageNumber: propsStageNumber,
		stagesData: propsStagesData,
		onChangeStageNumber,
		popupType: propsPopupType,
		lastStageWidth,
	} = props;
	const [stageNumber, setStageNumber] = React.useState(propsStageNumber);
	const [popupType, setPopupType] = React.useState(propsPopupType);

	const stagesData = popupType === ''
		? propsStagesData
		: propsStagesData[popupType];

	const stagesCount = stagesData.length;
	const stageNumbers = new Array(stagesCount).fill(0).map((_, ind) => ind + 1);

	React.useEffect(() => {
		const {stageNumber: propsStageNumber, popupType: propsPopupType} = props;
		if (propsStageNumber !== stageNumber) {
			setStageNumber(propsStageNumber);
		}
		if (propsPopupType !== popupType) {
			setPopupType(propsPopupType);
		}
	});

	const handleChangeStageNumber = (newStageNumber) => {
		setStageNumber(newStageNumber);
		onChangeStageNumber(newStageNumber);
	};

	const navigationLinkWidth = 717;
	const circleWidth = 14;
	const stageWidth = (navigationLinkWidth - lastStageWidth - circleWidth * stagesCount) / (stagesCount - 1);

	return (
		<div className={classes.navigationPanel}>
			<div className={'navigationLinkPanel'}>
				{
					stagesData.map((linkText, ind) => {
						const currStageNumber = ind + 1;
						const Number = NUMBERS[currStageNumber];
						return (
							<div
								key={uuidv4()}
								className={clsx('stageLink', {
									lastPage: currStageNumber === stagesCount,
									singleLine: linkText.length === 1,
									active: stageNumber === currStageNumber,
								})}
								style={currStageNumber === stagesCount
									? {
										width: lastStageWidth,
									}
									: {
										width: stageWidth,
									}
								}
								onClick={() => handleChangeStageNumber(currStageNumber)}
							>
								<Number
									className={
									clsx(
										classes.number,
										{
											[classes.numberActive]: stageNumber === currStageNumber
										}
									)
									}
								/>
								{linkText.length === 1 &&
									<div className={'linkText'}>{linkText[0]}</div>
								}
								{linkText.length > 1 &&
									<div className={'linkText'}>{linkText[0]}<br/>{linkText[1]}</div>
								}
							</div>
						)
					})
				}
			</div>

			<div
				className={'navigationProgressPanel'}
			>
				{stageNumbers.map(pointNumber => (
					<div
						key={uuidv4()}
						style={{
							display: 'flex',
							flexWrap: 'wrap',
						}}
					>
						{pointNumber < stageNumber
							? <CheckedRadio
								onClick={() => handleChangeStageNumber(pointNumber)}
							/>
							: pointNumber === stageNumber
								? <IndetermineRadio
									onClick={() => handleChangeStageNumber(pointNumber)}
								/>
								: <UnCheckedRadio
									onClick={() => handleChangeStageNumber(pointNumber)}
								/>
						}
						{pointNumber < stagesCount &&
						<div
							className={clsx('progressLine', {
								gradient: pointNumber === stageNumber,
							})}
							style={{
								width: stageWidth,
							}}
							onClick={() => handleChangeStageNumber(pointNumber)}
						>
							{pointNumber === stageNumber
								? <div
									className={'colorLine gradient'}
								> </div>
								: <div
									className={clsx('colorLine', {
										grayLine: pointNumber > stageNumber
									})}
								> </div>
							}
						</div>
						}
					</div>
				))}
			</div>
		</div>
	);
}

StageNavigationPanel.propTypes = {
	stageNumber: PropTypes.number,
	popupType: PropTypes.string,
	stagesData: PropTypes.object.isRequired,
	lastStageWidth: PropTypes.number,
	onChangeStageNumber: PropTypes.func,
};

StageNavigationPanel.defaultProps = {
	stageNumber: 1,
	popupType: '',
	lastStageWidth: 50,
	onChangeStageNumber: () => {},
};

import styled, { css } from 'styled-components';

import { TextEllipsisWrapperProps } from './types';

const getValue = (value: string | number) => typeof value  === 'number' ? `${value}px` : value;

export const TextEllipsis = styled.div<TextEllipsisWrapperProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ $fontSize }) => $fontSize && css`
    font-size:  ${getValue($fontSize)};
  `}

  ${({ $fontColor }) => $fontColor && css`
    color: ${$fontColor};
  `}

  ${({ $maxWidth }) => $maxWidth && css`
    max-width:  ${getValue($maxWidth)};
  `}

  ${({ $paddingRight }) => $paddingRight && css`
    padding-right: ${getValue($paddingRight)};
  `}

  ${({ $paddingLeft }) => $paddingLeft && css`
    padding-left: ${getValue($paddingLeft)};
  `}

  ${({ $marginRight }) => $marginRight && css`
    margin-right:  ${getValue($marginRight)};
  `}

  ${({ $marginLeft }) => $marginLeft && css`
    margin-left:  ${getValue($marginLeft)};
  `}

  ${({ $lineHeight }) => $lineHeight && css`
    line-height:  ${getValue($lineHeight)};
  `}

  ${({ onClick }) => onClick && css`
    &:hover {
      text-decoration: underline;
    }
  `}
`;

import { ColumnType } from '@components/Table/components/Header/types';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '26%',
    },
    sortable: true,
  },
  {
    id: 'type',
    label: 'Type',
    style: {
      width: '23%'
    },
    separator: true,
  },
  {
    id: 'usedFor',
    label: 'Used For',
    style: {
      width: '23%'
    },
    separator: true,
  },
  {
    id: 'options',
    label: 'Options',
    style: {
      width: '28%',
    },
    separator: true,
  },
];

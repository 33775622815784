import React, { useCallback, useEffect } from 'react';

import { POPUP_RADIO_VALUES } from '@constants/common';
import { RADIO_BUTTONS } from './data';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import RadioGroup, { useRadioBtn } from '@components/RadioGroup';

import { Wrapper } from './styles';

import { TodoDeleteConfirmationPopupProps } from './types';

export const TodoDeleteConfirmationPopup = ({
  closeModal,
  modalProps: {
    id,
    isRequirring,
    onCloseAction,
    onConfirm
  }
}: TodoDeleteConfirmationPopupProps) => {
  const { radioValue, onChange } = useRadioBtn(POPUP_RADIO_VALUES.current);

  const onCloseWrapper = useCallback(() => {
    closeModal();
    if(onCloseAction) {
      onCloseAction();
    }
  }, [closeModal, onCloseAction]);

  const onConfirmClick = useCallback(() => {
    onCloseWrapper();
    onConfirm([{
      id,
      withRecurring: POPUP_RADIO_VALUES.all === radioValue,
    }]);
  }, [closeModal, onConfirm, id, radioValue, onCloseWrapper]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'No' }
      confirmLabel={ 'Yes' }
      isOpen={ true }
      message={ 'Are you sure you want to delete task?' }
      onCancelClick={ onCloseWrapper }
      onClose={ onCloseWrapper }
      onConfirmClick={ onConfirmClick }
    >
      {
        <Wrapper>
          {
            isRequirring &&
            // @ts-ignore TS migrate
            <RadioGroup
              onChange={ onChange }
              options={ RADIO_BUTTONS }
              value={ radioValue }
            />
          }
        </Wrapper>
      }
    </ConfirmationPopup>

  );
};

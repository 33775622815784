import styled, { css } from 'styled-components';

import { LabelProps, WrapperProps } from './types';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  
  ${({ $paddingBottom }) => $paddingBottom && css`
    padding-bottom: ${$paddingBottom}px;
  `}

  ${({ $paddingTop }) => $paddingTop && css`
    padding-top: ${$paddingTop}px;
  `}
`;

export const Delimiter = styled.div`
  position: relative;
  bottom: 50%;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.borders};
`;


export const Label = styled.div<LabelProps>`
  position: absolute;
  left: 10px;
  top: 50%;
  padding: 3px 10px;
  z-index: 2;
  transform: translateY(-50%);
  background-color: ${COLORS.white};
  color: ${COLORS.tagText};
  
  ${({ $fontSize }) => $fontSize && css`
    font-size: ${$fontSize}px;
  `}
`;


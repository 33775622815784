import React from 'react';
import { Link } from 'react-router-dom';

import PageHeaderWrapper from '@components/BasicLayoutComponents/PageHeaderWrapper';
import { ContactDetailsPageHeaderProps } from './types';

import { TabWrapper } from './styles';
import CustomTabs from '@components/CustomTabs';
import {
  CONTACT_DETAILS_TABS
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsPageHeader/data';
export const ContactDetailsPageHeader = ({
  contactId,
  contactType,
  currentTab,
}: ContactDetailsPageHeaderProps) => {

  return (
    <PageHeaderWrapper $height={ '56px' }>
      <TabWrapper>
        <CustomTabs
          $height={ '56px' }
          value={ currentTab }
        >
          {
            CONTACT_DETAILS_TABS.map((tab, index) => {
              const Icon = tab.icon as React.ElementType;
              return (
                <CustomTabs.Tab
                  $minHeight={ '56px' }
                  component={ Link }
                  icon={ <Icon /> }
                  key={ tab.value }
                  label={ tab.label }
                  replace={ true }
                  to={ tab.getLink(contactId, contactType) }
                  value={ tab.value }
                />
              );
            })
          }
        </CustomTabs>
      </TabWrapper>
    </PageHeaderWrapper>
  );
};
import { ColumnType } from '@/components/Table/components/Header/types';

const COMMON_COLUMN_PROPS: Omit<ColumnType, 'id' | 'label'> = {
  separator: true,
  padding: 'none',
};

const NAME_COLUMN: ColumnType = {
  id: 'name',
  label: 'User',
  style: {
    minWidth: '280px',
  },
  padding: 'none',
  sortable: true,
};

const EMAIL_COLUMN: ColumnType = {
  id: 'email',
  label: 'Username',
  style: {
    minWidth: '150px',
  },
  sortable: true,
  ...COMMON_COLUMN_PROPS
};

const EMAILVERIFIED_COLUMN: ColumnType = {
  id: 'emailVerified',
  label: 'Verification Status',
  style: {
    minWidth: '155px',
    maxWidth: '200px',
  },
  ...COMMON_COLUMN_PROPS
};

const USER_ROLE_COLUMN: ColumnType = {
  id: 'roles',
  label: 'User Role',
  style: {
    minWidth: '120px',
  },
  sortable: true,
  ...COMMON_COLUMN_PROPS
};

const USER_STATUS_COLUMN: ColumnType = {
  id: 'status',
  label: 'Status',
  style: {
    minWidth: '180px',
  },
  sortable: true,
  ...COMMON_COLUMN_PROPS
};

export const USER_COLUMNS = [
  NAME_COLUMN,
  EMAIL_COLUMN,
  EMAILVERIFIED_COLUMN,
  USER_ROLE_COLUMN,
  USER_STATUS_COLUMN
];

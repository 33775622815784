import {
  addMonths,
  addWeeks,
  addYears,
  format,
} from 'date-fns';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import {
  ALL_OVERDUE_AND_DUE_OPTION,
  ALL_TASKS_OPTION,
  DUE_TODAY_OPTION,
  DUE_WITHIN_ONE_MONTH_OPTION,
  DUE_WITHIN_ONE_WEEK_OPTION,
  DUE_WITHIN_ONE_YEAR_OPTION,
  NO_DUE_DATE,
  OVERDUE_AND_DUE_TODAY_OPTION,
  OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION,
  TODOS_FILTER_NAMES
} from '@constants/todosData';

export const getOverdueFilterParamsObject = (
  value: string
): {
  overdue?: boolean,
  startDate?: string,
  endDate?: string,
} => {
  if(value === ALL_TASKS_OPTION.value) {
    return {};
  }

  if(value === ALL_OVERDUE_AND_DUE_OPTION.value) {
    return {
      [TODOS_FILTER_NAMES.overdue]: true
    };
  }

  if(value === OVERDUE_AND_DUE_TODAY_OPTION.value) {
    return {
      [TODOS_FILTER_NAMES.overdue]: true,
      [TODOS_FILTER_NAMES.endDate]: getFormatedDateNow()
    };
  }

  if(value === OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION.value) {
    const todayWithOneWeek = addWeeks(new Date(), 1);
    return {
      [TODOS_FILTER_NAMES.overdue]: true,
      [TODOS_FILTER_NAMES.endDate]: format(todayWithOneWeek, YEAR_MONTH_DAY)
    };
  }

  if(value === DUE_TODAY_OPTION.value) {
    const formattedTodayDate = getFormatedDateNow();
    return {
      [TODOS_FILTER_NAMES.startDate]: formattedTodayDate,
      [TODOS_FILTER_NAMES.endDate]: formattedTodayDate,
    };
  }

  if(value === DUE_WITHIN_ONE_WEEK_OPTION.value) {
    const todayWithOneWeek = addWeeks(new Date(), 1);
    return {
      [TODOS_FILTER_NAMES.startDate]: getFormatedDateNow(),
      [TODOS_FILTER_NAMES.endDate]: format(todayWithOneWeek, YEAR_MONTH_DAY),
    };
  }

  if(value === DUE_WITHIN_ONE_MONTH_OPTION.value) {
    const todayWithOneMonth = addMonths(new Date(), 1);
    return {
      [TODOS_FILTER_NAMES.startDate]: getFormatedDateNow(),
      [TODOS_FILTER_NAMES.endDate]: format(todayWithOneMonth, YEAR_MONTH_DAY),
    };
  }

  if(value === DUE_WITHIN_ONE_YEAR_OPTION.value) {
    const todayWithOneYear = addYears(new Date(), 1);
    return {
      [TODOS_FILTER_NAMES.startDate]: getFormatedDateNow(),
      [TODOS_FILTER_NAMES.endDate]: format(todayWithOneYear, YEAR_MONTH_DAY),
    };
  }

  if(value === NO_DUE_DATE.value) {
    return {
      [TODOS_FILTER_NAMES.overdue]: false
    };
  }

  return {};
};


export const NAME_TO_USE_RADIO_OPTIONS = [
  {
    value: 'First & Last Name',
    label: 'First & Last Name'
  },
  {
    value: 'Nickname',
    label: 'Nickname'
  }
];

import React, { useMemo } from 'react';

import zxcvbn from 'zxcvbn';

import { PASSWORD_STRENGTH_LEVELS } from './data';

import {
  PasswordStrengthLabel,
  PasswordStrengthProgress,
  WrapperPasswordStrength,
} from './styles';

import { PasswordStrengthMeterProps } from './types';

export const PasswordStrengthMeter = ({
  password
}:PasswordStrengthMeterProps) => {

  const testedResult = useMemo(() => zxcvbn(password), [ password ]);

  const createPasswordLabel = useMemo(() => PASSWORD_STRENGTH_LEVELS[testedResult.score],[ testedResult.score ]);

  return (
    <WrapperPasswordStrength>
      <PasswordStrengthProgress
        max='4'
        passwordLabel={ createPasswordLabel }
        value={ testedResult.score }
      />
      <br />
      <PasswordStrengthLabel
        passwordLabel={ createPasswordLabel }
      >
        { password && createPasswordLabel }
      </PasswordStrengthLabel>
    </WrapperPasswordStrength>
  );
};
import styled, { css } from 'styled-components';
import { ViewSwitcherItemWrapperProp } from './types';
import { COLORS } from '@constants/colors';

export const ViewSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewSwitcherItemWrapper = styled.div<ViewSwitcherItemWrapperProp>`
  display: flex;
  align-items: center;
  margin-right: 10px;

  color: ${COLORS.grey};
  
  &:last-child{
    margin-right: 0;
  }
  
  &:hover{ 
    cursor: pointer;
    color: ${COLORS.orange};
  }
  
  ${({ $isActive }) => $isActive && css`
    pointer-events: none;
    color: ${COLORS.primaryText};
  `}

  & svg path {
    fill: currentColor;
  }
`;

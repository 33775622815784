/* eslint-disable */
import { CORE_CLASSIFICATIONS } from '@constants/common';

import { GeneralClassification, ClientClassification, LeadClassification, ProspectClassification } from '@/assets';
import ListItemIcon from "@material-ui/core/ListItemIcon";

export default function CoreClassificationIcon(props) {
  const { classificationType, className } = props;
  let CoreIcon;
  switch (classificationType) {
  case CORE_CLASSIFICATIONS.CLIENT:
    CoreIcon = ClientClassification;
    break;
  case CORE_CLASSIFICATIONS.LEAD:
    CoreIcon = LeadClassification;
    break;
  case CORE_CLASSIFICATIONS.PROSPECT:
    CoreIcon = ProspectClassification;
    break;
  case CORE_CLASSIFICATIONS.GENERAL:
    CoreIcon = GeneralClassification;
    break;
  default:
    CoreIcon = GeneralClassification;
    break;
  }
  return (
    <ListItemIcon className={ className }>
      <CoreIcon />
    </ListItemIcon>
  );
}

import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import {
  clearPersistedStore,
  hydrateStore,
  makePersistable,
  pausePersisting,
  startPersisting
} from 'mobx-persist-store';

import axiosApi from '@services/api/axiosApi';

import { MODAL_TYPE } from '@constants/modalTypes';
import { ROUTES } from '@constants/routes';
import { SESSION_WORKER_MESSAGE_TYPES } from '@constants/sharedWorker';
import { Store } from '../store';

import LogoutPopupWithTimer from '@modules/LogoutPopupWithTimer';

const sharedWorker = new SharedWorker(new URL('./sharedWorker.ts', import.meta.url));

export class SessionStore {
  coreStore: Store;
  duration: number | null = null;
  expiredTime: number | null = null;
  onDurationChange: IReactionDisposer;

  constructor(coreStore: Store) {
    this.coreStore = coreStore;

    makeAutoObservable(this, {});

    makePersistable(this, {
      name: 'session',
      properties: ['duration', 'expiredTime'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.useInterceptor();

    this.onDurationChange = this.createOnDurationChangeReaction();
    this.storageEvent();
    sharedWorker.port.addEventListener('message', ({ data }) => {
      switch (data.type) {
      case SESSION_WORKER_MESSAGE_TYPES.lastMinuteBeforeLogout:
        this.showLogoutPopup();
        break;
      case SESSION_WORKER_MESSAGE_TYPES.logout:
        this.logout();
        break;
      }
    });
    sharedWorker.port.start();
  }

  createOnDurationChangeReaction() {
    return reaction(
      () => this.duration,
      () => this.updateExpiredTime()
    );
  }

  //This method is watching for persist-store changes in inactive tabs
  storageEvent(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if(event.key === 'settings' && (event.oldValue !== event.newValue) && !event.oldValue) {
        this.coreStore.RouterStore.customPush(ROUTES.dashboard);
        // TODO
        window.location.reload();
        this.coreStore.RouterStore.customPush(ROUTES.login);
      } 
      if(event.key === 'auth' && !event.newValue) {
        this.coreStore.ModalStore.closeAllModals();
        this.coreStore.RouterStore.customPush(ROUTES.login);
      }
      if(event.key === 'session' && event.newValue !== null) {
        this.hydrateStore();
        this.coreStore.ModalStore.closeModal(MODAL_TYPE.LOGOUT_POPUP);
      }
    });
  }

  async hydrateStore() {
    await hydrateStore(this);
  }

  updateExpiredTime(): void {
    if(this.duration) {
      this.expiredTime = this.duration + Date.now();
      sharedWorker.port.postMessage({
        type: SESSION_WORKER_MESSAGE_TYPES.updateExpiredTime,
        time: this.expiredTime
      });
    }
  }

  getExpiredTime(): number {
    return this.expiredTime ?? 0;
  }

  setDuration(duration: string) {
    this.duration = (parseInt(duration, 10) | 0) * 1000;
  }

  private useInterceptor(): void {
    axiosApi.interceptors.request.use((config) => {
      this.updateExpiredTime();
      return config;
    });
  }

  startSession(): void {
    if(this.coreStore.AuthStore.token) {
      this.startTimer();
    }
  }

  startTimer(): void {
    sharedWorker.port.postMessage({ type: SESSION_WORKER_MESSAGE_TYPES.start });
  }

  stopTimer(): void {
    sharedWorker.port.postMessage({ type: SESSION_WORKER_MESSAGE_TYPES.stop });
  }

  private showLogoutPopup(): void {
    this.stopTimer();
    this.coreStore.ModalStore.openModal({
      modalType: MODAL_TYPE.LOGOUT_POPUP,
      component: LogoutPopupWithTimer,
      modalProps: {}
    });
  }

  private async logout(): Promise<void> {
    this.stopTimer();
    await this.coreStore.AuthStore.logout();
    this.coreStore.RouterStore.customPush(ROUTES.login);
  }

  async endSession(): Promise<void> {
    pausePersisting(this);
    this.duration = null;
    this.expiredTime = null;
    startPersisting(this);
    await clearPersistedStore(this);
  }
}

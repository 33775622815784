import React, { useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { SalesActiveCycleKanbanColumn, SalesActiveCycleKanbanTicket } from './components';
import LimitAccessPopup from '@modules/LimitAccessPopup';

import { COLUMNS_CONFIG } from './data';
import { MODAL_TYPE } from '@constants/modalTypes';

import { Wrapper } from './styles';

import { SalesActiveCycleKanbanProps } from './types';

export const SalesActiveCycleKanban = observer(({
  onEdit,
  onDelete,
}: SalesActiveCycleKanbanProps) => {
  const modalStore = useStore().ModalStore;
  const { isLoad } = useStore().SalesCycleStore;
  const {
    resetStore,
    stageColumns,
    onDrop,
    init,
    updateFlagsSingle
  } = useStore().SalesCycleStore.SalesActiveCycleKanban;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  useEffect(() => {
    init();
    return resetStore;
  }, [init, resetStore ]);

  return (
    <Wrapper>
      <DndProvider backend={ HTML5Backend }>
        {
          COLUMNS_CONFIG.map((item) => (
            <SalesActiveCycleKanbanColumn
              key={ item.value }
              label={ item.label }
              onDrop={ onDrop }
              value={ item.value }
            >
              {
                !isLoad &&stageColumns?.[item.value]?.map(item => (
                  <SalesActiveCycleKanbanTicket
                    item={ item }
                    key={ item.id }
                    onBookmark={ updateFlagsSingle }
                    onDelete={ onDelete }
                    onEdit={ onEdit }
                    onOutOfSightClick={ onOutOfSightClick }
                  />
                ))
              }
            </SalesActiveCycleKanbanColumn>
          ))
        }
      </DndProvider>
    </Wrapper>
  );
});

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useToggle } from '@customHooks';

import { Check, Cross } from '@/assets';

import Tooltip from '@components/Tooltip/Tooltip';

import { ButtonsWrapper, IconWrapper, Input } from './styles';

import { TagButtonInputWithControlsProps } from './types';

import { getValue, calculateValue } from './utils';


export const TagButtonInputWithControls = ({
  $isDynamicInputWidth,
  initEditState,
  inputStyle,
  isNotEditable,
  maxLength,
  onDeleteCb,
  onEditEnd,
  onEditStart,
  onSaveCb,
  tagData,
  validator,
}: TagButtonInputWithControlsProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(getValue(tagData));
  const [isEdit, toggleIsEdit ] = useToggle(initEditState || false);

  const isForceBlur = useRef<boolean | null>(null);
  const onBlur = () => {
    setTimeout(() => {
      //this condition prevent fire of Blur event if it called with blur method
      if(isForceBlur.current) {
        isForceBlur.current = false;
        return;
      }
      toggleIsEdit(false);
      setInputValue(getValue(tagData));

      onEditEnd && onEditEnd(tagData);
    }, 10);
  };
  const forceBlur = () => {
    let blur = inputRef.current?.blur();
    isForceBlur.current = true;
  };

  const onFocus = () => {
    if(typeof tagData?.count === 'number'){
      setInputValue(tagData.label);
    }


    toggleIsEdit(true);
    onEditStart && onEditStart(tagData);
  };

  const onDelete = () => onDeleteCb(tagData);

  const onSave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if(!validator && inputValue.length === 0) {
      return;
    }

    if(validator && !validator(inputValue)){
      return;
    }

    if(typeof tagData?.count === 'number'){
      setInputValue(calculateValue(inputValue, tagData.count));
    }

    const data = {
      ...tagData,
      ...(
        'label' in tagData
          ? { label: inputValue }
          : { value: inputValue }
      )
    };

    onSaveCb(
      data,
      () => {
        toggleIsEdit();
        forceBlur();
      }
    );
  };

  const onEditCancel = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    toggleIsEdit();

    setInputValue(getValue(tagData));

    forceBlur();
    onEditEnd && onEditEnd(tagData);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if(maxLength && value.length > maxLength) {
      return;
    }
    setInputValue(value);
  };

  useEffect(() => {
    if(initEditState && inputRef.current){
      inputRef.current.focus();
    }
  }, [initEditState, ]);

  return (
    <>
      <Tooltip
        displayOnlyOnOverflow={ true }
        title={ inputValue }
      >
        <Input
          $inputLength={ inputValue.length }
          $isDynamicInputWidth={ $isDynamicInputWidth }
          $isEdit={ isEdit }
          disabled={ isNotEditable }
          onBlur={ onBlur }
          onChange={ onChange }
          onFocus={ onFocus }
          ref={ inputRef }
          style={ inputStyle }
          type={ 'text' }
          value={ inputValue }
        />
      </Tooltip>
      {
        isEdit &&
        <ButtonsWrapper>
          <IconWrapper
            $save={ true }
            onMouseDown={ onSave }
            style={ {
              marginRight: '5px'
            } }
          >
            <Check/>
          </IconWrapper>
          <IconWrapper
            $onEditCancel={ true }
            onMouseDown={ onEditCancel }
          >
            <Cross/>
          </IconWrapper>
        </ButtonsWrapper>
      }
      {
        !isEdit &&
        <ButtonsWrapper onClick={ onDelete }>
          <IconWrapper $delete={ true }>
            <Cross/>
          </IconWrapper>
        </ButtonsWrapper>
      }
    </>
  );
};

import styled from 'styled-components';

import { LabelProps } from './types';

export const LabelWrapper = styled.div<LabelProps>`
  width: min-content;
  height: 18px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: ${ ({ $bgColor }) => $bgColor };
  color: ${ ({ $color }) => $color };
  text-decoration: none !important;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap
`;

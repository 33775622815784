import { COLORS } from '@constants/colors';

import { DoneMarkWithCircle, Close, Edit, Eye, Restore, Trash, CopyTask } from '@/assets';

import {
  AnchorOriginType,
  MenuListPropsType,
  PaperPropsType,
  TemplatesType,
  TransformOriginType
} from './types';

const anchorOrigin: AnchorOriginType =  {
  vertical: 'bottom',
  horizontal: 'right',
};

const MenuListProps: MenuListPropsType = {
  style: {
    paddingTop: 0,
    paddingBottom: 0
  }
};

const PaperProps: PaperPropsType = {
  style: {
    marginTop: '3px',
    minWidth: '140px'
  }
};

const transformOrigin: TransformOriginType = {
  vertical: 'top',
  horizontal: 'right',
};

export const PREDEFINED_PROPS = {
  anchorOrigin,
  MenuListProps,
  PaperProps,
  transformOrigin
};

export const DOTS_MENU_ITEM_TYPES = {
  completeAndCopy: 'COMPLETE_AND_COPY',
  copyTask: 'COPY_TASK',
  delete: 'DELETE',
  edit: 'EDIT',
  permanentDelete: 'PERMANENT_DELETE',
  remove: 'REMOVE',
  restore: 'RESTORE',
  view: 'VIEW',
  unlink: 'UNLINK',
  viewOrEdit: 'EDIT_OR_VIEW',
} as const;

export const COMMON_COLORS = {
  fillColor: COLORS.primaryText,
  hoverColor: COLORS.orange
};

export const COMMON_DELETE_COLORS = {
  fillColor: COLORS.red,
};

export const DOTS_MENU_ITEMS_TEMPLATES: TemplatesType = {
  [DOTS_MENU_ITEM_TYPES.delete]: {
    type: DOTS_MENU_ITEM_TYPES.delete,
    icon: Trash,
    label: 'Delete',
    ...COMMON_DELETE_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.edit]: {
    type: DOTS_MENU_ITEM_TYPES.edit,
    icon: Edit,
    label: 'Edit',
    ...COMMON_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.permanentDelete]: {
    type: DOTS_MENU_ITEM_TYPES.permanentDelete,
    icon: Close,
    label: 'Permanent Delete',
    fillColor: COLORS.red,
  },
  [DOTS_MENU_ITEM_TYPES.remove]: {
    type: DOTS_MENU_ITEM_TYPES.remove,
    icon: Trash,
    label: 'Remove',
    ...COMMON_DELETE_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.restore]: {
    type: DOTS_MENU_ITEM_TYPES.restore,
    icon: Restore,
    label: 'Restore',
    ...COMMON_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.view]: {
    type: DOTS_MENU_ITEM_TYPES.view,
    icon: Eye,
    label: 'View',
    ...COMMON_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.unlink]: {
    type: DOTS_MENU_ITEM_TYPES.unlink,
    icon: Trash,
    label: 'Unlink',
    ...COMMON_DELETE_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.viewOrEdit]: {
    type: DOTS_MENU_ITEM_TYPES.viewOrEdit,
    icon: Edit,
    label: 'View / Edit',
    ...COMMON_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.completeAndCopy]: {
    type: DOTS_MENU_ITEM_TYPES.completeAndCopy,
    icon: DoneMarkWithCircle,
    label: 'Complete and Copy',
    ...COMMON_COLORS,
  },
  [DOTS_MENU_ITEM_TYPES.copyTask]: {
    type: DOTS_MENU_ITEM_TYPES.copyTask,
    icon: CopyTask,
    label: 'Copy Task',
    ...COMMON_COLORS,
  },
};

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import {
  AccountAndPlansPopupSettingsResponseType, PopupSettings
} from '@modules/AccountsAndPlansPopup/store/types';

export const normalizer = (
  response: AccountAndPlansPopupSettingsResponseType
): PopupSettings => {
  const data = response.data.data;

  return {
    accountSubTypeCashEquivalent: optionsFromValue(data.subTypeCashEquivalent),
    accountSubTypeInvestments: optionsFromValue(data.subTypeInvestments),
    accountType: optionsFromValue(data.typesForAccount),
    discretionary: optionsFromValue(data.discretionary),
    feeFrequency: optionsFromValue(data.feeFrequency),
    feeStatus: optionsFromValue(data.feeStatus),
    generalType: optionsFromValue(data.typeAccount),
    heldAwayStatus: optionsFromValue(data.heldAwayStatus),
    managedStatus: optionsFromValue(data.managedStatus),
    policySubTypeAnnuities: optionsFromValue(data.subTypeAnnuitiesField),
    policySubTypeLifeInsurance: optionsFromValue(data.subTypeLifeInsuranceField),
    policyType: optionsFromValue(data.typesForPolicy),
    registrationType: optionsFromValue(data.registrationType),
    status: optionsFromValue(data.status),
    taxQualification: optionsFromValue(data.taxQualification),
  };
};

import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { Radio } from 'react-feather';

import { useStore } from '@services/store/store';

import BlockButton from '@components/BlockButton';
import FormBlock from '@components/FormBlock';
import { Contacts } from './components/Contacts';

import { useToggle } from '@/shared/customHooks';
import { getContactRules } from './utils';

import { LabelWithIconWrapper, Wrapper } from './styles';

import { ContactDetailsProps } from './types';


export const ContactDetails = observer(({
  contact,
  contactsDetails,
  headerTitle,
  onViewAllClick
}: ContactDetailsProps) => {

  const mainContactsDetails = useMemo(() => (contactsDetails || []).slice(0,5), [contactsDetails]);
  const additionalContactsDetails = useMemo(() => (contactsDetails || []).slice(5), [contactsDetails]);
  
  const [isShow, toggleIsShow] = useToggle(false);

  const showAdditionalInfo = useMemo(() => isShow ? 'Hide All' : 'View All', [isShow]);

  const { states } = useStore().SettingsStore;

  const onShowAdditionalInfoClick = useCallback(() => {
    onViewAllClick ? onViewAllClick() : toggleIsShow();
  }, [toggleIsShow]);

  if(!contact) {
    return null;
  }

  return (
    <Wrapper>
      <FormBlock
        isBorderBottom={ false }
        marginBottom={ 20 }
      >
        <FormBlock.Header title={ headerTitle }>
          { !isEmpty(mainContactsDetails) &&
            <BlockButton
              $isNoneFill={ true }
              onClick={ onShowAdditionalInfoClick }
              style={ {
                padding: 0,
                minWidth: 50,
                marginLeft: 'auto'
              } }
            >
              { showAdditionalInfo }
            </BlockButton>
          }
        </FormBlock.Header>

        <Contacts
          contactsDetails={ mainContactsDetails }
          states={ states }
        />

        { isShow &&
          <Contacts
            contactsDetails={ additionalContactsDetails }
            states={ states }
          />
        }

        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelHeight={ 20 }
            $labelWidth={ 140 }
            renderLabelContent={ () => (
              <LabelWithIconWrapper>
                <Radio/> Contact Rules
              </LabelWithIconWrapper>
            ) }
          >
            <FormBlock.VerticalCenteredWrapper>
              { getContactRules(contact) }
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
    </Wrapper>
  );
});

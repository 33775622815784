import React, { useCallback, useMemo } from 'react';

import { DoneMarkWithCircle, Task } from '@/assets';

import Avatar from '@components/Avatar';
import DotsMenu from '@components/DotsMenu';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import Table, { TypeSelectedRow } from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { COLORS } from '@constants/colors';
import { COMMON_COLUMN_LEFT_PADDING } from '../../data';
import { NOTES_TASK_DOTS_MENU_OPTIONS, NOTES_TASK_DOTS_MENU_TYPES } from './data';
import { STAGES } from '@constants/todosData';

import { IconOuterWrapper, IconWrapper, InfoIconWrapper, InfoTextWrapper, InfoWrapper } from './styles';

import { getPriorityColor } from '@/shared/utils/getPriorityColor';
import { getAvatarData } from '@/shared/utils/getAvatarData';

import { NotesTaskAction } from './types';
import { NotesTasksTableRowProps } from './types';
import { getDateContent } from '@/shared/utils/getDateContent';
import { getDueDateColor } from '@/shared/utils/getDueDateColor';


const TABLE_NAME_CELL_STYLE = {
  style: {
    paddingLeft: 0
  }
};

const TABLE_COMMON_CELL_STYLE = {
  style: {
    paddingLeft: COMMON_COLUMN_LEFT_PADDING
  }
};

export const NotesTasksTableRow = ({
  task,
  onTaskEdit,
}: NotesTasksTableRowProps) => {

  const {
    priority,
    dueDate,
    stage,
    name,
    category,
    clientData,
  } = task;

  const mainImageData = useMemo(() => getAvatarData(clientData).mainImageData, [ clientData ]);

  const date = useMemo(() => ({
    converted: getDateContent({
      dateString: dueDate,
      fallBackContent: '-'
    }),
    color: getDueDateColor(dueDate)
  }), [dueDate]);

  const onMenuSelect = useCallback((action: NotesTaskAction) => {
    if (action === NOTES_TASK_DOTS_MENU_TYPES.viewEdit){
      onTaskEdit(task.id);
    }
  }, [onTaskEdit, task.id]);

  return (
    <Table.Row
      { ...(
        stage === STAGES.complete
          ? {
            $typeSelectedRow: TypeSelectedRow.Stripes,
            selected: true
          }
          : {}
      ) }
    >
      <Table.Cell TableCellProps={ TABLE_NAME_CELL_STYLE }>
        <IconOuterWrapper>
          {
            stage === STAGES.complete &&
            <IconWrapper>
              <DoneMarkWithCircle/>
            </IconWrapper>
          }
        </IconOuterWrapper>
        <InfoWrapper>
          <InfoIconWrapper>
            <Task/>
          </InfoIconWrapper>
          <InfoTextWrapper>
            <TextWithTooltip
              $fontColor={ COLORS.orange }
              text={ name }
            />
            <TextWithTooltip
              $fontColor={ COLORS.primaryText }
              $fontSize={ 10 }
              text={ category }
            />
          </InfoTextWrapper>
        </InfoWrapper>
      </Table.Cell>
      <Table.Cell TableCellProps={ TABLE_COMMON_CELL_STYLE }>
        { priority ? 
          <ListItemWithColorIndicator
            indicatorColor={ getPriorityColor(priority) }
            label={ priority }
          /> :
          '-'
        }
      </Table.Cell>
      <Table.Cell TableCellProps={ TABLE_COMMON_CELL_STYLE } >
        <TextWithTooltip
          $fontColor={ date.color || COLORS.primaryText }
          text={ date.converted }
        />
      </Table.Cell>
      <Table.Cell>
        <Avatar mainImageData={ mainImageData }/>
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '10px' }
          items={ NOTES_TASK_DOTS_MENU_OPTIONS }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};

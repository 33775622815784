import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { Location, Action } from 'history';

import { Prompt } from 'react-router-dom';
import { RouteGuardProps } from './types';
import { ROUTES } from '@constants/routes';
import { HISTORY_ACTIONS } from '@constants/common';



export const RouteGuard = observer(({
  triggerModalRender,
  when,
}: RouteGuardProps) => {
  const { setTransitionBlockState, browserHistory } = useStore().RouterStore;
  const authStore = useStore().AuthStore;
  const [isTransitionAllowed, setIsTransitionAllowed] = useState<boolean>(false);
  const [lastLocation, updateLastLocation] = useState<string>();

  const handleBlockedNavigation = (location: Location, action: Action) => {
    const isNotBlockLoginRoute = location.pathname === ROUTES.login && action === HISTORY_ACTIONS.PUSH;

    if(isNotBlockLoginRoute){
      authStore.workerLogout();
      return true;
    }


    if(!isTransitionAllowed) {
      setTransitionBlockState(true);
      updateLastLocation(location.pathname);

      triggerModalRender({
        onConfirm: () => {
          setIsTransitionAllowed(true);
        },
        onCancel: () => {}
      });

      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isTransitionAllowed && lastLocation) {
      browserHistory.push(lastLocation);
      setIsTransitionAllowed(false);
    }
  }, [browserHistory, isTransitionAllowed, lastLocation, setTransitionBlockState]);

  useEffect(() => {
    setTransitionBlockState(when);
    return () => setTransitionBlockState(false);
  }, [setTransitionBlockState, when]);

  return (
    <>
      <Prompt message={ handleBlockedNavigation } when={ when } />
    </>
  );
});



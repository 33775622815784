import React from 'react';
import { observer } from 'mobx-react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';

import { useStore } from '@store';

import { EVENT_TYPES } from '@constants/colorsByEventTypes';

import { PopoverEventItem } from './components';

import { StyledEventPopoverWrapper } from './styles';

export const ShowMorePopover = observer(() => {
  const calendarStore = useStore().CalendarStore;

  const {
    resetShowMoreData,
    showMoreAnchorElem,
    showMoreEvents
  } = calendarStore;

  const origin = {
    vertical: -5,
    horizontal: 'left',
  };

  return (
    <Popover
      PaperProps={ {
        style: {
          width: '228px',
          height: '295px',
          overflow: 'scroll'
        }
      } }
      anchorEl={ showMoreAnchorElem }
      anchorOrigin={ origin }
      id="mouse-over-popover"
      open={ showMoreAnchorElem && showMoreEvents }
      sx={ {
        pointerEvents: 'none',
      } }
      transformOrigin={ {
        vertical: 'bottom',
        horizontal: 'left',
      } }
    >
      <ClickAwayListener onClickAway={ resetShowMoreData }>
        <StyledEventPopoverWrapper>
          {
            showMoreEvents && showMoreEvents.map((event => {
              const { editAllow, type } = event.resource;
              const isBlocked = typeof editAllow === 'number' && !editAllow && type !== EVENT_TYPES.holiday;

              return (
                <PopoverEventItem
                  event={ event }
                  isBlocked={ isBlocked }
                  key={ event.title }
                />);
            }))
          }
        </StyledEventPopoverWrapper>
      </ClickAwayListener>
    </Popover>
  );
});

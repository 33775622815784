import { makeAutoObservable } from 'mobx';
import { Store } from '@store';

import { IdType } from '@/shared/types/commonTypes';

import { getContactById } from '@services/api/contacts/contacts';

import { ContactItem } from '@/shared/types/contact';
import { GetByIdResponse } from './types/common';

import { getContactName } from '@/shared/utils/getContactName';


export class ContactDetailsStore {
  coreStore: Store;
  isLoad: boolean = true;
  currentContact: ContactItem | null = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {});
    this.coreStore = coreStore;
  }

  get contactName(): string {
    if(!this.currentContact) {
      return '';
    }
    return getContactName(this.currentContact);
  }

  toggleLoadState(newState: boolean){
    this.isLoad = newState;
  }

  async getContact(id: IdType): Promise<ContactItem> {
    const response: GetByIdResponse = await getContactById({ id });
    return response.data.data['0'];
  }

  isNeedToUpdateContact(id: IdType):boolean{
    if(!this.currentContact){
      return true;
    }else {
      return this.currentContact.id !== Number(id);
    }
  }

  setCurrentContact(contactItem: ContactItem){
    this.currentContact = contactItem;
  }

  resetState() {
    this.currentContact = null;
    this.coreStore.ContactDetailsProfileStore.resetState();
  }
  
}

import React from 'react';


import {
  Cell,
  CellWithControls,
  Container,
  Header,
  Footer,
  FooterCountInfo,
  Body,
  Row
} from './components';

import { TableProps } from '@components/Table/types';

import { Wrapper } from './styles';

const Table = ({ children }: TableProps) => (<Wrapper> { children }</Wrapper>);

Table.Container = Container;
Table.Header = Header;
Table.Body = Body;
Table.Footer = Footer;
Table.FooterCountInfo = FooterCountInfo;
Table.Row = Row;
Table.Cell = Cell;
Table.CellWithControls = CellWithControls;

export default Table;

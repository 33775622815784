import React from 'react';

import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';

import { OpportunityStatusWrapper } from './styles';

import { EntityProps } from './types';
import { LinkedOpportunity } from '@/shared/types/salesCycleOpportunity';


export const OpportunityStatus = ({ data }: EntityProps<LinkedOpportunity>) => (
  <OpportunityStatusWrapper>
    { data.opportunityStatus ?
      <SalesClientOpportunitiesStatusOption
        label={ data.opportunityStatus }
        value={ data.opportunityStatus }
      /> : '-'
    }
  </OpportunityStatusWrapper>
);

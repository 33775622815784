import { ReactNode } from 'react';

import { COLORS } from '@constants/colors';
import { AlertCircle, AlertCircleFilled } from '@/assets';

import { Type, ColorScheme, ColorSchemeItem } from './types';

export const INFO_ICON_TYPES = {
  alertInfo: 'alertInfo',
  alertInfoFilled: 'alertInfoFilled',
} as const;

export const ICONS: Record<Type, ReactNode> = {
  [INFO_ICON_TYPES.alertInfo]: AlertCircle,
  [INFO_ICON_TYPES.alertInfoFilled]: AlertCircleFilled,
};

export const INFO_COLOR_SCHEME = {
  main: 'main',
  mainReversed: 'mainReversed',
  alert: 'alert',
  reversedAlert: 'reversedAlert',
  onlyRed: 'onlyRed',
  transparentAlert: 'transparentAlert'
} as const;

export const COLOR_SCHEME_TEMPLATES: Record<ColorScheme, ColorSchemeItem> = {
  main: {
    common: COLORS.helpText,
    hover: COLORS.grey,
  },
  mainReversed: {
    common: COLORS.grey,
    hover: COLORS.helpText,
  },
  alert: {
    common: COLORS.redBg,
    hover: COLORS.red,
  },
  reversedAlert: {
    common: COLORS.red,
    hover: COLORS.redBg,
  },
  onlyRed: {
    common: COLORS.red,
    hover: COLORS.red,
  },
  transparentAlert: {
    common: 'transparent',
    hover: COLORS.orange,
  }
};

import { parse, isWithinInterval } from 'date-fns';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const isDateInRange = (dateObj, startDateString, endDateString) => {
  const start = parse(startDateString, YEAR_MONTH_DAY, new Date());
  const end = parse(endDateString, YEAR_MONTH_DAY, new Date());

  return isWithinInterval(
    dateObj,
    {
      start,
      end
    }
  );
};

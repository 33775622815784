import styled, { css } from 'styled-components';
import { ERROR_SPACE_VALUE } from '@constants/style';

export const LinkedContactOuterWrapper = styled.div`
  height: 30px;
  position: relative;
    ${({ $showError }) => $showError && css`
        margin-bottom: ${ERROR_SPACE_VALUE}px;
    ` };
`;

export const LinkedContactWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

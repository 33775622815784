import { TODOS_COLUMNS_IDS } from '@constants/todosData';
import { ColumnType } from '@components/Table/components/Header/types';

export const COMMON_COLUMNS: Array<ColumnType> = [
  {
    id: TODOS_COLUMNS_IDS.name,
    label: 'Task Name',
    style: {
      width: '25%'
    },
  },
  {
    id: TODOS_COLUMNS_IDS.priority,
    label: 'Priority',
    style: {
      width: '15%'
    },
  },
  {
    id: TODOS_COLUMNS_IDS.stage,
    label: 'Stage',
    style: {
      width: '16%'
    },
  },
  {
    id: TODOS_COLUMNS_IDS.dueDate,
    label: 'Due Date',
    style: {
      width: '15%',
    },
  },
  {
    id: TODOS_COLUMNS_IDS.assignTo,
    label: 'Assigned To',
    style: {
      width: '25%'
    },
  },
];

export const WITH_COMPLETED_TASKS_COLUMNS: Array<ColumnType> = [
  {
    id: TODOS_COLUMNS_IDS.complete,
    label: '',
    style: {
      width: '3%'
    }
  },
  ...COMMON_COLUMNS
];

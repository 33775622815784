import { DEFAULT_STATE_VALUE } from '@pages/NewUserSettings/data';
import { UserProfileType } from '@services/store/userSettingsStore/types';

export const getMyProfileDefaultValues = (profile: UserProfileType) => ({
  addressLine: profile.addressLine,
  addressLine2: profile.addressLine2,
  businessName: profile.businessName,
  city: profile.city,
  compliance: profile.compliance,
  email: profile.email,
  emailSignature: profile.emailSignature,
  emailVerified: profile.emailVerified,
  faxNumber: profile.faxNumber,
  firstName: profile.firstName,
  headshot: profile.headshot,
  id: profile.id,
  lastName: profile.lastName,
  logo: profile.logo,
  phoneNumber: profile.phoneNumber,
  signature: profile.signature,
  state: profile.state || DEFAULT_STATE_VALUE,
  status: profile.status,
  title: profile.title,
  userRole: profile.userRole,
  website: profile.website,
  zip: profile.zip,
});
import React, { ElementType } from 'react';

import { SIZES, TEMPLATES } from './data';

import { Button, IconWrapper } from './styles';

import { CustomIconButtonPropsType } from '@components/CustomIconButton/types';

export const CustomIconButton = ({
  disabled,
  onClick = () => {},
  $size= SIZES.small,
  activeIcon,
  icon,
  $iconActiveColor,
  $iconActiveHoverColor,
  $iconColor,
  $iconHoverColor,
  $iconSize,
  $isActive,
  $substrateBgColor,
  type,
}: CustomIconButtonPropsType) => {
  const Icon = ((type && TEMPLATES[type]?.icon) || icon) as ElementType;
  const ActiveIcon = (activeIcon
    || (type && TEMPLATES[type]?.activeIcon)
    || (type && TEMPLATES[type]?.icon)) as ElementType;


  return (
    <Button
      $isActive={ $isActive }
      $size={ $size }
      $substrateBgColor={ $substrateBgColor || (type && TEMPLATES[type]?.substrateBgColor) }
      disabled={ disabled }
      onClick={ onClick }
    >
      <IconWrapper
        $iconActiveColor={ $iconActiveColor || (type && TEMPLATES[type]?.iconActiveColor) }
        $iconActiveHoverColor={ $iconActiveHoverColor || (type && TEMPLATES[type]?.iconActiveHoverColor) }
        $iconColor={ $iconColor || (type && TEMPLATES[type]?.iconColor) || '' }
        $iconHoverColor={ $iconHoverColor || (type && TEMPLATES[type]?.iconHoverColor) || '' }
        $iconSize={ $iconSize || (type && TEMPLATES[type]?.iconSize) }
        $isActive={ $isActive }
        $size={ $size }
      >
        {
          ($isActive && ActiveIcon)
            ? <ActiveIcon/>
            : <Icon/>
        }
      </IconWrapper>
    </Button>
  );
};

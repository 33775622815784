import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import { HeaderWrapperProps } from '../../types';


export const Wrapper = styled.div<HeaderWrapperProps>`
  display: flex;

  padding: 16px 20px;
  color: ${COLORS.white};
  font-size: ${({ $fontSize }) => $fontSize || '16px'};
  line-height: 1.2;
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  
  margin-left: auto;
`;

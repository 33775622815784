import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding-left: 25px;

  & .MuiFormControl-root {
    width: 100%;
  }

  & .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 10px;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 0;
`;

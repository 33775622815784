import React from 'react';
import { observer } from 'mobx-react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStore } from '@store';

import CustomTabs from '@components/CustomTabs';
import Surface from '@components/BasicLayoutComponents/Surface';

import { SALES_CYCLE_TABS_DATA } from './data';

import { SalesCycleParamsType } from '@/shared/types/salesActiveCycle';

const PRE_HEADER_STYLE_PROPS = {
  paddingLeft: '5px !important'
};

export const SalesCycleHeader = observer(() => {
  const { nestedStore , isLoad } = useStore().SalesCycleStore;
  const { filtersCount, toggleFiltersIsOpen, isFiltersOpen, resetFilters } = nestedStore;
  const { params: { tab } } =  useRouteMatch<SalesCycleParamsType>();

  return (
    <Surface.PreHeader
      $height={ '64px' }
      badgeClickHandler={ resetFilters }
      badgeCount={ filtersCount }
      buttonCLickHandler={ toggleFiltersIsOpen }
      enableFilterMode={ !isFiltersOpen }
      restBoxProps={ PRE_HEADER_STYLE_PROPS }
      withFilterButton={ true }
    >
      <CustomTabs
        value={ tab }
      >
        {
          SALES_CYCLE_TABS_DATA.map((tab, index) => {
            return (
              <CustomTabs.Tab
                $fontSize={ '16px' }
                $minHeight={ '64px' }
                component={ Link }
                key={ tab.value }
                label={ tab.label }
                replace={ true }
                to={ tab.link }
                value={ tab.value }
              />
            );
          })
        }
      </CustomTabs>
    </Surface.PreHeader>
  );
});

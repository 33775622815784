import * as yup from 'yup';

import {
  confirmPasswordValidation,
  newPasswordValidation,
  oldPasswordValidation,
} from '@modules/HookFormComponents/HFPasswordInput';
import { PasswordFormInputNames } from './data';

export const validationSchema = yup.object().shape({
  [PasswordFormInputNames.newPassword]: newPasswordValidation,
  [PasswordFormInputNames.confirmPassword]: confirmPasswordValidation(PasswordFormInputNames.newPassword),
  [PasswordFormInputNames.oldPassword]: oldPasswordValidation
});

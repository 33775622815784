import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { hexToRgba } from '@/shared/utils/hexToRgba';
import MuiBox from '@material-ui/core/Box';

export const Wrapper = styled(MuiBox)`
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
  background-color: ${hexToRgba(COLORS.light, 0.8)};
`;


export const FullPageWrapper = styled.div`
  & > ${Wrapper}{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }
`;

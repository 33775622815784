import { RadioGroupOption } from '@components/CustomRadio/types';

export const RADIO_OPTIONS:Array<RadioGroupOption> = [
  {
    value: 'Yes',
    label: 'Linked'
  },
  {
    value: 'No',
    label: 'Separate'
  }
];

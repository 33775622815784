import { COLORS } from '@constants/colors';
import { TypeSelectedRow } from './types';

export const getRowBackground = (type: TypeSelectedRow, hexColor?: string) => {
  switch(type) {
  case TypeSelectedRow.Solid:
    return hexColor || COLORS.orange;
  case TypeSelectedRow.Stripes:
    return (
      `repeating-linear-gradient(-45deg,
      ${COLORS.borders} 1px,
      transparent 2px 7px,
      ${COLORS.borders} 8px,
      transparent 7px)`
    );
  }
};
import { CONTACT_TYPES } from '@constants/common';
import { IdType } from '@/shared/types/commonTypes';

import { ROUTES } from '@constants/routes';

import { buildPath } from '@/shared/utils/buildPath';

import {
  BUSINESS_PROFILE_LINKS,
  BusinessProfileLinksType,
  CONTACT_DETAILS_TAB,
  ContactDetailsRouteType,
  HOUSEHOLD_PROFILE_LINKS,
  HouseHoldProfileLinksType,
  INDIVIDUAL_PROFILE_LINKS,
  IndividualProfileLinksType,
} from '@constants/routes/contacts';

type BusinessProfile = {
  contactType: CONTACT_TYPES.BUSINESS
  subTab?: BusinessProfileLinksType
}

type IndividualProfile = {
  contactType: CONTACT_TYPES.INDIVIDUAL
  subTab?: IndividualProfileLinksType
}

type HouseHoldProfile = {
  contactType: CONTACT_TYPES.HOUSEHOLD
  subTab?: HouseHoldProfileLinksType
}

export type GetProfileLinkProps = BusinessProfile | IndividualProfile | HouseHoldProfile
type GetProfileLink = (props?: GetProfileLinkProps) => string
type DefaultFunc = () => string

type GetContactDetailsLinkType= (id: IdType, contactType: CONTACT_TYPES) => {
  [CONTACT_DETAILS_TAB.accounts]: DefaultFunc
  [CONTACT_DETAILS_TAB.activities]: DefaultFunc
  [CONTACT_DETAILS_TAB.overview]: DefaultFunc
  [CONTACT_DETAILS_TAB.profile]: GetProfileLink
  [CONTACT_DETAILS_TAB.toDos]: DefaultFunc
}

export const generateContactDetailsLink = (
  id: IdType,
  contactType: CONTACT_TYPES,
  tab: ContactDetailsRouteType,
) => {
  return buildPath([ROUTES.contactsAll, `${id}`,contactType, tab]);
};

const getContactDetailsProfileLink = (pathWithoutSubTab: string, props: GetProfileLinkProps) => {
  const { subTab, contactType } = props;
  if(contactType === CONTACT_TYPES.BUSINESS && !subTab){
    return buildPath([pathWithoutSubTab, BUSINESS_PROFILE_LINKS.businessDetails]);
  }
  if(contactType === CONTACT_TYPES.INDIVIDUAL && !subTab){
    return buildPath([pathWithoutSubTab, INDIVIDUAL_PROFILE_LINKS.individualDetails]);
  }
  if(contactType === CONTACT_TYPES.HOUSEHOLD && !subTab){
    return buildPath([pathWithoutSubTab, HOUSEHOLD_PROFILE_LINKS.householdDetails]);
  }
  if(subTab){
    return buildPath([pathWithoutSubTab, subTab]);
  }

  return pathWithoutSubTab;
};

export const getContactDetailsLink: GetContactDetailsLinkType = (id, contactType,) => ({
  accounts: () =>  generateContactDetailsLink(id, contactType, CONTACT_DETAILS_TAB.accounts),
  activities: () =>  generateContactDetailsLink(id, contactType, CONTACT_DETAILS_TAB.activities),
  overview: () =>  generateContactDetailsLink(id, contactType, CONTACT_DETAILS_TAB.overview),
  profile: (props) =>  {
    const pathWithoutSubTab = generateContactDetailsLink(id, contactType, CONTACT_DETAILS_TAB.profile);

    if(props) {
      return getContactDetailsProfileLink(pathWithoutSubTab, props);
    }

    return pathWithoutSubTab;
  },
  toDos: () =>  generateContactDetailsLink(id, contactType, CONTACT_DETAILS_TAB.toDos),
});

import { AllHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

export type WrapperProps = AllHTMLAttributes<HTMLDivElement> & {
  $isBorder: boolean
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  border: ${({ $isBorder }) => $isBorder ? `1px solid ${COLORS.borders}` : 'none'};
  border-radius: 4px;
  box-sizing: border-box;

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0.26);
  `}

  &:hover {
    border-color: ${COLORS.orange};
  }

  & .MuiTextField-root {
    padding: 0px;
  }

  & .MuiAutocomplete-root {
    border-radius: 4px;
    height: 28px;
  }

  & > div {
    width: auto;
    margin-bottom: 0px;
  }

  & fieldset {
    border: none;
  }

  & .MuiSelect-select {
    height: 27px !important;
    color: ${COLORS.helpText};
  }
`;

export const Divider = styled.div`
  min-width: 1px;
  height: 16px;
  background: ${COLORS.borders};
`;

export const TaskIdWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${COLORS.orange};
    text-decoration: underline;
  }
`;

import React, { useCallback, useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import CustomIconButton, { ICON_BUTTON_TYPES, SIZES } from '@components/CustomIconButton';
import { RecentContactsLists } from '../RecentContactsLists';


export const ActionButtons = observer(() => {
  const { getRecentContacts, recentContactsLists } = useStore().AppHeaderStore;

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const onClockClick = useCallback((event: MouseEvent<HTMLElement>) => {
    getRecentContacts();
    setAnchorEl(event.currentTarget);
  }, [ setAnchorEl ]);

  const onListClose = useCallback(() => {
    setAnchorEl(null);
  }, [ setAnchorEl ]);

  return (
    <>
      <CustomIconButton
        $size={ SIZES.medium }
        onClick={ onClockClick }
        type={ ICON_BUTTON_TYPES.clock }
      />
      <CustomIconButton
        onClick={ () => {} }
        type={ ICON_BUTTON_TYPES.plus }
      />

      {
        isOpen &&
        <RecentContactsLists
          onClose={ onListClose }
          anchorEl={ anchorEl }
          contactsList={ recentContactsLists }
        />
      }
    </>
  );
});

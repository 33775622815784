import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  max-width: 395px;
  padding-bottom: 5px;
`;

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${COLORS.primaryText};
`;

export const HelperText = styled.div`
  width: 100%;
  padding-bottom: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${COLORS.helpText};
`;

export const SignificantText = styled.span`
  font-weight: 500;
  color: ${COLORS.header};
`;

export const NoteWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 3px;
  padding: 16px;
  border-radius: 4px;
  background: ${COLORS.background};
`;

export const AlertIcon = styled.div`
  margin-right: 5px;
`;

export const IntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 20px;
`;

export const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
`;

/* eslint-disable */
import { createMuiTheme } from '@material-ui/core/styles';

const mainTheme = createMuiTheme({
    spacing: 5,
    props: {
        MuiButton: {
            disableElevation: true
        },
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true,
            focusRipple: false
        },
        MuiFormControlLabel: {
            labelPlacement: 'start'
        },
        MuiBadge: {
            showZero: false
        },
        MuiSvgIcon: {
            fontSize: 'inherit'
        },
        MuiTypography: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',
                h7: 'p',
                h8: 'p',
                h9: 'p',
                h10: 'p',
                subtitle1: 'h6',
                subtitle2: 'h6',
                body1: 'p',
                body2: 'p'
            }
        },
        MuiTabs: {
            indicatorColor: 'primary',
            textColor: 'secondary'
        },
        MuiSelect: {
            variant: 'outlined'
        }
    },
    palette: {
        background: {
            default: '#F8F9FD',
        },
        primary: {
            light: '#FFB24A',
            main: '#EA8213',
            dark: '#E07809',
            contrastText: '#fff'
        },
        secondary: {
            light: '#4f5a6b',
            main: '#263140',
            dark: '#00081a',
            contrastText: '#fff'
        },
        success: {
            main: '#1DBD37',
            light: '#E8F8EB',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        error: {
            light: 'rgba(226, 65, 65, 0.2)',
            main: '#E24141',
        },
        custom: {
            pageBackground: '#F8F9FD',
            mainText: '#4E4E61',
            helperText: '#838390',
            titleText: '#474754',
            placeholderText: '#С6С7СА',
            borders: '#E1E3EC',
            dividers: '#ECEDF3',
            collapsedIcon: '#F2F3F8',
            unactiveTab: '#FDFDFE',
            selectedMenuItem: '#FFF9F1',
            actionsItem: '#FFEDD8',
            toggleIcon: '#9D9DB2',
            tagBackground: '#E6E3FF',
            tagText: '#736FAB',
            tagButtons: '#BDBBE1',
            lightGreen: '#96D171',
            darkOrange: '#F99501',
            lightOrange: '#F2B61A',
            lightBlue: '#1179C3',
            purple: '#D45AA0',
            darkBlue: '#4B43BC',
            tabTextGray: '#3A3A46',
            lightGray: '#C6C7CF',
            gray: '#BEC1C6',
            darkerGray: '#172231',
            childDarkGray: '#222C3A',
            hoverGray: '#323C4B',
            searchChipBgrnd: '#EAEAF2',
            middleOrange: '#F2A91A',
            greenBgrnd: '#E8F8EB',
            redBgrnd: '#FFEBEB',
            grayBgrnd: '#9D9DB3',
            resourceViolet: '#9B93EF',
            popupViolet: '#7D77D4',
            lightRed: '#F17A7A',
            tooltip: 'rgba(10, 10, 10, 0.75)',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: '1.375rem',
            lineHeight: '1.625rem',
            fontStyle: 'normal',
            fontWeight: 'normal',
            // color: '#3A3A46'
        },
        h2: {
            fontSize: '1.125rem',
            lineHeight: '1.3125rem',
            fontStyle: 'normal',
            fontWeight: 'normal',
            // color: '#3A3A46'
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.1875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            // color: '#3A3A46'
        },
        h4: {
            fontSize: '1rem',
            lineHeight: '1.1875rem',
            fontStyle: 'normal',
            fontWeight: 'normal',
            // color: '#3A3A46'
        },
        h5: {
            fontSize: '1rem',
            lineHeight: '1.1875rem',
            fontStyle: 'normal',
            fontWeight: '300',
            // color: '#4E4E61'
        },
        h6: {
            fontSize: '0.875rem',
            lineHeight: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            // color: '#3A3A46'
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                a: {
                    color: '#EA8213',
                    textDecoration: 'none'
                }
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '0.875rem',
                // width: '220px'
            },
            input: {
                padding: '7px 10px'
            }
        },
        MuiOutlinedInput: {
            root: {
                '&:hover $notchedOutline': {
                    borderColor: '#EA8213',
                    borderWidth: 1,
                },
                '&:hover svg': {
                    color: '#EA8213'
                },
                '&$focused $notchedOutline': {
                    borderWidth: 1
                },
            },
            input: {
                padding: 'none',
                '&::placeholder': {
                    opacity: 1,
                    color: '#C6C7CF',
                    fontSize: '14px'
                }
            },
            adornedEnd: {
                paddingRight: '0.4375rem'
            },
            notchedOutline: {
                borderColor: '#E1E3EC'
            }
        },
        MuiFormControl: {
            root: {
                '& p.Mui-error': {
                    height: 0
                }
            }
        },
        MuiFormGroup: {
            root: {
                gap: '1rem'
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 'unset'
            }
        },
        MuiFormControlLabel: {
            root: {
                justifyContent: 'space-between',
                alignItems: 'baseline'
            },
            labelPlacementStart: {
                marginLeft: 0,
                marginRight: 0
            }
        },
        MuiSelect: {
            root: {
                width: '11.125rem'
            }
        },
        MuiButton: {
            root: {
                fontWeight: 'normal',
                lineHeight: '1rem',
                textTransform: 'none',
                minWidth: '90px',
                padding: '9px 25px',
                borderRadius: '6px'
            },
            startIcon: {
                marginLeft: 0,
                '& > svg': {
                    height: 16,
                    width: 16
                }
            },
            outlined: {
                padding: '8px 25px'
            }
        },
        MuiTabPanel: {
            root: {
                padding: 0
            }
        },
        MuiTextField: {

        },
        MuiFormHelperText: {
            root: {
                // height: 0,
                lineHeight: '0.75rem',
                fontSize: '0.625rem',
                letterSpacing: '0.01rem',
                width: '13.75rem',
                marginTop: '0.375rem'
            },
            contained: {
                marginLeft: '0px',
                marginRight: 0
            }
        },
        MuiIconButton: {
            sizeSmall: {
                padding: 0
            },
            label: {
                fontSize: '1.3rem',
                '& svg:hover': {
                    color: '#EA8213'
                }
            }
        },
        MuiTypography: {
            h7: {
                fontSize: '0.875rem',
                lineHeight: '1rem',
                fontStyle: 'normal',
                fontWeight: 'normal',
                color: '#3A3A46'
            },
            h8: {
                fontSize: '0.875rem',
                lineHeight: '1rem',
                fontStyle: 'normal',
                fontWeight: '300',
                color: '#3A3A46'
            },
            h9: {
                fontSize: '0.75rem',
                lineHeight: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 'normal',
                color: '#3A3A46'
            },
            h10: {
                fontSize: '0.625rem',
                lineHeight: '0.75rem',
                fontStyle: 'normal',
                fontWeight: 'normal',
                color: '#3A3A46'
            }
        },
        MuiFormLabel: {
            asterisk: {
                color: '#E24141'
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: '0.625rem'
            }
        },
        MuiTab: {
            root: {
                textTransform: 'capitalize',
                fontWeight: 400
            },
            wrapper: {
                flexDirection: 'row',
                columnGap: '0.75rem',
                '& svg': {
                    marginBottom: 'unset !important'
                }
            },
            textColorSecondary: {
                '&$selected': {
                    fontWeight: 500
                }
            }
        },
        MuiInputAdornment: {
            root: {
                '& svg': {
                    color: '#C6C7CF'
                },
                '& svg:hover': {
                    color: 'red'
                }
            }
        }
    },
    shadows: [
        'none',
        '0px 1px 5px rgba(16, 28, 91, 0.07)',
        '0px 0px 10px rgba(16, 28, 91, 0.1)',
        '0px 1px 5px rgba(16, 28, 91, 0.12)',
        '0px 0px 10px rgba(16, 28, 91, 0.1)',
        '0px 1px 20px rgba(61, 62, 65, 0.78)',
        '0px 1px 5px rgba(16, 28, 91, 0.12)',
        '0px -1px 5px rgba(16, 28, 91, 0.12)',
        '0px 2px 10px rgba(16, 28, 91, 0.12)'
    ]
});

export default mainTheme;

/* eslint-disable */
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  h7: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  h8: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontStyle: 'normal',
    fontWeight: '300'
  },
  h9: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  h10: {
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 'normal'
  }
}));

export default function Text(props) {
  const classes = useStyles();

  return (
    <Typography classes={ classes } { ...props } />
  );
}

import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    border-top: 1px solid ${ COLORS.borders };
`;

import styled from 'styled-components';
import { Link } from '@material-ui/core';

import { COLORS } from '@constants/colors';

export const ErrorSubTitle = styled.div`
  width: 450px;
  margin: -20px 0 15px;
  position: relative;
  font-size: 14px;
  text-align: center;
  line-height: 150%;
  color: ${ COLORS.red };
`;

export const ForgotPasswordWrapper = styled(Link)`
  width: inherit;
  margin: -20px 0 15px;
  position: relative;
  font-size: 14px;
  text-align: right;
  color: ${ COLORS.primaryText };
`;

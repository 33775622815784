import React, { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import { useStore } from '@store';

import BaseBlockWrapper from '@components/BasicLayoutComponents/BaseBlockWrapper';
import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import ListDescription from '@modules/Lists/ListDescription';
import ListName from '@modules/Lists/ListName';
import ListOwner from '@modules/Lists/ListOwner';
import MailChimp from '@modules/Lists/MailChimp';
import SplitToggle from '@modules/Lists/SplitToggle';

import { LABEL_WIDTH } from './data';

import {
  DYNAMIC_ATTENTIONS_TEXT,
  LIST_SETUP_FIELDS_NAMES,
  LIST_SETUP_TYPE_OPTIONS,
  LISTS_TYPES, MANUAL_ATTENTIONS_TEXT,
  TYPE_FIELDS
} from '@constants/lists';
import { IdType } from '@/shared/types/commonTypes';
import { SaveListData } from '@/shared/types/lists';

import { addTimeZoneOffset } from '@/shared/utils/addTimeZoneOffset';

export const ListSetupForm = observer(() => {
  const {
    disconnectMailChimp,
    getMailchimpLists,
    initListOwner,
    isLoad,
    listCreationDate,
    listId,
    mailChimpListData,
    mailChimpLists,
    syncMailChimp,
  } = useStore().ListsStore.ListSetupStore;
  const { control, getValues } = useFormContext<SaveListData>();

  const listType = useWatch({
    control,
    name: LIST_SETUP_FIELDS_NAMES.typeList
  });

  const formatedCreationDate = useMemo(() => {
    const processed = addTimeZoneOffset(listCreationDate);

    if(!processed) {
      return '';
    }

    return format(processed, 'LLL dd, yyyy');
  }, [listCreationDate]);

  const onMailChimpClick = useCallback(() => {
    syncMailChimp();
  }, [syncMailChimp]);

  const onSyncNowClick = useCallback((listId: IdType) => {
    syncMailChimp(listId);
  }, [syncMailChimp]);

  const onDisconnectClick = useCallback(() => {
    disconnectMailChimp();
  }, [disconnectMailChimp]);

  return (
    <BaseBlockWrapper
      padding={ '20px' }
    >
      <HFHiddenInput
        control={ control }
        defaultValue={ null }
        name={ LIST_SETUP_FIELDS_NAMES.id }
      />
      <HFHiddenInput
        control={ control }
        defaultValue={ null }
        name={ LIST_SETUP_FIELDS_NAMES.parentId }
      />
      <HFHiddenInput
        control={ control }
        defaultValue={ TYPE_FIELDS.List }
        name={ LIST_SETUP_FIELDS_NAMES.typeField }
      />
      <FormBlock isBorderBottom={ false }>
        <FormBlock.Header title={ 'List Details' }/>
        <FormBlock.RowWrapper>
          <ListName
            control={ control }
            labelWidth={ LABEL_WIDTH }
            name={ LIST_SETUP_FIELDS_NAMES.name }
          />
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH }
            label={ 'List Type' }
          >
            <HFSelect
              control={ control }
              helperText={ listType === LISTS_TYPES.Dynamic ?  DYNAMIC_ATTENTIONS_TEXT : MANUAL_ATTENTIONS_TEXT }
              name={ LIST_SETUP_FIELDS_NAMES.typeList }
              options={ LIST_SETUP_TYPE_OPTIONS }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <ListOwner
            control={ control }
            labelWidth={ LABEL_WIDTH }
            name={ LIST_SETUP_FIELDS_NAMES.listOwner }
            user={ initListOwner }
          />
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper marginBottom={ '15px' }>
          <SplitToggle
            control={ control }
            labelWidth={ LABEL_WIDTH }
            name={ LIST_SETUP_FIELDS_NAMES.splitHousehold }
          />
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <ListDescription
            control={ control }
            labelWidth={ LABEL_WIDTH }
            name={ LIST_SETUP_FIELDS_NAMES.description }
          />
        </FormBlock.RowWrapper>
        <MailChimp
          isLoad={ isLoad }
          labelWidth={ LABEL_WIDTH }
          mailChimpListData={ mailChimpListData || null }
          mailChimpLists={ mailChimpLists || [] }
          onDisconnectClick={ onDisconnectClick }
          onMailChimpClick={ onMailChimpClick }
          onSyncNowClick={ onSyncNowClick }
          onViewAllClick={ getMailchimpLists }
        />
        <FormBlock.RowWrapper marginBottom={ '15px' }>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH }
            label={ 'List ID' }
          >
            <FormBlock.VerticalCenteredWrapper>
              {listId}
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LABEL_WIDTH }
            label={ 'List Creation Date' }
          >
            <FormBlock.VerticalCenteredWrapper>
              {formatedCreationDate}
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
    </BaseBlockWrapper>
  );
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import CustomFieldsAndTagsContent from '@modules/UserSettings/CustomFieldsAndTagsContent';
import HelperText from '@components/HelperText';
import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';
import { ListItem } from '..';


import { ActivityInformationProps } from './types';
import { CONTACT_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsContactCustomFields';


export const ActivityInformation = observer(({
  onAddEdit,
  onDelete,
  statusStore,
  subCategoryStore,
}: ActivityInformationProps) => {
  const {
    items: statusItems,
    onDrop: onDropStatus,
    onMove: onMoveStatus,
  } = statusStore;
  const {
    items: subCategoryItems,
    onDrop: onDropSubCategory,
    onMove: onMoveSubCategory,
  } = subCategoryStore;

  const onAddSubCategory = useCallback(() => {
    onAddEdit({
      field: CONTACT_CUSTOM_FIELDS_TYPES.activitySubCategory
    });
  } , [onAddEdit]);

  const onAddStatus = useCallback(() => {
    onAddEdit({
      field: CONTACT_CUSTOM_FIELDS_TYPES.activityStatus
    });
  } , [onAddEdit]);

  return (
    <CustomFieldsAndTagsContent
      $withDelimetr={ false }
      wrapperProps={ {
        maxHeight: 'inherit',
      } }
    >
      <CustomFieldsAndTagsContent.Header title={ 'Activity Information' }/>
      <CustomFieldsAndTagsContent.ActionRow
        marginBottom={ '10px' }
        onClick={ onAddSubCategory }
      >
        <HelperText
          marginRight={ '10px' }
          text={ 'Activity Subcategory' }
        />
        <InfoIcon
          colorScheme={ INFO_COLOR_SCHEME.mainReversed }
          helpText={ 'List of options that is displayed for activity subcategory' }
          type={ INFO_ICON_TYPES.alertInfo }
        />
      </CustomFieldsAndTagsContent.ActionRow>
      <CustomFieldsAndTagsContent.Content
        marginBottom={ '25px' }
        maxHeight={ 'calc(50% - 70px)' }
        padding={ '10px 0' }
      >
        <DndProvider backend={ HTML5Backend }>
          {
            subCategoryItems.map((item, index) => (
              <ListItem
                index={ index }
                item={ item }
                key={ item.id }
                onDelete={ onDelete }
                onDrop={ onDropSubCategory }
                onEdit={ onAddEdit }
                onMove={ onMoveSubCategory }
              />
            ))
          }
        </DndProvider>
      </CustomFieldsAndTagsContent.Content>

      <CustomFieldsAndTagsContent.ActionRow
        marginBottom={ '10px' }
        onClick={ onAddStatus }
      >
        <HelperText
          marginRight={ '10px' }
          text={ 'Activity Status' }
        />
        <InfoIcon
          colorScheme={ INFO_COLOR_SCHEME.mainReversed }
          helpText={ 'List of options that display for activity status' }
          type={ INFO_ICON_TYPES.alertInfo }
        />
      </CustomFieldsAndTagsContent.ActionRow>
      <CustomFieldsAndTagsContent.Content
        maxHeight={ 'calc(50% - 70px)' }
        padding={ '10px 0' }
      >
        <DndProvider backend={ HTML5Backend }>
          {
            statusItems.map((item, index) => (
              <ListItem
                index={ index }
                item={ item }
                key={ item.id }
                onDelete={ onDelete }
                onDrop={ onDropStatus }
                onEdit={ onAddEdit }
                onMove={ onMoveStatus }
              />
            ))
          }
        </DndProvider>
      </CustomFieldsAndTagsContent.Content>
    </CustomFieldsAndTagsContent>
  );
});

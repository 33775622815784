/* eslint-disable */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import { X } from 'react-feather';
import IconButton from '@material-ui/core/IconButton';
import propTypes from 'prop-types';
import Text from '../components/Text';
import colors from '../themes/colors';
import clsx from 'clsx';

import { WarningInfoIcon, ConfirmInfoIcon, InfoInfoIcon } from '@/assets';

import { COLORS } from '@constants/colors';

const ALERT_POPUP_TYPE = {
	WARNING: 'warning',
	INFO: 'info',
	CONFIRM: 'confirm',
	TIMER: 'timer'
}

const useStyles = makeStyles((theme) => ({
	popupContainer: {
		width: '25rem',
	},
	popupHeader: {
		display: 'flex',
		flexDirection: 'row-reverse',
		padding: '0.75rem 1.125rem 0 0',
		borderTop: ({type}) => `5px solid ${getThemeColor(type)}`
	},
	textMessage: {
		width: '20rem',
		margin: '0 auto',
    textAlign: 'center'
	},
	alertIconContainer: {
		margin: '0 auto',
		marginBottom: '1.5rem'
	},
	alertIcon: {
		width: '70px',
		height: '70px'
	},
	closeIcon: {
		'&:hover svg': {
			stroke: ({type}) => `${getThemeColor(type)}`,
		}
	},
	warningAlertIcon: {
		width: '85px',
		height: '70px'
    },
	textMessageCls: {
		color: COLORS.header,
		fontWeight: 400
	}
}))

const getThemeColor = (type) => {
	switch(type) {
		case ALERT_POPUP_TYPE.INFO:
		case ALERT_POPUP_TYPE.TIMER: {
			return colors.INFO_POPUP.MAIN;
		}
		case ALERT_POPUP_TYPE.WARNING: {
			return colors.ERROR.MAIN;
		}
		default: {
			return colors.PRIMARY.MAIN;
		}
	}
}

export default function AlertPopup(props) {

    const classes = useStyles(props);
    let {
        popupHeader,
        textMessage,
        closeIcon,
        alertIconContainer,
        alertIcon,
        warningAlertIcon
    } = classes;
    let {isOpen, message, type, onClose, children} = props;

    return (
        isOpen ?
        <Dialog classes={{ paper: classes.popupContainer }} open>
            <div className={popupHeader}>
                <IconButton
                    className={closeIcon}
                    size='small'
                    onClick={onClose}
                >
                    <X />
                </IconButton>
            </div>
            <div className={alertIconContainer}>
                {
                    {
                        [ALERT_POPUP_TYPE.INFO]: <InfoInfoIcon className={alertIcon}></InfoInfoIcon>,
                        [ALERT_POPUP_TYPE.WARNING]: <WarningInfoIcon className={warningAlertIcon}></WarningInfoIcon>,
                        [ALERT_POPUP_TYPE.CONFIRM]: <ConfirmInfoIcon className={alertIcon}></ConfirmInfoIcon>,
                    }[type]
                }
            </div>
						<Text variant='h5' className={clsx(classes.textMessageCls, textMessage)}>{message}</Text>
            {children}
        </Dialog> : null
    );
}

AlertPopup.propTypes = {
	isOpen: propTypes.bool,
	message: propTypes.oneOfType([
		propTypes.string,
		propTypes.element
	]),
  type: propTypes.string,
	onClose: propTypes.func,
	otherView: propTypes.elementType,
	children: propTypes.node,
	timerValue: propTypes.number,
}

import { ValueLabelObj, ValueOf } from '@/shared/types/commonTypes';
import {
  ACCOUNT_TYPES_OPTIONS,
  CONTACT_CLASSIFICATION,
  CONTACT_RANKS, CONTACT_STATUSES_OPTIONS,
  GENDER_OPTIONS,
  MONTH_OPTIONS,
  RETIREMENT_STATUS_OPTIONS,
  TAGS_TYPES
} from '@constants/common';
import {
  ACCOUNT_SUB_TYPE_OPTIONS,
  ASSET_TYPE_OPTIONS,
  ATTENDED_EVENTS_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  CONTACT_PREFERENCE_OPTIONS,
  CONTACT_RULES_OPTIONS,
  EXPENSES_TYPE_OPTIONS, INCOME_TYPE_OPTIONS,
  INSURANCE_POLICY_SUB_TYPE_OPTIONS,
  INSURANCE_POLICY_TYPE_OPTIONS, LIABILITY_TYPE_OPTIONS,
} from '@constants/listsCriteriaSelectOptions';

export const CRITERIA_TYPES = {
  Personal_Details: 'Personal Details',
  Contact_Details: 'Contact Details',
  Contact_information: 'Contact information',
  Family_details: 'Family details',
  Occupation : 'Occupation',
  Tags: 'Tags',
  Events: 'Events',
  Financial_Accounts: 'Financial Accounts',
  Insurance_Policies: 'Insurance Policies',
  // Assets: 'Assets',
  // Expense: 'Expenses',
  // Income: 'Income',
  // Liability: 'Liability',
  // Misc_Financial: 'Misc. Financial Details',
  Office_details: 'Office details',
};

export const OPERAND_OPTIONS = {
  After: 'After',
  Before: 'Before',
  In_Between: 'In Between',
  Specific_Date: 'Specific Date',
  Is_Empty: 'Is Empty',
  Is_Not_Empty: 'Is Not Empty',
  Equal_To_Any: 'Equal To Any',
  Not_Equal_To_Any: 'Not Equal To Any',
  Equal_To: 'Equal To',
  Not_Equal_To: 'Not Equal To',
  Greater_Than: 'Greater Than',
  Less_Than: 'Less Than',
  Begins_With: 'Begins With',
  Contains: 'Contains',
  Does_Not_Contain: 'Does Not Contain',
  Equal_To_All: 'Equal To All',
  Not_Equal_To_All : 'Not Equal To All'
};

const EQUAL_OR_NOT_EQUAL_TO_ANY = [
  OPERAND_OPTIONS.Equal_To_Any,
  OPERAND_OPTIONS.Not_Equal_To_Any
];

const EQUAL_OR_NOT_EQUAL_TO_ALL = [
  OPERAND_OPTIONS.Equal_To_All,
  OPERAND_OPTIONS.Not_Equal_To_All
];

const EQUAL_OR_NOT_EQUAL_TO = [
  OPERAND_OPTIONS.Equal_To,
  OPERAND_OPTIONS.Not_Equal_To,
];

export const IS_EMPTY_OR_IS_NOT_EMPTY = [
  OPERAND_OPTIONS.Is_Empty,
  OPERAND_OPTIONS.Is_Not_Empty
];

const EQUAL_TO_AND_EMPTY_COMPARISON = [
  ...EQUAL_OR_NOT_EQUAL_TO,
  ...IS_EMPTY_OR_IS_NOT_EMPTY
];

const RANGE_COMPARISON = [
  OPERAND_OPTIONS.Greater_Than,
  OPERAND_OPTIONS.Less_Than,
  OPERAND_OPTIONS.In_Between,
  ...EQUAL_TO_AND_EMPTY_COMPARISON
];

const TEXT_COMPARISON = [
  OPERAND_OPTIONS.Begins_With,
  OPERAND_OPTIONS.Contains,
  OPERAND_OPTIONS.Does_Not_Contain,
  ...EQUAL_TO_AND_EMPTY_COMPARISON
];

const APPLICABILITY_OPTIONS = {
  individuals: 'Individuals',
  household: 'Household',
  householdMembers: 'Household Members',
  business: 'Business'
};

const ALL_CONTACTS_TYPES = Object.values(APPLICABILITY_OPTIONS);
const BUSINESS = [APPLICABILITY_OPTIONS.business];
const INDIVIDUALS = [APPLICABILITY_OPTIONS.individuals];
const INDIVIDUALS_AND_HOUSEHOLD_MEMBERS = [APPLICABILITY_OPTIONS.individuals, APPLICABILITY_OPTIONS.householdMembers];
const INDIVIDUALS_AND_HOUSEHOLD = [APPLICABILITY_OPTIONS.individuals, APPLICABILITY_OPTIONS.household];
const ALL_WITHOUT_HOUSEHOLD_MEMBERS = [
  APPLICABILITY_OPTIONS.individuals, APPLICABILITY_OPTIONS.household, APPLICABILITY_OPTIONS.business
];


type FieldItemType = {
  operandOptions: Array<string>
  applicability: Array<string>
  valueOptions?: Array<ValueLabelObj>
  isMultiple?: boolean
  maxChars?: number
  fieldValue: string
  fieldLabel: string
}

export const PERSONAL_DETAILS_FIELDS = {
  Birthdate: 'Birthdate',
  Birth_Month: 'Birth Month',
  Gender: 'Gender',
  Age: 'Age',
  Retirement_Status: 'Retirement Status',
  Retirement_Date: 'Retirement Date',
} as const;
const PERSONAL_DETAILS: Record<ValueOf<typeof PERSONAL_DETAILS_FIELDS>, FieldItemType> = {
  [PERSONAL_DETAILS_FIELDS.Birthdate]: {
    fieldLabel: PERSONAL_DETAILS_FIELDS.Birthdate,
    fieldValue: PERSONAL_DETAILS_FIELDS.Birthdate,
    operandOptions: [
      OPERAND_OPTIONS.After,
      OPERAND_OPTIONS.Before,
      OPERAND_OPTIONS.In_Between,
      OPERAND_OPTIONS.Specific_Date,
      OPERAND_OPTIONS.Is_Empty,
      OPERAND_OPTIONS.Is_Not_Empty
    ],
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
  },
  [PERSONAL_DETAILS_FIELDS.Birth_Month]: {
    fieldLabel: PERSONAL_DETAILS_FIELDS.Birth_Month,
    fieldValue: PERSONAL_DETAILS_FIELDS.Birth_Month,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: MONTH_OPTIONS,

  },
  [PERSONAL_DETAILS_FIELDS.Gender]: {
    fieldLabel: PERSONAL_DETAILS_FIELDS.Gender,
    fieldValue: PERSONAL_DETAILS_FIELDS.Gender,
    operandOptions: [
      OPERAND_OPTIONS.Equal_To,
      OPERAND_OPTIONS.Not_Equal_To,
      OPERAND_OPTIONS.Is_Empty
    ],
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
    isMultiple: false,
    valueOptions: GENDER_OPTIONS,
  },
  [PERSONAL_DETAILS_FIELDS.Age]: {
    fieldValue: PERSONAL_DETAILS_FIELDS.Age,
    fieldLabel: PERSONAL_DETAILS_FIELDS.Age,
    operandOptions: RANGE_COMPARISON,
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS
  },
  [PERSONAL_DETAILS_FIELDS.Retirement_Status]: {
    fieldValue: PERSONAL_DETAILS_FIELDS.Retirement_Status,
    fieldLabel: PERSONAL_DETAILS_FIELDS.Retirement_Status,
    operandOptions: [
      OPERAND_OPTIONS.Equal_To,
      OPERAND_OPTIONS.Not_Equal_To,
      OPERAND_OPTIONS.Is_Empty
    ],
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
    isMultiple: false,
    valueOptions: RETIREMENT_STATUS_OPTIONS
  },
  [PERSONAL_DETAILS_FIELDS.Retirement_Date]: {
    fieldValue: PERSONAL_DETAILS_FIELDS.Retirement_Date,
    fieldLabel: PERSONAL_DETAILS_FIELDS.Retirement_Date,
    operandOptions: [
      OPERAND_OPTIONS.After,
      OPERAND_OPTIONS.Before,
      OPERAND_OPTIONS.In_Between,
      OPERAND_OPTIONS.Specific_Date,
      OPERAND_OPTIONS.Is_Empty,
      OPERAND_OPTIONS.Is_Not_Empty
    ],
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS
  },
};

export const CONTACT_DETAILS_FIELDS = {
  Core_Classification: 'Core Classification',
  Source: 'Source',
  Source_Category: 'Source Category',
  Rank: 'Rank',
  Status: 'Status',
  Date_Founded: 'Date Founded',
  Company_type: 'Company type',
  '#Employees': '#Employees',
  Annual_revenue: 'Annual revenue',
} as const;
const CONTACT_DETAILS: Record<ValueOf<typeof CONTACT_DETAILS_FIELDS>, FieldItemType> = {
  [CONTACT_DETAILS_FIELDS.Core_Classification]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Core_Classification,
    fieldLabel: CONTACT_DETAILS_FIELDS.Core_Classification,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: CONTACT_CLASSIFICATION
  },
  [CONTACT_DETAILS_FIELDS.Source]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Source,
    fieldLabel: CONTACT_DETAILS_FIELDS.Source,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
  },
  [CONTACT_DETAILS_FIELDS.Source_Category]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Source_Category,
    fieldLabel: CONTACT_DETAILS_FIELDS.Source_Category,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
  },
  [CONTACT_DETAILS_FIELDS.Rank]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Rank,
    fieldLabel: CONTACT_DETAILS_FIELDS.Rank,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: CONTACT_RANKS
  },
  [CONTACT_DETAILS_FIELDS.Status]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Status,
    fieldLabel: CONTACT_DETAILS_FIELDS.Status,
    operandOptions: [
      ...EQUAL_OR_NOT_EQUAL_TO_ALL,
      ...EQUAL_OR_NOT_EQUAL_TO_ANY
    ],
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: CONTACT_STATUSES_OPTIONS
  },
  [CONTACT_DETAILS_FIELDS.Date_Founded]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Date_Founded,
    fieldLabel: CONTACT_DETAILS_FIELDS.Date_Founded,
    operandOptions: [
      OPERAND_OPTIONS.After,
      OPERAND_OPTIONS.Before,
      OPERAND_OPTIONS.In_Between,
      OPERAND_OPTIONS.Specific_Date,
      OPERAND_OPTIONS.Is_Empty,
      OPERAND_OPTIONS.Is_Not_Empty
    ],
    applicability: BUSINESS
  },
  [CONTACT_DETAILS_FIELDS.Company_type]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Company_type,
    fieldLabel: 'Company Type',
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: BUSINESS,
    isMultiple: true,
    valueOptions: COMPANY_TYPE_OPTIONS
  },
  [CONTACT_DETAILS_FIELDS['#Employees']]: {
    fieldValue: CONTACT_DETAILS_FIELDS['#Employees'],
    fieldLabel: CONTACT_DETAILS_FIELDS['#Employees'],
    operandOptions: RANGE_COMPARISON,
    applicability: BUSINESS,
  },
  [CONTACT_DETAILS_FIELDS.Annual_revenue]: {
    fieldValue: CONTACT_DETAILS_FIELDS.Annual_revenue,
    fieldLabel: 'Annual Revenue',
    operandOptions: RANGE_COMPARISON,
    applicability: BUSINESS,
  },
};


export const CONTACT_INFORMATION_FIELDS = {
  Phone_Number: 'Phone Number',
  Email_Address: 'Email Address',
  Address_Street_Line_1: 'Address Street Line 1',
  Address_Street_Line_2: 'Address Street Line 2',
  Address_City: 'Address - City',
  Address_State: 'Address - State',
  Address_Zip_Code: 'Address - Zip Code',
  Address_Country: 'Address - Country',
  Contact_Preference: 'Contact Preference',
  Contact_Rules: 'Contact Rules'
} as const;
const CONTACT_INFORMATION: Record<ValueOf<typeof CONTACT_INFORMATION_FIELDS>, FieldItemType> = {
  [CONTACT_INFORMATION_FIELDS.Phone_Number]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Phone_Number,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Phone_Number,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 25
  },
  [CONTACT_INFORMATION_FIELDS.Email_Address]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Email_Address,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Email_Address,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 320
  },
  [CONTACT_INFORMATION_FIELDS.Address_Street_Line_1]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_Street_Line_1,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_Street_Line_1,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 50
  },
  [CONTACT_INFORMATION_FIELDS.Address_Street_Line_2]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_Street_Line_2,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_Street_Line_2,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 50
  },
  [CONTACT_INFORMATION_FIELDS.Address_City]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_City,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_City,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 50
  },
  [CONTACT_INFORMATION_FIELDS.Address_State]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_State,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_State,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
  },
  [CONTACT_INFORMATION_FIELDS.Address_Zip_Code]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_Zip_Code,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_Zip_Code,
    operandOptions: TEXT_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 9
  },
  [CONTACT_INFORMATION_FIELDS.Address_Country]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Address_Country,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Address_Country,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
  },
  [CONTACT_INFORMATION_FIELDS.Contact_Preference]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Contact_Preference,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Contact_Preference,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: CONTACT_PREFERENCE_OPTIONS
  },
  [CONTACT_INFORMATION_FIELDS.Contact_Rules]: {
    fieldValue: CONTACT_INFORMATION_FIELDS.Contact_Rules,
    fieldLabel: CONTACT_INFORMATION_FIELDS.Contact_Rules,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability: ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: CONTACT_RULES_OPTIONS
  },
};


export const FAMILY_DETAILS_FIELDS = {
  Children: 'Children',
  Childrens_Age: 'Children\'s Age',
} as const;
const FAMILY_DETAILS: Record<ValueOf<typeof FAMILY_DETAILS_FIELDS>, FieldItemType> = {
  [FAMILY_DETAILS_FIELDS.Children]: {
    fieldValue: FAMILY_DETAILS_FIELDS.Children,
    fieldLabel: FAMILY_DETAILS_FIELDS.Children,
    operandOptions: RANGE_COMPARISON,
    applicability: INDIVIDUALS_AND_HOUSEHOLD,
  },
  [FAMILY_DETAILS_FIELDS.Childrens_Age]: {
    fieldValue: FAMILY_DETAILS_FIELDS.Childrens_Age,
    fieldLabel: FAMILY_DETAILS_FIELDS.Childrens_Age,
    operandOptions: RANGE_COMPARISON,
    applicability: INDIVIDUALS_AND_HOUSEHOLD,
  },
};


export const OCCUPATION_FIELDS  = {
  '#Employees': '#Employees',
  Job_Title: 'Job Title'
} as const;
const OCCUPATION: Record<ValueOf<typeof OCCUPATION_FIELDS>, FieldItemType>  = {
  [OCCUPATION_FIELDS.Job_Title]: {
    fieldValue: OCCUPATION_FIELDS.Job_Title,
    fieldLabel: OCCUPATION_FIELDS.Job_Title,
    operandOptions: TEXT_COMPARISON,
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
    maxChars: 50
  },
  [OCCUPATION_FIELDS['#Employees']]: {
    fieldValue: OCCUPATION_FIELDS['#Employees'],
    fieldLabel: OCCUPATION_FIELDS['#Employees'],
    operandOptions: TEXT_COMPARISON,
    applicability: INDIVIDUALS_AND_HOUSEHOLD_MEMBERS,
    maxChars: 50
  }
};

// TODO: after create Opportunities tags
export const TAGS_FIELDS = {
  general: TAGS_TYPES.general,
  interest: TAGS_TYPES.interest,
} as const;

const TAGS: Record<ValueOf<typeof TAGS_FIELDS>, FieldItemType>  = {
  [TAGS_FIELDS.general]: {
    fieldValue: TAGS_FIELDS.general,
    fieldLabel: TAGS_FIELDS.general,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true
  },
  [TAGS_FIELDS.interest]: {
    fieldValue: TAGS_FIELDS.interest,
    fieldLabel: TAGS_FIELDS.interest,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability: INDIVIDUALS,
    isMultiple: true
  },
  // TODO: after create Opportunities tags
  // [TAGS_FIELDS.opportunities]: {
  //   fieldValue: TAGS_FIELDS.opportunities,
  //   fieldLabel: TAGS_FIELDS.opportunities,
  //   operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
  //   applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  //   isMultiple: true,
  // },
};

export const EVENTS_FIELDS  = {
  Attended_Events: 'Attended Events',
} as const;
const EVENTS :Record<ValueOf<typeof EVENTS_FIELDS>, FieldItemType>  = {
  [EVENTS_FIELDS.Attended_Events]: {
    fieldValue: EVENTS_FIELDS.Attended_Events,
    fieldLabel: EVENTS_FIELDS.Attended_Events,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: ATTENDED_EVENTS_OPTIONS
  }
};

export const FINANCIAL_ACCOUNTS_FIELDS  = {
  Account_Type: 'Account Type',
  Account_Sub_Type: 'Account Sub-Type',
  Institution_Name: 'Institution Name',
  Account_Balance: 'Account Balance',
} as const;
const FINANCIAL_ACCOUNTS :Record<ValueOf<typeof FINANCIAL_ACCOUNTS_FIELDS>, FieldItemType>  = {
  [FINANCIAL_ACCOUNTS_FIELDS.Account_Type]: {
    fieldValue: FINANCIAL_ACCOUNTS_FIELDS.Account_Type,
    fieldLabel: FINANCIAL_ACCOUNTS_FIELDS.Account_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: ACCOUNT_TYPES_OPTIONS
  },
  [FINANCIAL_ACCOUNTS_FIELDS.Account_Sub_Type]: {
    fieldValue: FINANCIAL_ACCOUNTS_FIELDS.Account_Sub_Type,
    fieldLabel: FINANCIAL_ACCOUNTS_FIELDS.Account_Sub_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: ACCOUNT_SUB_TYPE_OPTIONS
  },
  [FINANCIAL_ACCOUNTS_FIELDS.Institution_Name]: {
    fieldValue: FINANCIAL_ACCOUNTS_FIELDS.Institution_Name,
    fieldLabel: FINANCIAL_ACCOUNTS_FIELDS.Institution_Name,
    operandOptions: TEXT_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 50
  },
  [FINANCIAL_ACCOUNTS_FIELDS.Account_Balance]: {
    fieldValue: FINANCIAL_ACCOUNTS_FIELDS.Account_Balance,
    fieldLabel: FINANCIAL_ACCOUNTS_FIELDS.Account_Balance,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};


export const INSURANCE_POLICIES_FIELDS  = {
  Policy_Type: 'Policy Type',
  Policy_Sub_Type: 'Policy Sub-Type',
  Institution_Name: 'Institution Name',
  Policy_Balance: 'Policy Balance',
} as const;
const INSURANCE_POLICIES :Record<ValueOf<typeof INSURANCE_POLICIES_FIELDS>, FieldItemType>  = {
  [INSURANCE_POLICIES_FIELDS.Policy_Type]: {
    fieldValue: INSURANCE_POLICIES_FIELDS.Policy_Type,
    fieldLabel: INSURANCE_POLICIES_FIELDS.Policy_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: INSURANCE_POLICY_TYPE_OPTIONS
  },
  [INSURANCE_POLICIES_FIELDS.Policy_Sub_Type]: {
    fieldValue: INSURANCE_POLICIES_FIELDS.Policy_Sub_Type,
    fieldLabel: INSURANCE_POLICIES_FIELDS.Policy_Sub_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: INSURANCE_POLICY_SUB_TYPE_OPTIONS
  },
  [INSURANCE_POLICIES_FIELDS.Institution_Name]: {
    fieldValue: INSURANCE_POLICIES_FIELDS.Institution_Name,
    fieldLabel: INSURANCE_POLICIES_FIELDS.Institution_Name,
    operandOptions: TEXT_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    maxChars: 50,
  },
  [INSURANCE_POLICIES_FIELDS.Policy_Balance]: {
    fieldValue: INSURANCE_POLICIES_FIELDS.Policy_Balance,
    fieldLabel: INSURANCE_POLICIES_FIELDS.Policy_Balance,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};

export const ASSETS_FIELDS  = {
  Asset_Type: 'Asset Type',
  Asset_Value: 'Asset Value'
} as const;
const ASSETS :Record<ValueOf<typeof ASSETS_FIELDS>, FieldItemType>  = {
  [ASSETS_FIELDS.Asset_Type]: {
    fieldValue: ASSETS_FIELDS.Asset_Type,
    fieldLabel: ASSETS_FIELDS.Asset_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: ASSET_TYPE_OPTIONS
  },
  [ASSETS_FIELDS.Asset_Value]: {
    fieldValue: ASSETS_FIELDS.Asset_Value,
    fieldLabel: ASSETS_FIELDS.Asset_Value,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};

export const EXPENSES_FIELDS  = {
  Expense_Type: 'Expense Type',
  Expense_Value: 'Expense Value'
} as const;
const EXPENSES :Record<ValueOf<typeof EXPENSES_FIELDS>, FieldItemType>  = {
  [EXPENSES_FIELDS.Expense_Type]: {
    fieldValue: EXPENSES_FIELDS.Expense_Type,
    fieldLabel: EXPENSES_FIELDS.Expense_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: EXPENSES_TYPE_OPTIONS
  },
  [EXPENSES_FIELDS.Expense_Value]: {
    fieldValue: EXPENSES_FIELDS.Expense_Value,
    fieldLabel: EXPENSES_FIELDS.Expense_Value,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};


export const INCOME_FIELDS  = {
  Income_Type: 'Income Type',
  Income_Value: 'Income Value'
} as const;
const INCOME :Record<ValueOf<typeof INCOME_FIELDS>, FieldItemType>  = {
  [INCOME_FIELDS.Income_Type]: {
    fieldValue: INCOME_FIELDS.Income_Type,
    fieldLabel: INCOME_FIELDS.Income_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: INCOME_TYPE_OPTIONS
  },
  [INCOME_FIELDS.Income_Value]: {
    fieldValue: INCOME_FIELDS.Income_Value,
    fieldLabel: INCOME_FIELDS.Income_Value,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};


export const LIABILITY_FIELDS  = {
  Liability_Type: 'Liability Type',
  Liability_Value: 'Liability Value'
} as const;
const LIABILITY: Record<ValueOf<typeof LIABILITY_FIELDS>, FieldItemType>  = {
  [LIABILITY_FIELDS.Liability_Type]: {
    fieldValue: LIABILITY_FIELDS.Liability_Type,
    fieldLabel: LIABILITY_FIELDS.Liability_Type,
    operandOptions: EQUAL_TO_AND_EMPTY_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true,
    valueOptions: LIABILITY_TYPE_OPTIONS
  },
  [LIABILITY_FIELDS.Liability_Value]: {
    fieldValue: LIABILITY_FIELDS.Liability_Value,
    fieldLabel: LIABILITY_FIELDS.Liability_Value,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  },
};


export const MISC_FINANCIAL_DETAILS_FIELDS  = {
  Estimated_Net_Worth: 'Estimated Net Worth',
} as const;
const MISC_FINANCIAL_DETAILS :Record<ValueOf<typeof MISC_FINANCIAL_DETAILS_FIELDS>, FieldItemType>  = {
  [MISC_FINANCIAL_DETAILS_FIELDS.Estimated_Net_Worth]: {
    fieldValue: MISC_FINANCIAL_DETAILS_FIELDS.Estimated_Net_Worth,
    fieldLabel: MISC_FINANCIAL_DETAILS_FIELDS.Estimated_Net_Worth,
    operandOptions: RANGE_COMPARISON,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
  }
};


export const OFFICE_DETAILS_FIELDS  = {
  Primary_Advisor: 'Primary Advisor',
  Secondary_Advisor: 'Lead Planner',
  created_by: 'created_by'
} as const;
const OFFICE_DETAILS: Record<ValueOf<typeof OFFICE_DETAILS_FIELDS>, FieldItemType>  = {
  [OFFICE_DETAILS_FIELDS.Primary_Advisor]: {
    fieldValue: OFFICE_DETAILS_FIELDS.Primary_Advisor,
    fieldLabel: OFFICE_DETAILS_FIELDS.Primary_Advisor,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true
  },
  [OFFICE_DETAILS_FIELDS.Secondary_Advisor]: {
    fieldValue: OFFICE_DETAILS_FIELDS.Secondary_Advisor,
    fieldLabel: OFFICE_DETAILS_FIELDS.Secondary_Advisor,
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true
  },
  [OFFICE_DETAILS_FIELDS.created_by]: {
    fieldValue: OFFICE_DETAILS_FIELDS.created_by,
    fieldLabel: 'Created by',
    operandOptions: EQUAL_OR_NOT_EQUAL_TO_ANY,
    applicability:ALL_WITHOUT_HOUSEHOLD_MEMBERS,
    isMultiple: true
  },
};

type ConfigItemType = {
  [P: string]: FieldItemType
}

export const CRITERIA_CONFIG: Record<ValueOf<typeof CRITERIA_TYPES>, ConfigItemType> = {
  [CRITERIA_TYPES.Personal_Details]: PERSONAL_DETAILS,
  [CRITERIA_TYPES.Contact_Details]: CONTACT_DETAILS,
  [CRITERIA_TYPES.Contact_information]: CONTACT_INFORMATION,
  [CRITERIA_TYPES.Family_details]: FAMILY_DETAILS,
  [CRITERIA_TYPES.Occupation]: OCCUPATION,
  [CRITERIA_TYPES.Tags]: TAGS,
  [CRITERIA_TYPES.Events]: EVENTS,
  [CRITERIA_TYPES.Financial_Accounts]: FINANCIAL_ACCOUNTS,
  [CRITERIA_TYPES.Insurance_Policies]: INSURANCE_POLICIES,
  // [CRITERIA_TYPES.Assets]: ASSETS,
  // [CRITERIA_TYPES.Expense]: EXPENSES,
  // [CRITERIA_TYPES.Income]: INCOME,
  // [CRITERIA_TYPES.Liability]: LIABILITY,
  // [CRITERIA_TYPES.Misc_Financial]: MISC_FINANCIAL_DETAILS,
  [CRITERIA_TYPES.Office_details]: OFFICE_DETAILS,
};

export const CRITERIA_TYPES_LABELS = {
  [CRITERIA_TYPES.Personal_Details]: 'Personal Details',
  [CRITERIA_TYPES.Contact_Details]: 'Contact Details',
  [CRITERIA_TYPES.Contact_information]: 'Contact Information',
  [CRITERIA_TYPES.Family_details]: 'Family Details',
  [CRITERIA_TYPES.Occupation]: 'Occupation',
  [CRITERIA_TYPES.Tags]: 'Tags',
  [CRITERIA_TYPES.Events]: 'Events',
  [CRITERIA_TYPES.Financial_Accounts]: 'Financial Accounts',
  [CRITERIA_TYPES.Insurance_Policies]: 'Insurance Policies',
  // [CRITERIA_TYPES.Assets]: 'Assets',
  // [CRITERIA_TYPES.Expense]: 'Expenses',
  // [CRITERIA_TYPES.Income]: 'Income',
  // [CRITERIA_TYPES.Liability]: 'Liability',
  // [CRITERIA_TYPES.Misc_Financial]: 'Financial Details',
  [CRITERIA_TYPES.Office_details]: 'Office Details',
};


export const DATE_FIELDS = [
  PERSONAL_DETAILS_FIELDS.Birthdate,
  PERSONAL_DETAILS_FIELDS.Retirement_Date,
  CONTACT_DETAILS_FIELDS.Date_Founded,
];

export const INPUTS_TYPE_NUMBER_FIELDS = [
  PERSONAL_DETAILS_FIELDS.Age,
  CONTACT_DETAILS_FIELDS['#Employees'],
  CONTACT_DETAILS_FIELDS.Annual_revenue,
  FAMILY_DETAILS_FIELDS.Children,
  FAMILY_DETAILS_FIELDS.Childrens_Age,
  INSURANCE_POLICIES_FIELDS.Policy_Balance,
  ASSETS_FIELDS.Asset_Value,
  FINANCIAL_ACCOUNTS_FIELDS.Account_Balance,
  EXPENSES_FIELDS.Expense_Value,
  INCOME_FIELDS.Income_Value,
  LIABILITY_FIELDS.Liability_Value,
  MISC_FINANCIAL_DETAILS_FIELDS.Estimated_Net_Worth,
];

export const INPUTS_TYPE_TEXT_FIELDS = [
  CONTACT_INFORMATION_FIELDS.Phone_Number,
  CONTACT_INFORMATION_FIELDS.Email_Address,
  CONTACT_INFORMATION_FIELDS.Address_Street_Line_1,
  CONTACT_INFORMATION_FIELDS.Address_Street_Line_2,
  CONTACT_INFORMATION_FIELDS.Address_City,
  CONTACT_INFORMATION_FIELDS.Address_Zip_Code,
  OCCUPATION_FIELDS['#Employees'],
  OCCUPATION_FIELDS.Job_Title,
  FINANCIAL_ACCOUNTS_FIELDS.Institution_Name,
  INSURANCE_POLICIES_FIELDS.Institution_Name,
];

export const SINGLE_SELECTS = [
  PERSONAL_DETAILS_FIELDS.Gender,
  PERSONAL_DETAILS_FIELDS.Retirement_Status,
];

export const MULTIPLE_SELECTS = [
  PERSONAL_DETAILS_FIELDS.Birth_Month,
  CONTACT_DETAILS_FIELDS.Core_Classification,
  CONTACT_DETAILS_FIELDS.Source,
  CONTACT_DETAILS_FIELDS.Source_Category,
  CONTACT_DETAILS_FIELDS.Rank,
  CONTACT_DETAILS_FIELDS.Status,
  CONTACT_DETAILS_FIELDS.Company_type,
  CONTACT_INFORMATION_FIELDS.Address_State,
  CONTACT_INFORMATION_FIELDS.Address_Country,
  CONTACT_INFORMATION_FIELDS.Contact_Preference,
  CONTACT_INFORMATION_FIELDS.Contact_Rules,
  // TODO: after create Opportunities tags
  // TAGS_FIELDS.opportunities,
  EVENTS_FIELDS.Attended_Events,
  FINANCIAL_ACCOUNTS_FIELDS.Account_Type,
  FINANCIAL_ACCOUNTS_FIELDS.Account_Sub_Type,
  INSURANCE_POLICIES_FIELDS.Policy_Type,
  INSURANCE_POLICIES_FIELDS.Policy_Sub_Type,
  ASSETS_FIELDS.Asset_Type,
  EXPENSES_FIELDS.Expense_Type,
  INCOME_FIELDS.Income_Type,
  LIABILITY_FIELDS.Liability_Type,

  OFFICE_DETAILS_FIELDS.Primary_Advisor,
  OFFICE_DETAILS_FIELDS.Secondary_Advisor,
  OFFICE_DETAILS_FIELDS.created_by,

  TAGS_FIELDS.general,
  TAGS_FIELDS.interest,
];

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table from '@components/Table';
import { CustomTableRow } from './components';
import LimitAccessPopup from '@modules/LimitAccessPopup';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import { COLUMNS } from '@constants/contactsTableColumnsConfig';

import { ContactsTableProps, GoToContactDetails } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { MODAL_TYPE } from '@constants/modalTypes';

export const ContactsTable = observer(({
  addContactHandler,
  deleteContactHandler
}: ContactsTableProps) => {
  const contactsStore = useStore().ContactsStore;
  const routerStore = useStore().RouterStore;
  const modalStore   = useStore().ModalStore;

  const {
    table,
    isFetching,
    isPageActive,
  } = contactsStore;

  const goToContactDetails: GoToContactDetails = useCallback((id, contactType) => {
    routerStore.moveToContact({
      id,
      contactType,
      tab: CONTACT_DETAILS_TAB.overview,
    });
  }, [routerStore]);

  const showLimitedAccessPopup = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  const onRowBookmark = useCallback((id: IdType, state: boolean) => {
    contactsStore.updateFlags([{ id }], state);
  },[ contactsStore ]);

  const onRatingChange = useCallback((id: IdType, rating: number) => {
    contactsStore.updateRank(id, rating);
  }, [contactsStore]);

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: table.globalCheckboxState.checked,
            indeterminate: table.globalCheckboxState.indeterminate,
            onChange: table.onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isFetching && table.items.length > 0 &&
            table.items.map(contactItem => (
              <CustomTableRow
                data={ contactItem }
                deleteContactHandler={ deleteContactHandler }
                goToContactDetails={ goToContactDetails }
                isCheckboxActive={ table.getItemCheckboxState(contactItem) }
                key={ contactItem.id }
                onCheckboxClickCb={ table.onCheckBoxClick }
                onLimitedClick={ showLimitedAccessPopup }
                onRatingChangeCb={ onRatingChange }
                onRowBookmarkCb={ onRowBookmark }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isFetching && table.items.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              addActionButtonClickHandler={ addContactHandler }
              addActionButtonLabel={ 'Add New Contact' }
              withAction={ true }
            />
          </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});

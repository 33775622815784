import styled, { css } from 'styled-components';

import { ArrowStepperItemProps, ArrowStepperWrapperProps } from './types';
import { COLORS } from '@constants/colors';

const HEIGHT= 32;
const INACTIVE_BG_COLOR = COLORS.greyLight;
const ACTIVE_BG_COLOR = COLORS.orange;
const ARROW_BG_COLOR = COLORS.white;
const HOVER_COLOR = COLORS.select;
const TRANSITION = 0.3;

export const ArrowStepperWrapper = styled.div<ArrowStepperWrapperProps>`
  display: flex;
  
  ${({ $disabled }) => $disabled && css`
    opacity: 0.3;
    pointer-events: none;
  `}
`;


export const ArrowStepperLabel = styled.div`
  width: 112px;
  text-align: center;
  font-size: 12px;
`;

export const ArrowStepperDecoration = styled.div`
  position: relative;
  width: 13px;
  height: ${HEIGHT}px;
  background-color: ${ARROW_BG_COLOR};

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    z-index: 1;

    transition: 
            border-top-color ${TRANSITION}s, 
            border-bottom-color ${TRANSITION}s,
            border-left-color ${TRANSITION}s;
    
    border-top: 16px solid ${INACTIVE_BG_COLOR};
    border-bottom: 16px solid ${INACTIVE_BG_COLOR};
    border-left: 10px solid ${ARROW_BG_COLOR};
  }

  &:after {
    position: absolute;
    content: "";
    top: 0;
    right: 3px;
    z-index: 1;

    transition: border-left-color ${TRANSITION}s;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 10px solid ${INACTIVE_BG_COLOR};
  }
`;

export const ArrowStepperItem = styled.div<ArrowStepperItemProps>`
  display: flex;
  align-items: center;
  height: ${HEIGHT}px;

  background-color: ${INACTIVE_BG_COLOR};
  color: ${COLORS.primaryText};
  line-height: 1.5;
  cursor: pointer;
  transition: background-color ${TRANSITION}s;


  ${({ $isFirst }) => $isFirst && css`
    padding-left: 9px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

    & ${ArrowStepperDecoration} {
      display: none;
    }
  `}

  ${({ $isLast }) => $isLast && css`
    padding-right: 8px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  `}

  ${({ $isActive }) => $isActive && css`
    pointer-events: none;
    background-color: ${ACTIVE_BG_COLOR};
    color: ${COLORS.white};

    & > ${ArrowStepperDecoration}:before {
      border-top-color: ${ACTIVE_BG_COLOR};
      border-bottom-color: ${ACTIVE_BG_COLOR};
    }
    

    & + div > ${ArrowStepperDecoration}:after {
      border-left-color: ${ACTIVE_BG_COLOR};
    }
  `}
  
  ${({ $isActive }) => !$isActive && css`
    &:hover {
      background-color: ${HOVER_COLOR};
      
      & > ${ArrowStepperDecoration}:before {
        border-top-color: ${HOVER_COLOR};
        border-bottom-color: ${HOVER_COLOR};
      }
      
      & + div > ${ArrowStepperDecoration}:after {
        border-left-color: ${HOVER_COLOR};
      }
    }
  `}

`;

import styled, { css } from 'styled-components';
import { NotesWrapperProps } from '../../types';

export const Wrapper =  styled.div<NotesWrapperProps>`
  ${({ $padding }) => $padding && css`
    padding: ${$padding};
    box-sizing: border-box;
  `}
  
  flex-grow: 1;
  
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;




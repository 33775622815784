import {
  ConvertedGridAndKanbanFilterDataType,
  GridAndKanbanFiltersState,
  SalesActiveCycleByStage,
  SalesCycleKanbanSettings,
} from '@/shared/types/salesActiveCycle';
import { SALES_ACTIVE_CYCLE_STAGES } from '@constants/salesActiveCycle';

export const INIT_FILTERS_DATA: ConvertedGridAndKanbanFilterDataType = {
  meetingStatus: [],
  officePrimaryAdvisor: [],
  salesCycleStatus: [],
  stage: [],
};

export const INIT_FILTERS_STATE: GridAndKanbanFiltersState = {
  meetingStatus: [],
  fullName: '',
  officePrimaryAdvisor: [],
  salesCycleStatus: [],
  stage: [],
};

export const INIT_KANBAN_SETTINGS_STATE: SalesCycleKanbanSettings = {
  websockets: {
    url: '',
    channelName: '',
  }
};

export const EMPTY_STAGE_COLUMNS: SalesActiveCycleByStage = {
  [SALES_ACTIVE_CYCLE_STAGES.firstAppt]: [],
  [SALES_ACTIVE_CYCLE_STAGES.secondAppt]: [],
  [SALES_ACTIVE_CYCLE_STAGES.thirdAppt]: [],
  [SALES_ACTIVE_CYCLE_STAGES.sendProposal]: [],
  [SALES_ACTIVE_CYCLE_STAGES.proposalReview]: [],
  [SALES_ACTIVE_CYCLE_STAGES.docuSigns]: [],
};

import styled from 'styled-components';

export const ButtonWrapper = styled.span`
  position: relative;
  width: 100%;
  height: 100%;
  
  & > * {
    position: absolute;
    left: 0;
  }
`;
import { TabDataType } from '@components/CustomTabs/types';

import {
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_USER_CUSTOM_FIELDS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_MANAGE_TAGS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS,
} from '@constants/routes/userSettings';

type TabType = {
  value: string
  link: string
}
export const TABS_CONFIG: Array<TabDataType<TabType>> = [
  {
    value: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.contactCustomFields,
    link: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH,
    label: 'Contact Custom Fields',
  },
  {
    value: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.userCustomFields,
    link: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_USER_CUSTOM_FIELDS_PATH,
    label: 'User Custom Fields',
  },
  {
    value: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.manageTags,
    link: USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_MANAGE_TAGS_PATH,
    label: 'Manage Tags',
  }
];

import { RelatedItem } from '@services/store/relatedContactsStore/types';
import {
  RelatedContactFields
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/types';

import { RELATION_TYPES } from '@constants/common';

export const getDefaultState = (dataToEdit?: RelatedItem): RelatedContactFields => {
  if(!dataToEdit) {
    return {
      birthdate: '',
      firstName: '',
      id: '',
      lastName: '',
      referral: 'No',
      referralDate: null,
      relationType: RELATION_TYPES.SPOUSE,
      relativeDescription: '',
      relativeId: null,
    };
  }
  
  return {
    birthdate: dataToEdit.clientContact.birthdate,
    firstName: '',
    id: dataToEdit.id,
    lastName: '',
    referral: dataToEdit.referral,
    referralDate: dataToEdit.referralDate || '',
    relationType: dataToEdit.relationType,
    relativeDescription: dataToEdit.relativeDescription,
    relativeId: dataToEdit.clientContact.id,
  };
};

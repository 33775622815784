import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { InfoIconWrapperProps } from './types';
import MuiBox from '@material-ui/core/Box';

export const Wrapper = styled(MuiBox)<InfoIconWrapperProps>`
  width: 20px;
  height: 20px;
  
  & > svg {
    width: 20px;
    height: 20px;
    
    ${({ $color }) => $color && css`
      fill: ${$color};
    `}

    ${({ disabled }) => disabled && css`
      fill: ${COLORS.grey};
    `}

    & > path {
      fill: inherit;
    }
  }

  &:hover {
    & > svg {
      ${({ $hoverColor }) => $hoverColor && css`
        fill: ${$hoverColor};
      `}

      ${({ disabled }) => disabled && css`
        fill: ${COLORS.grey};
      `}

      & > path {
        fill: inherit;
      }
    }
  }
`;

import React from 'react';
import format from 'date-fns/format';

import { OutOfSightActivity } from '@components/OutOfSight';

import { COLORS_MAP_BY_EVENT_TYPE } from '@constants/colorsByEventTypes';
import { TIME_FORMAT } from '@constants/dateFormats';

import {
  Date,
  Name,
  Wrapper
} from './styles';

const DATE_TIME_FORMAT = `MMMM MM, ${TIME_FORMAT}`;

export const PopoverEventItem = ({ event, isBlocked }) => {
  const {
    start,
    end,
    title,
    resource: {
      type,
    }
  } = event;
  const { indicatorColor } = COLORS_MAP_BY_EVENT_TYPE[type] || {};

  return (
    <Wrapper $indicatorColor={ indicatorColor }>
      <Name>
        { title }
      </Name>
      <Date>
        {`${format(start, DATE_TIME_FORMAT)} - ${format(end, TIME_FORMAT)}`}
      </Date>
      { isBlocked && <OutOfSightActivity/> }
    </Wrapper>
  );
};

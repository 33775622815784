import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextEditor from '@/modules/HookFormComponents/HFTextEditor';

import { FORM_BLOCK_MARGIN_BOTTOM } from '@/pages/NewUserSettings/data';

import { HeaderWrapper } from '@pages/NewUserSettings/components/Layout/styles';
import { TextEditorWrapper } from './styles';

import { CommonProfileInformationFormProps } from '@/pages/NewUserSettings/types';

export const ComplianceInformationEmailSignature = ({
  inputNames,
}: CommonProfileInformationFormProps) => {

  const { control } = useFormContext();

  return (
    <Fragment>
      <FormBlock marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
        <HeaderWrapper>
          <FormBlock.Header title={ 'Compliance Information' }/>
        </HeaderWrapper>
        <FormBlock.RowWrapper marginBottom={ '20px' }>
          <TextEditorWrapper>
            <HFTextEditor
              control={ control }
              name={ inputNames.compliance }
            />
          </TextEditorWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>

      <FormBlock isBorderBottom={ false }>
        <HeaderWrapper>
          <FormBlock.Header title={ 'Email Signature' }/>
        </HeaderWrapper>
        <FormBlock.RowWrapper>
          <TextEditorWrapper>
            <HFTextEditor
              control={ control }
              name={ inputNames.emailSignature }
            />
          </TextEditorWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </Fragment>
  );
};

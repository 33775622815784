import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useController, useFormContext } from 'react-hook-form';
import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import { ACCOUNTS_AND_PLANS_TYPES_BUTTONS } from './data';
import { COMMON_FIELD_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';

import PopupButtonPanelItems from '@components/PopupButtonPanelItems';

import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

export const BtnPanel = observer(() => {
  const accountAndPlansPopupLocalStore = useAccountAndPlansPopupLocalStore();
  const { editId, isLoad } = accountAndPlansPopupLocalStore;
  const { isNotesPanelInAddOrEditMode } = accountAndPlansPopupLocalStore.notesUtilsStore;

  const { control } = useFormContext<FormFieldsValues>();
  const { field: { value } } = useController({
    name: COMMON_FIELD_NAMES.accountType,
    control,
  });

  const onChangeCb = useCallback((event: any, value: any) => {
    accountAndPlansPopupLocalStore.onTypeChange(value as ACCOUNTS_AND_PLANS_TYPES);
  },[accountAndPlansPopupLocalStore]);
  
  const buttonProps = useMemo(() => ({
    disabled: isLoad || isNotesPanelInAddOrEditMode || Boolean(editId)
  }), [isNotesPanelInAddOrEditMode, editId, isLoad]);

  return (
    <PopupButtonPanelItems
      ButtonProps={ buttonProps }
      currentActionType={ value }
      onClick={ onChangeCb }
      options={ ACCOUNTS_AND_PLANS_TYPES_BUTTONS }
    />
  );
});

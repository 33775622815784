import React, { useEffect, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { USER_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsUserCustomFields';
import { validationMessages } from '@constants/validationMessages';

import { FieldsFactoryProps } from './types';

import {
  FIELDS_NAMES, LEFT_COLUMN_WIDTH, RIGHT_COLUMN_WIDTH
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonCustomFields/components/CustomFieldPopup/data';

import {
  LeftColumnWrapper,
  RightColumnWrapper
  // eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonCustomFields/components/CustomFieldPopup/styles';

const textAreaInputProps = {
  placeholder: 'Enter Text',
  maxLength: 255,
  rows: 4,
  style: {
    margin: '-9px -6px'
  }
};

const textAreaTextFieldProps = {
  multiline: true,
};

export const FieldsFactory = ({
  isEditMode,
  nameOptions,
  options,
}: FieldsFactoryProps) => {

  const { control, clearErrors, setValue } = useFormContext();

  const customFieldId: number = useWatch({
    control,
    name: FIELDS_NAMES.customFieldId
  });

  let currentSelectedOption = useMemo(() => {
    if(!customFieldId){
      return null;
    }

    return options.find(item => item.id === customFieldId);
  },[customFieldId, options]);

  const currentType = currentSelectedOption?.type;

  const dropDownOptions = useMemo(() => {
    if(!currentSelectedOption){
      return [];
    }
    return currentSelectedOption.options.map(item => ({
      value: item.id,
      label: item.label
    }));
  },[currentSelectedOption]);


  useEffect(() => {
    if(!isEditMode){
      setValue(FIELDS_NAMES.value, '');
      setValue(FIELDS_NAMES.currentType, currentType);
      clearErrors(FIELDS_NAMES.value);
    }
  }, [clearErrors, currentType, isEditMode, setValue]);

  return (
    <>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <HFHiddenInput
            control={ control }
            defaultValue={ '' }
            name={ FIELDS_NAMES.currentType }
          />
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Name' }
          >
            <HFSelect
              ControllerProps={ {
                rules: {
                  required: validationMessages.required,
                }
              } }
              control={ control }
              disabled={ isEditMode }
              name={ FIELDS_NAMES.customFieldId }
              options={ nameOptions }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          {
            currentType && currentType !== USER_CUSTOM_FIELDS_TYPES.textArea &&
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              isRequired={ true }
              label={ 'Value' }
            >
              {
                currentType === USER_CUSTOM_FIELDS_TYPES.dropDownList &&
                <HFSelect
                  control={ control }
                  name={ FIELDS_NAMES.value }
                  options={ dropDownOptions as any } // to do
                  placeholder={ 'Please Select' }
                />
              }

              {
                currentType === USER_CUSTOM_FIELDS_TYPES.dateField &&
                <HFDatePicker
                  control={ control }
                  name={ FIELDS_NAMES.value }
                />
              }
              {
                currentType === USER_CUSTOM_FIELDS_TYPES.emailField &&
                <HFTextInput
                  control={ control }
                  name={ FIELDS_NAMES.value }
                  placeholder={ 'Enter Email Address' }
                />
              }
              {
                currentType === USER_CUSTOM_FIELDS_TYPES.phoneField &&
                <HFPhoneField
                  control={ control }
                  countryListWidth={ 310 }
                  name={ FIELDS_NAMES.value }
                />
              }
              {
                currentType === USER_CUSTOM_FIELDS_TYPES.textField &&
                <HFTextInput
                  control={ control }
                  inputProps={ {
                    maxLength: 255,
                  } }
                  name={ FIELDS_NAMES.value }
                  placeholder={ 'Enter Value' }
                />
              }
            </FormBlock.FormField>
          }
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      {
        currentType === USER_CUSTOM_FIELDS_TYPES.textArea &&
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Value' }
          >
            <HFTextInput
              TextFieldProps={ textAreaTextFieldProps }
              control={ control }
              inputProps={ textAreaInputProps }
              name={ FIELDS_NAMES.value }
              placeholder={ 'Enter Value' }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      }
    </>
  );
};



import { ColumnType } from '@components/Table/components/Header/types';

export const NAME_COLUMN_LEFT_PADDING = '42px';
export const COMMON_COLUMN_LEFT_PADDING = '10px';
export const COLUMNS: Array<ColumnType> = [
  {
    id: 'taskName',
    label: 'Task Name',
    style: {
      paddingLeft: NAME_COLUMN_LEFT_PADDING,
      width: '34%'
    },
  },
  {
    id: 'priority',
    label: 'Priority',
    style: {
      paddingLeft: COMMON_COLUMN_LEFT_PADDING,
      width: '18%'
    },
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    style: {
      paddingLeft: COMMON_COLUMN_LEFT_PADDING,
      width: '26%'
    },
  },
  {
    id: 'manager',
    label: 'Manager',
    style: {
      paddingLeft: COMMON_COLUMN_LEFT_PADDING,
      width: '21%'
    },
  },
];

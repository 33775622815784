import React from 'react';

import { Controller } from 'react-hook-form';

import { HFHiddenInputProps } from './types';

export const HFHiddenInput = ({
  ControllerProps,
  control,
  defaultValue,
  name,
}: HFHiddenInputProps) => (
  <Controller
    control={ control }
    defaultValue={ defaultValue }
    name= { name }
    render={ ({ field }) => <input type='hidden' { ...field }/> }
    { ...ControllerProps }
  />
);

import React from 'react';

import { AuthPageHeader } from '@components/AuthPageHeader';
import { NotificationBar } from '@/components/NotificationBar';

import {
  Label,
  Main,
  Title,
  Wrapper,
} from './styles';

import { ContainerProps } from './types';

export const Content = ({ children }: ContainerProps) => <Wrapper>{ children }</Wrapper>;

Content.Header = AuthPageHeader;
Content.Label = Label;
Content.Main = Main;
Content.InfoBar = NotificationBar;
Content.Title = Title;

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { COLORS } from '@constants/colors';
import {
  SALES_PIPELINE_BASE_STATUSES,
  SALES_PIPELINE_BASE_STATUSES_COLOR_MAP,
  SALES_PIPELINE_MEETING_STATUSES
} from './salesPipeline';

import { MapTypeNamesToValues } from '@/shared/types/commonTypes';
import { SalesActiveCycleType, SalesActiveCycleStatusType } from '@/shared/types/salesActiveCycle';
import { NoteItem } from '@/shared/types/note';
import { NoteTagItem } from '@/shared/types/tags';

export const SALES_ACTIVE_CYCLE_STAGES = {
  firstAppt: '1st Appt',
  secondAppt: '2nd Appt',
  thirdAppt: '3rd+ Appt',
  sendProposal: 'Send Proposal',
  proposalReview: 'Proposal Review',
  docuSigns: 'DocuSigns',
} as const;

export const SALES_ACTIVE_CYCLE_STAGES_OPTIONS = optionsFromValue(Object.values(SALES_ACTIVE_CYCLE_STAGES));

export const SALES_ACTIVE_CYCLE_STATUS = {
  ...SALES_PIPELINE_BASE_STATUSES
} as const;

export const SALES_ACTIVE_CYCLE_STATUS_OPTIONS = optionsFromValue(Object.values(SALES_ACTIVE_CYCLE_STATUS));

export const SALES_CYCLE_STATUS_COLOR_MAP: Record<SalesActiveCycleStatusType, string> =
  SALES_PIPELINE_BASE_STATUSES_COLOR_MAP;

export const SALES_ACTIVE_CYCLE_MEETING_STATUS = {
  ...SALES_PIPELINE_MEETING_STATUSES
} as const;

export const SALES_ACTIVE_CYCLE_MEETING_STATUS_OPTIONS = optionsFromValue(
  Object.values(SALES_ACTIVE_CYCLE_MEETING_STATUS)
);

export const SALES_ACTIVE_CYCLE_FIELD_NAMES:MapTypeNamesToValues<SalesActiveCycleType> ={
  amount: 'amount',
  cycleOpenDate: 'cycleOpenDate',
  id: 'id',
  keyNote: 'keyNote',
  contactId: 'contactId',
  meetingStatus: 'meetingStatus',
  nextAppointmentDate: 'nextAppointmentDate',
  notes: 'notes',
  salesCycleStatus: 'salesCycleStatus',
  sourceId: 'sourceId',
  stage: 'stage',
  wonDate: 'wonDate'
} as const;

export const SALES_ACTIVE_NOTES_MEMBERS_FIELD_NAMES:MapTypeNamesToValues<Required<NoteItem>> ={
  noteContactId: 'noteContactId',
  noteId: 'noteId',
  noteText: 'noteText',
  noteType: 'noteType',
  tags: 'tags',
  title: 'title'
};

export const SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES:MapTypeNamesToValues<NoteTagItem> ={
  id: 'id',
  value: 'value'
};

export const SALES_CYCLE_KANBAN_DND_TYPE = 'salesCycleKanban';


export const SALES_ACTIVE_CYCLE_FILTER_NAMES = {
  officePrimaryAdvisor: 'officePrimaryAdvisor',
  meetingStatus: 'meetingStatus',
  salesCycleStatus: 'salesCycleStatus',
  stage: 'stage',
  name: 'fullName',
} as const;

import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import { Store } from '@store';

import { addContactsToList, getListContacts, deleteContactsFromList } from '@services/api/lists/lists';
import { deleteContacts, updateContactFlags, updateContactRating } from '@services/api/contacts/contacts';

import {
  AddContactsToListData,
  ConvertedListContactsFiltersData,
  ListContactItem,
  ListContactsFiltersType,
  ListContactsGridResponse,
  ListDataDetailsType
} from '@/shared/types/lists';
import CommonTableStore from '@services/store/commonTableStore';
import { GlobalBookmarkSortAcc } from '@services/store/listsStore/subStores/listContactsStore/types';
import { ValueOf } from '@/shared/types/commonTypes';

import { contactsConverter, convertsFilterData } from './normalizers';
import { getDeleteOrRestoreItemsParams } from '@/shared/utils/getDeleteOrRestoreItemsParams';
import {
  getMultipleSortParams,
  getFilterParams,
  getFiltersCount,
  getGlobalFlagged,
} from '@/shared/utils/filterUtils';
import { filterItemsByPermission } from '@/shared/utils/filterItemsByPermission';

import { LIST_CONTACTS_TABLE_FILTER_NAMES } from '@constants/lists';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

class ListContactsStore {
  coreStore: Store;
  currentListDetails: ListDataDetailsType | null = null;
  filters: ListContactsFiltersType = {};
  filtersData: ConvertedListContactsFiltersData = {} as ConvertedListContactsFiltersData;
  isContactManagement: boolean = false;
  isListContactsFiltersOpen: boolean = false;
  isLoad: boolean = false;
  isPageActive: boolean = false;
  listId: number | null = null;
  onContactManagementChangeReaction: IReactionDisposer;
  onFiltersChangeReaction: IReactionDisposer;
  table: CommonTableStore<ListContactItem>;
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      addContactsToList: flow.bound,
      getListContacts: flow.bound,
      getListContactsWithLoad: flow.bound,
      init: flow.bound,
      removeContactsFromList: flow.bound,
    });

    this.coreStore = coreStore;
    this.table = new CommonTableStore<ListContactItem>({
      onGlobalFlaggedChangeReactionCallback: this.getListContactsWithLoad,
      onSortReactionCallback: this.getListContactsWithLoad,
      onPageChangeReactionCallback: this.getListContactsWithLoad,
      checkboxItemsProcessor: filterItemsByPermission
    });

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.contact
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.onContactManagementChangeReaction = this.createOnContactManagementChangeReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  *deleteContacts(arrayOfIds:Array<string | number>){
    try {
      yield deleteContacts(getDeleteOrRestoreItemsParams(arrayOfIds));

      yield this.getListContactsWithLoad();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(arrayOfIds);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *addContactsToList(data: AddContactsToListData, callback?: () => void){
    try {
      if(callback) {
        callback();
      }
      this.isLoad = true;
      this.table.selectedIDs = this.table.selectedIDs.filter(id => (
        data.contacts.findIndex((dataItem => String(dataItem) === String(id))) < 0
      ));

      yield addContactsToList(data);
      this.getListContactsWithLoad();
    } catch (error) {
      console.log(error);
    }
  }

  *getListContacts(){
    if(!this.isPageActive) { return; }

    try {
      const start = async () => {
        this.table.clearItems(true);
        const response: ListContactsGridResponse = await getListContacts({
          page: this.table.currentPage,
          listId: this.listId,
          ...getFilterParams(this.filters),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...(this.isContactManagement ? { newContacts: 1 } : {}),
          ...getGlobalFlagged(this.table.globalFlagged),
        });

        const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
        this.coreStore.ListsStore.setListName(response.data.data.data.listData.name);
        this.currentListDetails = response.data.data.data.listData;
        this.table.items = contactsConverter(response.data.data.data);
        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.filtersData = convertsFilterData(response.data.data.data.filterData, currentUserId);
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: Math.random() * 10000
        }),
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getListContactsWithLoad() {
    try {
      this.isLoad = true;
      yield this.getListContacts();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *init(listId: number) {
    this.isPageActive = true;
    if(listId !== this.listId){
      this.listId = listId;
    }
    try {
      this.isLoad = true;
      yield this.getListContacts();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  createOnContactManagementChangeReaction(){
    return reaction(
      () => this.isContactManagement,
      () => {
        if(this.isPageActive){
          this.table.setCurrentPageWithoutReaction(1);
          this.table.selectedIDs = [];
          this.getListContactsWithLoad();
        }
      }
    );
  }
  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive){
          this.table.selectedIDs = [];
          this.table.setCurrentPageWithoutReaction(1);
          this.getListContactsWithLoad();
        }
      }, 1500),
    );
  }


  get filtersCount () {
    return getFiltersCount(this.filters);
  }

  resetFilters= () => {
    this.filters = {};
  };

  *removeContactsFromList(ids: Array<string | number>){
    try {
      this.isLoad = true;

      yield deleteContactsFromList({
        id: this.currentListDetails!.id as number,
        contactsIds: ids,
      });

      this.table.refreshSelectedIds(ids);
      yield this.getListContactsWithLoad();
      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    }
  }

  resetState() {
    this.isPageActive = false;
    this.table.resetTable();
    this.listId = null;
    this.currentListDetails = null;
    this.isListContactsFiltersOpen = false;

    this.onContactManagementChangeReaction();
    this.isContactManagement = false;
    this.onContactManagementChangeReaction = this.createOnContactManagementChangeReaction();

    this.onFiltersChangeReaction();
    this.resetFilters();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  setFilterState= (name: ValueOf<typeof LIST_CONTACTS_TABLE_FILTER_NAMES>, value: Array<string> | string) =>{
    this.filters = {
      ...this.filters,
      [name]: value
    };
  };

  toggleContactManagement = () => {
    this.isContactManagement = !this.isContactManagement;
  };
  toggleFiltersState = () => {
    this.isListContactsFiltersOpen = !this.isListContactsFiltersOpen;
  };

  *updateBookmark(id: string | number | null){
    try {
      let { item } = this.table.getItemAndItemIndex(id);

      if(item) {
        let newState = item.flagged === 1 ? 0 : 1;

        yield updateContactFlags([{
          id: item.id,
          flagged: newState
        }]);


        if(this.isPageActive){
          this.table.updateItemById(item.id, { flagged: newState });
        }
      }
      if(this.table.globalFlagged) {
        this.getListContactsWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  *updateBookmarkGlobal(){
    try {
      const itemsIdsByState = this.table.items.reduce((acc: GlobalBookmarkSortAcc, item) => {
        if(item.flagged){
          acc.bookmark.push(item.id);
        } else {
          acc.unBookmark.push(item.id);
        }
        return acc;
      },{
        bookmark: [],
        unBookmark: []
      });

      if(itemsIdsByState.bookmark.length === this.table.items.length){

        yield updateContactFlags(
          this.table.items.map(item => ({
            id: item.id,
            flagged: 0
          }))
        );

        if(this.isPageActive) {
          this.table.items.forEach(item => {
            this.table.updateItemById(item.id, { flagged: 0 });
          });
        }
      } else {
        yield updateContactFlags(
          itemsIdsByState.unBookmark.map(id => ({
            id,
            flagged: 1
          }))
        );

        itemsIdsByState.unBookmark.forEach(id => {
          if(this.isPageActive) {
            this.table.updateItemById(id, { flagged: 1 });
          }
        });
      }

    } catch (error) {
      console.log(error);
    }
  }

  *updateRating(id: string | number, rating: number){
    yield updateContactRating({
      id,
      rating
    });

    if(this.isPageActive){
      this.table.updateItemById(id, { rating });
    }
  }
}

export default ListContactsStore;

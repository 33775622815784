import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  & > * {
    max-height: 620px;

    @media screen and (min-height: 1080px) {
      max-height: 764px;
    }
  }
`;

import React, { useCallback } from 'react';

import {
  Crop,
  FullCropBox,
  MoveArrows,
  ZoomIn,
  ZoomOut,
  RotateRight,
  RotateLeft
} from '@/assets';

import {
  ButtonsWrapper,
  IconContainer,
  SquareIconButtonWrapper,
  SquareIconMoveButtonWrapper
} from './styles';

import { SettingsButtonsProps } from './types';


export const SettingsButtons = ({
  cropMode,
  cropperRef,
  getCroppedImgUrl,
  setCropMode,
  setCroppedImg,
}: SettingsButtonsProps) => {

  const onCrop = useCallback(() => {
    if(cropMode) {
      const croppedImgUrl = getCroppedImgUrl();
      setCroppedImg(croppedImgUrl!);
    }
  }, [ cropMode ]);

  const onRotateCounterclockwise = useCallback(() => {
    if(cropMode) {
      const imageElement = cropperRef.current;
      imageElement?.cropper.rotate(-90);
    }
  }, [ cropMode, cropperRef ]);

  const onRotateClockwise = useCallback(() => {
    if(cropMode) {
      const imageElement = cropperRef.current;
      imageElement?.cropper.rotate(90);
    }
  }, [ cropMode, cropperRef ]);


  const onZoomIn = useCallback(() => {
    if(cropMode) {
      const imageElement = cropperRef.current;
      imageElement?.cropper.zoom(0.1);
    }
  }, [ cropMode, cropperRef ]);

  const onZoomOut = useCallback(() => {
    if(cropMode) {
      const imageElement = cropperRef.current;
      imageElement?.cropper.zoom(-0.1);
    }
  }, [ cropMode, cropperRef ]);

  const onClickMoveButton = useCallback(() => {
    setCropMode(!cropMode);
  }, [setCropMode, cropMode]);

  const resizeCropBox = useCallback(() => {
    if(cropMode) {
      const imageElement = cropperRef.current;
      if(imageElement) {
        const cropper = imageElement.cropper;
        const { width, height } = cropper.getContainerData();
        cropper.setCropBoxData({ width,height });
      }
    }
  }, [ cropMode, cropperRef ]);

  return (
    <ButtonsWrapper>
      <IconContainer isBorder={ true }>
        <SquareIconMoveButtonWrapper
          isCropMode={ cropMode }
          onClick={ onClickMoveButton }
          startIcon={ <MoveArrows/> }
        />

        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ onCrop }
          startIcon={ <Crop/> }
        />

        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ resizeCropBox }
          startIcon={ <FullCropBox/> }
        />
      </IconContainer>

      <IconContainer isBorder={ true }>
        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ onRotateClockwise }
          startIcon={ <RotateRight/> }
        />

        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ onRotateCounterclockwise }
          startIcon={ <RotateLeft/> }
        />
      </IconContainer>

      <IconContainer>
        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ onZoomIn }
          startIcon={ <ZoomIn/> }
        />
        <SquareIconButtonWrapper
          disabled={ !cropMode }
          onClick={ onZoomOut }
          startIcon={ <ZoomOut/> }
        />
      </IconContainer>
    </ButtonsWrapper>
  );
};

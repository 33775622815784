import React, { useEffect } from 'react';

import { useActivityPopupLocalStore } from '@/modules/ActivityPopup/store';

import { POPUP_RADIO_VALUES } from '@constants/common';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import RadioGroup, { useRadioBtn } from '@components/RadioGroup';

import { RADIO_BUTTONS } from './data';

import { Wrapper } from './styles';

import { DeleteActivityConfirmPopupProps } from './types';

export const DeleteActivityConfirmPopup = ({
  closeModal,
  modalProps: {
    removeData,
    onDelete,
  }
}: DeleteActivityConfirmPopupProps) => {

  const { radioValue, onChange } = useRadioBtn(POPUP_RADIO_VALUES.current);

  const onConfirmClick = () => {
    const data = {
      ...removeData.activityData,
      withRecurring: radioValue === POPUP_RADIO_VALUES.all
    };
    closeModal();
    onDelete(data);
  };

  return (
    <ConfirmationPopup
      cancelLabel={ 'No' }
      confirmLabel={ 'Yes' }
      isOpen={ true }
      message={ 'Are you want to delete activity?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    >
      {
        removeData?.isRecurring &&
        <Wrapper>
          {/* @ts-ignore */}
          <RadioGroup
            onChange={ onChange }
            options={ RADIO_BUTTONS }
            value={ radioValue }
          />
        </Wrapper>
      }
    </ConfirmationPopup>
  );
};

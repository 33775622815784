import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { SalesActiveCycleFormFields } from '../../../../types';
import { useSalesActivePopupStore } from '../../../../store';
import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from '../../../../data';
import { LeftWrapper,RightWrapper } from '../../../../styles';

import { SALES_ACTIVE_CYCLE_FIELD_NAMES } from '@constants/salesActiveCycle';

export const AdditionalDetilas = observer(() => {
  const { control } = useFormContext<SalesActiveCycleFormFields>();

  const {
    notesUtilsStore,
    sourceOptions
  } = useSalesActivePopupStore();

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Additional Details' } />
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Source' }
          >
            <HFSelect
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.sourceId }
              options={ sourceOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Won Date' }
          >
            <HFDatePicker
              control={ control }
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.wonDate }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const WrapperWithLabel = styled.div`
  max-width: 90px;
  margin-left: 12px;
`;

export const LabelWrapper = styled.div`
  height: 14px;
  color: ${COLORS.helpText};
  font-size: 10px;
`;

export const OpportunityStatusWrapper = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${COLORS.primaryText};
`;

import { buildPath } from '@/shared/utils/buildPath';
import { COMMON_PATH_PART } from '@constants/routes/common';

export const TRASH_TAB_LINKS = {
  accountsAndPlans: 'accounts-and-plans',
  activities: 'activities',
  clientOpportunities: 'client-opportunities',
  contacts: 'contacts',
  lists: 'lists',
  salesCycle: 'sales-cycles',
  tasks: 'tasks',
} as const;

export const TRASH_COMMON_PATH = buildPath([COMMON_PATH_PART, 'trash']);
export const TRASH_ROUTE =  buildPath([TRASH_COMMON_PATH, ':id']);

export const TRASH_ACCOUNT_AND_PLANS_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.accountsAndPlans]);
export const TRASH_ACTIVITIES_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.activities]);
export const TRASH_CONTACTS_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.contacts]);
export const TRASH_CLIENT_OPPORTUNITIES_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.clientOpportunities]);
export const TRASH_LISTS_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.lists]);
export const TRASH_SALES_CYCLES_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.salesCycle]);
export const TRASH_TASKS_PATH =  buildPath([TRASH_COMMON_PATH, TRASH_TAB_LINKS.tasks]);

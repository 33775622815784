import styled, { css } from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';

import { COLORS } from '@constants/colors';
import { INPUT_HEIGHT } from '@constants/style';

import { getInputsErrorStyles } from '@/shared/utils/getInputsErrorStyles';

import { DisablePortalWrapperProps, StyledAutocompleteProps } from './types';

export const DisablePortalWrapper = styled.div<DisablePortalWrapperProps>`
  position: relative;

  & .MuiAutocomplete-popperDisablePortal{
    top: ${INPUT_HEIGHT}px;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)<StyledAutocompleteProps>`
  min-width: 200px;
  flex: 1;
  background-color: ${COLORS.white};

  & .MuiAutocomplete-inputRoot {
    padding: 0 10px 0 0 !important ;
  }

  & .MuiAutocomplete-input {
    height: ${INPUT_HEIGHT}px;
    box-sizing: border-box;
    padding: 7px 10px !important;
    font-size: 14px;
  }

  & svg path {
    fill: ${ COLORS.grey };
  }

  &:hover svg path {
    fill: ${ COLORS.orange };
  }

  & .Mui-focused {
    & svg path {
      fill: ${ COLORS.orange };
    }
  }
  & .MuiFormControl-root{
    ${({ $showError }) => $showError && getInputsErrorStyles($showError)};
  }
`;

export const StyledPaper = styled(Paper)`
  & .MuiAutocomplete-option {
    padding: 0;
  }
`;

import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useUserCustomFieldPopupStore } from '../../store';

import { PlusWithCircle } from '@/assets';

import FormBlock from '@components/FormBlock';
import BlockButton from '@components/BlockButton';
import ContentCenter from '@components/ContentCenter';

import { OptionsWrapper } from './components';

import { LeftColumnWrapper } from '../../styles';

import { OPTIONS_ALERT_TYPES, OPTIONS_USE_FIELD_ARRAY_KEY } from '../../data';
import {
  USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES,
  USER_CUSTOM_FIELD_FORM_FIELDS,
  USER_CUSTOM_FIELDS_TYPES
} from '@constants/userSettingsUserCustomFields';

import { FieldOptionsProps,  } from './types';
import { onEdit } from '../../types';
import { UserCustomFieldFormFields } from '@/shared/types/userSettingsUserCustomFields';


export const FieldOptions = observer(({}: FieldOptionsProps) => {
  const { setAlertData, setIsOptionsInEditMode, isOptionsInEditMode } = useUserCustomFieldPopupStore();

  const [editItemIndex, setEditItemIndex ] = useState<null | number>(null);

  const {
    control,
    trigger,
    clearErrors ,
    setValue,
  } = useFormContext<UserCustomFieldFormFields>();
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: USER_CUSTOM_FIELD_FORM_FIELDS.options,
    keyName: OPTIONS_USE_FIELD_ARRAY_KEY
  });

  const typeValue = useWatch({
    control,
    name: USER_CUSTOM_FIELD_FORM_FIELDS.type
  });

  const checkIsUsed = useCallback((index: number) => {
    return Boolean(fields[index]?.isUsed);
  },[fields]);

  const onAddClickWrapper = useCallback(() => {
    clearErrors(USER_CUSTOM_FIELD_FORM_FIELDS.options);
    setIsOptionsInEditMode(true);
    append({
      id: null,
      label: '',
    });
    setEditItemIndex(fields.length);

  },[append, clearErrors, fields.length, setIsOptionsInEditMode]);

  const onEditClickWrapper = useCallback<onEdit>((index) => {
    const isUsed = checkIsUsed(index);
    const onEdit = () => {
      setIsOptionsInEditMode(true);
      setEditItemIndex(index);
    };

    if (isUsed) {
      setAlertData(OPTIONS_ALERT_TYPES.edit, onEdit, () => {});
    } else {
      onEdit();
    }

  },[checkIsUsed, setAlertData, setIsOptionsInEditMode]);

  const onSaveClickWrapper = useCallback<onEdit>(async (index) => {
    const isValid = await trigger(
      `${USER_CUSTOM_FIELD_FORM_FIELDS.options}.${index}.${USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES.label}`
    );

    if(isValid){
      setIsOptionsInEditMode(false);
      setEditItemIndex(null);
    }
  },[setIsOptionsInEditMode, trigger]);

  const onDeleteClickWrapper = useCallback((index: number) => {
    const isUsed = checkIsUsed(index);
    const deleteItem = () => remove(index);

    if(isUsed) {
      setAlertData(OPTIONS_ALERT_TYPES.remove, deleteItem, () => {});
    } else {
      deleteItem();
    }

  },[checkIsUsed, remove, setAlertData]);

  useEffect(() => {
    if(typeValue !== USER_CUSTOM_FIELDS_TYPES.dropDownList){
      setIsOptionsInEditMode(false);
      setValue(USER_CUSTOM_FIELD_FORM_FIELDS.options, []);
    }
  }, [typeValue]);

  if(typeValue !== USER_CUSTOM_FIELDS_TYPES.dropDownList){
    return  null;
  }

  return (
    <LeftColumnWrapper>
      <FormBlock isBorderBottom={ false }>
        <FormBlock.Header title={ 'Field Options' } >
          <ContentCenter
            $width={ '120px' }
            acceptStylesToChild={ true }
          >
            <BlockButton
              $iconSize={ '16px' }
              disabled={ isOptionsInEditMode }
              onClick={ onAddClickWrapper }
              startIcon={ <PlusWithCircle/> }
            >
              Add Option
            </BlockButton>
          </ContentCenter>
        </FormBlock.Header>
        <OptionsWrapper
          editIndex={ editItemIndex }
          fields={ fields }
          onDelete={ onDeleteClickWrapper }
          onEdit={ onEditClickWrapper }
          onSave={ onSaveClickWrapper }
        />
      </FormBlock>
    </LeftColumnWrapper>
  );
});

import React, { MouseEvent, useCallback } from 'react';

import { StyledTypeBtn } from './styles';

import { TypeButtonProps } from './types';

export const TypeButton = ({ 
  BtnProps,
  children,
  isActive,
  onClick,
  value,
}: TypeButtonProps) => {
  const clickHandler = useCallback((event: MouseEvent) => onClick(event, value), [onClick, value]);

  return (
    <StyledTypeBtn
      $isActive={ isActive }
      onClick={ clickHandler }
      variant='outlined'
      { ...BtnProps }
    >
      { children }
    </StyledTypeBtn>
  );
};

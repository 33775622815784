import { useEffect, useRef } from 'react';

export const usePrevState = <T>(value: T, compareMode: boolean = false): T | null => {
  const ref = useRef<T | null>();

  useEffect(() => {
    if(compareMode){
      if(value === ref.current){
        return;
      } else {
        ref.current = value;
      }
    } else {
      ref.current = value;
    }
  },[compareMode, value]);

  return ref.current || null;
};

import styled from 'styled-components';

export const StatusSelectWrapper = styled.div`
    width: 105px;
    margin-right: 10px;
    flex-shrink: 0;
`;

export const TextEllipsis = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
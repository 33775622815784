import React from 'react';

import { EyeSash } from '@/assets';

import {
  ActivityItem,
  ActivityItemIcon,
  ActivityItemText,
  OutOfSightActivityWrapper,
  Wrapper
} from './styles';

import { OutOfSightActivityProps } from './types';

export const OutOfSightActivity = ({
  $marginLeft,
  isShowEye = true,
  isShowText = true,
  onClick = () => {},
}: OutOfSightActivityProps) => (
  <OutOfSightActivityWrapper onClick={ onClick } $marginLeft={ $marginLeft }>
    <Wrapper>
      <ActivityItem>
        {
          isShowEye &&
          <ActivityItemIcon>
            <EyeSash />
          </ActivityItemIcon>
        }
        { 
          isShowText &&
          <ActivityItemText>
            {'Out of sight'}
          </ActivityItemText>
        }
      </ActivityItem>
    </Wrapper>
  </OutOfSightActivityWrapper>
);

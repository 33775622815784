import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStore } from '@store';

import AvatarLine from '@components/AvatarLine';
import Counter from '@components/Counter';
import FilterButton from '@components/FilterButton';
import InfoIcon, { INFO_ICON_TYPES, INFO_COLOR_SCHEME } from '@components/InfoIcon';
import ToggleWithInnerLabels from '@components/ToggleWithInnerLabels';
import UserItem from '@components/NewUserItem';

import {
  INIT_CRITERION_WITHOUT_VALUE,
  LIST_CRITERIA_ROOT_NAME, LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_OFF, LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_ON,
  OPERAND_OPTIONS,
} from '@constants/lists';

import { ListCriteriaHeaderProps } from './types';
import { AvatarData, getAvatarData } from '@/shared/utils/getAvatarData';
import { CriteriaFormWrapper } from '@/shared/types/lists';

import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

import {
  ActionsWrapper,
  ContactsLabel,
  ContactsWrapper,
  CounterWrapper,
  CriteriaLabel,
  FirstRow, InfoWrapper,
  SecondRow,
  ToggleWrapper,
  Wrapper
} from './styles';

export const ListCriteriaHeader = observer(({
  append,
}: ListCriteriaHeaderProps) => {
  const { setGlobalOperand, globalOperand, contactsData, currentListDetails } = useStore().ListsStore.ListCriteriaStore;
  const { control, setValue, getValues,handleSubmit } = useFormContext<CriteriaFormWrapper>();

  const allCriteriaItems = useWatch({
    control,
    name: LIST_CRITERIA_ROOT_NAME,
  });

  const contactAvatarData = useMemo(() => {
    if(!contactsData){
      return [];
    }
    return convertObjectWithNumericKeysToArray<AvatarData>(contactsData, getAvatarData);
  }, [contactsData]);

  const alertText = useMemo(() => {
    return currentListDetails?.splitHousehold
      ? LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_ON
      : LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_OFF;
  }, [currentListDetails?.splitHousehold]);

  const onAdd = useCallback(async () => {
    const isWithOperand = allCriteriaItems?.length > 0;
    await append({
      ...(
        isWithOperand
          ? { operand: globalOperand as 'or' | 'and' }
          : {}
      ),
      criteria: [
        {
          // @ts-ignore
          criterion: INIT_CRITERION_WITHOUT_VALUE
        }
      ]
    });
  },[allCriteriaItems?.length, append, globalOperand]);

  // @ts-ignore
  return (
    <Wrapper>
      <FirstRow>
        <CounterWrapper>
          <CriteriaLabel>
            Criteria
          </CriteriaLabel>
          <Counter>
            { allCriteriaItems?.length || 0 }
          </Counter>
        </CounterWrapper>
      </FirstRow>
      <SecondRow>
        <ContactsWrapper>
          <ContactsLabel>
            Contacts in List:
          </ContactsLabel>
          {
            contactAvatarData?.length === 0 &&
            <AvatarLine
              count={ 0 }
              isHideCounter={ false }
              items={ contactAvatarData }
            />
          }
          {
            contactAvatarData?.length > 1 &&
            <AvatarLine
              count={ contactsData?.count || 0 }
              isHideCounter={ contactsData?.count <= 0 && contactAvatarData?.length > 0 }
              items={ contactAvatarData }
            />
          }
          {
            contactAvatarData?.length === 1 && contactsData?.[0] &&
            <UserItem
              labelWidth={ 300 }
              user={ contactsData?.[0] }
            />
          }
        </ContactsWrapper>
        <InfoWrapper>
          <InfoIcon
            colorScheme={ INFO_COLOR_SCHEME.onlyRed }
            helpText={ alertText }
            type={ INFO_ICON_TYPES.alertInfoFilled }
          />
        </InfoWrapper>
        <ActionsWrapper>
          <ToggleWrapper>
            <ToggleWithInnerLabels
              onChange={ setGlobalOperand }
              options={ OPERAND_OPTIONS }
              value={ globalOperand }
            />
          </ToggleWrapper>
          <FilterButton
            badgeClickHandler={ onAdd }
            badgeCount={ 0 }
            buttonCLickHandler={ onAdd }
            enableFilterMode={ false }
            label={ 'Add New Criteria' }
            withIcon={ false }
          />
        </ActionsWrapper>
      </SecondRow>
    </Wrapper>);
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';
import CustomFieldsAndTagsContent from '@modules/UserSettings/CustomFieldsAndTagsContent';
import HelperText from '@components/HelperText';

import { ListItem } from '..';

import { SalesAppointmentProps } from './types';
import { CONTACT_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsContactCustomFields';


export const SalesAppointment = observer(({
  onAddEdit,
  onDelete,
  store,
}: SalesAppointmentProps) => {
  const {
    items,
    onDrop,
    onMove
  } = store;

  const onAdd = useCallback(() => {
    onAddEdit({
      field: CONTACT_CUSTOM_FIELDS_TYPES.appointmentType
    });
  } , [onAddEdit]);

  return (
    <CustomFieldsAndTagsContent
      wrapperProps={ {
        maxHeight: 'inherit',
      } }
    >
      <CustomFieldsAndTagsContent.Header title={ 'Sales Appointment' }/>
      <CustomFieldsAndTagsContent.ActionRow
        marginBottom={ '10px' }
        onClick={ onAdd }
      >
        <HelperText
          marginRight={ '10px' }
          text={ 'Appointment Types' }
        />
        <InfoIcon
          colorScheme={ INFO_COLOR_SCHEME.mainReversed }
          helpText={ 'List of other options that display for appointment types' }
          type={ INFO_ICON_TYPES.alertInfo }
        />
      </CustomFieldsAndTagsContent.ActionRow>
      <CustomFieldsAndTagsContent.Content padding={ '10px 0' }>
        <DndProvider backend={ HTML5Backend }>
          {
            items.map((item, index) => (
              <ListItem
                index={ index }
                item={ item }
                key={ item.id }
                onDelete={ onDelete }
                onDrop={ onDrop }
                onEdit={ onAddEdit }
                onMove={ onMove }
              />
            ))
          }
        </DndProvider>
      </CustomFieldsAndTagsContent.Content>
    </CustomFieldsAndTagsContent>
  );
});

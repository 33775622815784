import { differenceInDays, format, isBefore } from 'date-fns';

import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';
import { parseFormatedUTCDateString } from '@/shared/utils/parseFormatedUTCDateString';

// need to include current day to 'days before activity' counter
const ONE_DAY = 1;

export const getActivityStartDateFields = (activityStartDate: string) => {

  const UTCActivityStartDate = parseFormatedUTCDateString(activityStartDate);
  const isBeforeCurrentDate = isBefore(UTCActivityStartDate, new Date());
  const startDate = isBeforeCurrentDate ? new Date() : UTCActivityStartDate;

  const activityDate = format(startDate, MONTH_DAY_YEAR_BACKSLASH);
  const numberOfDaysBeforeActivity = differenceInDays(new Date(activityDate), new Date()) + ONE_DAY;
  const typeName = numberOfDaysBeforeActivity > 1 ? 'days' : 'day';
  
  return {
    activityDate,
    numberOfDaysBeforeActivity: isBeforeCurrentDate ? 0 : numberOfDaysBeforeActivity,
    typeName
  };
};
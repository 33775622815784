import React from 'react';

import { StyledCheckbox } from './styles';

import {
  IndeterminateCheckbox,
  IndeterminateCheckboxDisabled,
  SelectedCheckbox,
  SelectedCheckboxDisabled,
  UnSelectedCheckbox,
  UnSelectedDisableCheckbox
} from '@/assets';

import { SIZES } from './data';

import { CustomCheckboxWithoutLabelProps } from './types';

export const CustomCheckboxWithoutLabel = ({
  $size = SIZES.small,
  $withPaddings = true,
  checked,
  disabled,
  indeterminate,
  onChange,
  CheckboxProps
}:CustomCheckboxWithoutLabelProps) => (
  <StyledCheckbox
    $size={ $size }
    $withPaddings={ $withPaddings }
    checked={ checked }
    checkedIcon={ disabled ? <SelectedCheckboxDisabled/> : <SelectedCheckbox/> }
    color={ 'primary' }
    disabled={ disabled }
    icon={ disabled ? <UnSelectedDisableCheckbox /> : <UnSelectedCheckbox/> }
    indeterminate={ indeterminate }
    indeterminateIcon={ disabled ? <IndeterminateCheckboxDisabled /> : <IndeterminateCheckbox/> }
    onChange={ onChange }
    { ...CheckboxProps }
  />
);
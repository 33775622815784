import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { CONTACT_CLASSIFICATION, CONTACT_STATUSES_OPTIONS } from '@constants/common';
import { validationMessages } from '@constants/validationMessages';

import CoreClassificationSelectOption from '@components/CoreClassificationSelectOption';
import FormBlock from '@components/FormBlock';
import HFRank from '@modules/HookFormComponents/HFRank';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { StatusProps } from './types';
import { RenderItemArg } from '@components/CustomSelect/types';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import {
  LeftColumnWrapper,
  RightColumnWrapper
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/Layout';

export const Status = ({
  classificationName,
  contactType,
  headOfHouseHoldName,
  headOfHouseHoldOptions,
  isHousehold,
  ratingName,
  renderContactTypeBlock,
  statusName,
}: StatusProps) => {
  const { control } = useFormContext();

  const renderCoreClassification = useCallback((arg: RenderItemArg) => {
    if(!Array.isArray(arg)){
      return (
        <CoreClassificationSelectOption label={ arg.label } value={ arg.value } />
      );
    }
  }, []); 

  return (
    <FormBlock
      isBorderBottom
      marginBottom={ 20 }
      paddingBottom={ isHousehold ? 20 : 5 }
    >
      <FormBlock.Header title={ 'Status' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Core Classification' }
          >
            <HFSelect 
              control={ control }
              name={ classificationName }
              options={ CONTACT_CLASSIFICATION }
              renderItem={ renderCoreClassification }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Contact Type' }
          >
            <FormBlock.VerticalCenteredWrapper>
              { contactType }
              { renderContactTypeBlock && renderContactTypeBlock() }
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Contact Status' }
          >
            <HFSelect
              ControllerProps={ {
                rules: { required: validationMessages.required }
              } }
              control={ control }
              name={ statusName }
              options={ CONTACT_STATUSES_OPTIONS }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          {
            !isHousehold &&
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'Rank' }
            >
              <FormBlock.VerticalCenteredWrapper>
                <HFRank
                  control={ control }
                  name={ ratingName }
                />
              </FormBlock.VerticalCenteredWrapper>
            </FormBlock.FormField>
          }
          {
            isHousehold &&
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'Head of Household' }
            >
              <HFSelect
                control={ control }
                name={ headOfHouseHoldName }
                options={ headOfHouseHoldOptions }
                resettable={ true }
              />
            </FormBlock.FormField>
          }
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      {
        isHousehold &&
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Rank' }
            >
              <FormBlock.VerticalCenteredWrapper>
                <HFRank
                  control={ control }
                  name={ ratingName }
                />
              </FormBlock.VerticalCenteredWrapper>
            </FormBlock.FormField>
          </LeftColumnWrapper>
        </FormBlock.RowWrapper>
      }
    </FormBlock>
  );
};

export const NOTIFICATION_TYPES = {
  default: 'default',
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
} as const;

export const NOTIFICATION_MESSAGE_ACTIONS = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  remove: 'remove',
  load: 'load',
  restore: 'restore',
} as const;

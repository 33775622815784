import { CreatePasswordFieldNames } from './types';

export const FORM_FIELD_NAMES: CreatePasswordFieldNames = {
  confirmPassword: 'confirmPassword',
  newPassword: 'newPassword',
};

export const FORM_DEFAULTS_VALUES = {
  [FORM_FIELD_NAMES.confirmPassword]: '',
  [FORM_FIELD_NAMES.newPassword]: '',
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const WrapperSelectUser = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 20px;
  margin-bottom: -25px;
  box-sizing: border-box;

  & > div {
    flex-grow: 1;
  }
`;

export const WrapperPopupChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 0px 20px 0 20px;
`;

export const WrapperText = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 12px;
`;

export const BlockWithCircle = styled.div`
  &::before {
    content: '';
    position: relative;
    bottom: 1px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border: 2px solid ${COLORS.red};
    border-radius: 100%;
  }
`;

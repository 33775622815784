import RelatedContactsStore from '@services/store/relatedContactsStore/relatedContactsStore';
import { ContactRelatedItem, RelatedItemFields } from '@/shared/types/contact';
import { RelatedItem } from '@services/store/relatedContactsStore/types';
import { RELATION_TYPES } from '@constants/common';

export const getParams = (store: RelatedContactsStore) => {
  const sortData = Object.entries(store.table.multipleSorting);
  if (sortData.length > 0) {
    return {
      filters: {
        order: sortData.reduce((acc, sortData) => (
          {
            ...acc,
            [sortData[0]]: sortData[1]
          }
        ), {})
      }
    };
  }

  return {};
};

export const prePareDataForSpouseChange = (
  item:ContactRelatedItem | RelatedItem,
  toSpouse?: boolean
): RelatedItemFields => ({
  id: item.id,
  relationType: toSpouse ? RELATION_TYPES.SPOUSE : RELATION_TYPES.OTHER,
  referral: item.referral,
  referralDate: item.referralDate,
  relativeDescription: item.relativeDescription,
  relativeId: item.relativeId,
});

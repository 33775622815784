import { MapTypeNamesToValues } from '@/shared/types/commonTypes';
import { OccupationItemType } from '@/shared/types/contact';


export const KEY_NAME = 'useFormOccupationId';
export const OCCUPATIONS_FIELDS_NAMES: MapTypeNamesToValues<Required<OccupationItemType>> = {
  companyName: 'companyName',
  current: 'current',
  end: 'end',
  id: 'id',
  start: 'start',
  title: 'title',
  useAsTitle: 'useAsTitle',
};

export const OCCUPATION_ITEM_DEFAULT_VALUE: OccupationItemType = {
  companyName: '',
  current: 'No',
  end: '',
  start: '',
  title: '',
  useAsTitle: 'No',
};
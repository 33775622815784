import { IdType } from '@/shared/types/commonTypes';
import { ConvertedGridAndKanbanFilterDataType, GridAndKanbanFilterDataType } from '@/shared/types/salesActiveCycle';
import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { SALES_ACTIVE_CYCLE_FILTER_NAMES } from '@constants/salesActiveCycle';

export const convertFilterData = (
  data: GridAndKanbanFilterDataType,
  currentUserId: IdType
): ConvertedGridAndKanbanFilterDataType => {
  return {
    [SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor]: getSortedUserOption(data.clients, currentUserId),
    [SALES_ACTIVE_CYCLE_FILTER_NAMES.meetingStatus]: optionsFromValue(data.meetingStatus),
    [SALES_ACTIVE_CYCLE_FILTER_NAMES.salesCycleStatus]: optionsFromValue(data.salesCycleStatus),
    [SALES_ACTIVE_CYCLE_FILTER_NAMES.stage]: optionsFromValue(data.stage),
  };
};

import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';

import { FieldsFactory } from './components';
import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import Preloader from '@components/Preloader';

import { CustomFieldFormFields, CustomFieldPopupProps } from './types';

import { FIELDS_NAMES } from './data';
import { validationSchema } from './validationScheme';

export const CustomFieldPopup = observer(({
  closeModal,
  modalProps,
}: CustomFieldPopupProps) => {
  const customFieldStore = useStore().ContactCustomFields;
  const { isLoad } = useStore().ContactDetailsStore;
  const { customFieldsSettings, table } = customFieldStore;

  const { dataToEdit } = modalProps;
  const isEditMode = Boolean(dataToEdit);
  const headerTitle = `${isEditMode ? 'Edit' : 'Add'} Contact Custom Fields`;

  const nameOptions = useMemo(() => {
    return customFieldsSettings.map(field => {
      return {
        value: field.id,
        label: field.name,  
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customFieldsSettings, table.items]);

  const methods:UseFormReturn<CustomFieldFormFields> = useForm<CustomFieldFormFields>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { control, reset, formState, handleSubmit, setError } = methods;

  const isSaveDisabled = Object.keys(formState?.errors).length > 0;

  const submit = handleSubmit((data) => {
    const onError = (message: string) => {
      setError(FIELDS_NAMES.value, {
        type: 'custom',
        message,
      });  
    };
    customFieldStore.saveCustomField(data, onError);
  });

  useEffect(() => {
    if(dataToEdit){
      const currentFieldSetting = customFieldsSettings.find((item) => item.name === dataToEdit.name);
      reset({
        customFieldId: currentFieldSetting!.id,
        value: dataToEdit.value
      });
    }
  }, [customFieldsSettings, dataToEdit, reset]);

  return (
    <FormProvider { ...methods }>
      <Modal
        onClose={ closeModal }
        title={ headerTitle }
      >
        <Modal.Content padding={ '30px' }>
          <FormBlock isBorderBottom={ false }>
            <FormBlock.Header title={ 'Field Details' } />
            {
              isEditMode &&
                <HFHiddenInput
                  control={ control }
                  defaultValue={ dataToEdit.id }
                  name={ FIELDS_NAMES.id }
                />
            }
            <FieldsFactory
              isEditMode={ isEditMode }
              nameOptions={ nameOptions }
              options={ customFieldsSettings }
            />
          </FormBlock>
          { isLoad && <Preloader/> }
        </Modal.Content>
        <Modal.Footer>
          <PopupActionsButtons
            SaveProps={ {
              disabled: isSaveDisabled
            } }
            onCancelClick={ closeModal }
            onSaveClick={ submit }
            wrapperProps={ {
              marginLeft: 'auto',
            } }
          />
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});

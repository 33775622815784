import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { store } from '@services/store/store';

import { ROUTES } from '@constants/routes';

export const PrivateRoute = ({ children, ...rest }: RouteProps & { children: React.ReactNode}) => {
  return (
    <Route
      { ...rest }
      render={ ({ location }) => {
        return store.AuthStore.token ? (
          children
        ) : (
          <Redirect
            to={ {
              pathname: ROUTES.login,
              state: { from: location },
            } }
          />
        );
      } }
    />
  );
};

import React from 'react';

import { StyledTableRowCell, TableRowContentWrapper, } from './styles';

import { CellProps } from './types';

import { DATA_CUSTOM_STYLE_ATTRIBUTE_NAME } from '@constants/style';

export const Cell = ({
  $alignItems = 'center',
  $paddingLeft,
  TableCellProps,
  children,
  onClick,
  dataCustomStyleAttributeName = DATA_CUSTOM_STYLE_ATTRIBUTE_NAME,
  dataCustomStyleAttributeValue
}: CellProps) => {
  return (
    <StyledTableRowCell
      dataCustomStyleAttributeName={ dataCustomStyleAttributeName }
      dataCustomStyleAttributeValue={ dataCustomStyleAttributeValue }
      onClick={ onClick }
      padding={ 'none' }
      { ...TableCellProps }
    >
      <TableRowContentWrapper
        $alignItems={ $alignItems }
        $paddingLeft={ $paddingLeft }
      >
        { children }
      </TableRowContentWrapper>
    </StyledTableRowCell>
  );
};

import * as yup from 'yup';

import { CONTACT_TYPES, HOUSEHOLD_FORM_PREFIX } from '@constants/common';
import { CONTACTS_DETAILS_SCHEMA, OWNER_SCHEMA } from './contactsDetailsSchema';
import { dateOutputValidation, websiteValidation } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

import {
  BUSINESS_FIELDS, COMMON_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { imageValidation } from '@components/ImageUploader';
import { INDIVIDUAL_CONTACT_DETAILS_SCHEMA } from './individualSchema';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';
import { PRIMARY_ADVISER_RULES, CLIENT_TIMESTAMP_RULES } from './common';

import { ContactItem } from '@/shared/types/contact';

export const validationSchema = yup.lazy((fields: ContactItem) => {

  if (fields.type === CONTACT_TYPES.BUSINESS){
    return yup.object().shape({
      [BUSINESS_FIELDS.occupationCompany]: yup.string()
        .required(validationMessages.required),
      [BUSINESS_FIELDS.birthdate]: yup.string()
        .nullable()
        .matches(dateOutputValidation, validationMessages.invalidDate)
        .test(
          'mast be equal or less than Today',
          validationMessages.invalidDate,
          (value) => (
            isDateBefore({
              dateStringToCompare: getFormatedDateNow(),
              dateString: value,
              withEqual: true,
            })
          )
        ),
      [BUSINESS_FIELDS.website]: yup.string().nullable().matches(websiteValidation, {
        excludeEmptyString: true,
        message: validationMessages.invalidWebsite
      }),
      [COMMON_FIELDS.briefDescription]: yup.string()
        .nullable(),
      [COMMON_FIELDS.photo]: imageValidation,
      ...PRIMARY_ADVISER_RULES,
      ...CLIENT_TIMESTAMP_RULES,
      ...CONTACTS_DETAILS_SCHEMA,
    });
  }

  if (fields.type === CONTACT_TYPES.INDIVIDUAL){
    return yup.object().shape({
      ...PRIMARY_ADVISER_RULES,
      ...CLIENT_TIMESTAMP_RULES,
      ...CONTACTS_DETAILS_SCHEMA,
      ...INDIVIDUAL_CONTACT_DETAILS_SCHEMA,
    });
  }


  // HOUSEHOLD
  return yup.object().shape({
    ...PRIMARY_ADVISER_RULES,
    ...CLIENT_TIMESTAMP_RULES,
    ...CONTACTS_DETAILS_SCHEMA,
    ...OWNER_SCHEMA,
    [HOUSEHOLD_FORM_PREFIX]: yup.array().of(yup.object().shape({
      ...INDIVIDUAL_CONTACT_DETAILS_SCHEMA,
      ...CONTACTS_DETAILS_SCHEMA,
      ...OWNER_SCHEMA,
    })),
  });
});

import styled, { css } from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiBox from '@material-ui/core/Box';

import { WrapperProps } from './types';

import { COLORS } from '@constants/colors';

export const Wrapper = styled(MuiBox)<WrapperProps>`
    ${({ disabled }) => disabled && css`
      opacity: 0.3;
    `}
`;

export const Label = styled(FormControlLabel)`
   display: flex;
   align-items: center;
   margin-right: 20px;
   margin-left: 0;

  & .MuiFormControlLabel-label{
    font-size: 14px;
    line-height: 1;
    color: ${ COLORS.primaryText };
  }

   & .MuiRadio-root{
     padding: 0;
     margin-right: 8px;  
   }

   &:last-child {
     margin-right: 0;
   }
`;


import { OPPORTUNITY_FILTER_NAMES } from '@constants/salesCycleOpportunity';

export const USERS = {
  name: OPPORTUNITY_FILTER_NAMES.users,
  label: 'Users',
  values: []
};

export const SALE_TYPE =   {
  name: OPPORTUNITY_FILTER_NAMES.saleType,
  label: 'Sale Type',
  values: []
};

export const OPPORTUNITY_STATUS  =   {
  name: OPPORTUNITY_FILTER_NAMES.opportunityStatus,
  label: 'Opportunity Status',
  values: []
};

export const MEETING_STATUS =   {
  name: OPPORTUNITY_FILTER_NAMES.meetingStatus,
  label: 'Meeting Status',
  values: []
};

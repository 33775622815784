import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { pick, pickBy, identity, debounce } from 'lodash';

import {
  CustomFieldTagItem,
  GetListTags,
  NewTagResponse,
  TagFilters,
  TagListItem,
  TagSaveUpdateResponse,
  TagTypeWithoutOpportunities
} from '@/shared/types/tags';

// @ts-ignore
import { v4 } from 'uuid';

import { createTag, deleteTag, getTagsList, updateTag } from '@services/api/tags/tags';

import { TAGS_FRONT_ID_KEY } from '@constants/userSettingsUserCustomFields';


type Item = CustomFieldTagItem | null
type ContainerType = null | HTMLDivElement;
type ErrorMessage = string | null;
type Filters = Partial<TagFilters>

const TAGS_FILTERS_INIT_STATE: TagFilters = {
  from: null,
  to: null,
};

class TagsStore {
  container:ContainerType  = null;
  editIndex: number | null = null;
  errorMessage: ErrorMessage = null;
  filters: Filters = TAGS_FILTERS_INIT_STATE;
  loadSetter: (flag: boolean) => void;
  onFiltersChangeReaction: IReactionDisposer;
  tags: Array<CustomFieldTagItem> = [];
  type:TagTypeWithoutOpportunities;

  constructor(
    type: TagTypeWithoutOpportunities,
    loadFlagSetter: (flag: boolean) => void
  ) {
    makeAutoObservable(this, {
      getTags: flow.bound,
      onAdd: action.bound,
      onDelete: flow.bound,
      onEditEnd: action.bound,
      onEditStart: action.bound,
      onSave: flow.bound,
      setContainerRef: action.bound,
      setErrorMessage: action.bound,
      setFilters: action.bound,
      tags: true,
    });

    this.type = type;
    this.loadSetter= loadFlagSetter;
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        this.getTags(() => {
          this.container && this.container.scrollTo({
            left: 0,
            top: 0,
          });
        });
      }, 2000),
    );
  }

  *getTags(callback?: () => void) {
    try {
      this.loadSetter(true);

      const response: GetListTags = yield getTagsList([{
        type: this.type,
        ...pickBy(this.filters, identity)
      }]);

      this.setTags(response.data.data);

      callback && callback();
    } catch (error) {
      console.log(error);
    } finally {
      this.loadSetter(false);
    }
  }

  onAdd(){
    const newLength = this.tags.push({
      type: this.type,
      id: null,
      count: 0,
      label: 'New Tag',
      [TAGS_FRONT_ID_KEY]: v4()
    });

    this.editIndex = newLength - 1;

    setTimeout(() => {
      if(this.container){
        this.container.scrollTo({
          left: 0,
          top: this.container.scrollHeight,
        });
      }
    },0);
  }

  *onDelete(item: Item){
    try {
      if(item && typeof item.id === 'number'){
        yield deleteTag(item.id);
        this.tags = this.tags.filter(tagItem => tagItem.id && tagItem.id !== item.id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  onEditEnd(item: CustomFieldTagItem) {
    if(TAGS_FRONT_ID_KEY in item){
      this.tags.pop();
    }
    this.editIndex = null;
    this.errorMessage = null;
  }

  onEditStart(item: CustomFieldTagItem){
    if(TAGS_FRONT_ID_KEY in item){
      return;
    }

    this.editIndex = this.tags.findIndex(tag => tag.id && tag.id === item.id);
  }

  *onSave(item: CustomFieldTagItem, saveEnd: () => void) {
    if(this.errorMessage){
      this.setErrorMessage(null);
    }

    const itemIndex = this.editIndex as number;
    const prevState = this.tags[this.editIndex as number];
    const isUpdate = typeof prevState.id === 'number';

    try {
      if(isUpdate){
        yield updateTag(pick(item, ['id', 'label']));
        this.tags[itemIndex] = {
          ...this.tags[itemIndex],
          label: item.label
        };
      } else {
        const response: NewTagResponse = yield createTag(pick(item, ['type', 'label']));
        this.tags[itemIndex] = response.data.data;
      }

      this.editIndex = null;
      saveEnd();
    } catch (error) {
      const errorObject = error as TagSaveUpdateResponse;
      const errorMessage = errorObject.response?.data?.message[0];

      if(errorMessage){
        this.setErrorMessage(errorMessage);
      }
    }
  }

  resetState() {
    this.setTags([]);
    this.editIndex = null;
    this.setContainerRef(null);
    this.setErrorMessage(null);

    this.onFiltersChangeReaction();
    this.setFilters(TAGS_FILTERS_INIT_STATE);
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  setContainerRef(containerRef: ContainerType){
    this.container = containerRef;
  }

  setErrorMessage(message: ErrorMessage) {
    this.errorMessage = message;
  }

  setFilters(filters: TagFilters){
    this.filters = filters;
  }

  setTags(tags: Array<TagListItem>){
    this.tags = tags;
  }

}

export default TagsStore;

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const SelectedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;
export const SelectedHeader = styled.div`
  padding: 14px 20px;
  background-color: ${COLORS.background};
  border-top: 1px solid ${COLORS.dividingLines};
  border-bottom: 1px solid ${COLORS.dividingLines};
  
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: ${COLORS.primaryText}
`;

export const SelectedItemWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.dividingLines};
`;
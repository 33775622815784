import { CALENDAR_TOOLTIP_ATTR_NAME } from '@pages/NewCalendar/data';
import { element } from 'prop-types';
import React, { Children, cloneElement, useCallback, useRef, useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { useTooltipStyles } from './styles';

export const StyledTooltip = ({
  displayOnOverFlow,
  children,
  title,
  props
}) => {
  const classes = useTooltipStyles();
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const handleMouseOver = (event) => {
    if(displayOnOverFlow){
      const nestedElement = event.currentTarget.querySelector(`[${CALENDAR_TOOLTIP_ATTR_NAME}]`);

      if (nestedElement) {
        setIsOverflowing(nestedElement.scrollWidth > nestedElement.clientWidth);
      }
    } else {
      setIsOverflowing(true);
    }
  };

  const handleMouseLeave = () => {
    setIsOverflowing(false);
  };

  return (
    <Tooltip
      PopperProps={ {
        placement: 'auto',
        modifiers: {
          preventOverflow: {
            priority: ['bottom','left','right','top',],
          }
        }
      } }
      arrow
      classes={ classes }
      disableHoverListener={ !isOverflowing }
      enterDelay={ 100 }
      onMouseLeave={ handleMouseLeave }
      onMouseOver={ handleMouseOver }
      title={ title }
      { ...props }
    >
      {children}
    </Tooltip>
  );
};

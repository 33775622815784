export const getAbbr = (firstString: string, secondString?: string) => {
  if(firstString && !secondString){
    return firstString.slice(0, 2);
  }

  if(typeof firstString ==='string' && typeof secondString ==='string'){
    return firstString.charAt(0) + secondString.charAt(0);
  }

  return '';
};
import { format, isValid, parse } from 'date-fns';

import { MuiDateValueType } from '@components/CustomDatePicker/types';
export const valueGuard = (value: any): string => {
  return typeof value === 'string' ? value : '';
};

export const convertToString = (date: Date | null, value: MuiDateValueType, formatString: string) => {
  return isValid(date)
    ? format(date as Date, formatString)
    : valueGuard(value);
};

export const convertFromOutputToViewFormat = (
  dateString: string,
  outputFormat: string,
  viewFormat: string
): string => {
  const date = parse(dateString, outputFormat, new Date());
  return isValid(date)
    ? format(date, viewFormat)
    : dateString || '';
};

import React, { useCallback } from 'react';

import { Reload } from '@/assets';

import NotificationWithAction from '@components/NotificationWithAction';

import { SalesActiveCycleKanbanNotificationProps } from './types';

export const SalesActiveCycleKanbanNotification = ({
  closeModal,
  modalProps
}: SalesActiveCycleKanbanNotificationProps) => {
  const { action } = modalProps;

  return (
    <NotificationWithAction
      buttonAction={ action }
      closeAction={ closeModal }
    >
      <NotificationWithAction.Item $width={ '270px' }>
        <NotificationWithAction.Header withCloseButton={ false }>
          {'The kanban board is updated'}
        </NotificationWithAction.Header>
        <NotificationWithAction.Row>
          {'The kanban board is updated with actual data. Please reload the board'}
        </NotificationWithAction.Row>
        <NotificationWithAction.Footer
          Icon={ <Reload/> }
          text={ 'Reload' }
        />
      </NotificationWithAction.Item>
    </NotificationWithAction>
  );
};

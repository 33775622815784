import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { FilterButton, SubcategoriesList } from './components';

import { FILTER_ALL_BTN_LABEL } from '@constants/common';
import { TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES } from './data';

import { TwoLevelFilterProps } from './types';

export const TwoLevelFilter = observer(({
  $disabled,
  $isAfterLine = true,
  $isBeforeLine = false,
  allButtonLabel = '',
  categoryName,
  colorSchema = TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.contactDetailsPanelFilter,
  colorSchemaSelected = TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.contactDetailsPanelFilterSelected,
  initCategory = null,
  isMultiplyFilter = false,
  onTypeFilterChange,
  selectedCategory,
  startAdornment,
  StyleBlockButtonProps = {},
  subCategoryOptions,
}: TwoLevelFilterProps) => {

  const [ selectedSubcategory, setSelectedSubcategory ] = useState<string | number | null>(null);
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

  const onClickSubcategory = useCallback((event: MouseEvent, item: string | number | null) => {
    if(item) {
      setSelectedSubcategory(item);
      onTypeFilterChange(event, item);  
    }
    onClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypeFilterChange, setSelectedSubcategory]);

  const onButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [ setAnchorEl ]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [ setAnchorEl ]);

  const isSelectedSubCategory = useMemo(() => {
    if(isMultiplyFilter && selectedSubcategory && selectedCategory !== FILTER_ALL_BTN_LABEL) {
      return true;
    }

    if(selectedCategory === FILTER_ALL_BTN_LABEL) {
      setSelectedSubcategory(null);
    }

    const isSelected = selectedCategory === selectedSubcategory;
    const isSelectedInitCategory = Object.values(subCategoryOptions).some((option: string) => option === initCategory);
    if(isSelectedInitCategory) {
      setSelectedSubcategory(initCategory);
    }
    return isSelected || isSelectedInitCategory;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedCategory, initCategory, isMultiplyFilter ]);

  return (
    <>
      <FilterButton
        $disabled={ $disabled }
        $isAfterLine={ $isAfterLine }
        $isBeforeLine={ $isBeforeLine }
        StyleBlockButtonProps={ StyleBlockButtonProps }
        allButtonLabel={ allButtonLabel }
        categoryName={ categoryName }
        colorSchema={ colorSchema }
        colorSchemaSelected={ colorSchemaSelected }
        isListOpen={ Boolean(anchorEl) }
        isSelected={ isSelectedSubCategory }
        onButtonClick={ onButtonClick }
        selectedSubcategory={ selectedSubcategory }
        startAdornment={ startAdornment }
        subCategoryOptions={ subCategoryOptions }
      />
      <SubcategoriesList
        allButtonLabel={ allButtonLabel }
        anchorEl={ anchorEl }
        onClickSubcategory={ onClickSubcategory }
        onClose={ onClose }
        selectedCategory={ selectedCategory }
        selectedSubcategory={ isMultiplyFilter ? selectedSubcategory : '' }
        subCategoryOptions={ subCategoryOptions }
      />
    </>
  );
});

import React from 'react';
import { useFormContext } from 'react-hook-form';

import HFRadioGroup from '@/modules/HookFormComponents/HFRadioGroup';

import {
  OPTIONS_NAME_TO_DELETE_USER,
  UserDeletePopupInputNames,
} from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/data';

import { WrapperRadioGroup } from './styles';

import { DeleteUserOption } from '@pages/NewUserSettings/components/Tabs/Users/components/DeleteUserPopup/types';

export const PopupChildWIthChoosing = () => {

  const { control } = useFormContext();

  return (
    <WrapperRadioGroup>
      <HFRadioGroup
        ControllerProps={ {
          defaultValue: DeleteUserOption.Replace
        } }
        control={ control }
        name={ UserDeletePopupInputNames.deleteOption }
        options={ OPTIONS_NAME_TO_DELETE_USER }
        wrapperProps={ {
          flexDirection: 'column'
        } }
      />
    </WrapperRadioGroup>
  );
};


import { TextFieldProps } from '@material-ui/core';

export const basicPlanDescriptionInputProps = {
  placeholder: 'Enter Text',
  maxLength: 500,
  rows: 6,
  style: {
    margin: '-9px -6px'
  }
};

export const feeAmountInputProp = {
  placeholder: 'Enter Fee Amount',
  maxLength: 15,
};

export const feeAmountTextFieldProp: TextFieldProps  = {
  type: 'number',
};

export const nameInputProps = {
  placeholder: 'Enter Plan Name',
  maxLength: 150,
};
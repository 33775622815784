import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { DeleteListPopupProps } from './types';

export const DeleteListPopup = ({
  closeModal,
  modalProps
}: DeleteListPopupProps) => {
  const { ids, onSave } = modalProps;

  const onSaveClick = useCallback(() => {
    closeModal();
    onSave(ids);
  }, [closeModal, ids, onSave]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ 'Are you sure you want to delete the List?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onSaveClick }
    />
  );
};

import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

export const ActionButtonWrapper = styled.div`
  margin-right: 10px;
`;

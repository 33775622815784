import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { CONTACT_TYPES } from '@constants/common';
import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getContactName } from '@/shared/utils/getContactName';

import { ChevronRight } from '@/assets';
import { OutOfSightCommon } from '@components/OutOfSight';
import Avatar from '@components/Avatar';
import TextWithTooltip from '@components/TextWithTooltip';

import { ChevronRightIcon, ContactInfo, ContactType, Wrapper, IndividualContactWrapper } from './styles';

import { IdType } from '@/shared/types/commonTypes';
import { LinkedHouseholdProps } from './types';


// ONLY for Individual and Household type
export const LinkedHousehold = observer(({
  currentContact,
  onClick,
  onOutOfSightClick
}: LinkedHouseholdProps) => {

  const contactName = useMemo(() => {
    if(currentContact.type === CONTACT_TYPES.INDIVIDUAL) {
      if(!isEmpty(currentContact.householderContacts)) {
        return getContactName(currentContact, CONTACT_TYPES.HOUSEHOLD);
      }
      return getContactName(currentContact);
    }
    return '';
  }, [currentContact]);

  const onClickWrapper = useCallback((id: IdType, type: CONTACT_TYPES, isBlocked: boolean) => {
    isBlocked ? onOutOfSightClick() : onClick(id, type);
  }, [onOutOfSightClick, onClick]);

  if(currentContact.type === CONTACT_TYPES.HOUSEHOLD) {
    return(
      <IndividualContactWrapper>
        {
          currentContact.householderContacts.map((contact) => {
            const isBlocked = typeof contact.editAllow === 'number' && !contact.editAllow;
          
            return (
              <Wrapper
                key={ contact.id }
                onClick={ () => onClickWrapper(contact.id, CONTACT_TYPES.INDIVIDUAL, isBlocked) }
                $width={ '50%' }
              >
                <Avatar
                  mainImageData={ getAvatarData(contact).mainImageData }
                />
                <ContactInfo>
                  <TextWithTooltip
                    text={ `${contact.firstName} ${contact.lastName}` }
                  />
                  <ContactType>Individual</ContactType>
                </ContactInfo>
                <ChevronRightIcon>
                  <ChevronRight/>
                </ChevronRightIcon>
                {
                  isBlocked && <OutOfSightCommon onClick={ onOutOfSightClick } />
                }
              </Wrapper>
            );
          })
        }
      </IndividualContactWrapper>
    );
  }

  if(currentContact.type === CONTACT_TYPES.INDIVIDUAL && !isEmpty(currentContact.householderContacts)) {
    const members = currentContact.householderContacts;

    const editHouseholdAllow = members.find(member => (
      member.id === currentContact.id
    ))?.editHouseholdAllow;
    const isBlocked = typeof editHouseholdAllow === 'number' && !editHouseholdAllow;

    return(
      <Wrapper
        onClick={ () => onClickWrapper(members[0].householdId, CONTACT_TYPES.HOUSEHOLD, isBlocked) }
        $width={ 'auto' }
      >
        <Avatar
          $oneByOneType={ true }
          mainImageData={ getAvatarData(members[0]).mainImageData }
          subImageData={ getAvatarData(members[1]).mainImageData }
        />
        <ContactInfo>
          <TextWithTooltip
            text={ contactName }
          />
          <ContactType>Household</ContactType>
        </ContactInfo>
        <ChevronRightIcon>
          <ChevronRight/>
        </ChevronRightIcon>
        {
          isBlocked && <OutOfSightCommon onClick={ onOutOfSightClick } />
        }
      </Wrapper>
    );
  }

  return <></>;
});

import React  from 'react';
import { useDrop } from 'react-dnd';

import { SALES_CYCLE_KANBAN_DND_TYPE } from '@constants/salesActiveCycle';

import { CollectedDragProps, KanbanColumnProps } from './types';
import { SalesKanbanDragDataType } from '@/shared/types/salesActiveCycle';

import { Wrapper, Header, Items } from './styles';

export const SalesActiveCycleKanbanColumn = ({
  value,
  label,
  children,
  onDrop,
}: KanbanColumnProps) => {
  const [{ isOver }, dropTargetRef] = useDrop<SalesKanbanDragDataType, unknown, CollectedDragProps>({
    accept: SALES_CYCLE_KANBAN_DND_TYPE,
    drop: (dragItem) => {
      if(dragItem.isBlocked) {
        return {};
      }
      return onDrop({
        id: dragItem.id,
        newStage: value,
        prevStage: dragItem.prevStage,
        isBlocked: dragItem.isBlocked
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <Wrapper
      $isOver={ isOver }
      ref={ dropTargetRef }
    >
      <Header>
        { label }
      </Header>
      <Items>
        { children }
      </Items>
    </Wrapper>
  );
};

import { useState, useEffect, useRef, RefObject } from 'react';
import { usePrevState } from '@/shared/customHooks';

type UseIntersectionObserverProps = {
  ref: Element | null
  options: IntersectionObserverInit
  onIn?: () => void
  onOut?: () => void
}

export const useIntersectionObserver = ({
  ref,
  options = {},
  onIn,
  onOut,
}: UseIntersectionObserverProps) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const [ node, setNode ] = useState<Element | null>(null);
  const [ isNodeIntersected, setIsNodeIntersected ] = useState<boolean | null>(null);
  const prevIntersectionState = usePrevState<boolean | null>(isNodeIntersected);

  const offIntersectionListen = () => {
    if (!observer.current) { return; }
    observer.current.disconnect();
  };

  const intersectionHandler = (entries: Array<IntersectionObserverEntry>, observer:IntersectionObserver) => {
    setIsNodeIntersected(entries[0].isIntersecting);
  };

  useEffect(() => setNode(ref), [ ref ]);

  useEffect(() => {
    if (!node) {
      return;
    }
    offIntersectionListen();
    observer.current = new IntersectionObserver(intersectionHandler, options);
    observer.current.observe(node);
    return offIntersectionListen;
  }, [ node, options ]);

  useEffect(() => {
    if (!isNodeIntersected && prevIntersectionState && onOut) {
      onOut();
    }
    if (isNodeIntersected && onIn) {
      onIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNodeIntersected]);
};

import styled, { css } from 'styled-components';

import { hexToRgba } from '@/shared/utils/hexToRgba';

const textOverflow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RefWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 4px 0px 4px 10px;
`;

export const AllDayEventWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 100%;
`;

export const AllDayEventTextWrapper = styled.div`
  ${textOverflow};
`;

export const CommonEventWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const ColumnEventWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 4px 10px 4px 0px;
`;

export const ColumnEventTime = styled.div`
  display: flex;
  margin-bottom: 8px;
  ${textOverflow};
`;

export const ColumnEventDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background-color: ${ props => hexToRgba(props.$color, 0.3) };
`;

export const ColumnEventLabel = styled.div`
  margin-top: 8px;
  font-size: 14px;

  ${({ $lineClamp }) => $lineClamp && css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${$lineClamp};
  `}
`;

export const LineEventWrapper = styled.div`
  padding: 4px 0;
  flex-grow: 1;
  font-size: 14px;
  box-sizing: border-box;
  ${textOverflow}
`;

export const HighLineEventWrapper = styled.div`
  width: 100%;
  padding: 4px 10px 4px 0px;
`;

export const HighLineEventTime = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const HighLineEventTitle = styled.div`
  margin-top: 8px;
  font-size: 14px;
  ${textOverflow}
`;

export const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 7px;
  
    & > svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) ;
      width: 18px;
      height: 18px;
    }
`;

import React from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import CustomTabs from '@components/CustomTabs';

import { TABS_CONFIG } from './data';

export const CustomFieldsAndTagsTabs = () => {
  const { params } = useRouteMatch<{ subTab: string}>();

  return(
    <CustomTabs
      $hideIndicator={ true }
      $overFlow={ 'unset' }
      value={ params?.subTab }
    >
      {
        TABS_CONFIG.map((item, index) => (
          <CustomTabs.RealWorldTab
            $isFirst={ index === 0 }
            $maxZIndex={ TABS_CONFIG.length }
            $zIndex={ TABS_CONFIG.length - index }
            component={ Link }
            key={ item.value }
            label={ item.label }
            replace={ true }
            to={ item.value }
            value={ item.value }
          />
        ))
      }
    </CustomTabs>
  );
};

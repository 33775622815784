import React, { useCallback } from 'react';

import { FolderPlus, PencilPlus } from '@/assets';

import BlockButton from '@components/BlockButton';
import PreTableWrapper  from '@components/BasicLayoutComponents/PreTableWrapper';
import { UserSelectFilter } from '@modules/UserSelectFilter';
import BubbleButton from '@components/BubbleButton';
import RowFilter from '@components/RowFilter';

import { PreTablePanelProps } from './types';

import { ButtonsWrapper, TypeFilterWrapper } from './styles';
import { LISTS_TYPES } from '@constants/lists';

const TYPE_ALL_BUTTON_LABEL = 'All';
export const PreTablePanel = ({
  isFolderAddDisabled,
  isLoad,
  onFolderAdd,
  onListAdd,
  onTypeChange,
  setUserFilter,
  table,
  typeFilterValue,
  userFilterState,
  userOptions,
}: PreTablePanelProps) => {

  const onTypeFilterChange = useCallback((value: any) => {
    const newValue = value === TYPE_ALL_BUTTON_LABEL
      ? null
      :value;
    onTypeChange(newValue);
  }, [onTypeChange]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }
    >
      <UserSelectFilter
        closeTrigger={ isLoad }
        onChange={ setUserFilter }
        usersOptions={ userOptions }
        value={ userFilterState }
        withAllUserOption={ true }
      />
      <TypeFilterWrapper>
        <RowFilter
          allButtonLabel={ TYPE_ALL_BUTTON_LABEL }
          initState={ typeFilterValue || '' }
          items={ [LISTS_TYPES.Dynamic, LISTS_TYPES.Manual] }
          limit={ 3 }
          onChange={ onTypeFilterChange }
          renderItem={ ({
            content,
            isSelected,
            isShowMore,
            onClick,
            isSelectedFromMore,
            indexForKey,
          }) => (
            <BubbleButton
              $isActive={ isSelected && !isShowMore }
              $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
              key={ indexForKey }
              onClick={ onClick }
            >
              { content }
            </BubbleButton>
          ) }
        />
      </TypeFilterWrapper>

      <ButtonsWrapper>
        <BlockButton
          disabled={ isFolderAddDisabled }
          onClick={ onFolderAdd }
          startIcon={ <FolderPlus /> }
        >
          New Folder
        </BlockButton>
        <BlockButton
          onClick={ onListAdd }
          startIcon={ <PencilPlus /> }
        >
          New Custom List
        </BlockButton>
      </ButtonsWrapper>
    </PreTableWrapper>
  );
};

import { useFormContext, useWatch } from 'react-hook-form';
import { UseWithNickNameProps, UseWithNickNameReturn } from './types';
import { useEffect } from 'react';

export const useWithNickname = ({
  boundFieldResetValue,
  nickNameFieldName,
  boundFieldName,
}: UseWithNickNameProps): UseWithNickNameReturn => {
  const { control, setValue } = useFormContext();

  const [nickNameValue, boundFieldValue] = useWatch({
    control,
    name: [nickNameFieldName, boundFieldName]
  });

  useEffect(() => {
    if(!nickNameValue && boundFieldValue !== boundFieldResetValue){
      setValue(boundFieldName, boundFieldResetValue);
    }
  },[nickNameValue, boundFieldValue, boundFieldResetValue, setValue, boundFieldName]);

  return {
    disabled: !nickNameValue
  };
};

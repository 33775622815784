import {
  ClientOpportunityFilterState,
  ConvertedClientOpportunityFilterDataType
} from '@/shared/types/salesCycleOpportunity';
import { OPPORTUNITY_FILTER_NAMES } from '@constants/salesCycleOpportunity';

export const INIT_FILTERS_STATE: ClientOpportunityFilterState = {
  [OPPORTUNITY_FILTER_NAMES.opportunityStatus]: [],
  [OPPORTUNITY_FILTER_NAMES.users]: [],
  [OPPORTUNITY_FILTER_NAMES.meetingStatus]: [],
  [OPPORTUNITY_FILTER_NAMES.saleType]: [],
  [OPPORTUNITY_FILTER_NAMES.search]: ''
};


export const INIT_FILTER_DATA: ConvertedClientOpportunityFilterDataType = {
  [OPPORTUNITY_FILTER_NAMES.users]: [],
  [OPPORTUNITY_FILTER_NAMES.opportunityStatus]: [],
  [OPPORTUNITY_FILTER_NAMES.saleType]: [],
  [OPPORTUNITY_FILTER_NAMES.meetingStatus]: [],
};

import React, { useMemo } from 'react';
import { differenceInMinutes, format, parse } from 'date-fns';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import FormBlock from '@components/FormBlock';
import TextWithTooltip from '@components/TextWithTooltip';

import { TextWrapper } from './styles';

import { getUTCFormattedString } from '@/shared/utils/getUTCFormattedString';

import { COLORS } from '@constants/colors';
import { CONTACT_TYPES } from '@constants/common';
import { MONTH_DAY_YEAR_BACKSLASH, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

import {
  LeftColumnWrapper,
  RightColumnWrapper,
  TabContentWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  RIGHT_COLUMN_SYSTEM_DETAILS,
  LEFT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

const addZero = (number: number) => {
  return number.toString().length === 1 ? `0${ number }` : number;
};

export const CommonSystemDetails = observer(() => {
  const { currentContact } = useStore().ContactDetailsStore;
  const { optionsForBusiness, optionsForHousehold, optionsForIndividual } = useStore().ContactDetailsProfileStore;

  const { control } = useFormContext();

  const createdBy: string = useMemo(() => {
    if(!currentContact?.type){
      return '';
    }

    let options;

    if(currentContact.type === CONTACT_TYPES.BUSINESS){
      options = optionsForBusiness;
    }

    if(currentContact.type === CONTACT_TYPES.INDIVIDUAL){
      options = optionsForIndividual;
    }

    if(currentContact.type === CONTACT_TYPES.HOUSEHOLD){
      options = optionsForHousehold;
    }

    if(!options) {
      return '';
    } else {
      const advisor = options.primaryAdvisers.find(item => item.id === currentContact!.createdBy);
      if(!advisor){
        return '';
      }
      return `${advisor.firstName} ${advisor.lastName}`;
    }
  }, [currentContact, optionsForBusiness, optionsForHousehold, optionsForIndividual]);


  const date = useMemo(() => {
    if(!currentContact?.type){
      return '';
    }

    const recordTimestamp = getUTCFormattedString(currentContact!.recordTimestamp,
      YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
    const createDate = parse(recordTimestamp, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date());
    const today = new Date();

    let diffInMinutes = differenceInMinutes(today, createDate);

    const diffInDays = Math.trunc(diffInMinutes / (60 * 24));
    diffInMinutes = diffInMinutes - (diffInDays * 24 * 60);

    const diffInHours = Math.trunc(diffInMinutes / 60);
    diffInMinutes = diffInMinutes - (diffInHours * 60);

    return (
      `${format(createDate, MONTH_DAY_YEAR_BACKSLASH) } ` +
      `(${ addZero(diffInDays) }d ${ addZero(diffInHours) }h ${ addZero(diffInMinutes) }m)`
    );
  }, [currentContact]);

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item>
        <FormBlock isBorderBottom={ false }>
          <FormBlock.Header title={ 'System Details ' }/>
          <FormBlock.RowWrapper>
            <LeftColumnWrapper>
              <FormBlock.FormField
                $labelWidth={ LEFT_COLUMN_WIDTH }
                label={ 'Record Creation Date' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  <TextWrapper>
                    { date }
                  </TextWrapper>
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </LeftColumnWrapper>
            <RightColumnWrapper>
              <FormBlock.FormField
                $labelWidth={ RIGHT_COLUMN_SYSTEM_DETAILS }
                label={ 'Created By' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  <TextWithTooltip
                    $fontColor={ COLORS.primaryText }
                    $lineHeight={ 20 }
                    $maxWidth={ 335 }
                    text={ createdBy }
                  />
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </RightColumnWrapper>
          </FormBlock.RowWrapper>
        </FormBlock>
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

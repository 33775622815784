import { COLORS } from '@/shared/constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto -1.5rem auto;
    position: relative;
    top: -1.5rem;
`;

export const TitleWrapper = styled.div`
  color: ${COLORS.helpText};
  font-size: 16px;
  text-align: center;
`;

export const TimerValueWrapper = styled.div`
  height: 94px;
  color: ${COLORS.header};
  font-size: 80px;
  text-align: center;
  font-weight: 300;
`;

export const SecondsWrapper = styled.div`
  color: ${COLORS.helpText};
  font-size: 14px;
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const MessageWrapper = styled.div`
  width: 20rem;
  margin: 0 auto;
  text-align: center;
`;

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
import { SEARCH_BUTTON_MODE } from './data';

import BlockButton from '@components/BlockButton';

import { SearchIconButtonProps } from './types';

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 10px;
`;

export const RowFilterWrapper = styled.div`
  display: flex;
`;

export const SearchIconButton = styled(BlockButton)<SearchIconButtonProps>`
  min-width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 25px;
  border-color: ${COLORS.borders};

  & svg {
    width: 19px !important;
  }

  &:after {
    position: absolute;
    left: 43px;
    content: '';
    display: block;
    width: 1px;
    height: 20px;
    background: ${COLORS.borders};
  }

  ${({ mode }) => {
    if(mode !== SEARCH_BUTTON_MODE.inactive) {
      return css`
        background-color: ${COLORS.select} !important;
        border-color: inherit;
      `;
    }
  }}

  &:before {
    position: absolute;
    left: 23px;
    bottom: 23px;
    content: '';
    display: ${({ mode }) => mode === SEARCH_BUTTON_MODE.withResult ? 'block' : 'none'};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid ${COLORS.white};
    background: ${COLORS.orange};
  }
`;

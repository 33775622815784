import { useCallback } from 'react';

import get from 'lodash/get';

import { IdType } from '@/shared/types/commonTypes';

// TODO replace it with commontable store

type UseRowCheckboxProps = {
  selectedIds: Array<IdType>,
  pageItemIdPath?: string,
  onGlobalCheckboxClickCallback: (arrOfIds: Array<IdType>) => void,
}

export const useRowCheckbox = ({
  selectedIds,
  pageItemIdPath = 'id',
  onGlobalCheckboxClickCallback
}: UseRowCheckboxProps) => {
  const getCheckboxState = useCallback((item: object) => (
    selectedIds.includes(get(item, pageItemIdPath))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [ selectedIds ]);

  const onRowCheckboxClick = useCallback((item: object) => (e: any, state: boolean) => {
    const itemId:IdType = get(item, pageItemIdPath);
    if (state) {
      onGlobalCheckboxClickCallback([ ...selectedIds, itemId ]);
    } else {
      const withoutSelectedID = selectedIds.filter(id => id !== itemId);
      onGlobalCheckboxClickCallback(withoutSelectedID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedIds ]);

  return {
    getCheckboxState,
    onRowCheckboxClick
  };
};

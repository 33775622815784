import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '@store';

import CheckboxDropDown from '@components/CheckboxDropDown';
import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';
import SearchField from '@components/SearchField';
import SideFilters from '@modules/SideFilters';

import { NestedStoreTableOrKanban } from '@/shared/types/salesActiveCycle';
import { DropDownFilterDataType } from '@/shared/types/commonTypes';
import { addFilterOptions } from '@/shared/utils/filterUtils';

import { SALES_ACTIVE_CYCLE_FILTER_NAMES } from '@constants/salesActiveCycle';
import { USERS, MEETING_STATUS, SALES_CYCLE_STATUS } from './data';


export const SalesActiveCycleFilters = observer(() => {
  const { isLoad, nestedStore, } = useStore().SalesCycleStore;
  const { resetFilters, filtersCount, filterData, filters,  } = nestedStore as unknown as NestedStoreTableOrKanban;

  const meetingStatusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      MEETING_STATUS,
      filterData[SALES_ACTIVE_CYCLE_FILTER_NAMES.meetingStatus]
    )
  ), [filterData]);

  const salesCycleStatusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      SALES_CYCLE_STATUS,
      filterData?.[SALES_ACTIVE_CYCLE_FILTER_NAMES.salesCycleStatus] || []
    )
  ), [filterData]);

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      USERS,
      filterData[SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor]
    )
  ), [filterData]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    nestedStore.setFilterState(fieldName, newValue);
  }, [nestedStore]);

  const onNameChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    nestedStore.setFilterState(SALES_ACTIVE_CYCLE_FILTER_NAMES.name, value);
  }, [nestedStore]);

  return (
    <SideFilters
      count={ filtersCount }
      isNonePointerEvents={ isLoad }
      onClearClick={ resetFilters }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onNameChange }
          placeholder={ 'Search Contacts' }
          value={ filters?.[SALES_ACTIVE_CYCLE_FILTER_NAMES.name] || '' }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor]  || [] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ salesCycleStatusDropDownData.label }
          name={ salesCycleStatusDropDownData.name }
          renderItemLabel={ (label, value) => (
            <SaleCycleStatusOption
              label={ label }
              value={ value }
            />
          ) }
          setState={ onChangeDrop }
          state={ filters?.[SALES_ACTIVE_CYCLE_FILTER_NAMES.salesCycleStatus]  || [] }
          values={ salesCycleStatusDropDownData.values }
        />

        <CheckboxDropDown
          label={ meetingStatusDropDownData.label }
          name={ meetingStatusDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[SALES_ACTIVE_CYCLE_FILTER_NAMES.meetingStatus] || [] }
          values={ meetingStatusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});

import React, { useCallback, useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import { DATE_BUTTONS_CONFIG } from './data';
import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { Wrapper, Item } from './styles';
import { DateButtonsProps } from './types';


export const DateButtons = ({
  disabled
}: DateButtonsProps) => {

  const { setValue } = useFormContext();

  const [ selectedValue, setSelectedValue ] = useState<number | null>(null);

  const onSelect = useCallback((value: number) => () => {
    setSelectedValue(value);

    const newDate = addDays(new Date(), value);
    const formattedNewDate = format(newDate, YEAR_MONTH_DAY);

    setValue(TO_DOS_FIELDS_NAMES.reviewDate, formattedNewDate);
  }, []);

  useEffect(() => {
    setSelectedValue(null);
  }, [disabled]);

  return (
    <Wrapper>
      {
        DATE_BUTTONS_CONFIG.map(({ value, label }) => (
          <Item
            $selected={ value === selectedValue }
            key={ label }
            onClick={ onSelect(value) }
            disabled={ disabled }
          >
            { label }
          </Item>
        ))
      }
    </Wrapper>
  );
};

import { format } from 'date-fns';
import { convertUTCFormatToString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { MailChimpList } from '@/shared/types/lists';

export const getLastSyncDate = (mailChimpListData: MailChimpList | null) => {
  if(mailChimpListData && mailChimpListData.mailChimpLastSyncDate) {
    const dateAndTime = mailChimpListData.mailChimpLastSyncDate.split(' ');
    const { date, time } = convertUTCFormatToString(dateAndTime[0], dateAndTime[1]);

    const formattedSyncDate = format(new Date(date), 'MMMM do yyyy');
    const formattedSyncTime = format(new Date(`${date} ${time}`), 'H:mm aaa');
    return `Last Sync Date: ${formattedSyncDate} at ${formattedSyncTime}`; 
  }
  return '';
};

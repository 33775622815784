import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  margin-left: auto;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const TypeFilterWrapper = styled.div`
  width: 200px;
  margin-left: 10px;
`;

import { HOUSEHOLD_FORM_PREFIX } from '@constants/common';
import {
  COMMON_FIELDS,
  INDIVIDUAL_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

import { Common, GetFieldSReturn, Individual, RemapNames } from './types';

export  const spouseButtonStyle = {
  marginTop: 'auto',
  flexGrow: 1,
};

export const FIXED_HEIGHT_OF_SITE_HEADER = 120;

const remapFields = <T>(target: {}, index: number) => (Object.keys(target) as Array<keyof T>).reduce((acc, field) => {
  //@ts-ignore
  acc[field] = `${HOUSEHOLD_FORM_PREFIX}.${index}.${field}`;
  return acc;
},{} as RemapNames<T>);

export const getFieldsNames = (index: number): GetFieldSReturn => {
  const individualFields = remapFields<Individual>(INDIVIDUAL_FIELDS, index);
  const commonFields = remapFields<Common>(COMMON_FIELDS, index);
  
  return {
    ...individualFields,
    ...commonFields
  };
};
import { EVENT_TYPES } from '@/shared/constants/colorsByEventTypes';
// @ts-ignore
import { dateFnsLocalizer } from 'react-big-calendar';
import { format, getDay, parse, startOfWeek } from 'date-fns';

import {
  Toolbar,
  MonthDateHeader,
  DayHeader,
  WeekDayViewTimeGutterHeader,
  DayAndWeekEventWrapper,
  MonthEventWrapper,
} from './components';

const LOCALES = {
  'en-US': 'en-US',
};

export const LOCALIZER = dateFnsLocalizer({
  getDay,
  format,
  locales: LOCALES,
  parse,
  startOfWeek,
});


export const TIME_GUTTER_COLUMN_ELEM_WIDTH = '89px';



export const SLOT_ACTIONS = {
  doubleClick: 'doubleClick',
};

export const EVENT_TYPES_BUTTONS = [
  {
    value: EVENT_TYPES.generalActivity,
    label: 'General Activity',
  },
  {
    value: EVENT_TYPES.salesAppointment,
    label: 'Sales Appointment',
  },
  {
    value: EVENT_TYPES.accountReview,
    label: 'Account Review',
  },
  {
    value: EVENT_TYPES.meeting,
    label: 'Meeting',
  },
  {
    value: EVENT_TYPES.event,
    label: 'Event',
  },
  {
    value: EVENT_TYPES.timeBlock,
    label: 'Time Block',
  },
];

import { ValueLabelObj } from '@/shared/types/commonTypes';
import { SALES_ACTIVE_CYCLE_STAGES } from '@constants/salesActiveCycle';
import { SalesActiveCycleStageType } from '@/shared/types/salesActiveCycle';

export const COLUMNS_CONFIG:Array<ValueLabelObj<SalesActiveCycleStageType, string>> = [
  {
    label: SALES_ACTIVE_CYCLE_STAGES.firstAppt,
    value: SALES_ACTIVE_CYCLE_STAGES.firstAppt
  },
  {
    label: SALES_ACTIVE_CYCLE_STAGES.secondAppt,
    value: SALES_ACTIVE_CYCLE_STAGES.secondAppt
  },
  {
    label: SALES_ACTIVE_CYCLE_STAGES.thirdAppt,
    value: SALES_ACTIVE_CYCLE_STAGES.thirdAppt
  },
  {
    label: SALES_ACTIVE_CYCLE_STAGES.sendProposal,
    value: SALES_ACTIVE_CYCLE_STAGES.sendProposal
  },
  {
    label: SALES_ACTIVE_CYCLE_STAGES.proposalReview,
    value: SALES_ACTIVE_CYCLE_STAGES.proposalReview
  },
  {
    label: SALES_ACTIVE_CYCLE_STAGES.docuSigns,
    value: SALES_ACTIVE_CYCLE_STAGES.docuSigns
  },
];

import { ColumnType } from '@components/Table/components/Header/types';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import {
  getColumnCommonFields
} from '@modules/AccountsAndPlansPopup/components/Content/utils';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'updated',
    label: 'Updated',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'value',
    label: 'Value',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'type',
    label: 'Type',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'newBusinessAmount',
    label: 'New Business Amount',
    ...getColumnCommonFields('260px')
  },
];

export const BALANCE_HISTORY_TYPES = {
  initialValue: 'Initial Value',
  updatedValue: 'Updated Value',
  newBusiness: 'New Business',
  withdrawal: 'Withdrawal',
  contribution: 'Contribution',
};

export const BALANCE_HISTORY_TYPES_OPTIONS =  optionsFromValue(Object.values(BALANCE_HISTORY_TYPES));
export const BALANCE_HISTORY_TYPES_OPTIONS_WITHOUT_INIT =  BALANCE_HISTORY_TYPES_OPTIONS.filter(option => {
  return option.value !== BALANCE_HISTORY_TYPES.initialValue;
});
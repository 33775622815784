import { parse, isValid, addMinutes } from 'date-fns';
import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

export const addTimeZoneOffset= (dateString: string) => {
  const parsed = parse(dateString, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date());
  if(!isValid(parsed)){
    return null;
  }

  return addMinutes(parsed, parsed.getTimezoneOffset());
};

import React from 'react';

import { Wrapper } from './styles';
import { ContentHeaderProps } from './types';

export const ContentHeader = ({
  children,
  style
}: ContentHeaderProps) => (
  <Wrapper style={ style }>
    { children }
  </Wrapper>
);

import React, { ChangeEventHandler, KeyboardEventHandler, useCallback } from 'react';

import { CustomTextFieldProps } from '@components/CustomTextField/types';

import { StyledTextField } from './styles';

export const CustomTextField = ({
  InputProps,
  TextFieldProps,
  disabled,
  error,
  fullWidth,
  helperText,
  inputProps,
  name,
  onBlur,
  onChange,
  onFocus,
  onlyNumbers,
  placeholder,
  showErrorText = false,
  value,
}: CustomTextFieldProps) => {

  // Use that wrapper to fix input with type number and maxLength
  const onChangeWrapper: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback((event) => {
    const isNumberInput = TextFieldProps?.type === 'number';

    if(isNumberInput && inputProps?.maxLength){
      event.target.value = event.target.value.toString().slice(0, inputProps.maxLength);
    }
    onChange && onChange(event);
  },[inputProps?.maxLength, TextFieldProps?.type, onChange]);

  const onKeyDown:KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    const isNumberInput = TextFieldProps?.type === 'number';
    if(TextFieldProps?.type === 'number'){
      (event.key === 'e' || event.key === 'E') && event.preventDefault();
    }

    if(isNumberInput && onlyNumbers){
      const invalidChars = ['-', '+', '.' ];
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
  },[TextFieldProps?.type, onlyNumbers]);

  return (
    <StyledTextField
      $showError = { showErrorText }
      disabled={ disabled }
      error={ error }
      fullWidth={ fullWidth }
      helperText={ helperText }
      inputProps={ inputProps }
      InputProps={ InputProps }
      name={ name }
      onBlur={ onBlur }
      onChange={ onChangeWrapper }
      onFocus={ onFocus }
      onKeyDown={ onKeyDown }
      placeholder={ placeholder }
      value={ value }
      variant="outlined"
      { ...TextFieldProps }
    />
  );
};

import styled from 'styled-components';

import Popover from '@material-ui/core/Popover';

import { COLORS } from '@constants/colors';

export const StyledPopover = styled(Popover)``;

export const GroupsWrapper = styled.div`
  display: grid;
  grid-template-columns: [firstColumn] 150px 10px [secondColumn] 133px;
  grid-row-gap: 30px;
  padding: 16px 0;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: firstColumn / firstColumn;
  
   &:nth-child(2n) {
     grid-column: secondColumn / secondColumn;
   }
  
`;
export const GroupHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const GroupName = styled.div``;
export const GroupIcon = styled.div`
  margin-left: 20px;
  margin-right: 8px;
  
  & > svg {
    width: 16px;
    height: 16px;
  }
`;
export const GroupItem = styled.div`
  padding: 5px 0 5px 15px;
  border-left: 2px solid transparent;
  
  &:hover {
    cursor: pointer;
    border-color: ${COLORS.orange};
    color: ${COLORS.orange};
    background-color: ${COLORS.select};
  }
`;


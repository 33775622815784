import { COLORS } from '@constants/colors';

import {
  CompleteAndCopy,
  DoneMarkWithCircle,
  SaveAndCopy,
  Trash,
} from '@/assets';

import { ButtonTypes } from './types';

export enum POPUP_BUTTON_TYPES  {
  completeAndCopy = 'completeAndCopy',
  delete= 'delete',
  done = 'done',
  saveAndCopy = 'saveAndCopy',
}

// const COMMON_COLORS = {
//   typeColor: COLORS.primaryText,
//   typeHoverColor: COLORS.orange,
// };

const GREEN_COLORS = {
  typeColor: COLORS.primaryText,
  typeHoverColor: COLORS.green,
};

// const COMMON_BG_COLORS = {
//   typeBgHoverColor: COLORS.select,
// };

const NO_BG_COLORS = {
  typeBgHoverColor: 'transparent',
};

export const BUTTONS_DATA: ButtonTypes = {
  [POPUP_BUTTON_TYPES.delete]: {
    ...NO_BG_COLORS,
    TypeIcon: Trash,
    typeColor: COLORS.primaryText,
    typeHoverColor: COLORS.red,
  },
  [POPUP_BUTTON_TYPES.done]: {
    ...GREEN_COLORS,
    ...NO_BG_COLORS,
    TypeIcon: DoneMarkWithCircle,
  },
  [POPUP_BUTTON_TYPES.completeAndCopy]: {
    ...GREEN_COLORS,
    ...NO_BG_COLORS,
    TypeIcon: CompleteAndCopy,
  },
  [POPUP_BUTTON_TYPES.saveAndCopy]: {
    ...GREEN_COLORS,
    ...NO_BG_COLORS,
    TypeIcon: SaveAndCopy,
  }
};

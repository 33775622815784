import ApiService from '@services/api/api.service';
import {
  ContactCustomFieldItem,
  SavedContactCustomFieldItem,
  UpdateContactCustomFieldItemParams
} from '@/shared/types/userSettingsContactCustomFields';


export const getContactCustomFields = () => {
  return ApiService.get('/api/fieldOption/list',);
};

export const saveContactCustomFieldsItem = (params: ContactCustomFieldItem) => {
  return ApiService.post('/api/fieldOption/create', params);
};

export const updateContactCustomFieldsItem = (params: UpdateContactCustomFieldItemParams) => {
  return ApiService.patch('/api/fieldOption/update', params);
};

export const deleteContactCustomFieldsItem = (params: Pick<SavedContactCustomFieldItem, 'id'>) => {
  return ApiService.delete('/api/fieldOption/delete', { params });
};


import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { FormCellProps } from './types';

export const FormCellWrapper = styled.div<FormCellProps>`
  width: 100%;
  padding-right: 20px;
  
  ${({ $maxWidth }) => $maxWidth && css`
    max-width: ${$maxWidth};
  `}

  & .MuiFormControl-root{
    width: 100%;
    padding-bottom: 0;
  }
`;

export const SingleActionButtonWrapper = styled.div`
  & button {
    padding: 6px 0;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin: 0 4px !important;
  }
`;

export const CounterWrapper = styled.div`
  min-width: 24px;
  height: 24px;
  margin-left: 8px;
  padding: 3px 0px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  background-color: #F2F3F8;
  box-sizing: border-box;
`;

export const CompletedIconWrapper = styled.div`
  display: flex;
  min-width: 28px;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${COLORS.greenBg};

  & svg path {
    fill: ${COLORS.green};
  }
`;

import styled from 'styled-components';

import { Cross } from '@/assets';

import { COLORS } from '@constants/colors';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65px;
  ${({ $maxWidth }) =>
    $maxWidth && `max-width: ${$maxWidth}px;`};
  padding: 0 24px 0 30px;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid ${COLORS.dividingLines};
`;

export const CloseIcon = styled(Cross)`
  min-height: 12px;
  min-width: 12px;
  margin-left: 8px;
  cursor: pointer;

  path {
    fill: ${COLORS.primaryText};
  }
`;

export const HeaderText = styled.div`
  margin-right: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${COLORS.header};
`;

import { buildPath } from '@/shared/utils/buildPath';

export const USER_SETTINGS_TABS =  {
  accountSettings: 'account-settings',
  customFieldsAndTags:'custom-fields-and-tags',
  myProfile: 'my-profile',
  password: 'password',
  users: 'users',
} as const;

export const USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS =  {
  contactCustomFields: 'contact-custom-fields',
  userCustomFields: 'user-custom-fields',
  manageTags: 'manage-tags',
} as const;


export const USER_SETTINGS_COMMON_PATH = '/user-settings';
export const USER_SETTINGS_ROUTE = buildPath([USER_SETTINGS_COMMON_PATH, ':tab']);
export const USER_SETTINGS_ACCOUNT_SETTINGS_PATH = buildPath([
  USER_SETTINGS_COMMON_PATH,
  USER_SETTINGS_TABS.accountSettings
]);
export const USER_SETTINGS_MY_PROFILE_PATH = buildPath([USER_SETTINGS_COMMON_PATH, USER_SETTINGS_TABS.myProfile]);
export const USER_SETTINGS_PASSWORD_PATH = buildPath([USER_SETTINGS_COMMON_PATH, USER_SETTINGS_TABS.password]);
export const USER_SETTINGS_USERS_PATH = buildPath([USER_SETTINGS_COMMON_PATH, USER_SETTINGS_TABS.users]);

export const USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_ROUTE = buildPath([
  USER_SETTINGS_COMMON_PATH,
  USER_SETTINGS_TABS.customFieldsAndTags,
  ':subTab'
]);

export const USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH = buildPath([
  USER_SETTINGS_COMMON_PATH,
  USER_SETTINGS_TABS.customFieldsAndTags,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.contactCustomFields
]);

export const USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_USER_CUSTOM_FIELDS_PATH = buildPath([
  USER_SETTINGS_COMMON_PATH,
  USER_SETTINGS_TABS.customFieldsAndTags,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.userCustomFields
]);

export const USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_MANAGE_TAGS_PATH = buildPath([
  USER_SETTINGS_COMMON_PATH,
  USER_SETTINGS_TABS.customFieldsAndTags,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_TABS.manageTags
]);


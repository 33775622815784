import React, { useCallback } from 'react';

import { CustomRadioWithLabel } from './CustomRadioWithLabel';

import { Wrapper } from './styles';

import { CustomRadioGroupProps, RadioGroupOption } from './types';

export const CustomRadioGroup = ({
  disabled,
  onChange,
  options,
  value,
  wrapperProps,
}: CustomRadioGroupProps) => {

  const onChangeWrapper = useCallback((
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange && onChange(event, checked);
  }, [onChange]);

  const renderItem = useCallback((option: RadioGroupOption) => {
    return (
      <CustomRadioWithLabel
        key={ option.value as string }
        labelProps={ {
          disabled: disabled,
          ...option
        } }
        radioProps={ {
          onChange: onChangeWrapper,
          checked: value === option.value
        } }
      />
    );
  },[disabled, onChangeWrapper, value]);

  return (
    <Wrapper
      alignItems={ 'center' }
      disabled={ disabled }
      display={ 'flex' }
      { ...wrapperProps }
    >
      {
        options.map(option => renderItem(option))
      }
    </Wrapper>
  );
};

import { ValueOf } from '@/shared/types/commonTypes';

export const LINKED_ITEM_DATA_NAMES = {
  ACCOUNT_DATA: 'accountData',
  CONTACT_DATA: 'contactData',
  CONTACT: 'contact',
  OPPORTUNITY_DATA: 'opportunityData',
  PLAN_DATA: 'planData',
  POLICY_DATA: 'policyData',
  SALES_PIPELINE_DATA: 'salesPipeLineData',
  TASK_DATA: 'taskData',
} as const;

export const LINKED_ITEM_ID_NAMES = {
  accountId: 'accountId',
  linkedContactId: 'linkedContactId',
  opportunityId: 'opportunityId',
  salesPipelineId: 'salesPipelineId',
  taskId: 'taskId',
  financialPlanId: 'financialPlanId',
  policyId: 'policyId'
} as const;

export const LINKED_ITEM_TYPES = {
  account: 'Account',
  contact: 'Contact',
  opportunity: 'Opportunity',
  plan: 'Plan',
  policy: 'Policy',
  salesCycle: 'Sales Cycle',
  task: 'Task',
} as const;

export type LinkedItemTypes = ValueOf<typeof LINKED_ITEM_TYPES>;

export const CONTACT_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.CONTACT_DATA]: {
    type: LINKED_ITEM_TYPES.contact,
    id: LINKED_ITEM_ID_NAMES.linkedContactId,
    dataName: LINKED_ITEM_DATA_NAMES.CONTACT_DATA
  },
};

export const CONTACT_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.CONTACT]: {
    type: LINKED_ITEM_TYPES.contact,
    id: LINKED_ITEM_ID_NAMES.linkedContactId,
    dataName: LINKED_ITEM_DATA_NAMES.CONTACT
  },
};

export const TASK_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.TASK_DATA]: {
    type: LINKED_ITEM_TYPES.task,
    id: LINKED_ITEM_ID_NAMES.taskId,
    dataName: LINKED_ITEM_DATA_NAMES.TASK_DATA
  }
};

export const ACCOUNT_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.ACCOUNT_DATA]: {
    type: LINKED_ITEM_TYPES.account,
    id: LINKED_ITEM_ID_NAMES.accountId,
    dataName: LINKED_ITEM_DATA_NAMES.ACCOUNT_DATA
  },
};

export const PLAN_DATA_ACCOUNT_ID_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.PLAN_DATA]: {
    type: LINKED_ITEM_TYPES.plan,
    id: LINKED_ITEM_ID_NAMES.accountId,
    dataName: LINKED_ITEM_DATA_NAMES.PLAN_DATA
  },
};

export const PLAN_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.PLAN_DATA]: {
    type: LINKED_ITEM_TYPES.plan,
    id: LINKED_ITEM_ID_NAMES.financialPlanId,
    dataName: LINKED_ITEM_DATA_NAMES.PLAN_DATA
  },
};

export const POLICY_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.POLICY_DATA]: {
    type: LINKED_ITEM_TYPES.policy,
    id: LINKED_ITEM_ID_NAMES.policyId,
    dataName: LINKED_ITEM_DATA_NAMES.POLICY_DATA
  },
};

export const POLICY_DATA_ACCOUNT_ID_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.POLICY_DATA]: {
    type: LINKED_ITEM_TYPES.policy,
    id: LINKED_ITEM_ID_NAMES.accountId,
    dataName: LINKED_ITEM_DATA_NAMES.POLICY_DATA
  },
};

export const SALES_PIPELINE_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.SALES_PIPELINE_DATA]: {
    type: LINKED_ITEM_TYPES.salesCycle,
    id: LINKED_ITEM_ID_NAMES.salesPipelineId,
    dataName: LINKED_ITEM_DATA_NAMES.SALES_PIPELINE_DATA
  },
};

export const OPPORTUNITY_DATA_SCHEME = {
  [LINKED_ITEM_DATA_NAMES.OPPORTUNITY_DATA]: {
    type: LINKED_ITEM_TYPES.opportunity,
    id: LINKED_ITEM_ID_NAMES.opportunityId,
    dataName: LINKED_ITEM_DATA_NAMES.OPPORTUNITY_DATA
  },
};

export const LINKED_ITEM_TYPE_SCHEME = {
  ...ACCOUNT_DATA_SCHEME,
  ...CONTACT_DATA_SCHEME,
  ...OPPORTUNITY_DATA_SCHEME,
  ...PLAN_DATA_ACCOUNT_ID_SCHEME,
  ...PLAN_DATA_SCHEME,
  ...POLICY_DATA_ACCOUNT_ID_SCHEME,
  ...POLICY_DATA_SCHEME,
  ...SALES_PIPELINE_DATA_SCHEME,
  ...TASK_DATA_SCHEME,
};
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 30px 0;
`;

export const ButtonWrapper = styled.div`
  margin: 0 10px;
`;


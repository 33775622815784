import { TodoData, TodoItem } from '@/shared/types/todos';

export const todoItemsResponseNormalizer = (
  response: TodoData,
): {
  items: Array<TodoItem>,
} => {
  const { filterData, ...todosItems } = response;

  const items = Object.values(todosItems).map((item) => {
    if(!item.category && item.request) {
      item.category = item.request;
    }
    return item;
  }) as Array<TodoItem>;

  return {
    items,
  };
};

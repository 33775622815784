import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const TextWrapper = styled.span`
  color: ${COLORS.primaryText};
`;

export const DatePickerWrapper = styled.span`
  max-width: 200px;
`;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useSalesClientOpportunityPopupStore } from '../../store';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { LeftWrapper,RightWrapper } from '../../styles';

import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from '../../data';
import { OPPORTUNITY_FIELD_NAMES } from '@constants/salesCycleOpportunity';


export const AdditionalDetails = observer(() => {
  const { control } = useFormContext();

  const {
    isLoad,
    notesUtilsStore,
    sourceOptions
  } = useSalesClientOpportunityPopupStore();

  const isDisabled = notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad;

  return (
    <FormBlock
      isBorderBottom={ false }
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Additional Details' } />
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Source' }
          >
            <HFSelect
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.sourceId }
              options={ sourceOptions }
              placeholder={ 'Please Select' }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Won Date' }
          >
            <HFDatePicker
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.wonDate }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Est. Close Date' }
          >
            <HFDatePicker
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.estCloseDate }
            />
          </FormBlock.FormField>
        </LeftWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

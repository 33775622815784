import React, { useEffect } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { useAddUserPopupLocalStore } from './components/Content/store';

import { Actions } from './components';
import { Content } from './components/Content/Content';
import Modal from '@/components/Modal';
import Preloader from '@components/Preloader';

import { getValidationScheme } from '@pages/NewUserSettings/components/Tabs/validationScheme';
import { UserProfileFormInputNames } from '@/pages/NewUserSettings/data';

import { Spacer } from './styles';

import { AddUserPopupProps } from './types';
import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';

export const AddUserPopup = observer(({
  closeModal,
  modalProps
}: AddUserPopupProps) => {
  const notificationStore = useStore().NotificationsStore;
  const addUserPopupLocalStore = useAddUserPopupLocalStore();
  const { isFetching } = addUserPopupLocalStore;

  const methods: UseFormReturn<UserProfileType> = useForm<UserProfileType>({
    resolver: yupResolver(getValidationScheme(UserProfileFormInputNames)),
  });

  useEffect(() => {
    addUserPopupLocalStore.init({
      closeModal,
      useFormMethods: methods,
      updateUsersList: modalProps.updateUsersList,
      notificationStore
    });
    return () => addUserPopupLocalStore.reset();
  }, [addUserPopupLocalStore, closeModal, methods, modalProps.updateUsersList, notificationStore]);

  return (
    <FormProvider { ...methods }>
      <Modal
        onClose={ closeModal }
        title={ 'Add New User' }
      >
        <Modal.Content>
          {
            isFetching ? (
              <Spacer>
                <Preloader/>
              </Spacer>
            ) : (
              <Content/>
            )
          }
        </Modal.Content>
        <Modal.Footer>
          <Actions/>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});

import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { TodoMassDeleteConfirmationPopupProps } from './types';


export const TodoMassDeleteConfirmationPopup = ({
  closeModal,
  modalProps: {
    deleteData,
    onConfirm
  }
}: TodoMassDeleteConfirmationPopupProps) => {

  const onConfirmClick = useCallback(() => {
    closeModal();
    onConfirm(deleteData);
  }, [onConfirm, closeModal]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'No' }
      confirmLabel={ 'Yes' }
      isOpen={ true }
      message={ 'Are you sure you want to delete task?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    />
  );
};

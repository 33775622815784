import React from 'react';

import { StyledBubbleButton } from './styles';

import { BubbleButtonProps } from '@components/BubbleButton/types';

export const BubbleButton = ({
  onClick,
  $isActive,
  $isSecondActiveStyle,
  children,
  BtnProps,
}: BubbleButtonProps) => {
  return (
    <StyledBubbleButton
      $isActive={ $isActive }
      $isSecondActiveStyle={ $isSecondActiveStyle }
      color={ 'primary' }
      onClick={ onClick }
      variant={ 'contained' }
      { ...BtnProps }
    >
      {children}
    </StyledBubbleButton>
  );
};

import ApiService from '@services/api/api.service';

const deleteRelatedContact = (params) => {
  return ApiService.delete('api/contact/related/delete', { params });
};

const getRelatedContacts = (params) => {
  return ApiService.post('api/contact/related/getByContact', params);
};

const getNotRelatedContacts = (params) => {
  return ApiService.get('api/crm/contact/searchContactByNameForRelation', {
    params
  });
};

const getContactByNameForRelationWithoutContactId = (params) => {
  return ApiService.get(
    'api/crm/contact/searchContactByNameForRelationWithoutContact',
    { params }
  );
};

const saveRelatedContact = (data) => {
  return ApiService.post('api/contact/related/save', data);
};

export {
  deleteRelatedContact,
  getContactByNameForRelationWithoutContactId,
  getNotRelatedContacts,
  getRelatedContacts,
  saveRelatedContact
};

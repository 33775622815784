import HFCheckbox from '@modules/HookFormComponents/HFCheckbox';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import React from 'react';

import {
  CONTACT_TYPE,
  LEFT_COLUMN_WIDTH_V_TWO,
  RIGHT_COLUMN_WIDTH_V_TWO,
} from '@modules/AddContactModal/data';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import { CommunicateWith } from './components';

import { LeftColumnWrapperVariantTwo, RightColumnWrapperVariantTwo } from '@modules/AddContactModal/components/Columns';

import { maxInputLength } from '@/shared/utils/maxInputLength';

import { CheckBoxContainer, PhoneFieldContainer } from './styles';

const MENU_LIST_PROPS = {
  style: {
    maxHeight: 210,
  }
};

function ContactInformation({ properties, type }) {
  const { control, options } = properties;

  const householdType = type == CONTACT_TYPE.Household;
  const individualType = type === CONTACT_TYPE.Individual;

  return (
    <FormBlock isBorderBottom={ false } >
      <FormBlock.Header title={ 'Primary Contact Information' }/>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
          label={ 'Mailing Address'  }
        >
          <HFTextInput
            inputProps={ maxInputLength(50) }
            control={ control }
            name={ '2.mailingLocation' }
            placeholder={ 'Enter Mailing Address Line 1' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
          label={ 'Address Line 2'  }
        >
          <HFTextInput
            inputProps={ maxInputLength(50) }
            control={ control }
            name={ '2.addressLine2' }
            placeholder={ 'Enter Address Line 2' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantTwo>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
            label={ 'State' }
          >
            <HFSelect
              control={ control }
              name={ '2.address.state' }
              options={ options.states }
              placeholder={ 'Select State' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantTwo>
        <RightColumnWrapperVariantTwo>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_TWO }
            label={ 'City' }
          >
            <HFTextInput
              inputProps={ maxInputLength(50) }
              control={ control }
              name={ '2.address.city' }
              placeholder={ 'Enter City Name' }
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantTwo>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapperVariantTwo>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
            label={ 'ZIP' }
          >
            <HFTextInput
              inputProps={ maxInputLength(9) }
              control={ control }
              name={ '2.address.zip' }
              placeholder={ 'Enter ZIP Code' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapperVariantTwo>
        <RightColumnWrapperVariantTwo>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH_V_TWO }
            label={ 'Country' }
          >
            <HFSelect
              MenuListProps={ MENU_LIST_PROPS }
              control={ control }
              name={ '2.address.country' }
              options={ options.countries }
              placeholder={ 'Select Country' }
            />
          </FormBlock.FormField>
        </RightColumnWrapperVariantTwo>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
          label={ 'Mobile Telephone' }
        >
          <PhoneFieldContainer>
            <HFPhoneField
              control={ control }
              name={ '2.mobilePhone' }
            />
          </PhoneFieldContainer>
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
          label={ 'Email' }
        >
          <HFTextInput
            control={ control }
            name={ '2.email' }
            placeholder={ 'Example@domain.com' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>

      {
        !individualType &&
        <FormBlock.RowWrapper>
          <LeftColumnWrapperVariantTwo>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
              label={ 'Contact Preference' }
            >
              <HFSelect
                control={ control }
                name={ '2.preference' }
                options={ options.preferences }
              />
            </FormBlock.FormField>
          </LeftColumnWrapperVariantTwo>
          {
            householdType &&
            <RightColumnWrapperVariantTwo>
              <CommunicateWith
                control={ control }
                options={ options.communicateWith }
              />
            </RightColumnWrapperVariantTwo>
          }
        </FormBlock.RowWrapper>
      }
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_TWO }
          label={ 'Rules' }
        >
          <CheckBoxContainer>
            <HFCheckbox
              $distanceFromLabel={ '10px' }
              control={ control }
              label='Do Not Call'
              name='2.rules.allowCall'
            />
            <HFCheckbox
              $distanceFromLabel={ '10px' }
              control={ control }
              label='Do Not Email'
              name='2.rules.allowEmail'
            />
            <HFCheckbox
              $distanceFromLabel={ '10px' }
              control={ control }
              label='Do Not Mail'
              name='2.rules.allowMail'
            />
            <HFCheckbox
              $distanceFromLabel={ '10px' }
              control={ control }
              label='Do Not Text'
              name='2.rules.allowText'
            />
          </CheckBoxContainer>
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>

  );
}

export default ContactInformation;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";

import { TagClose,TagDelete,TagSave } from '@/assets';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { tagsTypes } from '@modules/AddContactModal/addContactStore/data/constantData';
import TextWithTooltip from '@components/TextWithTooltip';

const useStyles = makeStyles({
  options: {
    '&::after': {
      content: props => `' (${props.numberOfOptions})'`
    }
  }
});
export default function Tag(props) {
  const { viewMode, onHandleChange, onHandleDelete, classes, numberOfOptions, className, tag } = props;

  const [originalValue, setOriginalValue] = React.useState(tag.label);
  const [tagValue, setTagValue] = React.useState(tag.label);
  const [tagViewMode, setViewMode] = React.useState(viewMode);

  const customClasses = useStyles({ numberOfOptions });

  const handleDelete = (event) => {
    event.nativeEvent.preventDefault();
    event.nativeEvent.stopPropagation();
    onHandleDelete(tag);
  };

  const handleInputChange = (event) => {
    setTagValue(event.target.value);
  };

  const handleChange = () => {
    onHandleChange(tag, tagValue);
    setOriginalValue(tagValue);
    setViewMode(!tagViewMode);
  };

  const handleClose = () => {
    setTagValue(originalValue);
    setViewMode(!tagViewMode);
  };

  const handleTagClick = () => {
    if (tagViewMode && tag.type === tagsTypes.new) {
      setViewMode(!tagViewMode);
    }
  };

  return (
    <div className={ clsx(classes.tag, className) } onClick={ handleTagClick }>
      {tagViewMode && <TextWithTooltip text={ tagValue || '' } />}
      {tagViewMode && <IconButton className={ classes.tagCloseBtn } onClick={ handleDelete }
        size="large">
        <TagDelete className={ classes.tagCloseIcon } />
      </IconButton>}
      {!tagViewMode && <input onChange={ handleInputChange } value={ tagValue } />}
      {!tagViewMode && <IconButton className={ classes.tagViewBtn } onClick={ handleChange }
        size="large">
        <TagSave />
      </IconButton>}
      {!tagViewMode && <IconButton className={ classes.tagViewBtn } onClick={ handleClose }
        size="large">
        <TagClose />
      </IconButton>}
    </div>
  );
}

Tag.propTypes = {
  viewMode: PropTypes.bool,
  onHandleChange: PropTypes.func,
  onHandleDelete: PropTypes.func,
  value: PropTypes.any,
};

Tag.defaultProps = {
  viewMode: true,
  onHandleChange: () => { },
  onHandleDelete: () => { },
  value: 'Tag',
};

import React from 'react';

import { COLORS } from '@constants/colors';
import { Check } from '@/assets';

import { ColorShemes, StatusTemplates } from './types';

// TODO red, grey, blue
export const COLOR_SCHEME: ColorShemes = {
  green: {
    color: COLORS.green,
    bgColor: COLORS.greenBg
  }
};

export const STATUS_TEMPLATES: StatusTemplates = {
  completed: {
    label: 'Completed',
    icon: Check,
    colorScheme: COLOR_SCHEME.green
  },
  verified: {
    label: 'Verified',
    icon: Check,
    colorScheme: COLOR_SCHEME.green
  },
};

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { NOTE_LINKED_CONTACT_ACTIONS } from '@modules/NotesAndHistory';

export const COMMON_TABS_VALUES = {
  BASIC_INFORMATION: 'Basic Information',
  STANDING_INSTRUCTIONS: 'Standing Instructions',
  BALANCE_HISTORY: 'Balance History'
};

export const TABS = {
  [ACCOUNTS_AND_PLANS_TYPES.ACCOUNT]: optionsFromValue(Object.values(COMMON_TABS_VALUES)),
  [ACCOUNTS_AND_PLANS_TYPES.POLICY]: optionsFromValue(Object.values(COMMON_TABS_VALUES)),
  [ACCOUNTS_AND_PLANS_TYPES.PLAN]: optionsFromValue([COMMON_TABS_VALUES.BASIC_INFORMATION]),
} as const;
export const LABEL_SIZES = {
  leftLabelWidth: 155,
  rightLabelWidth: 139
};

export const MANAGED_STATUSES = {
  managed: 'Managed',
  heldAway: 'Held Away',
};

export const HELD_AWAY_STATUSES = {
  movable: 'Movable',
  nonMovable: 'Nonmovable',
};

export const ACCOUNT_ACCOUNT_TYPES = {
  cashAndEquivalent: 'Cash & Equivalent',
  investments: 'Investments',
  otherAccount: 'Other Account'
};

export const DEFAULT_SELECTED = {
  status: 'Paperwork / Application',
  typeForAccount: ACCOUNT_ACCOUNT_TYPES.cashAndEquivalent,
  typeForPolicy: 'Annuities',
  managedStatus: MANAGED_STATUSES.managed,
  heldAwayStatus: '',
  taxQualification: 'Taxable',
  registrationType: 'Individual',
  beneficiariesType: 'Primary',
};

export const PLAN_DEFAULT_SELECTED = {
  feeStatus: 'Unpaid',
  feeFrequency: 'Once',
};

export const ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES_MESSAGES = {
  [NOTE_LINKED_CONTACT_ACTIONS.reconnect]:
    'All the Notes from the Accounts and Plans will be reconnected to a new Linked Contact.',
};

import styled from 'styled-components';

export const UsersWrapper = styled.div`
  width: 100%;
`;

export const UserList = styled.div`
  width: 100%;
  padding-top: 15px;
  
  & > *:last-child {
    margin-bottom: 10px;
  }
`;

export const UserItemWrapper = styled.div`

  & > div div:nth-child(2) {
    margin-left: 10px;
  }
`;

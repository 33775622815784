import ListCriteriaStore  from '@/shared/services/store/listsStore/subStores/listCriteriaStore';
import {
  CONTACT_DETAILS_FIELDS,
  CONTACT_INFORMATION_FIELDS,
  DATE_FIELDS,
  INPUTS_TYPE_NUMBER_FIELDS,
  INPUTS_TYPE_TEXT_FIELDS,
  IS_EMPTY_OR_IS_NOT_EMPTY,
  MULTIPLE_SELECTS,
  OFFICE_DETAILS_FIELDS,
  OPERAND_OPTIONS,
  SINGLE_SELECTS,
  TAGS_FIELDS
} from '@constants/listsCriteria';
import { LIST_VALUE_NESTED_KEYS } from '@constants/lists';

export const getOptionsFromBackend = (store: ListCriteriaStore, typeValue: string) => {
  switch (typeValue) {
  case CONTACT_INFORMATION_FIELDS.Address_State:
    return store.statesOptions;
  case CONTACT_INFORMATION_FIELDS.Address_Country:
    return store.countriesOptions;
  case TAGS_FIELDS.general:
    return store.tagsOptions.General;
  case TAGS_FIELDS.interest:
    return store.tagsOptions.Interest;
  // TODO: after create Opportunities tags
  // case TAGS_FIELDS.opportunities:
  //   return store.tagsOptions.Opportunities;
  case OFFICE_DETAILS_FIELDS.Primary_Advisor:
    return store.allClientsInGroupOptions;
  case OFFICE_DETAILS_FIELDS.Secondary_Advisor:
  case OFFICE_DETAILS_FIELDS.created_by:
    return store.advisorOptions;
  case CONTACT_DETAILS_FIELDS.Source:
    return store.sourcesOptions;
  case CONTACT_DETAILS_FIELDS.Source_Category:
    return store.sourceCategoriesOptions;
  default:
    return [];
  }
};

export const getAndSetValueForReset = (setValue: any, path: string, option: string, operand: string) => {
  if((!operand && !option) || IS_EMPTY_OR_IS_NOT_EMPTY.includes(operand)){
    setValue(path, null);
    return ;
  }

  if(MULTIPLE_SELECTS.includes(option as any)){
    setValue(path, null);
    setValue(`${path}`, []);
    return ;
  }

  if(
    INPUTS_TYPE_NUMBER_FIELDS.includes(option as any) ||
    INPUTS_TYPE_TEXT_FIELDS.includes(option as any) ||
    DATE_FIELDS.includes(option as any) ||
    SINGLE_SELECTS.includes(option as any)
  ){
    setValue(path, null);
    if(operand === OPERAND_OPTIONS.In_Between){
      setValue(`${path}.${LIST_VALUE_NESTED_KEYS.first}`, null);
      setValue(`${path}.${LIST_VALUE_NESTED_KEYS.last}`, null);
    }else {
      setValue(`${path}.${LIST_VALUE_NESTED_KEYS.first}`, null);
    }
    return ;
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useParams } from 'react-router-dom';

import { createPassword } from '@services/api/common/common';
import { useStore } from '@store';

import { Content } from '../components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import Preloader from '@components/Preloader';

import { FORM_FIELD_NAMES, FORM_DEFAULTS_VALUES } from './data';
import { ROUTES } from '@constants/routes';
import { validationSchema } from './validationSchema';

import { InfoSubTitle } from './styles';

import NewPassword from '@components/NewPassword';
import HFPasswordInput from '@modules/HookFormComponents/HFPasswordInput';


export const RestorePassword = () => {

  const { RouterStore } = useStore();

  const { pathname } = useLocation();
  let { token } = useParams<{ token: string; }>();

  const [ isLoading, setLoading ] = useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: FORM_DEFAULTS_VALUES,
  });

  const { control, handleSubmit } = methods;

  const onSubmitClick = useCallback(() => {
    handleSubmit(async (data) => {
      const { newPassword } = data;
      const params = {
        token,
        password: newPassword
      };

      try {
        setLoading(true);

        await createPassword(params);

        RouterStore.customPush(ROUTES.login);
      } catch(error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [ handleSubmit, RouterStore, token ]);

  useEffect(() => {
    const url = pathname.split('/');
    token = url[url.length - 1];

    RouterStore.customPush(ROUTES.restorePasswordToken);
  }, []);

  return (
    <Content>
      <Content.Header/>

      <Content.Main>
        <Content.Title>Please Reset Your Password</Content.Title>
        <InfoSubTitle>
          Please Choose a New Password.
        </InfoSubTitle>

        <Content.Label>Password</Content.Label>
        <NewPassword
          control={ control }
          name={ FORM_FIELD_NAMES.newPassword }
        />

        <Content.Label>Confirm Password</Content.Label>
        <HFPasswordInput
          control={ control }
          name={ FORM_FIELD_NAMES.confirmPassword }
          placeholder={ 'Confirm Password' }
        />

        <BlockButton
          colorSchema={ COLOR_SCHEME_TYPES.contained }
          onClick={ onSubmitClick }
          style={ {
            marginTop: '20px'
          } }
          variant='contained'
        >
          SUBMIT
        </BlockButton>
      </Content.Main>

      { isLoading && <Preloader/> }
    </Content>
  );
};

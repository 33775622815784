import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import { WrapperProps } from './types';

export const ActionsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  color: ${COLORS.primaryText};
  visibility: hidden;
  
  & > * {
    margin-right: 15px;
  }
`;

export const DragIconWrapper = styled.div`
  width: 7px;
  height: 18px;
  
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  
  &:hover {
    color: ${COLORS.orange}
  }

  & > svg > path > * {
    fill: currentColor;
  }
  
  visibility: hidden;
`;


export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-left: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  
  ${({ $isHovered }) => $isHovered && css`
    padding-left: 26px;
    box-shadow:
              0px 5px 5px -2px rgba(16,28,91,0.07),
              0px -5px 5px -2px rgba(16,28,91,0.07);
  
    & ${ActionsWrapper},
    & ${DragIconWrapper}{
      visibility: visible;
    }
  `}
  }
  
  ${({ $isDragging }) => $isDragging && css`
    & > * {
      opacity: 0;
    }
    box-shadow: none;
    background-color: ${COLORS.select};
  `}
`;

export const LabelWrapper = styled.div``;


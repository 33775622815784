import { getSortedUserOption } from '@/shared/utils/filterUtils';

const createItemWithValueAndLabel = item => ({
  value: item,
  label: item,
});

export const filterOptionsNormalizer = (data, currentUserId) => ({
  category: data?.category?.map(createItemWithValueAndLabel) || [],
  classification: data?.coreClassification?.map(createItemWithValueAndLabel) || [],
  clients: getSortedUserOption(data?.clients, currentUserId) || [],
});

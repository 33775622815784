import * as yup from 'yup';
import { validationMessages } from '@constants/validationMessages';
import { OPPORTUNITY_FIELD_NAMES } from '@constants/salesCycleOpportunity';
import { dateOutputValidation } from '@constants/validationPatterns';
import { isDateAfter } from '@/shared/utils/dateValidationUtils';

export const validationSchema = yup.object().shape({
  [OPPORTUNITY_FIELD_NAMES.contactId]: yup.number()
    .nullable()
    .required(validationMessages.required),
  [OPPORTUNITY_FIELD_NAMES.stage]: yup.string()
    .nullable()
    .required(validationMessages.required),
  [OPPORTUNITY_FIELD_NAMES.opportunityOpenDate]:  yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate),
  [OPPORTUNITY_FIELD_NAMES.wonDate]:  yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or grater than opportunityOpenDate',
      validationMessages.invalidDate,
      (value, context) => (
        isDateAfter({
          dateStringToCompare: context.parent[OPPORTUNITY_FIELD_NAMES.opportunityOpenDate],
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [OPPORTUNITY_FIELD_NAMES.estCloseDate]:  yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
});

import React from 'react';
import ListItemWithColorIndicator, { INDICATOR_TYPES } from '@components/ListItemWithColorIndicator';

import { ValueLabelObj } from '@/shared/types/commonTypes';
import { ClientOpportunityStatusType } from '@/shared/types/salesCycleOpportunity';
import { OPPORTUNITY_STATUSES_COLOR_MAP } from '@constants/salesCycleOpportunity';

export const SalesClientOpportunitiesStatusOption = (renderData: ValueLabelObj | ValueLabelObj[]) => {
  if (Array.isArray(renderData)) {
    return null;
  }
  return (
    <ListItemWithColorIndicator
      indicatorColor={ OPPORTUNITY_STATUSES_COLOR_MAP[renderData.value as ClientOpportunityStatusType] }
      label={ renderData.label as string }
      type={ INDICATOR_TYPES.round }
    />
  );
};

import React, { useCallback, useContext } from 'react';

import BlockButton from '@components/BlockButton';
import { COLORS } from '@constants/colors';

import { FooterProps } from '../../types';

import { Wrapper } from './styles';

import { NotificationWithActionContext } from '@components/NotificationWithAction/context';

const BUTTON_STYLE = {
  minWidth: 'auto',
  padding: 0
};

export const Footer = ({
  text,
  Icon
}: FooterProps) => {
  const { action } = useContext(NotificationWithActionContext);

  return (
    <Wrapper>
      <BlockButton
        $hoverColor={ 'currentColor' }
        onClick={ action }
        startIcon={ Icon }
        style={ BUTTON_STYLE }
      >
        {text}
      </BlockButton>
    </Wrapper>
  );
};

import { isAfter, isBefore, isEqual, isValid, parse } from 'date-fns';
import { YEAR_MONTH_DAY, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

const getDate = ({
  dateString,
  timeString,
  withoutTimeFormat = YEAR_MONTH_DAY,
  withTimeFormat = YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
}: { 
  dateString: string,
  timeString?: string,
  withoutTimeFormat?: string,
  withTimeFormat?: string
}): Date => {
  if (!timeString) {
    return parse(dateString, withoutTimeFormat, new Date());
  }

  return parse(`${ dateString } ${ timeString }`, withTimeFormat, new Date());
};

export const getDates = ({
  endDateString,
  endTimeString,
  startDateString,
  startTimeString,
  withoutTimeFormat = YEAR_MONTH_DAY,
  withTimeFormat = YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS,
}: {
  endDateString: string,
  endTimeString?: string,
  startDateString: string,
  startTimeString?: string,
  withoutTimeFormat?: string,
  withTimeFormat?: string
}): Record<'start' | 'end', Date> => ({
  start: getDate({
    dateString: startDateString,
    timeString: startTimeString,
    withoutTimeFormat,
    withTimeFormat
  }),
  end: getDate({
    dateString: endDateString,
    timeString: endTimeString,
    withoutTimeFormat,
    withTimeFormat
  }),
});

export const isDateBefore = ({
  dateString,
  dateStringToCompare,
  withEqual = false
}: {
  dateString: string | null | undefined,
  dateStringToCompare: string,
  withEqual?: boolean,
}): boolean => {
  if(!dateString) {
    return true;
  }
  const date = getDate({ dateString });
  const dateToCompare = getDate({ dateString: dateStringToCompare });

  if (isValid(date) && isValid(dateToCompare)) {
    return withEqual
      ? isEqual(date, dateToCompare) || isBefore(date, dateToCompare)
      : isBefore(date, dateToCompare);
  }

  return true;
};

export const isDateAfter = ({
  dateString,
  dateStringToCompare,
  withEqual = false
}: {
  dateString: string | null | undefined,
  dateStringToCompare: string,
  withEqual?: boolean,
}): boolean => {
  if(!dateString) {
    return true;
  }
  const date = getDate({ dateString });
  const dateToCompare = getDate({ dateString: dateStringToCompare });

  if (isValid(date) && isValid(dateToCompare)) {
    return withEqual
      ? isEqual(date, dateToCompare) || isAfter(date, dateToCompare)
      : isAfter(date, dateToCompare);
  }
  return true;
};

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import {
  RenderRow
} from '@modules/AccountsAndPlansPopup/components/Content/components/PopupTable/types';

import { CommonBalanceHistoryRow } from './components';

import { COLUMNS } from './data';

import { BALANCE_HISTORY_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';

import {
  BalanceHistoryItem,
  BalanceHistoryType,
} from '@modules/AccountsAndPlansPopup/types';

import { generateNewBalanceHistoryItem } from './utils';

export const CommonBalanceHistory = observer(() => {
  const store = useAccountAndPlansPopupLocalStore();
  const { isNotesPanelInAddOrEditMode } = store.notesUtilsStore;

  const renderRow: RenderRow<BalanceHistoryItem> = useCallback(({
    control,
    disable,
    disableDelete,
    disableEdit,
    field,
    fieldPath,
    fields,
    getSiblingPath,
    index,
    isEdit,
    onDelete,
    onEdit,
    onRowClick,
    onSave,
    originalIndex,
    setValue,
  }) => (
    <CommonBalanceHistoryRow
      control={ control }
      disable={ disable }
      disableDelete={ disableDelete }
      disableEdit={ disableEdit }
      field={ field }
      fieldPath={ fieldPath }
      fields={ fields }
      getSiblingPath={ getSiblingPath }
      index={ index }
      isEdit={ isEdit }
      key={ field.useFieldArrayCustomId }
      onDelete={ onDelete }
      onEdit={ onEdit }
      onRowClick={ onRowClick }
      onSave={ onSave }
      originalIndex={ originalIndex }
      setValue={ setValue }
    />
  ), []);

  return (
    <PopupTable<BalanceHistoryType, BalanceHistoryItem>
      addButtonLabel={ 'Add Update' }
      columns={ COLUMNS }
      disable={ isNotesPanelInAddOrEditMode }
      generateNewItem={ generateNewBalanceHistoryItem }
      isReturnLastIfEqual={ true }
      name={ BALANCE_HISTORY_NAMES.balanceHistory }
      onRowAddEditEnd={ store.resetBalanceHistoryRowState.bind(store) }
      onRowAddEditStart={ store.setAddEditBalanceHistoryRowState.bind(store) }
      renderRow={ renderRow }
      title={ 'Balance History' }
    />
  );
});

import { useCallback, useEffect, useRef, useState } from 'react';
import { ArrayPath, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { getTimeStampInMs } from '@/shared/utils/getTimeStampInMs';

import {
  EditIndexType,
  ExtendType,
  FieldWithOriginalIndex,
  ForceFields, GetSiblingPath,
  LocalKeyNameType,
  OnAddClick,
  OnDelete,
  OnEdit,
  OnRowClick,
  OnSave,
  UsePopupTableStateProps,
  UsePopupTableStateReturn,
} from './types';

const mapFieldsToOriginalIndexes = <TFormFieldType extends {} >
  (fields: any): Array<FieldWithOriginalIndex<TFormFieldType>>  => (
    (fields as unknown as ForceFields<TFormFieldType>).map((item, index) => ({
      data: item,
      originalIndex: index
    }))
  );

export const usePopupTableState = <
  TFormFieldsType extends ExtendType,
  TFormFieldType extends {}
>({
    generateNewItem,
    name,
    onRowAddEditEnd,
    onRowAddEditStart,
  }: UsePopupTableStateProps<TFormFieldsType, TFormFieldType>): UsePopupTableStateReturn<TFormFieldType>  => {
  const {
    control,
    getValues,
    setValue,
    trigger,
    watch
  } = useFormContext<TFormFieldsType>();
  const { fields, append, update, remove } = useFieldArray<
    TFormFieldsType,
    ArrayPath<TFormFieldsType>,
    keyof LocalKeyNameType
  >({
    control,
    name,
    keyName: 'useFieldArrayCustomId'
  });

  // need it for actual fields state
  const fieldsCurrentState = useWatch({
    control,
    // @ts-ignore
    name,
  });

  const [ isNewItem, setIsNewItem ] = useState<boolean>(false);
  const [ editedIndex, setEditedIndex] = useState<EditIndexType>(null);
  const [ preEditFieldState, setPreEditFieldState] = useState<TFormFieldType| null>(null);

  const editIndexRef = useRef<EditIndexType>(null);
  const isNewItemRef = useRef<boolean>(false);
  const preEditFieldStateRef = useRef<TFormFieldType| null>(null);

  const setEditIndexAction = useCallback((editIndex: EditIndexType) => {
    setEditedIndex(editIndex);
    editIndexRef.current = editIndex;
  }, []);

  const setIsNewItemAction = useCallback((state: boolean) => {
    setIsNewItem(state);
    isNewItemRef.current = state;
  }, []);
  
  const setPreEditFieldStateAction = useCallback((state: TFormFieldType| null) => {
    setPreEditFieldState(state);
    preEditFieldStateRef.current = state;
  }, []);

  const onAddClick: OnAddClick = useCallback(() => {
    const editIndex = fields.length > 0 ? fields.length : 0;
    onRowAddEditStart({
      index: editIndex
    });
    setEditIndexAction(editIndex);
    setIsNewItemAction(true);
    append(
      generateNewItem(
        getTimeStampInMs(new Date()),
        fields as unknown as ForceFields<TFormFieldType>
      ) as any
    );
  }, [fields, onRowAddEditStart, setEditIndexAction, setIsNewItemAction, append, generateNewItem]);

  const onDelete: OnDelete = useCallback((index) => {
    onRowAddEditEnd();
    remove(index);
    setEditIndexAction(null);
  }, [onRowAddEditEnd, remove, setEditIndexAction]);

  const onEdit: OnEdit = useCallback((index) => {
    const fieldState = fields[index];
    onRowAddEditStart({
      index,
      preEditState: fieldState as any,
    });

    setPreEditFieldStateAction(fieldState as any);
    setEditIndexAction(index);
  }, [fields, onRowAddEditStart, setPreEditFieldStateAction, setEditIndexAction]);

  const onRowClick: OnRowClick = useCallback((index) => {
    const isSomeFieldEdited = typeof editIndexRef.current === 'number';
    if(!isSomeFieldEdited) {
      onEdit(index);
    }
  }, [onEdit]);

  const onSave: OnSave = useCallback(async (fieldPath: string) => {
    const validationResult = await trigger(fieldPath as any);

    if(validationResult) {
      const value = getValues(fieldPath as any);

      if(isNewItem) {
        setIsNewItemAction(false);
      }
      if(preEditFieldState){
        setPreEditFieldStateAction(null);
      }
      // Use ignore, because no way to make field type extendable with hook form
      // @ts-ignore
      update(editedIndex, value);
      setEditIndexAction(null);
      onRowAddEditEnd();
    }
  }, [
    trigger, getValues, isNewItem, preEditFieldState, update, editedIndex, setEditIndexAction,
    setIsNewItemAction, setPreEditFieldStateAction, onRowAddEditEnd
  ]);
  
  const getSiblingPath: GetSiblingPath = useCallback((index, chunk) => {
    const pathToRoot = `${name}.${index}`;
    if(chunk){
      return `${pathToRoot}.${chunk}`;
    }
    return pathToRoot;
  }, [name]);

  
  useEffect(() => {
    // This return need in two ways
    // 1) If user add new item, and don't click save, we need remove it, if tab change
    // 2) If user edit saved item, and don't click save we need reset to prev state of row, if tab change
    // Note! the same procedures implemented at accountAndPlansPopupLocalStore at onTypeChange method
    // we need to do this to avoid set incorrect data between type changes
    return () => {
      const isEditedIndex = typeof editIndexRef.current === 'number';
      if(isEditedIndex && isNewItemRef.current){
        remove(editIndexRef.current as number);
        editIndexRef.current = null;
        setEditedIndex(null);
      }
      if(isEditedIndex && preEditFieldStateRef.current){
        update(editIndexRef.current as number, preEditFieldStateRef.current as any);
      }

      onRowAddEditEnd();
    };
  },[onRowAddEditEnd, remove, update]);

  return {
    control,
    editedIndex,
    fieldsToRender: mapFieldsToOriginalIndexes(fields),
    getSiblingPath,
    onAddClick,
    onDelete,
    onEdit,
    onRowClick,
    onSave,
    setValue,
  };
};
import React from 'react';

import { OutOfSight } from './OutOfSight';

import { OutOfSightTDWrapper } from './styles';

import { OutOfSightTDProps } from './types';

export const OutOfSightTD = ({ onClick }: OutOfSightTDProps) => (
  <OutOfSightTDWrapper onClick={ onClick }>
    <OutOfSight/>
  </OutOfSightTDWrapper>
);
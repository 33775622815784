import axiosApi from './axiosApi';

class ApiService {
  get(url, options = {}) {
    return axiosApi.get(url, options);
  }

  post(url, data, options = {}) {
    return axiosApi.post(url, data, options);
  }

  put(url, data, options = {}) {
    return axiosApi.put(url, data, options);
  }

  patch(url, data, options = {}) {
    return axiosApi.patch(url, data, options);
  }

  delete(url, options = {}) {
    return axiosApi.delete(url, options);
  }
}

const apiService = new ApiService();
export default apiService;

import { TODOS_FILTER_NAMES } from '@constants/todosData';

export const CLASSIFICATION =   {
  name: TODOS_FILTER_NAMES.classification,
  label: 'Core Classification',
  values: [ ]
};

export const USERS = {
  name: TODOS_FILTER_NAMES.users,
  label: 'Assigned To',
  values: []
};

export const PRIORITY =   {
  name: TODOS_FILTER_NAMES.priority,
  label: 'Priority',
  values: [ ]
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin-left: 144px;
  color: ${ COLORS.helpText };
`;

import React from 'react';
import { observer } from 'mobx-react';

import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { useStore } from '@services/store/store';

import PopupActionsButtons from '@components/PopupActionsButtons';
import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';
import { NotesAndHistoryNotificationWrapper } from '@modules/NotesAndHistory';

import { InnerWrapper, Wrapper } from './styles';

export const Actions = observer(() => {
  const {
    id,
    isGlobalDisabled,
    isQuickCompleteDisable,
    notesUtilsStore,
    onCancel,
    onComplete,
    onCompleteAndCopy,
    onDelete,
    onSave,
    onSaveAndCopy,
  } = useTodoPopupLocalStore();

  const isButtonsDisabled = isGlobalDisabled;

  return (
    <Wrapper $preventClicks={ isButtonsDisabled }>
      <InnerWrapper>
        <NotesAndHistoryNotificationWrapper alertType={ notesUtilsStore.alertType }>
          <>
            <PopupSingleActionButton
              disabled={ isQuickCompleteDisable || isGlobalDisabled }
              onClick={ onComplete }
              type={ POPUP_BUTTON_TYPES.done }
            >
              Complete
            </PopupSingleActionButton>
            {
              id &&
              <>
                <PopupSingleActionButton
                  disabled={ isQuickCompleteDisable || isGlobalDisabled }
                  onClick={ onCompleteAndCopy }
                  style={ {
                    marginRight: '4px',
                    marginLeft: '4px',
                  } }
                  type={ POPUP_BUTTON_TYPES.completeAndCopy }
                >
                  Complete & Copy
                </PopupSingleActionButton>
                <PopupSingleActionButton
                  disabled={ isGlobalDisabled }
                  onClick={ onSaveAndCopy }
                  type={ POPUP_BUTTON_TYPES.saveAndCopy }
                >
                  Save & Copy
                </PopupSingleActionButton>
                <PopupSingleActionButton
                  disabled={ isGlobalDisabled }
                  onClick={ onDelete }
                  style={ {
                    marginLeft: '4px',
                  } }
                  type={ POPUP_BUTTON_TYPES.delete }
                >
                  Delete
                </PopupSingleActionButton>
              </>
            }
          </>
        </NotesAndHistoryNotificationWrapper>
      </InnerWrapper>
      <PopupActionsButtons
        CancelProps={ {
          disabled: isButtonsDisabled
        } }
        SaveProps={ {
          disabled: isButtonsDisabled
        } }
        cancelLabel={ notesUtilsStore.alertType ? 'No' : 'Cancel' }
        onCancelClick={ onCancel }
        onSaveClick={ onSave }
        saveLabel={ notesUtilsStore.alertType ? 'Yes' : 'Save' }
      />
    </Wrapper>
  );
});

import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import Table from '@components/Table';
import UserItem from '@components/NewUserItem';

import { ContactNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';


export const ContactsCells = observer(({
  item,
}: TrashCellsProps<ContactNormalizerItem['item']>) => {

  const { isContactsNamesReversed } = useStore().SettingsStore;

  return (
    <>
      <Table.Cell>
        <UserItem
          innerPadding={ '0px' }
          reverseNames={ isContactsNamesReversed }
          user={ item as any }
          withCoreClassification={ true }
        />
      </Table.Cell>
      
      <Table.Cell>{ item.type || '' }</Table.Cell>
    </>
  );
});

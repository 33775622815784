import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

export const TextBox = styled(MuiBox)` 
    padding: 5px 0 20px 0;
    font-size: 12px;
    line-height: 150%;
    color: ${COLORS.helpText};
    border-bottom: 1px solid ${COLORS.borders};
`;

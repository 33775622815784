import ApiService from '@services/api/api.service';
import {
  SalesActiveCycleGetByParams,
  SalesCycleDeleteParams,
  SalesCyclePartialUpdateData,
  SaveSalesActiveCyclePipelineData
} from '@/shared/types/salesActiveCycle';

import { GetNextAppointmentDateParams, UpdateFlagsParams } from '@/shared/types/commonTypes';

export const getSaleActiveCycleTable = (params?: any) => {
  return ApiService.get('/api/pipeline/getGrid', { params });
};

export const getSalesCyclePopupSettings = (params?: any, signal?: AbortSignal) => {
  return ApiService.get('api/pipeline/getSetting', { params, signal });
};

export const getSalesCycleItem = (params: SalesActiveCycleGetByParams, signal?: AbortSignal) => {
  return ApiService.get('/api/pipeline/getById', { params, signal });
};

export const getContactNextApptDate = (params: GetNextAppointmentDateParams, signal?: AbortSignal) => {
  return ApiService.get('/api/pipeline/getNextApptDate', { params, signal });
};

export const saveSalesActiveCyclePipeline = (data: SaveSalesActiveCyclePipelineData) => {
  return ApiService.post('api/pipeline/savePipeline', data);
};

export const deleteSalesCycle = (params: SalesCycleDeleteParams) => {
  return ApiService.delete('/api/pipeline/delete', { params });
};

export const updateSalesCycleFlags = (params: UpdateFlagsParams) => {
  return ApiService.post('/api/pipeline/updateFlags', params);
};

export const getKanban = (params?: any) => {
  return ApiService.get('/api/pipeline/getKanban', { params });
};

export const partialUpdateSalesCycle = (params: SalesCyclePartialUpdateData) => {
  return ApiService.patch('/api/pipeline/updateGrid', params);
};

export const getKanbanSettings = () => {
  return ApiService.get('/api/pipeline/getKanbanSettings');
};

import { action, flow, makeAutoObservable } from 'mobx';
import { cloneDeep, omit, pullAt } from 'lodash';

import {
  ContactCustomFieldType,
  DraggableContactCustomField,
  OnSaveContactCustomFieldArgs,
  SavedContactCustomFieldItem,
  SaveUpdateContactCustomFieldErrorResp,
  SaveUpdateContactCustomFieldResp
} from '@/shared/types/userSettingsContactCustomFields';

import {
  deleteContactCustomFieldsItem,
  saveContactCustomFieldsItem,
  updateContactCustomFieldsItem
} from '@services/api/userSettings/userSettingsContactCustomFields';


class ContactCustomField {
  loadSetter: (flag: boolean) => void;
  type:ContactCustomFieldType;
  items: Array<SavedContactCustomFieldItem> = [];
  prevState: Array<SavedContactCustomFieldItem> = [];

  constructor(
    type: ContactCustomFieldType,
    loadFlagSetter: (flag: boolean) => void
  ) {
    makeAutoObservable(this, {
      onDelete: flow.bound,
      onSave: flow.bound,
      onDrop: flow.bound,
      onMove: action.bound,
    });

    this.type = type;
    this.loadSetter= loadFlagSetter;
  }

  *onDelete(id: SavedContactCustomFieldItem['id'], index: number) {
    this.loadSetter(true);
    try {
      yield deleteContactCustomFieldsItem({ id });
      this.setItems(this.items.filter((item, itemIndex) => itemIndex !== index));
    } catch (error) {
      console.log(error);
    } finally {
      this.loadSetter(false);
    }
  }

  *onDrop(draggableItem: DraggableContactCustomField){
    try {
      this.loadSetter(true);
      yield updateContactCustomFieldsItem({
        ...omit(draggableItem, 'index'),
        sortOrder: draggableItem.index,
      });

    } catch (error) {
      console.log(error);
      this.items = this.prevState;
    } finally {
      this.loadSetter(false);
    }
  }

  onMove(draggableItem: DraggableContactCustomField, targetItem: DraggableContactCustomField){
    const clone = cloneDeep(this.items);
    let [itemToReplace] = pullAt(clone, draggableItem.index);
    clone.splice(targetItem.index, 0, itemToReplace);
    this.items = clone;
  }

  *onSave(
    {
      closeModal,
      data,
      errorSetter
    }: OnSaveContactCustomFieldArgs,
    index?: number
  ) {
    try {
      if(data?.id && typeof index === 'number'){
        const response: SaveUpdateContactCustomFieldResp = yield updateContactCustomFieldsItem(
          data as SavedContactCustomFieldItem
        );
        this.items[index] = response.data.data;
      } else {
        const response: SaveUpdateContactCustomFieldResp = yield saveContactCustomFieldsItem(data);
        this.items.push(response.data.data);
      }

      closeModal();
    } catch (error) {
      const errorResponse = error as SaveUpdateContactCustomFieldErrorResp;
      const message = errorResponse.response?.data?.message?.[0];
      if(message){
        errorSetter(message);
      }
    }
  }

  resetState() {
    this.setItems([]);
  }

  setItems(items: Array<SavedContactCustomFieldItem>) {
    this.prevState = this.items;
    this.items = items;
  }

}

export default ContactCustomField;

import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { getSortedUserOption } from '@/shared/utils/filterUtils';

import { ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@/shared/constants/accountsAndPlanData';

import { IdType } from '@/shared/types/commonTypes';
import {
  AccountAndPlanItemFull,
  AccountAndPlanItemShort,
  AccountAndPlansData,
  AccountAndPlansResponseType,
  ConvertedFiltersData,
  FiltersData
} from '@/shared/types/accountsAndPlan';

const accountAndPlansItemConverter = (item:AccountAndPlanItemFull)=> (
  {
    accountNumber: item?.accountNumber,
    accountType: item.accountType,
    balance: item?.balance,
    contactData: item?.contactData,
    flagged: Boolean(item.flagged),
    id: item.id,
    linkedContact: item.contactData,
    managedStatus: item.managedStatus,
    name: item.name,
    policyNumber: item?.policyNumber,
    status: item?.status,
  }
);

const accountAndPlansItemsConverter = (data: AccountAndPlansData): Array<AccountAndPlanItemShort> => {
  if (!data) {
    return [];
  }

  return Object.entries(data).reduce((acc: Array<AccountAndPlanItemShort>, [ key, item ]) => {
    if (isNaN(parseInt(key))) {
      return acc;
    }
    const convertedContactItem = accountAndPlansItemConverter(item as AccountAndPlanItemFull);
    acc.push(convertedContactItem);
    return acc;
  }, []);
};

const filterDataConverter = (filtersData: FiltersData, currentUserId:IdType):ConvertedFiltersData => {
  return {
    [ACCOUNTS_AND_PLANS_FILTER_NAMES.users]: getSortedUserOption(filtersData.clients, currentUserId),
    [ACCOUNTS_AND_PLANS_FILTER_NAMES.managedStatus]: optionsFromValue(filtersData?.managedStatus),
    [ACCOUNTS_AND_PLANS_FILTER_NAMES.status]: optionsFromValue(filtersData?.status)
  };
};

export const accountAndPlansNormalizer = (response: AccountAndPlansResponseType, currentUserId: IdType) => {
  const { data } = response.data.data;

  return {
    filtersData: filterDataConverter(data.filterData, currentUserId),
    accountAndPlansItems: accountAndPlansItemsConverter(data),
  };
};

import * as yup from 'yup';
import { isDateAfter, isDateBefore } from '@/shared/utils/dateValidationUtils';

import { commonValidation } from './commonValidation';
import { dateOutputValidation, positiveNumberWithOptionalTwoDecimal } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

import {
  PLAN_BASIC_INFORMATION_FIELDS_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

const {
  startDate,
  completionDate,
  feeAmount
} = PLAN_BASIC_INFORMATION_FIELDS_NAMES;

export const planValidationSchema = yup.object().shape({
  ...commonValidation,
  [completionDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or grater than startDate',
      validationMessages.invalidDate,
      (value, context) => (
        isDateAfter({
          dateStringToCompare: context.parent[startDate],
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [startDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than completionDate',
      validationMessages.invalidDate,
      (value, context) => (
        isDateBefore({
          dateStringToCompare: context.parent[completionDate],
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [feeAmount]: yup.string()
    .nullable()
    .when(feeAmount, {
      is: (feeAmountValue: string) => Boolean(feeAmountValue),
      then: (schema) => schema.matches(positiveNumberWithOptionalTwoDecimal, validationMessages.invalid),
    })
},[[feeAmount, feeAmount]]);

import styled, { css } from 'styled-components';

import { WrapperType } from './types';

export const Wrapper = styled.div<WrapperType>`
  ${({ $maxWidth }) => $maxWidth && css`
    flex-grow: 1;
    max-width: ${$maxWidth};
  `}
`;

import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '@store';

import { CONTACT_TYPES } from '@constants/common';

import { getAvatarData } from '@/shared/utils/getAvatarData';

import Avatar from '@components/Avatar';
import CoreClassificationIcon from '@components/CoreClassificationIcon';
import Rank from '@components/Rank';
import Tooltip from '@components/Tooltip';

import { AvatarWrapper, ClassificationWrapper, ContactName, Wrapper } from './styles';
import { LinkedContact } from '@/shared/types/linkedContact';


export const BackButtonContent = observer(() => {
  const { currentContact, contactName } = useStore().ContactDetailsStore;

  const avatarData = useMemo(() => {
    return currentContact ? getAvatarData(currentContact as LinkedContact) : null;
  },[currentContact]);

  if(!currentContact){
    return null;
  }

  return (
    <Wrapper>
      <AvatarWrapper>
        {
          avatarData &&
          <Avatar
            $oneByOneType={ currentContact.type === CONTACT_TYPES.HOUSEHOLD }
            mainImageData={ avatarData.mainImageData }
            subImageData={ avatarData?.subImageData }
          />
        }
      </AvatarWrapper>
      <Tooltip displayOnlyOnOverflow title={ contactName } >
        <ContactName>{contactName}</ContactName>
      </Tooltip>
      <ClassificationWrapper>
        <CoreClassificationIcon classificationType={ currentContact.classification }/>
      </ClassificationWrapper>
      {
        currentContact?.rating > 0 &&
        <Rank
          drawSingle={ true }
          initRank={ currentContact.rating }
          resettable={ false }
        />
      }
    </Wrapper>
  );
});

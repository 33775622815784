import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const RemoveButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const TypeFilterWrapper = styled.div`
  margin-left: 10px;
  width: 240px;
`;

import { getTimeStampInMs } from '@/shared/utils/getTimeStampInMs';

import { FieldsNames } from './types';

import {
  CheckAndSetTypeProps,
  GetPathAndDataFunctionsProps,
  ProcessCurrentFieldWithAnotherType,
  ProcessCurrentFieldWithInitialType
} from './types';

import { BalanceHistoryItem } from '@modules/AccountsAndPlansPopup/types';

import { BALANCE_HISTORY_ITEM_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';
import {
  BALANCE_HISTORY_TYPES
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

export const getFieldsNames = (fieldPath: string): FieldsNames => ({
  valueFieldName: `${fieldPath}.${BALANCE_HISTORY_ITEM_NAMES.value}`,
  sortFieldName: `${fieldPath}.${BALANCE_HISTORY_ITEM_NAMES.sort}`,
  newBusinessAmountFieldName: `${fieldPath}.${BALANCE_HISTORY_ITEM_NAMES.newBusinessAmount}`,
  typeFieldName: `${fieldPath}.${BALANCE_HISTORY_ITEM_NAMES.type}`,
  updatedFieldName: `${fieldPath}.${BALANCE_HISTORY_ITEM_NAMES.updated}`,
});

export const getPathAndDataForNewInitial = ({
  getSiblingPath,
  itemToConvert,
  originalIndex,
}: GetPathAndDataFunctionsProps): [string, BalanceHistoryItem] => {
  const path = getSiblingPath(originalIndex);

  let convertedItem: BalanceHistoryItem = {
    ...itemToConvert,
    type: BALANCE_HISTORY_TYPES.initialValue
  };

  if(itemToConvert.type === BALANCE_HISTORY_TYPES.newBusiness){
    delete convertedItem.newBusinessAmount;
  }

  return [path, convertedItem];
};

export const getPathAndDataForUpdated = ({
  getSiblingPath,
  itemToConvert,
  originalIndex,
}: GetPathAndDataFunctionsProps): [string, BalanceHistoryItem] => {
  const path = getSiblingPath(originalIndex);
  let convertedItem: BalanceHistoryItem = {
    ...itemToConvert,
    type: BALANCE_HISTORY_TYPES.updatedValue
  };

  return [path, convertedItem];
};

const processCurrentFieldWithInitialType = async ({
  field,
  fields,
  getSiblingPath,
  index,
  originalIndex,
  setValue,
  sortFieldName,
  timeStamp,
}: ProcessCurrentFieldWithInitialType) => {
  if(!fields) {
    return;
  }
  const isSortDecreaseOrStay = timeStamp <= field.sort;

  if(isSortDecreaseOrStay) {
    await setValue(sortFieldName, timeStamp);
  }else {
    const prevField =  fields[index - 1];
    const isCurrentSortLessThenPrevFieldSort = timeStamp <= prevField.data.sort;

    if(isCurrentSortLessThenPrevFieldSort){
      await setValue(sortFieldName, timeStamp);
    } else {
      // swap prev field with current initial type
      const [updatePath, updatedData] = getPathAndDataForUpdated({
        getSiblingPath,
        itemToConvert: {
          ...field,
          sort: timeStamp,
        },
        originalIndex
      });

      const [newInitialPath, newInitialData] = getPathAndDataForNewInitial({
        getSiblingPath,
        itemToConvert: prevField.data,
        originalIndex: prevField.originalIndex,
      });

      await setValue(updatePath, updatedData);
      await setValue(newInitialPath, newInitialData);
    }
  }
};

const processCurrentFieldWithAnotherType =  async ({
  field,
  fields,
  getSiblingPath,
  originalIndex,
  setValue,
  sortFieldName,
  timeStamp,
}:ProcessCurrentFieldWithAnotherType) => {
  const fieldWithInitType = fields && fields.find((field) => field.data.type === BALANCE_HISTORY_TYPES.initialValue);
  if(!fields || !fieldWithInitType) {
    return;
  }
  const {
    data: fieldWithInitTypeData,
    originalIndex: originalIndexOfFieldWithInitType
  } = fieldWithInitType;

  const isCurrentFieldSortMoreThanFieldWithInitValue = timeStamp >= fieldWithInitTypeData.sort;

  if(isCurrentFieldSortMoreThanFieldWithInitValue){
    await setValue(sortFieldName, timeStamp);
  } else {
    // swap prev initial with current field
    const [updatePath, updatedData] = getPathAndDataForUpdated({
      getSiblingPath,
      itemToConvert: fieldWithInitTypeData,
      originalIndex: originalIndexOfFieldWithInitType,
    });

    const [newInitialPath, newInitialData] = getPathAndDataForNewInitial({
      getSiblingPath,
      itemToConvert: {
        ...field,
        sort: timeStamp,
      },
      originalIndex
    });

    await setValue(updatePath, updatedData);
    await setValue(newInitialPath, newInitialData);
  }
};

export const checkAndSetTypeAndSort = async ({
  field,
  fields,
  getSiblingPath,
  index,
  originalIndex,
  saveCallback,
  setValue,
  sortFieldName,
}: CheckAndSetTypeProps) => {
  const timeStamp = getTimeStampInMs(field.updated);

  if(timeStamp && fields && fields?.length === 1) {
    await setValue(sortFieldName, timeStamp);
  }

  if(timeStamp && fields && fields?.length > 1) {
    const isCurrentFieldWithInitialType = field.type === BALANCE_HISTORY_TYPES.initialValue;

    if(isCurrentFieldWithInitialType){
      await processCurrentFieldWithInitialType({
        fields,
        originalIndex,
        getSiblingPath,
        index,
        sortFieldName,
        setValue,
        field,
        timeStamp,
      });
    } else {
      await processCurrentFieldWithAnotherType({
        fields,
        originalIndex,
        getSiblingPath,
        sortFieldName,
        setValue,
        field,
        timeStamp,
      });
    }
  }

  await saveCallback();
};

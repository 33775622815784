import React, { useCallback, useMemo } from 'react';

import { RemoveContactsPopupProps } from './types';

import ConfirmationPopup from '@modules/ConfirmationPopup';

export const RemoveContactsPopup = ({
  closeModal,
  modalProps
}: RemoveContactsPopupProps) => {

  const onSaveClick = useCallback(() => {
    closeModal();

    if('contactsIds' in modalProps) {
      modalProps.onSave(modalProps.contactsIds);
    }

    if('type' in modalProps){
      modalProps.onSave([modalProps.type]);
    }
  }, [closeModal, modalProps]);

  const message = useMemo(() => {
    const numberOfContactsIds = 'contactsIds' in modalProps ? modalProps.contactsIds.length : 0;
    const numberOfContactsCount =  'contactsCount' in modalProps ? modalProps.contactsCount : 0;

    const numberOfDeleteItems = numberOfContactsIds || numberOfContactsCount;

    return (
      `Are you sure you want to remove 
        ${numberOfDeleteItems > 1 ? numberOfDeleteItems : ''}
        ${numberOfDeleteItems > 1 ? 'contacts' : 'contact'}
        from this list ?
        `
    );
  }, [modalProps]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onSaveClick }
    />
  );
};

import React from 'react';
import { useController } from 'react-hook-form';

import { CustomRadioGroup } from '@components/CustomRadio';

import { HFRadioGroupProps } from './types';


export const HFRadioGroup = ({
  ControllerProps = {},
  control,
  disabled,
  name,
  options,
  wrapperProps
}: HFRadioGroupProps) => {

  const {
    field: {
      value,
      onChange
    },
  } = useController({
    name,
    control,
    ...ControllerProps
  });

  return (
    <CustomRadioGroup
      disabled={ disabled }
      onChange={ onChange }
      options={ options }
      value={ value }
      wrapperProps={ wrapperProps }
    />
  );
};

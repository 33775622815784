import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { PreloaderProps } from './types';

import { FullPageWrapper, Wrapper } from './styles';

export const Preloader = ({
  isWithoutFullPageWrapper = false,
  wrapperProps
}: PreloaderProps) => {

  if(isWithoutFullPageWrapper){
    return (
      <Wrapper { ...wrapperProps }>
        <CircularProgress
          color="primary"
          size="46px"
        />
      </Wrapper>
    );
  }

  return (
    <FullPageWrapper>
      <Wrapper { ...wrapperProps }>
        <CircularProgress
          color="primary"
          size="46px"
        />
      </Wrapper>
    </FullPageWrapper>
  );

};

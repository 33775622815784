import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { modalFooterPortalId } from '@/modules/Modals/components/Modal/ModalWrapper/data';


const el = document.createElement('div');

const Portal = ({ children }) => {
  useEffect(() => {
    const mount = document.getElementById(modalFooterPortalId);
    if (mount) {
      mount.appendChild(el);
    }
    return () => {
      if (mount) {
        mount.removeChild(el);
      }
    };
  }, []);

  return createPortal(children, el);
};

export default Portal;

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { WrapperProps } from '@modules/NotesAndHistory/types';


const COMMON_STYLES = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const OnlyNotesWrapper = styled.div<WrapperProps>`
  ${COMMON_STYLES};
  
  overflow-y: auto;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-left: 1px solid ${COLORS.greyLight};
  
  ${({ $maxHeight }) => $maxHeight && css`
    max-height: ${ $maxHeight };
  `}
`;

export const Wrapper =  styled.div<WrapperProps>`
  ${COMMON_STYLES};
  
  ${({ $maxHeight }) => $maxHeight && css`
    max-height: ${ $maxHeight };
  `}
`;

export const ContentWrapper =  styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid ${COLORS.greyLight};
  border-top: 1px solid ${COLORS.greyLight};
  background-color: ${COLORS.white};
  box-sizing: border-box;
  overflow: hidden;
`;


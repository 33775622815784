import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';


import { SourceProps } from './types';

import {
  LeftColumnWrapper,
  RightColumnWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

export const Source = ({
  sourceCategoryName,
  sourceCategoryOptions,
  sourceName,
  sourceOptions,
}: SourceProps) => {
  const { control } = useFormContext();
  
  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Source' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField 
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Source' }
          >
            <HFSelect
              control={ control }
              name={ sourceName }
              options={ sourceOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField 
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Source Category' }
          >
            <HFSelect
              control={ control }
              name={ sourceCategoryName }
              options={ sourceCategoryOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

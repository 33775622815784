import React, { CSSProperties, useCallback } from 'react';
import { observer } from 'mobx-react';

import ContentCenter from '@components/ContentCenter';
import CustomFieldsAndTagsContent from '@modules/UserSettings/CustomFieldsAndTagsContent';
import NoDataItemsView from '@modules/NoDataItemsView';
import TagButton, { TAG_BUTTON_TYPES } from '@components/TagButton';

import { Filters } from './components';

import { TagColumnProps } from './types';
import { CustomFieldTagItem } from '@/shared/types/tags';

import { MAX_LENGTH_OF_TAG, TAGS_FRONT_ID_KEY } from '@constants/userSettingsUserCustomFields';
import { validationMessages } from '@constants/validationMessages';

const TAG_BUTTON_STYLE: CSSProperties = {
  maxWidth: '250px',
  minWidth: '50px'
};


export const TagColumn = observer(({
  title,
  store,
  isLoad
}: TagColumnProps) => {
  const {
    editIndex,
    onAdd,
    onDelete,
    onEditEnd,
    onEditStart,
    onSave,
    tags,
    setContainerRef,
    errorMessage,
    setErrorMessage,
    setFilters
  } = store;

  const validator = useCallback((value:string) => {
    if(value.length === 0){
      setErrorMessage(validationMessages.invalid);
      return false;
    }

    return true;
  },[setErrorMessage]);

  const isEditIndex = typeof  editIndex === 'number';

  return (
    <CustomFieldsAndTagsContent
      wrapperProps={ {
        width: 400,
      } }
    >
      <CustomFieldsAndTagsContent.Header title={ title }/>
      <CustomFieldsAndTagsContent.Row marginBottom={ '0px' }>
        <Filters
          disabled={ isEditIndex }
          onFiltersChange={ setFilters }
        />
      </CustomFieldsAndTagsContent.Row>
      <CustomFieldsAndTagsContent.ActionRow
        buttonDisabled={ isEditIndex }
        onClick={ onAdd }
      />
      <CustomFieldsAndTagsContent.Content
        $disableScroll={ isEditIndex }
        position={ 'relative' }
        ref={ setContainerRef as any }
      >
        {
          tags.map((tag, index) => (
            <TagButton<CustomFieldTagItem>
              $isDynamicInputWidth={ true }
              $showError={ true }
              disabled={ isEditIndex && editIndex !== index }
              errorMessage={ editIndex === index ? errorMessage : null }
              initEditState={ editIndex === index }
              inputStyle={ TAG_BUTTON_STYLE }
              isNotEditable={ false }
              key={ TAGS_FRONT_ID_KEY in tag ? tag[TAGS_FRONT_ID_KEY] : tag.id }
              maxLength={ MAX_LENGTH_OF_TAG }
              onDeleteCb={ onDelete }
              onEditEnd={ onEditEnd }
              onEditStart={ onEditStart }
              onSaveCb={ onSave }
              tagData={ tag as CustomFieldTagItem }
              type={ TAG_BUTTON_TYPES.edit }
              validator={ validator }
            />
          ))
        }
        {
          !isLoad && tags.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              $imageWidth={ '350px' }
              $infoTextFontSize={ '18px' }
              markedText={ 'Tags' }
            />
          </ContentCenter>
        }
      </CustomFieldsAndTagsContent.Content>
    </CustomFieldsAndTagsContent>
  );
});

import React from 'react';

import BlockButton from '@components/BlockButton';

import { Header } from './styles';

import { ComponentHeaderProps } from './types';

export const ComponentHeader = ({
  children,
  isShowViewAllButton = true,
  onViewAllClick,
  title,
}: ComponentHeaderProps) => {
  return (
    <Header>
      { title }
      { isShowViewAllButton &&
        <BlockButton
          $isNoneFill={ true }
          onClick={ onViewAllClick }
          style={ {
            padding: 0,
            minWidth: 50,
            marginLeft: 'auto'
          } }
        >
          View All
        </BlockButton>
      }
      { children }
    </Header>
  );
};

import { CONTACT_TYPE } from '@modules/AddContactModal/data';
import { TAGS_TYPES } from '@constants/common';

import { optionsFromValue } from '@/shared/utils/optionsFromValue';

export const CONTACT_TYPES_OPTIONS =  optionsFromValue(Object.values(CONTACT_TYPE));

export const defaultStepData = {
  [CONTACT_TYPE.Individual]: [
    ['Basic', 'Information'],
    ['Contact', 'Information'],
    ['Spouse', 'Information'],
    ['Tags']
  ],
  [CONTACT_TYPE.Household]: [
    ['Household', 'Information'],
    ['Contact', 'Information'],
    ['Add Members'],
    ['Tags']
  ],
  [CONTACT_TYPE.Business]: [
    ['Business', 'Information'],
    ['Contact', 'Information'],
    ['Point-Of-Contact'],
    ['Tags']
  ]
};

export const tagsTypes = {
  general: TAGS_TYPES.general,
  interest: TAGS_TYPES.interest,
  new: 'New'
};

export const initialType = CONTACT_TYPE.Individual;

export const initialStep = 1;

export const CONTACT_OPTIONS_URL = {
  individual: 'getIndividualOptions',
  household: 'getContactHouseholdOptions',
  business: 'getContactBusinessOptions'
};

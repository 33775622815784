import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getContactsForSpouse } from '@services/api/contacts/contacts';
import { getSearchParamsWithName, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';

import { SpouseTypeField, HeadOfHouseHoldField } from '..';

import { LeftWrapperWide } from '../../styles';

import {
  ADD_SPOUSE_FIELD_NAMES,
  ADD_SPOUSE_TYPES,
  LEFT_LABEL_WIDTH_WIDE
} from '../../data';

import { ExistedContactProps } from './types';

export const ExistedContact = ({
  contactId,
  currentContact,
  existedContact,
  onExistedContactChange,
}: ExistedContactProps) => {
  const { control } = useFormContext();

  const spouseType = useWatch({
    control,
    name: ADD_SPOUSE_FIELD_NAMES.spouseType
  });

  const LinkedContactFetchProps = useMemo(() => ({
    delay: 500,
    getParams: getSearchParamsWithName({ id: contactId }),
    requestSender: getContactsForSpouse,
    responseConverter: usersResponseConverter,
  }),[contactId]);

  return (
    <>
      <FormBlock.Header title={ 'Link Existing Contact as Spouse' } />
      <FormBlock.RowWrapper>
        <LeftWrapperWide>
          <SpouseTypeField labelWidth={ LEFT_LABEL_WIDTH_WIDE } />
        </LeftWrapperWide>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_LABEL_WIDTH_WIDE }
          childrenWrapperProps={ {
            marginRight: '20px'
          } }
          isRequired={ true }
          label={ 'Select Contact' }
        >
          <HFLinkedContact
            FetchProps={ LinkedContactFetchProps }
            control={ control }
            initLinkedContact={ null }
            name={ ADD_SPOUSE_FIELD_NAMES.spouseId }
            onLinkedContactChange={ onExistedContactChange }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      {
        spouseType === ADD_SPOUSE_TYPES.combineHousehold &&
        <FormBlock.RowWrapper>
          <LeftWrapperWide>
            <HeadOfHouseHoldField
              contactId={ contactId }
              currentContact={ currentContact }
              existedContact={ existedContact }
              labelWidth={ LEFT_LABEL_WIDTH_WIDE }
            />
          </LeftWrapperWide>
        </FormBlock.RowWrapper>
      }
    </>
  );
};

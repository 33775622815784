import React, { useCallback, useMemo } from 'react';

import Avatar from '@components/Avatar';

import { AvatarLineProps } from './types';

import { CountWrapper, ItemWrapper, Wrapper } from './styles';
import { COLORS } from '@constants/colors';

export const AvatarLine = ({
  count,
  onClick,
  items,
  isHideCounter = false
}: AvatarLineProps) => {

  const onClickHandler = useCallback(() => {
    if(onClick){
      onClick();
    }
  },[onClick]);

  return (
    <Wrapper onClick={ onClickHandler }>
      {
        !isHideCounter && typeof count === 'number' &&
        <CountWrapper
          $count={ count }
          $zIndex={ items.length + 1 }
        >
          <Avatar
            $color={ COLORS.primaryText }
            $withFrame={ true }
            mainImageData={ {
              abbr: count.toFixed()
            } }
          />
        </CountWrapper>
      }
      {
        items.map((item, index) => (
          <ItemWrapper
            $withoutNegativeMargin={ isHideCounter && index === 0 }
            $zIndex={ items.length - index }
            key={ index + item.mainImageData.abbr }
          >
            <Avatar
              $subType={ Boolean(item.subImageData) }
              $withFrame={ true }
              mainImageData={ item.mainImageData }
              subImageData={ item.subImageData }
            />
          </ItemWrapper>
        ))
      }
    </Wrapper>
  );
};

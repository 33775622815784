import styled from 'styled-components';

export const InnerWrapper = styled.div`
  padding: 0 15px 15px 15px;
`;

export const DatePickerWrapper = styled.div`
  & .MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: auto;
  }

  & .MuiPickersBasePicker-pickerView{
    max-width: 267px;
    min-width: auto;
    min-height: 235px;
  }

  & .MuiPickersCalendar-transitionContainer {
    min-height: 164px;
  }

  & .MuiPickersCalendarHeader-daysHeader > span {
    width: 26px;
    margin: 0 3px;
  }

  & .MuiButtonBase-root {
    width: 26px;
    height: 26px;
  }

  & .MuiPickersDay-day {
    margin: 0 3px;
  }
`;

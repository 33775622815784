import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  LeftColumnWrapper,
  RightColumnWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { KeyInformationProps } from './types';

export const KeyInformation = ({
  birthplaceName,
  citizenshipCountryName,
  countriesOptions,
  disableSsn,
  dlExpirationDateName,
  dlNumberName,
  dlStateName,
  ethnicityName,
  ethnicityOptions,
  maritalStatusName,
  maritalStatusOptions,
  ssnName,
  stateOptions,
  taxIdNumberName,
}: KeyInformationProps) => {
  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Personal Information' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Birth Place' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Birth Place',
                maxLength: 50,
              } }
              name={ birthplaceName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Marital Status' }
          >
            <HFSelect
              control={ control }
              name={ maritalStatusName }
              options={ maritalStatusOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'D.L. Number' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter D.L Number',
                maxLength: 50
              } }
              name={ dlNumberName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'D.L. State' }
          >
            <HFSelect
              control={ control }
              name={ dlStateName }
              options={ stateOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'D.L. Expiration Date' }
          >
            <HFDatePicker
              control={ control }
              name={ dlExpirationDateName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Citizenship Country' }
          >
            <HFSelect
              control={ control }
              name={ citizenshipCountryName }
              options={ countriesOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Ethnicity' }
          >
            <HFSelect
              control={ control }
              name={ ethnicityName }
              options={ ethnicityOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Tax ID Number' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Tax ID Number',
                maxLength: 50,
              } }
              name={ taxIdNumberName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Social Security Number' }
          >
            <HFTextInput
              control={ control }
              disabled={ disableSsn }
              inputProps={ {
                placeholder: 'Enter Social Security Number ',
                maxLength: 50,
              } }
              name={ ssnName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper></RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

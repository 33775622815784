import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import { WrapperProps } from './types';

const BORDERS_COLOR = COLORS.greyLight;
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 268px);
  min-width: 245px;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  
  border-radius: 8px;
  border: 1px solid ${BORDERS_COLOR};
  
  background-color: ${COLORS.background};
  
  ${({ $isOver }) => $isOver && css`
    background-color: ${COLORS.select};
  `}
`;

export const Header = styled.div`
  padding: 16px 0;

  font-size: 16px;
  color: ${COLORS.header};
  text-align: center;

  border-bottom: 1px solid ${BORDERS_COLOR}
`;

export const Items = styled.div`
  overflow-y: auto;
  padding: 10px 5px 10px 10px;
  
  & > * {
    margin-bottom: 20px;
  }
  
  &:last-child{
    margin-bottom: 0;
  }
`;


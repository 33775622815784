import React from 'react';

import { ChevronLeft } from '@/assets';

import BlockButton from '@components/BlockButton';

import { AppBackButtonProps } from './types';
import { ButtonTitleWrapper, Wrapper } from './styles';

export const AppBackButton = ({
  children,
  onClick,
  title = 'Back',
}: AppBackButtonProps) => {

  return (
    <Wrapper>
      <BlockButton
        onClick={ onClick }
        startIcon={ <ChevronLeft /> }
      >
        <ButtonTitleWrapper>
          {title}
        </ButtonTitleWrapper>
      </BlockButton>
      {children}
    </Wrapper>

  );
};
import { Edit, Trash } from '@/assets';

import { TemplatesType } from '@components/DotsMenu/types';

import { DOTS_MENU_COMMON_COLORS, DOTS_MENU_DELETE_COLORS } from '@components/DotsMenu';

import { SalesClientOpportunityItemDotsMenuTypes } from './types';

export const CLIENT_OPPORTUNITY_DOTS_MENU_TYPES = {
  remove: 'REMOVE',
  edit: 'EDIT',
} as const ;

export const CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS_CONFIG: TemplatesType<SalesClientOpportunityItemDotsMenuTypes> = {
  [CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.edit]: {
    icon: Edit,
    label: 'Edit',
    type: CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.edit,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.remove]: {
    type: CLIENT_OPPORTUNITY_DOTS_MENU_TYPES.remove,
    icon: Trash,
    label: 'Delete',
    topSeparator: true,
    ...DOTS_MENU_DELETE_COLORS,
  },
};

export const CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS = [
  CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS_CONFIG.EDIT,
  CLIENT_OPPORTUNITY_DOTS_MENU_OPTIONS_CONFIG.REMOVE
];

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useActivityPopupLocalStore } from '@/modules/ActivityPopup/store';

import { ActivityPopupFieldsName, LABEL_WIDTH } from '@/modules/ActivityPopup/data';
import { getDateFromCurrWithOneWeek } from '@/modules/ActivityPopup/utils';
import { getRecurringOptions } from '@/shared/utils/getRecurringOptions';

import { TimeButtons } from './components';
import FormBlock from '@components/FormBlock';
import HFCheckbox from '@/modules/HookFormComponents/HFCheckbox';
import HFDatePicker from '@/modules/HookFormComponents/HFDatePicker';
import HFSelect from '@/modules/HookFormComponents/HFSelect';
import TimePicker from '@components/BasicFormComponents/TimePicker';

import {
  AllDayAndRecurringWrapper,
  DatePickerWrapper,
  Divider,
  EndDateWrapper,
  InnerRow,
  RecurringFrequencyWrapper,
  TimeButtonsWrapper,
  TimePickerWrapper
} from './styles';

import { DateAndTimeProps } from './types';

const SELECT_PROPS = {
  style: {
    width: 245
  }
};

export const DateAndTime = observer(({
  editMode,
}: DateAndTimeProps) => {

  const { control, getValues } = useFormContext();

  const { activityCreationSettings: { recurringFrequency } } = useActivityPopupLocalStore();

  const [ allDay, recurring, startDate ] = useWatch({
    control,
    name: [
      ActivityPopupFieldsName.allDay,
      ActivityPopupFieldsName.recurring,
      ActivityPopupFieldsName.startDate
    ]
  });

  const isRecurringDisable = useMemo(() => editMode, [ editMode ]);

  const recurringOptionsBasedOnDate = useMemo(() => {
    return getRecurringOptions(startDate, recurringFrequency);
  }, [ startDate, recurringFrequency ]);

  const recurringEndDateInitValue = useMemo(() => {
    if(recurring) {
      const { startDate, recurringEndDate } = getValues();
      if(startDate && !recurringEndDate){
        return getDateFromCurrWithOneWeek(startDate);
      }
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurring]);

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABEL_WIDTH.common }
          label={ 'Date / Time' }
        >
          <InnerRow>
            <DatePickerWrapper>
              <HFDatePicker
                control={ control }
                name={ ActivityPopupFieldsName.startDate }
              />
            </DatePickerWrapper>

            {
              !allDay &&
                <TimePickerWrapper>
                  {/* @ts-ignore TS migrate*/}
                  <TimePicker
                    $width={ 'auto' }
                    control={ control }
                    isRequired={ true }
                    name={ ActivityPopupFieldsName.startTime }
                  />
                </TimePickerWrapper>
            }

            <Divider> to </Divider>

            <DatePickerWrapper>
              <HFDatePicker
                control={ control }
                name={ ActivityPopupFieldsName.endDate }
              />
            </DatePickerWrapper>

            {
              !allDay &&
                <TimePickerWrapper>
                  {/* @ts-ignore TS migrate*/}
                  <TimePicker
                    $width={ 'auto' }
                    control={ control }
                    isRequired={ true }
                    name={ ActivityPopupFieldsName.endTime }
                  />
                </TimePickerWrapper>
            }
          </InnerRow>

          <InnerRow>
            <AllDayAndRecurringWrapper>
              <HFCheckbox
                control={ control }
                disabled={ isRecurringDisable }
                label={ 'All Day' }
                name={ ActivityPopupFieldsName.allDay }
              />
            </AllDayAndRecurringWrapper>
            <AllDayAndRecurringWrapper>
              <HFCheckbox
                control={ control }
                disabled={ isRecurringDisable }
                label={ 'Recurring' }
                name={ ActivityPopupFieldsName.recurring }
              />
            </AllDayAndRecurringWrapper>
            {
              !allDay &&
              <TimeButtonsWrapper>
                <TimeButtons/>
              </TimeButtonsWrapper>
            }
          </InnerRow>

          {
            recurring &&
            <InnerRow>
              <RecurringFrequencyWrapper>
                <HFSelect
                  SelectProps={ SELECT_PROPS }
                  control={ control }
                  disabled={ isRecurringDisable }
                  name={ ActivityPopupFieldsName.recurringFrequency }
                  options={ recurringOptionsBasedOnDate }
                  placeholder={ 'Please select' }
                />
              </RecurringFrequencyWrapper>

              <EndDateWrapper>
                <FormBlock.FormField
                  $labelWidth={ 106 }
                  label={ 'End Date' }
                >
                  <HFDatePicker
                    ControllerProps={ {
                      defaultValue: recurringEndDateInitValue
                    } }
                    control={ control }
                    disabled={ isRecurringDisable }
                    name={ ActivityPopupFieldsName.recurringEndDate }
                  />
                </FormBlock.FormField>
              </EndDateWrapper>
            </InnerRow>
          }
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';

import { MuiBoxStyledProps } from './types';

export const Wrapper = styled(MuiBox)<MuiBoxStyledProps>`
  width: ${ ({ width }) => width || '100%'};
  display: ${ ({ display }) => display || 'flex' };
  justify-content: ${ ({ justifyContent }) => justifyContent || 'space-between' };
  gap: ${ ({ gap }) => gap || '0px' };
`;

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:12px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    background-color: ${ COLORS.background };
    color: ${ COLORS.header };
    border-bottom: 1px solid ${ COLORS.borders };
`;

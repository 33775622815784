import React, { useCallback, useEffect } from 'react';

import { useStore } from '@store';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';

import { getTypeNameOfCurrentView } from '@pages/Trash/utils';
import { PopupProps } from '@pages/Trash/types';


export const RestorePopup = ({
  closeModal,
  modalProps: {
    arrayOfIds
  }
}: PopupProps) => {
  const trashStore = useStore().TrashStore;

  const { table, currentTrashView } = trashStore;

  const getConfirmationMessage = () => {
    const selectedLenght = arrayOfIds?.length || table.selectedIDs.length;
    const { one, many } = getTypeNameOfCurrentView(currentTrashView);
    return (
      `${selectedLenght} deleted ${selectedLenght > 1 ? many : one} 
      will be restored.`
    );
  };

  const onConfirmClick = useCallback(() => {
    closeModal();
    trashStore.restoreItems(arrayOfIds);
  }, [ arrayOfIds, closeModal, trashStore ]);

  useEffect(() => {
    const timerForClose = setTimeout(() => {
      closeModal();
    }, 5000);
    return () => clearTimeout(timerForClose);
  }, [ closeModal ]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ getConfirmationMessage() }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
      type={ POPUP_TYPES.CONFIRM }
    />
  );
};

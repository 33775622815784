import { Store } from '@store';
import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { UseFormReset } from 'react-hook-form';
import debounce from 'lodash/debounce';

import CommonTableStore from '@services/store/commonTableStore';
import { OPPORTUNITY_FIELD_NAMES, OPPORTUNITY_FILTER_NAMES } from '@constants/salesCycleOpportunity';

import { CommonStore } from '../../commonStore';
import { CloseModal } from '@/shared/types/commonTypes';

import {
  ClientOpportunitiesStageFilterState,
  ClientOpportunityBackendResponse,
  ClientOpportunityFilterState,
  ClientOpportunityGridItem,
  ClientOpportunityRemoveData,
  ClientOpportunityRowFormState,
  ConvertedClientOpportunityFilterDataType,
  ClientOpportunityStageType,
  ClientOpportunityFormFields
} from '@/shared/types/salesCycleOpportunity';

import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';

import { INIT_FILTER_DATA, INIT_FILTERS_STATE } from './data';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';

import {
  deleteClientOpportunity,
  getClientOpportunitiesTable,
  partialUpdateClientOpportunity,
  saveClientOpportunity,
  updateClientOpportunitiesFlags
} from '@services/api/salesPipeline/clientOpportunities';

import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';
import { getFilterParams, getFiltersCount, getGlobalFlagged, getMultipleSortParams } from '@/shared/utils/filterUtils';
import { convertFilterData } from './utils';
import { filterItemsByPermission } from '@/shared/utils/filterItemsByPermission';

export class SalesClientOpportunitiesTable implements CommonStore{
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  filters: ClientOpportunityFilterState = INIT_FILTERS_STATE;
  filterData: ConvertedClientOpportunityFilterDataType = INIT_FILTER_DATA;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onFiltersChangeReaction: IReactionDisposer;
  onTypeFilterReaction: IReactionDisposer;
  stageFilter: ClientOpportunitiesStageFilterState = null;
  table: CommonTableStore<ClientOpportunityGridItem>;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getClientOpportunity: flow.bound,
      getClientOpportunityWithLoad: flow.bound,
      init: flow.bound,
      onRemove: flow.bound,
      onRowChange: flow.bound,
      onSave: flow.bound,
      resetStore: action.bound,
      setFilterState: action.bound,
      setStageFilter: action.bound,
      updateFlagsSingle: flow.bound,
      updateFlagsMassive: flow.bound,
    });
    this.coreStore = coreStore;

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.clientOpportunity
    );

    this.table = new CommonTableStore<ClientOpportunityGridItem>({
      checkboxItemsProcessor: filterItemsByPermission,
      onGlobalFlaggedChangeReactionCallback: this.getClientOpportunityWithLoad,
      onSortReactionCallback: this.getClientOpportunityWithLoad,
      onPageChangeReactionCallback:  this.getClientOpportunityWithLoad
    });

    this.onTypeFilterReaction = this.createOnTypeFilterReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive){
          this.table.selectedIDs = [];
          this.table.setCurrentPageWithoutReaction(1);
          this.getClientOpportunityWithLoad();
        }
      }, 1500),
    );
  }

  createOnTypeFilterReaction() {
    return reaction(
      () => this.stageFilter,
      () => {
        if (this.isPageActive) {
          this.table.selectedIDs = [];
          this.table.setCurrentPageWithoutReaction(1);
          this.getClientOpportunityWithLoad();
        }
      }
    );
  }

  *getClientOpportunity(){
    if(this.isPageActive){
      try {
        this.table.clearItems(true);
        const start = async () => {
          const response: ClientOpportunityBackendResponse = await getClientOpportunitiesTable({
            page: this.table.currentPage,
            ...getFilterParams(this.filters),
            ...getFilterParams(this.stageFilter),
            ...getMultipleSortParams(this.table.multipleSorting),
            ...getGlobalFlagged(this.table.globalFlagged),
          });

          this.table.items = convertObjectWithNumericKeysToArray<ClientOpportunityGridItem>(
            response.data.data.data
          );
          this.table.setPaginationData(response.data.data);
          this.table.checkAndSetIfPageOutOfRange();

          const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
          this.filterData = convertFilterData(response.data.data.data.filterData, currentUserId);
        };

        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: start,
          onError: () => this.notificationHelper.load({
            status: NOTIFICATION_TYPES.error,
            isAutoUniqueKey: true
          }),
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      }
    }
  }

  *getClientOpportunityWithLoad(){
    if(this.isPageActive){
      try {
        this.coreStore.SalesCycleStore.isLoad = true;
        yield this.getClientOpportunity();
      } catch (error) {
        console.log(error);
      } finally {
        this.coreStore.SalesCycleStore.isLoad = false;
      }
    }
  }

  *init() {
    this.isPageActive = true;
    yield this.getClientOpportunityWithLoad();
  }

  get filtersCount () {
    return getFiltersCount(this.filters);
  }

  get isAllItemsFlagged ()  {
    return this.table.items.length > 0 && this.table.items.every(item => Boolean(item?.flagged));
  }

  *onSave(closeModal: CloseModal, data: ClientOpportunityFormFields){
    try {
      closeModal();
      this.coreStore.SalesCycleStore.isLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveClientOpportunity(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: true,
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: false,
        })
      });

      yield this.getClientOpportunity();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onRemove(data: ClientOpportunityRemoveData){
    try {
      this.coreStore.SalesCycleStore.isLoad = true;
      const idsToDelete = Array.isArray(data) ? data : [data];
      const countOfEntities = idsToDelete.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => deleteClientOpportunity({
          ids: idsToDelete
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
          isAutoUniqueKey: true
        })
      });

      yield this.getClientOpportunityWithLoad();

      yield this.table.checkAndSetIfPageOutOfRange();
      yield this.table.refreshSelectedIds(idsToDelete);
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onRowChange(data: ClientOpportunityRowFormState, rowReset: UseFormReset<ClientOpportunityRowFormState>){
    const isSortedOrFiltered = this.filtersCount > 0 ||
      Object.keys(this.table.multipleSorting).length > 0 || this.stageFilter;
    const { item: itemPrevState } = this.table.getItemAndItemIndex(data.id);

    try {
      const wrappedUpdate = () => (
        this.asyncRequestExecutor.wrapAsyncOperation({
          func: async () => partialUpdateClientOpportunity(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isUpdate: true,
            isError: true,
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isUpdate: true,
            isError: false,
          })
        })
      );

      if(!isSortedOrFiltered){
        rowReset(data);
        this.table.updateItemById(data.id, data);
        yield wrappedUpdate();
      } else {
        this.coreStore.SalesCycleStore.isLoad = true;
        yield wrappedUpdate();
        yield this.getClientOpportunity();
      }
    } catch (error) {
      if(!isSortedOrFiltered  && itemPrevState){
        rowReset({
          [OPPORTUNITY_FIELD_NAMES.meetingStatus]: itemPrevState.meetingStatus,
          [OPPORTUNITY_FIELD_NAMES.opportunityStatus]: itemPrevState.opportunityStatus,
          [OPPORTUNITY_FIELD_NAMES.saleType]: itemPrevState.saleType,
        });
        this.table.updateItemById(itemPrevState.id, itemPrevState);
      }
    } finally {
      if(isSortedOrFiltered){
        this.coreStore.SalesCycleStore.isLoad = false;
      }
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  resetFilters = () => {
    this.filters = INIT_FILTERS_STATE;
  };

  resetStore() {
    this.isPageActive = false;
    this.isFiltersOpen = false;

    this.stageFilter = null;

    this.table.resetTable();

    this.onFiltersChangeReaction();
    this.resetFilters();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  setFilterState = (name: string, value: Array<string> | string) =>{
    this.filters = {
      ...this.filters,
      [name]: value
    };
  };

  setStageFilter = (value: string | null) => {
    this.stageFilter = value
      ? {
        [OPPORTUNITY_FILTER_NAMES.stage]: value as ClientOpportunityStageType
      }
      : null;
  };


  toggleFiltersIsOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };

  *updateFlagsSingle(id: number) {
    try {
      yield this.table.updateSingleItemFlag(
        id,
        (data) => updateClientOpportunitiesFlags([data])
      );
      if(this.table.globalFlagged) {
        this.getClientOpportunityWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  *updateFlagsMassive() {
    try {
      yield this.table.updateMassiveItemFlag(
        this.isAllItemsFlagged,
        (data) => updateClientOpportunitiesFlags(data)
      );
    } catch (error) {
      console.log(error);
    }
  }
}


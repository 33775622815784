import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { CountWrapperProps, ItemWrapperProps } from './types';

export const CountWrapper = styled.div<CountWrapperProps>`
  ${({ $zIndex }) => $zIndex && css`
    z-index: ${$zIndex};
  `}
  
  & > * > * > * > * {
    ${({ $count }) => {
    const countLength = $count.toFixed().length;
    if(countLength === 3){
      return css` font-size: 12px`;
    }

    if(countLength === 4){
      return css` font-size: 10px`;
    }

    if(countLength === 5){
      return css` font-size: 9px`;
    }
    return '';
  } }
  }
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  ${({ $zIndex }) => $zIndex && css`
    z-index: ${$zIndex};
  `}

  ${({ $withoutNegativeMargin }) =>  !$withoutNegativeMargin && css`
    margin-left: -6px
  `}
  

`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  isolation: isolate;
  
    &:hover ${CountWrapper} {
       & > * > * > * {
        background-color: ${COLORS.orange}; 
       }
      }
    }
`;

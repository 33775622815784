import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { SubcategoryItemProps } from './types';

export const SubcategoryItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  font-size: 12px;
`;

export const SubcategoryItem = styled.div<SubcategoryItemProps>` 
  padding: 9px 12px;
  font-size: 12px;
  cursor: pointer;

  ${({ $isActive }) => $isActive && css`
    padding-left: 10px;
    border-left: 2px solid ${COLORS.orange};
    background-color: ${COLORS.select};
  `};

  ${({ $isBorderBottom }) => $isBorderBottom && css`
    border-bottom: 1px solid ${COLORS.dividingLines}
  `};
`;

import React from 'react';

import Box from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';
import { FooterContentWrapperProps } from '../../types';

export const FooterContentWrapper = ({
  children,
  alertMessage,
  boxProps
}: FooterContentWrapperProps) => {
  if(alertMessage){
    return (
      <Box
        alignItems={ 'center' }
        color={ COLORS.primaryText }
        display={ 'flex' }
        fontSize={ 14 }
        { ...boxProps }
      >
        {alertMessage}
      </Box>
    );
  }

  return (
    <>
      {children || null}
    </>
  );
};

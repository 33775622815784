import styled, { css } from 'styled-components';

import { CounterWrapperProps } from './types';

export const Wrapper = styled.div<CounterWrapperProps>`
  display: grid;
  place-items: center;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 1.5;
  
  ${({ $bgColor }) => $bgColor && css`
      background-color: ${$bgColor};
  `};
  
  ${({ $color }) => $color && css`
      color: ${$color}; 
  `};
`;

import * as yup from 'yup';

import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation } from '@constants/validationPatterns';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

import { ROOT_FIELD_NAMES, FIELD_NAMES } from './data';

export const validationSchema = yup.object().shape({
  [ROOT_FIELD_NAMES.householderContacts]: yup.array().of(yup.object().shape({
    [FIELD_NAMES.firstName]: yup.string()
      .required(validationMessages.required),
    [FIELD_NAMES.lastName]: yup.string()
      .required(validationMessages.required),
    [FIELD_NAMES.officePrimaryAdvisor]: yup.string()
      .nullable()
      .required(validationMessages.required),
    [FIELD_NAMES.birthdate]: yup.string()
      .nullable()
      .matches(dateOutputValidation, validationMessages.invalidDate)
      .test(
        'mast be equal or less than Today',
        validationMessages.invalidDate,
        (value) => (
          isDateBefore({
            dateStringToCompare: getFormatedDateNow(),
            dateString: value,
            withEqual: true,
          })
        )
      )
  }))
});

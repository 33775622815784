import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@services/store/store';
import { useTodoPopupLocalStore } from './store';

import { validationSchema } from './validationScheme';

import {
  Actions,
  BtnPanel,
  Content,
} from './components';
import Modal from '@components/Modal';
import Preloader from '@components/Preloader';

import { Spacer, TopPanelWrapper } from './styles';

import { TaskPopupProps } from './types';
import { TodoFormFields  } from '@/shared/types/todos';

export const TodoPopup = observer(({
  closeModal,
  modalProps: {
    deleteCallback,
    id,
    isLinkedItem,
    initLinkedContact,
    isCloneTask,
    onCloseAction = () => {},
    saveCallback,
    updateCallback
  }
}: TaskPopupProps) => {

  const store = useStore();
  const todoPopupLocalStore = useTodoPopupLocalStore();
  const {
    isFetching,
    notesUtilsStore,
    init,
    id: todoId,
    onCancel
  } = todoPopupLocalStore;

  const title = todoId ? `Task #${todoId} - Edit Task` : 'Add Task';

  const methods = useForm<TodoFormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    init({
      closeModal,
      coreStore: store,
      deleteCallback,
      id,
      isLinkedItem,
      initLinkedContact,
      isCloneTask,
      onCloseAction,
      saveCallback,
      updateCallback,
      useFormMethods: methods,
    });
    // eslint-disable-next-line
  }, []);


  return (
    <FormProvider { ...methods }>
      <Modal
        headerProps={ {
          padding: '22px 25px 22px 20px'
        } }
        onClose={ onCancel }
        title={ isFetching ? '' : title }
      >
        <TopPanelWrapper>
          <Modal.TopPanel
            padding={ '10px 25px 10px 20px' }
          >
            <BtnPanel />
          </Modal.TopPanel>
        </TopPanelWrapper>
        <Modal.Content $disabled={ Boolean(notesUtilsStore.alertType) }>
          {
            isFetching ? (
              <Spacer>
                <Preloader isWithoutFullPageWrapper={ true } />
              </Spacer>
            ) : (
              <Content/>
            )
          }
        </Modal.Content>
        <Modal.Footer
          padding={ '20px 25px 20px 20px' }
        >
          <Actions/>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});

import React, { useCallback, useRef, useState } from 'react';
import { useDrag, useDrop, useDragLayer } from 'react-dnd';

import { DragDots } from '@/assets';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';

import { ListItemProps, DragCollectedProps, DropCollectedProps } from './types';
import { DraggableContactCustomField } from '@/shared/types/userSettingsContactCustomFields';

import { ActionsWrapper, DragIconWrapper, LabelWrapper,  Wrapper } from './styles';

export const ListItem = ({
  index,
  item,
  onDelete,
  onDrop,
  onEdit,
  onMove,
}: ListItemProps) => {
  const onDeleteWrapper = useCallback(() => onDelete(item, index),[index, item, onDelete]);
  const onEditWrapper = useCallback(() => onEdit(item, index),[index, item, onEdit]);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const dragIconContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const ItemWithIndex = {
    ...item,
    index
  };

  const [ { isDragging }, dragRef, refForShadow] = useDrag<
    DraggableContactCustomField,
    unknown,
    DragCollectedProps
  >({
    type: item.field,
    item: ItemWithIndex,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

  });

  const [{ isOver }, dropRef] = useDrop<
    DraggableContactCustomField,
    unknown,
    DropCollectedProps
  >({
    accept: item.field,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (!dragIconContainerRef.current || !containerRef.current) {
        return;
      }
      const dragIndex = item.index;
      if (dragIndex === index) {
        return;
      }

      onMove(item, ItemWithIndex);
      item.index = index;
    },
    drop: (item, monitor) => {
      onDrop(item, ItemWithIndex);
    },
  });

  const isGlobalDrag = useDragLayer(monitor => monitor.isDragging());
  const onMouseEnter = () => {
    if(!isGlobalDrag && !isOver){
      setIsHovered(true);
    }
  };

  const onMouseLeave = () => {
    if(!isGlobalDrag && !isOver){
      setIsHovered(false);
    }
  };

  dragRef(dragIconContainerRef);
  dropRef(refForShadow(containerRef));

  return (
    <Wrapper
      $isDragging={ isDragging }
      $isHovered={ isHovered }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      ref={ containerRef }
    >
      <DragIconWrapper ref={ dragIconContainerRef }>
        <DragDots/>
      </DragIconWrapper>
      <LabelWrapper>
        { item.option }
      </LabelWrapper>
      {
        item.clientId !== null &&
        <ActionsWrapper>
          <CustomIconButton
            onClick={ onEditWrapper }
            type={ ICON_BUTTON_TYPES.edit }
          />
          <CustomIconButton
            onClick={ onDeleteWrapper }
            type={ ICON_BUTTON_TYPES.close }
          />
        </ActionsWrapper>
      }

    </Wrapper>
  );
};

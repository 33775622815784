import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import {
  HOUSEHOLD_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';
import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import {
  LeftColumnWrapper,
  RightColumnWrapper
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/Layout';

import { CONTACT_TYPES } from '@constants/common';
import { getContactName } from '@/shared/utils/getContactName';

export const CommunicationPreferences = observer(() => {
  const { contact, householdMembers } = useStore().ContactDetailsProfileStore;
  const { control } = useFormContext();

  const options = useMemo(() => {
    if(!contact || contact.type !== CONTACT_TYPES.HOUSEHOLD || householdMembers.length === 0){
      return [];
    }

    return [
      {
        value: `${householdMembers[0].id},${householdMembers[1].id}`,
        label: 'Both'
      },
      ...(householdMembers.map(contact => ({
        value: `${contact.id}`,
        label: getContactName(contact)
      })))
    ];
  },[contact, householdMembers]);

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Communication Preferences' } />
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Communicate With' }
          >
            <HFSelect
              control={ control }
              name={ HOUSEHOLD_FIELDS.communicationWithIds }
              options={ options }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Default Send Mail To' }
          >
            <HFSelect
              control={ control }
              name={ HOUSEHOLD_FIELDS.sendMailToIds  }
              options={ options }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Default Send Email To' }
          >
            <HFSelect
              control={ control }
              name={ HOUSEHOLD_FIELDS.sendEmailToIds }
              options={ options }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});

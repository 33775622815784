import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { useNotesAndHistoryLocalState } from '@modules/NotesAndHistory/NotesAndHistoryLocalState';

import { TodoPopup } from '@modules/TodoPopup';
import Modal from '@components/Modal';
import Preloader from '@components/Preloader';
import SalesPipelinePopupFooter from '@modules/SalesPipline/SalesPipelinePopupFooter';
import SalesPipelinePopupTopPanel from '@modules/SalesPipline/SalesPipelinePopupTopPanel';

import { AdditionalDetails, PrimaryDetails, TasksAndNotes } from './components';

import { ClientOpportunityFormFields } from '@/shared/types/salesCycleOpportunity';
import { IdType } from '@/shared/types/commonTypes';
import { SalesClientOpportunityPopupProps } from './types';

import { useSalesClientOpportunityPopupStore } from './store';

import { MODAL_TYPE } from '@constants/modalTypes';
import { OPPORTUNITY_FIELD_NAMES, OPPORTUNITY_STAGES_OPTIONS } from '@constants/salesCycleOpportunity';

import { Spacer, LeftColumn, RightColumn, ContentWrapper } from './styles';

import { validationSchema } from './validationSchema';

import { getDefaultValues } from './utils';


export const SalesClientOpportunityPopup = observer(({
  closeModal,
  modalProps
}: SalesClientOpportunityPopupProps) => {
  const {
    id,
    onSaveCallback,
    onDeleteCallback,
  } = modalProps;
  const isEdit = Boolean(id);
  const header = `${ isEdit ? 'Edit' : 'Add'} Opportunity`;

  const {
    init,
    isHideModal,
    isLoad,
    notesUtilsStore,
    onCancel,
    onConfirm,
    onDelete,
    removeTodo,
    resetState,
    saveTodo,
    setIsHideModal,
  } = useSalesClientOpportunityPopupStore();

  const methods= useForm<ClientOpportunityFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: getDefaultValues(isEdit),
  });

  const modalStore = useStore().ModalStore;

  const onEditToDo = useCallback((id: IdType) => {
    setIsHideModal(true);
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        initLinkedContact: null,
        saveCallback: saveTodo,
        deleteCallback: removeTodo,
        onCloseAction: () => setIsHideModal(false),
      },
      component: TodoPopup
    });
  }, [ modalStore, saveTodo ]);
  
  useEffect(() => {
    init({
      closeTrigger: closeModal,
      id,
      onSaveCallback,
      onDeleteCallback,
      useFormMethods: methods
    });
    return resetState;
  }, [id, init, methods, onDeleteCallback, onSaveCallback, resetState]);

  // TODO: separate tabs in the NotesAndHistory
  const { resetTab: resetNotesAndHistoryTab } = useNotesAndHistoryLocalState();
  useEffect(() => {
    return resetNotesAndHistoryTab;
  }, [resetNotesAndHistoryTab]);

  return(
    <>
      {
        isHideModal && isLoad && <Preloader/>
      }
      <Modal
        $isHide={ isHideModal }
        isCloseDisabled={ isLoad }
        onClose={ closeModal }
        title={ header }
      >
        <FormProvider { ...methods }>
          <Modal.Content $disabled={ Boolean(notesUtilsStore.alertType) }>
            <Modal.TopPanel paddingLeft={ '20px !important' }>
              <SalesPipelinePopupTopPanel
                disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
                name={ OPPORTUNITY_FIELD_NAMES.stage }
                options={ OPPORTUNITY_STAGES_OPTIONS }
              />
            </Modal.TopPanel>
            {
              isLoad &&
              <Spacer>
                <Preloader isWithoutFullPageWrapper={ true }/>
              </Spacer>
            }
            {
              !isLoad &&
              <ContentWrapper>
                <LeftColumn>
                  <PrimaryDetails/>
                  <AdditionalDetails/>
                </LeftColumn>
                <RightColumn>
                  <TasksAndNotes onEditToDo={ onEditToDo }/>
                </RightColumn>
              </ContentWrapper>
            }
          </Modal.Content>
          <Modal.Footer paddingLeft={ `${isEdit ? '8px' : '12px'} !important` }>
            <SalesPipelinePopupFooter
              alertType={ notesUtilsStore.alertType }
              isDisabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
              isEdit={ isEdit }
              onCancel={ onCancel }
              onConfirm={ onConfirm }
              onDelete={ onDelete }
              statusFieldName={ OPPORTUNITY_FIELD_NAMES.opportunityStatus }
            />
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </>
  );
});

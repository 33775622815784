import React from 'react';
import { useFormContext } from 'react-hook-form';

import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation } from '@constants/validationPatterns';

import { getPattern } from '@/shared/utils/useFormUtils';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import {
  LeftColumnWrapper,
  RightColumnWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { ImportantDatesProps } from './types';

export const ImportantDates = ({
  anniversaryDateName,
  birthdateName,
  retirementDateName,
  retirementStatusName,
  retirementStatusOptions,
}: ImportantDatesProps) => {
  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Important Dates' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Date of Birth' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              name={ birthdateName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Anniversary Date' }
          >
            <HFDatePicker
              control={ control }
              name={ anniversaryDateName }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Retirement Date' }
          >
            <HFDatePicker
              control={ control }
              name={ retirementDateName }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Retirement Status' }
          >
            <HFSelect
              control={ control }
              name={ retirementStatusName }
              options={ retirementStatusOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};


import styled from 'styled-components';
import { COLORS } from '@constants/colors';
import { InnerWrapperProps } from './types';


export const Wrapper = styled.div`
  position: relative;
  height: 0;
`;

export const InnerWrapper = styled.div<InnerWrapperProps>`
  position: absolute;
  bottom: ${({ $bottom }) => $bottom ? `${$bottom}px` : 0};
  font-size: 12px;
  color: ${({ $disabled }) => $disabled ? COLORS.borders : COLORS.helpText};
`;

import { PaginationItem } from '@material-ui/lab';
import { COLORS } from '@constants/colors';
import styled from 'styled-components';

export const PaginationItemStyled = styled(PaginationItem)`
    color: ${COLORS.primaryText};
    &.Mui-disabled {
        color: ${COLORS.borders};
    }
    &.Mui-selected:hover {
        background-color: inherit;
    }
    &.MuiPaginationItem-outlinedPrimary {
        border-color: ${COLORS.borders};
    }
    &.MuiPaginationItem-outlinedPrimary.Mui-selected {
        background-color: ${COLORS.orange};
        color: ${COLORS.white};
    }
    &.MuiPaginationItem-outlinedPrimary.Mui-selected:hover {
        background-color: ${COLORS.orange};
    }
    &.MuiPaginationItem-page.Mui-disabled {
        opacity: 1;
    }
    &.MuiPaginationItem-page:hover {
        border: 1px solid ${COLORS.orange};
        background-color: inherit;
    },
`;
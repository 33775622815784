import React, { MouseEvent, useCallback, useState } from 'react';

import { PREDEFINED_PROPS } from './data';

import {
  DotsMenuButton,
  DotsMenuStyledDotIcon,
  IconWrapper,
  LabelWrapper,
  StyledMenu,
  StyledMenuItem
} from './styles';

import { DotsMenuProps, DotsMenuItemTypes } from './types';

export const DotsMenu = <T = DotsMenuItemTypes>({
  $marginBottom,
  $marginLeft,
  $marginRight,
  $marginTop,
  items,
  onSelect,
}: DotsMenuProps<T>) => {
  const  [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const onMenuClose = useCallback(() => setAnchorEl(null), []);

  const onItemClick = useCallback((type: T) => () => {
    setAnchorEl(null);
    onSelect(type);
  }, [ onSelect ]);

  const {
    MenuListProps,
    PaperProps,
    anchorOrigin,
    transformOrigin,
  } = PREDEFINED_PROPS;

  return (
    <>
      <DotsMenuButton
        $isActive={ Boolean(anchorEl) }
        $marginBottom={ $marginBottom }
        $marginLeft={ $marginLeft }
        $marginRight={ $marginRight }
        $marginTop={ $marginTop }
        onClick={ onMenuClick }
      >
        <DotsMenuStyledDotIcon/>
      </DotsMenuButton>
      <StyledMenu
        MenuListProps={ MenuListProps }
        PaperProps={ PaperProps }
        anchorEl={ anchorEl }
        anchorOrigin={ anchorOrigin }
        getContentAnchorEl={ null }
        onClose={ onMenuClose }
        open={ Boolean(anchorEl) }
        transformOrigin={ transformOrigin }
      >
        {
          items.map(item => {
            const Icon = item.icon;

            return (
              <StyledMenuItem
                $iconHoverColor={ item?.hoverColor }
                $topSeparator={ item.topSeparator }
                key={ item.type as string }
                onClick={ onItemClick(item.type) }
              >
                <IconWrapper $fillColor={ item.fillColor }>
                  { <Icon /> }
                </IconWrapper>
                <LabelWrapper>
                  { item.label }
                </LabelWrapper>
              </StyledMenuItem>
            );
          })
        }
      </StyledMenu>
    </>
  );
};

import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import PopupActionsButtons from '@components/PopupActionsButtons';
import { NotesAndHistoryNotificationWrapper } from '@modules/NotesAndHistory';

import { ButtonsWrapper, Wrapper } from './styles';

import { ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES_MESSAGES } from '@modules/AccountsAndPlansPopup/data/data';


export const Actions = observer(() => {
  const accountAndPlansPopupLocalStore = useAccountAndPlansPopupLocalStore();
  const {
    isSaveCloseActionDisabled,
    isLoad,
    notesUtilsStore,
    onCancel,
    onSave,
  } = accountAndPlansPopupLocalStore;

  const buttonProps = useMemo(() => ({
    disabled: isLoad || isSaveCloseActionDisabled
  }), [isLoad, isSaveCloseActionDisabled]);

  return (
    <Wrapper>
      <NotesAndHistoryNotificationWrapper
        alertTexts={ ACCOUNT_AND_PLANS_POPUP_ALERT_TYPES_MESSAGES }
        alertType={ notesUtilsStore.alertType }
      />

      <ButtonsWrapper>
        <PopupActionsButtons
          CancelProps={ buttonProps }
          SaveProps={ buttonProps }
          cancelLabel={ notesUtilsStore.alertType ? 'No' : 'Cancel' }
          onCancelClick={ onCancel }
          onSaveClick={ onSave }
          saveLabel={ notesUtilsStore.alertType ? 'Yes' : 'Save' }
        />
      </ButtonsWrapper>
    </Wrapper>
  );
});

const NAME_COLUMN = {
  align: 'left',
  name: 'Name',
  width: '33%',
};

const TYPE_COLUMN = {
  align: 'center',
  name: 'Type',
  width: '17%',
};

const SALE_TYPE_COLUMN = {
  align: 'left',
  name: 'Sale Type',
  width: '17%',
};

const STATUS_COLUMN = {
  align: 'center',
  name: 'Status',
  width: '18%',
};

const AMOUNT_COLUMN = {
  align: 'left',
  name: 'Amount',
  width: '15%',
};

const STAGE_COLUMN = {
  align: 'left',
  name: 'Stage',
  width: '17%',
};

const INSTITUTION_COLUMN = {
  align: 'left',
  name: 'Institution',
  width: '17%',
};

const ACCOUNT_NUMBER_COLUMN = {
  align: 'center',
  name: 'Acct #',
  width: '18%',
};

const START_DATE_COLUMN = {
  align: 'left',
  name: 'Start Date',
  width: '18%',
};

const FEE_AMOUNT_COLUMN = {
  align: 'left',
  name: 'Fee Amount',
  width: '15%',
};

const ASSIGNED_TO_COLUMN = {
  align: 'center',
  name: 'Assigned to',
  width: '18%',
};

const DUE_REVIEW_DATE_COLUMN = {
  align: 'left',
  name: 'Due/Review Date',
  width: '15%',
};

export const DEFAULT_CONFIG = [
  NAME_COLUMN,
  TYPE_COLUMN
];

export const CONTACT_CONFIG = [
  ...DEFAULT_CONFIG
];

export const OPPORTUNITY_CONFIG = [
  ...DEFAULT_CONFIG,
  SALE_TYPE_COLUMN,
  STATUS_COLUMN,
  AMOUNT_COLUMN,
];

export const SALES_CYCLE_CONFIG = [
  ...DEFAULT_CONFIG,
  STAGE_COLUMN,
  STATUS_COLUMN,
  AMOUNT_COLUMN,
];

export const ACCOUNT_CONFIG = [
  ...DEFAULT_CONFIG,
  INSTITUTION_COLUMN,
  ACCOUNT_NUMBER_COLUMN,
  AMOUNT_COLUMN,
];

export const PLAN_CONFIG = [
  ...DEFAULT_CONFIG,
  START_DATE_COLUMN,
  STATUS_COLUMN,
  FEE_AMOUNT_COLUMN,
];

export const TASK_CONFIG = [
  ...DEFAULT_CONFIG,
  STAGE_COLUMN,
  ASSIGNED_TO_COLUMN,
  DUE_REVIEW_DATE_COLUMN,
];

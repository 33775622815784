import React from 'react';

import BookmarkIcon from '@components/BookmarkIcon';
import { CustomCheckboxWithoutLabel, SIZES } from '@components/CustomCheckbox';

import { CellWithControlsProps } from './types';

import { StyledTableCell } from './styles';

const BOOKMARK_ICON_BOX_PROPS = {
  marginLeft: '2px',
  marginTop: '5px'
};

export const CellWithControls = ({
  bookmarkIconProps,
  checkboxProps,
}: CellWithControlsProps) => {
  return (
    <>
      {
        checkboxProps &&
        <StyledTableCell padding={ 'none' }>
          <CustomCheckboxWithoutLabel
            $size={ SIZES.medium }
            $withPaddings={ false }
            checked={ checkboxProps.checked }
            onChange={ checkboxProps.onChange }
          />

        </StyledTableCell>
      }
      {
        bookmarkIconProps &&
        <StyledTableCell padding={ 'none' }>
          <BookmarkIcon
            boxProps={ BOOKMARK_ICON_BOX_PROPS }
            isActive={ bookmarkIconProps.isActive }
            margin-top
            onStateChange={ bookmarkIconProps.onStateChange }
          />
        </StyledTableCell>
      }
    </>
  );
};

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CustomCheckbox, { SIZES } from '@components/CustomCheckbox';
import FormBlock from '@components/FormBlock';

import { RULES } from '@constants/contactRules';

import { Item, Wrapper } from './styles';

export const Rules = () => {
  const { control } = useFormContext();

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 20 }
    >
      <FormBlock.Header title={ 'Rules' }/>
      <FormBlock.RowWrapper>
        <Wrapper>
          {
            RULES.map(({ name, label }) => (
              <Item key={ name }>
                <Controller
                  control={ control }
                  name={ name }
                  render={ ({
                    field: {
                      value,
                      onChange
                    }
                  }) => (
                    <CustomCheckbox
                      $size={ SIZES.medium }
                      CheckboxProps={ {
                        value: value
                      } }
                      checked={ !value }
                      label={ label }
                      onChange={ (e, newValue) => {
                        onChange(Number(!newValue)); // here we use reverse values, because props on
                        // backend use numbers and means 'allow', but ui means 'disallow'
                      } }
                    />
                  ) }
                />
              </Item>
            ))
          }
        </Wrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

import React, { useCallback, useMemo } from 'react';

import { Close } from '@/assets';
import CustomSelect from '@components/CustomSelect';
import UserItem from '@components/NewUserItem';

import {
  RELATION_OPTIONS_WITHOUT_SPOUSE,
  RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE
} from '@constants/relatedContacts';
import cloneData from '@/shared/utils/cloneData';
import { CONTACT_TYPE } from '@modules/AddContactModal/data';

import { RelatedListContainer, CustomSelectContainer } from './styles';

import { ContactRelatedItem } from '@/shared/types/contact';
import { IdType } from '@/shared/types/commonTypes';
import { RelatedListProps } from './types';


export const RelatedList = ({
  contactType,
  relatedContacts,
  setRelatedContacts
}: RelatedListProps) => {

  const onChange = useCallback((id: IdType | null, event?: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const contacts = cloneData(relatedContacts);
    const index = contacts.findIndex((contact: ContactRelatedItem) => contact.id === id);
    event?.target.value
      ? (contacts[index].relationType = event?.target.value)
      : contacts.splice(index, 1);
    setRelatedContacts(contacts);
  }, [relatedContacts, setRelatedContacts]);

  const relationOptions = useMemo(() => {
    if(contactType === CONTACT_TYPE.Household) {
      return RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE;
    }
    return RELATION_OPTIONS_WITHOUT_SPOUSE;
  }, [contactType]);

  return (
    <>
      { relatedContacts.length > 0 && relatedContacts.map((contact: ContactRelatedItem) => (
        <RelatedListContainer key={ contact.id }>
          <UserItem
            labelWidth={ 275 }
            user={ contact }
            withCoreClassification
          />

          <CustomSelectContainer>
            <CustomSelect
              onChange={ (event) => onChange(contact.id, event) }
              options={ relationOptions }
              value={ contact.relationType }
            />
          </CustomSelectContainer>

          <Close onClick={ () => onChange(contact.id) }/>
        </RelatedListContainer>
      ))}
    </>
  );
};

import React, { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import TypeButton from '@components/TypeButton';


import { EXPORT_FIELD_NAMES } from '../../data';

import { BUTTONS_CONFIG, ButtonStyle } from './data';

import { Wrapper } from './styles';
import { TypeButtonOnClickType } from '@components/TypeButton/types';


export const ExportTypeButtons = () => {
  const { control } = useFormContext();
  const { field: { onChange, value: currentValue } } = useController({
    name: EXPORT_FIELD_NAMES.exportType
  });

  const onChangeWrapper: TypeButtonOnClickType = useCallback((event, value) => {
    onChange(value);
  }, [onChange]);

  return (
    <Wrapper>
      {
        Object.values(BUTTONS_CONFIG).map((button) => {
          const { value, icon, label } = button;
          const Icon = icon;

          return(
            <TypeButton
              BtnProps={ {
                startIcon: <Icon/>,
                style: ButtonStyle,
              } }
              isActive={ value === currentValue }
              key={ value }
              onClick={ onChangeWrapper }
              value={ value }
            >
              {label}
            </TypeButton>
          );
        })
      }
    </Wrapper>
  );
};

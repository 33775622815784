import { flow, makeAutoObservable } from 'mobx';
import { Store } from '@store';

import { AxiosResponse } from 'axios';
import { ContactData, CriteriaFormWrapper, GetCriteriaResponseType, ListDataDetailsType } from '@/shared/types/lists';
import { GetAllTags, GetCountriesResponse, GetStatesResponse, ValueLabelObj } from '@/shared/types/commonTypes';
import { TagsOptions, UseFormMethods } from './types';

import { ENTITY_NAMES, ID_OF_USASTATES } from '@constants/common';
import { LISTS_TYPES, OPERAND_OPTIONS, TYPE_FIELDS } from '@constants/lists';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { INIT_TAGS_OPTIONS } from './data';

import { getContactIndividualOptions } from '@services/api/contacts/contacts';
import { getCountries, getStates } from '@services/api/common/common';
import { getListCriteria, saveList } from '@services/api/lists/lists';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { convertBackendTags, convertFormToBackend, convertToFormState } from './utils';
import { getCountriesOptions, sourceConverter } from '@/shared/utils/getOptionsUtils';
import { getStatesOptions } from '@/shared/utils/getStatesOptions';

import { IndividualOptionsResponse } from '@/shared/types/contactOptionsTypes';
import { getAllTags } from '@services/api/tags/tags';


class ListCriteriaStore {
  advisorOptions: Array<ValueLabelObj> = [];
  allClientsInGroupOptions: Array<ValueLabelObj> = [];
  asyncRequestExecutor: AsyncRequestExecutor;
  contactsData: ContactData = {} as ContactData;
  coreStore: Store;
  countriesOptions: Array<ValueLabelObj> = [];
  currentListDetails: ListDataDetailsType | null = null;
  globalOperand: string = OPERAND_OPTIONS[0].value as string;
  isLoad: boolean = false;
  isPageActive: boolean = false;
  isShowSaveBar: boolean = false;
  lastSavedValue: CriteriaFormWrapper = {} as CriteriaFormWrapper;
  listId: string = '';
  notificationHelper: NotificationHelper;
  sourcesOptions: Array<ValueLabelObj> = [];
  sourceCategoriesOptions: Array<ValueLabelObj> = [];
  statesOptions: Array<ValueLabelObj> = [];
  tagsOptions: TagsOptions = INIT_TAGS_OPTIONS;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow.bound
    });

    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.list
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  *getCriteria() {
    if(this.isPageActive){
      try {
        const start = async ()=> {
          const response: GetCriteriaResponseType =  await getListCriteria({ id: this.listId });

          this.coreStore.ListsStore.setListName(response.data.data.listData.name);

          const convertedData = convertToFormState(response.data.data.criteria);

          this.setFormState(convertedData);

          this.lastSavedValue = convertedData;
          this.currentListDetails = response.data.data.listData;
          this.contactsData = response.data.data.listData.contactData;
        };

        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: start,
          onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  *init(listId: string, useFormMethods: UseFormMethods) {
    try {
      this.isPageActive = true;
      const start = async () => {
        this.isLoad = true;
        this.listId = listId;
        this.useFormMethods = useFormMethods;

        await this.getCriteria();

        const response: AxiosResponse<GetStatesResponse> = await getStates({ id: ID_OF_USASTATES });
        this.statesOptions = getStatesOptions(response.data.data);

        const countriesOptionsResponse:AxiosResponse<GetCountriesResponse> = await getCountries();
        this.countriesOptions = getCountriesOptions(countriesOptionsResponse.data.data);

        const individualOptionsResponse:IndividualOptionsResponse =  await getContactIndividualOptions();
        this.sourcesOptions = sourceConverter(individualOptionsResponse.data.data.source);
        this.sourceCategoriesOptions = sourceConverter(individualOptionsResponse.data.data.sourceCategory);
        this.advisorOptions = individualOptionsResponse.data.data.adviser.map(advisor =>({
          value: advisor.id,
          label: `${advisor.firstName} ${advisor.lastName}`
        }));
        this.allClientsInGroupOptions = individualOptionsResponse.data.data.allClientsInGroup.map(client =>({
          value: client.id,
          label: `${client.firstName} ${client.lastName}`
        }));


        const tagsResponse: AxiosResponse<GetAllTags> = await getAllTags();

        this.tagsOptions =  convertBackendTags(tagsResponse.data);
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setFormState(newState: CriteriaFormWrapper) {
    this.useFormMethods.reset(newState);
  }

  setGlobalOperand = (newGlobalOperand: string) => {
    this.globalOperand = newGlobalOperand;
  };

  onCancel = () => {
    this.setFormState(this.lastSavedValue);
    this.isShowSaveBar = false;
  };

  *onSaveCallback(data: CriteriaFormWrapper) {
    try {
      this.isShowSaveBar = false;
      this.isLoad = true;


      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => (
          saveList({
            id: this.currentListDetails!.id,
            parentId: this.currentListDetails!.parentId,
            typeField: TYPE_FIELDS.List,
            typeList: LISTS_TYPES.Dynamic,
            name: this.currentListDetails!.name,
            splitHousehold: this.currentListDetails!.splitHousehold,
            description: this.currentListDetails!.description,
            listOwner: this.currentListDetails!.listOwner,
            filter: convertFormToBackend(data)
          })
        ),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
      });

      yield this.getCriteria();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;

      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  onSave = () => {
    this.useFormMethods.handleSubmit((data) => {
      this.onSaveCallback(data);
    })();
  };

  resetStore = () => {
    this.advisorOptions = [];
    this.allClientsInGroupOptions = [];
    this.contactsData = {} as ContactData;
    this.countriesOptions = [];
    this.currentListDetails = null;
    this.globalOperand = OPERAND_OPTIONS[0].value as string;
    this.isLoad = false;
    this.isShowSaveBar = false;
    this.lastSavedValue = {} as CriteriaFormWrapper;
    this.listId = '';
    this.sourcesOptions = [];
    this.sourceCategoriesOptions = [];
    this.statesOptions = [];
    this.tagsOptions = INIT_TAGS_OPTIONS;
    this.useFormMethods = {} as UseFormMethods;
    this.isPageActive = false;
  };

  toggleSaveBar = (forcedSate: boolean) => {
    this.isShowSaveBar = forcedSate;
  };
}

export default ListCriteriaStore;

import { BENEFICIARIES_ITEM_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';

export const getFieldsNames = (fieldPath: string) => ({
  nameFieldName: `${fieldPath}.${BENEFICIARIES_ITEM_NAMES.name}`,
  typeFieldName: `${fieldPath}.${BENEFICIARIES_ITEM_NAMES.type}`,
  percentFieldName: `${fieldPath}.${BENEFICIARIES_ITEM_NAMES.percent}`,
  relationTypeFieldName: `${fieldPath}.${BENEFICIARIES_ITEM_NAMES.relationType}`,
  sortFieldName: `${fieldPath}.${BENEFICIARIES_ITEM_NAMES.sort}`,
});

export const nameFieldInputProps = {
  placeholder: 'Enter Name',
  maxLength: 150,
};

export const percentFieldInputProps = {
  placeholder: '0',
  maxLength: 3,
};
import React from 'react';

import StageNavigationPanel from '@components/StageNavigationPanel'; // TODO

import { ContactInformation, Details } from './components';

import { ContentProps } from './types';

import { StepperWrapper, Wrapper } from './styles';

export const Content = ({
  classificationOptions,
  currentStep,
  genderOptions,
  isLastStep,
  onChangeStageNumber,
  primaryAdviser,
  sourceCategoryOptions,
  sourceOptions,
  stagesData,
  statusOptions,
}: ContentProps) => (
  <Wrapper>
    <StepperWrapper>
      <StageNavigationPanel
        lastStageWidth={ 90 }
        onChangeStageNumber={ onChangeStageNumber }
        stageNumber={ currentStep }
        stagesData={ stagesData }
      />
    </StepperWrapper>
    {
      !isLastStep &&
      <ContactInformation
        classificationOptions={ classificationOptions }
        currentStep={ currentStep - 1 }
        genderOptions={ genderOptions }
        key={ currentStep } // Attention key is used here to trigger rerender on step change
        primaryAdviser={ primaryAdviser }
        sourceCategoryOptions={ sourceCategoryOptions }
        sourceOptions={ sourceOptions }
        statusOptions={ statusOptions }
      />
    }
    {
      isLastStep &&
      <Details/>
    }
  </Wrapper>
);
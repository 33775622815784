import { LISTS_TABLE_FILTER_NAMES } from '@constants/lists';

export const USERS = {
  name: LISTS_TABLE_FILTER_NAMES.officePrimaryAdvisor,
  label: 'Users',
  values: []
};

export const LIST_OWNER =   {
  name: LISTS_TABLE_FILTER_NAMES.listOwner,
  label: 'List Owner',
  values: []
};


export const CLASSIFICATION =   {
  name: LISTS_TABLE_FILTER_NAMES.classification,
  label: 'Core Classification',
  values: []
};

export const STATUS = {
  name: LISTS_TABLE_FILTER_NAMES.status,
  label: 'Contact Status',
  values: []
};


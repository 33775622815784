import { ColumnType } from '@components/Table/components/Header/types';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import {
  getColumnCommonFields
} from '@modules/AccountsAndPlansPopup/components/Content/utils';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'startDate',
    label: 'Start Date',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'endDate',
    label: 'End Date',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'amount',
    label: 'Amount',
    ...getColumnCommonFields('130px')
  },
  {
    id: 'type',
    label: 'Type',
    ...getColumnCommonFields('260px')
  }
];

export const STANDING_INSTRUCTIONS_TYPES = {
  premiumPayment: 'Premium Payment',
  contribution: 'Contribution',
  withdrawal: 'Withdrawal',
};

export const STANDING_INSTRUCTIONS_TYPES_OPTIONS =  optionsFromValue(Object.values(STANDING_INSTRUCTIONS_TYPES));
import React from 'react';
import { observer } from 'mobx-react';

import SideMenu from '@components/SideMenu';

import { AppBackButton, AppHeader, AppPageTitle } from './components';

import { AppPageProps } from './types';

import { ContentWrapper, SidebarWrapper, Wrapper } from './styles';
export const AppPageComponent = ({
  withSidebar = true,
  children,
}: AppPageProps) => {

  return (
    <Wrapper withSidebar={ withSidebar }>
      {
        withSidebar &&
        <SidebarWrapper>
          <SideMenu />
        </SidebarWrapper>
      }
      <ContentWrapper withSidebar={ withSidebar }>
        { children }
      </ContentWrapper>
    </Wrapper>);
};

AppPageComponent.AppHeader = AppHeader;
AppPageComponent.AppPageTitle = AppPageTitle;
AppPageComponent.AppBackButton = AppBackButton;

export const AppPage = observer(AppPageComponent);
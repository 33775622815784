import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getContactNameWithNickname } from '@/shared/utils/getContactNameWithNickname';

import {
  BriefInfo,
  ImportantDates,
  KeyInformation,
  MiscInfo,
  NameGender,
  Occupations,
  SpouseBtn,
  TabContentWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';
import LimitAccessPopup from '@modules/LimitAccessPopup';

import { getFieldsNames, spouseButtonStyle } from './data';
import { ImageUploaderCategories } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { IndividualContactItem } from '@/shared/types/contact';


export const HouseholdPersonalDetails = observer(() => {
  const { optionsForHousehold, householdMembers } = useStore().ContactDetailsProfileStore;
  const { states: statesOptions } = useStore().SettingsStore;
  const modalStore = useStore().ModalStore;

  const firstProfileRef = useRef<HTMLDivElement>(null);
  const secondProfileRef = useRef<HTMLDivElement>(null);

  const fieldNames = useMemo(() => (
    householdMembers.map((item, index) => getFieldsNames(index))
  ),[householdMembers]);

  const isBlocked = typeof householdMembers[1]?.editAllow === 'number' && !householdMembers[1].editAllow;

  const scrollTo = useCallback(() => {
    if(isBlocked) {
      modalStore.openModal({
        modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
        component: LimitAccessPopup,
        modalProps: {
          withPushToContacts: false
        }
      });
    } else {
      secondProfileRef?.current && secondProfileRef?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  },[isBlocked, modalStore]);

  const scrollToHouseHold = useCallback(() => {
    firstProfileRef?.current && firstProfileRef?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }, []);

  if(householdMembers.length === 0){
    return null;
  }

  const getSsnState = (contact: IndividualContactItem) => (
    contact && 'ssnReadOnly' in contact ? Boolean(contact.ssnReadOnly) : false
  );

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item
        $noBottomPadding={ true }
        ref={ firstProfileRef }
      >
        <BriefInfo
          briefDescriptionName={ fieldNames[0].briefDescription }
          contactTitle={ getContactNameWithNickname((householdMembers[0])) }
          headshotBackgroundType={ ImageUploaderCategories.DefaultContact }
          photoName={ fieldNames[0].photo }
        >
          <SpouseBtn
            isBlocked={ isBlocked }
            onItemClick={ scrollTo }
            spouseData={ householdMembers[1] }
            style={ spouseButtonStyle }
          />
        </BriefInfo>
        <NameGender
          emailNameToUseName={ fieldNames[0].emailNameToUse }
          firstNameName={ fieldNames[0].firstName }
          genderName={ fieldNames[0].gender }
          genderOptions={ optionsForHousehold?.gender || [] }
          lastNameName={ fieldNames[0].lastName }
          maidenNameName={ fieldNames[0].maidenName }
          mailNameToUseName={ fieldNames[0].mailNameToUse }
          middleNameName={ fieldNames[0].middleName }
          nicknameName={ fieldNames[0].nickname }
          prefixName={ fieldNames[0].prefix }
          prefixOptions={ optionsForHousehold?.prefix || [] }
          suffixName={ fieldNames[0].suffix }
          suffixOptions={ optionsForHousehold?.suffix || [] }
        />
        <ImportantDates
          anniversaryDateName={ fieldNames[0].anniversaryDate }
          birthdateName={ fieldNames[0].birthdate }
          retirementDateName={ fieldNames[0].retirementDate }
          retirementStatusName={ fieldNames[0].retirementStatus }
          retirementStatusOptions={ optionsForHousehold?.retirementStatus || [] }
        />
        <KeyInformation
          birthplaceName={ fieldNames[0].birthplace }
          citizenshipCountryName={ fieldNames[0].citizenshipCountry }
          countriesOptions={ optionsForHousehold?.countries || [] }
          disableSsn={ getSsnState(householdMembers[0]) }
          dlExpirationDateName={ fieldNames[0].dlExpirationDate }
          dlNumberName={ fieldNames[0].dlNumber }
          dlStateName={ fieldNames[0].dlState }
          ethnicityName={ fieldNames[0].ethnicity }
          ethnicityOptions={ optionsForHousehold?.ethnicity || [] }
          maritalStatusName={ fieldNames[0].maritalStatus }
          maritalStatusOptions={ optionsForHousehold?.maritalStatus || [] }
          ssnName={ fieldNames[0].ssn }
          stateOptions={ statesOptions || [] }
          taxIdNumberName={ fieldNames[0].taxIdNumber }
        />
        <Occupations name={ fieldNames[0].occupations }/>
        <MiscInfo petsName={ fieldNames[0].pets } />
      </TabContentWrapper.Item>

      { !isBlocked &&
        <TabContentWrapper.Item
          $noBottomPadding={ true }
          ref={ secondProfileRef }
        >
          <BriefInfo
            briefDescriptionName={ fieldNames[1].briefDescription }
            contactTitle={ getContactNameWithNickname((householdMembers[1])) }
            headshotBackgroundType={ ImageUploaderCategories.DefaultContact }
            photoName={ fieldNames[1].photo }
          >
            <SpouseBtn
              isBlocked={ false }
              onItemClick={ scrollToHouseHold }
              spouseData={ householdMembers[0] }
              style={ spouseButtonStyle }
            />
          </BriefInfo>
          <NameGender
            emailNameToUseName={ fieldNames[1].emailNameToUse }
            firstNameName={ fieldNames[1].firstName }
            genderName={ fieldNames[1].gender }
            genderOptions={ optionsForHousehold?.gender || [] }
            lastNameName={ fieldNames[1].lastName }
            maidenNameName={ fieldNames[1].maidenName }
            mailNameToUseName={ fieldNames[1].mailNameToUse }
            middleNameName={ fieldNames[1].middleName }
            nicknameName={ fieldNames[1].nickname }
            prefixName={ fieldNames[1].prefix }
            prefixOptions={ optionsForHousehold?.prefix || [] }
            suffixName={ fieldNames[1].suffix }
            suffixOptions={ optionsForHousehold?.suffix || [] }
          />
          <ImportantDates
            anniversaryDateName={ fieldNames[1].anniversaryDate }
            birthdateName={ fieldNames[1].birthdate }
            retirementDateName={ fieldNames[1].retirementDate }
            retirementStatusName={ fieldNames[1].retirementStatus }
            retirementStatusOptions={ optionsForHousehold?.retirementStatus || [] }
          />
          <KeyInformation
            birthplaceName={ fieldNames[1].birthplace }
            citizenshipCountryName={ fieldNames[1].citizenshipCountry }
            countriesOptions={ optionsForHousehold?.countries || [] }
            disableSsn={ getSsnState(householdMembers[1]) }
            dlExpirationDateName={ fieldNames[1].dlExpirationDate }
            dlNumberName={ fieldNames[1].dlNumber }
            dlStateName={ fieldNames[1].dlState }
            ethnicityName={ fieldNames[1].ethnicity }
            ethnicityOptions={ optionsForHousehold?.ethnicity || [] }
            maritalStatusName={ fieldNames[1].maritalStatus }
            maritalStatusOptions={ optionsForHousehold?.maritalStatus || [] }
            ssnName={ fieldNames[1].ssn }
            stateOptions={ statesOptions || [] }
            taxIdNumberName={ fieldNames[1].taxIdNumber }
          />
          <Occupations name={ fieldNames[1].occupations }/>
          <MiscInfo petsName={ fieldNames[1].pets } />
        </TabContentWrapper.Item>
      }
    </TabContentWrapper>
  );
});

import styled from 'styled-components';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import { Sliders } from 'react-feather';

import { ReactComponent as Cross } from '@/assets/icons/cross.svg';

export const CrossIcon = styled(Cross)``;

export const FilterIcon = styled(Sliders)`
  path {
    fill: ${ ({ $enableFilterMode }) => $enableFilterMode ? '#263140' : '#fff' };
  }

  &:hover {
    path {
      fill: ${ ({ $enableFilterMode }) => $enableFilterMode ? '#263140' : '#fff' };
    }
  }
`;

export const StyledBadge = styled(Badge)`
  &.MuiBadge-root {
    & .MuiBadge-badge {
      cursor: pointer;
      background: ${ ({ $hoverState }) => ($hoverState ? '#e24141' : '#ea8213') };
      color: #fff;
    }
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${ ({ $enableFilterMode }) => $enableFilterMode ? '#fff' : '#263140' };
    color: ${ ({ $enableFilterMode }) => $enableFilterMode ? '#263140' : '#fff' };
    border-color: #263140;

    &:hover {
      background-color: ${ ({ $enableFilterMode }) => $enableFilterMode ? '#fff' : '#172130' };
      border-color: #263140;
    }
  }
`;

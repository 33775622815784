import React from 'react';

import { FILTER_ALL_BTN_LABEL } from '@/shared/constants/common';
import { NOTE_TYPES } from '@constants/common';

import RowFilter from '@components/RowFilter';
import BubbleButton from '@components/BubbleButton';

import { StandardFiltersProps } from './types';


export const StandardFilters = ({
  onChange
}: StandardFiltersProps) => {

  return(
    <RowFilter
      allButtonLabel={ FILTER_ALL_BTN_LABEL }
      boxProps={ {
        justifyContent: 'start',
      } }
      items={ Object.values(NOTE_TYPES) }
      limit={ 5 }
      onChange={ onChange }
      renderItem={ ({
        content,
        isSelected,
        isShowMore,
        onClick,
        isSelectedFromMore,
        indexForKey,
      }) => (
        <BubbleButton
          $isActive={ isSelected && !isShowMore }
          $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
          BtnProps={ {
            style: {
              marginRight: '5px'
            }
          } }
          key={ indexForKey }
          onClick={ onClick }
        >
          {content}
        </BubbleButton>
      ) }
    />
  );
};
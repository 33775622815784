import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { ValueLabelObj } from '@/shared/types/commonTypes';
import {
  BusinessOptionsDataBackendType,
  BusinessOptionsType,
  Country,
  HouseholdOptionsDataBackendType,
  HouseholdOptionsType,
  IndividualOptionsDataBackendType,
  IndividualOptionsType,
} from '@/shared/types/contactOptionsTypes';
import { advisersConverter, sourceCategoryConverter, sourceConverter } from '@/shared/utils/getOptionsUtils';


const countryConverter = (country: Country): ValueLabelObj => ({
  value: country.id,
  label: country.full_name,
});

export const businessOptionsNormalizer = (data: BusinessOptionsDataBackendType): BusinessOptionsType => ({
  companyType: optionsFromValue(data.companyType),
  countries: data.countries.map(countryConverter),
  employees: optionsFromValue(data.employees),
  primaryAdvisers: advisersConverter(data.adviser),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
});

export const houseHoldOptionsNormalizer= (data: HouseholdOptionsDataBackendType): HouseholdOptionsType => ({
  countries: data.countries.map(countryConverter),
  ethnicity: optionsFromValue(data.ethnicity),
  gender: optionsFromValue(data.gender),
  maritalStatus: optionsFromValue(data.maritalStatus),
  prefix: optionsFromValue(data.prefix),
  primaryAdvisers: advisersConverter(data.adviser),
  retirementStatus: optionsFromValue(data.retirementStatus),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
  suffix: optionsFromValue(data.suffix)
});

export const individualOptionsNormalizer = (data: IndividualOptionsDataBackendType): IndividualOptionsType => ({
  countries: data.countries.map(countryConverter),
  ethnicity: optionsFromValue(data.ethnicity),
  gender: optionsFromValue(data.gender),
  maritalStatus: optionsFromValue(data.maritalStatus),
  prefix: optionsFromValue(data.prefix),
  primaryAdvisers: advisersConverter(data.adviser),
  retirementStatus: optionsFromValue(data.retirementStatus),
  source: sourceConverter(data.source),
  sourceCategory: sourceCategoryConverter(data.sourceCategory),
  suffix: optionsFromValue(data.suffix)
});

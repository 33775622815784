import React, { useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Cross } from '@/assets';

import BlockButton from '@components/BlockButton';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HelperText from '@components/HelperText';
import MuiBox from '@material-ui/core/Box';

import { FiltersProps } from './types';
import { TagFilters } from '@/shared/types/tags';

import { TAG_FILTERS_FIELDS_NAMES } from '@constants/userSettingsUserCustomFields';
import { FROM_INPUT_PROP, TO_INPUT_PROP, COMMON_TEXT_FIELD_PROP, DEFAULT_VALUES, BUTTON_STYLE } from './data';

import { validationSchema } from './validationSchema';

export const Filters = ({
  disabled,
  onFiltersChange
}: FiltersProps) => {
  const {
    control,
    reset,
    formState: {
      dirtyFields,
    },
    handleSubmit
  } = useForm<TagFilters>({
    defaultValues: DEFAULT_VALUES,
    mode: 'all',
    resolver: yupResolver(validationSchema)
  });

  const onReset = useCallback(() => {
    reset(DEFAULT_VALUES);
    onFiltersChange(DEFAULT_VALUES);
  }, [onFiltersChange, reset]);

  const allFields = useWatch({ control });

  const numberOfDirtyFields = Object.keys(dirtyFields).length;

  useEffect(() => {
    if(numberOfDirtyFields > 0){
      handleSubmit((data) => {
        onFiltersChange(data);
      })();
    }
  }, [allFields, handleSubmit, numberOfDirtyFields, onFiltersChange]);

  return (
    <MuiBox
      alignItems={ 'center' }
      display={ 'flex' }
      justifyContent={ 'flex-start' }
    >
      <HelperText
        padding={ '0 13px 15px 0px' }
        text={ 'Tag Count Filter' }
      />
      <MuiBox width={ '68px' }>
        <HFTextInput
          TextFieldProps={ COMMON_TEXT_FIELD_PROP }
          control={ control }
          disabled={ disabled }
          inputProps={ FROM_INPUT_PROP }
          name={ TAG_FILTERS_FIELDS_NAMES.from }
          onlyNumbers={ true }
        />
      </MuiBox>
      <HelperText
        padding={ '0 13px 15px 13px' }
        text={ 'to' }
      />
      <MuiBox width={ '62px' }>
        <HFTextInput
          TextFieldProps={ COMMON_TEXT_FIELD_PROP }
          control={ control }
          disabled={ disabled }
          inputProps={ TO_INPUT_PROP }
          name={ TAG_FILTERS_FIELDS_NAMES.to }
          onlyNumbers={ true }
        />
      </MuiBox>
      {
        numberOfDirtyFields > 0 &&
        <MuiBox marginLeft={ '11px' }>
          <BlockButton
            $iconSize={ '12px' }
            disabled={ disabled }
            onClick={ onReset }
            startIcon={ <Cross/> }
            style={ BUTTON_STYLE }
          >
            Clear
          </BlockButton>
        </MuiBox>
      }
    </MuiBox>
  );
};

import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { POPUP_RADIO_VALUES } from '@constants/common';
import { RADIO_BUTTONS } from './data';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';
import RadioGroup, { useRadioBtn } from '@components/RadioGroup';

import { Wrapper } from './styles';

import { TodoEditConfirmationPopupProps } from './types';

export const TodoEditConfirmationPopup = observer(({
  closeModal,
  modalProps: {
    formData,
    onCloseAction,
    onConfirm
  }
}: TodoEditConfirmationPopupProps) => {
  const { radioValue, onChange } = useRadioBtn(POPUP_RADIO_VALUES.current);

  const onCloseWrapper = useCallback(() => {
    closeModal();
    if(onCloseAction) {
      onCloseAction();
    }
  }, [closeModal, onCloseAction]);

  const onClick = useCallback(async() => {
    closeModal();
    onCloseWrapper();
    onConfirm({
      ...formData,
      withRecurring: radioValue === POPUP_RADIO_VALUES.all
    });
  }, [closeModal, onConfirm, formData, radioValue, onCloseWrapper]);

  return (
    <ConfirmationPopup
      confirmLabel={ 'Apply' }
      isOpen={ true }
      message={ 'What tasks do you want to change?' }
      onCancelClick={ onCloseWrapper }
      onClose={ onCloseWrapper }
      onConfirmClick={ onClick }
      type={ POPUP_TYPES.CONFIRM }
    >
      <Wrapper>
        {/*@ts-ignore TS migrate*/}
        <RadioGroup
          onChange={ onChange }
          options={ RADIO_BUTTONS }
          value={ radioValue }
        />
      </Wrapper>
    </ConfirmationPopup>
  );
});

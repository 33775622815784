import { Envelope, ListSetup } from '@/assets';

import { ListItemTabs } from '@pages/NewContacts/subpages/Lists/subpages/ListsDetails/types';

import { LISTS_MANUAL_TABS } from '@constants/routes/contacts';
import { LISTS_TYPES } from '@constants/lists';

import { getListItemLink } from '@/shared/utils/listsRoutesUtils';

export const MANUAL_LIST_TABS: Array<ListItemTabs> = [
  {
    getLink: (id) => getListItemLink(LISTS_TYPES.Manual, id)!.contacts,
    icon: Envelope,
    label: 'List Contacts',
    value: LISTS_MANUAL_TABS.contacts,
  },
  {
    getLink: (id) => getListItemLink(LISTS_TYPES.Manual, id)!.setup,
    icon: ListSetup,
    label: 'List Setup',
    value: LISTS_MANUAL_TABS.setup,
  },
];
import React from 'react';

import { Container, Tab, Text } from './styles';

function TabBar({ contactTypes, onChange, selectedType }) {
  return (
    <Container>
      {contactTypes.map(({ label, value }) => (
        <Tab 
          key={ value } 
          onClick={ () => onChange(value) } 
          selected={ value === selectedType }
        >
          <Text>{label}</Text>
        </Tab>
      ))}
    </Container>
  );
}

export default TabBar;

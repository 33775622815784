import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { useMoveListStore } from './moveListStore';

import { FolderPlus } from '@/assets';

import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';
import Preloader from '@components/Preloader';
import { Items, Item } from './componets';

import { MoveListToAnotherFolderProps } from './types';

import { ContentWrapper, HeaderWrapper, ItemsWrapper, ScrollTarget } from './styles';

import { NEW_FOLDER_FRONT_ID_KEY, NEW_FOLDER_STATIC_INDEX } from '@constants/lists';

export const MoveListToAnotherFolder = observer(({
  closeModal,
  modalProps
}: MoveListToAnotherFolderProps) => {
  const { listItem, onSave } = modalProps;

  const moveListStore = useMoveListStore();
  const {
    editIndex,
    getSaveData,
    isLoad,
    onBlurAccept,
    onFolderAdd,
    resetStore,
    selectedIndex,
    setEditIndex,
    setScrollTargetRef,
    setSelectedIndex,
    treeItems,
    treeRoot,
  } = moveListStore;

  const onSaveClick = useCallback(() => {
    const { newFolders, saveTarget } = getSaveData();
    onSave({
      closeModal,
      newFolders,
      saveTarget,
      listItem,
    });
  }, [closeModal, getSaveData, listItem, onSave]);

  useEffect(() => {
    moveListStore.init();
    return () => resetStore();
  }, [moveListStore, resetStore]);

  return (
    <Modal
      isCloseDisabled={ isLoad }
      onClose={ closeModal }
      title={ 'Move List' }
    >
      <Modal.Content width={ '800px' }>
        <ContentWrapper>
          {
            isLoad && <Preloader isWithoutFullPageWrapper={ true }/>
          }
          {
            !isLoad &&
            <>
              <HeaderWrapper>
                Where do you want to move this list to?
              </HeaderWrapper>
              <ItemsWrapper>
                <Item
                  $isActive={ selectedIndex === treeRoot.id }
                  $level={ 0 }
                  editIndex={ editIndex }
                  item={ treeRoot }
                  listItem={ listItem }
                  onBlurAccept={ onBlurAccept }
                  setEditIndex={ setEditIndex }
                  setSelectedIndex={ setSelectedIndex }
                />
                {
                  treeItems.map(item => (
                    <Items
                      $isActive={
                        NEW_FOLDER_FRONT_ID_KEY in item
                          ? item[NEW_FOLDER_FRONT_ID_KEY] === selectedIndex
                          : selectedIndex === item.id
                      }
                      $level={ 1 }
                      editIndex={ editIndex }
                      item={ item }
                      key={ item.name }
                      listItem={ listItem }
                      onBlurAccept={ onBlurAccept }
                      setEditIndex={ setEditIndex }
                      setSelectedIndex={ setSelectedIndex }
                    />
                  ))
                }
              </ItemsWrapper>
              <ScrollTarget ref={ setScrollTargetRef }/>
            </>
          }
        </ContentWrapper>
      </Modal.Content>
      <Modal.Footer paddingLeft={ '25px !important' }>
        <PopupSingleActionButton
          Icon={ FolderPlus }
          disabled={ isLoad || editIndex !== NEW_FOLDER_STATIC_INDEX }
          onClick={ onFolderAdd }
          type={ POPUP_BUTTON_TYPES.done }
        >
          New Folder
        </PopupSingleActionButton>
        <PopupActionsButtons
          CancelProps={ {
            disabled: isLoad
          } }
          SaveProps={ {
            disabled: isLoad || editIndex !== NEW_FOLDER_STATIC_INDEX || selectedIndex === NEW_FOLDER_STATIC_INDEX
          } }
          onCancelClick={ closeModal }
          onSaveClick={ onSaveClick }
          saveLabel={ 'Move' }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
});

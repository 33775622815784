import React, { useState, useLayoutEffect, useCallback } from 'react';
import * as workerTimers from 'worker-timers';
import { useStore } from '@store';

import { ROUTES } from '@constants/routes';

import ConfirmationPopup from '@modules/ConfirmationPopup';
import { POPUP_TYPES } from '@modules/ConfirmationPopup';

import { continueSession } from '@services/api/common/common';

import { LogoutPopupProps } from './types';
import {
  MessageWrapper,
  SecondsWrapper,
  TimerValueWrapper,
  TitleWrapper,
  Wrapper,
} from './styles';

export const LogoutPopupWithTimer = ({
  closeModal
}: LogoutPopupProps) => {
  const { ModalStore, RouterStore, SessionStore, AuthStore } = useStore();

  const [ timerValue, setTimerValue ] = useState<number | string>('');

  const message = 'Please click “Continue” to keep working or click “Log off” to end your session now.';

  const onLogOffHandler = useCallback(() => {
    closeModal();
    ModalStore.closeAllModals();
    RouterStore.customPush(ROUTES.login);
    AuthStore.workerLogout();
  },[ModalStore, AuthStore]);

  const onContinueClick  = async() => {
    closeModal();
    await continueSession(AuthStore.token);
    SessionStore.startSession();
  };

  useLayoutEffect(() => {
    let timerId = workerTimers.setTimeout(function timer() {
      const expiredTime = SessionStore.getExpiredTime() - Date.now();
      setTimerValue(Math.floor(expiredTime / 1000));

      if (typeof timerValue === 'number' && timerValue <= 0) {
        onLogOffHandler();
      } else {
        setTimerValue(v => Number(v) - 1);
      }
      timerId = workerTimers.setTimeout(timer, 1000);
    }, 1000);

    return () => {
      workerTimers.clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'Log Off' }
      confirmLabel={ 'Continue' }
      isOpen={ true }
      onCancelClick={ onLogOffHandler }
      onClose={ onContinueClick }
      onConfirmClick={ onContinueClick }
      type={ POPUP_TYPES.TIMER }
    >
      {
        <Wrapper>
          <TitleWrapper>Your online session will expire in:</TitleWrapper>
          <TimerValueWrapper>{ timerValue }</TimerValueWrapper>
          <SecondsWrapper>Seconds</SecondsWrapper>
          <MessageWrapper>{ message }</MessageWrapper>
        </Wrapper>
      }
    </ConfirmationPopup>

  );
};

import { Client, IdType, MapTypeNamesToValues } from '@/shared/types/commonTypes';
import { CloseModal } from '@/shared/types/commonTypes';

export type DataItemModalProps = {
  id: number,
  firstName: string,
  lastName: string,
};

export type DeleteUserPopupProps = {
  closeModal: CloseModal,
  modalProps: {
    dataItem: DataItemModalProps,
  }
};

export enum DeleteUserOption {
  Replace = 'Delete user and replace all contacts, tasks, etc. with another user',
  Unassigned = 'Delete user and leave contacts, tasks, etc. unassigned',
}

export enum PopupSteps {
  Choosing = 'Choosing',
  Replacing = 'Replacing',
  UnassignedAndSave = 'UnassignedAndSave',
  ReplacingAndSave = 'ReplacingAndSave',
}

export type FieldNamesDeletePopup = {
  id: IdType,
  firstName: string,
  lastName: string,
  deleteOption: DeleteUserOption,
  replacingUser: Client | null,
};

export type UserDeletePopupInputNamesType = MapTypeNamesToValues<FieldNamesDeletePopup>;

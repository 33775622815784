

export const convertObjectWithNumericKeysToArray = <T>(obj: any, converter?: (item: any) => any) => {
  return Object.entries(obj).reduce((acc: Array<T>, [ key, item ]) => {
    if (isNaN(parseInt(key))) {
      return acc;
    }

    if(converter){
      acc.push(converter(item as T));
    } else {
      acc.push(item as T);
    }

    return acc;
  }, []);
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntersectionObserver } from '@customHooks';

import Preloader from '@components/Preloader';
import BlockButton from '@components/BlockButton';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { ViewAllProps } from './types';

import { PaperProps } from '@material-ui/core';

import { PreloaderWrapper, OuterWrapper, Trigger, ContentWrapper } from './styles';


const STYLE = {
  minWidth: 'auto'
};

const ANCHOR_ORIGIN = {
  vertical: 25,
  horizontal: 'right' as 'right',
};

const TRANSFORM_ORIGIN = {
  vertical: 'top' as 'top',
  horizontal: 'right' as 'right',
};

const POPER_PROPS: PaperProps = {
  style: {
    display: 'flex',
  }
};

export const ViewAll = ({
  buttonLabel = 'View All',
  children,
  disabled,
  interSectionOptions = {},
  isLoad,
  isRenderTrigger,
  onTriggerIntersection,
}: ViewAllProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  const [triggerRef, setTriggerRef] = useState<Element | null>(null);
  const rootRef = useRef<HTMLDivElement | null>(null);

  const onTriggerRefChange = useCallback((node: HTMLDivElement) =>{
    setTriggerRef(node);
  }, []);

  const handleClick = useCallback((e: any) => {
    setAnchorEl(e.target);
  }, []);

  const handleClickAway = useCallback(() =>{
    setAnchorEl(null);
  }, []);

  useIntersectionObserver({
    ref: triggerRef,
    options: {
      ...interSectionOptions,
      root: rootRef.current
    },
    onIn: onTriggerIntersection,
  });

  return (
    <ClickAwayListener
      disableReactTree={ true }
      onClickAway={ handleClickAway }
    >
      <div >
        <BlockButton
          disabled={ disabled }
          onClick={ handleClick }
          style={ STYLE }
        >
          { buttonLabel }
        </BlockButton>
        {
          anchorEl &&
          <Popover
            PaperProps={ POPER_PROPS }
            anchorEl={ anchorEl }
            anchorOrigin={ ANCHOR_ORIGIN }
            id="mouse-over-popover"
            open={ Boolean(anchorEl) }
            transformOrigin={ TRANSFORM_ORIGIN }
          >
            <OuterWrapper>
              {
                isLoad &&
                <PreloaderWrapper>
                  <Preloader isWithoutFullPageWrapper={ true }/>
                </PreloaderWrapper>
              }
              <ContentWrapper
                $preventPointerEvents={ isLoad }
                ref={ rootRef }
              >
                { children }
                {
                  isRenderTrigger && <Trigger ref={ onTriggerRefChange }/>
                }
              </ContentWrapper>
            </OuterWrapper>
          </Popover>
        }
      </div>
    </ClickAwayListener>
  );
};

import * as yup from 'yup';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateAfter, isDateBefore } from '@/shared/utils/dateValidationUtils';


import { dateOutputValidation } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

import {
  OCCUPATIONS_FIELDS_NAMES
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components/Occupations/data';

export const OCCUPATION_SCHEMA = yup.object().shape({
  [OCCUPATIONS_FIELDS_NAMES.companyName]: yup.string()
    .nullable(),
  [OCCUPATIONS_FIELDS_NAMES.end]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or more than start',
      validationMessages.invalidDate,
      function (value, context){
        return isDateAfter({
          dateStringToCompare: context.parent.start,
          dateString: value,
          withEqual: true,
        });
      }),
  [OCCUPATIONS_FIELDS_NAMES.start]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than Today',
      validationMessages.invalidBirthday,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    ).test(
      'mast be equal or less than end',
      validationMessages.invalidDate,
      function (value, context){
        return isDateBefore({
          dateStringToCompare: context.parent.end,
          dateString: value,
          withEqual: true,
        });
      }),
  [OCCUPATIONS_FIELDS_NAMES.title]: yup.string()
    .nullable()
    .required(validationMessages.required),
});

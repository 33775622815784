export const yupTestMethodValidationTrigger = ({
  prevValues,
  fieldName,
  currentValue,
  triggerFunction
}) => {
  let isTriggerSelfValidation = typeof prevValues[fieldName] === 'boolean'
    && !prevValues[fieldName] && currentValue;

  if (isTriggerSelfValidation && triggerFunction) {
    triggerFunction(fieldName);
  }

  prevValues[fieldName] = currentValue;
};

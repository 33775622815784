import React, { useEffect } from 'react';

import { useWatch } from 'react-hook-form';

import HFTextEditor from '@modules/HookFormComponents/HFTextEditor';

export const NoteText = ({
  names,
  control,
  errors,
  clearErrors,
}) => {
  const noteTextFieldValue = useWatch({
    control: control,
    name: names.noteText
  });

  useEffect(() => {
    if(noteTextFieldValue?.length > 0 && errors?.[names.title]){
      clearErrors(names.title);
    }
  }, [clearErrors, errors, names.title, noteTextFieldValue]);

  return (
    <HFTextEditor
      control={ control }
      name={ names.noteText }
      textEditorProps={ {
        init: {
          height: 240,
          toolbar: 'bold italic strikethrough  | numlist bullist | table',
        }
      } }
    />
  );
};

import * as yup from 'yup';

import { YesNoType } from '@/shared/types/commonTypes';


import { dateOutputValidation } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

import {
  FIELD_NAMES,
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/data';

export const getValidationSchema = (isEditMode: boolean) => {
  return yup.object().shape({
    [FIELD_NAMES.birthdate]: yup.string()
      .nullable()
      .matches(dateOutputValidation, validationMessages.invalidDate)
      .test(
        'mast be equal or less than today',
        validationMessages.invalidDate,
        (value, context) => (
          isDateBefore({
            dateStringToCompare: getFormatedDateNow(),
            dateString: value,
            withEqual: true,
          })
        )
      ),
    [FIELD_NAMES.referralDate]: yup.string()
      .nullable()
      .when(FIELD_NAMES.referral, {
        is: (referral: YesNoType) => referral === 'Yes',
        then: (schema) => schema
          .matches(dateOutputValidation, validationMessages.invalidDate)
      }),
    [FIELD_NAMES.firstName]: yup.string()
      .test('error', validationMessages.required, function (value) {
        const { relativeId } = this.parent;
        if(value || relativeId || !relativeId === null) {
          return true;
        }
        return false;
      }),
    [FIELD_NAMES.lastName]: yup.string()
      .test('error', validationMessages.required, function (value) {
        const { relativeId } = this.parent;
        if(value || relativeId || !relativeId === null) {
          return true;
        }
        return false;
      }),
    [FIELD_NAMES.relativeId]: yup.string()
      .nullable()
      .test('error', validationMessages.required, function (value) {
        const { firstName, lastName } = this.parent;
        const isAtLeastOneFieldFilled = Boolean(firstName?.length || lastName?.length);
        if(value || isAtLeastOneFieldFilled) {
          return true;
        }
        return false;
      }),
  },[[FIELD_NAMES.firstName, FIELD_NAMES.relativeId], [FIELD_NAMES.lastName, FIELD_NAMES.relativeId]]);
};

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { ActivityAndTaskInfo, BusinessSnapshot } from './components';
import { AppPage } from '@modules/AppPage';
import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';

export const Dashboard = observer(() => {

  const store = useStore().DashboardStore;

  const { init, isFetching, reset } = store;

  useEffect(() => {
    init();
    return reset;
  }, [store]);

  return (
    <AppPage>
      <AppPage.AppHeader>
        <AppPage.AppPageTitle title={ 'Dashboard' }/>
      </AppPage.AppHeader>
      <Surface>
        <Surface.Content>
          {
            isFetching && <Preloader />
          }
          <ActivityAndTaskInfo/>
          <BusinessSnapshot/>
        </Surface.Content>
      </Surface>
    </AppPage>
  );
});

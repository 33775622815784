import React, { useEffect } from 'react';

import { useActivityPopupLocalStore } from '@/modules/ActivityPopup/store';

import { POPUP_RADIO_VALUES } from '@constants/common';

import RadioGroup, { useRadioBtn } from '@components/RadioGroup';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';

import { RADIO_BUTTONS } from './data';

import { Wrapper } from './styles';
import { EditActivityConfirmPopupProps } from './types';

export const EditActivityConfirmPopup = ({
  closeModal,
  modalProps: {
    formData,
    onConfirm
  }
}: EditActivityConfirmPopupProps) => {
  const { radioValue, onChange } = useRadioBtn(POPUP_RADIO_VALUES.current);

  const onClick = () => {
    const data = {
      ...formData,
      withRecurring: radioValue === POPUP_RADIO_VALUES.all
    };
    closeModal();
    onConfirm(data);
  };

  return (
    <ConfirmationPopup
      confirmLabel={ 'Apply' }
      isOpen={ true }
      message={ 'What Activity do you want to change?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onClick }
      type={ POPUP_TYPES.CONFIRM }
    >
      <Wrapper>
        {/*@ts-ignore TS migrate*/}
        <RadioGroup
          onChange={ onChange }
          options={ RADIO_BUTTONS }
          value={ radioValue }
        />
      </Wrapper>
    </ConfirmationPopup>
  );
};

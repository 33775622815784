import React, { useCallback } from 'react';

import { useStore } from '@store';
import { observer } from 'mobx-react';

import PageHeaderWrapper from '@components/BasicLayoutComponents/PageHeaderWrapper';

import FilterButton from '@components/FilterButton';

import { ActionButtonWrapper, ButtonsWrapper } from './styles';

export const PageHeader = observer(({
  isFiltersBtnActive,
  onFiltersClick
}) => {
  const accountsAndPlansStore = useStore().AccountsAndPlansStore;
  const { selectedFiltersCount } = accountsAndPlansStore;

  const onClearClick = useCallback(() => {
    accountsAndPlansStore.resetFilters();
  }, [ accountsAndPlansStore ]);

  return (
    <PageHeaderWrapper>
      <ButtonsWrapper>
        <ActionButtonWrapper>
          {/*Actions Todo*/}
        </ActionButtonWrapper>
        <FilterButton
          badgeClickHandler={ onClearClick }
          badgeCount={ selectedFiltersCount }
          buttonCLickHandler={ onFiltersClick }
          enableFilterMode={ !isFiltersBtnActive }
        />
      </ButtonsWrapper>
    </PageHeaderWrapper>
  );
});

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { NOTES_LINKED_ITEMS_IDS, NOTES_LINKED_ITEMS_TYPES_SCHEME } from '@constants/overview';

import { ContactItem } from '@/shared/types/contact';
import { ContactNote, ContactNoteData } from '@/shared/types/note';
import { IdType, ValueOf } from '@/shared/types/commonTypes';
import { SchemeType } from '@/shared/types/contactOverview';

export const notesNormalized = (data: ContactNoteData, contact: any): Array<ContactNote> => {
  const notes: Array<ContactNote> = [];
  Object.values(data).map(entityNotes => notes.push(...noteConverter(entityNotes, contact)));
  return notes;
};

export const noteConverter = (
  notes: Array<ContactNote>,
  contact: ContactItem,
): Array<ContactNote> => {
  return notes.map((note) => {
    let type: SchemeType | undefined = undefined;
    
    for (const [key, value] of Object.entries(note)) {
      if(!type) {
        type = Object.values(NOTES_LINKED_ITEMS_TYPES_SCHEME).find(scheme => scheme.id === key && value)?.type;
      }

      if(Object.values(NOTES_LINKED_ITEMS_IDS).includes(key as ValueOf<typeof NOTES_LINKED_ITEMS_IDS>) && value) {
        note.linkedItemId = value as IdType;
        note.linkedItem = {
          //@ts-ignore
          linkedContactType: type,
          contactData: contact,
        };
        if(note.linkedItem && note.linkedItem.linkedContactType === LINKED_ITEM_TYPES.opportunity) {
          note.linkedItem.opportunityStatus = note.opportunityStatus;
        }

        delete note[key as ValueOf<typeof NOTES_LINKED_ITEMS_IDS>];
      }
    }

    if(type) {
      note.type = type;
      if(note.linkedItem) {
        note.linkedItem.linkedContactType = type;
      }  
    }
    return note;
  });
};

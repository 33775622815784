import { PopoverOrigin } from '@material-ui/core';

export const transformOriginProps: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const anchorOriginProps: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const paperProps =  {
  style: {
    marginTop: '5px',
    width: '150px',
    borderRadius: '5px'
  }
};

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
import { ImageStyledProps } from './types';

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;

  & > div > div {
    margin-bottom: 20px;

    & > div {
      width: 100%;
    }
  }
  & > div > div:last-child {
    margin-bottom: 0px;
  }
`;

export const ContactImage = styled.img<ImageStyledProps>`
  ${({ size }) => {
    if(size === 'small') {
      return css`
        width: 120px;
        height: 120px;
      `;
    }
    if(size === 'medium') {
      return css`
        width: 170px;
        height: 150px;
      `;
    }
  }};

  border-radius: 6px;
  margin-right: 20px;
  object-fit: cover;
`;

export const ContactSpouse = styled.div<ImageStyledProps>`
  position: relative;

  ${({ size }) => {
    if(size === 'small') {
      return css`
        width: 120px;
        height: 120px;
        padding: 10px;
      `;
    }
    if(size === 'medium') {
      return css`
        width: 170px;
        height: 150px;
        padding: 15px;
      `;
    }
  }};

  border-radius: 6px;
  border: 1px solid ${COLORS.borders};
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
`;

export const SpouseText = styled.div`
  line-height: 150%;
  font-size: 12px;
  color: ${COLORS.helpText};
`;

export const StyledSpouseImage = styled.img<ImageStyledProps>`
  ${({ size }) => {
    if(size === 'small') {
      return css`
        width: 50px;
        height: 50px;
      `;
    }
    if(size === 'medium') {
      return css`
        width: 70px;
        height: 70px;
      `;
    }
  }};

  margin-bottom: 10px;
  border-radius: 50%;
  object-fit: cover;
`;

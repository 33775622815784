import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useAddContactPopupStore } from '@modules/AddContactModal/addContactStore';

import GrowElement from '@components/GrowElement';

import { MODAL_TYPES_TO_HIDE } from '@/shared/constants/modalTypes';

import ModalContent from '@/modules/Modals/components/Modal/ModalContent';
import ModalFooter from '@/modules/Modals/components/Modal/ModalFooter';
import ModalHeader from '@/modules/Modals/components/Modal/ModalHeader';

import { useStore } from '@store';

import { modalFooterPortalId } from './data';
import {
  getPaperStyles,
  ModalDialogWrapper,
  ModalFooterPortal,
} from './styles';

function ModalWrapper({
  closeModal,
  minimized,
  modalContent,
  modalFooter,
  modalProps,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const { data, } = useStore().ModalStore;
  const { setSaveCallback, setCloseCallback, setCurrentUserId } = useAddContactPopupStore();

  const isHiddenModal = Object.keys(data).some(
    (modalType, _index, openModalsArray) =>
      MODAL_TYPES_TO_HIDE.includes(modalType) && openModalsArray.length > 1
  );

  useEffect(() => {
    setSaveCallback(modalProps.saveCallback);
    setCloseCallback(closeModal);
    setCurrentUserId(modalProps.currentUserId);
    // eslint-disable-next-line
  }, []);

  return (
    <ModalDialogWrapper
      $isHiddenModal={ isHiddenModal }
      $isMinimizedModal={ !!minimized }
      PaperProps={ getPaperStyles(modalProps) }
      disableEscapeKeyDown
      fullScreen={ false }
      maxWidth="md"
      open={ isOpen }
    >
      <ModalHeader
        maxWidth={ modalProps.maxWidth }
        minimizeButton={ modalProps.minimizeButton }
        onClose={ closeModal }
        title={ modalProps.title }
      />
      <ModalContent>{modalContent}</ModalContent>
      {
        modalProps.fullHeight && <GrowElement />
      }
      {
        modalFooter
          ? (
            <ModalFooter>{modalFooter}</ModalFooter>
          )
          : (
            <ModalFooterPortal id={ modalFooterPortalId } />
          )}
    </ModalDialogWrapper>
  );
}

export default observer(ModalWrapper);

import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from '@constants/colors';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  position: inherit;

  padding: ${({ $isOpen }) => $isOpen ?  ' 16px 20px 0' : ' 18px 16px 0'}px;
`;
export const LogoFullWrapper = styled.div`
  margin-right: auto;
  margin-top: -6px;
`;
export const LogoSmallWrapper = styled.div`
  cursor: pointer;
`;

export const ToggleSizeButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${COLORS.menuCollapseBck};
  
    & .MuiIconButton-label {
      width: inherit;
      height: inherit;
      
      & > svg > path {
        fill: ${COLORS.white};
      }
    }
`;

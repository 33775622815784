import { IndividualContactItem } from '@/shared/types/contact';

import { getContactName } from '@/shared/utils/getContactName';
export const getContactNameWithNickname = (contact: IndividualContactItem) => {
  if(!contact) {
    return '';
  }

  const currentContact = contact as IndividualContactItem;
  const nickname = currentContact?.nickname
    ? `(“${currentContact.nickname}”)`
    : '';

  return `${getContactName(contact)} ${nickname}`;
};



import * as yup from 'yup';

import { FORM_FIELD_NAMES } from './data';
import { validationMessages } from '@constants/validationMessages';

export const validationSchema = yup.object().shape({
  [FORM_FIELD_NAMES.email]: yup.string()
    .email(validationMessages.invalid)
    .required(validationMessages.invalidEmail),
});

import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

export const Wrapper = styled.div`
  width: 80%;
  height: calc(100vh - 20px);
  margin: 20px auto 0px;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  width: 350px;
  margin: auto;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  width: max-content;
  margin-bottom: 52px;
  font-size: 40px;
  text-align: center;
  color: ${COLORS.primaryText};
`;

export const Label = styled.div`
  width: inherit;
  padding-bottom: 6px;
  font-size: 12px;
  line-height: 150%;
  text-align: left;
  color: ${COLORS.primaryText};
`;

import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import BlockButton from '@components/BlockButton';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import { PlusWithCircle } from '@/assets';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';

import { CustomFieldPopup, CustomFieldRow, DeletePopup } from './components';

import { COLUMNS, LINK_NEW_CUSTOM_FIELD } from './data';
import { USER_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsUserCustomFields';
import { MODAL_TYPE } from '@constants/modalTypes';

import {
  TabContentWrapper
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import { CommonCustomFieldsProps, EditData } from './types';
import { CustomFieldItem } from '@services/store/contactCustomFields/types';


const buttonsStyle = {
  marginLeft: 'auto',
};

export const CommonCustomFields = observer(({ contactId }: CommonCustomFieldsProps) => {
  const { isLoad } = useStore().ContactDetailsStore;
  const customFieldStore = useStore().ContactCustomFields;
  const modalStore = useStore().ModalStore;
  const { table, customFieldsSettings } = customFieldStore;

  const onDelete = useCallback((item: CustomFieldItem) => {
    modalStore.openModal({
      component: DeletePopup,
      modalProps: item,
      modalType: MODAL_TYPE.DELETE_CUSTOM_FIELD_FROM_CONTACT,
    });
  }, [modalStore]);

  const onAddEdit = useCallback((item?: CustomFieldItem) => {
    let data: EditData = item;
    // if we edit DROPDOWN_LIST we need swap value from backend with optionId
    // because we get label at value field not customFieldOptionId
    if(item && item.type === USER_CUSTOM_FIELDS_TYPES.dropDownList){
      const selectedType = customFieldsSettings.find(setting => setting.name === item.name);
      const selectedOption = selectedType!.options.find(option => option.label === item.value);
      data = {
        ...item,
        value: selectedOption!.id as number
      };
    }

    modalStore.openModal({
      component: CustomFieldPopup,
      modalProps: {
        dataToEdit: data
      },
      modalType: MODAL_TYPE.ADD_CONTACT_CUSTOM_FIELD_TO_CONTACT,
    });
  },[modalStore, customFieldsSettings]);

  const onAdd = useCallback(() => onAddEdit(),[onAddEdit]);

  useEffect(() => {
    customFieldStore.initPage(contactId);
    return () => customFieldStore.reset();
    // eslint-disable-next-line
  }, []);

  return (
    <TabContentWrapper isFluid={ true }>
      {
        isLoad &&
        <Preloader/>
      }
      <TabContentWrapper.Item $withPadding={ false }>
        <PreTableWrapper>
          <BlockButton
            onClick={ onAdd }
            startIcon={ <PlusWithCircle/> }
            style={ buttonsStyle }
          >
            {LINK_NEW_CUSTOM_FIELD}
          </BlockButton>
        </PreTableWrapper>
        <Table>
          <Table.Container triggerScrollToTop={ table.currentPage }>
            <Table.Header
              columns={ COLUMNS }
              onSortChangeCb={ table.setMultipleSorting }
              sortingState={ table.multipleSorting }
            />
            <Table.Body>
              {
                table.items.length > 0 &&
                table.items.map(customField => (
                  <CustomFieldRow
                    data={ customField }
                    key={ customField.id }
                    onDelete={ onDelete }
                    onEdit={ onAddEdit }
                  />
                ))
              }
            </Table.Body>
            {
              !isLoad && table.items.length === 0 &&
              <ContentCenter>
                <NoDataItemsView
                  addActionButtonClickHandler={ onAdd }
                  addActionButtonLabel={ LINK_NEW_CUSTOM_FIELD }
                  withAction={ true }
                />
              </ContentCenter>
            }
          </Table.Container>
          <Table.Footer
            currentPage={ table.currentPage }
            onPageChangeCb={ table.setCurrentPage }
            pagesCount={ table.totalPages }
          >
            <Table.FooterCountInfo
              from={ table.itemsFrom }
              to={ table.itemsTo }
              total={ table.totalItems }
            />
          </Table.Footer>
        </Table>
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

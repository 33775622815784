import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { DetectLinkTransitionPopupPropsWithModalStoreProps } from './types';

export const DetectLinkTransitionPopup = ({
  closeModal,
  modalProps,
}: DetectLinkTransitionPopupPropsWithModalStoreProps) => {
  const {
    message,
    children,
    onConfirm,
    onCancel,
  } = modalProps;

  const onConfirmWrapper = useCallback(() => {
    closeModal();
    onConfirm();
  },[closeModal, onConfirm]);

  const onCancelWrapper = useCallback(() => {
    closeModal();
    onCancel();
  },[closeModal, onCancel]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message || 'All entered data will be lost' }
      onCancelClick={ onCancelWrapper }
      onClose={ onCancelWrapper }
      onConfirmClick={ onConfirmWrapper }
    >
      { children }
    </ConfirmationPopup>
  );
};

import React from 'react';

import { Pagination as MaterialPagination } from '@material-ui/lab';

import { PaginationItemStyled } from './styles';

import { PaginationProps, HandleChangeType } from './types';

export const Pagination = ({
  count,
  onHandleChange,
  page,
}: PaginationProps) => {
  const handleChange:HandleChangeType = (e, newPage) => {
    onHandleChange(newPage);  
  };
  
  return (
    <MaterialPagination
      color='primary'
      count={ count === 1 ? 0 : count }
      onChange={ handleChange }
      page={ page }
      renderItem={ (item) => <PaginationItemStyled { ...item } /> }
      shape='rounded'
      siblingCount={ 0 }
      variant='outlined'
    />
  );
};
import { makeStyles } from '@material-ui/core/styles';

import { hexToRgba } from '@/shared/utils/hexToRgba';

const color = hexToRgba('#0A0A0A', 0.75);

export const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: color,
  },
  tooltip: {
    backgroundColor: color,
  },
  tooltipPlacementTop: {
    margin: '4px 0',
  },
  tooltipPlacementLeft: {
    margin: '0 4px ',
  },
  tooltipPlacementRight: {
    margin: '0 4px ' ,
  },
  tooltipPlacementBottom: {
    margin: '4px 0',
  },
}));

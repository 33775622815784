import React, { useCallback } from 'react';

import PopupActionsButtons from '@components/PopupActionsButtons';

import { FooterProps } from './types';


export const Footer = ({
  isFirstStep,
  isLastStep,
  onClose,
  onNext,
  onPrev,
  onSave,
}: FooterProps) => {
  const saveLabel = isLastStep
    ? 'Save'
    : 'Next';

  const cancelLabel = isFirstStep
    ? 'Cancel'
    : 'Back';
  
  const onSaveWrapper = useCallback(() => {
    if(isLastStep){
      onSave();
    } else {
      onNext();
    }
  }, [isLastStep, onNext, onSave]);

  const onCancelWrapper = useCallback(() => {
    if(isFirstStep){
      onClose();
    } else {
      onPrev();
    }
  }, [isFirstStep, onClose, onPrev]);

  return (
    <PopupActionsButtons
      cancelLabel={ cancelLabel }
      onCancelClick={ onCancelWrapper }
      onSaveClick={ onSaveWrapper }
      saveLabel={ saveLabel }
      wrapperProps={ {
        marginLeft: 'auto'
      } }
    />
  );
};

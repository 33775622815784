import {
  CONTACT_RANK_A,
  CONTACT_RANK_B,
  CONTACT_RANK_C,
  CONTACT_RANK_D,
  CONTACT_RANK_F
} from '@constants/common';

export const ACTIVE_RANK_COLORS = {
  [CONTACT_RANK_A.value]: '#1DBD37',
  [CONTACT_RANK_B.value]: '#96D171',
  [CONTACT_RANK_C.value]: '#F2B61A',
  [CONTACT_RANK_D.value]: '#F99501',
  [CONTACT_RANK_F.value]: '#E24141',
};

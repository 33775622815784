import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';

import { getDuration, getStatusName } from './utils';

import { NEXT_APPOINTMENT_COLORS } from '@modules/ContactDetailsSideBarPanel/data';

import {
  Wrapper,
  NextAppointment,
  StyledDate,
  StyledTaskNameAndStatus,
  UserItemWrapper,
} from './styles';

import { HeaderProps } from '@modules/ContactDetailsSideBarPanel/types';

export const Header = observer(({
  onClose,
  onContactNameClick
}: HeaderProps) => {

  const store = useContactDetailsSideBarPanelLocalStore();
  const {
    isLoading,
    contact,
    nextAppointment,
  } = store;
  
  const onClosePanel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const statusColor = useMemo(() => (
    nextAppointment ? NEXT_APPOINTMENT_COLORS.exist : NEXT_APPOINTMENT_COLORS.none
  ), [nextAppointment]);

  const statusName = useMemo(() => getStatusName(nextAppointment), [contact]);

  const duration = useMemo(() => getDuration(nextAppointment), [contact]);

  if(!contact) {
    return null;
  }
  
  return (
    <Wrapper>
      <CustomIconButton
        disabled={ Boolean(isLoading) }
        onClick={ onClosePanel }
        type={ ICON_BUTTON_TYPES.cross }
      />
      <UserItemWrapper onClick={ onContactNameClick }>
        <UserItem
          user={ contact }
        />
      </UserItemWrapper>
      <NextAppointment>
        <StyledTaskNameAndStatus>
          <ListItemWithColorIndicator
            indicatorColor={ statusColor }
            label={ statusName }
          />
          { nextAppointment &&
            <TextWithTooltip
              text={ nextAppointment.name }
            />
          }
        </StyledTaskNameAndStatus>

        { nextAppointment &&
          <StyledDate>
            { duration }
          </StyledDate>
        }
      </NextAppointment>
    </Wrapper>      
  );
});

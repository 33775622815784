/* eslint-disable */
import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: 6
    },
    startIcon: {
        marginRight: 0,
    },
    outlinedPrimary: {
        borderColor: theme.palette.primary.main
    },
    outlinedSecondary: {
        borderColor: theme.palette.secondary.main
    },
    containedPrimary: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    containedSecondary: {
        border: `1px solid ${theme.palette.secondary.main}`
    }
}));

export default function SquareIconButton(props) {

    const classes = useStyles();

    return (
        <Button classes={classes} variant='outlined' color='secondary' {...props}></Button>
    )
}

import React, { ChangeEvent, useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '@store';

import CheckboxDropDown from '@components/CheckboxDropDown';
import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import SearchField from '@components/SearchField';
import SideFilters from '@modules/SideFilters';

import { DropDownFilterDataType } from '@/shared/types/commonTypes';
import { addFilterOptions } from '@/shared/utils/filterUtils';

import { OPPORTUNITY_FILTER_NAMES } from '@constants/salesCycleOpportunity';
import {
  USERS,
  MEETING_STATUS,
  OPPORTUNITY_STATUS,
  SALE_TYPE
} from './data';


export const SalesClientOpportunitiesFilters = observer(() => {
  const { isLoad, SalesClientOpportunitiesTable } = useStore().SalesCycleStore;
  const {
    resetFilters,
    filtersCount,
    filterData,
    filters,
    setFilterState
  } = SalesClientOpportunitiesTable;

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      USERS,
      filterData[OPPORTUNITY_FILTER_NAMES.users]
    )
  ), [filterData]);

  const saleTypeDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      SALE_TYPE,
      filterData[OPPORTUNITY_FILTER_NAMES.saleType]
    )
  ), [filterData]);

  const opportunityStatusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      OPPORTUNITY_STATUS,
      filterData[OPPORTUNITY_FILTER_NAMES.opportunityStatus]
    )
  ), [filterData]);

  const meetingStatusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      MEETING_STATUS,
      filterData[OPPORTUNITY_FILTER_NAMES.meetingStatus]
    )
  ), [filterData]);

  const onSearchChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFilterState(OPPORTUNITY_FILTER_NAMES.search, value);
  }, [setFilterState]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    setFilterState(fieldName, newValue);
  }, [setFilterState]);

  return (
    <SideFilters
      count={ filtersCount }
      isNonePointerEvents={ isLoad }
      onClearClick={ resetFilters }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onSearchChange }
          placeholder={ 'Search Contacts'  }
          value={ filters[OPPORTUNITY_FILTER_NAMES.search] }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[OPPORTUNITY_FILTER_NAMES.users] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ saleTypeDropDownData.label }
          name={ saleTypeDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[OPPORTUNITY_FILTER_NAMES.saleType] }
          values={ saleTypeDropDownData.values }
        />

        <CheckboxDropDown
          label={ opportunityStatusDropDownData.label }
          name={ opportunityStatusDropDownData.name }
          renderItemLabel={ (label, value) => (
            <SalesClientOpportunitiesStatusOption
              label={ label }
              value={ value }
            />
          ) }
          setState={ onChangeDrop }
          state={ filters[OPPORTUNITY_FILTER_NAMES.opportunityStatus]  }
          values={ opportunityStatusDropDownData.values }
        />
        <CheckboxDropDown
          label={ meetingStatusDropDownData.label }
          name={ meetingStatusDropDownData.name }
          setState={ onChangeDrop }
          state={ filters[OPPORTUNITY_FILTER_NAMES.meetingStatus] }
          values={ meetingStatusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import CustomTabs from '@components/CustomTabs';

import { COLORS } from '@constants/colors';
import { CONTACT_DETAILS_SIDE_BAR_PANEL_TABS } from '@modules/ContactDetailsSideBarPanel/data';
import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';

const CUSTOM_TABS_STYLE_PROPS = {
  style: {
    background: COLORS.background,
    minHeight: '64px',
  }
};

export const Tabs = observer(() => {
  const store = useContactDetailsSideBarPanelLocalStore();
  const {
    currentTab,
    setCurrentTab,
  } = store;
  
  const handleChange = useCallback((e: any, value: string) => {
    setCurrentTab(value);
  }, [setCurrentTab]);

  return (
    <CustomTabs
      $height={ '64px' }
      onChange={ handleChange }
      value={ currentTab }
      { ...CUSTOM_TABS_STYLE_PROPS }
    >
      {
        CONTACT_DETAILS_SIDE_BAR_PANEL_TABS.map((tab) => (
          <CustomTabs.Tab
            $fontSize={ '16px' }
            $minHeight={ '64px' }
            key={ tab.value }
            label={ tab.label }
            replace={ true }
            style={ {
              width: 206
            } }
            value={ tab.value }
          />
        ))
      }
    </CustomTabs>
  );
});

import React from 'react';

import FormBlock from '@components/FormBlock';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import InfoIcon, { INFO_ICON_TYPES } from '@components/InfoIcon';

import { InfoIconWrapper } from './styles';

import {  SPLIT_HOUSEHOLD_TEXT } from '@constants/lists';


import { SplitToggleProps } from './types';

const WrappedInfoIcon = () => (
  <InfoIconWrapper>
    <InfoIcon
      helpText={ SPLIT_HOUSEHOLD_TEXT }
      type={ INFO_ICON_TYPES.alertInfo }
    />
  </InfoIconWrapper>
);

export const SplitToggle = ({
  control,
  label = 'Split Household',
  labelWidth,
  name
}: SplitToggleProps) => (
  <FormBlock.FormField
    $labelWidth={ labelWidth }
    label={ label }
    renderLabelContent={ WrappedInfoIcon }
  >
    <HFToggle
      control={ control }
      labelPlacement={ 'end' }
      name={ name }
      valueInConverter={ Boolean }
      valueOutConverter={ Number }
    />
  </FormBlock.FormField>
);

import { isBefore, isEqual, isValid, parse, startOfDay } from 'date-fns';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';
import { COLORS } from '@constants/colors';

export const getDueDateColor = (dateValue: string | null) => {
  if(!dateValue){
    return  null;
  }
  const today = startOfDay(new Date());
  const parsedDate = parse(dateValue, YEAR_MONTH_DAY, today);

  if(!isValid(parsedDate)){
    return null;
  }

  const dateEqualToday = isEqual(parsedDate, today);

  if(isBefore(parsedDate, today) && !dateEqualToday){
    return COLORS.red;
  }

  if(dateEqualToday){
    return COLORS.blue;
  }

  return null;
};

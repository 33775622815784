import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';

import { Content } from './components';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';

import { getDefaultState } from './utils';
import { getValidationSchema } from './validationSchema';

import { EmployeeAddEditPopupProps } from './types';
import { EmployeeOwnFields } from '@/shared/types/employee';
import { LinkedContactIndividual } from '@/shared/types/linkedContact';

export const EmployeeAddEditPopup = ({
  closeModal,
  modalProps,
}: EmployeeAddEditPopupProps) => {
  const { currentContact } = useStore().ContactDetailsStore;
  const { deleteEmployeeWithoutUpdatePage, saveEmployees } = useStore().EmployeeStore;
  
  const { dataToEdit } = modalProps;
  const isEditMode = Boolean(dataToEdit);

  const [ existingContact, setExistingContact ] = useState<LinkedContactIndividual | null>(dataToEdit?.contact || null);

  const title = `${isEditMode ? 'Edit' : 'Add New'} Employee/Contact`;
  
  const methods = useForm<EmployeeOwnFields>({
    defaultValues: getDefaultState(dataToEdit),
    resolver: yupResolver(getValidationSchema(isEditMode)),
  });

  const { handleSubmit } = methods;

  const onSave = useCallback(() => {
    handleSubmit(async (data: EmployeeOwnFields) => {
      if(existingContact) {
        data.firstName = existingContact.firstName;
        data.lastName = existingContact.lastName;
      }
      closeModal();

      if(dataToEdit && Number(dataToEdit.employeeId) !== Number(data.employeeId)) {
        data.id = '';
        await deleteEmployeeWithoutUpdatePage(dataToEdit.id);
      }  
      saveEmployees(data);
    })();
  }, [closeModal, handleSubmit, deleteEmployeeWithoutUpdatePage, existingContact, saveEmployees, dataToEdit]);

  return (
    <Modal
      onClose={ closeModal }
      title={ title }
    >
      <Modal.Content>
        <FormProvider { ...methods }>
          <Content
            currentContact={ currentContact }
            existingContact={ existingContact }
            setExistingContact={ setExistingContact }
          />
        </FormProvider>
      </Modal.Content>
      <Modal.Footer>
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onSave }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Surface from '@components/BasicLayoutComponents/Surface';

import {
  CustomFieldsAndTagsTabs,
  UserCustomFields,
  ManageTags,
  ContactCustomFields,
} from './components';

import {
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_USER_CUSTOM_FIELDS_PATH,
  USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_MANAGE_TAGS_PATH,
} from '@constants/routes/userSettings';

export const CustomFieldsAndTags = () => (
  <Surface.Content $flexDirection={ 'column' }>
    <CustomFieldsAndTagsTabs/>
    <Surface.Main
      borderRadius={ '0px 10px 10px 10px' }
      height={ '1px' }
    >
      <Switch>
        <Route path={ USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_CONTACT_CUSTOM_FIELDS_PATH }>
          <ContactCustomFields />
        </Route>
        <Route path={ USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_USER_CUSTOM_FIELDS_PATH }>
          <UserCustomFields />
        </Route>
        <Route path={ USER_SETTINGS_CUSTOM_FIELDS_AND_TAGS_MANAGE_TAGS_PATH }>
          <ManageTags />
        </Route>
      </Switch>
    </Surface.Main>
  </Surface.Content>
);

import styled from 'styled-components';
import { COLORS } from '@constants/colors';
import { hexToRgba } from '@/shared/utils/hexToRgba';

import { CreatedDateWrapperProps } from './types';

export const RequrringWrapper = styled.div`
  padding-left: 123px;
`;

export const LeftColumnWrapper = styled.div`
  width: 333px;
  margin-right: auto;
`;

export const RightColumnWrapper = styled.div`
  width: 322px;
`;

export const DateWrapper = styled.div`
  position: relative;
`;

export const InfoIconWrapper = styled.div`
  width: auto;
  position: absolute;
  content: '';
  top: 5px;
  right: -30px;
`;

export const CreatedDateWrapper = styled.div<CreatedDateWrapperProps>`
  color: ${({ $disabled }) => $disabled ? hexToRgba(COLORS.helpText, 0.8) : COLORS.header };
`;
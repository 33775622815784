import React from 'react';

import FormBlock from '@components/FormBlock';

import { notificationMessage } from './data';

import { AlertIcon, Content, NotificationMessage } from './styles';


export const Notification = () => {
  return (
    <FormBlock.RowWrapper marginBottom={ '15px' }>
      <Content>
        <AlertIcon />
        <NotificationMessage>
          <b>NOTE:</b> { notificationMessage }
        </NotificationMessage>
      </Content>
    </FormBlock.RowWrapper>

  );
};

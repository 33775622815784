import React from 'react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import TextWithTooltip from '@components/TextWithTooltip';

import { LabelWrapper, WrapperWithLabel } from './styles';

import { EntityProps } from './types';
import { LinkedOpportunity } from '@/shared/types/salesCycleOpportunity';


export const Opportunity = ({ data }: EntityProps<LinkedOpportunity>) => {

  const amountConverted = data?.linkedContactType === LINKED_ITEM_TYPES.opportunity && data.amount ?
    formatNumberToAmount(data.amount) : '-';
  
  return (
    <>
      <WrapperWithLabel>
        <LabelWrapper>Sale Type</LabelWrapper>
        <TextWithTooltip text={ data.saleType || '-' }/>
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Status</LabelWrapper>
        { data.opportunityStatus ?
          <SalesClientOpportunitiesStatusOption
            label={ data.opportunityStatus }
            value={ data.opportunityStatus }
          /> : '-'
        }
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Amount</LabelWrapper>
        <TextWithTooltip text={ amountConverted }/>
      </WrapperWithLabel>        
    </>
  );
};

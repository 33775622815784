import React, { useEffect } from 'react';
import { useStore } from '@store';

import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { AllLists, ListsDetails } from './subpages';


export const Lists = observer(() => {
  const { resetState } = useStore().ListsStore;

  useEffect(() => {
    return resetState;
  }, [resetState]);

  return (
    <Switch>
      <Route
        component={ AllLists }
        exact={ true }
        path={ ROUTES.lists }
      />
      <Route
        exact={ true }
        path={ ROUTES.listsFolder }
      >
        <AllLists isFolderView={ true }  />
      </Route>
      <Route
        component={ ListsDetails }
        exact={ true }
        path={ ROUTES.listsDetails }
      />
    </Switch>
  );
});

import React from 'react';

import {
  StyledFormControlLabel,
  StyledSwitch
} from './styles';

import { CustomToggleProps } from './types';

export const CustomToggle = ({
  LabelProps,
  SwitchProps,
  checked,
  disabled = false,
  label= '',
  $labelMargin,
  $fluid = true,
  labelPlacement = 'start',
  onChange,
  value = 'on',
}: CustomToggleProps) => (
  <StyledFormControlLabel
    $fluid={ $fluid }
    $labelMargin={ $labelMargin }
    checked={ checked }
    control={
      <StyledSwitch
        color={ 'primary' }
        { ...SwitchProps }
      />
    }
    disabled={ disabled }
    label={ label }
    labelPlacement={ labelPlacement }
    onChange={ onChange }
    value={ value }
    { ...LabelProps }
  />
);

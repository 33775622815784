import { IdType, Items } from '@/shared/types/commonTypes';

type Data = Array<IdType> | IdType

export const getDeleteOrRestoreItemsParams = (data: Data) => {
  if(Array.isArray(data)) {
    return data.reduce((acc:Items, id: IdType, index: number) => {
      acc[`ids[${index}]`] = id;
      return acc;
    }, {});
  } else {
    return {
      'ids[0]': data,
    };
  }
};
import {
  ConvertedListContactsFiltersData,
  ListContactItem,
  ListContactsData,
  ListContactsFiltersData
} from '@/shared/types/lists';

import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

export const contactsConverter = (data: ListContactsData) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).reduce((acc: Array<ListContactItem>, [ key, item ]) => {
    if (isNaN(parseInt(key))) {
      return acc;
    }
    acc.push(item as ListContactItem);
    return acc;
  }, []);
};

export const convertsFilterData = (
  data: ListContactsFiltersData,
  currentUserId: number
): ConvertedListContactsFiltersData => {
  return {
    classification: data?.classification ? optionsFromValue(data.classification) : [],
    officePrimaryAdvisor: data?.createdBy ? getSortedUserOption(data.createdBy, currentUserId) : [],
    status:  data?.status ? optionsFromValue(data.status) : [],
    type:  data?.type ? optionsFromValue(data.type) : []
  };
};
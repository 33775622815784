import { COLORS } from '@constants/colors';

export const ACTIVITY_COLORS = {
  main: COLORS.green,
  second: COLORS.greenBg
};

export const TASK_COLORS = {
  main: COLORS.purple,
  second: COLORS.tag
};
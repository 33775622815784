import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFselect from '@modules/HookFormComponents/HFSelect';

import { useStore } from '@store';

import { LABELS_WIDTH } from '@/pages/NewUserSettings/data';
import {
  AccountSettingsInformationInputNames as InputNames
} from '@pages/NewUserSettings/components/Tabs/AccontSettings/data';

import { LeftColumnWrapper, RightColumnWrapper } from '../Layout';

export function Security() {
  const { configData } = useStore().UserSettingsStore;

  const { control } = useFormContext();

  return (     
    <FormBlock marginBottom={ 20 }>
      <FormBlock.Header title={ 'Security' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingLeftLabelWidth }
            label={ 'Automatic Logout' }
          >
            <HFselect
              control={ control }
              name={ InputNames.automaticLogout }
              options={ configData?.automaticLogout }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}
import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const WrapperFilter = styled.div`
  margin-left: 20px;
  
  & > div > button {
    margin-right: 5px;
  }
`;

export const StarIconWrapper = styled.div`
  width: 20px;
  height: 20px;

  & svg path{
      fill: ${COLORS.blue} !important;
    };
  }
`;

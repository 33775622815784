import React, { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { useTableActions } from './useAllListsTableActions';

import Preloader from '@components/Preloader';
import Surface from '@components/BasicLayoutComponents/Surface';
import { AppPage } from '@modules/AppPage';

import { ListsFilters, ListsTable, PreTablePanel, } from './components';

import { AllListsParamsType, AllListsProps } from './types';
import { LISTS_ROOT_NAME, LISTS_TABLE_FILTER_NAMES, LISTS_TYPES } from '@constants/lists';

export const AllLists = observer(({ isFolderView }: AllListsProps) => {

  const store = isFolderView
    ? useStore().ListsStore.FolderStore
    : useStore().ListsStore.AllListsStore;

  const {
    filters,
    filtersCount,
    filtersData,
    isAllListsLoad,
    isFiltersOpen,
    resetFilters,
    setFilterState,
    setTypeFilter,
    table,
    toggleFilterState,
    typeFilter
  } = store;

  const match = useRouteMatch<AllListsParamsType>();

  const setUserFilter = useCallback((value: Array<string>) => {
    setFilterState(LISTS_TABLE_FILTER_NAMES.officePrimaryAdvisor, value);
  }, [setFilterState]);

  const onCloneWrapper = useCallback((id: number) => {
    store.cloneList({
      id
    });
  },[store]);

  const {
    addContactsToList,
    addEditFolder,
    addNewList,
    deleteFolder,
    moveList,
    onDropList,
    onExportList,
    removeLists,
  } = useTableActions(Boolean(isFolderView));

  useEffect(() => {
    store.init(match.params?.folderId);
    return () => store.resetState();
  }, [match.params?.folderId, store]);

  return (
    <AppPage>
      <AppPage.AppHeader>
        <AppPage.AppPageTitle title={ LISTS_ROOT_NAME } />
      </AppPage.AppHeader>
      <Surface>
        {
          isAllListsLoad && <Preloader />
        }
        <Surface.PreHeader
          badgeClickHandler={ resetFilters }
          badgeCount={ filtersCount }
          buttonCLickHandler={ toggleFilterState }
          enableFilterMode={ !isFiltersOpen }
          withFilterButton={ true }
        />
        <Surface.Content $isWrap={ false }>
          <Surface.Main>
            <PreTablePanel
              isFolderAddDisabled={ Boolean(isFolderView) }
              isLoad={ store.isAllListsLoad }
              onFolderAdd={ addEditFolder }
              onListAdd={ addNewList }
              onTypeChange={ setTypeFilter }
              setUserFilter={ setUserFilter }
              table={ table }
              typeFilterValue={ typeFilter?.[LISTS_TABLE_FILTER_NAMES.typeList] || '' }
              userFilterState={ filters.officePrimaryAdvisor }
              userOptions={ filtersData.officePrimaryAdvisor }
            />
            <ListsTable
              isFolderView={ Boolean(isFolderView) }
              onAddContacts={ addContactsToList }
              onClone={ onCloneWrapper }
              onDropList={ onDropList }
              onExport={ onExportList }
              onFolderDelete={ deleteFolder }
              onFolderRename={ addEditFolder }
              onListAdd={ addNewList }
              onMove={ moveList }
              onPageChange={ table.setCurrentPage }
              onRemove={ removeLists }
            />
          </Surface.Main>
          {
            isFiltersOpen &&
              <Surface.Side>
                <ListsFilters
                  filtersData={ filtersData }
                  filtersState={ filters }
                  isLoad={ isAllListsLoad }
                  onClearClick={ resetFilters }
                  selectedFiltersCount={ filtersCount }
                  setFilterState={ setFilterState }
                />
              </Surface.Side>
          }
        </Surface.Content>
      </Surface>
    </AppPage>
  );
});

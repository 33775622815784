import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
  & .MuiButtonBase-root{
    padding: 9px 10px 9px 0 !important;
    min-width: auto;
    margin-right: 10px;
    
    & .MuiButton-label {
      & .MuiButton-startIcon{
        margin-right: 3px;
      }
    }
  }
`;

export const ButtonTitleWrapper = styled.span`
  line-height: 1;
  margin-top: 2px;
`;
import { format } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';
import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';

import { AccountSettingsType } from '@services/store/userSettingsStore/types';

export const settingNormalizer = (data: AccountSettingsType) => {
  const formattedToday = format(new Date, YEAR_MONTH_DAY);
  const { startTime: calendarStartTime, endTime: calendarEndTime } = convertUTCFormatToStartEndDateString({
    startDate: formattedToday,
    startTime: data.calendarStartTime,
    endDate: formattedToday,
    endTime: data.calendarEndTime  
  });
  
  return {
    ...data,
    calendarStartTime,
    calendarEndTime
  };
};

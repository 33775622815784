import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import clsx from 'clsx';

import { v4 as uuidv4 } from 'uuid';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import Tag1 from '@components/Tag1';
import TextWithTooltip from '@components/TextWithTooltip';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

import { tagsTypes } from '@modules/AddContactModal/addContactStore/data/constantData';

import TagSearch from '../FormBlocks/TagsBlock/components/TagSearch';

import { checkIclusion, excludeOption } from './helpers';

// TODO ts migrate
function Tags({ contactTags, tagsToSelect, tagType, setContactTags }) {
  const classes = useStyles();
  const [showAllTags, setShowAllTags] = useState(false);

  const showAllTagsButtonLabel = useMemo(() => showAllTags ? 'Hide All' : 'View All', [showAllTags]);

  const addNewTagHandler = () => {
    const newTag = { label: 'New Tag', value: uuidv4(), type: tagsTypes.new };
    setContactTags([...contactTags, newTag]);
  };

  const addTagHandler = (tag) => {
    if (!checkIclusion(tag, contactTags)) {
      setContactTags([...contactTags, tag]);
    }
  };

  const deleteContactTag = (tag) => {
    setContactTags(excludeOption(tag, contactTags));
  };

  const renameContactTag = (tag, newValue) => {
    const index = contactTags.findIndex((item) => item.value === tag.value);
    if (index !== -1) {
      contactTags[index].label = newValue;
      setContactTags([...contactTags]);
    }
  };

  const selectChangeHandler = (tag) => {
    if (checkIclusion(tag, contactTags)) {
      deleteContactTag(tag);
    } else {
      setContactTags([...contactTags, tag]);
    }
  };

  return (
    <div className={ classes.formFields }>
      <div className={ 'field fullField' }>
        <Typography className={ 'fieldLabel leftFieldLabel' } component='div'>
          {tagType} Tags
        </Typography>
        <TagSearch
          noOptionsText={ 'No results' }
          onChange={ selectChangeHandler }
          options={ tagsToSelect }
          placeholder={ `Start Typing ${tagType} Tag Name...` }
          selectedTags={ contactTags }
        />
      </div>

      <div className={ 'userTags' }>
        <div className={ 'tagArea' }>
          {contactTags.map((tag) => (
            <Tag1
              className={ 'userTag' }
              key={ tag.value }
              onHandleChange={ renameContactTag }
              onHandleDelete={ deleteContactTag }
              tag={ tag }
            />
          ))}

          <div className={ classes.addTag } onClick={ addNewTagHandler }>
            +
          </div>
        </div>

        <Button
          className={ 'viewAll' }
          color='primary'
          endIcon={
            showAllTags ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <AddCircleOutlineIcon />
            )
          }
          onClick={ () => {
            setShowAllTags(!showAllTags);
          } }
          size='medium'
        >
          { showAllTagsButtonLabel }
        </Button>
      </div>

      <hr
        className={ clsx(classes.hr, 'viewAllTagsHr', {
          primary: showAllTags
        }) }
      />

      {showAllTags && (
        <div className={ 'allTagArea' }>
          {tagsToSelect.map((tag) => (
            <div
              className={ clsx(classes.addTag, 'userTag', 'tag2') }
              key={ tag.id }
              onClick={ () => addTagHandler(tag) }
            >
              <TextWithTooltip text={ tag.label || '' } />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default observer(Tags);

import { OptionsAlertType } from './types';
export const LEFT_LABEL_WIDTH =  72;
export const RIGHT_LABEL_WIDTH =  93;

export const OPTIONS_ALERT_TYPES = {
  remove: 'remove',
  edit: 'edit',
} as const;

export const OPTIONS_USE_FIELD_ARRAY_KEY = 'useFieldArrayOptionsKey';

export const OPTIONS_ALERT_MESSAGES: Record<OptionsAlertType, string> = {
  [OPTIONS_ALERT_TYPES.remove]: `'The option is in use with other contacts. 
    If you delete the option, it would be deleted for all the contacts.`,
  [OPTIONS_ALERT_TYPES.edit]: `The option is in use with other contacts. 
    If you change the option name, it will be changed for all the contacts. Do you want to continue?`
};





import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';

import {
  LeftWrapper,
  RightWrapper
} from '@modules/AccountsAndPlansPopup/components/Content/components';

import { DEFAULT_SELECTED, LABEL_SIZES } from '@modules/AccountsAndPlansPopup/data/data';
import {
  BASIC_INFORMATION_FIELDS_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';
import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';
import { FormFieldsValues } from '@modules/AccountsAndPlansPopup/types';

const { leftLabelWidth, rightLabelWidth } = LABEL_SIZES;

export const AdditionalDetails = observer(() => {
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const { popupSettings }  = accountsAndPlansLocalStore;
  const { isNotesPanelInAddOrEditMode } = accountsAndPlansLocalStore.notesUtilsStore;

  const { control } = useFormContext<FormFieldsValues>();

  return (
    <>
      <FormBlock
        marginBottom={ 20 }
        paddingBottom={ 5 }
      >
        <FormBlock.Header title={ 'Additional Details' }/>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Institution Name' }
            >
              <HFTextInput
                inputProps={ {
                  placeholder: 'Enter Institution Name',
                  maxLength: 150,
                } }
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.institutionName }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Registration Type' }
            >
              <HFSelect
                ControllerProps={ {
                  defaultValue: DEFAULT_SELECTED.registrationType
                } }
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.registrationType }
                options={ popupSettings.registrationType }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Account Number' }
            >
              <HFTextInput
                inputProps={ {
                  placeholder: 'Enter Account Number',
                  maxLength: 150,
                } }
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.accountNumber }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Product / Model' }
            >
              <HFTextInput
                inputProps={ {
                  placeholder: 'Enter Product / Model',
                  maxLength: 150,
                } }
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.productModel }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Application Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.applicationDate }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Submitted Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.submittedDate }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Effective Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.effectiveDate }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Delivery Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.deliveryDate }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftWrapper>
            <FormBlock.FormField
              $labelWidth={ leftLabelWidth }
              label={ 'Deadline Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.deadlineDate }
              />
            </FormBlock.FormField>
          </LeftWrapper>
          <RightWrapper>
            <FormBlock.FormField
              $labelWidth={ rightLabelWidth }
              label={ 'Review Date' }
            >
              <HFDatePicker
                control={ control }
                disabled={ isNotesPanelInAddOrEditMode }
                name={ BASIC_INFORMATION_FIELDS_NAMES.reviewDate }
              />
            </FormBlock.FormField>
          </RightWrapper>
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});



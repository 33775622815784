import { TAG_BUTTON_VARIANTS } from '@components/TagButton';

export const COMMON_INPUT_STYLES = {
  maxWidth: '55px',
};

export const COMMON_BUTTON_STYLES = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '120px',
  padding: '3px 10px',
};

export const TAG_TYPES = {
  general: 'general',
  interest: 'interest'
};

export const TAG_BUTTON_COLOR_SCHEME_VARIANTS = {
  [TAG_TYPES.general]: {
    button: TAG_BUTTON_VARIANTS.custom,
    adminButton: TAG_BUTTON_VARIANTS.system
  },
  [TAG_TYPES.interest]: {
    button: TAG_BUTTON_VARIANTS.interest,
    adminButton: TAG_BUTTON_VARIANTS.systemInterest
  }
};
export const TAG_BUTTON_VARIANTS = {
  custom: 'custom',
  system: 'system',
  interest: 'interest',
  systemInterest: 'systemInterest'
} as const;

export const TAG_BUTTON_TYPES = {
  edit: 'edit',
  button: 'button',
  view: 'view'
} as const;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import TextWithoutSpaceReservation from '@components/TextWithoutSpaceReservation';

import { CONTACT_TYPES, RELATION_TYPES } from '@constants/common';
import { getSearchParamsWithName, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getNotRelatedContacts } from '@services/api/relatedContacts/relatedContacts';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';
import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import {
  FIELD_NAMES,
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/data';
import {
  RELATION_OPTIONS_WITHOUT_SPOUSE,
  RELATION_OPTIONS,
  RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE
} from '@constants/relatedContacts';

import {
  DESCRIPTION_INPUT_PROPS,
  DESCRIPTION_TEXTFIELD_PROPS,
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH,
} from './data';

import { ContentWrapper, NamesWrapper, LeftColumnWrapper, RightColumnWrapper } from './styles';

import { ContentProps } from './types';
import { LinkedContactIndividual } from '@/shared/types/linkedContact';
import { RelatedItem } from '@services/store/relatedContactsStore/types';

export const Content = ({
  currentContact,
  dataToEdit,
  existingContact,
  relatedContacts,
  setExistingContact,
}: ContentProps) => {
  const { control, setValue, clearErrors } = useFormContext();

  const linkedContactFetchProps = useMemo(() => {
    return {
      delay: 500,
      getParams: getSearchParamsWithName({ id: currentContact?.id }),
      requestSender: getNotRelatedContacts,
      responseConverter: usersResponseConverter,
    };
  }, [currentContact]);

  const options = useMemo(() => {
    const spouseIndex = relatedContacts.findIndex((item: RelatedItem) => (
      item.relationType === RELATION_TYPES.SPOUSE
    ));

    const isSpouseExist = typeof spouseIndex === 'number' && spouseIndex >= 0;
    const isEditSpouse = Boolean(dataToEdit) &&
      isSpouseExist &&
      relatedContacts[spouseIndex].relativeId  === dataToEdit?.clientContact.id;

    if(isSpouseExist && !isEditSpouse) {
      setValue(FIELD_NAMES.relationType, RELATION_TYPES.OTHER);
      return RELATION_OPTIONS_WITHOUT_SPOUSE;
    }

    if(currentContact?.type === CONTACT_TYPES.HOUSEHOLD ||
      dataToEdit?.clientContact.type !== CONTACT_TYPES.INDIVIDUAL) {
      setValue(FIELD_NAMES.relationType, RELATION_TYPES.OTHER);
      return RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE;
    }

    if(isEditSpouse){
      return RELATION_OPTIONS;
    }

    return RELATION_OPTIONS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[relatedContacts, dataToEdit]);

  const [ referral, relativeId, firstName, lastName, referralDate ] = useWatch({
    control,
    name: [
      FIELD_NAMES.referral,
      FIELD_NAMES.relativeId,
      FIELD_NAMES.firstName,
      FIELD_NAMES.lastName,
      FIELD_NAMES.referralDate
    ]
  });

  useEffect(() => {
    if(referral === 'No'){
      setValue(FIELD_NAMES.referralDate, null);
    }

    if(referral === 'Yes' && !referralDate && referralDate !== ''){
      setValue(FIELD_NAMES.referralDate, getFormatedDateNow());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[referral, setValue]);

  useEffect(() => {
    if(firstName || lastName){
      clearErrors(FIELD_NAMES.relativeId);
    }
  },[firstName, lastName, clearErrors]);

  useEffect(() => {
    if(relativeId){
      clearErrors(FIELD_NAMES.firstName);
      clearErrors(FIELD_NAMES.lastName);
    }
  },[relativeId, clearErrors]);

  const onLinkedContactChange = useCallback((data: LinkedContactIndividual | null) => {
    setExistingContact(data);
  }, [setExistingContact]);

  const isReferralDateDisabled = referral === 'No' || !referral;
  const isNameFieldsDisabled = relativeId;
  const isLinkedContactDisabled = firstName || lastName;

  return (
    <ContentWrapper>
      <FormBlock marginBottom={ 20 }>
        <FormBlock.Header title={ 'Related Contact Info' } />
        <FormBlock.RowWrapper>
          <HFHiddenInput
            control={ control }
            defaultValue={ null }
            name={ FIELD_NAMES.id }
          />
          <FormBlock.FormField
            $isDisabled={ isLinkedContactDisabled }
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Link Existing Record' }
          >
            <HFLinkedContact
              FetchProps={ linkedContactFetchProps }
              UserItemProps={ {
                disabled: false,
              } }
              control={ control }
              disabled={ isLinkedContactDisabled || dataToEdit }
              initLinkedContact={ existingContact }
              name={ FIELD_NAMES.relativeId }
              onLinkedContactChange={ onLinkedContactChange }
              withRemoveButton={ !dataToEdit }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
        <TextWithoutSpaceReservation
          $bottom={ 2 }
          $disabled={ isNameFieldsDisabled || isLinkedContactDisabled }
          text={ 'or' }
        />
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $isDisabled={ isNameFieldsDisabled }
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Add Related Contact' }
          >
            <NamesWrapper>
              <HFTextInput
                control={ control }
                disabled={ isNameFieldsDisabled || dataToEdit }
                name={ FIELD_NAMES.firstName  }
                placeholder={ 'Enter First Name' }
              />
              <HFTextInput
                control={ control }
                disabled={ isNameFieldsDisabled || dataToEdit }
                name={ FIELD_NAMES.lastName }
                placeholder={ 'Enter Last Name' }
              />
            </NamesWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
      <FormBlock isBorderBottom={ false }>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Relation' }
            >
              <HFSelect
                control={ control }
                name={ FIELD_NAMES.relationType }
                options={ options }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'Birth Date' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disableFuture: true
                } }
                control={ control }
                name={ FIELD_NAMES.birthdate }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <LeftColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ LEFT_COLUMN_WIDTH }
              label={ 'Referral' }
            >
              <HFToggle
                control={ control }
                labelForFalse={ 'No' }
                labelForTrue={ 'Yes' }
                labelPlacement={ 'end' }
                name={ FIELD_NAMES.referral }
                valueInConverter={ yesNoToBoolean }
                valueOutConverter={ booleanToYesNo }
              />
            </FormBlock.FormField>
          </LeftColumnWrapper>
          <RightColumnWrapper>
            <FormBlock.FormField
              $labelWidth={ RIGHT_COLUMN_WIDTH }
              label={ 'Referral Date' }
            >
              <HFDatePicker
                DatePickerProps={ {
                  disabled: isReferralDateDisabled
                } }
                control={ control }
                name={ FIELD_NAMES.referralDate }
              />
            </FormBlock.FormField>
          </RightColumnWrapper>
        </FormBlock.RowWrapper>
        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Description' }
          >
            <HFTextInput
              TextFieldProps={ DESCRIPTION_TEXTFIELD_PROPS }
              control={ control }
              inputProps={ DESCRIPTION_INPUT_PROPS }
              name={ FIELD_NAMES.relativeDescription }
            />
          </FormBlock.FormField>
        </FormBlock.RowWrapper>
      </FormBlock>
    </ContentWrapper>);
};

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 20px;
  background-color: ${ COLORS.white };
  border-bottom: 1px solid ${ COLORS.dividingLines };
  
  ${({ $height }) => $height && css`
    height: ${$height};
    min-height: ${$height};
  `}
`;

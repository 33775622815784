import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import BubbleButton from '@components/BubbleButton';
import RowFilter from '@components/RowFilter';

import {
  ACTIVITY_TYPES
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsActivities/data';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { WrapperFilter } from './styles';

import { Item } from '@components/RowFilter/types';
import {
  ContactActivityFiltersNames
} from '@services/store/contactDetailsActivitiesStore/types';

export const TypeFilters = observer(() => {

  const contactDetailsActivitiesStore = useStore().ContactDetailsActivitiesStore;

  const onChange = useCallback((value: Item) => {
    const newValue = value === FILTER_ALL_BTN_LABEL ? null : value.toString();
    contactDetailsActivitiesStore.setTypeFilter({ [ContactActivityFiltersNames.Type]: newValue });
  }, [ contactDetailsActivitiesStore ]);

  return (
    <WrapperFilter>
      <RowFilter
        allButtonLabel={ FILTER_ALL_BTN_LABEL }
        items={ Object.values(ACTIVITY_TYPES) }
        limit={ 7 }
        onChange={ onChange }
        renderItem={ ({
          content,
          isSelected,
          isShowMore,
          onClick,
          indexForKey
        }) => (
          <BubbleButton
            $isActive={ isSelected && !isShowMore }
            $isSecondActiveStyle={ isSelected && isShowMore }
            key={ indexForKey }
            onClick={ onClick }
          >
            {content}
          </BubbleButton>
        ) }
      />
    </WrapperFilter>
  );
});

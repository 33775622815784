import React, { ElementType, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CONTACTS_DATA_FIELD_NAMES } from '@constants/contactsData';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import Tooltip from '@components/Tooltip/Tooltip';

import {
  AuxiliaryHiddenBlock,
  HouseholdWrapper,
  IconWrapper,
  InputWrapper,
  ItemWrapper,
  ToolbarActionsWrapper,
  Wrapper
} from './styles';

import { ToolbarProps } from './types';

export const Toolbar = ({
  $maxWidth,
  children,
  getPrimaryName,
  headOfHouseholdOptions = [],
  icon,
  index,
  isHousehold = false,
  onDeleteClick,
  onPrimaryClick,
  parentName,
  withPrimary = true,
}: ToolbarProps) => {
  const { control } = useFormContext();

  const Icon = icon as ElementType || null;

  const onDeleteWrapper = useCallback(() => {
    onDeleteClick(index);
  }, [index, onDeleteClick]);

  const onPrimaryWrapper = useCallback(() => {
    onPrimaryClick(parentName, index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, onPrimaryClick]);

  return (
    <Wrapper>
      <InputWrapper $maxWidth={ $maxWidth }>
        {children}
      </InputWrapper>
      {
        icon &&
        <IconWrapper>
          <Icon/>
        </IconWrapper>
      }
      {
        <ToolbarActionsWrapper $isBigLeftMargin={ !icon }>
          {
            withPrimary &&
            <Controller
              control={ control }
              name={ getPrimaryName(parentName, index) }
              render={ ({ field, fieldState, formState, }) => {
                const processedValue = typeof field.value === 'string'
                  ? yesNoToBoolean(field.value)
                  : field.value;

                if(processedValue) {
                  return (
                    <ItemWrapper $preventHidden={ true }>
                      <Tooltip title={ 'Primary contact' }>
                        <span>
                          <CustomIconButton
                            $isActive={ processedValue }
                            disabled={ processedValue }
                            onClick={ onPrimaryWrapper }
                            type={ ICON_BUTTON_TYPES.star }
                          />
                        </span>
                      </Tooltip>
                    </ItemWrapper>
                  );
                }
                return (
                  <ItemWrapper >
                    <CustomIconButton
                      $isActive={ processedValue }
                      disabled={ processedValue }
                      onClick={ onPrimaryWrapper }
                      type={ ICON_BUTTON_TYPES.star }
                    />
                  </ItemWrapper>
                );
              } }
            />
          }
          {
            isHousehold && (
              <>
                <AuxiliaryHiddenBlock/>
                <HouseholdWrapper>
                  <FormBlock.FormField
                    $labelWidth={ 144 }
                    isWrap={ false }
                    label={ 'Contact Owner' }
                  >
                    <HFSelect
                      control={ control }
                      name={ `${parentName}.${index}.${CONTACTS_DATA_FIELD_NAMES.ownerId}` }
                      options={ headOfHouseholdOptions }
                    />
                  </FormBlock.FormField>
                </HouseholdWrapper>
              </>
            )
          }
          <ItemWrapper >
            <CustomIconButton
              onClick={ onDeleteWrapper }
              type={ ICON_BUTTON_TYPES.close }
            />
          </ItemWrapper>
        </ToolbarActionsWrapper>
      }
    </Wrapper>);
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';

import { useStore } from '@store';
import { useActivityPopupLocalStore } from './store';

import { convertActivityData, detectOutOfWorkHours } from './utils';
import { MODAL_TYPE } from '@constants/modalTypes';
import { validationSchema } from './validationScheme';

import { Actions, BtnPanel, Content, EditActivityConfirmPopup } from './components';
import Modal from '@components/Modal';
import Preloader from '@components/Preloader';

import { Spacer } from './styles';

import { AddEditActivityPopupProps } from './types';
import { ActivityStatusOptions, ClientConverter, ConvertActivityData } from '@/shared/types/activityPopup';

export const ActivityPopup = observer(({
  closeModal,
  modalProps: {
    editMode,
    forDay,
    id,
    initData,
    onConfirm,
    onDelete
  }
}: AddEditActivityPopupProps) => {

  const modalStore = useStore().ModalStore;
  const {
    activityCreationSettings: { statuses },
    clearEditActivityItem,
    editActivityItem,
    getActivityById,
    getSettings,
    isFetching,
  } = useActivityPopupLocalStore();

  const [ load, setLoad ] = useState(editMode);
  const [ isOutOfWorkHours, setIsOutOfWorkHours ] = useState(false);
  const [ activityUsers, setActivityUsers ] = useState<Array<ClientConverter>>([]);

  const title = useMemo(() => `${editMode ? 'Edit' : 'Add '} ${'Calendar Activity'}`, [ editMode ]);

  const contactData = useMemo(() => {
    if(isEmpty(editActivityItem?.renderData?.primaryContact)) {
      return initData?.primaryContact;
    }
    return editActivityItem?.renderData?.primaryContact;
  }, [ editActivityItem, initData ]);

  const defaultValues = useMemo(() => {
    return initData ? {
      ...initData,
      statusId: statuses?.find((status) => status.label === ActivityStatusOptions.Scheduled)?.value,
      primaryContactId: contactData?.id
    } : {};
  }, [ initData, contactData, statuses ]);

  const methods: UseFormReturn<ConvertActivityData> = useForm<ConvertActivityData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const { handleSubmit, reset } = methods;

  const onEditMode = useCallback((convertedData: ConvertActivityData) => {
    const formData =  {
      ...convertedData,
      id: editActivityItem.formData.id,
      recurringChangeDate: forDay,
      withRecurring: false,
    };

    closeModal();
    if(editActivityItem.formData.recurring) {
      modalStore.openModal({
        modalType: MODAL_TYPE.EDIT_ACTIVITY_CONFIRMATION,
        modalProps: {
          formData,
          onConfirm
        },
        component: EditActivityConfirmPopup
      });
    } else {
      onConfirm(formData);
    }
  }, [
    closeModal,
    editActivityItem?.formData?.id,
    editActivityItem?.formData?.recurring,
    forDay,
    modalStore,
    onConfirm
  ]);

  const onSaveClick = useCallback(() => {
    handleSubmit((data: ConvertActivityData) => {
      const convertedData = convertActivityData(data, data.allDay);
      if(!isOutOfWorkHours && detectOutOfWorkHours(data, activityUsers)) {
        setIsOutOfWorkHours(true);
        return;
      }

      if(editMode) {
        onEditMode(convertedData);
      } else {
        closeModal();
        onConfirm(convertedData);
      }
    })();
  }, [handleSubmit, isOutOfWorkHours, activityUsers, editMode, onEditMode, closeModal, onConfirm]);

  const onCancelClick = useCallback(() => {
    if(isOutOfWorkHours) {
      setIsOutOfWorkHours(false);
    }
    closeModal();
  }, [ isOutOfWorkHours ]);

  useEffect(() => {
    getSettings();

    if(editMode && id) {
      getActivityById(id);
    }
  }, [getSettings, editMode, id, getActivityById]);

  useEffect(() => {
    if(editMode && !isEmpty(editActivityItem)) {
      reset(editActivityItem.formData);
      setLoad(false);
      return () => clearEditActivityItem();
    }
  }, [clearEditActivityItem, editActivityItem.formData, editMode, reset, setLoad]);

  useEffect(() => {
    reset(defaultValues);
  }, [ statuses ]);

  return (
    <FormProvider { ...methods }>
      <Modal
        onClose={ closeModal }
        title={ title }
      >
        <Modal.TopPanel>
          <BtnPanel
            $preventClicks={ load }
          />
        </Modal.TopPanel>
        <Modal.Content>
          {
            isFetching || load ? (
              <Spacer>
                <Preloader isWithoutFullPageWrapper={ true }/>
              </Spacer>
            ) : (
              <Content
                editMode={ editMode }
                isOverlay={ isOutOfWorkHours }
                primaryContact={ contactData as any }
                setActivityUsers={ setActivityUsers }
              />
            )
          }
        </Modal.Content>
        <Modal.Footer>
          <Actions
            $preventClicks={ load }
            close={ closeModal }
            editMode={ editMode }
            isOutOfWorkHours={ isOutOfWorkHours }
            onCancelClick={ onCancelClick }
            onDelete={ onDelete }
            onSaveClick={ onSaveClick }
            removeData={ {
              isRecurring: Boolean(editActivityItem.formData?.recurring),
              activityData: {
                id: editActivityItem.formData?.id,
                recurringChangeDate: forDay,
              }
            } }
          />
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
});

import { ValueLabelObj } from '@/shared/types/commonTypes';

type Option = string | number;

export const optionsFromValue = (
  options: Array<Option>,
  labelConverter?: (option: Option) => Option
) : Array<ValueLabelObj> => {
  if(labelConverter) {
    return options.map((option) => ({ label: labelConverter(option), value: option }));
  }
  return options.map((option) => ({ label: option, value: option }));
};

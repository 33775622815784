import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { FormControl, FormHelperText, OutlinedInput } from '@material-ui/core';

import { CalendarIconWrapperType, DatePickerInputProps, FormControlProps } from '@components/CustomDatePicker/types';

import { getInputsErrorStyles } from '@/shared/utils/getInputsErrorStyles';

export const CalendarIconWrapper = styled.div<CalendarIconWrapperType>`
  color: ${COLORS.grey};

  &:hover {
    color: ${COLORS.orange};
  }

  ${(props) => props.$disabled && css`
    color: ${COLORS.grey} !important;
  `}

  ${(props) => props.$error && css`
    color: ${COLORS.red};
  `}

  ${(props) => props.$focused && css`
    color: ${ COLORS.orange };
  `}
`;

export const StyledFormControl = styled(FormControl)<FormControlProps>`
  position: relative;
  width: 100%;
  background-color: ${COLORS.white};


  ${({ $showError }) => getInputsErrorStyles(Boolean($showError))};
  
  & .MuiInputBase-root{
    height: 30px;
  }
`;

export const StyledOutlinedInput = styled(OutlinedInput)<DatePickerInputProps>`
  &.MuiOutlinedInput-root {
    &:hover {
      color: ${COLORS.orange};
    }
    &.Mui-error {
      &:hover {
        color: ${COLORS.red};
      }
    }
    &.Mui-disabled {
      background: ${COLORS.background};

      &:hover {
        color: ${COLORS.disabled};
      }
    }
  }
`;

export const StyledFormHelperText= styled(FormHelperText)``;

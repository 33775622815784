import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import {
  Filters,
  PreTablePanelWrapper,
  TodoTable,
} from '@pages/NewToDos/components';
import { TodoPopup } from '@modules/TodoPopup';
import Surface from '@components/BasicLayoutComponents/Surface';

import { MODAL_TYPE } from '@constants/modalTypes';

import { IdType } from '@/shared/types/commonTypes';

export const ClientSpecificTasks = observer(() => {

  const modalStore = useStore().ModalStore;

  const {
    getTasksWithLoad,
    init,
    isFiltersOpen,
    onRemove,
    onSave,
    resetStore,
  } = useStore().ToDosStore;

  useEffect(() => {
    init();
    return resetStore;
  }, [init, resetStore]);

  const onAddTaskClick = useCallback((id?: IdType, isCloneTask?: boolean, previousCopyData?: any) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        initLinkedContact: null,
        isCloneTask,
        previousCopyData,
        saveCallback: onSave,
        deleteCallback: onRemove,
        updateCallback: getTasksWithLoad
      },
      component: TodoPopup
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ modalStore, onRemove, onSave ]);

  return (
    <Surface.Content $isWrap={ false }>
      <Surface.Main>
        <PreTablePanelWrapper
          onAddTaskClick={ onAddTaskClick }
        />
        <TodoTable
          onAddTaskClick={ onAddTaskClick }
        />
      </Surface.Main>
      {
        isFiltersOpen && (
          <Surface.Side>
            <Filters />
          </Surface.Side>
        )
      }
    </Surface.Content>
  );
});

import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';

import { Store } from '@store';

import CommonTableStore from '@services/store/commonTableStore';
import {
  deleteAccountAndPlans,
  getAccountAndPlans,
  saveAccountOrPoliceOrPlan,
  updateAccountAndPlans,
  updateFlags
} from '@services/api/accountAndPlans/accountAndPlans';

import {
  accountAndPlansNormalizer
} from '@services/store/accountsAndPlansStore/normalizers/accountAndPlansNormalizer';
import { ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@/shared/constants/accountsAndPlanData';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { ENTITY_NAMES } from '@constants/common';
import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { getDeleteOrRestoreItemsParams } from '@/shared/utils/getDeleteOrRestoreItemsParams';
import { getFilterParams, getGlobalFlagged, getMultipleSortParams } from '@/shared/utils/filterUtils';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';

import {
  AccountAndPlanItemShort,
  AccountAndPlansResponseType,
  ConvertedFiltersData,
  Filters,
  TypeFilter
} from '@/shared/types/accountsAndPlan';
import { ContactItem } from '@/shared/types/contact';
import { IdType, ItemWithId } from '@/shared/types/commonTypes';
import { NOTIFICATION_TYPES } from '@/shared/constants/notifications';
import { SubmitData } from '@modules/AccountsAndPlansPopup/types';

export class ContactDetailsAccountAndPlanStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;

  contact: ContactItem | null = null;
  coreStore: Store;
  filters: Filters = {} as Filters;
  filtersData: ConvertedFiltersData = {} as ConvertedFiltersData;
  isPageActive: boolean = false;
  onFilterChangeReaction: IReactionDisposer;
  onSortingChangeReaction: IReactionDisposer;
  onTypeFilterReaction: IReactionDisposer;
  table: CommonTableStore<AccountAndPlanItemShort>;
  typeFilter: TypeFilter = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow,
      deleteAccountsAndPlans: flow,
      getAccountAndPlansItems: flow.bound,
      updateFlags: flow,
    });
    this.coreStore = coreStore;
    this.table = new CommonTableStore<AccountAndPlanItemShort>({
      onGlobalFlaggedChangeReactionCallback: this.getAccountAndPlansItems,
      onPageChangeReactionCallback: this.getAccountAndPlansItems,
    });

    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onSortingChangeReaction = this.createOnSortingChangeReaction();
    this.onTypeFilterReaction = this.createOnTypeFilterReaction();

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      coreStore.NotificationsStore,
      ENTITY_NAMES.accountAndPlans
    );
  }

  createOnFilterChangeReaction() {
    return reaction(
      () => this.filters,
      () => {
        debounce(() => {
          this.table.setCurrentPage(1);
          this.getAccountAndPlansItems(this.contact?.id);
        }, 1500)();
      }
    );
  }

  createOnSortingChangeReaction() {
    return reaction(
      () => this.table.multipleSorting,
      () => this.getAccountAndPlansItems(this.contact?.id)
    );
  }

  createOnTypeFilterReaction() {
    return reaction(
      () => this.typeFilter,
      () => {
        this.table.setCurrentPage(1);
        this.getAccountAndPlansItems();
      }
    );
  }

  *init(contactId: IdType){
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);
    this.isPageActive = true;

    try {
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData: ContactItem = yield contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }

      yield this.getAccountAndPlansItems();

    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *getAccountAndPlansItems(contactId?: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    const currentContactId = contactId ? contactId : contactDetailsStore.currentContact!.id;
    contactDetailsStore.toggleLoadState(true);

    this.onFilterChangeReaction();
    this.setFilters({
      [ACCOUNTS_AND_PLANS_FILTER_NAMES.contactId]: currentContactId
    });
    this.onFilterChangeReaction = this.createOnFilterChangeReaction();

    try {
      this.table.clearItems(true);
      const response: AccountAndPlansResponseType = yield getAccountAndPlans({
        page: this.table.currentPage,
        ...getFilterParams(this.filters),
        ...getFilterParams(this.typeFilter),
        ...getMultipleSortParams(this.table.multipleSorting),
        ...getGlobalFlagged(this.table.globalFlagged),
      });

      this.table.setPaginationData(response.data.data);
      this.table.checkAndSetIfPageOutOfRange();

      const currentUserId = this.coreStore?.SettingsStore?.profile?.id;

      const { accountAndPlansItems, filtersData } = accountAndPlansNormalizer(response, currentUserId);

      this.table.items = accountAndPlansItems;
      this.filtersData = filtersData;
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
    }
  }

  *saveAccountsAndPlans(data: SubmitData) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      if(data.id){
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateAccountAndPlans(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: true,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: true,
            uniqueKey: data.id
          }),
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveAccountOrPoliceOrPlan(data),
          onError: () => this.notificationHelper.createUpdateNotification({
            isError: true,
            isUpdate: false,
            uniqueKey: data.id
          }),
          onSuccess: () => this.notificationHelper.createUpdateNotification({
            isError: false,
            isUpdate: false,
            uniqueKey: data.id
          }),
        });
      }
      yield this.getAccountAndPlansItems();
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *deleteAccountsAndPlans(ids: Array<IdType>) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    contactDetailsStore.toggleLoadState(true);

    try {
      const params = getDeleteOrRestoreItemsParams(ids);
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteAccountAndPlans(params),
        onError: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getAccountAndPlansItems();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(ids);
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean) {
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield updateFlags(params);

      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, { flagged: state });
      });
      if(this.table.globalFlagged) {
        this.getAccountAndPlansItems();
      }
    } catch (error) {
      console.log(error);
    }
  }

  setFilters(newFilters: Filters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setTypeFilter(value: TypeFilter) {
    this.typeFilter = value;
  }

  reset() {
    this.onFilterChangeReaction();
    this.onSortingChangeReaction();
    this.onTypeFilterReaction();

    this.contact = null;
    this.table.resetTable();
    this.filters = {} as Filters;
    this.typeFilter = null;
    this.isPageActive = false;

    this.onFilterChangeReaction = this.createOnFilterChangeReaction();
    this.onTypeFilterReaction = this.createOnTypeFilterReaction();
    this.onSortingChangeReaction = this.createOnSortingChangeReaction();
  }
}

import styled from 'styled-components';

export const ModalFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: right;
  height: 73px;
  padding: 20px 24px;
  border-top: 1px solid #DDDEEA;
  background: #fff;
`;

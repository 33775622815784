export const removeFalseValues = (obj, excludeArr = []) => (
  Object.entries(obj).reduce((acc, [ key, value ]) => {
    if (!value && !excludeArr.includes(key)) {
      return acc;
    }

    acc[key] = value;
    return acc;
  }, {})
);

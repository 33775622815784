export const ActivityPopupFieldsName = {
  allDay: 'allDay',
  clients: 'clients',
  description: 'description',
  endDate: 'endDate',
  endTime: 'endTime',
  name: 'name',
  primaryClientId: 'primaryClientId',
  primaryContactId: 'primaryContactId',
  recurring: 'recurring',
  recurringEndDate: 'recurringEndDate',
  recurringFrequency: 'recurringFrequency',
  startDate: 'startDate',
  startTime: 'startTime',
  statusId: 'statusId',
  subcategoryId: 'subcategoryId',
};

export const LABEL_WIDTH = {
  common: 125
};
import React, { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';

import { OptionItemWrapperProps, OptionsWrapperProps } from './types';

import {
  Wrapper,
  ItemsWrapper,
  ErrorWrapper,
  ItemWrapper,
  InputWrapper,
  NotEditButtonsWrapper
} from './styles';

import { UserCustomFieldFormFields } from '@/shared/types/userSettingsUserCustomFields';
import {
  USER_CUSTOM_FIELD_FORM_FIELDS,
  USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES
} from '@constants/userSettingsUserCustomFields';


const OptionItem = ({
  actualField,
  field,
  index,
  isDisabled,
  isEditMode,
  onDelete,
  onEdit,
  onSave,
}: OptionItemWrapperProps) => {
  const { control } = useFormContext<UserCustomFieldFormFields>();

  const onEditClick = useCallback(() => onEdit(index), [index, onEdit]);
  const onDeleteClick = useCallback(() => onDelete(index), [index, onDelete]);
  const onSaveClick = useCallback(() => onSave(index), [index, onSave]);

  return (
    <ItemWrapper $isDisabled={ isDisabled }>
      {
        !isEditMode &&
        <>
          {actualField.label}
          <NotEditButtonsWrapper
            $isShowOnParentHover={ true }
            $withGap={ true }
          >
            <CustomIconButton
              disabled={ isDisabled }
              onClick={ onEditClick }
              type={ ICON_BUTTON_TYPES.edit }
            />

            <CustomIconButton
              disabled={ isDisabled }
              onClick={ onDeleteClick }
              type={ ICON_BUTTON_TYPES.close }
            />
          </NotEditButtonsWrapper>
        </>
      }
      {
        isEditMode &&
          <>
            <InputWrapper>
              <HFTextInput
                inputProps={ {
                  maxLength: 20,
                } }
                control={ control }
                name={
                  `${USER_CUSTOM_FIELD_FORM_FIELDS.options}.${index}.${USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES.label}`
                }
                showErrorText={ false }
              />
            </InputWrapper>
            <NotEditButtonsWrapper>
              <CustomIconButton
                disabled={ isDisabled }
                onClick={ onSaveClick }
                type={ ICON_BUTTON_TYPES.done }
              />
            </NotEditButtonsWrapper>
          </>
      }
      <HFHiddenInput
        control={ control }
        defaultValue={ field.id }
        name={ `${USER_CUSTOM_FIELD_FORM_FIELDS.options}.${index}.${USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES.id}` }
      />
    </ItemWrapper>
  );
};


export const OptionsWrapper = ({
  fields,
  editIndex,
  onEdit,
  onDelete,
  onSave,
}: OptionsWrapperProps) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext<UserCustomFieldFormFields>();

  const isSomeOptionIsEdit = typeof editIndex === 'number';

  const actualStateOfOptions = useWatch({
    control,
    name: USER_CUSTOM_FIELD_FORM_FIELDS.options
  });


  const isError = Boolean(errors?.options);
  const extract = useCallback((errors: any) => {
    const optionsErrors = errors?.options;

    if(!optionsErrors){
      return null;
    }

    if(optionsErrors?.message){
      return optionsErrors?.message;
    }

    let result;
    for (const optionError of optionsErrors as Array<any>) {
      if (optionError?.label?.message) {
        result = optionError?.label?.message;
      }
    }

    return result;
    // do not delete actualStateOfOptions from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <ItemsWrapper
        $isError={ isError }
        $isScrollBlocked={ isSomeOptionIsEdit }
      >
        {
          fields.map((field, index) => (
            <OptionItem
              actualField={ actualStateOfOptions[index] }
              field={ field }
              index={ index }
              isDisabled={ isSomeOptionIsEdit && index !== editIndex }
              isEditMode={ index === editIndex }
              key={ field.useFieldArrayOptionsKey }
              onDelete={ onDelete }
              onEdit={ onEdit }
              onSave={ onSave }
            />
          ))
        }
      </ItemsWrapper>
      {
        isError &&
        <ErrorWrapper>
          {extract(errors)}
        </ErrorWrapper>
      }
    </Wrapper>
  );
};

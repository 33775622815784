import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useFormContext, useWatch } from 'react-hook-form';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import { COMMON_FIELD_NAMES } from '@modules/AccountsAndPlansPopup/data/fieldNames';


import { AccountForm, PlanForm, PolicyForm } from './components';

export const Content = observer(() => {
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();
  const { isNotesPanelDisabled, tabs, currentTab } = accountsAndPlansLocalStore;

  const { control } = useFormContext();
  const [ accountType, name, contactId ] = useWatch({
    control,
    name: [COMMON_FIELD_NAMES.accountType, COMMON_FIELD_NAMES.name, COMMON_FIELD_NAMES.contactId],
  });

  const onTabChange = useCallback((value: string | number) =>{
    accountsAndPlansLocalStore.setCurrentTab(value);
  },[accountsAndPlansLocalStore]);

  useEffect(() => {
    if(name && contactId){
      accountsAndPlansLocalStore.setNotesPanelDisableState(false);
    }

    if(!name || !contactId){
      accountsAndPlansLocalStore.setNotesPanelDisableState(true);
    }
  }, [name, accountsAndPlansLocalStore, isNotesPanelDisabled, contactId]);

  if (accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT) {
    return (
      <AccountForm
        currentTab={ currentTab }
        onTabChange={ onTabChange }
        tabs={ tabs }
      />
    );
  }

  if (accountType === ACCOUNTS_AND_PLANS_TYPES.POLICY) {
    return (
      <PolicyForm
        currentTab={ currentTab }
        onTabChange={ onTabChange }
        tabs={ tabs }
      />);
  }

  if (accountType === ACCOUNTS_AND_PLANS_TYPES.PLAN) {
    return (
      <PlanForm
        currentTab={ currentTab }
        onTabChange={ onTabChange }
        tabs={ tabs }
      />
    );
  }
  return null;
});
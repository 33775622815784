import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import MuiToolbar from '@material-ui/core/Toolbar';

import { COLORS } from '@constants/colors';
import { CSS_VARIABLE_NAMES } from '@constants/style';

import { StyledMuiToolbarProps } from './types';

export const StyledMuiToolbar = styled(MuiToolbar)<StyledMuiToolbarProps>`
  z-index: 20;
  position: fixed;

  ${ ({ isSideMenu }) => isSideMenu ? css`
    top: 66px;
    left: var(${CSS_VARIABLE_NAMES.menuWidth});
    ` : css`
    top: 95px;
    left: 0;
  `}

  right: 0;
  flex-flow: row;
  height: 66px;
  box-sizing: border-box;
  background-color: ${COLORS.menuBck};
  opacity: 0.86;
  padding: 1rem 30px;
  justify-content: space-between;
`;

export const InfoText = styled.h3`
  flex-grow: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.white};
`;

export const StyledButton = styled(Button)`
  color: ${COLORS.white};
  margin-left: 15px;
`;

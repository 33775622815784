import React, { useCallback } from 'react';

import { useStore } from '@store';

import { ROUTES } from '@constants/routes';

import BlockButton, { COLOR_SCHEME_TYPES } from '@/components/BlockButton';
import { GrayLogo } from '@/assets';

import { ButtonsWrapper, StyledHeader } from './styles';

import { AuthPageHeaderProps } from './types';


export const AuthPageHeader = ({
  isVisibleLogInButton = true,
  isVisibleSignUpButton = false,
}: AuthPageHeaderProps) => {

  const routerStore = useStore().RouterStore;

  const onLogInClick = useCallback(() => {
    routerStore.customPush(ROUTES.login);
  }, [ routerStore ]);

  const onSignUpClick = useCallback(() => {
    routerStore.customPush(ROUTES.register);
  }, [ routerStore ]);

  return (
    <StyledHeader>
      <GrayLogo />

      <ButtonsWrapper>
        { isVisibleLogInButton &&
          <BlockButton
            color={ 'secondary' }
            colorSchema={ COLOR_SCHEME_TYPES.outlined }
            onClick={ onLogInClick }
            variant='outlined'
          >
            Log in
          </BlockButton>
        }

        { isVisibleSignUpButton &&
          <BlockButton
            colorSchema={ COLOR_SCHEME_TYPES.contained }
            onClick={ onSignUpClick }
            variant='contained'
          >
            Sign Up Free
          </BlockButton>
        }
      </ButtonsWrapper>
    </StyledHeader>
  );
};

import React, { ElementType } from 'react';

import BlockButton from '@components/BlockButton';

import { BUTTONS_DATA } from './data';

import { PopupSingleActionButtonProps } from './types';

export const PopupSingleActionButton = ({
  $bgColor,
  $color,
  $bgHoverColor,
  $hoverColor,
  $iconSize = '18px',
  Icon,
  children,
  onClick,
  type,
  ...props
}: PopupSingleActionButtonProps) => {
  const {
    typeColor,
    typeBgColor,
    typeBgHoverColor,
    typeHoverColor,
    TypeIcon
  } = (type && BUTTONS_DATA[type]) || {};
  const IconToRender = (Icon || TypeIcon) as ElementType;

  return (
    <BlockButton
      $bgColor={ $bgColor || typeBgColor  }
      $bgHoverColor={ $bgHoverColor || typeBgHoverColor }
      $color={ typeColor || $color }
      $hoverColor={ $hoverColor || typeHoverColor }
      $iconSize={ $iconSize }
      color={ 'secondary' }
      onClick={ onClick }
      startIcon={ <IconToRender /> }
      { ...props }
    >
      {children}
    </BlockButton>
  );
};

import React from 'react';
import { Controller } from 'react-hook-form';

import CustomCheckbox from '@components/CustomCheckbox';

import { HFCheckboxProps } from './types';

export const HFCheckbox = ({
  name,
  ControllerProps,
  control,
  CheckboxProps,
  disabled,
  $distanceFromLabel,
  label,
  labelPlacement,
}: HFCheckboxProps) => (
  <Controller
    control={ control }
    name={ name }
    render={ ({
      field: { value, onChange },
    }) => (
      <CustomCheckbox
        $distanceFromLabel={ $distanceFromLabel }
        CheckboxProps={ CheckboxProps }
        checked={ value }
        disabled={ disabled }
        label={ label }
        labelPlacement={ labelPlacement }
        onChange={ onChange }
      />
    ) }
    { ...ControllerProps }
  />
);

import React, { ReactNode } from 'react';

import { SnackbarProvider } from 'notistack';

import { NotificationCustomItem } from './components';

import { NotificationRender } from './NotificationRender';


export const NotificationsProvider = () => {
  return (
    <SnackbarProvider
      Components={ {
        info: NotificationCustomItem,
        warning: NotificationCustomItem,
        success: NotificationCustomItem,
        error: NotificationCustomItem,
        default: NotificationCustomItem,
      } }
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'right',
      } }
      autoHideDuration={ null }
      preventDuplicate={ true }
    >
      <NotificationRender />
    </SnackbarProvider>
  );
};

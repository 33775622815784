import React, { useCallback, useState } from 'react';

import { CaretDown, CaretUp } from '@/assets';

import Popover from '@material-ui/core/Popover';

import {
  anchorOriginProps,
  paperProps,
  transformOriginProps
} from './data';

import {
  ContentWrapper,
  IconWrapper,
  ItemsWrapper,
} from './styles';

import { Item } from '@components/RowFilter/types';
import { ShowMoreProps } from './types';

export const ShowMore = ({
  $showMoreListMarginBottom,
  currentSelected,
  IconClose = CaretDown,
  IconOpen = CaretUp,
  items,
  onItemClickCb,
  renderItem,
  showMoreLabel,
}: ShowMoreProps) => {
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState<HTMLElement | null>(null);

  const closePopover = useCallback(() => setPopoverAnchorElem(null),[]);
  const onItemClick = useCallback((value: Item) => () => {
    onItemClickCb(value);
    closePopover();
  },[closePopover, onItemClickCb]);

  const ShowMoreCaretIcon = (
    popoverAnchorElem
      ? IconOpen
      : IconClose
  ) as React.ElementType;

  return (
    <>
      {
        items && items.length > 0 &&
        renderItem({
          content: (
            <ContentWrapper>
              { `${showMoreLabel}  ${items.length}` }
              <IconWrapper>
                {
                  <ShowMoreCaretIcon />
                }
              </IconWrapper>
            </ContentWrapper>
          ),
          isSelected: Boolean(popoverAnchorElem),
          onClick: (event) => setPopoverAnchorElem(event.currentTarget as HTMLElement),
          isShowMore: true,
          isUnlimited: false,
          isSelectedFromMore: Boolean(items.find((item) => item === currentSelected)),
          indexForKey: showMoreLabel
        })
      }

      { items && items.length > 0 &&
        <Popover
          PaperProps={ paperProps }
          anchorEl={ popoverAnchorElem }
          anchorOrigin={ anchorOriginProps }
          onClose={ closePopover }
          open={ Boolean(popoverAnchorElem) }
          transformOrigin={ transformOriginProps }
        >
          <ItemsWrapper $showMoreListMarginBottom={ $showMoreListMarginBottom }>
            {
              items.map((item, index) => (
                renderItem({
                  content: item,
                  isSelected: currentSelected === item,
                  onClick: onItemClick(item),
                  isSelectedFromMore: false,
                  isShowMore: false,
                  isUnlimited: false,
                  indexForKey: index
                })
              ))
            }
          </ItemsWrapper>
        </Popover>
      }
    </>
  );
};

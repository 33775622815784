import {
  CommonFields,
  BusinessFields,
  HouseHoldFields,
  IndividualFields,
  CommonFieldsForIndividualAndHousehold,
} from '@/shared/types/contact';
import { MapTypeNamesToValues } from '@/shared/types/commonTypes';

type BusinessFieldsNames = MapTypeNamesToValues<Omit<BusinessFields, 'type'>>
type CommonFieldsNames = MapTypeNamesToValues<CommonFields>
type HouseHoldFieldsNames = MapTypeNamesToValues<Omit<HouseHoldFields, 'type'>>
type IndividualFieldsNames = MapTypeNamesToValues<Omit<IndividualFields, 'type'>>
type CommonIndividualAndHouseholdFieldsNames = MapTypeNamesToValues<CommonFieldsForIndividualAndHousehold>
type HouseholdContactDetailsParentFieldsNames = Array<string>

export const COMMON_FIELDS: CommonFieldsNames = {
  allowCall: 'allowCall',
  allowEmail: 'allowEmail',
  allowMail: 'allowMail',
  allowText: 'allowText',
  briefDescription: 'briefDescription',
  classification: 'classification',
  clientId: 'clientId',
  clientTimestamp: 'clientTimestamp',
  clientCreationDate: 'clientCreationDate',
  contacts: 'contacts',
  editAllow: 'editAllow',
  flagged: 'flagged',
  id: 'id',
  officeLocation: 'officeLocation',
  officePrimaryAdvisor: 'officePrimaryAdvisor',
  officePrimaryCsr: 'officePrimaryCsr',
  officeSecondaryAdvisor: 'officeSecondaryAdvisor',
  officeSecondaryCsr: 'officeSecondaryCsr',
  photo: 'photo',
  rating: 'rating',
  recordTimestamp: 'recordTimestamp',
  relatedContact: 'relatedContact',
  sourceCategory: 'sourceCategory',
  sourceId: 'sourceId',
  status: 'status',
  type: 'type',
};

export const COMMON_INDIVIDUAL_AND_HOUSEHOLD_FIELDS_NAMES: CommonIndividualAndHouseholdFieldsNames = {
  importantInformation: 'importantInformation',
  ssnReadOnly: 'ssnReadOnly',
};

export const BUSINESS_FIELDS: BusinessFieldsNames = {
  annualRevenue: 'annualRevenue',
  birthdate: 'birthdate',
  companyType: 'companyType',
  employeeCount: 'employeeCount',
  occupationCompany: 'occupationCompany',
  taxIdNumber: 'taxIdNumber',
  website: 'website',
};

export const HOUSEHOLD_FIELDS: HouseHoldFieldsNames = {
  communicationWithIds: 'communicationWithIds',
  householderContacts: 'householderContacts',
  householdOwnerId: 'householdOwnerId',
  sendEmailToIds: 'sendEmailToIds',
  sendMailToIds: 'sendMailToIds',
};

export const INDIVIDUAL_FIELDS: IndividualFieldsNames = {
  anniversaryDate: 'anniversaryDate',
  birthdate: 'birthdate',
  birthplace: 'birthplace',
  citizenshipCountry: 'citizenshipCountry',
  dlExpirationDate: 'dlExpirationDate',
  dlNumber: 'dlNumber',
  dlState: 'dlState',
  emailNameToUse: 'emailNameToUse',
  ethnicity: 'ethnicity',
  firstName: 'firstName',
  gender: 'gender',
  lastName: 'lastName',
  maidenName: 'maidenName',
  mailNameToUse: 'mailNameToUse',
  maritalStatus: 'maritalStatus',
  middleName: 'middleName',
  nickname: 'nickname',
  occupations: 'occupations',
  pets: 'pets',
  prefix: 'prefix',
  relatedContact: 'relatedContact',
  relatedEmployees: 'relatedEmployees',
  retirementDate: 'retirementDate',
  retirementStatus: 'retirementStatus',
  ssn: 'ssn',
  suffix: 'suffix',
  taxIdNumber: 'taxIdNumber',
};

export const HOUSEHOLD_CONTACT_DETAILS_PARENT_FIELDS: HouseholdContactDetailsParentFieldsNames = [
  COMMON_FIELDS.contacts,
  `${HOUSEHOLD_FIELDS.householderContacts}[0].${COMMON_FIELDS.contacts}`,
  `${HOUSEHOLD_FIELDS.householderContacts}[1].${COMMON_FIELDS.contacts}`
];


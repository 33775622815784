import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { ContentProps } from './types';

export const AccountAndPlansWrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

export const Divider = styled.div<ContentProps>`
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background: ${({ textColor }) => textColor && textColor };
`;

export const Content = styled.div`
  width: 30%;
`;

export const Type = styled.div<ContentProps>`
  color: ${({ textColor }) => textColor ? textColor : COLORS.primaryText};
`;

export const Count = styled.div<ContentProps>`
  padding-bottom: 2px;
  font-size: 26px;
  font-weight: 300;
  color: ${({ textColor }) => textColor ? textColor : COLORS.primaryText};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

import { ContactDetailsTabData } from '@/shared/types/salesActiveCycle';
import { SALES_ACTIVE_CYCLE_PATH, SALES_CLIENT_OPPORTUNITIES_PATH, salesCycleTabs } from '@constants/routes/salesCycle';

export const  SALES_CYCLE_TABS_DATA: ContactDetailsTabData = [
  {
    value: salesCycleTabs.activeSalesCycles,
    label: 'Active Sales Cycles',
    link: SALES_ACTIVE_CYCLE_PATH,
  },
  {
    value: salesCycleTabs.clientOpportunities,
    label: 'Client Opportunities',
    link: SALES_CLIENT_OPPORTUNITIES_PATH,
  },
];

import { makeAutoObservable } from 'mobx';

import { DataType, DataTypeAssertion, ModalType, OpenModalProps } from './types';


class ModalStore {
  data: DataType = {};

  constructor() {
    makeAutoObservable(this);
  }

  get isSomeModalOpen() {
    return Object.keys(this.data).length > 0;
  }

  openModal<K extends ModalType>({
    modalType,
    modalProps,
    component
  }: OpenModalProps<K>) {
    this.data = {
      ...this.data,
      [modalType]: {
        modalProps,
        component,
      },
    };
  }

  closeModal(modalType: ModalType) {
    if(this.data?.[modalType]){
      delete this.data[modalType];
    }
  }

  closeAllModals() {
    this.data = {};
  }

  minimizeModal(modalType: ModalType) {
    if (this.data[modalType]) {
      this.data[modalType] = {
        ...this.data[modalType],
        minimized: true,
      } as DataTypeAssertion;
    }
  }

  maximizeModal(modalType: ModalType) {
    if (this.data[modalType]) {
      this.data[modalType] = {
        ...this.data[modalType],
        minimized: false,
      } as DataTypeAssertion;
    }
  }
}

export default ModalStore;

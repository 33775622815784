import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 24px 20px 0 20px;
`;

export const TextContent = styled.div`
    font-weight: 400;
    line-height: 24px;
`;
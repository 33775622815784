import { LISTS_CONTACTS_GROUP_KEYS, ListsContactsGroupTypes } from '@/shared/types/lists';

import { Item } from './types';

export const CONFIG: Record<ListsContactsGroupTypes, Item> = {
  Active: {
    label: 'Active',
    value: LISTS_CONTACTS_GROUP_KEYS.Active
  },
  Deceased: {
    label: 'Deceased',
    value: LISTS_CONTACTS_GROUP_KEYS.Deceased
  },
  allowCall: {
    label: 'Do Not Call',
    value: LISTS_CONTACTS_GROUP_KEYS.allowCall,
  },
  allowEmail: {
    label: 'Do Not Email',
    value: LISTS_CONTACTS_GROUP_KEYS.allowEmail,
  },
  allowMail: {
    label: 'Do Not Mail',
    value: LISTS_CONTACTS_GROUP_KEYS.allowMail,
  },
  allowText: {
    label: 'Do Not Text',
    value: LISTS_CONTACTS_GROUP_KEYS.allowText
  },
};

export const DRAW_CONFIG = [
  CONFIG.allowEmail,
  CONFIG.allowMail,
  CONFIG.allowCall,
  CONFIG.allowText,
  CONFIG.Active,
  CONFIG.Deceased,
];

import { createContext, useContext } from 'react';
import { action, makeAutoObservable } from 'mobx';

import { Item, NotesViewType } from './types';
import { INIT_NOTES_VIEW, NOTES_TAB_VALUE, VIEWS } from './data';

export class NotesAndHistoryStateManager {
  notesView: NotesViewType = INIT_NOTES_VIEW;
  editNote: null | Item = null;
  currentTab: string = NOTES_TAB_VALUE;

  constructor() {
    makeAutoObservable(this,{
      onAddNoteClick: action.bound,
      resetState: action.bound,
      setCurrentTab: action.bound,
      resetTab: action.bound,
      setEditNote: action.bound,
      setNotesView: action.bound,
    });
  }

  onAddNoteClick() {
    this.setNotesView(VIEWS.addEdit);
  }

  setNotesView(notesView: NotesViewType){
    this.notesView = notesView;
  }

  setCurrentTab(tab: string){
    this.currentTab = tab;
  }

  setEditNote(editNote: Item | null) {
    this.editNote = editNote;
  }

  resetState() {
    this.setNotesView(INIT_NOTES_VIEW);
    this.setEditNote(null);
    // TODO: separate tabs in the NotesAndHistory
    // this.setCurrentTab(NOTES_TAB_VALUE);
  }

  // TODO: separate tabs in the NotesAndHistory
  resetTab() {
    this.setCurrentTab(NOTES_TAB_VALUE);
  }
}

const store = new NotesAndHistoryStateManager();
export const NotesAndHistoryLocalState = createContext<NotesAndHistoryStateManager>(store);
export const useNotesAndHistoryLocalState = () => useContext(NotesAndHistoryLocalState);

import React, { useCallback } from 'react';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import BlockButton from '@components/BlockButton';

import { OnClick as CustomIconButtonOnClick } from '@components/CustomIconButton/types';
import { PopupTableActionsProps } from './types';

import { Wrapper, SaveButtonWrapper, EditButtonWrapper } from './styles';

export const PopupTableActions = ({
  $alignSelf = 'flex-start',
  $marginLeft = 'auto',
  $marginRight = '20px',
  $marginTop = '11px',
  disableDelete,
  disableEdit,
  isEdit,
  onDeleteClick,
  onEditClick,
  onSaveClick,
}: PopupTableActionsProps) => {

  const onCloseWrapper:CustomIconButtonOnClick = useCallback((event) => {
    event.stopPropagation();
    onDeleteClick();
  },[onDeleteClick]);

  return (
    <Wrapper
      $alignSelf={ $alignSelf }
      $marginLeft={ $marginLeft }
      $marginRight={ $marginRight }
      $marginTop={ $marginTop }
    >
      {
        isEdit
          ? (
            <SaveButtonWrapper>
              <BlockButton
                disabled={ disableEdit }
                onClick={ onSaveClick }
              >
                {'Save'}
              </BlockButton>
            </SaveButtonWrapper>
          )
          : (
            <EditButtonWrapper>
              <CustomIconButton
                disabled={ disableEdit }
                onClick={ onEditClick }
                type={ ICON_BUTTON_TYPES.edit }
              />
            </EditButtonWrapper>
          )
      }
      <CustomIconButton 
        disabled={ disableDelete }
        onClick={ onCloseWrapper }
        type={ ICON_BUTTON_TYPES.close }
      />
    </Wrapper>
  );
};

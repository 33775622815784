import * as yup from 'yup';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES } from '@constants/salesActiveCycle';
import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation } from '@constants/validationPatterns';
import { isDateAfter } from '@/shared/utils/dateValidationUtils';

export const validationSchema = yup.object().shape({
  [SALES_ACTIVE_CYCLE_FIELD_NAMES.contactId]: yup.number()
    .nullable()
    .required(validationMessages.required),
  [SALES_ACTIVE_CYCLE_FIELD_NAMES.stage]: yup.string()
    .nullable()
    .required(validationMessages.required),
  [SALES_ACTIVE_CYCLE_FIELD_NAMES.cycleOpenDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidCycleOpenDate),
  [SALES_ACTIVE_CYCLE_FIELD_NAMES.wonDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidWonDate)
    .test(
      'mast be equal or grater than standingInstructionStartDate',
      validationMessages.invalidWonDate,
      (value, context) => (
        isDateAfter({
          dateStringToCompare: context.parent[SALES_ACTIVE_CYCLE_FIELD_NAMES.cycleOpenDate],
          dateString: value,
          withEqual: true,
        })
      )
    )
});

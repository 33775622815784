import {
  ACCOUNT_DATA_SCHEME,
  CONTACT_SCHEME,
  LINKED_ITEM_ID_NAMES,
  OPPORTUNITY_DATA_SCHEME,
  PLAN_DATA_SCHEME,
  POLICY_DATA_SCHEME,
  SALES_PIPELINE_DATA_SCHEME
} from '@constants/linkedItem';
import { LinkedItemSchemeType, SchemeDataName } from './types';

export const TRASH_TO_DOS_LINKED_ITEMS_IDS = {
  accountId: LINKED_ITEM_ID_NAMES.accountId,
  linkedContactId: LINKED_ITEM_ID_NAMES.linkedContactId,
  opportunityId: LINKED_ITEM_ID_NAMES.opportunityId,
  planId: LINKED_ITEM_ID_NAMES.financialPlanId,
  policyId: LINKED_ITEM_ID_NAMES.policyId,
  salesPipeLineId: LINKED_ITEM_ID_NAMES.salesPipelineId,
} as const;

export const TRASH_TO_DOS_LINKED_ITEMS_TYPES_SCHEME: Record<SchemeDataName, LinkedItemSchemeType> = {
  ...CONTACT_SCHEME,
  ...OPPORTUNITY_DATA_SCHEME,
  ...ACCOUNT_DATA_SCHEME,
  ...PLAN_DATA_SCHEME,
  ...POLICY_DATA_SCHEME,
  ...SALES_PIPELINE_DATA_SCHEME,
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

import { hexToRgba } from '@/shared/utils/colorConverter';

import { ItemProps } from '../../types';

export const Item = styled.div<ItemProps>`
  width: ${({ $width }) => $width || '200px' };
  
  position: absolute;
  bottom: 40px;
  right: 40px;
  
  border-radius: 10px;
  overflow: hidden;
  
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 19px;

  background-color: ${hexToRgba(COLORS.notificationWithAction, 0.65)};
`;


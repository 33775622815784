import React from 'react';
import { Plus } from 'react-feather';

import { OutOfSightTD } from '@components/OutOfSight';
import Avatar from '@components/Avatar';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';

import { getAbbr } from '@/shared/utils/getAbbr';
import { USER_ICON_SIZES } from '@components/UserIcon';

import {
  AddSpouseBtn,
  ItemAvatar,
  ItemBtn,
  ItemInfo,
  ItemInner,
  ItemName,
  ItemTitle,
} from './styles';

import { SpouseBtnProps } from './types';


export const SpouseBtn = ({
  onAddClick = () => {},
  onItemClick = () => {},
  isBlocked,
  spouseData,
  style,
}: SpouseBtnProps) => {

  if(spouseData) {
    const fullName = `${spouseData?.firstName} ${spouseData?.lastName}`;
    return (
      <ItemBtn
        onClick={ onItemClick }
        style={ style }
      >
        <ItemInner>
          <ItemAvatar>
            <Avatar
              $size={ USER_ICON_SIZES.medium }
              mainImageData={ {
                abbr: getAbbr(spouseData.firstName, spouseData.lastName),
                src: spouseData?.photo
              } }
            />
          </ItemAvatar>
          <ItemInfo>
            <ItemName>
              <Tooltip
                displayOnlyOnOverflow={ true }
                title={ fullName }
              >
                <TextEllipsis $maxWidth={ 85 }>
                  {fullName}
                </TextEllipsis>
              </Tooltip>
            </ItemName>
            <ItemTitle>
              Spouse
            </ItemTitle>
          </ItemInfo>
        </ItemInner>
        {
          isBlocked && <OutOfSightTD onClick={ onItemClick } />
        }
      </ItemBtn>
    );
  }
  return (
    <AddSpouseBtn
      color='secondary'
      onClick={ onAddClick }
      startIcon={
        <Plus size={ 40 } />
      }
      style={ style }
      variant='outlined'
    >
      Add Spouse
    </AddSpouseBtn>
  );
};

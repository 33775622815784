import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  color: ${COLORS.header};
`;

import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { useStore } from '@store';
import browserHistory from '@/shared/services/browserHistory';

import { AppSwitch } from './AppSwitch';
import ModalRenderer from '@/modules/Modals/ModalRenderer';
import { GlobalStyle } from './AppGlobalStyles';
import { NotificationsProvider } from '@modules/Notification';

import mainTheme from '@/themes';

function App() {
  const sessionStore = useStore().SessionStore;

  useEffect(() => {
    sessionStore.startSession();
  }, []);

  return (
    <MuiPickersUtilsProvider utils={ DateFnsUtils }>
      <Router history={ browserHistory } >
        <ThemeProvider theme={ mainTheme }>
          <StylesProvider injectFirst={ true }>
            <GlobalStyle />
            <NotificationsProvider/>
            <AppSwitch />
            <ModalRenderer />
          </StylesProvider>
        </ThemeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default observer(App);

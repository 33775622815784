import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { PASSWORD_STRENGTH } from './data';

export const WrapperPasswordStrength = styled.div`
  height: 8px;
  display: flex;
  align-items: start;
  padding-top: 2px;
`;

export const PasswordStrengthLabel = styled.label<PasswordStrengthProps>`
  position: absolute;
  bottom: 45px;
  left: calc(100% + 10px);
  font-size: 10px;
  color: ${COLORS.red};

  ${({ passwordLabel }) => {
    switch(passwordLabel) {
    case PASSWORD_STRENGTH.Weak: return css`color: ${COLORS.red};`;
    case PASSWORD_STRENGTH.Good: return css`color: ${COLORS.yellow};`;
    case PASSWORD_STRENGTH.Average: return css`color: ${COLORS.logoOrange};`;
    case PASSWORD_STRENGTH.Strong: return css`color: ${COLORS.green};`;
    }
  }};
`;

export type PasswordStrengthProps = {
  passwordLabel: string
};

export const PasswordStrengthProgress = styled.progress<PasswordStrengthProps>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;

  &::-webkit-progress-bar {
    background-color: ${COLORS.borders};
    border-radius: 3px;
  }

  &::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  ${({ passwordLabel }) => {
    switch(passwordLabel) {
    case PASSWORD_STRENGTH.Weak:
      return css`
      &::-webkit-progress-value {
        background-color: ${COLORS.red};
      }
    `;
    case PASSWORD_STRENGTH.Good:
      return css`
      &::-webkit-progress-value {
        background-color: ${COLORS.yellow};
      }
    `;
    case PASSWORD_STRENGTH.Average:
      return css`
      &::-webkit-progress-value {
        background-color: ${COLORS.logoOrange};
      }
    `;
    case PASSWORD_STRENGTH.Strong:
      return css`
      &::-webkit-progress-value {
        background-color: ${COLORS.green};
      }
    `;
    }
  }};
`;
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useActivityPopupLocalStore } from '@/modules/ActivityPopup/store';
import { ActivityPopupFieldsName, LABEL_WIDTH } from '@/modules/ActivityPopup/data';

import FormBlock from '@components/FormBlock';
import HFSelect from '@/modules/HookFormComponents/HFSelect';

import { ActivityStatusWrapper, SubcategoryWrapper } from './styles';


export const StatusAndSubCategory = () => {

  const { control } = useFormContext();

  const {
    activityCreationSettings: {
      statuses,
      subCategories,
    }
  } = useActivityPopupLocalStore();

  return (
    <FormBlock.RowWrapper>
      <ActivityStatusWrapper>
        <FormBlock.FormField
          $labelWidth={ LABEL_WIDTH.common }
          label={ 'Activity Status' }
        >
          <HFSelect
            control={ control }
            name={ ActivityPopupFieldsName.statusId }
            options={ statuses || [] }
            placeholder={ 'Please select' }
          />
        </FormBlock.FormField>
      </ActivityStatusWrapper>

      <SubcategoryWrapper>
        <FormBlock.FormField
          $labelWidth={ LABEL_WIDTH.common }
          label={ 'Subcategory' }
        >
          <HFSelect
            control={ control }
            name={ ActivityPopupFieldsName.subcategoryId }
            options={ subCategories || [] }
            placeholder={ 'Please select' }
          />
        </FormBlock.FormField>
      </SubcategoryWrapper>
    </FormBlock.RowWrapper>
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  border: 1px solid ${COLORS.borders};
  background-color: ${COLORS.white};
  
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;


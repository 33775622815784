import ApiService from '@services/api/api.service';

const addSpouse = (data) => {
  return ApiService.post('api/crm/contact/addSpouse', data);
};

const deleteContacts = (params) => {
  return ApiService.delete('/api/crm/contact/delete', { params });
};

const deleteCustomField = (params) => {
  return ApiService.delete('api/customField/deleteContactCustomField', { params });
};

const deleteEmployee = (params) => {
  return ApiService.delete('api/contact/employee/delete', { params });
};

const getActivityForOverview = (params) => {
  return ApiService.get('api/activity/getActivityForOverview', { params });
};

const getContactAllNotes = (params) => {
  return ApiService.get('api/crm/contact/getContactAllNotes', { params });
};

const saveContactNote = (params) => {
  return ApiService.post('api/contact/saveContactNote', params);
};

const getContactBusinessOptions = () => {
  return ApiService.get('api/setting/contact/getContactBusinessOptions');
};

const getContactById = (params) => {
  return ApiService.get('/api/crm/contact/get', { params });
};

const getContactOverView = (params) => {
  return ApiService.get('/api/crm/contact/getContactOverView', { params });
};

const getContactByIdWithNextAppointmentDate = (params) => {
  return ApiService.get('/api/crm/contact/getContactByIdWithNextAppointmentDate', { params });
};

const getContactCustomFields = (params) => {
  return ApiService.get('api/customField/getAllContactCustomFieldByContact', { params });
};

const getContactHouseholdOptions = () => {
  return ApiService.get('api/setting/contact/getContactHouseholdOptions');
};

const getContactIndividualOptions = () => {
  return ApiService.get('api/setting/contact/getIndividualOptions');
};

const getContacts = (params) => {
  return ApiService.get('/api/crm/contact/gridList', { params });
};

const getContactsForSpouse = (params) => {
  return ApiService.get('/api/crm/contact/searchContactByNameForSpouse', { params });
};

const getEmployees = (params) => {
  return ApiService.get('api/contact/employee/getByContact', { params });
};

const saveCustomField = (data) => {
  return ApiService.post('api/customField/saveContactCustomField', data);
};

const saveEmployee = (data) => {
  return ApiService.post('api/contact/employee/save', data);
};
const saveNewContact = (data) => {
  return ApiService.post('/api/crm/contact/create', data);
};

const splitHouseHold = (data) => {
  return ApiService.post('api/contact/separateHouseholder', data);
};

const updateContact = (data) => {
  return ApiService.post('/api/crm/contact/update', data);
};

const updateContactFlags = (data) => {
  return ApiService.post('/api/crm/contact/updateFlags', data);
};

const updateContactRating = (data) => {
  return ApiService.post('/api/crm/contact/updateRating', data);
};


export {
  addSpouse,
  deleteContacts,
  deleteCustomField,
  deleteEmployee,
  getActivityForOverview,
  getContactAllNotes,
  getContactBusinessOptions,
  getContactById,
  getContactOverView,
  getContactByIdWithNextAppointmentDate,
  getContactCustomFields,
  getContactHouseholdOptions,
  getContactIndividualOptions,
  getContacts,
  getContactsForSpouse,
  getEmployees,
  saveContactNote,
  saveCustomField,
  saveEmployee,
  saveNewContact,
  splitHouseHold,
  updateContact,
  updateContactFlags,
  updateContactRating,
};

import styled, { css } from 'styled-components';
import FormHelperText from '@material-ui/core/FormHelperText';

import { COLORS } from '@constants/colors';

export const AdornmentWrapper = styled.div`
    position: relative;
    height: 16px;
    display: flex;
`;

export const DayTime = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  transform: translateX(calc(-100% - 11px));
  line-height: 1.2;
`;
export const Arrow = styled.div`
    width: 10px;
    position: relative;
    cursor: pointer;
    color:${ props => props.$isOpen
    ? COLORS.orange
    : COLORS.primaryText
};

    ${ props => props.$disabled && css` color: ${ COLORS.helpText }; `}

    &:after {
      width: 0;
      height: 0;
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: currentColor transparent transparent transparent;

      ${ props => props.$isOpen && css`
          border-width: 0 4px 5px 4px;
          border-color: transparent transparent currentColor transparent;
      `}
    }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  width: 100%;
  margin-top: 1px;
`;

import React from 'react';

import ListItemWithColorIndicator, { INDICATOR_TYPES } from '@components/ListItemWithColorIndicator';

import { SALES_CYCLE_STATUS_COLOR_MAP } from '@constants/salesActiveCycle';

import { ValueLabelObj } from '@/shared/types/commonTypes';
import { SalesActiveCycleStatusType } from '@/shared/types/salesActiveCycle';

export const SaleCycleStatusOption = (renderData: ValueLabelObj | ValueLabelObj[]) => {
  if (Array.isArray(renderData)) {
    return null;
  }
  return (
    <ListItemWithColorIndicator
      indicatorColor={ SALES_CYCLE_STATUS_COLOR_MAP[renderData.value as SalesActiveCycleStatusType] }
      label={ renderData.label as string }
      type={ INDICATOR_TYPES.round }
    />
  );
};

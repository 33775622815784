import styled from 'styled-components';

export const Wrapper = styled.div`
    flex-grow: 1;
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    color: ${props => props.$fontColor};
    background-color: ${props => props.$bgColor};

    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: ${props => props.$indicatorColor};
    }
`;

import { PasswordInputNamesType } from '@/pages/NewUserSettings/types';

export const PasswordFormInputNames: PasswordInputNamesType = {
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword',
};

export const PASSWORD_FORM_DEFAULT_VALUES = {
  [PasswordFormInputNames.newPassword]: '',
  [PasswordFormInputNames.confirmPassword]: '',
  [PasswordFormInputNames.oldPassword]: '',
};

import React, { useCallback, MouseEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { CATEGORIES, OFFICIAL_REQUEST_SUBCATEGORIES, TO_DOS_FIELDS_NAMES } from '@constants/todosData';

import { ColorStarInCircle } from '@/assets';
import { TwoLevelFilter, TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES } from '@modules/TwoLevelFilter';
import RowFilter from '@components/RowFilter';
import TypeButton from '@components/TypeButton';

import { BtnPanelWrapper, StarIconWrapper } from './styles';

import { RenderItemProps } from '@components/RowFilter/types';


export const BtnPanel = observer(() => {
  const { control } = useFormContext();

  const { isGlobalDisabled, notesUtilsStore } = useTodoPopupLocalStore();

  const disabled = Boolean(notesUtilsStore.alertType) || isGlobalDisabled;

  const {
    field: {
      value,
      onChange
    }
  } = useController({
    name: TO_DOS_FIELDS_NAMES.category,
    control,
  });

  const onClickButton = useCallback((value: string | number) => {
    onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFilterChange = useCallback(
    (setCurrentSelected: RenderItemProps['setCurrentSelected']) => (event: MouseEvent, value: string | number) => {
      onChange(value);
      if(setCurrentSelected) {
        setCurrentSelected(value);
      }
    }, [ onChange ]);
    
  return (
    <BtnPanelWrapper>
      <RowFilter
        initState={ value }
        items={ Object.values(CATEGORIES) }
        limit={ 11 }
        onChange={ onClickButton }
        renderItem={ ({
          content,
          currentSelected,
          indexForKey,
          isSelected,
          isSelectedFromMore,
          isShowMore,
          onClick,
          setCurrentSelected
        }) => (
          content === CATEGORIES.officialRequests ? (
            <TwoLevelFilter
              $disabled={ disabled }
              categoryName={ content }
              colorSchema={ TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.popupFilter }
              colorSchemaSelected={ TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.popupFilterSelected }
              initCategory={ value }
              onTypeFilterChange={ onClickFilterChange(setCurrentSelected) }
              selectedCategory={ currentSelected || null }
              startAdornment={ (
                <StarIconWrapper>
                  <ColorStarInCircle/>
                </StarIconWrapper>
              ) }
              subCategoryOptions={ OFFICIAL_REQUEST_SUBCATEGORIES }
            />
          ) : (
            <TypeButton
              BtnProps={ {
                disabled: disabled
              } }
              isActive={ (isSelected && !isShowMore) || isSelectedFromMore }
              key={ indexForKey }
              onClick={ onClick }
              value={ value }
            >
              { content }
            </TypeButton>
          ))
        }
      />
    </BtnPanelWrapper>
  );
});

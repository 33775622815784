import { buildPath } from '@/shared/utils/buildPath';
import {
  LISTS_DYNAMIC_ITEM_PATH, LISTS_DYNAMIC_TABS,
  LISTS_FOLDER_PATH,
  LISTS_MANUAL_ITEM_PATH,
  LISTS_MANUAL_TABS,
  LISTS_PATH
} from '@constants/routes/contacts';
import { ParentId } from '@/shared/types/lists';
import { LISTS_TYPES } from '@constants/lists';

export const getFolderLink = (id: string) => {
  return buildPath([LISTS_FOLDER_PATH, id]);
};

export const getPrevParentLink = (parentId?: ParentId) => {
  if(parentId){
    return buildPath([LISTS_FOLDER_PATH, parentId.toString()]);
  } else {
    return LISTS_PATH;
  }
};

type ListType = LISTS_TYPES.Dynamic | LISTS_TYPES.Manual;

export const getListItemLink = (listType: ListType, id: string) => {
  if(listType === LISTS_TYPES.Manual){
    const getManualLink = (id: string, subTab:string) => buildPath([LISTS_MANUAL_ITEM_PATH, id, subTab]);
    return {
      [LISTS_MANUAL_TABS.contacts]: getManualLink(id, LISTS_MANUAL_TABS.contacts),
      [LISTS_MANUAL_TABS.setup]: getManualLink(id, LISTS_MANUAL_TABS.setup)
    };
  }

  if(listType === LISTS_TYPES.Dynamic){
    const getDynamicLink = (id: string, subTab:string) => buildPath([LISTS_DYNAMIC_ITEM_PATH, id, subTab]);
    // DYNAMIC LIST TYPE
    return {
      [LISTS_DYNAMIC_TABS.criteria]: getDynamicLink(id, LISTS_DYNAMIC_TABS.criteria),
      [LISTS_DYNAMIC_TABS.contacts]: getDynamicLink(id, LISTS_DYNAMIC_TABS.contacts),
      [LISTS_DYNAMIC_TABS.setup]: getDynamicLink(id, LISTS_DYNAMIC_TABS.setup),
    };
  }
};
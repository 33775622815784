import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 4px 0 4px 12px;
`;

export const IconWrapper = styled.div`
  width: 12px;
  height: 12px;
  position: relative;
  margin-right: 7px;
  
    & > svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) ;
      width: 18px;
      height: 18px;
    }
`;

import React, { useEffect } from 'react';
import { FormProvider,useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';

import Modal from '@components/Modal';

import { FieldDetails, FieldOptions, Footer } from './components';

import { UserCustomFieldPopupProps } from './types';

import { useUserCustomFieldPopupStore } from './store';

import { validationSchema } from './validationSchema';

import { UserCustomFieldFormFields } from '@/shared/types/userSettingsUserCustomFields';
import Preloader from '@components/Preloader';


export const UserCustomFieldPopup = observer(({
  closeModal,
  modalProps
}: UserCustomFieldPopupProps) => {
  const { onSaveCallback, id, isUsedBefore = false } = modalProps;
  const isEdit =  Boolean(id);
  const header = `${isEdit ? 'Edit' : 'Add'} User-Defined Custom Field`;

  const {
    isLoad,
    init,
    resetStore,
    alertType
  } = useUserCustomFieldPopupStore();

  const methods = useForm<UserCustomFieldFormFields>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    init(methods, closeModal,onSaveCallback, id,);
    return resetStore;
  }, [id, init, methods, onSaveCallback, resetStore]);


  return (
    <Modal
      isCloseDisabled={ isLoad }
      onClose={ closeModal }
      title={ header }
    >
      <FormProvider { ...methods }>
        <Modal.Content
          $disabled={ isLoad || Boolean(alertType) }
          minHeight={ '160px' }
          padding={ '25px 30px 15px' }
          width={ '800px' }
        >
          <FieldDetails
            isEdit={ isEdit }
            isUsedBefore={ isUsedBefore }
          />
          <FieldOptions />
          { isLoad && <Preloader/> }
        </Modal.Content>
        <Footer/>
      </FormProvider>
    </Modal>
  );
});

import React, { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useStore } from '@store';

import {
  ACCOUNTS_AND_PLANS_STATUSES_COLOR_MAP,
  ACCOUNTS_AND_PLANS_FILTER_NAMES
} from '@constants/accountsAndPlanData';

import { MANAGED_STATUS, STATUS, USERS } from './data';
import { addFilterOptions } from '@/shared/utils/filterUtils';

import ListItemWithColorIndicator, { INDICATOR_TYPES } from '@components/ListItemWithColorIndicator';
import CheckboxDropDown from '@components/CheckboxDropDown';
import SideFilters from '@modules/SideFilters';
import SearchField from '@components/SearchField';

export const Filters = observer(() => {
  const accountsAndPlansStore = useStore().AccountsAndPlansStore;
  const {
    selectedFiltersCount,
    filtersData,
    filters
  } = accountsAndPlansStore;

  const usersDropDownData = useMemo(() => (
    addFilterOptions(
      USERS,
      filtersData?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.users]
    )
  ), [ filtersData ]);

  const mangedStatusDropDownData = useMemo(() => (
    addFilterOptions(
      MANAGED_STATUS,
      filtersData?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.managedStatus]
    )
  ), [ filtersData ]);

  const statusDropDownData = useMemo(() => (
    addFilterOptions(
      STATUS,
      filtersData?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.status]
    )
  ), [ filtersData ]);

  const onChangeDrop = useCallback((fieldName, newValue) => {
    accountsAndPlansStore.setFilters({
      [fieldName]: newValue,
    });
  }, [ accountsAndPlansStore ]);

  const onClearClick = useCallback(() => {
    accountsAndPlansStore.resetFilters();
  }, [ accountsAndPlansStore ]);

  const onNameChange = useCallback(({ target: { value } }) => {
    accountsAndPlansStore.setFilters({
      [ACCOUNTS_AND_PLANS_FILTER_NAMES.accountName]: value || null,
    });
  }, [ accountsAndPlansStore ]);

  const renderStatusItem = useCallback((label, value) => (
    <ListItemWithColorIndicator
      indicatorColor={ ACCOUNTS_AND_PLANS_STATUSES_COLOR_MAP[value] }
      label={ label }
      type={ INDICATOR_TYPES.square }
    />
  ), []);


  return (
    <SideFilters
      count={ selectedFiltersCount }
      isNonePointerEvents={ false }
      onClearClick={ onClearClick }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onNameChange }
          placeholder={ 'Search Accounts' }
          value={ filters?.[ACCOUNTS_AND_PLANS_FILTER_NAMES.accountName] || '' }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[usersDropDownData.name] || [] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ mangedStatusDropDownData.label }
          name={ mangedStatusDropDownData.name }
          setState={ onChangeDrop }
          state={ filters?.[mangedStatusDropDownData.name] || [] }
          values={ mangedStatusDropDownData.values }
        />
        <CheckboxDropDown
          label={ statusDropDownData.label }
          name={ statusDropDownData.name }
          renderItemLabel={ renderStatusItem }
          setState={ onChangeDrop }
          state={ filters?.[statusDropDownData.name] || [] }
          values={ statusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
});

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { useUserCustomFieldPopupStore } from '../../store';

import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import { COLOR_SCHEME_TYPES } from '@components/BlockButton';

import { FooterProps } from './types';

import { OPTIONS_ALERT_MESSAGES, OPTIONS_ALERT_TYPES } from '@modules/UserSettings/UserCustomFieldPopup/data';


export const Footer = observer(({}: FooterProps) => {
  const {
    onCancel,
    onConfirm,
    alertType,
    isLoad,
    isOptionsInEditMode
  } = useUserCustomFieldPopupStore();

  const alertMessage = useMemo(() => {
    if(!alertType){
      return alertType;
    }

    return OPTIONS_ALERT_MESSAGES[alertType];
  },[alertType]);

  return (
    <Modal.Footer>
      <Modal.FooterContentWrapper
        alertMessage={ alertMessage }
        boxProps={ {
          maxWidth: 500
        } }
      />
      <PopupActionsButtons
        CancelProps={ {
          disabled: isLoad,
          colorSchema: alertType === OPTIONS_ALERT_TYPES.remove
            ? COLOR_SCHEME_TYPES.warningOutlined
            : COLOR_SCHEME_TYPES.outlined
        } }
        SaveProps={ {
          disabled: isLoad || isOptionsInEditMode,
          colorSchema: alertType === OPTIONS_ALERT_TYPES.remove
            ? COLOR_SCHEME_TYPES.warningContained
            : COLOR_SCHEME_TYPES.contained
        } }
        cancelLabel={ alertType ? 'No' : 'Cancel' }
        onCancelClick={ onCancel }
        onSaveClick={ onConfirm }
        saveLabel={ alertType ? 'Yes' : 'Save' }
        wrapperProps={ {
          marginLeft: 'auto',
        } }
      />
    </Modal.Footer>
  );
});

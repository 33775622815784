import React, { ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react';

import {
  getViewOptions
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsActivities/utils';
import { useStore } from '@store';
import { ALL_ASSIGNED_TO_SELECT_OPTION } from '@constants/common';

import { UserSelectFilter } from '@modules/UserSelectFilter';
import AddActivity from '@components/Icons/AddActivity';
import BlockButton from '@components/BlockButton';
import CustomToggle, { useCustomToggleState } from '@components/CustomToggle';
import PreTableWrapper  from '@components/BasicLayoutComponents/PreTableWrapper';
import CustomSelect from '@components/CustomSelect';
import {
  TypeFilters
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsActivities/components';

import { SelectMenuWrapper, ToggleWrapper } from './styles';

import {
  CalendarPeriod,
  ContactActivityFiltersNames
} from '@services/store/contactDetailsActivitiesStore/types';
import { PreTablePanelProps } from './types';

export const PreTablePanel = observer(({
  onAddActivityClick,
}: PreTablePanelProps) => {

  const { isLoad } = useStore().ContactDetailsStore;
  const contactDetailsActivitiesStore = useStore().ContactDetailsActivitiesStore;
  const { period, table, filterData, filters, previousActivityToggleState } = contactDetailsActivitiesStore;

  const onUserFilterChange = useCallback((value: Array<string>) => {
    contactDetailsActivitiesStore.setFilters({
      [ContactActivityFiltersNames.Clients]: value
    });
  },[ contactDetailsActivitiesStore ]);

  const onPeriodChange = useCallback((event: ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    const { target: { value } } = event;
    contactDetailsActivitiesStore.setPeriod(value as CalendarPeriod);
  },[ contactDetailsActivitiesStore ]);

  const { checked, onChange } = useCustomToggleState({
    initValue: previousActivityToggleState,
    onChangeCb: ({ isChecked }) => {
      contactDetailsActivitiesStore.setPreviousActivityToggleState(isChecked);
      isChecked
        ? contactDetailsActivitiesStore.setAllStatusFilter()
        : contactDetailsActivitiesStore.setInitStatusFilter();
    }
  });

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }>
      <UserSelectFilter
        allUserOption={ ALL_ASSIGNED_TO_SELECT_OPTION }
        closeTrigger={ isLoad }
        onChange={ onUserFilterChange }
        usersOptions={ filterData?.[ContactActivityFiltersNames.Clients] }
        value={ filters?.[ContactActivityFiltersNames.Clients] ?? [] }
        withAllUserOption={ true }
      />

      <SelectMenuWrapper>
        <CustomSelect
          onChange={ onPeriodChange }
          options={ getViewOptions }
          style={ {
            width: '85px',
          } }
          value={ period }
        />
      </SelectMenuWrapper>

      <TypeFilters/>

      <ToggleWrapper>
        <CustomToggle

          checked={ checked }
          label={ 'Show Previous' }
          labelPlacement={ 'end' }
          onChange={ onChange }
        />
      </ToggleWrapper>

      <BlockButton
        onClick={ onAddActivityClick }
        startIcon={ <AddActivity /> }
      >
        Add Activity
      </BlockButton>

    </PreTableWrapper>
  );
});


import { ValueLabelObj } from '@/shared/types/commonTypes';

export const calendarTimesOptions = (): Array<ValueLabelObj> => {
  const options = [];
  for (let time = 0; time <= 23; time++) {
    options.push({
      value: `${time < 10 ? '0' : ''}${time}:00:00`,
      label: formatTime24_12(time),
    });
  }
  return options;
};

const formatTime24_12 = (time24: number): string => {
  let time = +time24;
  const period = time >= 12 && time <= 23
    ? 'pm'
    : 'am';
  if (time > 12) {
    time -= 12;
  }
  if (time === 0) {
    time = 12;
  }
  return `${time}:00 ${period}`;
};
import styled from 'styled-components';
import Cropper from 'react-cropper';
import { Dialog } from '@material-ui/core';

import { COLORS } from '@constants/colors';

export const ImageContainer = styled.div`
  height: 600px;

  & .cropper-point {
    background-color: ${COLORS.logoOrange} !important;
  }

  & .cropper-line {
    background-color: ${COLORS.logoOrange} !important;
  }

  & .cropper-view-box {
    outline: 1px solid ${COLORS.logoOrange} !important;
    outline-color: ${COLORS.logoOrange} !important;
  }

  & .cropper-container.cropper-bg {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const ImageWrapper = styled.img`
  width: 600px;
  height: 600px;
`;

export const CropperWrapper = styled(Cropper)`
  width: 600px;
  height: 600px;
`;


export const DialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    width: 600px;
    height: auto;
    padding: 4px 4px 0;
    overflow-y: clip;
  }
`;

export const ButtonsContainer = styled.div`
  width: 608px;
  padding: 20px 30px 20px 0;
  margin-left: -4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  box-shadow: 0px -1px 5px 0px rgba(16, 28, 91, 0.12);
`;


import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import BlockButton from '@components/BlockButton';
import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import ToggleWithInnerLabels, { VARIANTS } from '@components/ToggleWithInnerLabels';
import { ListCriteriaFieldsRow, ListCriteriaOperandField } from '../index';
import { PlusWithCircle } from '@/assets';

import {
  CRITERIA_FIELD_BLOCK_ITEM_NAMES,
  INIT_CRITERION_WITHOUT_VALUE,
  LIST_CRITERIA_FIELDS_KEY_NAME,
  OPERAND_OPTIONS
} from '@constants/lists';

import { CriteriaFormWrapper, CriterionFieldsType } from '@/shared/types/lists';
import { ListCriteriaFieldsBlockProps } from './types';

import { AddWrapper, CriteriaLabel, DeleteWrapper, Header, Wrapper } from './styles';

export const ListCriteriaFieldsBlock = ({
  name,
  index,
  onBlockDelete,
}: ListCriteriaFieldsBlockProps) => {
  const [operand, setOperand] = useState<string>(OPERAND_OPTIONS[1].value as string);
  const { control, getValues } = useFormContext<CriteriaFormWrapper>();

  const { append, remove,fields, update } = useFieldArray({
    control,
    //@ts-ignore  name always `[variable].${number}`
    name,
    keyName: LIST_CRITERIA_FIELDS_KEY_NAME
  });

  const allBlockFields = useWatch({
    control,
    //@ts-ignore
    name
  }) as CriterionFieldsType;

  const fieldsToRender = fields as CriterionFieldsType;


  const onBlockDeleteWrapper = useCallback(() => {
    onBlockDelete(index);
  }, [index, onBlockDelete]);

  const onRowDelete = useCallback(async  (index: number) => {
    if(index === 0 && allBlockFields?.length >= 2){
      const updateIndex = index + 1;
      const nextData = await getValues(`${name}.${updateIndex}` as any);
      delete nextData?.operand;
      //@ts-ignore
      await update(updateIndex, nextData);
      remove(index);
    }else {
      remove(index);
    }

  }, [allBlockFields?.length, getValues, name, remove, update]);

  const onRowAdd = useCallback(() => {
    const isWithOperand = allBlockFields?.length > 0;
    append({
      ...(
        isWithOperand
          ? { operand: operand as 'or' | 'and' }
          : {}
      ),
      //@ts-ignore
      criterion: INIT_CRITERION_WITHOUT_VALUE
    });
  }, [allBlockFields, append, operand]);

  return (
    <Wrapper>
      <Header>
        <CriteriaLabel>
          {`Criteria #${index + 1}`}
        </CriteriaLabel>
        <ToggleWithInnerLabels
          $variant={ VARIANTS.SECOND }
          onChange={ setOperand }
          options={ OPERAND_OPTIONS }
          value={ operand }
        />
        <AddWrapper>
          <BlockButton
            $iconSize={ '16px' }
            onClick={ onRowAdd }
            startIcon={ <PlusWithCircle/> }
          >
            Add Alternative
          </BlockButton>
        </AddWrapper>
        <DeleteWrapper>
          <CustomIconButton
            onClick={ onBlockDeleteWrapper }
            type={ ICON_BUTTON_TYPES.close }
          />
        </DeleteWrapper>
      </Header>
      {
        fieldsToRender.map((field, index) => (
          //@ts-ignore
          <Fragment key={ field?.[LIST_CRITERIA_FIELDS_KEY_NAME] }>
            {
              field.operand &&
              <ListCriteriaOperandField
                $paddingBottom={ 12 }
                $paddingTop={ 10 }
                name={ `${name}.${index}.${CRITERIA_FIELD_BLOCK_ITEM_NAMES.operand}` }
                value={ field.operand }
              />
            }
            <ListCriteriaFieldsRow
              index={ index }
              namePath={ `${name}.${index}.${CRITERIA_FIELD_BLOCK_ITEM_NAMES.criterion}` }
              onDelete={ onRowDelete }
            />
          </Fragment>
        ))
      }
    </Wrapper>
  );
};



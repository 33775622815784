import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ROUTES } from '@constants/routes';

import { PageHeader } from './components/PageHeader';
import Surface from '@components/BasicLayoutComponents/Surface';
import { AppPage } from '@modules/AppPage';
import {
  AccountSettings,
  CustomFieldsAndTags,
  MyProfile,
  Password,
  Users,
} from '@pages/NewUserSettings/components/Tabs';

import Preloader from '@/components/Preloader';

import { useStore } from '@/shared/services/store/store';
import { getIsAbleToSetUserPermission } from './utils';

export const NewUserSettingsPage = observer(() => {

  const { roles } = useStore().SettingsStore;
  const userSettingsStore = useStore().UserSettingsStore;
  const { isFetching } = userSettingsStore;
  const isAbleRole = getIsAbleToSetUserPermission(roles);

  return (
    <AppPage>
      <AppPage.AppHeader>
        <AppPage.AppPageTitle title={ 'User Settings' }/>
      </AppPage.AppHeader>
      <Surface>
        {
          isFetching && <Preloader />
        }
        <PageHeader />
        <Switch>
          <Route exact path={ ROUTES.userSettingsAccountSettings }>
            <AccountSettings />
          </Route>
          <Route path={ ROUTES.userSettingsMyProfile }>
            <MyProfile />
          </Route>
          <Route path={ ROUTES.userSettingsPassword }>
            <Password />
          </Route>
          { isAbleRole &&
            <Route path={ ROUTES.userSettingsUsers }>
              <Users />
            </Route>
          }
          <Route path={ ROUTES.userSettingsCustomFieldsAndTags }>
            <CustomFieldsAndTags/>
          </Route>
        </Switch>
      </Surface>
    </AppPage>
  );
});

import { ReactNode } from 'react';

import { BoxProps } from '@material-ui/core/Box';

export type WrapperProps = {
  $height?: string
  restBoxProps?: BoxProps
}

type CommonProps = {
  children?: ReactNode
} & WrapperProps;

export const WITH_FILTER_BTN_PROP_NAME  = 'withFilterButton' as const;

type WithFilterBtn = {
  [WITH_FILTER_BTN_PROP_NAME]: boolean

  badgeClickHandler: () => void
  badgeCount: number
  buttonCLickHandler: () => void
  enableFilterMode: boolean
}

type OnlyFilterBtn = WithFilterBtn & CommonProps;

export type PreHeaderProps = CommonProps | OnlyFilterBtn

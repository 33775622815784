import { format, parse } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const getDueDate = (dueDate: string | null) => {
  if(dueDate) {
    const parseDate = parse(dueDate, YEAR_MONTH_DAY, new Date());
    return format(parseDate, 'MM/dd/yyyy');
  }
  return '-';
};

import {
  CriteriaBackendFields,
  CriteriaBackendFormFields,
  CriteriaBlocks,
  CriteriaFormWrapper,
  CriterionType
} from '@/shared/types/lists';
import {
  CRITERIA_FIELD_NAMES,
  LIST_CRITERIA_ROOT_NAME,
  LIST_VALUE_NESTED_KEYS,
  OPERAND_FIELD_NAMES
} from '@constants/lists';
import {
  DATE_FIELDS,
  INPUTS_TYPE_NUMBER_FIELDS,
  INPUTS_TYPE_TEXT_FIELDS, IS_EMPTY_OR_IS_NOT_EMPTY,
  OPERAND_OPTIONS, SINGLE_SELECTS
} from '@constants/listsCriteria';
import { TagsOptions } from '@services/store/listsStore/subStores/listCriteriaStore/types';
import { GetAllTags } from '@/shared/types/commonTypes';
import { INIT_TAGS_OPTIONS } from '@services/store/listsStore/subStores/listCriteriaStore/data';

export const convertToFormState = (data: CriteriaBackendFormFields): CriteriaFormWrapper => {
  const processed = data.reduce((
    acc: CriteriaBlocks,
    currValue,
    index: number,
    array
  ) => {
    if(Array.isArray(currValue)){
      let operand = null;

      const criteria = currValue.reduce((
        innerAcc: Array<CriterionType>,
        currentValue,
        index,
        array
      ) => {
        if(CRITERIA_FIELD_NAMES.name in currentValue){
          let criterion = { ...currentValue };

          if(IS_EMPTY_OR_IS_NOT_EMPTY.includes(currentValue.optionValueFilter)){
            // @ts-ignore
            criterion.value = null;
          } else if(
            INPUTS_TYPE_NUMBER_FIELDS.includes(currentValue.option as any) ||
            INPUTS_TYPE_TEXT_FIELDS.includes(currentValue.option as any) ||
            DATE_FIELDS.includes(currentValue.option as any) ||
            SINGLE_SELECTS.includes(currentValue.option as any)
          ){
            if(currentValue.optionValueFilter === OPERAND_OPTIONS.In_Between){
              criterion.value = {
                // @ts-ignore
                [LIST_VALUE_NESTED_KEYS.first]: currentValue.value?.[0] || null,
                [LIST_VALUE_NESTED_KEYS.last]: currentValue.value?.[1] || null
              };
            } else {
              criterion.value = {
                // @ts-ignore
                [LIST_VALUE_NESTED_KEYS.first]: currentValue.value?.[0] || null,
              };
            }
          }


          let operand = null;

          const prevElemWithOperand = array[index - 1];
          if(prevElemWithOperand && OPERAND_FIELD_NAMES.typeWhere in prevElemWithOperand){
            operand = prevElemWithOperand.typeWhere;
          }

          innerAcc.push({
            criterion,
            ...(operand ? { operand } : {})
          });
        }

        return innerAcc;
      }, []);

      const outerPrevElemWithOperand = array[index - 1];
      if(outerPrevElemWithOperand && OPERAND_FIELD_NAMES.typeWhere in outerPrevElemWithOperand){
        operand = outerPrevElemWithOperand.typeWhere;
      }

      acc.push({
        criteria,
        ...(operand ? { operand } : {})
      });
    }

    return acc;
  }, []);

  return {
    [LIST_CRITERIA_ROOT_NAME]: processed
  };
};


export const convertFormToBackend = (data: CriteriaFormWrapper): CriteriaBackendFormFields => {
  return data[LIST_CRITERIA_ROOT_NAME].reduce((
    acc:CriteriaBackendFormFields,
    currValue,
  ) => {
    if(currValue.operand){
      acc.push({
        [OPERAND_FIELD_NAMES.typeWhere]: currValue.operand
      });
    }

    if(currValue.criteria.length > 0){
      const innerResult = currValue.criteria.reduce((
        innerAcc: CriteriaBackendFields,
        innerCurrValue
      ) => {
        if(innerCurrValue.operand){
          innerAcc.push({
            [OPERAND_FIELD_NAMES.typeWhere]: innerCurrValue.operand
          });
        }

        if(!innerCurrValue.criterion.value){
          innerAcc.push({
            ...innerCurrValue.criterion,
            value: [null]
          });
        }else if (LIST_VALUE_NESTED_KEYS.first in innerCurrValue.criterion.value){
          const newData = {
            ...innerCurrValue.criterion,
            value: [innerCurrValue.criterion.value[LIST_VALUE_NESTED_KEYS.first]]
          };
          if(LIST_VALUE_NESTED_KEYS.last in innerCurrValue.criterion.value){
            // @ts-ignore
            newData.value.push(innerCurrValue.criterion.value[LIST_VALUE_NESTED_KEYS.last]);
          }

          innerAcc.push(newData);
        }else if (Array.isArray(innerCurrValue.criterion.value)){
          innerAcc.push({
            ...innerCurrValue.criterion,
            value: Array.isArray(innerCurrValue.criterion.value) ? innerCurrValue.criterion.value: []
          });
        }
        return innerAcc;
      }, []);

      acc.push(innerResult);
    }

    return acc;
  }, []);
};

export const convertBackendTags = (tagsResp:GetAllTags): TagsOptions => {
  return Object.entries(tagsResp.data).reduce((acc: TagsOptions, data) => {
    const [key, dataValue] = data;
    acc[key as keyof TagsOptions] = dataValue.map((item) => ({
      value: item.id,
      label: item.label
    }));

    return acc;
  }, { ...INIT_TAGS_OPTIONS });
};

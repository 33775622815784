import { action, flow, makeAutoObservable } from 'mobx';

import { createSubClient } from '@/shared/services/api/userSettings/userSettings';

import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';

import { ENTITY_NAMES } from '@constants/common';
import { UserProfileFormInputNames } from '@pages/NewUserSettings/data';

import {
  TabsValues,
  UseFormMethods
} from '@pages/NewUserSettings/components/Tabs/Users/components/AddUserPopup/types';
import { AddUserErrorData, InitProps } from './types';
import { ClientWithCalendarData } from '@/shared/types/commonTypes';
import { convertToFormData } from '@/shared/services/store/userSettingsStore/helpers';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import axios from 'axios';
import { NOTIFICATION_TYPES } from '@constants/notifications';


class AddUserPopupLocalStore {
  advisorsList: Array<ClientWithCalendarData> = [];
  closeModal: () => void = () => {};
  currentTab: string = TabsValues.AddUser;
  isFetching: boolean = false;
  updateUsersList: () => void = () => {};
  useFormMethods: UseFormMethods = {} as UseFormMethods;
  usersList: Array<ClientWithCalendarData> = [];

  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper = {} as NotificationHelper;

  constructor() {
    makeAutoObservable(this, {
      onValidAction: flow,
      setAdvisorsList: action.bound,
      setUsersList: action.bound,
    });

    this.asyncRequestExecutor = new AsyncRequestExecutor();
  }

  init({ useFormMethods, closeModal, updateUsersList, notificationStore }: InitProps) {
    this.useFormMethods = useFormMethods;
    this.closeModal = closeModal;
    this.updateUsersList = updateUsersList;

    this.notificationHelper = new NotificationHelper(
      notificationStore,
      ENTITY_NAMES.user
    );
  }

  onSave() {
    this.useFormMethods.handleSubmit(
      this.onValidAction.bind(this),
      this.onInValidAction.bind(this)
    )();
  }

  async convertUrlToBlob(image: string | null) {
    if(image && typeof image === 'string') {
      return await fetch(image as unknown as URL)
        .then(blobFile => blobFile.blob() as unknown as string);
    }
    return '';
  }

  *onValidAction(data: UserProfileType) {
    let isValidationError = false;

    this.setIsFetching(true);
    try {
      const start = async () => {
        data.headshot = await this.convertUrlToBlob(data.headshot);
        data.logo = await this.convertUrlToBlob(data.logo);
        data.signature = await this.convertUrlToBlob(data.signature);

        const formData = convertToFormData(data);
        await createSubClient(formData);
      };

      const error = (error: any) => {
        if(axios.isAxiosError(error) && error?.response?.status === 422){
          isValidationError = true;
          const errorResponse = error.response?.data as AddUserErrorData;
          this.useFormMethods.setError(UserProfileFormInputNames.email, {
            type: 'custom',
            message: errorResponse.data.error.email
          });
        } else {
          this.notificationHelper.create({ status: NOTIFICATION_TYPES.error });
        }
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: error,
        onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success }),
        continueOnError: false
      });

      this.closeModal();
      yield this.updateUsersList();
    } catch(error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);

      if(!isValidationError){
        this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      }
    }
  }

  onInValidAction() {
    this.setCurrentTab(TabsValues.AddUser);
  }

  reset() {
    this.setCurrentTab(TabsValues.AddUser);
    this.setAdvisorsList([]);
    this.setUsersList([]);
  }

  setIsFetching(value: boolean) {
    this.isFetching = value;
  }

  setCurrentTab(newTabValue: string) {
    this.currentTab = newTabValue;
  }

  setAdvisorsList(data: Array<ClientWithCalendarData>){
    this.advisorsList = data;
  }

  setUsersList(data: Array<ClientWithCalendarData>) {
    this.usersList = data;
  }
}

export default AddUserPopupLocalStore;

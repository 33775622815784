import { ColumnDimensions, ColumnType } from '@components/Table/components/Header/types';
import { COLORS } from './colors';

export const STATUS_COLUMN_DIMENSIONS: ColumnDimensions = {
  style: {
    minWidth: '230px',
    maxWidth: '295px',
  }
};

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '18%'
    },
    sortable: true,
  },
  {
    id: 'linkedContactName',
    label: 'Linked Contact',
    style: {
      width: '20%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'accountNumber',
    label: 'Account #',
    style: {
      width: '13%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'balance',
    label: 'Balance',
    style: {
      width: '12%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'managedStatus',
    label: 'Managed Status',
    style: {
      width: '16%'
    },
    separator: true,
  },
  {
    id: 'status',
    label: 'Status',
    style: {
      width: '23%'
    },
    sortable: true,
    separator: true,
  },
];

export enum ACCOUNTS_AND_PLANS_TYPES {
  ACCOUNT = 'Account',
  POLICY ='Policy',
  PLAN ='Plan'
}

export enum ACCOUNTS_AND_PLANS_STATUSES  {
  PAPERWORK_APPLICATION = 'Paperwork / Application',
  APPLICATION_SCRUBBING = 'Application Scrubbing',
  SUBMITTED_WITH_TRANSFER = 'Submitted - With Transfer',
  SUBMITTED_WITH_CHECK = 'Submitted - With Check',
  IN_COMPLIANCE_REVIEW = 'In Compliance Review',
  PENDING_FUNDING = 'Pending - Funding',
  FUNDED_DELIVERY = 'Funded / Delivery',
  OPEN_ACTIVE = 'Open / Active',
  CLOSED_LOST = 'Closed - Lost',
  CLOSED_OTHER = 'Closed - Other'
}

export const ACCOUNTS_AND_PLANS_STATUSES_COLOR_MAP: Record<ACCOUNTS_AND_PLANS_STATUSES, string> = {
  [ACCOUNTS_AND_PLANS_STATUSES.PAPERWORK_APPLICATION]: COLORS.pink,
  [ACCOUNTS_AND_PLANS_STATUSES.APPLICATION_SCRUBBING]: COLORS.logoOrange,
  [ACCOUNTS_AND_PLANS_STATUSES.SUBMITTED_WITH_TRANSFER]: COLORS.yellow,
  [ACCOUNTS_AND_PLANS_STATUSES.SUBMITTED_WITH_CHECK]: COLORS.lightGreen,
  [ACCOUNTS_AND_PLANS_STATUSES.IN_COMPLIANCE_REVIEW]: COLORS.green,
  [ACCOUNTS_AND_PLANS_STATUSES.PENDING_FUNDING]: COLORS.blue,
  [ACCOUNTS_AND_PLANS_STATUSES.FUNDED_DELIVERY]: COLORS.purple,
  [ACCOUNTS_AND_PLANS_STATUSES.OPEN_ACTIVE]: COLORS.borders,
  [ACCOUNTS_AND_PLANS_STATUSES.CLOSED_LOST]: COLORS.red,
  [ACCOUNTS_AND_PLANS_STATUSES.CLOSED_OTHER]: COLORS.red,
};

export const ACCOUNTS_AND_PLANS_FILTER_NAMES = {
  accountName: 'name',
  accountType: 'accountType',
  contactId: 'contactId',
  managedStatus: 'managedStatus',
  status: 'status',
  users: 'createdBy',
} as const;

import React, {
  Children,
  cloneElement,
  useCallback,
  useRef,
  useState,
} from 'react';

import { StyledTooltip } from './styles';

function Tooltip({
  arrow = true,
  disableAnimation = false, // добавление нового пропа
  children,
  displayOnlyOnOverflow = false,
  $maxWidth = '',
  title,
  disabled = false,
  ...restProps
}) {
  const [open, setOpen] = useState(false);
  const childNodeRef = useRef(null);
  const childElement = Children.only(
    typeof children === 'object' ? children : <span>{children}</span>
  );
  const newChildElement = cloneElement(childElement, {
    ref: (element) => {
      childNodeRef.current = element;
      const childRef = childElement.ref;
      if (childRef) {
        if (typeof childRef === 'function') {
          childRef(element);
        } else {
          childRef.current = element;
        }
      }
    },
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = () => {
    const child = childNodeRef.current;
    if (
      displayOnlyOnOverflow &&
      child &&
      (child.offsetWidth < child.scrollWidth ||
        child.offsetHeight < child.scrollHeight)
    ) {
      setOpen(true);
    } else if (!displayOnlyOnOverflow) {
      setOpen(true);
    }
  };

  return (
    <StyledTooltip
      $maxWidth={ $maxWidth }
      arrow={ arrow }
      disableHoverListener={ disabled }
      onClose={ handleClose }
      onOpen={ handleOpen }
      open={ typeof restProps?.open === 'boolean' ? restProps?.open : open }
      title={ title }
      { ...(disableAnimation ? { TransitionComponent: ({ children }) => children } : {}) }
      { ...restProps }
    >
      {newChildElement}
    </StyledTooltip>
  );
}

export default Tooltip;

import { COLORS } from '@constants/colors';

export const TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES = {
  contactDetailsPanelFilter: 'contactDetailsPanelFilter',
  contactDetailsPanelFilterSelected: 'contactDetailsPanelFilterSelected',
  headerFilter: 'headerFilter',
  headerFilterSelected: 'headerFilterSelected',
  popupFilter: 'popupFilter',
  popupFilterSelected: 'popupFilterSelected',
} as const;

export const TWO_LEVEL_FILTER_STYLE_SCHEME_CONFIG = {
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.contactDetailsPanelFilter]: {
    $color: COLORS.primaryText,
    $bgColor: COLORS.background,
    $bgHoverColor: COLORS.greyLight,
    $borderColor: null,
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: null,
    $secondColor: null,
    $borderRadius: '20px',
  },
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.contactDetailsPanelFilterSelected]: {
    $color: COLORS.white,
    $bgColor: COLORS.orange,
    $bgHoverColor: COLORS.greyLight,
    $borderColor: null,
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: null,
    $secondColor: null,
    $borderRadius: '20px',
  },
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.headerFilter]: {
    $color: COLORS.primaryText,
    $bgColor: COLORS.background,
    $bgHoverColor: COLORS.borders,
    $borderColor: 'transparent',
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: null,
    $secondColor: COLORS.helpText,
    $borderRadius: '20px',
  },
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.headerFilterSelected]: {
    $color: COLORS.primaryText,
    $bgColor: COLORS.lightBlue,
    $bgHoverColor: COLORS.lightBlue,
    $borderColor: COLORS.blue,
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: null,
    $secondColor: COLORS.helpText,
    $borderRadius: '20px',
  },
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.popupFilter]: {
    $color: COLORS.primaryText,
    $bgColor: COLORS.white,
    $bgHoverColor: COLORS.white,
    $borderColor: COLORS.borders,
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: COLORS.blue,
    $secondColor: COLORS.helpText,
    $borderRadius: '6px',
  },
  [TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.popupFilterSelected]: {
    $color: COLORS.primaryText,
    $bgColor: COLORS.lightBlue,
    $bgHoverColor: COLORS.lightBlue,
    $borderColor: COLORS.blue,
    $hoverColor: COLORS.primaryText,
    $borderHoverColor: null,
    $secondColor: COLORS.helpText,
    $borderRadius: '6px',
  },
};

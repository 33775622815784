import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { restorePassword } from '@services/api/common/common';
import { useStore } from '@store';

import { Content } from '../components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Preloader from '@components/Preloader';

import { FORM_FIELD_NAMES, FORM_DEFAULTS_VALUES } from './data';
import { ROUTES } from '@constants/routes';
import { validationSchema } from './validationSchema';

import { InfoSubTitle } from './styles';


export const ResetPassword = () => {

  const { RouterStore } = useStore();

  const [ isLoading, setLoading ] = useState(false);
  const [ isSendEmail, setSendEmail ] = useState(false);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: FORM_DEFAULTS_VALUES,
  });

  const { control, handleSubmit } = methods;

  const onSubmitClick = useCallback(() => {
    handleSubmit(async (data) => {
      const params = { email: data.email };

      try {
        setLoading(true);

        await restorePassword(params);

        setSendEmail(true);
      } catch(error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [ handleSubmit, RouterStore ]);

  const onLogInClick = useCallback(() => {
    RouterStore.customPush(ROUTES.login);
  }, [ RouterStore ]);

  return (
    <Content>
      <Content.Header
        isVisibleLogInButton={ !isSendEmail }
        isVisibleSignUpButton={ false } // TODO !isSendEmail
      />

      <Content.Main>
        <Content.Title>Reset Your Password</Content.Title>
        { isSendEmail &&
          <>
            <InfoSubTitle>
              Please enter the email address associated with your VFM account. We will email
              you a temporary password reset link that you can use to reset your password.
            </InfoSubTitle>

            <BlockButton
              color={ 'secondary' }
              colorSchema={ COLOR_SCHEME_TYPES.outlined }
              onClick={ onLogInClick }
              variant='outlined'
            >
              Log in
            </BlockButton>
          </>
        }

        { !isSendEmail &&
          <>
            <InfoSubTitle>
              Please enter the email address associated with your VFM account. We will email
              you a temporary password reset link that you can use to reset your password.
            </InfoSubTitle>

            <Content.Label>Email Address</Content.Label>
            <HFTextInput
              control={ control }
              name={ FORM_FIELD_NAMES.email }
              placeholder={ 'Enter Email Address' }
            />

            <BlockButton
              colorSchema={ COLOR_SCHEME_TYPES.contained }
              onClick={ onSubmitClick }
              style={ {
                marginTop: '20px'
              } }
              variant='contained'
            >
              SUBMIT
            </BlockButton>
          </>
        }
      </Content.Main>

      { isLoading && <Preloader/> }
    </Content>
  );
};

import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';

import { DatePickerContext } from '@components/CustomDatePicker/components/DatePickerMain/DatePickerMain';

import { ToolbarWrapper } from './styles';

import { DatePickerToolbarPropsType } from '@components/CustomDatePicker/types';

export const DatePickerToolbar = (props:DatePickerToolbarPropsType) => {
  const { toggleIsOpen, setSelectedDate } = useContext(DatePickerContext);

  const onClickHandler = async () => {
    await setSelectedDate(new Date());
    toggleIsOpen();
  };

  return (
    <ToolbarWrapper>
      <Button
        color={ 'primary' }
        onClick={ onClickHandler }
        variant={ 'text' }>
        Today
      </Button>
    </ToolbarWrapper>
  );
};

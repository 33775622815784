import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const QuestionWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const Question = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${ COLORS.helpText };
  align-self: center;
  margin-right: 20px;
`;

export const RadioGroupWrapper = styled.div`
  & .MuiFormControlLabel-root{
    align-items: center;
  }
`;


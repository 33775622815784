import React, { createContext, useCallback, useMemo, useRef } from 'react';

import { useToggle } from '@customHooks';

import { DatePickerInput, DatePickerToolbar, } from '..';

import { convertFromOutputToViewFormat, convertToString, valueGuard } from '@components/CustomDatePicker/utils';

import { StyledKeyboardDatePicker, useStyles } from './styles';

import {
  DatePickerContextType,
  DatePickerInputPropsType,
  DatePickerMainProps,
  DatePickerToolbarPropsType,
  MuiDateType,
  MuiDateValueType,
} from '@components/CustomDatePicker/types';

export const DatePickerContext = createContext<DatePickerContextType>({} as DatePickerContextType);

const renderInput = (props:DatePickerInputPropsType) => <DatePickerInput { ...props } />;
const renderToolbar = (props:DatePickerToolbarPropsType) => <DatePickerToolbar { ...props } />;

export const DatePickerMain = ({
  DatePickerProps,
  dateFormat,
  disabled,
  errorMessage,
  mask,
  onChange,
  outputFormat,
  placeholder,
  preventMinDateAutoSet,
  showError,
  isDateWithTime,
  value,
}: DatePickerMainProps) => {
  const [ isOpen, toggleIsOpen ] = useToggle(false);
  const onChangePreventAutoSetRef = useRef<boolean>(false);
  const onAcceptPreventAutoSetRef = useRef<boolean>(false);
  const classes = useStyles();

  const popoverProps = useMemo(() => ({
    PaperProps: {
      classes: {
        root: classes.paperRoot
      }
    }
  }), [classes]);

  const onOpen = useCallback(() => {
    toggleIsOpen(true);
    if (preventMinDateAutoSet) {
      onChangePreventAutoSetRef.current = Boolean(errorMessage);
    }
  }, [ preventMinDateAutoSet, errorMessage, toggleIsOpen ]);

  const onAccept = useCallback((date: MuiDateType) => {
    if (preventMinDateAutoSet) {
      if (onAcceptPreventAutoSetRef.current) {
        toggleIsOpen(true);
        onAcceptPreventAutoSetRef.current = false;
      } else {
        const dateToString = convertToString(date, value, outputFormat);
        onChange(dateToString);
        toggleIsOpen(false);
      }
    } else {
      toggleIsOpen(false);
    }
  }, [ preventMinDateAutoSet, toggleIsOpen, onChange, outputFormat, value ]);

  const convertedValue = useMemo(() => {
    const dateValue = isDateWithTime && value ? value.split(' ')[0] : value;

    const guardedValue = valueGuard(dateValue);
    return convertFromOutputToViewFormat(guardedValue, outputFormat, dateFormat);
  }, [isDateWithTime, value, outputFormat, dateFormat]);

  const onDateChange = useCallback((date: MuiDateType, value: MuiDateValueType) => {
    if (preventMinDateAutoSet && onChangePreventAutoSetRef.current) {
      onChangePreventAutoSetRef.current = false;
      onAcceptPreventAutoSetRef.current = true;
      return;
    }
    const dateToString = convertToString(date, value, outputFormat);
    onChange(dateToString);
  }, [ preventMinDateAutoSet, onChange, outputFormat ]);

  return (
    <DatePickerContext.Provider value={ {
      errorMessage,
      isOpen,
      setSelectedDate: onDateChange,
      showError,
      toggleIsOpen,
    } }>
      <StyledKeyboardDatePicker
        PopoverProps={ popoverProps }
        TextFieldComponent={ renderInput }
        ToolbarComponent={ renderToolbar }
        disabled={ disabled }
        format={ dateFormat }
        inputValue={ convertedValue }
        inputVariant={ 'outlined' }
        mask={ mask }
        onAccept={ onAccept }
        onChange={ onDateChange }
        onClose={ toggleIsOpen }
        onOpen={ onOpen }
        open={ isOpen }
        placeholder={ placeholder }
        value={ null }
        variant={ 'inline' }
        { ...DatePickerProps }
      />
    </DatePickerContext.Provider>
  );
};

type Object = Record<string, any>
export const compareTwoObjectsAndFindDiffFieldNames = (main: Object, second: Object): Array<string> => {
  let notEqualFields: string[] = [];

  for (let prop in main) {
    if (Object.prototype.hasOwnProperty.call(main, prop) &&
      Object.prototype.hasOwnProperty.call(second, prop) &&
      main[prop] !== second[prop]
    ) {
      notEqualFields.push(prop);
    }
  }

  return notEqualFields;
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.greyLight};
  padding-bottom: 25px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  
  border-bottom: 1px solid ${COLORS.greyLight};
  background-color: ${COLORS.background};
`;

export const CriteriaLabel = styled.span`
  font-size: 14px;
  color: ${COLORS.header};
  margin: 0 20px;
`;

export const AddWrapper = styled.div`
  margin-left: auto;
`;

export const DeleteWrapper = styled.div`
  margin-left: 10px;
  margin-right: 20px;
`;

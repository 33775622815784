import { YellowChartLine, GreenChartLine, RedChartLine } from '@/assets';

import { DashboardAccountAndPlansTabData, QuarterSchemeType } from '@/shared/types/dashboard';

export const DASHBOARD_ACCOUNT_AND_PLANS_TYPES = {
  'annuities': 'Annuities',
  'aum': 'AUM',
  'lifeInsurance': 'Life Insurance'
} as const;

export const DASHBOARD_LINE_TABS: Array<DashboardAccountAndPlansTabData> = [
  {
    label: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.annuities,
    value: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.annuities,
    icon: GreenChartLine
  },
  {
    label: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.aum,
    value: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.aum,
    icon: YellowChartLine
  },
  {
    label: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.lifeInsurance,
    value: DASHBOARD_ACCOUNT_AND_PLANS_TYPES.lifeInsurance,
    icon: RedChartLine
  },
];

export const DASHBOARD_FILTER_NAMES = {
  adviserId: 'adviserId',
} as const;

export const PERIOD_FILTER_NAMES = {
  year: 'year',
  quarter: 'quarter',
} as const;

export const PERIOD_OPTIONS = [{
  value: 'year',
  label: 'Year'
}, {
  value: 'quarter',
  label: 'Quarter'
}];

export const QUARTERS_SCHEME: Record<number, QuarterSchemeType> = {
  1: {
    number: 1,
    firstMonthNumber: 0,
    endMonthNumber: 2,
  },
  2: {
    number: 2,
    firstMonthNumber: 3,
    endMonthNumber: 5,
  },
  3: {
    number: 3,
    firstMonthNumber: 6,
    endMonthNumber: 8,
  },
  4: {
    number: 4,
    firstMonthNumber: 9,
    endMonthNumber: 11,
  },
};

export const ALL_SELECT_OPTION = {
  label: 'All',
  value: 'all'
};

import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import Button from '@material-ui/core/Button';

import { StyledTypeButtonProps } from '@components/TypeButton/types';

export const StyledTypeBtn = styled(Button)<StyledTypeButtonProps>`
  min-width: auto;
  padding: 8px 15px;
  background-color: ${ COLORS.white };
  color: ${COLORS.primaryText};
  border-color: ${COLORS.borders};
  font-weight: 300;
  
  &:hover {
    border-color: ${COLORS.orange};
    background-color: ${ COLORS.white };
  }

  ${({ $isActive }) => $isActive && css`
    border-color: ${COLORS.orange};
    color: ${COLORS.orange};
    background-color: ${ COLORS.select };
  `};
  
  & svg {
    fill: currentColor;

    & path{
      fill: currentColor;
    }
  }
`;

import { getSortedUserOption, getUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';

import { ConvertedListsFiltersData, FiltersData } from '@/shared/types/lists';

export const convertsFilterData = (data: FiltersData, currentUserId: number): ConvertedListsFiltersData => {
  return {
    listOwner: data?.listOwner
      ? data.listOwner.map(listOwnerItem => {
        return getUserOption({
          userObj: listOwnerItem
        });
      })
      : [],
    classification: data?.classification ? optionsFromValue(data.classification) : [],
    status: data?.status ? optionsFromValue(data.status) : [],
    officePrimaryAdvisor: data?.users ? getSortedUserOption(data.users, currentUserId) : [],
  };
};

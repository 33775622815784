import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const ButtonsGroupWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;
export const BtnWrapper = styled.div`
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }
`;

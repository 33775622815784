import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';
import { ALL_ASSIGNED_TO_SELECT_OPTION } from '@constants/common';

import { UserSelectFilter } from '@modules/UserSelectFilter';

import { TrashFiltersNames, TrashTabValues } from '@pages/Trash/types';

import { Wrapper } from './styles';


export const UsersFilter = observer(() => {
  const trashStore = useStore().TrashStore;
  const { filters, filtersData, isPageLoads, currentTrashView } = trashStore;

  const userFilterName = currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks ?
    TrashFiltersNames.Clients : TrashFiltersNames.Users;

  const allUserOption = useMemo(() => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      return ALL_ASSIGNED_TO_SELECT_OPTION;
    }
  }, [currentTrashView]);

  const onChange = useCallback((newValue: Array<string>) => {
    if(currentTrashView === TrashTabValues.Activities || currentTrashView === TrashTabValues.Tasks) {
      trashStore.setFilters({
        [TrashFiltersNames.Clients]: newValue,
      });  
    } else {
      trashStore.setFilters({
        [TrashFiltersNames.Users]: newValue,
      });
    }
  }, [trashStore, currentTrashView]);

  return (
    <Wrapper>
      <UserSelectFilter
        allUserOption={ allUserOption }
        closeTrigger={ isPageLoads }
        onChange={ onChange }
        usersOptions={ filtersData?.[userFilterName] || [] }
        //@ts-ignore
        value={ filters?.[userFilterName] }
      />
    </Wrapper>
  );
});

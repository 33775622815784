import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

export const SearchBarWrapper = styled(MuiBox)`
  display: flex;
  align-items: center;
  gap: 18px;
  height: 44px;
  padding: 0 20px;
  background-color: ${COLORS.background};
`;

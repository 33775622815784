import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { CSS_VARIABLE_NAMES } from '@constants/style';

const SHADOW_COLOR = 'rgba(7, 13, 37, 0.12)';

export const Panel = styled(Drawer)`
  left: var(${CSS_VARIABLE_NAMES.menuWidth}) !important;

  & .MuiBackdrop-root {
    left: var(${CSS_VARIABLE_NAMES.menuWidth});
    background: ${COLORS.white}80;
  }

  & .MuiDrawer-paper {
    width: 620px;
    box-shadow: -2px 0px 10px 0px ${SHADOW_COLOR};
    overflow: hidden;
    box-sizing: border-box;
  }
`;

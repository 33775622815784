import React, { ElementType, useMemo } from 'react';

import { PlusWithCircle } from '@/assets';

import { usePopupTableState } from './usePopupTableState';
import { sortCompareFn } from '@modules/AccountsAndPlansPopup/utils/sortCompareFn';

import ContentCenter from '@components/ContentCenter';
import BlockButton from '@components/BlockButton';
import FormBlock from '@components/FormBlock';
import Table from '@components/Table';

import { CellWrapper, PopupTableActions } from './components';

import { AccountAndPlansPopupTableProps, ExtendType, FieldWithOriginalIndex } from './types';


export const PopupTable = <
  TFormFieldsType extends ExtendType,
  TFormFieldType extends {}
> ({
    addButtonIcon = PlusWithCircle,
    addButtonLabel,
    columns,
    disable,
    generateNewItem,
    isBorderBottom = true,
    isReturnLastIfEqual = false,
    marginBottom,
    name,
    onRowAddEditEnd,
    onRowAddEditStart,
    renderRow,
    title,
  }: AccountAndPlansPopupTableProps<TFormFieldsType, TFormFieldType>) => {
  const IconComponent = useMemo(() => addButtonIcon as ElementType, [addButtonIcon]);

  const {
    control,
    editedIndex,
    fieldsToRender,
    getSiblingPath,
    onAddClick,
    onDelete,
    onEdit,
    onRowClick,
    onSave,
    setValue,
  } = usePopupTableState<TFormFieldsType, TFormFieldType>({
    generateNewItem,
    name,
    onRowAddEditEnd,
    onRowAddEditStart,
  });

  const processedFields = useMemo(() => {
    const compareFn = sortCompareFn<FieldWithOriginalIndex<TFormFieldType>>({
      direction: 'desc',
      isReturnLastIfEqual,
    });
    return fieldsToRender.sort(compareFn);
  }, [fieldsToRender, isReturnLastIfEqual]);

  const isTableAtAddEditMode = typeof editedIndex === 'number';

  return (
    <FormBlock 
      isBorderBottom={ isBorderBottom }
      marginBottom={ marginBottom }
      paddingBottom={ 20 }
    >
      <FormBlock.Header title={ title }>
        <ContentCenter 
          $width={ '200px' }
          acceptStylesToChild={ true }
        >
          <BlockButton
            disabled={ isTableAtAddEditMode || disable }
            onClick={ onAddClick }
            startIcon={ <IconComponent /> }
          >
            { addButtonLabel }
          </BlockButton>
        </ContentCenter>
      </FormBlock.Header>
      <Table>
        <Table.Container
          $height={ 'auto' }
        >
          <Table.Header
            columns={ columns }
          />
          <Table.Body>
            {
              processedFields.map(({ data, originalIndex }, index) => (
                renderRow({
                  control,
                  disable: Boolean(disable),
                  disableDelete:(isTableAtAddEditMode && originalIndex !== editedIndex) || disable || false,
                  disableEdit: (isTableAtAddEditMode && originalIndex !== editedIndex) || disable || false,
                  field: data,
                  fieldPath: `${name}.${originalIndex}`,
                  fields: processedFields,
                  getSiblingPath,
                  index,
                  isEdit: originalIndex === editedIndex,
                  onDelete,
                  onEdit,
                  onRowClick,
                  onSave,
                  originalIndex,
                  setValue,
                })
              ))
            }
          </Table.Body>
        </Table.Container>
      </Table>
    </FormBlock>
  );
};

PopupTable.Actions = PopupTableActions;
PopupTable.CellWrapper = CellWrapper;
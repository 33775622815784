import React, { useMemo } from 'react';

import { Alert } from '@/assets';

import { Wrapper, Text, IconWrapper, IntroText } from './styles';

import { AttentionBlockProps } from './types';

export const AttentionBlock = ({
  $lineHeight,
  $padding,
  $withLeftLine,
  icon,
  introText = 'NOTE: ',
  text,
}: AttentionBlockProps) => {
  const Icon = useMemo(() => icon || Alert, [icon]);
  
  return (
    <Wrapper 
      $padding={ $padding }
      $withLeftLine={ $withLeftLine }
    >
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Text $lineHeight={ $lineHeight }>
        <IntroText>
          {introText}
        </IntroText>
        &nbsp;
        {text}
      </Text>
    </Wrapper>);
};

import React from 'react';

import PopupActionsButtons from '@components/PopupActionsButtons';

import { FooterProps } from './types';

import { TextWrapper, Wrapper } from './styles';

export const Footer = ({
  closeModal,
  onSave,
  onAttentionCancel,
  onAttentionConfirm,
  isAttentionMode,
}: FooterProps) => {

  const onCancelClick =  isAttentionMode 
    ? onAttentionCancel 
    : closeModal;
  const onSaveClick =  isAttentionMode 
    ? onAttentionConfirm
    : onSave;
  
  const saveLabel = isAttentionMode
    ? 'Yes'
    : 'Save';

  return (
    <Wrapper>
      {
        isAttentionMode &&
        <TextWrapper>
          {'If you switch to another type, all the published information will be lost. Continue?'}
        </TextWrapper>
      }
      <PopupActionsButtons
        onCancelClick={ onCancelClick }
        onSaveClick={ onSaveClick }
        saveLabel={ saveLabel }
        wrapperProps={ {
          marginLeft: 'auto',
        } }
      />
    </Wrapper>
  );
};

import styled, { css } from 'styled-components';
import { PopupButtonPanelItemsWrapperProps } from '@components/PopupButtonPanelItems/types';

export const Wrapper = styled.div<PopupButtonPanelItemsWrapperProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  
  & > * {
    ${({ $elemMarginRight }) => $elemMarginRight && css`
        margin-right: ${$elemMarginRight};

        &:last-child {
          margin-right: 0;
        }
    `};
  }
`;

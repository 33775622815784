import { COLORS } from '@constants/colors';

export const ACCOUNT_AND_PLANS_TYPE_SCHEME = [{
  colorText: COLORS.green,
  totalAmountText: 'Total Assets',
  type: 'Accounts',
}, {
  colorText: COLORS.pink,
  totalAmountText: 'Assets Managed ',
  type: 'Plans',
}, {
  colorText: COLORS.blue,
  totalAmountText: 'Assets Managed ',
  type: 'Policy',
}];

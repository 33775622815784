import { LoginFieldNames } from './types';

export const FORM_FIELD_NAMES: LoginFieldNames = {
  email: 'email',
  password: 'password',
};

export const FORM_DEFAULTS_VALUES = {
  [FORM_FIELD_NAMES.email]: '',
  [FORM_FIELD_NAMES.password]: ''
};

export const COMMON_ERROR_MESSAGE = 'Invalid Email or Password';

import React from 'react';

import {
  AccountPolicyValues,
  PrimaryDetails,
  AdditionalDetails,
  Beneficiaries
} from './components';

export const CommonBasicInformation = () => {

  return (
    <>
      <PrimaryDetails />
      <AccountPolicyValues />
      <AdditionalDetails />
      <Beneficiaries />
    </>
  );
};

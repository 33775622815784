import React, { useCallback } from 'react';

import { useStore } from '@store';

import { observer } from 'mobx-react';

import { ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@constants/accountsAndPlanData';
import { COLUMNS } from '@constants/accountsAndPlanData';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';

import { AccountAndPlansRow } from '@modules/AccountAndPlansRow';
import { AccountAndPlansRowPreTablePanel } from '@modules/AccountAndPlansRowPreTablePanel';
import { AccountsAndPlansPopup } from '@modules/AccountsAndPlansPopup';
import { DeleteAccountsAndPlansPopup } from '@modules/DeleteAccountsAndPlansPopup';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table from '@components/Table';


export const AccountAndPlansTable = observer(() => {
  const accountsAndPlansStore = useStore().AccountsAndPlansStore;
  const {
    table,
    isLoad,
    filters,
    filtersData,
    isPageActive,
  } = accountsAndPlansStore;
  const modalStore = useStore().ModalStore;

  const openPopup = useCallback((id) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ACCOUNTS_AND_PLANS_MODAL,
      component: AccountsAndPlansPopup,
      modalProps: {
        outerOnSubmit: accountsAndPlansStore.saveAccountsAndPlansBy.bind(accountsAndPlansStore),
        id,
      }
    }); }, [accountsAndPlansStore, modalStore]);

  const onAddClick = useCallback(() => openPopup(), [openPopup]);
  const onEdit = useCallback(id => openPopup(id), [openPopup]);

  const onUserFilterChange = useCallback((value) => {
    accountsAndPlansStore.setFilters({
      [ACCOUNTS_AND_PLANS_FILTER_NAMES.users]: value
    });
  }, [ accountsAndPlansStore ]);

  const onTypeFilterChange = useCallback((value) => {
    const newValue = value === FILTER_ALL_BTN_LABEL
      ? null
      : {
        [ACCOUNTS_AND_PLANS_FILTER_NAMES.accountType]: value
      };
    accountsAndPlansStore.setTypeFilter(newValue);
  }, [ accountsAndPlansStore ]);

  const onRowBookmark = useCallback((id, state) => {
    accountsAndPlansStore.updateFlags([{ id }], state);
  }, [ accountsAndPlansStore ]);

  const onDeleteClickAction = useCallback((idsData) => {
    const arrayOfIds = Array.isArray(idsData) ? idsData : [idsData];

    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_ACCOUNT_AND_PLANS_CONFIRMATION,
      modalProps: {
        arrayOfIds,
        deleteAccountsAndPlans: accountsAndPlansStore.removeAccountsAndPlans
      },
      component: DeleteAccountsAndPlansPopup
    });
  }, [accountsAndPlansStore, modalStore]);

  return (
    <>
      <AccountAndPlansRowPreTablePanel
        filters={ filters }
        filtersData={ filtersData }
        isLoad={ isLoad }
        onAddClick={ onAddClick }
        onDeleteClick={ onDeleteClickAction }
        onTypeFilterChange={ onTypeFilterChange }
        onUserFilterChange={ onUserFilterChange }
        table={ table }
      />
      <Table>
        <Table.Container triggerScrollToTop={ table.currentPage }>
          <Table.Header
            columns={ COLUMNS }
            globalCheckboxProps={ {
              checked: table.globalCheckboxState.checked,
              indeterminate: table.globalCheckboxState.indeterminate,
              onChange: table.onGlobalCheckboxClick,
            } }
            onSortChangeCb={ table.setMultipleSorting }
            sortingState={ table.multipleSorting }
            withBookmarkPlaceholder
            withGlobalCheckbox
          />
          <Table.Body>
            {
              isPageActive && !isLoad && table.items.length > 0 &&
              table.items.map((accountAndPlanItem) => (
                <AccountAndPlansRow
                  accountAndPlansItem={ accountAndPlanItem }
                  isCheckboxActive={ table.getItemCheckboxState(accountAndPlanItem) }
                  isLinkedContactClickable={ true }
                  key={ accountAndPlanItem.id }
                  onCheckboxClickCb={ table.onCheckBoxClick }
                  onDeleteAction={ onDeleteClickAction }
                  onEditAction={ onEdit }
                  onRowBookmarkCb={ onRowBookmark }
                />
              ))
            }
          </Table.Body>
          {
            isPageActive && !isLoad && table.items.length === 0 &&
            <ContentCenter>
              <NoDataItemsView
                addActionButtonClickHandler={ onAddClick }
                addActionButtonLabel={ 'Add Account or Plan' }
                withAction={ true }
              />
            </ContentCenter>
          }
        </Table.Container>
        <Table.Footer
          currentPage={ table.currentPage }
          onPageChangeCb={ table.setCurrentPage }
          pagesCount={ table.totalPages }
        >
          <Table.FooterCountInfo
            from={ table.itemsFrom }
            to={ table.itemsTo }
            total={ table.totalItems }
          />
        </Table.Footer>
      </Table>
    </>
  );
});

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm, FormProvider } from 'react-hook-form';

import { useStore } from '@store';

import Surface from '@components/BasicLayoutComponents/Surface';
import { FormChangeWatcher, ListSetupContactsByType, ListSetupForm } from './components';

import { ListSetupProps } from './types';

import { Wrapper } from './styles';
import { SaveListData } from '@/shared/types/lists';

export const ListSetup = observer(({
  listType,
  listId
}: ListSetupProps) => {
  const listSetupStore = useStore().ListsStore.ListSetupStore;
  const methods = useForm<SaveListData>();

  useEffect(() => {
    listSetupStore.init(listId, methods);
    return () => listSetupStore.resetStore();
    // eslint-disable-next-line
  }, []);

  return (
    <Surface.Content
      $isWrap={ false }
    >
      <FormProvider { ...methods }>
        <FormChangeWatcher />
        <Wrapper>
          <ListSetupForm />
          <ListSetupContactsByType />
        </Wrapper>
      </FormProvider>
    </Surface.Content>
  );
});

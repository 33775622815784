import { Store } from '@store';
import { action, makeAutoObservable } from 'mobx';
import { VIEW_TYPES } from '@constants/common';
import { SalesActiveCycleViewType } from '@/shared/types/salesActiveCycle';

import {
  SalesActiveCycleTable,
  SalesActiveCycleKanban,
  SalesClientOpportunitiesTable,
} from './subStores';
import { salesCycleTabs } from '@constants/routes/salesCycle';

export class SalesCycleStore {
  coreStore: Store;
  currentView: SalesActiveCycleViewType = VIEW_TYPES.table;
  currentTab: string = salesCycleTabs.activeSalesCycles;
  isLoad: boolean = true;

  SalesActiveCycleTable: SalesActiveCycleTable;
  SalesActiveCycleKanban: SalesActiveCycleKanban;
  SalesClientOpportunitiesTable: SalesClientOpportunitiesTable;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      resetStore: action.bound
    });
    this.coreStore = coreStore;

    this.SalesActiveCycleKanban = new SalesActiveCycleKanban(coreStore);
    this.SalesActiveCycleTable = new SalesActiveCycleTable(coreStore);
    this.SalesClientOpportunitiesTable = new SalesClientOpportunitiesTable(coreStore);
  }

  get nestedStore () {
    if(this.currentTab === salesCycleTabs.activeSalesCycles){
      if(this.currentView === VIEW_TYPES.table){
        return this.SalesActiveCycleTable;
      } else {
        return this.SalesActiveCycleKanban;
      }
    }

    // if(this.currentTab === salesCycleTabs.clientOpportunities)
    return this.SalesClientOpportunitiesTable;
  }

  setCurrentTab = (newTab: string) => {
    this.currentTab = newTab;

    // this.currentView = VIEW_TYPES.table;
  };

  changeView = (view: SalesActiveCycleViewType) => {
    this.currentView = view;
  };

  resetStore = () => {
    this.currentView = VIEW_TYPES.table;
    this.isLoad = true;
  };

}

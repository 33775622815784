import { useCallback, useState } from 'react';

import { UseToggleStateType, UseToggleReturnType } from './types';
export const useToggle = (initialState: UseToggleStateType = false): UseToggleReturnType  => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback((forcedState?: UseToggleStateType) => {
    if (typeof forcedState === 'boolean') {
      return setState(forcedState);
    }
    return setState((prevState) => !prevState);
  }, []);
  return [state, toggle];
};

import styled from 'styled-components';

export const NotesAndHistoryWrapper = styled.div`
  height: calc(100% - 128px);

  & > div {
    max-width: none;
    height: 100%;
  }
`;

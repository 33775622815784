import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import { BUSINESS_PROFILE_LINKS } from '@constants/routes/contacts';

import {
  CommonCustomFields,
  CommonSystemDetails,
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components';

import {
  BusinessDetails,
  BusinessEmployees,
} from './components';

import { BusinessContentTabsProps } from './types';

const isNotContactTab = (subTab:string | null) => {
  return subTab === BUSINESS_PROFILE_LINKS.customFields || subTab === BUSINESS_PROFILE_LINKS.employees;
};

export const BusinessContentTabs = observer(({
  contactId,
  subTab
}: BusinessContentTabsProps) => {
  const contactDetailsProfileStore = useStore().ContactDetailsProfileStore;
  const useFormMethods = useFormContext();

  const [prevTab, setPrevTab] = useState<string | null>(null);
  const [prevContactId, setPrevContactId] = useState<string | number | null>(null);

  useEffect(() => {
    setPrevTab(subTab);
    setPrevContactId(contactId);

    contactDetailsProfileStore.resetStateOnTabSwitch();

    if(isNotContactTab(subTab)){
      return;
    }

    if(!prevTab || isNotContactTab(prevTab) || prevContactId !== contactId){
      contactDetailsProfileStore.init(contactId, useFormMethods);
    }
    // eslint-disable-next-line
  },[subTab, contactId]);

  switch (subTab) {
  case BUSINESS_PROFILE_LINKS.businessDetails:
    return <BusinessDetails />;
  case BUSINESS_PROFILE_LINKS.customFields:
    return <CommonCustomFields contactId={ contactId }/>;
  case BUSINESS_PROFILE_LINKS.employees:
    return <BusinessEmployees contactId={ contactId }/>;
  case BUSINESS_PROFILE_LINKS.systemDetails:
    return <CommonSystemDetails />;
  default:
    throw new TypeError('Unexpected subTab for Business type');
  }
});

import styled from 'styled-components';

export const Spacer = styled.div`
  width: 1500px;
  
  height: 620px;

  @media screen and (min-height: 1080px) {
    height: 764px;
  }
`;

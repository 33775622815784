import React from 'react';

import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';

import { COLORS_MAP_BY_EVENT_TYPE } from '@/shared/constants/colorsByEventTypes';
import { typeConverter } from '@/shared/utils/typeConverter';

import { CategoryLabelProps } from './types';

export const CategoryLabel = ({
  label,
  value
}: CategoryLabelProps) => {
  const color = COLORS_MAP_BY_EVENT_TYPE[typeConverter({ isBackend: true, type: value })];
  return (
    <ListItemWithColorIndicator
      indicatorColor={ color?.indicatorColor }
      label={ label }
    />
  );
};

import styled from 'styled-components';

export const LocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  & > *{
    width: 230px;
    margin-right: 7px;
  }

  & > *:last-child{
    width: 105px;
    margin-right: 0;
  }
`;

import React, { forwardRef, useMemo } from 'react';

import { useSnackbarLogic } from '../../utils';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';

import { IconWrapper, Wrapper, MessageWrapper, ActionWrapper } from './styles';

import { NOTIFICATION_ITEM_CONFIG } from './data';

import { NotificationCustomItemProps } from './types';

export const NotificationCustomItem = forwardRef<HTMLDivElement, NotificationCustomItemProps>((props, ref) => {
  const {
    id,
    message,
    variant,
    action,
    autoHideDuration
  } = props;

  const { closeNotification } = useSnackbarLogic({
    key: id,
    autoHideDuration,
  });

  const {
    $fontColor,
    $indicatorColor,
    $iconColor,
    icon,
    $bgColor
  } = NOTIFICATION_ITEM_CONFIG[variant];

  const Icon = icon as React.ElementType;
  const actionContent = useMemo(() => {
    if(!action){
      return null;
    }

    if(typeof action === 'function'){
      return action(id);
    }

    return action;
  },[action, id]);


  return (
    <Wrapper
      $bgColor={ $bgColor }
      $indicatorColor={ $indicatorColor }
      onClick={ closeNotification }
      ref={ ref }
    >
      <IconWrapper $iconColor={ $iconColor }>
        <Icon/>
      </IconWrapper>
      <MessageWrapper $fontColor={ $fontColor }>
        {message}
      </MessageWrapper>
      <ActionWrapper>
        <CustomIconButton
          onClick={ closeNotification }
          type={ ICON_BUTTON_TYPES.cross }
        />
      </ActionWrapper>
    </Wrapper>
  );
});

NotificationCustomItem.displayName = 'NotificationCustomItem';

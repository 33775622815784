import { AtSign, Compass, Link, MapPin, Phone } from 'react-feather';
import {
  ContactEmail,
  ContactFacebook,
  ContactLinkedIn,
  ContactPhone,
  ContactTwitter,
} from '@/assets';

import {
  CONTACT_PREFERENCE,
  FACEBOOK_LINK,
  HOME_EMAIL,
  HOME_LOCATION,
  HOME_PHONE,
  LINKEDIN_LINK,
  MAILING_LOCATION,
  MOBILE_PHONE,
  OTHER_EMAIL,
  OTHER_LOCATION,
  OTHER_PHONE,
  TWITTER_LINK,
  WORK_EMAIL,
  WORK_LOCATION,
  WORK_PHONE,
} from '@constants/contactsData';

import { DetailsDataMap, PopupItems } from './types';
import { CommonFields, LocationFields } from '@/shared/types/contactsData';


export const KEY_NAME = 'useFormContactsId';

const DEFAULT_ADDRESS_OPTIONS = [
  WORK_LOCATION,
  HOME_LOCATION,
  MAILING_LOCATION,
  OTHER_LOCATION,
];

const BUSINESS_ADDRESS_OPTIONS = [
  WORK_LOCATION,
  MAILING_LOCATION,
  OTHER_LOCATION,
];

const DEFAULT_PHONE_OPTIONS = [
  MOBILE_PHONE,
  WORK_PHONE,
  HOME_PHONE,
  OTHER_PHONE,
];

const BUSINESS_PHONE_OPTIONS = [
  MOBILE_PHONE,
  WORK_PHONE,
  OTHER_PHONE,
];

const DEFAULT_LINK_OPTIONS = [
  FACEBOOK_LINK,
  TWITTER_LINK,
  LINKEDIN_LINK,
];

const DEFAULT_EMAIL_OPTIONS = [
  WORK_EMAIL,
  HOME_EMAIL,
  OTHER_EMAIL,
];

const BUSINESS_EMAIL_OPTIONS = [
  WORK_EMAIL,
  OTHER_EMAIL,
];

const DEFAULT_OTHER_OPTIONS = [
  CONTACT_PREFERENCE
];

const ADDRESS_CONFIG = {
  groupName: 'Address',
  icon: MapPin,
  options: DEFAULT_ADDRESS_OPTIONS
};

const PHONE_CONFIG = {
  groupName: 'Phone',
  icon: Phone,
  options: DEFAULT_PHONE_OPTIONS
};

const LINK_CONFIG = {
  groupName: 'Link',
  icon: Link,
  options: DEFAULT_LINK_OPTIONS
};

const EMAIL_CONFIG = {
  groupName: 'Email',
  icon: AtSign,
  options: DEFAULT_EMAIL_OPTIONS
};

const OTHER_CONFIG = {
  groupName: 'Other',
  icon: Compass,
  options: DEFAULT_OTHER_OPTIONS
};

export const DEFAULT_CONFIG: PopupItems = [
  ADDRESS_CONFIG,
  PHONE_CONFIG,
  LINK_CONFIG,
  EMAIL_CONFIG,
  OTHER_CONFIG
];

export const BUSINESS_CONFIG = [
  {
    ...ADDRESS_CONFIG,
    options: BUSINESS_ADDRESS_OPTIONS
  },
  {
    ...PHONE_CONFIG,
    options: BUSINESS_PHONE_OPTIONS
  },
  LINK_CONFIG,
  {
    ...EMAIL_CONFIG,
    options: BUSINESS_EMAIL_OPTIONS
  },
  OTHER_CONFIG
];

const commonFieldsData: CommonFields = {
  ownerId: '',
  primary: 'No',
  primaryToHousehold: 'No',
  useToEmployee: 'No',
  useToHousehold: 'No',
};

const locationFieldsData: LocationFields = {
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
};

export const DETAILS_DATA_MAP:DetailsDataMap = {
  [CONTACT_PREFERENCE.fieldId]: {
    label: CONTACT_PREFERENCE.fieldName,
    defaultValues: {
      fieldId: CONTACT_PREFERENCE.fieldId,
      fieldName: CONTACT_PREFERENCE.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [FACEBOOK_LINK.fieldId]: {
    label: FACEBOOK_LINK.fieldName,
    icon: ContactFacebook,
    defaultValues: {
      fieldId: FACEBOOK_LINK.fieldId,
      fieldName: FACEBOOK_LINK.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [HOME_EMAIL.fieldId]: {
    label: HOME_EMAIL.fieldName,
    icon: ContactEmail,
    defaultValues: {
      fieldId: HOME_EMAIL.fieldId,
      fieldName: HOME_EMAIL.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [HOME_LOCATION.fieldId]: {
    label: HOME_LOCATION.fieldName,
    defaultValues: {
      fieldId: HOME_LOCATION.fieldId,
      fieldName: HOME_LOCATION.fieldName,
      ...locationFieldsData,
      ...commonFieldsData,
    }
  },
  [HOME_PHONE.fieldId]: {
    label: HOME_PHONE.fieldName,
    icon: ContactPhone,
    defaultValues: {
      fieldId: HOME_PHONE.fieldId,
      fieldName: HOME_PHONE.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [LINKEDIN_LINK.fieldId]: {
    label: LINKEDIN_LINK.fieldName,
    icon: ContactLinkedIn,
    defaultValues: {
      fieldId: LINKEDIN_LINK.fieldId,
      fieldName: LINKEDIN_LINK.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [MAILING_LOCATION.fieldId]: {
    label: MAILING_LOCATION.fieldName,
    defaultValues: {
      fieldId: MAILING_LOCATION.fieldId,
      fieldName: MAILING_LOCATION.fieldName,
      ...locationFieldsData,
      ...commonFieldsData,
    }
  },
  [MOBILE_PHONE.fieldId]: {
    label: MOBILE_PHONE.fieldName,
    icon: ContactPhone,
    defaultValues: {
      fieldId: MOBILE_PHONE.fieldId,
      fieldName: MOBILE_PHONE.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [OTHER_EMAIL.fieldId]: {
    label: OTHER_EMAIL.fieldName,
    icon: ContactEmail,
    defaultValues: {
      fieldId: OTHER_EMAIL.fieldId,
      fieldName: OTHER_EMAIL.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [OTHER_LOCATION.fieldId]: {
    label: OTHER_LOCATION.fieldName,
    defaultValues: {
      fieldId: OTHER_LOCATION.fieldId,
      fieldName: OTHER_LOCATION.fieldName,
      ...locationFieldsData,
      ...commonFieldsData,
    }
  },
  [OTHER_PHONE.fieldId]: {
    label: OTHER_PHONE.fieldName,
    icon: ContactPhone,
    defaultValues: {
      fieldId: OTHER_PHONE.fieldId,
      fieldName: OTHER_PHONE.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [TWITTER_LINK.fieldId]: {
    label: TWITTER_LINK.fieldName,
    icon: ContactTwitter,
    defaultValues: {
      fieldId: TWITTER_LINK.fieldId,
      fieldName: TWITTER_LINK.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [WORK_EMAIL.fieldId]: {
    label: WORK_EMAIL.fieldName,
    icon: ContactEmail,
    defaultValues: {
      fieldId: WORK_EMAIL.fieldId,
      fieldName: WORK_EMAIL.fieldName,
      value: '',
      ...commonFieldsData,
    }
  },
  [WORK_LOCATION.fieldId]: {
    label: WORK_LOCATION.fieldName,
    defaultValues: {
      fieldId: WORK_LOCATION.fieldId,
      fieldName: WORK_LOCATION.fieldName,
      ...locationFieldsData,
      ...commonFieldsData,
    }
  },
  [WORK_PHONE.fieldId]: {
    label: WORK_PHONE.fieldName,
    icon: ContactPhone,
    defaultValues: {
      fieldId: WORK_PHONE.fieldId,
      fieldName: WORK_PHONE.fieldName,
      value: '',
      ...commonFieldsData,
    }
  }
};

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';

export const ACCOUNTS_AND_PLANS_TYPES_BUTTONS = [
  {
    value: ACCOUNTS_AND_PLANS_TYPES.ACCOUNT,
    label: 'Account',
  },
  {
    value: ACCOUNTS_AND_PLANS_TYPES.POLICY,
    label: 'Policy',
  },
  {
    value: ACCOUNTS_AND_PLANS_TYPES.PLAN,
    label: 'Financial Plan',
  }
];
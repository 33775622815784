import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { COLORS } from '@constants/colors';

export const AddSpouseBtn = styled(Button)`
    height: 84px;
    border: 1px dashed ${ COLORS.grey };
    border-radius: 8px;
    color: ${ COLORS.grey };

    &:hover {
        border: 1px dashed ${ COLORS.helpText };
        color: ${ COLORS.helpText };
        background-color: ${ COLORS.background };
    }

    & .MuiButton-startIcon > svg {
        width: 22px;
        height: 22px;
    }
`;

export const ItemBtn = styled(Button)`
    height: 84px;
    border: 1px dashed ${ COLORS.grey };
    border-radius: 8px;
    color: ${ COLORS.grey };
`;

export const ItemInner = styled.div`
    display: flex;
`;

export const ItemAvatar = styled.div`
    margin-right: 15px;
`;

export const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ItemName = styled.div`
    font-size: 14px;
    color: ${ COLORS.primaryText};
    margin-bottom: auto;
`;

export const ItemTitle = styled.div`
    font-size: 12px;
    color: ${ COLORS.helpText};
`;

import React from 'react';

import Avatar from '@components/Avatar';
import Label from '@components/Label';
import Tooltip from '@components/Tooltip/Tooltip';

import { getUserTitle } from './utils';
import { getAvatarData } from '@/shared/utils/getAvatarData';

import {
  ActiveStarIcon,
  CloseIcon,
  Container,
  ContentBox,
  CoreIcon,
  EditIcon,
  ActionsBox,
  IconButton,
  StarIcon,
  UserName,
} from './styles';

import { UserItemProps } from './types';
import { COLORS } from '@constants/colors';

function UserItem({
  additionalUserContent,
  containerStyle = {},
  disableTooltipAnimation,
  disabled,
  innerPadding = '5px',
  isLinkedContactType,
  isMarginFixed,
  isStarActive,
  labelWidth = 0,
  onClose,
  onEdit,
  onStar,
  reverseNames,
  user,
  withCoreClassification,
}: UserItemProps) {
  const title = getUserTitle(user, reverseNames);
  const { mainImageData, subImageData } = getAvatarData(user);

  return (
    <Container
      disabled={ disabled }
      innerPadding={ innerPadding }
      style={ containerStyle }
    >
      <ContentBox>
        <Avatar
          $subType={ true }
          mainImageData={ mainImageData }
          subImageData={ subImageData }
        />
        {
          withCoreClassification &&
          'classification' in user &&
          <CoreIcon classificationType={ (user as any)?.coreClassification || user?.classification } />
        }
        <Tooltip
          data-custom-style={ 'user-name' }
          disableAnimation={ disableTooltipAnimation }
          displayOnlyOnOverflow
          title={ title }
        >
          <UserName labelWidth={ labelWidth } >
            { title }
          </UserName>
        </Tooltip>
        { isLinkedContactType && 'linkedContactType' in user && user.linkedContactType &&
          <Label
            $bgColor={ COLORS.dividingLines }
            $color={ COLORS.helpText }
            StyleProps={ {
              marginRight: 0
            } }
          >
            { user.linkedContactType }
          </Label>
        }
        { additionalUserContent }
      </ContentBox>
      <ActionsBox isMarginFixed={ isMarginFixed }>
        {
          onStar &&
          <IconButton
            $star
            onClick={ onStar }
          >
            {
              isStarActive
                ? <ActiveStarIcon />
                : <StarIcon/>
            }
          </IconButton>
        }
        {
          onClose &&
          <IconButton
            $close
            onClick={ onClose }
          >
            <CloseIcon/>
          </IconButton>
        }
        {
          onEdit &&
          <IconButton
            $edit
            onClick={ onEdit }
          >
            <EditIcon />
          </IconButton>
        }
      </ActionsBox>
    </Container>
  );
}

export default UserItem;

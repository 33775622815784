import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { MapTypeNamesToValues, ValueLabelObj } from '@/shared/types/commonTypes';
import { SaveListData, CriterionField, Operand, CriteriaBlock, CriterionType, TreeRoot } from '@/shared/types/lists';

export const LISTS_TABLE_FILTER_NAMES = {
  classification: 'classification',
  listOwner: 'listOwner',
  name: 'fullName',
  officePrimaryAdvisor: 'officePrimaryAdvisor',
  status: 'status',
  typeList: 'typeList',
} as const;

export const LIST_CONTACTS_TABLE_FILTER_NAMES = {
  classification: 'classification',
  officePrimaryAdvisor: 'officePrimaryAdvisor',
  name: 'name',
  status: 'status',
  type: 'type',
} as const;

export enum LISTS_TYPES {
  Folder = 'Folder',
  Dynamic = 'Dynamic',
  Manual = 'Manual'
}

export enum TYPE_FIELDS {
  Folder = 'Folder',
  List = 'List',
}

export const LIST_SETUP_TYPE_OPTIONS = optionsFromValue([
  LISTS_TYPES.Manual,
  LISTS_TYPES.Dynamic
]);

export const LIST_SETUP_FIELDS_NAMES:MapTypeNamesToValues<Required<SaveListData>> = {
  description: 'description',
  filter: 'filter',
  id: 'id',
  name: 'name',
  parentId: 'parentId',
  splitHousehold: 'splitHousehold',
  typeField: 'typeField',
  typeList: 'typeList',
  listOwner: 'listOwner',
};

export const SPLIT_HOUSEHOLD_TEXT = 'Household Contacts will be split into Household Members';

export enum LIST_EXPORT_FILE_TYPE {
  excel = 'excel',
  csv = 'csv'
}

export enum LIST_EXPORT_TYPE {
  primaryContactInformation = 'primaryContactInformation',
  fullContactInformation = 'fullContactInformation',
  fullContactProfile = 'fullContactProfile',
}


export const OPERAND_OPTIONS: [ValueLabelObj<string , string>, ValueLabelObj<string , string>]= [
  {
    value: 'and',
    label: 'And'
  },
  {
    value: 'or',
    label: 'Or'
  }
];

export const LIST_CRITERIA_ROOT_NAME = 'rootTarget' as const;

export const CRITERIA_FIELD_NAMES:MapTypeNamesToValues<CriterionField> = {
  name: 'name',
  option: 'option',
  optionValueFilter: 'optionValueFilter',
  value: 'value'
};

export const OPERAND_FIELD_NAMES:MapTypeNamesToValues<Operand> = {
  typeWhere: 'typeWhere'
};

export const CRITERIA_FIELD_BLOCK_NAMES:MapTypeNamesToValues<CriteriaBlock> = {
  criteria: 'criteria',
  operand: 'operand'
};

export const CRITERIA_FIELD_BLOCK_ITEM_NAMES:MapTypeNamesToValues<CriterionType> = {
  operand: 'operand',
  criterion: 'criterion'
};


export const LIST_CRITERIA_FIELDS_KEY_NAME = 'FieldsKey' as const;
export const LIST_CRITERIA_BLOCK_KEY_NAME = 'BlockKey' as const;

export const NEW_FOLDER_FRONT_ID_KEY = 'tempId' as const;
export const NEW_FOLDER_STATIC_INDEX = 'NEW_FOLDER_STATIC_INDEX';
export const DEFAULT_NEW_FOLDER_NAME = 'New Folder';

export const LISTS_ROOT_NAME = 'Lists Manager';
export const TREE_ROOT_MOCK: TreeRoot = {
  name: LISTS_ROOT_NAME,
  id: null,
  parentId: null,
};


export const MANUAL_ATTENTIONS_TEXT = `A Manual List is for adding and removing contacts to the list by hand. 
You have manual control over who is on the list at all times.`;

export const DYNAMIC_ATTENTIONS_TEXT = `A Dynamic List automatically updates the 
list count based on how many contacts in the system meet the list criteria you’ve specified.
`;

export const INIT_CRITERION_WITHOUT_VALUE: Omit<CriterionField, 'value'> = {
  name: '',
  optionValueFilter: '',
  option:'',
};

export const LIST_VALUE_NESTED_KEYS = {
  first: 'temporaryFrontKeyFirst',
  last: 'temporaryFrontKeyLast',
} as const;


export const DELETE_FOLDER_RADIO_BUTTONS: Array<ValueLabelObj> = [
  {
    value: 'move',
    label: 'Move to another folder',
  },
  {
    value: 'delete',
    label: 'Delete folder and lists'
  }
];

export const LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_OFF = `Household Members will not be added to the list. 
Installed list criteria for Household Members will be invalid. 
Household and Household Members can not be shown simultaneously.
`;

export const LIST_CRITERIA_SPLIT_HOUSEHOLD_TEXT_ON = `Household will not be added to the list. 
Installed list criteria for Household will be invalid. 
Household and Household Members can not be shown simultaneously.
`;

export const CREATED_BY_CHECKBOX_FILTER_MAX_HEIGHT = 190;

import styled from 'styled-components';

export const UserFilterWrapper = styled.div`
  width: 160px;
`;

export const SelectMenuWrapper = styled.div`
  margin-left: 14px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  margin-left: auto;

  & > * {
    margin-right: 23px;
    width: 140px;

    &:last-child {
      margin-right: 0;
      width: 105px;
    }
  }
`;
import React from 'react';

import Table from '@components/Table';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';

import { NotesTasksTableRow } from './components';

import { COLUMNS } from './data';

import { NotesTasksTableProps } from './types';


export const NotesTasksTable = ({
  $height,
  onScrollToBottomAction,
  onTaskEdit,
  tasks,
}: NotesTasksTableProps) => {

  return (
    <Table>
      <Table.Container
        $height={ $height }
        onScrollToBottomAction={ onScrollToBottomAction }
      >
        <Table.Header columns={ COLUMNS }/>
        <Table.Body>
          {
            tasks.length > 0 && tasks.map((task) => (
              <NotesTasksTableRow
                key={ task.id }
                onTaskEdit={ onTaskEdit }
                task={ task }
              />
            ))
          }
        </Table.Body>
        {
          tasks.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              $imageWidth={ '450px' }
              $infoTextFontSize={ '20px' }
              markedText={ 'No Tasks' }
            />
          </ContentCenter>
        }
      </Table.Container>
    </Table>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFImageUploader from '@modules/HookFormComponents/HFImageUploader';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';

import {
  LeftContentWrapper,
  MiddleContentWrapper,
  NameWrapper,
  RightContentWrapper,
  Wrapper
} from './styles';

import { BriefInfoProps } from './types';


const inputProps = {
  placeholder: 'This is a brief description of any important ' +
    'information that the user wants to make about the contact.',
  maxLength: 250,
  rows: 4,
  style: {
    margin: '1px 0'
  }
};

const textFieldProps = {
  multiline: true,
};

export const BriefInfo = ({
  briefDescriptionName,
  headshotBackgroundType,
  children,
  contactTitle,
  photoName,
}: BriefInfoProps) => {
  const { control } = useFormContext();

  return (
    <FormBlock
      isBorderBottom
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.RowWrapper>
        <Wrapper>
          <LeftContentWrapper>
            <HFImageUploader
              control={ control }
              headshotBackgroundType={ headshotBackgroundType }
              isAvatar={ true }
              name={ photoName }
            />
          </LeftContentWrapper>
          <MiddleContentWrapper>
            <NameWrapper>
              <Tooltip
                displayOnlyOnOverflow={ true }
                title={ contactTitle }
              >
                <TextEllipsis $maxWidth={ 500 }>
                  { contactTitle }
                </TextEllipsis>
              </Tooltip>
            </NameWrapper>
            <FormBlock.FormField label={ '' }>
              <HFTextInput
                TextFieldProps={ textFieldProps }
                control={ control }
                inputProps={ inputProps }
                name={ briefDescriptionName }
              />
            </FormBlock.FormField>
          </MiddleContentWrapper>
          {
            children &&
            <RightContentWrapper>
              { children }
            </RightContentWrapper>
          }
        </Wrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

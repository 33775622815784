import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { AvatarWrapperProps, UserIconWrapperProps } from './types';

export const Wrapper = styled.div<AvatarWrapperProps>`
  position: relative;
  isolation: isolate;
  
  ${({ $oneByOneType }) => $oneByOneType && css`
    display: flex;
  `}
  
`;

export const  UserIconWrapper = styled.div<UserIconWrapperProps>`
  z-index: 2;
  
  ${({ $subType }) => $subType && css`
    position: absolute;
    right: 0;
    bottom: 0;
  `}

  ${({ $withFrame }) => $withFrame && css`
    outline: 2px solid ${COLORS.white};
    border-radius: 50%;
  `}

  ${({ $oneByOneType }) => $oneByOneType && css`
    margin-left: -10px;
    z-index: 1;
  `}
`;


import React from 'react';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import { validationMessages } from '@constants/validationMessages';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { getActiveClientByName } from '@services/api/common/common';

import { ListOwnerProps } from './types';

export const ListOwner = ({
  control,
  label = 'List Owner',
  labelWidth,
  name,
  user,
}: ListOwnerProps) => {

  return (
    <FormBlock.FormField
      $labelWidth={ labelWidth }
      isRequired={ true }
      label={ label }
    >
      <HFLinkedContact
        AutocompleteProps={ {
          ListboxProps: {
            style: {
              maxHeight: '200px',
            }
          } }
        }
        ControllerProps={ {
          rules: {
            required: validationMessages.required
          }
        } }
        FetchProps={ {
          getParams: getNameParam,
          requestSender: getActiveClientByName,
          responseConverter: usersResponseConverter,
        } }
        UserItemOptionProps={ {
          withCoreClassification: false
        } }
        UserItemProps={ {
          withCoreClassification: false
        } }
        control={ control }
        initLinkedContact={ user }
        name={ name }
        withoutQueryMode={ true }
      />
    </FormBlock.FormField>
  );
};

import * as yup from 'yup';
import { TestContext } from 'yup/lib/util/createValidation';

import { CriterionField } from '@/shared/types/lists';
import {
  CRITERIA_FIELD_BLOCK_ITEM_NAMES,
  CRITERIA_FIELD_BLOCK_NAMES,
  CRITERIA_FIELD_NAMES,
  LIST_CRITERIA_ROOT_NAME, LIST_VALUE_NESTED_KEYS,
} from '@constants/lists';
import { validationMessages } from '@constants/validationMessages';
import { TestContextExtended } from '@/shared/types/commonTypes';
import {
  DATE_FIELDS,
  INPUTS_TYPE_NUMBER_FIELDS,
  INPUTS_TYPE_TEXT_FIELDS,
  IS_EMPTY_OR_IS_NOT_EMPTY,
  OPERAND_OPTIONS, PERSONAL_DETAILS_FIELDS,
  SINGLE_SELECTS,
} from '@constants/listsCriteria';
import { dateOutputValidation } from '@constants/validationPatterns';
import { isDateAfter, isDateBefore } from '@/shared/utils/dateValidationUtils';
import { isYearOfDateValid } from '@/shared/utils/isYearOfDateValid';


function singleSelectValidation (
  this: TestContext,
  value: string | null | undefined,
  context: yup.TestContext) {
  const { from } = context as yup.TestContext & TestContextExtended<CriterionField>;
  const isSingleSelect = SINGLE_SELECTS.includes(from?.[1].value.option as any);
  const isEmptyAndNotEmpty = IS_EMPTY_OR_IS_NOT_EMPTY.includes(from?.[1].value.optionValueFilter);

  if(!isSingleSelect || isEmptyAndNotEmpty) { return true; }

  if(!value){
    return this.createError({
      path: context.path,
      message: validationMessages.required
    });
  }

  return true;
}

function textFieldValidation (
  this: TestContext,
  value: string | null | undefined,
  context: yup.TestContext) {
  const { from } = context as yup.TestContext & TestContextExtended<CriterionField>;
  const isTextField = INPUTS_TYPE_TEXT_FIELDS.includes(from?.[1].value.option as any);
  const isEmptyAndNotEmpty = IS_EMPTY_OR_IS_NOT_EMPTY.includes(from?.[1].value.optionValueFilter);

  if(!isTextField || isEmptyAndNotEmpty){
    return true;
  }
  return Boolean(value);
}

const datesValidation = (isFirst: boolean) => (
  function (
    this: TestContext,
    value: string | null | undefined,
    context: yup.TestContext) {
    const { from } = context as yup.TestContext & TestContextExtended<CriterionField>;
    const isDate = DATE_FIELDS.includes(from?.[1].value.option as any);
    const isBirthDate = PERSONAL_DETAILS_FIELDS.Birthdate === from?.[1].value.option as any;
    const isEmptyAndNotEmpty = IS_EMPTY_OR_IS_NOT_EMPTY.includes(from?.[1].value.optionValueFilter);


    if(!isDate || isEmptyAndNotEmpty) { return true; }

    const createErrorWrapper = (message: string = validationMessages.invalidDate): any => (
      this.createError({
        path: context.path,
        message: message
      })
    );


    const isRange = from?.[1].value.optionValueFilter === OPERAND_OPTIONS.In_Between;

    if(isFirst || isRange){
      if(!value){
        return createErrorWrapper(validationMessages.required);
      }
      if(!dateOutputValidation.test(value)){
        return createErrorWrapper();
      }

      if(!isYearOfDateValid(value) && !isBirthDate){
        return createErrorWrapper();
      }
    }

    if(isRange) {
      if(isFirst) {
        const isStartDateAfterEnd = isDateAfter({
          dateString: value,
          dateStringToCompare: from?.[1].value.value?.[LIST_VALUE_NESTED_KEYS.last as any],
        });

        if (isStartDateAfterEnd) {
          return createErrorWrapper();
        }
      }

      if(!isFirst){
        const isEndDateBeforeStart = isDateBefore({
          dateString: value,
          dateStringToCompare: from?.[1].value.value?.[LIST_VALUE_NESTED_KEYS.first as any]
        });

        if(isEndDateBeforeStart){
          return createErrorWrapper();
        }
      }
    }
    return true;
  }
);

const textFieldWithTypeNumberValidation = (isFirst: boolean) => (
  function (
    this: TestContext,
    value: string | null | undefined,
    context: yup.TestContext) {
    const { from } = context as yup.TestContext & TestContextExtended<CriterionField>;
    const isTextFieldWithTypeNumber = INPUTS_TYPE_NUMBER_FIELDS.includes(
      from?.[1].value.option as any
    );

    const isEmptyAndNotEmpty = IS_EMPTY_OR_IS_NOT_EMPTY.includes(from?.[1].value.optionValueFilter);

    if(!isTextFieldWithTypeNumber || isEmptyAndNotEmpty) { return true; }

    const isRange = from?.[1].value.optionValueFilter === OPERAND_OPTIONS.In_Between;

    if(isFirst || isRange){
      if(!value || BigInt(value) < 0){
        return this.createError({
          path: context.path,
          message: validationMessages.required
        });
      }
    }

    if(isRange){
      if(isFirst){
        const valueOfLast = from?.[1].value.value[LIST_VALUE_NESTED_KEYS.last as any];
        const isValueOfLastNumber = valueOfLast !== null && !isNaN(Number(valueOfLast));

        if(isValueOfLastNumber && value && BigInt(value) > BigInt(valueOfLast)){
          return this.createError({
            path: context.path,
            message: validationMessages.invalidValue
          });
        }

      } else {
        const valueOfFirst = from?.[1].value.value[LIST_VALUE_NESTED_KEYS.first as any];
        const isValueOfFirstNumber = valueOfFirst !== null && !isNaN(Number(valueOfFirst));

        if(isValueOfFirstNumber && value && BigInt(value) < BigInt(valueOfFirst)){
          return this.createError({
            path: context.path,
            message: validationMessages.invalidValue
          });
        }
      }

    }
    return true;
  }
);


export const validationSchema = yup.object().shape({
  [LIST_CRITERIA_ROOT_NAME]: yup.array().of(yup.object().shape({
    [CRITERIA_FIELD_BLOCK_NAMES.operand as string]: yup.string().optional(),
    [CRITERIA_FIELD_BLOCK_NAMES.criteria]: yup.array().of(
      yup.object().shape({
        [CRITERIA_FIELD_BLOCK_ITEM_NAMES.criterion]: yup.object().shape({
          [CRITERIA_FIELD_NAMES.name]: yup.string()
            .required(validationMessages.required),
          [CRITERIA_FIELD_NAMES.option]: yup.string()
            .required(validationMessages.required),
          [CRITERIA_FIELD_NAMES.optionValueFilter]: yup.string()
            .required(validationMessages.required),
          [CRITERIA_FIELD_NAMES.value]: yup.lazy((val: any) => {
            if(!Array.isArray(val)){
              return yup.object().shape({
                [LIST_VALUE_NESTED_KEYS.first]: yup.string()
                  .nullable()
                  .test(
                    'Single selects validation',
                    validationMessages.required,
                    singleSelectValidation
                  )
                  .test(
                    'Text Fields validation',
                    validationMessages.required,
                    textFieldValidation
                  )
                  .test(
                    'Date fields validation',
                    validationMessages.required,
                    datesValidation(true)
                  )
                  .test(
                    'Text Fields with type number validation',
                    validationMessages.required,
                    textFieldWithTypeNumberValidation(true)
                  ),
                [LIST_VALUE_NESTED_KEYS.last]: yup.string()
                  .nullable()
                  .optional()
                  .test(
                    'Date fields validation',
                    validationMessages.required,
                    datesValidation(false)
                  )
                  .test(
                    'Text Fields with type number validation',
                    validationMessages.required,
                    textFieldWithTypeNumberValidation(false)
                  ),
              }).nullable();
            }

            return yup.array().min(1, validationMessages.required);
          })
        })
      }))
  }))
});



import * as yup from 'yup';

import { ACCEPTABLE_FORMATS, MAX_SIZE_OF_IMAGE } from './data';

import { validationMessages } from '@constants/validationMessages';

export const imageValidation = yup.mixed().nullable()
  .test('fileSize', validationMessages.invalidImage, (value) => {
    if(!value || typeof value === 'string') {
      return true;
    }
    return (value && value.size <= MAX_SIZE_OF_IMAGE);
  })
  .test('fileType', validationMessages.invalidImage, (value) => {
    if(!value || typeof value === 'string') {
      return true;
    }

    if(value && !ACCEPTABLE_FORMATS.includes(value?.type)){
      return false;
    }

    return true;
  });



import { ContactCustomFieldFormFieldNames } from '@/shared/types/userSettingsContactCustomFields';

export const CONTACT_CUSTOM_FIELDS_TYPES = {
  activityStatus: 'Activity status',
  activitySubCategory: 'Activity subcategory',
  appointmentType: 'Appointment type',
  contactSource: 'Contact source',
  // oppStage: 'Opp stage', // ???
} as const;

export const CONTACT_CUSTOM_FIELD_NAMES: ContactCustomFieldFormFieldNames = {
  field: 'field',
  id: 'id',
  option: 'option'
} ;

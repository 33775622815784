export const PASSWORD_STRENGTH = {
  Weak: 'Weak',
  Good: 'Good',
  Average: 'Average',
  Strong: 'Strong',
};

export const PASSWORD_STRENGTH_LEVELS = {
  0: PASSWORD_STRENGTH.Weak,
  1: PASSWORD_STRENGTH.Weak,
  2: PASSWORD_STRENGTH.Good,
  3: PASSWORD_STRENGTH.Average,
  4: PASSWORD_STRENGTH.Strong,
};
import React, { forwardRef } from 'react';

import { StyledRow } from './styles';

import { RowProps, TypeSelectedRow } from './types';

export const Row = forwardRef<HTMLTableRowElement ,RowProps>((props, ref) =>{
  const {
    $backgroundColor,
    $preventHover,
    $preventPointerEvents,
    $typeSelectedRow = TypeSelectedRow.Solid,
    children,
    ...restProps
  } = props;

  return (
    <StyledRow
      $backgroundColor={ $backgroundColor }
      $preventHover={ $preventHover }
      $preventPointerEvents={ $preventPointerEvents }
      $typeSelectedRow={ $typeSelectedRow }
      ref={ ref }
      { ...restProps }
    >
      { children }
    </StyledRow>
  );
});

Row.displayName = 'Row';

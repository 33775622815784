import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormProvider,useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSalesActivePopupStore } from './store';

import Modal from '@components/Modal';
import SalesPipelinePopupTopPanel from '@modules/SalesPipline/SalesPipelinePopupTopPanel';
import SalesPipelinePopupFooter from '@modules/SalesPipline/SalesPipelinePopupFooter';
import Preloader from '@components/Preloader';

import { Content, SalesActiveCycleNotes } from './components';

import { ContentWrapper, LeftColumn, RightColumn, Spacer } from './styles';

import { SalesActiveCycleFormFields, SalesActivePopupProps } from './types';

import { validationSchema } from './validationScheme';

import { SALES_ACTIVE_POPUP_DEFAULT_VALUES } from './data';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES, SALES_ACTIVE_CYCLE_STAGES_OPTIONS } from '@constants/salesActiveCycle';


export const SalesActivePopup = observer(({
  closeModal,
  modalProps,
}: SalesActivePopupProps) => {
  const {
    id,
    onSaveCallback,
    onDeleteCallback,
  } = modalProps;
  const isEdit = Boolean(id);
  const header = `${ isEdit ? 'Edit' : 'Add'} Cycle`;

  const {
    isLoad ,
    init,
    resetState,
    notesUtilsStore,
    onCancel,
    onDelete,
    onConfirm,
  } = useSalesActivePopupStore();

  const methods= useForm<SalesActiveCycleFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: SALES_ACTIVE_POPUP_DEFAULT_VALUES
  });

  useEffect(() => {
    init({
      closeTrigger: closeModal,
      id,
      onSaveCallback,
      useFormMethods: methods,
      onDeleteCallback,
    });
    return resetState;
  },[id, init, methods, onDeleteCallback, onSaveCallback, resetState]);

  return (
    <Modal
      headerProps={ {
        paddingLeft: '20px !important'
      } }
      isCloseDisabled={ isLoad }
      onClose={ closeModal }
      title={ header }
    >
      <FormProvider { ...methods }>
        <Modal.Content $disabled={ Boolean(notesUtilsStore.alertType) }>
          <Modal.TopPanel paddingLeft={ '20px !important' }>
            <SalesPipelinePopupTopPanel
              disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.stage }
              options={ SALES_ACTIVE_CYCLE_STAGES_OPTIONS }
            />
          </Modal.TopPanel>
          {
            isLoad &&
            <Spacer>
              <Preloader isWithoutFullPageWrapper={ true }/>
            </Spacer>
          }
          {
            !isLoad &&
            <ContentWrapper>
              <LeftColumn>
                <Content />
              </LeftColumn>
              <RightColumn>
                <SalesActiveCycleNotes/>
              </RightColumn>
            </ContentWrapper>
          }
        </Modal.Content>
        <Modal.Footer paddingLeft={ `${isEdit ? '8px' : '12px'} !important` }>
          <SalesPipelinePopupFooter
            alertType={ notesUtilsStore.alertType }
            isDisabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
            isEdit={ isEdit }
            onCancel={ onCancel }
            onConfirm={ onConfirm }
            onDelete={ onDelete }
            statusFieldName={ SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus }
          />
        </Modal.Footer>
      </FormProvider>
    </Modal>
  );
});

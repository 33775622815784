import { PopoverOrigin } from '@material-ui/core';

export const TRANSFORM_ORIGIN_PROPS: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const ANCHOR_ORIGIN_PROPS: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const PAPER_PROPS = {
  style: {
    width: '450px',
    marginTop: '-10px',
    padding: '25px',
    borderRadius: '10px',
  }
};

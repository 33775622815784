import { isBefore, parse, startOfToday } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

const NEXT_APPOINTMENT_DATE_LABEL = 'Next Appt Date';
const LAST_APPOINTMENT_DATE_LABEL = 'Last Appt Date';

export const getAppointmentDateLabel = (
  nextAppointmentDate: string | null,
  lastAppointmentLabel: string = LAST_APPOINTMENT_DATE_LABEL,
  nextAppointmentLabel: string = NEXT_APPOINTMENT_DATE_LABEL
) => {
  if(nextAppointmentDate) {
    const isBeforeToday = isBefore(parse(nextAppointmentDate, YEAR_MONTH_DAY, new Date()), startOfToday());
    return isBeforeToday ? lastAppointmentLabel : nextAppointmentLabel;
  }
  return nextAppointmentLabel;
};

import axios from 'axios';
import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';

import { Store } from '@store';

import { IdType, onErrorCallback } from '@/shared/types/commonTypes';
import { ContactItem } from '@/shared/types/contact';
import { USER_CUSTOM_FIELDS_TYPES, USER_CUSTOM_FIELD_ENTITY } from '@constants/userSettingsUserCustomFields';

import {
  CustomFieldBackend,
  CustomFieldItem,
  SaveCustomFieldErrorData,
  SaveData,
  SaveResponse
} from './types';

import {
  deleteCustomField,
  getContactCustomFields,
  saveCustomField,
} from '@/shared/services/api/contacts/contacts';
import CommonTableStore from '@services/store/commonTableStore';
import { getCustomFieldByTypeId } from '@services/api/userSettings/userSettingsUserCustomFields';
import { GetListOfUserCustomFieldsResponse, UserCustomField } from '@/shared/types/userSettingsUserCustomFields';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { MODAL_TYPE } from '@constants/modalTypes';
import { NOTIFICATION_TYPES } from '@constants/notifications';

class ContactCustomFields {
  asyncRequestExecutor: AsyncRequestExecutor;
  contactId: IdType| null = null;
  coreStore: Store;
  customFieldsSettings: Array<UserCustomField> = [];
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onPageChangeReaction: IReactionDisposer;
  onSortingChangeReaction: IReactionDisposer;
  table: CommonTableStore<CustomFieldItem>;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      initPage: flow,
      getCustomFieldsList: flow,
      saveCustomField: flow,
      deleteCustomField: flow,
    });

    this.coreStore = coreStore;

    this.table = new CommonTableStore<CustomFieldItem>();

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.customField
    );

    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.onSortingChangeReaction = this.createOnSortingChangeReaction();
  }

  createOnSortingChangeReaction() {
    return reaction(
      () => this.table.multipleSorting,
      () => {
        if(this.isPageActive) {
          this.getCustomFieldsListWithLoad();
        }
      }
    );
  }

  createOnPageChangeReaction() {
    return reaction(
      () => ({
        page: this.table.currentPage,
      }),
      () => {
        if (this.isPageActive) {
          this.getCustomFieldsListWithLoad();
        }
      }
    );
  }

  *getCustomFieldsList() {
    this.table.clearItems(true);
    const listResponse: CustomFieldBackend = yield getContactCustomFields({
      id: this.contactId
    });

    this.table.setPaginationData(listResponse.data.data);
    this.table.items = listResponse.data.data.data;
  }

  *getCustomFieldsListWithLoad() {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    try {
      contactDetailsStore.toggleLoadState(true);
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => this.getCustomFieldsList(),
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error })
      });
    } catch (error) {
      console.log(error);
    }
    finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  async *initPage(contactId: IdType) {
    const contactDetailsStore = this.coreStore.ContactDetailsStore;
    this.contactId = contactId;
    this.isPageActive = true;

    try {
      contactDetailsStore.toggleLoadState(true);
      const isNeedNewContact = contactDetailsStore.isNeedToUpdateContact(contactId);
      if(isNeedNewContact) {
        const contactData:ContactItem = yield await contactDetailsStore.getContact(contactId);
        contactDetailsStore.setCurrentContact(contactData);
      }

      yield await this.getCustomFieldsListWithLoad();

      const settingsResponse: GetListOfUserCustomFieldsResponse = yield await getCustomFieldByTypeId({
        type: USER_CUSTOM_FIELD_ENTITY.contact
      });

      this.customFieldsSettings = settingsResponse.data.data;
    } catch (error) {
      console.log(error);
    } finally {
      contactDetailsStore.toggleLoadState(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveCustomField(saveData: SaveData, onError: onErrorCallback) {
    this.coreStore.ContactDetailsStore.toggleLoadState(true);
    try {
      // if we send value from DROPDOWN_LIS we need find option and set to value label
      // and add customFieldOptionId to request and set here id of selected option
      const selectedType = this.customFieldsSettings.find(item => item.id === saveData.customFieldId);
      const isDropDown = selectedType && selectedType.type === USER_CUSTOM_FIELDS_TYPES.dropDownList;
      const selectedDropDownOption = isDropDown
        ? selectedType.options.find(item => item.id as unknown === saveData.value as string)
        : null;

      const dataForDropDown = isDropDown && selectedDropDownOption
        ? {
          value: selectedDropDownOption.label,
          customFieldOptionId: selectedDropDownOption.id
        }
        : {};

      const contactId = this.coreStore.ContactDetailsStore.currentContact!.id;
      const data = {
        entityId: contactId,
        ...saveData,
        ...dataForDropDown,
      };

      const customFieldItemResponse: SaveResponse = yield saveCustomField(data);
      this.coreStore.ModalStore.closeModal(MODAL_TYPE.ADD_CONTACT_CUSTOM_FIELD_TO_CONTACT);

      yield this.getCustomFieldsList();
    } catch (error) {
      console.log(error);
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        const { data: { error: { value } } } = error.response?.data as SaveCustomFieldErrorData;
        onError(value);
      }
    } finally {
      this.coreStore.ContactDetailsStore.toggleLoadState(false);
    }
  }

  async *deleteCustomField(customFieldId: IdType) {
    this.coreStore.ContactDetailsStore.toggleLoadState(true);
    try {
      yield await deleteCustomField({
        id: customFieldId,
        force: 1
      });

      const contactId = this.coreStore.ContactDetailsStore.currentContact!.id;

      yield await this.getCustomFieldsList();

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.ContactDetailsStore.toggleLoadState(false);
    }
  }

  reset() {
    this.contactId = null;
    this.table.resetTable();
    this.customFieldsSettings = [];
    this.isPageActive = false;
  }
}

export default ContactCustomFields;

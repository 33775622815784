import React from 'react';
import { useWatch } from 'react-hook-form';

import HFPasswordInput from '@modules/HookFormComponents/HFPasswordInput';
import PasswordStrengthMeter from '@components/PasswordStrengthMeter';

import { NewPasswordProps } from './types';

const HELPER_TEXT = 'Password must be not less than 8 characters long, include uppercase, numbers and symbols.';

export const NewPassword = ({
  ControllerProps = {},
  control,
  name,
}: NewPasswordProps) => {

  const password = useWatch({
    control,
    name
  });

  return (
    <HFPasswordInput
      ControllerProps={ ControllerProps }
      control={ control }
      helperTextMessage={ HELPER_TEXT }
      helperTextNodeElement={ password && <PasswordStrengthMeter password={ password }/> }
      name={ name }
      placeholder={ 'Enter New Password' }
    />
  );
};
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { MODAL_TYPE } from '@constants/modalTypes';

import ContactCustomFieldsPopup from '@modules/UserSettings/ContactCustomFieldsPopup';
import DeleteContactCustomFieldPopup from '@modules/UserSettings/DeleteContactCustomFieldPopup';

import { ActivityInformation, ContactInformation, SalesAppointment } from './components';

import { Wrapper } from './styles';

import {
  ContactCustomFieldAddEditProps,
  SavedContactCustomFieldItem
} from '@/shared/types/userSettingsContactCustomFields';

export const ContactCustomFields = observer(() => {
  const {
    init,
    resetState,
    activitySubCategory,
    contactSource,
    appointmentType,
    activityStatus,
    onSave,
    onDelete,
  } = useStore().UserSettingsContactCustomFields;

  const modalStore = useStore().ModalStore;

  const onAddEdit = useCallback((data: ContactCustomFieldAddEditProps['data'], index?: number) => {
    modalStore.openModal({
      component: ContactCustomFieldsPopup,
      modalProps: {
        data,
        onSave,
        index
      },
      modalType: MODAL_TYPE.ADD_CONTACT_CUSTOM_FIELD
    });
  },[modalStore, onSave]);

  const onDeleteItem = useCallback((item: SavedContactCustomFieldItem, index: number) => {
    modalStore.openModal({
      component: DeleteContactCustomFieldPopup,
      modalProps: {
        onConfirm: () => onDelete(item, index)
      },
      modalType: MODAL_TYPE.DELETE_CUSTOM_FIELD
    });
  },[modalStore, onDelete]);

  useEffect(() => {
    init();
    return resetState;
  }, [init, resetState]);

  return (
    <Wrapper>
      {/*<SalesAppointment*/}
      {/*  onAddEdit={ onAddEdit }*/}
      {/*  onDelete={ onDeleteItem }*/}
      {/*  store={ appointmentType }*/}
      {/*/>*/}
      <ContactInformation
        onAddEdit={ onAddEdit }
        onDelete={ onDeleteItem }
        store={ contactSource }
      />
      <ActivityInformation
        onAddEdit={ onAddEdit }
        onDelete={ onDeleteItem }
        statusStore={ activityStatus }
        subCategoryStore={ activitySubCategory }
      />
    </Wrapper>
  );
});

import ApiService from '@services/api/api.service';

import { LoginParams, RegisterParams } from '@/shared/types/auth';

export const LOGIN_URL = 'api/login';
export const LOGOUT_URL = 'api/logout';

export const login = (params: LoginParams) => {
  return ApiService.post(LOGIN_URL, params);
};

export const register = (params: RegisterParams) => {
  return ApiService.post('api/register', params);
};

export const logout = () => {
  return ApiService.get(LOGOUT_URL);
};

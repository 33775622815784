import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 12px 14px 0;
    order: 1;

    & .MuiButton-text {
      min-width: auto;
      padding: 6px 8px;
    }
`;

import styled from 'styled-components';

export const LeftColumnWrapper = styled.div`
  width: 50%;
  padding-right: 31px;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  padding-left: 53px;
  box-sizing: border-box;
`;
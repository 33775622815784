import { makeAutoObservable, flow, action } from 'mobx';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseFormSetError } from 'react-hook-form';

import {
  deleteUser,
  getListUsers,
  getSettings,
  getUserById,
  getVerifiedEmail,
  getProfile,
  savePassword,
  saveProfile,
  saveSettings,
  saveUserProfile,
} from '@services/api/userSettings/userSettings';

import { Store } from '../store';
import { configDataNormalizer, convertToFormData, profileDataNormalizer, settingNormalizer } from './helpers';
import { getMultipleSortParams } from '@/shared/utils/filterUtils';

import {
  AccountSettingsType,
  ConfigData,
  GetSettingsResponse,
  GetUserResponse,
  MainPasswordProperty,
  SaveProfileErrorData,
  UserProfileType,
  UserProfileTypeResponse,
} from './types';
import { BackendGridResponse, ObjectLike } from '@/shared/types/commonTypes';

import CommonTableStore from '@services/store/commonTableStore';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { convertArrayOfStringToHtmlLines } from '@/shared/utils/NotificationHelper/generateMessage';
import { convertBlobImageToString } from '@/shared/utils/convertBlobImageToString';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { UserProfileFormInputNames } from '@pages/NewUserSettings/data';

class UserSettingsStore {
  configData: ConfigData = {} as ConfigData;
  coreStore: Store;
  isFetching: boolean = false;
  profile: UserProfileType | null = null;
  table: CommonTableStore<UserProfileType>;
  userStatus: string = '';
  asyncRequestExecutor: AsyncRequestExecutor;
  notificationHelper: NotificationHelper;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      deleteUser: flow,
      getListUsers: flow.bound,
      getProfile: flow.bound,
      getUserById: flow,
      getUserProfile: flow,
      getVerifiedEmail: flow,
      savePassword: flow,
      saveProfile: flow,
      saveSettings: flow,
      saveUserProfile: flow,
      setIsFetching: action.bound,
    });

    this.coreStore = coreStore;

    this.table = new CommonTableStore<UserProfileType>({
      onSortReactionCallback: this.getListUsers,
      onPageChangeReactionCallback: this.getListUsers
    });

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.user
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

  }

  setProfile(data: UserProfileType): void {
    this.profile = data;
  }

  *deleteUser(params: ObjectLike<string | number>) {
    this.setIsFetching(true);
    try {

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteUser(params),
        onError: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.error,
          message: convertArrayOfStringToHtmlLines(['The deleting of the User is failed.', 'Please try again.']),
          customAction: 'deleteUser'
        }),
        onSuccess: () => this.notificationHelper.customNotification({
          status: NOTIFICATION_TYPES.success,
          message: 'The User has been deleted.',
          customAction: 'deleteUser'
        }),
      });

      yield this.getListUsers();

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getSettings() : Generator<any, void, any> {
    this.setIsFetching(true);
    try {
      const response: AxiosResponse<GetSettingsResponse> = yield getSettings();
      this.configData = configDataNormalizer(response.data.configData);
      this.coreStore?.CalendarStore?.setCurrentView(response.data.data.defaultCalendarView);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *getVerifiedEmail(id: number) : Generator<any, void, any> {
    this.setIsFetching(true);
    try {
      yield getVerifiedEmail({ id });
      const data: UserProfileTypeResponse = yield this.getUserById(id);
      if(this.profile) {
        this.profile.emailVerified = data.emailVerified;
        yield this.getListUsers();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *getListUsers() : Generator<any, void, any> {
    this.setIsFetching(true);
    try {

      const start = async () => {
        this.table.clearItems(true);
        const response: AxiosResponse<BackendGridResponse<Array<UserProfileType>>>  = await getListUsers({
          page: this.table.currentPage,
          ...getMultipleSortParams(this.table.multipleSorting)
        });

        this.table.setPaginationData(response.data.data);
        this.table.items = response.data.data.data;
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });

    } catch (error) {
      console.log(error);
    }
    this.setIsFetching(false);
    this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
  }

  *getUserById(id: number) : Generator<any, UserProfileType | null, any> {
    this.setIsFetching(true);
    try {
      const response: AxiosResponse<GetUserResponse>  = yield getUserById({ id });
      return response.data.data;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      this.setIsFetching(false);
    }
  }

  *getProfile() : Generator<any, void, any> {
    this.setIsFetching(true);
    try {
      const response: AxiosResponse<GetUserResponse> = yield getProfile();
      const convertedData = profileDataNormalizer(response.data.data);
      this.setProfile(convertedData);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *getUserProfile(id: number) : Generator<any, void, any> {
    this.setIsFetching(true);
    try {
      const data: UserProfileTypeResponse = yield this.getUserById(id);
      const convertedData = profileDataNormalizer(data);
      this.setProfile(convertedData);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  }

  *saveSettings(data: AccountSettingsType): Generator<any, void, any> {
    this.setIsFetching(true);
    try {
      const normalizedData = settingNormalizer(data); 

      const otherEntityName = ENTITY_NAMES.settings;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveSettings(normalizedData),
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          otherEntityName,
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          otherEntityName,
        })
      });

      this.coreStore.SettingsStore.setSettings(normalizedData);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveUserProfile(data: UserProfileType, setError: UseFormSetError<UserProfileType>): Generator<any, boolean, any> {
    try {
      const start = async () => {
        const formData = convertToFormData(data);

        if(data.headshot) {
          data.headshot = convertBlobImageToString(data.headshot);
        }

        await saveUserProfile(formData);
      };

      const otherEntityName = ENTITY_NAMES.profile;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          otherEntityName,
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          otherEntityName,
        }),
        continueOnError: false
      });

      yield this.getProfile();
      return true;
    } catch (error) {
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.setUserProfileEmailError(error, setError);
      }
      console.log(error);
      return false;
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *saveProfile(data: UserProfileType, setError: UseFormSetError<UserProfileType>): Generator<any, void, any> {
    try {
      const start = async () => {
        const formData = convertToFormData(data);

        await saveProfile(formData);
      };

      const otherEntityName = ENTITY_NAMES.profile;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          otherEntityName,
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          otherEntityName,
        }),
        continueOnError: false
      });

      yield this.getUserProfile(data.id);
      yield this.getListUsers();
    } catch (error) {
      if(axios.isAxiosError(error) && error?.response?.status === 400) {
        this.setUserProfileEmailError(error, setError);
      }
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *savePassword(data: MainPasswordProperty) {
    try {
      const otherEntityName = ENTITY_NAMES.password;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => savePassword(data),
        onError: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.error,
          otherEntityName,
        }),
        onSuccess: () => this.notificationHelper.update({
          status: NOTIFICATION_TYPES.success,
          otherEntityName,
        })
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setUserProfileEmailError(error: AxiosError<unknown, any>, setError: UseFormSetError<UserProfileType>) {
    const errorResponse = error.response?.data as SaveProfileErrorData;
    setError(UserProfileFormInputNames.email, {
      type: 'custom',
      message: errorResponse.data.error[0].email
    });
  }

  setIsFetching(value: boolean) {
    this.isFetching = value;
  }

  reset() {
    this.profile = {} as UserProfileType;
    this.table.resetTable();
  }
}

export default UserSettingsStore;

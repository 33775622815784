import { ColumnsType } from '@components/Table/components/Header/types';

export const COLUMNS: ColumnsType = [
  {
    id: 'name',
    label: 'Activity Name',
    style: {
      width: '20%',
    },
    sortable: true,
  },
  {
    id: 'linkedContact',
    label: 'Linked Contact',
    style: {
      width: '21%'
    },
    separator: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    style: {
      width: '11%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'startTime',
    label: 'Start Time',
    style: {
      width: '9%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'type',
    label: 'Activity Category',
    style: {
      width: '14%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'assignedTo',
    label: 'Assigned to',
    style: {
      width: '18%'
    },
    separator: true,
  },
];

export const ACTIVITY_TYPES = {
  general: 'General Activity',
  salesAppointments: 'Sales Appointment',
  accountReview: 'Account Review',
  meeting:  'Meeting',
  event: 'Event',
  timeBlock: 'Time Block',
};

export const CALENDAR_PERIOD = {
  all: 'all',
  month: 'month',
  week: 'week',
  day: 'day',
};

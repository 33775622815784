import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import NotesAndHistory from '@modules/NotesAndHistory';

import { useSalesActivePopupStore } from '../../store';

import { SalesActiveCycleFormFields } from '../../types';

import {
  SALES_ACTIVE_CYCLE_FIELD_NAMES,
  SALES_ACTIVE_NOTES_MEMBERS_FIELD_NAMES,
  SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES
} from '@constants/salesActiveCycle';


const INPUT_NAMES = {
  tagId: SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES.id,
  tagValue: SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES.value,
  notes: SALES_ACTIVE_CYCLE_FIELD_NAMES.notes,
  ...SALES_ACTIVE_NOTES_MEMBERS_FIELD_NAMES
};

export const SalesActiveCycleNotes = observer(() => {
  const { control } = useFormContext<SalesActiveCycleFormFields>();

  const { profile } = useStore().SettingsStore;
  const salesActivePopupStore = useSalesActivePopupStore();

  const {
    predefinedTags,
    linkedContactData,
    notesUtilsStore
  } = salesActivePopupStore;

  return (
    <NotesAndHistory
      $maxHeight={ '765px' }
      control={ control }
      currentUser={ profile }
      deleteConfirmationText={ `The Note will be deleted if you save the changes for the Sales Cycle. 
        Are you sure you want to delete note?`
      }
      disableAddNoteTooltipText={ 'To create a Note you need to select Linked Contact' }
      isAddNoteBtnDisabled={ !linkedContactData }
      linkedContactData={ linkedContactData }
      names={ INPUT_NAMES }
      noteContactOptions={ notesUtilsStore.noteContactOptions }
      onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
      onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
      predefinedTags={ predefinedTags }
      title={ 'Sales Cycle Notes & History' }
    />
  );
});

import ApiService from '@services/api/api.service';
import { GetNextAppointmentDateParams, UpdateFlagsParams } from '@/shared/types/commonTypes';
import {
  ClientOpportunityDeleteParams,
  ClientOpportunityFormFields,
  ClientOpportunityGetByIdParams,
  ClientOpportunityRowFormState
} from '@/shared/types/salesCycleOpportunity';

export const getClientOpportunitiesTable = (params?: any) => {
  return ApiService.get('/api/opportunity/getGrid', { params });
};

export const updateClientOpportunitiesFlags = (params: UpdateFlagsParams) => {
  return ApiService.post('/api/opportunity/updateFlags', params);
};

export const getClientOpportunityPopupSettings = () => {
  return ApiService.get('/api/opportunity/getSetting');
};

export const getClientOpportunityItem = (params: ClientOpportunityGetByIdParams, signal?: AbortSignal) => {
  return ApiService.get('/api/opportunity/getById', { params, signal });
};

export const getClientOpportunityNextApptDate = (params: GetNextAppointmentDateParams, signal?: AbortSignal) => {
  return ApiService.get('/api/opportunity/getNextApptDate', { params, signal });
};

export const saveClientOpportunity = (data: ClientOpportunityFormFields) => {
  return ApiService.post('/api/opportunity/saveOpportunity', data);
};

export const deleteClientOpportunity  = (params: ClientOpportunityDeleteParams) => {
  return ApiService.delete('/api/opportunity/delete', { params });
};

export const partialUpdateClientOpportunity = (params: ClientOpportunityRowFormState) => {
  return ApiService.patch('/api/opportunity/updateGrid', params);
};

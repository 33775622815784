import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { SalesClientOpportunitiesDeletePopupProps } from './types';

export const SalesClientOpportunitiesDeletePopup = ({
  closeModal,
  modalProps,
}: SalesClientOpportunitiesDeletePopupProps) => {

  const onConfirmWrapper = useCallback(() => {
    closeModal();
    modalProps.onConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ 'Are you sure you want to delete Opportunity?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmWrapper }
    />
  );
};

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStore } from '@services/store/store';
import { useTodoPopupLocalStore } from '@modules/TodoPopup/store';

import { NOTES_AND_HISTORY_INPUT_NAMES } from '@modules/TodoPopup/data';
import { TO_DOS_FIELDS_NAMES } from '@constants/todosData';

import { TaskDetails } from '@modules/TodoPopup/components';
import NotesAndHistory from '@modules/NotesAndHistory';

import { NotesAndHistoryWrapper, Wrapper } from './styles';


export const Content = observer(() => {
  const { control } = useFormContext();
  const taskName = useWatch({
    control,
    name: TO_DOS_FIELDS_NAMES.name
  });

  const { profile } = useStore().SettingsStore;

  const {
    predefinedTags,
    notesUtilsStore,
    linkedContactData
  } = useTodoPopupLocalStore();

  const isAddNoteBtnDisabled = useMemo(() => !linkedContactData || !taskName, [ linkedContactData, taskName ]);

  return (
    <Wrapper>
      <TaskDetails/>
      <NotesAndHistoryWrapper>
        <NotesAndHistory
          control={ control }
          currentUser={ profile }
          deleteConfirmationText={ `The Note will be deleted if you save the changes for the Task. 
            Are you sure you want to delete note?`
          }
          disableAddNoteTooltipText={ 'To create a Note you need to publish Task Name and select Linked Item' }
          isAddNoteBtnDisabled={ isAddNoteBtnDisabled }
          linkedContactData={ linkedContactData }
          names={ NOTES_AND_HISTORY_INPUT_NAMES }
          noteContactOptions={ notesUtilsStore.noteContactOptions }
          onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
          onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
          predefinedTags={ predefinedTags }
          title={ 'Task Notes & History' }
        />
      </NotesAndHistoryWrapper>
    </Wrapper>
  );
});

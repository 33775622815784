export const LISTBOX_PROPS = {
  style: {
    maxHeight: '200px'
  }
};

export const ASSIGNED_TASK_INSTRUCTIONS_INPUT_PROPS = {
  style: {
    margin: '-9px -5px'
  },
  placeholder: 'Enter Assigned Task Instructions',
  maxLength: 500,
};

export const ASSIGNED_TASK_INSTRUCTIONS_TEXT_FIELD_PROPS = {
  multiline: true,
  rows: 3,
};

import React, { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import BlockButton from '@components/BlockButton';
import { PlusWithCircle } from '@/assets';

import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';
import { getUseFieldArrayPath } from '@/shared/utils/getUseFieldArrayPath';
import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';

import { KEY_NAME, OCCUPATION_ITEM_DEFAULT_VALUE, OCCUPATIONS_FIELDS_NAMES } from './data';

import { AddButtonWrapper } from './styles';

import { OccupationItem } from './components';

import { ForceFieldsType, OccupationsProps } from './types';


export const Occupations = ({
  name
}: OccupationsProps) => {
  const { control, setValue, clearErrors } = useFormContext();
  const { fields , append, remove } = useFieldArray({
    control,
    keyName: KEY_NAME,
    name,
  });

  const allFields = useWatch({
    control,
    name,
  });

  const onAddClick = useCallback(() => append(OCCUPATION_ITEM_DEFAULT_VALUE),[append]);

  const onCurrentClick = useCallback((index: number) => {
    const isCurrentSelected = yesNoToBoolean((allFields as ForceFieldsType)[index].current);
    
    if(isCurrentSelected) {
      setValue(
        getUseFieldArrayPath(name, index, OCCUPATIONS_FIELDS_NAMES.current),
        booleanToYesNo(false),
        { shouldDirty: true }
      );
    } else {
      const prevCurrentIndex = (allFields as ForceFieldsType).findIndex(field => yesNoToBoolean(field.current));
      if(prevCurrentIndex >= 0){
        setValue(
          getUseFieldArrayPath(name, prevCurrentIndex, OCCUPATIONS_FIELDS_NAMES.current),
          booleanToYesNo(false),
          { shouldDirty: true }
        );
      }

      setValue(
        getUseFieldArrayPath(name, index, OCCUPATIONS_FIELDS_NAMES.current),
        booleanToYesNo(true),
        { shouldDirty: true }
      );
      clearErrors(getUseFieldArrayPath(name, index, OCCUPATIONS_FIELDS_NAMES.end));
    }
  },[allFields, clearErrors, name, setValue]);

  const onDeleteClick = useCallback((index: number) => remove(index),[remove]);

  const onUseAsTitleClick = useCallback((index: number) => {
    const isCurrentSelected = yesNoToBoolean((allFields as ForceFieldsType)[index].useAsTitle);

    if(isCurrentSelected) {
      setValue(
        getUseFieldArrayPath(name, index, OCCUPATIONS_FIELDS_NAMES.useAsTitle),
        booleanToYesNo(false),
        { shouldDirty: true }
      );
    } else {
      const prevUseAsTitleIndex = (allFields as ForceFieldsType).findIndex(field => yesNoToBoolean(field.useAsTitle));
      if(prevUseAsTitleIndex >= 0){
        setValue(
          getUseFieldArrayPath(name, prevUseAsTitleIndex, OCCUPATIONS_FIELDS_NAMES.useAsTitle),
          booleanToYesNo(false),
          { shouldDirty: true }
        );
      }

      setValue(
        getUseFieldArrayPath(name, index, OCCUPATIONS_FIELDS_NAMES.useAsTitle),
        booleanToYesNo(true),
        { shouldDirty: true }
      );
    }
  },[allFields, name, setValue]);

  return (
    <FormBlock 
      marginBottom={ 20 }
      paddingBottom={ 20 }
    >
      <FormBlock.Header title={ 'Occupations' }>
        <AddButtonWrapper>
          <BlockButton
            $iconSize={ '16px' }
            onClick={ onAddClick }
            startIcon={ <PlusWithCircle/> }
          >
            Add Occupation
          </BlockButton>
        </AddButtonWrapper>
      </FormBlock.Header>
      {
        (fields as ForceFieldsType).map((field, index) => (
          <OccupationItem 
            field={ field }
            index={ index }
            key={ field.useFormOccupationId }
            onCurrentClick={ onCurrentClick }
            onDeleteClick={ onDeleteClick }
            onUseAsTitleClick={ onUseAsTitleClick }
            parentName={ name }
          />
        ))
      }
    </FormBlock>
  );
};

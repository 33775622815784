import React from 'react';

import { COLOR_SCHEME, VARIANTS } from './data';

import { Wrapper } from './styles';

import { CounterProps } from './types';

export const Counter = ({ 
  bgColor,
  children,
  textColor,
  variant,
}: CounterProps) => {
  const backgroundColor = bgColor
    || (variant && COLOR_SCHEME[variant].bgColor)
    || COLOR_SCHEME[VARIANTS.common].bgColor;
  const color = textColor
    || (variant && COLOR_SCHEME[variant].color)
    || COLOR_SCHEME[VARIANTS.common].color;

  return (
    <Wrapper
      $bgColor={ backgroundColor }
      $color={ color }
    >
      { children }
    </Wrapper>
  );
};

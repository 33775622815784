import { ACCOUNTS_AND_PLANS_FILTER_NAMES } from '@constants/accountsAndPlanData';

export const USERS = {
  name: ACCOUNTS_AND_PLANS_FILTER_NAMES.users,
  label: 'Users',
  values: []
};

export const MANAGED_STATUS = {
  name: ACCOUNTS_AND_PLANS_FILTER_NAMES.managedStatus,
  label: 'Managed Status',
  values: []
};

export const STATUS = {
  name: ACCOUNTS_AND_PLANS_FILTER_NAMES.status,
  label: 'Status',
  values: []
};
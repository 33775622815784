import { makeAutoObservable, flow, action } from 'mobx';
import { AxiosResponse } from 'axios';
import {
  clearPersistedStore,
  hydrateStore,
  makePersistable,
  pausePersisting,
  startPersisting
} from 'mobx-persist-store';

import {
  getStates,
  getAppSettings,
} from '@services/api/common/common';
import { Store } from '../store';

import { getStatesOptions } from '@/shared/utils/getStatesOptions';
import { settingNormalizer } from './helpers';

import { FULL_NAME_ORDER } from '@constants/userSettings';
import { ID_OF_USASTATES, Roles } from '@constants/common';

import { GetStatesResponse, ValueLabelObj } from '@/shared/types/commonTypes';
import { AccountSettingsType, UserProfileType } from '@services/store/userSettingsStore/types';

export class SettingsStore {
  coreStore: Store;
  isMenuOpen: boolean = true;
  profile: UserProfileType = {} as UserProfileType;
  roles: Array<Roles> = [];
  settings: AccountSettingsType = {} as AccountSettingsType;
  states: Array<ValueLabelObj> = [];

  constructor(coreStore: Store) {
    this.coreStore = coreStore;

    makeAutoObservable(this, {
      getAppSettings: flow,
      getStates: flow,
      toggleMenuState: action.bound,
    });

    makePersistable(this, {
      name: 'settings',
      properties: ['settings', 'states', 'roles', 'profile'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.storageEvent();
  }

  get isContactsNamesReversed() {
    return this.settings.fullNameOrder === FULL_NAME_ORDER.lastName;
  }

  get profileId() {
    return this.profile.id;
  }

  storageEvent(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if(event.key === 'settings' && (event.oldValue !== event.newValue) && event.oldValue && event.newValue) {
        this.pausePersisting();

        const newValue = JSON.parse(event.newValue);
        this.setSettings(newValue.settings);

        this.startPersisting();
        this.hydrateStore();
        this.coreStore.SessionStore.hydrateStore();
      }
    });
  }

  setSettings(data: AccountSettingsType): void {
    const normalizedData = settingNormalizer(data);
    this.settings = normalizedData;
    this.coreStore?.CalendarStore?.setCurrentView(data?.defaultCalendarView);
  }

  setRoles(roles: Array<Roles>) {
    this.roles = roles;
  }

  setProfile(data: UserProfileType): void {
    this.profile = data;
  }

  *getAppSettings() {
    try {
      const response: AxiosResponse<any> = yield getAppSettings();
      this.setSettings(response.data.data.settings);
      this.setProfile(response.data.data.profile);
      this.setRoles(response.data.data.roles);

      yield this.getStates();
    } catch (error) {
      console.log({ error });
    }
  }

  *getStates() : Generator<any, void, any> {
    try {
      const response: AxiosResponse<GetStatesResponse> = yield getStates({ id: ID_OF_USASTATES });
      this.setStates(getStatesOptions(response.data.data));
    } catch (error) {
      console.log(error);
    }
  }

  setStates(data: Array<ValueLabelObj>) {
    this.states = data;
  }

  pausePersisting() {
    pausePersisting(this);
  }

  startPersisting() {
    startPersisting(this);
  }

  toggleMenuState(forcedState?: boolean) {
    this.isMenuOpen = typeof forcedState === 'boolean'
      ? forcedState
      : !this.isMenuOpen;
  }

  async hydrateStore() {
    await hydrateStore(this);
  }

  async clear() {
    this.pausePersisting();
    this.settings = {} as AccountSettingsType;
    this.states = [];
    this.roles = [];
    this.profile = {} as UserProfileType;
    this.startPersisting();
    this.toggleMenuState(true);
    await clearPersistedStore(this);
  }
}

import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';

import { Folder } from '@/assets';

import Box from '@material-ui/core/Box';
import DotsMenu from '@components/DotsMenu';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import {
  ListsRowFolderProps
} from '@pages/NewContacts/subpages/Lists/subpages/AllLists/components/ListsTable/components/ListsTableRow/types';
import { ListsFolderDotsMenuItemTypes } from './types';
import { ListDragObject } from '@/shared/types/lists';

import { LIST_FOLDER_DOTS_MENU_TYPES, MENU_OPTIONS } from './data';
import { TYPE_FIELDS } from '@constants/lists';

export const ListsRowFolder = ({
  item,
  moveToFolder,
  onRename,
  onDelete,
  onDropList,
}: ListsRowFolderProps) => {

  const [, dropTargetRef] = useDrop<ListDragObject>({
    accept: TYPE_FIELDS.List,
    drop: (draggedItem) => onDropList({
      id: draggedItem.listId,
      parentId: item.id
    }),
  });

  const moveToFolderWrapper = useCallback(() => {
    moveToFolder(item.id);
  }, [item.id, moveToFolder]);

  const onMenuSelect = useCallback((action: ListsFolderDotsMenuItemTypes) => {
    if(action === LIST_FOLDER_DOTS_MENU_TYPES.delete) {
      onDelete(item);
    }
    if(action === LIST_FOLDER_DOTS_MENU_TYPES.rename) {
      onRename(item);
    }
    if(action === LIST_FOLDER_DOTS_MENU_TYPES.view) {
      moveToFolderWrapper();
    }
  }, [item, moveToFolderWrapper, onDelete, onRename]);

  return (
    <Table.Row
      onDoubleClick={ moveToFolderWrapper }
      ref={ dropTargetRef }
    >
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <Box
          marginLeft={ '-1px' }
          marginTop={ '2px' }
        >
          <Folder/>
        </Box>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.name } />
      </Table.Cell>
      <Table.Cell>{item.typeList}</Table.Cell>
      <Table.Cell></Table.Cell>
      <Table.Cell>
        <DotsMenu<ListsFolderDotsMenuItemTypes>
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ MENU_OPTIONS }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
    </Table.Row>
  );
};

import React from 'react';

import { Tooltip } from '@material-ui/core';

import styled, { css } from 'styled-components';

import { hexToRgba } from '@/shared/utils/hexToRgba';

import { COLORS } from '@constants/colors';

const BG_COLOR = hexToRgba(COLORS.notificationWithAction, 0.65);

export const StyledTooltip = styled((props) => (
  <Tooltip classes={ { popper: props.className } } { ...props } />
))`
  &.MuiTooltip-popper {
    .MuiTooltip-tooltip {
      padding: 9px 12px 8px;
      background: ${BG_COLOR};
      font-size: 14px;
      line-height: 16px;

      & * {
        color: #fff;
      }
      
      ${({ $maxWidth }) => $maxWidth && css`
        max-width: ${$maxWidth};
      `}
  }};

      span {
        color: ${BG_COLOR};
      }
    }

    &[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
      margin-top: 14px;
    }

    &[data-popper-placement*="top"] .MuiTooltip-tooltip {
      margin-bottom: 14px;
    }
  }
`;

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';

import {
  AccountAndPlans,
  CalendarActivity,
  PendingTasks,
  Tags
} from '@modules/ContactComponents';

import { AccountsTagsLayoutWrapper, ActivityTasksLayoutWrapper } from '../styles';
import { BusinessDetailsWrapper } from './styles';


export const BusinessDetails = observer(() => {
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    accountPlansData,
    contact,
    getMoreActivity,
    id,
    nextCalendarActivityData,
    pendingTaskData,
    removeTodo,
    saveActivity,
    saveTodo,
  } = contactDetailsOverview;

  const routerStore = useStore().RouterStore;

  const goToContactActivities = useCallback(() => {
    if(contact) {
      routerStore.moveToContact({
        id: id!,
        contactType: contact.type,
        tab: CONTACT_DETAILS_TAB.activities,
      });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerStore, contact]);

  const goToContactToDos = useCallback(() => {
    if(contact) {
      routerStore.moveToContact({
        id: id!,
        contactType: contact.type,
        tab: CONTACT_DETAILS_TAB.toDos,
      });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerStore, contact]);

  const goToContactAccounts = useCallback(() => {
    if(contact) {
      routerStore.moveToContact({
        id: id!,
        contactType: contact.type,
        tab: CONTACT_DETAILS_TAB.accounts,
      });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerStore, contact]);

  if(!contact) {
    return null;
  }

  return (
    <BusinessDetailsWrapper>
      <ActivityTasksLayoutWrapper $height={ '49%' }>
        <CalendarActivity
          activity={ nextCalendarActivityData || [] }
          contact={ contact }
          getMoreActivity={ getMoreActivity }
          onSaveActivity={ saveActivity }
          onViewAllClick={ goToContactActivities }
        />
        <PendingTasks
          $tableHeight={ '209px' }
          headerTitle={ 'Pending Tasks' }
          isLoading={ false }
          isShowCompletedTasks={ false }
          linkedContact={ contact }
          onDeleteTask={ removeTodo }
          onSaveTodo={ saveTodo }
          onUpdateTask={ saveTodo }
          onViewAllClick={ goToContactToDos }
          table={ pendingTaskData || [] }
        />
      </ActivityTasksLayoutWrapper>
      <AccountsTagsLayoutWrapper $height={ '51%' }>
        <AccountAndPlans
          data={ accountPlansData || [] }
          headerTitle={ 'Accounts & Plans' }
          onViewAllClick={ goToContactAccounts }
        />
        <Tags
          contactType={ contact.type }
          headerTitle={ 'Groups & Interests' }
        />
      </AccountsTagsLayoutWrapper>
    </BusinessDetailsWrapper>
  );
});

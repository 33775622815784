import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    position: relative;
    padding: 0 16px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      position: absolute;
      content: '';
      top: 7px;
      left: 1px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${props => props.$indicatorColor};
    }
`;

export const Name = styled.div`
  font-size: 14px;
  color: ${ COLORS.primaryText };
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;


export const Date = styled.div`
  font-size: 10px;
  color: ${ COLORS.helpText };
`;

import styled from 'styled-components';
import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  pointer-events: ${({ $preventClicks }) => $preventClicks ? 'none' : 'auto'};
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  & button {
    min-width: auto;
  }
`;

export const NotificationText = styled.div``;

export const NotificationTextItem = styled.p`
  margin: 0;
`;

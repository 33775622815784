import { action, flow, makeAutoObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { FieldErrors } from 'react-hook-form';

import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';
import {
  BASIC_INFORMATION_FIELDS_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';
import {
  COMMON_TABS_VALUES,
  TABS
} from '@modules/AccountsAndPlansPopup/data/data';
import { InitPopupSettings, InitType } from './data';

import { BackendCommonResponse, IdType, ValueOf } from '@/shared/types/commonTypes';
import {
  CloseModal,
  FormFieldsValues,
  OuterOnSubmit
} from '@modules/AccountsAndPlansPopup/types';
import { LinkedContact } from '@/shared/types/linkedContact';

import {
  AccountAndPlansPopupSettingsResponseType,
  AccountAndPolicyCommonData,
  AddEditBalanceHistoryRowState,
  AddEditBeneficiariesRowState,
  AddEditStandingInstructionsRowState,
  BalanceHistoryData,
  Beneficiaries,
  CommonData,
  EditItemResponse,
  InitProps,
  MiddleColumnRef,
  PopupSettings,
  StandingInstructionsData,
  UseFormMethods,
} from './types';

import {
  getAccountAndPlansPopupSettings,
  getByIdAccountAndPlans,
  saveAccountOrPoliceOrPlan,
  updateAccountAndPlans
} from '@services/api/accountAndPlans/accountAndPlans';

import { getDefaultState } from '@modules/AccountsAndPlansPopup/utils/getDefaultState';
import { normalizer } from './normalizers/normalizer';
import { processPopupTableData } from './utils';
import { NoteTagItem } from '@/shared/types/tags';
import { NotesUtilsStore } from '@modules/NotesAndHistory';
import { getTags } from '@services/api/tags/tags';

class AccountAndPlansPopupLocalStore {

  accountAndPolicyCommonData: AccountAndPolicyCommonData | {} = {};
  addEditBalanceHistoryRowState: AddEditBalanceHistoryRowState = null;
  addEditBeneficiaryRowState: AddEditBeneficiariesRowState = null;
  addEditStandingInstructionsRowState: AddEditStandingInstructionsRowState = null;
  balanceHistoryData: BalanceHistoryData | {} = {};
  closeModal: CloseModal = () => {};
  commonData: CommonData | {} = {};
  currentTab: string | number = TABS[InitType][0].value;
  editId: IdType | null = null;
  isLoad: boolean = true;
  isNotesPanelDisabled: boolean = true;
  linkedContactData: LinkedContact | null = null;
  middleColumnRef: MiddleColumnRef = null;
  notesUtilsStore: NotesUtilsStore;
  outerOnSubmit?: OuterOnSubmit;
  popupSettings: PopupSettings = InitPopupSettings;
  predefinedTags: Array<NoteTagItem> = [];
  standingInstructionsData: StandingInstructionsData | {} = {};
  tabs: ValueOf<typeof TABS> = TABS[InitType];
  tempData: FormFieldsValues | null = null;
  type: ACCOUNTS_AND_PLANS_TYPES = InitType;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  constructor() {
    makeAutoObservable(this, {
      getNoteTags: flow,
      init: flow.bound,
      onAcceptNotesProcessing: flow,
      onCancel: action.bound,
      onSave: action.bound,
      onValidAction: flow,
      save: flow,
      saveOrUpdate: flow,
      reset: action.bound
    });

    this.notesUtilsStore = new NotesUtilsStore();
  }

  *init({
    id,
    linkedContact,
    useFormMethods,
    outerOnSubmit,
    closeModal,
  }: InitProps) {
    try {
      const settingsResponse:AccountAndPlansPopupSettingsResponseType = yield getAccountAndPlansPopupSettings();
      this.popupSettings = normalizer(settingsResponse);
      this.useFormMethods = useFormMethods;
      this.closeModal = closeModal;
      this.outerOnSubmit = outerOnSubmit;

      yield this.getNoteTags();

      if (id) {
        const { data:{ data: editItemData } }: EditItemResponse = yield getByIdAccountAndPlans({ id });

        this.editId = editItemData.id;
        this.type = editItemData.accountType;

        this.setLinkedContactData(editItemData.contactData);
        this.setTabsByType(this.type);

        const defaultStateByType = getDefaultState(this.type);
        const mergedDefaultWithExistedData = {
          ...defaultStateByType,
          ...editItemData,
        };

        this.useFormMethods.reset(mergedDefaultWithExistedData);
      } else if (linkedContact) {
        this.setLinkedContactData(linkedContact);

        const defaultStateByType = getDefaultState(this.type);
        const mergedDefaultWithExistedData = {
          ...defaultStateByType,
          contactId: linkedContact.id
        };

        this.useFormMethods.reset(mergedDefaultWithExistedData);
      } else {
        this.useFormMethods.reset(getDefaultState(this.type));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  get isSaveCloseActionDisabled(): boolean {
    return (
      this.notesUtilsStore.isNotesPanelInAddOrEditMode ||
      Boolean(this.addEditStandingInstructionsRowState) ||
      Boolean(this.addEditBalanceHistoryRowState) ||
      Boolean(this.addEditBeneficiaryRowState)
    );
  }

  *getNoteTags(){
    const tagsResp:AxiosResponse<BackendCommonResponse<Array<NoteTagItem>>> = yield getTags({
      type: 'Account Note',
      searchPhrase: ''
    });

    this.predefinedTags = tagsResp.data.data;
  }

  onCancel() {
    if(this.notesUtilsStore.alertType){
      this.notesUtilsStore.setAlertType(null);
    } else {
      this.closeModal();
    }
  }

  *saveOrUpdate(data: FormFieldsValues) {
    const dataWithOrNotId = {
      ...data,
      ...(this.editId ? { id: this.editId } : {}),
    };

    if(this.outerOnSubmit){
      this.outerOnSubmit(dataWithOrNotId);
    } else {
      try {
        if(this.editId) {
          yield updateAccountAndPlans(dataWithOrNotId);
        } else {
          yield saveAccountOrPoliceOrPlan(dataWithOrNotId);
        }

      } catch (error) {
        console.log(error);
      }
    }
  }

  *onAcceptNotesProcessing() {
    const processedData = {
      ...this.tempData,
      accountPlansNotes: this.notesUtilsStore.convertNotesByAlertType(
        this.tempData?.accountPlansNotes || [],
        this.linkedContactData
      )
    } as FormFieldsValues;

    this.closeModal();
    yield this.saveOrUpdate(processedData);
  }

  *onValidAction(data:FormFieldsValues) {
    if(this.notesUtilsStore.detectAndSetAlert(data.accountPlansNotes || [], this.linkedContactData)){
      this.tempData = data;
      return;
    }

    this.closeModal();
    yield this.saveOrUpdate(data);
  }

  onInValidAction(data:FieldErrors<FormFieldsValues>) {
    if(this.type === ACCOUNTS_AND_PLANS_TYPES.PLAN){
      return;
    }

    if(this.currentTab !== COMMON_TABS_VALUES.BASIC_INFORMATION){
      this.setCurrentTab(COMMON_TABS_VALUES.BASIC_INFORMATION);
    }

    if(this.middleColumnRef){
      this.middleColumnRef.scrollTo({ left: 0, top: 0, behavior:'smooth' });
    }
  }

  onSave(){
    if(this.notesUtilsStore.alertType){
      this.onAcceptNotesProcessing();
    } else {
      this.save();
    }
  }

  *save(){
    yield this.useFormMethods.handleSubmit(
      this.onValidAction.bind(this),
      this.onInValidAction.bind(this)
    )();
  }

  setMiddleColumnRef(ref: MiddleColumnRef){
    this.middleColumnRef = ref;
  }

  setLinkedContactData(linkedContactData: LinkedContact | null) {
    this.notesUtilsStore.setPrevLinkedContact(this.linkedContactData);
    this.notesUtilsStore.setCurrentLinkedContact(linkedContactData);

    this.linkedContactData = linkedContactData;
  }

  setCurrentTab(newTabValue: string | number) {
    this.currentTab = newTabValue;
  }

  setTabsByType(newType: ACCOUNTS_AND_PLANS_TYPES){
    this.tabs = TABS[newType];
    this.setCurrentTab(this.tabs[0].value);
  }
  setCommonData(data:FormFieldsValues) {
    const { name, contactId, accountPlansNotes } = data;
    this.commonData = {
      contactId,
      name,
      ...(accountPlansNotes? { accountPlansNotes }: {})
    };
  }

  setAddEditStandingInstructionsRowState(data: AddEditStandingInstructionsRowState){
    this.addEditStandingInstructionsRowState = data;
  }

  resetStandingInstructionsRowState(){
    this.setAddEditStandingInstructionsRowState(null);
  }
  setStandingInstructionData(data:FormFieldsValues) {
    const { accountType } = data;
    if(accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT || accountType === ACCOUNTS_AND_PLANS_TYPES.POLICY) {
      this.standingInstructionsData = {
        standingInstructionsNotes: data?.standingInstructionsNotes || '',
        standingInstructions: processPopupTableData({
          source: data?.standingInstructions,
          state: this.addEditStandingInstructionsRowState
        }),
      };

      this.resetStandingInstructionsRowState();
    }
  }

  setAddEditBalanceHistoryRowState(data: AddEditBalanceHistoryRowState){
    this.addEditBalanceHistoryRowState = data;
  }

  resetBalanceHistoryRowState(){
    this.setAddEditBalanceHistoryRowState(null);
  }

  setBalanceHistoryData(data:FormFieldsValues) {
    const { accountType } = data;
    if(accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT || accountType === ACCOUNTS_AND_PLANS_TYPES.POLICY) {

      this.balanceHistoryData = {
        balanceHistory: processPopupTableData({
          source: data?.balanceHistory,
          state: this.addEditBalanceHistoryRowState
        }),
      };

      this.resetBalanceHistoryRowState();
    }
  }

  setAddEditBeneficiaryRowState(data: AddEditBeneficiariesRowState){
    this.addEditBeneficiaryRowState = data;
  }

  resetBeneficiaryRowState() {
    this.setAddEditBeneficiaryRowState(null);
  }

  processBeneficiaries(beneficiaries?: Beneficiaries): Beneficiaries {
    const processedBeneficiaries = processPopupTableData({
      source: beneficiaries,
      state: this.addEditBeneficiaryRowState
    });

    this.resetBeneficiaryRowState();

    return processedBeneficiaries;
  }

  setAccountAndPolicyCommonData(data:FormFieldsValues) {
    const { accountType } = data;
    if(accountType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT || accountType === ACCOUNTS_AND_PLANS_TYPES.POLICY) {
      const {
        accountNumber,
        applicationDate,
        beneficiaries,
        deadlineDate,
        deliveryDate,
        effectiveDate,
        heldAwayStatus,
        institutionName,
        managedStatus,
        productModel,
        registrationType,
        reviewDate,
        status,
        submittedDate,
        taxQualification,
      } = data;

      this.accountAndPolicyCommonData ={
        accountNumber,
        applicationDate,
        [BASIC_INFORMATION_FIELDS_NAMES.beneficiaries]: this.processBeneficiaries(beneficiaries),
        deadlineDate,
        deliveryDate,
        effectiveDate,
        heldAwayStatus,
        institutionName,
        managedStatus,
        productModel,
        registrationType,
        reviewDate,
        status,
        submittedDate,
        taxQualification,
      };
    }
  }

  onTypeChange(newType: ACCOUNTS_AND_PLANS_TYPES) {
    this.type = newType;
    const formFields = this.useFormMethods.getValues();

    this.setCommonData(formFields);
    this.setAccountAndPolicyCommonData(formFields);
    this.setStandingInstructionData(formFields);
    this.setBalanceHistoryData(formFields);

    this.setTabsByType(this.type);
    this.resetFormStateWithCompatibleData(this.type);
  }

  resetFormStateWithCompatibleData(newType: ACCOUNTS_AND_PLANS_TYPES) {
    const defaultState = getDefaultState(newType);
    let extendData = {};
    if(newType === ACCOUNTS_AND_PLANS_TYPES.ACCOUNT){
      extendData = {
        ...defaultState,
        ...this.commonData,
        ...this.accountAndPolicyCommonData,
        ...this.standingInstructionsData,
        ...this.balanceHistoryData,
      };
    }

    if(newType === ACCOUNTS_AND_PLANS_TYPES.POLICY){
      extendData = {
        ...defaultState,
        ...this.commonData,
        ...this.accountAndPolicyCommonData,
        ...this.standingInstructionsData,
        ...this.balanceHistoryData,
      };
    }

    if(newType === ACCOUNTS_AND_PLANS_TYPES.PLAN){
      extendData = {
        ...defaultState,
        ...this.commonData,
      };
    }

    this.useFormMethods.reset(extendData);
  }

  reset() {
    this.isLoad = true;
    this.isNotesPanelDisabled = true;
    this.outerOnSubmit = undefined;
    this.editId = null;
    this.type = InitType;
    this.commonData = {};
    this.standingInstructionsData= {};
    this.balanceHistoryData = {};
    this.accountAndPolicyCommonData = {};
    this.predefinedTags = [];
    this.tempData = null;

    this.resetBalanceHistoryRowState();
    this.resetBeneficiaryRowState();
    this.resetStandingInstructionsRowState();
    this.setLinkedContactData(null);
    this.setTabsByType(this.type);
    this.notesUtilsStore.resetNotesState();
  }

  setNotesPanelDisableState(state: boolean) {
    this.isNotesPanelDisabled = state;
  }
}

export default AccountAndPlansPopupLocalStore;

import { useRef, useEffect } from 'react';

import { UseResizeObserverProps } from './types';
export const useResizeObserver = ({
  elementRef,
  onResizeCb
}:UseResizeObserverProps) => {
  const observer = useRef(
    new ResizeObserver((entries:ResizeObserverEntry[]) => {
      const { contentRect } = entries[0];

      if(onResizeCb && elementRef.current) {
        onResizeCb({
          element: elementRef.current,
          contentRect,
        });
      }
    })
  );

  useEffect(() => {
    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      if(elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(elementRef.current);
      }
    };
  }, [elementRef, observer]);
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import FormBlock from '@components/FormBlock';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';

import { getContactWithSpouseHousehold } from '@/shared/services/api/common/common';

import { getNameParam, usersResponseConverter, } from '@/shared/utils/fetchAutocompleteUtils';
import { LinkedContactProps } from './types';
import { ActivityPopupFieldsName, LABEL_WIDTH } from '@/modules/ActivityPopup/data';
import { LinkedContact as LinkedContactType } from '@/shared/types/linkedContact';

export const LinkedContact = observer(({
  initLinkedContact,
  onLinkedContactChange
}: LinkedContactProps) => {

  const { control } = useFormContext();

  return (
    <FormBlock.RowWrapper>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH.common }
        label={ 'Linked Contact' }
      >
        <HFLinkedContact<LinkedContactType>
          FetchProps={ {
            getParams: getNameParam,
            requestSender: getContactWithSpouseHousehold,
            responseConverter: usersResponseConverter,
          } }
          control={ control }
          initLinkedContact={ initLinkedContact }
          name={ ActivityPopupFieldsName.primaryContactId }
          onLinkedContactChange={ onLinkedContactChange }
        />
      </FormBlock.FormField>
    </FormBlock.RowWrapper>
  );
});

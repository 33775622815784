import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { NAME_COLUMN_LEFT_PADDING } from '../../data';

export const IconOuterWrapper = styled.div`
  width: ${NAME_COLUMN_LEFT_PADDING};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  
  & > svg > path {
    fill: ${COLORS.green}
  }
`;

export const InfoWrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
`;

export const InfoIconWrapper = styled.div`
  width: 20px;
  height: 22px;
  margin: auto 9px auto 0;
  
  & > svg > path {
    fill: ${COLORS.orange}
  }
`;

export const InfoTextWrapper = styled.div`
  flex-grow: 1;
  
  display: flex;
  flex-direction: column;
  height: 33px;
  max-width: 100px;
  margin: auto 0;
`;

import React, { useCallback } from 'react';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';

import { UserCustomFieldAlertPopupProps } from './types';

export const UserCustomFieldAlertPopup = ({
  closeModal,
  modalProps,
}: UserCustomFieldAlertPopupProps) => {
  const { message, onConfirmCallback } = modalProps;

  const onConfirmClick = useCallback(() => {
    closeModal();
    onConfirmCallback();
  }, [closeModal, onConfirmCallback]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'Cancel' }
      confirmLabel={ 'Ok' }
      isOpen={ true }
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
      type={ POPUP_TYPES.CONFIRM }
    />
  );
};

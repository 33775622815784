import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { COLORS } from '@constants/colors';

export const CustomSaveButton = styled(Button)`
  background-color: ${COLORS.menuBck};

  &:hover {
    background-color: ${COLORS.menuLines};
  }
`;

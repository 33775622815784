export const EVENT_VIEW_TYPE = {
  line: 'line',
  column: 'column',
  empty: 'empty',
  highLine: 'highLine',
};

export const EVENT_CARD_BREAKPOINTS = {
  minColumnWidth: 30,
  minColumnHeight: 21,
  minLineWidth: 45,
  minLineHeight: 6,
  maxLineHeight: 16,
  minHeightLineHeight: 16,
  maxHeightLineHeight: 21,
};

export const ICON_MAX_VISIBILITY_WIDTH = 30;
export const TEXT_MAX_VISIBILITY_WIDTH = 100;

export const MAX_VISIBILITY_HEIGHT = 15;
import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-right: 40px;

    &:last-child{
        margin-right: 0;
    }
`;
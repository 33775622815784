import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import { ClientOpportunitiesNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';


export const ClientOpportunitiesCells = observer(({
  item,
}: TrashCellsProps<ClientOpportunitiesNormalizerItem['item']>) => {

  const amountConverted = useMemo(() => formatNumberToAmount(item.amount), [item.amount]);

  return (
    <>
      <Table.Cell>
        {
          !Array.isArray(item.contactData) ?
            (
              <UserItem
                labelWidth={ 250 }
                user={ item.contactData }
                withCoreClassification={ true }
              />
            )
            : '-'
        }
      </Table.Cell>
      <Table.Cell>
        { item.opportunityStatus ?
          <SalesClientOpportunitiesStatusOption
            label={ item.opportunityStatus }
            value={ item.opportunityStatus }
          />
          : '-'
        }
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ item.saleType || '-' }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ amountConverted }/>
      </Table.Cell>
    </>
  );
});

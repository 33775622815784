import React, { useCallback, useEffect, useState } from 'react';

import UserItem from '@components/NewUserItem';
import FetchAutoComplete from '@modules/FetchAutoComplete';

import { LinkedContactOuterWrapper, LinkedContactWrapper } from './styles';

export const LinkedContact = ({
  AutocompleteProps,
  disabled,
  FetchProps,
  TextFieldProps,
  UserItemOptionProps,
  UserItemProps,
  initLinkedContact,
  onChange,
  onLinkedContactChange = (data) => {},
  showError,
  withoutQueryMode,
  withEditButton = false,
  withRemoveButton = true,
  value,
}) => {
  const [ linkedContact, setLinkedContact ] = useState(initLinkedContact || null);

  const onSelectContact = useCallback((data) => {
    setLinkedContact(data);
    onLinkedContactChange(data);
    onChange(data.id);
  },[onChange, onLinkedContactChange]);


  const removeContactFromState = useCallback(() => {
    setLinkedContact(null);
    onLinkedContactChange(null);
    onChange(null);
  }, [onChange, onLinkedContactChange]);

  const onEditContact = useCallback(() => {
    removeContactFromState();
  },[removeContactFromState]);

  useEffect(() => {
    if(String(value) === String(initLinkedContact?.id)){
      setLinkedContact(initLinkedContact);
    }
  }, [initLinkedContact, value]);

  return(
    <>
      {
        linkedContact && (
          <LinkedContactOuterWrapper $showError={ showError }>
            <LinkedContactWrapper>
              <UserItem
                innerPadding={ '0px' }
                onClose={ withRemoveButton ? removeContactFromState : undefined }
                onEdit={ withEditButton ? onEditContact : undefined }
                user={ linkedContact }
                withCoreClassification={ true }
                { ...UserItemProps }
              />
            </LinkedContactWrapper>
          </LinkedContactOuterWrapper>
        )
      }
      {
        !linkedContact &&
        <FetchAutoComplete
          AutocompleteProps={ {
            renderOption: (option) => (
              <UserItem
                user={ option }
                { ...UserItemOptionProps }
              />
            ),
            getOptionLabel: (option) => option.fullName || 'label',
            ...AutocompleteProps
          } }
          FetchProps={ FetchProps }
          TextFieldProps={ TextFieldProps }
          disabled={ disabled }
          onChange={ onSelectContact }
          showError={ showError }
          withoutQueryMode={ withoutQueryMode }
        />
      }
    </>
  );
};

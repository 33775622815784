import { TabsValues } from './types';

export const ADD_USER_POPUP_TABS = [
  {
    value: TabsValues.AddUser,
    label: TabsValues.AddUser,
  },
  {
    value: TabsValues.UserProfile,
    label: TabsValues.UserProfile,
  },
];
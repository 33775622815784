import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { DatePicker } from '@material-ui/pickers';

import ContentToggle from '@components/ContentToggle';
import CheckboxDropDown from '@components/CheckboxDropDown';
import SideFilters from '@modules/SideFilters';

import { CategoryLabel } from '@/components/CategoryLabel';

import { CALENDAR_VIEWS } from '@constants/common';
import {
  ASSIGNED_TO,
  CLASSIFICATION,
  CATEGORY,
} from './data';

import { addFilterOptions } from '@utils/filterUtils';

import {
  DatePickerWrapper,
  InnerWrapper,
} from './styles';

export const Filters = observer(() => {
  const calendarStore = useStore().CalendarStore;
  const {
    filtersData,
    selectedFiltersCount,
    filters,
    userSelectedDate,
    onClear
  } = calendarStore;

  const usersData = useMemo(() => addFilterOptions(ASSIGNED_TO, filtersData.clients), [filtersData]);
  const classification = useMemo(() => addFilterOptions(CLASSIFICATION, filtersData.classification), [filtersData]);
  const category = useMemo(() => addFilterOptions(CATEGORY, filtersData.category), [filtersData]);

  const onChange = useCallback((fieldName, newValue) => {
    calendarStore.setFilters({
      [fieldName]: newValue,
    });
  }, [calendarStore]);


  const setDate = useCallback((date) => {
    calendarStore.setDate(date);
    calendarStore.setCurrentView(CALENDAR_VIEWS.day);
    calendarStore.setUserSelectedDate(date);
  },[calendarStore]);

  return (
    <SideFilters
      count={ selectedFiltersCount }
      isHeaderExists={ false }
      isNonePointerEvents={ false }
      onClearClick={ onClear }
    >
      <InnerWrapper>
        <ContentToggle
          label={ 'Calendar Navigation' }
        >
          <DatePickerWrapper>
            <DatePicker
              ToolbarComponent={ () => null }
              onChange={ setDate }
              openTo={ 'date' }
              value={ userSelectedDate }
              variant={ 'static' }
            />
          </DatePickerWrapper>
        </ContentToggle>
        {
          usersData &&
          <CheckboxDropDown
            label={ usersData.label }
            name={ usersData.name }
            setState={ onChange }
            state={ filters?.[usersData.name] || [] }
            values={ usersData.values }
          />
        }
        {
          classification &&
          <CheckboxDropDown
            label={ classification.label }
            name={ classification.name }
            setState={ onChange }
            state={ filters?.[classification.name] || [] }
            values={ classification.values }
          />
        }
        {
          category &&
          <CheckboxDropDown
            label={ category.label }
            name={ category.name }
            renderItemLabel={ (label,value) => (
              <CategoryLabel
                label={ label }
                value={ value }
              />
            ) }
            setState={ onChange }
            state={ filters?.[category.name] || [] }
            values={ category.values }
          />
        }
      </InnerWrapper>
    </SideFilters>
  );
});

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { MoreBtnProps, TextWrapperProps } from './types';

export const Wrapper = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  color: ${COLORS.primaryText};
  font-weight: 300;
`;


export const TextWrapper = styled.div<TextWrapperProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ $isExpand }) => $isExpand ? 'unset' : '2'};
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
`;

export const MoreBtn = styled.span<MoreBtnProps>`
  position: absolute;
  right: 0;
  bottom: 1px;
  visibility: ${({ $isShow }) => $isShow ? 'visible' : 'hidden'};
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const ElipsisText = styled.span`
  padding-right: 16px;
  color: ${COLORS.primaryText};
  background-color: ${COLORS.white};
`;

export const GradientFadeOut = styled.span`
  display: inline-block;
  width: 100px;
  height: 20px;
  margin-bottom: -5px;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
`;

export const ClickableText = styled.span`
  color: ${COLORS.orange};
  background-color: ${COLORS.white};
`;

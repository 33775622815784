import styled from 'styled-components';

export const LeftWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 25px;
`;


export const RightWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 45px;
`;

import React, { useCallback } from 'react';

import { useStore } from '@store';

import { EmployeeDeleteConfirmationProps } from './types';

import ConfirmationPopup from '@modules/ConfirmationPopup';

export const EmployeeDeleteConfirmation = ({
  closeModal,
  modalProps,
}: EmployeeDeleteConfirmationProps) => {
  const { id } = modalProps;
  const relatedContactsStore = useStore().EmployeeStore;

  const onConfirm = useCallback(() => {
    closeModal();
    relatedContactsStore.deleteEmployee(id);
  },[closeModal, id, relatedContactsStore]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ 'Are you sure you want to remove employee?' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirm }
    />
  );
};



const COMMON_TASK_NORMALIZED_OMIT_PROPS = [
  'assignedByData',
  'clientData',
  'contactData',
  'createdByData',
  'linkedItemData',
];

export const CLONE_TASK_NORMALIZED_OMIT_PROPS = [
  ...COMMON_TASK_NORMALIZED_OMIT_PROPS,
  'createdDate',
  'id',
  'isNew',
  'taskNotes',
];

export const EDIT_TASK_NORMALIZED_OMIT_PROPS = COMMON_TASK_NORMALIZED_OMIT_PROPS;
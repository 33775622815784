export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `${ r },${ g },${ b }`;
  }

  return null;
};

export const hexToRgba = (hex, opacity) => {
  const rgbValue = hexToRgb(hex);

  if (rgbValue) {
    return `rgba(${ rgbValue }, ${ opacity })`;
  }

  return null;
};

type Props = {
  dateString: string,
  separatorIn?: string,
  separatorOut?: string,
}
export const convert_string_YY_MM_DD_to_MM_DD_YY = ({
  dateString,
  separatorIn = '-',
  separatorOut =  '-',
}: Props) => {
  const [YY, MM, DD] = dateString.split(separatorIn);
  return [MM, DD, YY].join(separatorOut);
};
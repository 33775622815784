import { BackendTodoFormFields, TodoFormFields } from '@/shared/types/todos';
import {
  OFFICIAL_REQUEST_SUBCATEGORIES,
  STAGES,
  TO_DOS_FIELDS_NAMES,
  YES_NO_TO_BOOLEAN_REQUIRRING_FIELD
} from '@constants/todosData';
import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';

export const convertData = (data: TodoFormFields) => {
  const convertedData = Object.entries(data).reduce((acc, item) => {
    const [name, value] = item as [string, unknown];

    if(name === TO_DOS_FIELDS_NAMES.category) {
      const isRequest = Object.values(OFFICIAL_REQUEST_SUBCATEGORIES).some((subCategory) => {
        return subCategory === value;
      });
      if(isRequest) {
        acc[TO_DOS_FIELDS_NAMES.request] = value;
        acc[name] = null; 
        return acc;
      } else {
        acc[TO_DOS_FIELDS_NAMES.request] = null; 
      }
      acc[name] = value;
      return acc;
    }

    if (YES_NO_TO_BOOLEAN_REQUIRRING_FIELD.includes(name)) {
      acc[name] = booleanToYesNo(value as boolean);
      return acc;
    }

    acc[name] = value;
    return acc;
  }, {} as Record<string, unknown>);

  return convertedData as BackendTodoFormFields;
};


export const getQuickCompletedData = (data: TodoFormFields, isStageComplete: boolean) => {
  if(isStageComplete){
    return {
      ...data,
      [TO_DOS_FIELDS_NAMES.stage]: STAGES.complete,
    };
  } else {
    return data;
  }
};

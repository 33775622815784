import styled from 'styled-components';

export const ManualItemsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ActionsWrapper = styled.div`
  margin-left: 20px;
  
  & > * {
    margin-right: 20px;
    
    &:last-child{
      margin-right: 0;
    }
  }
`;
import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ComplianceInformationEmailSignature, Headshots } from '@pages/NewUserSettings/components/ProfileInformation';
import AdditionalUserInformation from './components/AdditionalUserInformation';

import { UserProfileFormInputNames } from '@pages/NewUserSettings/data';
import { convertBlobImageToString } from '@/shared/utils/convertBlobImageToString';


export const UserProfile = observer(() => {

  const { control, setValue } = useFormContext();
  const [ headshot, logo, signature ] = useWatch({
    control,
    name: [
      UserProfileFormInputNames.headshot,
      UserProfileFormInputNames.logo,
      UserProfileFormInputNames.signature
    ]
  });

  const setImageValue = useCallback((image: string, formFieldName: string) => {
    if(image) {
      const photo = convertBlobImageToString(image);
      setValue(formFieldName, photo);
    }
  }, [ setValue ]);

  useEffect(() => {
    setImageValue(headshot, UserProfileFormInputNames.headshot);
    setImageValue(logo, UserProfileFormInputNames.logo);
    setImageValue(signature, UserProfileFormInputNames.signature);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ headshot, logo, signature ]);
    
  return (
    <>
      <Headshots
        inputNames={ UserProfileFormInputNames }
        title={ 'User Information' }
      />

      <AdditionalUserInformation/>

      <ComplianceInformationEmailSignature
        inputNames={ UserProfileFormInputNames }
      />
    </>
  );
});
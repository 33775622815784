import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFImageUploader from '@/modules/HookFormComponents/HFImageUploader';

import { ImageUploaderTypes } from '@constants/common';

import { HeaderWrapper, WrapperImageUploader } from '@/pages/NewUserSettings/components/Layout/styles';

import { FORM_BLOCK_MARGIN_BOTTOM } from '@/pages/NewUserSettings/data';

import { CommonProfileInformationFormProps } from '@/pages/NewUserSettings/types';

export const Headshots = ({
  title,
  inputNames,
}: CommonProfileInformationFormProps) => {

  const { control } = useFormContext();

  return (
    <FormBlock marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
      { title &&
        <HeaderWrapper>
          <FormBlock.Header title={ title }/>
        </HeaderWrapper>
      }
      <FormBlock.RowWrapper>
        <WrapperImageUploader>

          <HFImageUploader
            control={ control }
            isAvatar={ true }
            name={ inputNames.headshot  }
          />

          <HFImageUploader
            control={ control }
            imageType={ ImageUploaderTypes.Signature }
            isAvatar={ false }
            name={ inputNames.signature }
            title={ 'Signature' }
          />

          <HFImageUploader
            control={ control }
            imageType={ ImageUploaderTypes.Logo }
            isAvatar={ false }
            name={ inputNames.logo }
            title={ 'Company Logo' }
          />

        </WrapperImageUploader>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import { INDIVIDUAL_PROFILE_LINKS } from '@constants/routes/contacts';

import {
  CommonCustomFields,
  CommonRelatedContacts,
  CommonSystemDetails,
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components';

import {
  IndividualIndividualDetails,
  IndividualPersonalDetails,
} from './components';

import { IndividualContentTabsProps } from './types';

const isNotContactTab = (subTab:string | null) => {
  return subTab === INDIVIDUAL_PROFILE_LINKS.customFields || subTab === INDIVIDUAL_PROFILE_LINKS.relatedContacts;
};

export const IndividualContentTabs = observer(({
  contactId,
  subTab,
}: IndividualContentTabsProps) => {
  const contactDetailsProfileStore = useStore().ContactDetailsProfileStore;
  const useFormMethods = useFormContext();

  const [prevTab, setPrevTab] = useState<string | null>(null);
  const [prevContactId, setPrevContactId] = useState<string | number | null>(null);

  useEffect(() => {
    setPrevTab(subTab);
    setPrevContactId(contactId);

    contactDetailsProfileStore.resetStateOnTabSwitch();

    if(isNotContactTab(subTab)){
      return;
    }

    if(!prevTab || isNotContactTab(prevTab) || prevContactId !== contactId){
      contactDetailsProfileStore.init(contactId, useFormMethods);
    }
    // eslint-disable-next-line
  }, [contactId, subTab]);

  switch (subTab) {
  case INDIVIDUAL_PROFILE_LINKS.individualDetails:
    return <IndividualIndividualDetails />;
  case INDIVIDUAL_PROFILE_LINKS.personalDetails:
    return <IndividualPersonalDetails />;
  case INDIVIDUAL_PROFILE_LINKS.customFields:
    return <CommonCustomFields contactId={ contactId }/>;
  case INDIVIDUAL_PROFILE_LINKS.relatedContacts:
    return <CommonRelatedContacts contactId={ contactId }/>;
  case INDIVIDUAL_PROFILE_LINKS.systemDetails:
    return <CommonSystemDetails />;
  default:
    throw new TypeError('Unexpected subTab for Individual type');
  }
});

import React, { useCallback } from 'react';

import { ArrowStepperWrapper, ArrowStepperItem, ArrowStepperDecoration, ArrowStepperLabel } from './styles';

import { ArrowStepperItemWrapperProps, ArrowStepperProps } from './types';

const ArrowStepperItemWrapper = ({
  onChange,
  $isLast,
  $isFirst,
  $isActive,
  item
}: ArrowStepperItemWrapperProps) => {

  const onChangeWrapper = useCallback(() => {
    onChange(item.value);
  }, [item.value, onChange]);

  return (
    <ArrowStepperItem
      $isActive={ $isActive }
      $isFirst={ $isFirst }
      $isLast={ $isLast }
      onClick={ onChangeWrapper }
    >
      <ArrowStepperDecoration/>
      <ArrowStepperLabel>
        {item.label}
      </ArrowStepperLabel>
    </ArrowStepperItem>
  );
};

export const ArrowStepper = ({
  currentSelected,
  disabled,
  onChange,
  options,
}: ArrowStepperProps) => (
  <ArrowStepperWrapper $disabled={ disabled }>
    {
      options.map((item, index) => (
        <ArrowStepperItemWrapper
          $isActive={ item.value === currentSelected }
          $isFirst={ index === 0 }
          $isLast={ index === options.length - 1 }
          item={ item }
          key={ item.value }
          onChange={ onChange }
        />
      ))
    }
  </ArrowStepperWrapper>
);

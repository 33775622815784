import React, { useCallback, useMemo, memo } from 'react';
import { useDrag } from 'react-dnd';

import Avatar from '@components/Avatar';
import BookmarkIcon  from '@components/BookmarkIcon';
import SalesCycleDotsMenu from '@modules/SalesPipline/SalesCycleDotsMenu';
import TextWithTooltip from '@components/TextWithTooltip';

import {
  ActionsWrapper,
  AvatarWrapper,
  InfoFirstRow,
  InfoSecondRow,
  InfoWrapper,
  TicketInfoWrapper,
  Wrapper
} from './styles';

import { OutOfSightCommon } from '@components/OutOfSight';
import { SALES_CYCLE_KANBAN_DND_TYPE, SALES_CYCLE_STATUS_COLOR_MAP } from '@constants/salesActiveCycle';
import { USER_ICON_SIZES } from '@components/UserIcon';
import NextApptDate from '@modules/NextApptDate';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';
import { getAppointmentDateLabel } from '@/shared/utils/getApptDateLabel';
import { getAvatarData } from '@/shared/utils/getAvatarData';
import { getContactName } from '@/shared/utils/getContactName';

import { SaleCycleKanbanTicketProps } from './types';
import { SalesKanbanDragDataType } from '@/shared/types/salesActiveCycle';


const LAST_APPOINTMENT_LABEL = 'Last Appt.';
const NEXT_APPOINTMENT_LABEL = 'Next Appt.';

export const SalesActiveCycleKanbanTicket = memo(({
  item,
  onDelete,
  onEdit,
  onBookmark,
  onOutOfSightClick
}: SaleCycleKanbanTicketProps) => {
  const isBlocked = typeof item?.contactData?.editAllow === 'number' && !item?.contactData?.editAllow;

  const [, dragRef] = useDrag<SalesKanbanDragDataType>({
    type: SALES_CYCLE_KANBAN_DND_TYPE,
    item: {
      id: item.id,
      prevStage: item.stage,
      isBlocked: isBlocked
    }
  });

  const avatarData = useMemo(() => getAvatarData(item.contactData), [item.contactData]);

  const name = useMemo(() => getContactName(item.contactData), [item.contactData]);
  const appointmentDateLabel = getAppointmentDateLabel(
    item.nextAppointmentDate || null,
    LAST_APPOINTMENT_LABEL,
    NEXT_APPOINTMENT_LABEL
  );
  const amountConverted = useMemo(() => formatNumberToAmount(item?.amount), [item?.amount]);

  const onBookmarkClick = useCallback(() => {
    onBookmark(item.id, item.stage);
  }, [item.id, item.stage, onBookmark]);

  const onTicketClick = useCallback(() => {
    onEdit(item.id);
  }, [item.id, onEdit]);

  return (
    <Wrapper
      $indicatorColor={ SALES_CYCLE_STATUS_COLOR_MAP[item.salesCycleStatus] }
      ref={ dragRef }
    >
      <TicketInfoWrapper onDoubleClick={ onTicketClick }>
        <AvatarWrapper>
          <Avatar
            $size={ USER_ICON_SIZES.large }
            $subType={ true }
            mainImageData={ avatarData.mainImageData }
            subImageData={ avatarData?.subImageData }
          />
        </AvatarWrapper>
        <InfoWrapper>
          <InfoFirstRow>
            <TextWithTooltip
              $fontSize={ 14 }
              onClick={ onTicketClick }
              text={ name }
            />
            <TextWithTooltip
              $fontSize={ 12 }
              text={ amountConverted }
            />
          </InfoFirstRow>
          <InfoSecondRow>
            <TextWithTooltip
              $fontSize={ 12 }
              text={ appointmentDateLabel }
            />
            <NextApptDate
              date={ item.nextAppointmentDate || null }
              disabled={ false }
              fallBack={ '-' }
            />
          </InfoSecondRow>
        </InfoWrapper>
        <ActionsWrapper>
          <BookmarkIcon
            isActive={ Boolean(item.flagged) }
            onStateChange={ onBookmarkClick }
          />
          <SalesCycleDotsMenu
            $marginTop={ '5px' }
            id={ item.id }
            onDelete={ onDelete }
            onEdit={ onEdit }
          />
        </ActionsWrapper>
      </TicketInfoWrapper>
      { isBlocked && <OutOfSightCommon onClick={ onOutOfSightClick }/> }
    </Wrapper>);
});

SalesActiveCycleKanbanTicket.displayName = 'SalesActiveCycleKanbanTicket';

import axios from 'axios';
import browserHistory from '@services/browserHistory';

import { store } from '@services/store/store';
import { ROUTES } from '@constants/routes';

const axiosApi = axios.create({
  // TODO condition with Env variable;
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
});

axiosApi.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }
    if (!('Authorization' in config.headers)) {
      const token = store.AuthStore.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  }
);

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.ModalStore.closeAllModals();
      store.AuthStore.clear();
      store.SettingsStore.clear();
      store.SessionStore.endSession();
      browserHistory.push(ROUTES.login);
    }

    return Promise.reject(error);
  }
);

export default axiosApi;

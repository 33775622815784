import get from 'lodash/get';

export const getErrorMessage = (formState) => (pathPart) => get(formState.errors, `${pathPart}.message`);

export const getPattern = (regEx) => (message) => ({
  pattern: {
    value: regEx,
    message,
  }
});

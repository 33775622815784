import styled, { css } from 'styled-components';

import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

import { ContainerProps, WrapperCustomProps } from './types';

export const Wrapper = styled(MuiBox)<WrapperCustomProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  
  position: relative;
  
    &:before {
      position: absolute;
      content: '';
      right: 0;
      top: 20px;
      bottom: 20px;
      width: 1px;
      background-color: ${COLORS.dividingLines};
    }
`;

export const ContentWrapper = styled(MuiBox)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  width: 100%;
  
  flex-grow: 1;
  box-sizing: border-box;
  padding: ${({ padding }) => padding || '10px' };
  overflow-y: auto;
  border: 1px solid ${COLORS.borders};
  border-radius: 6px;
  
  ${({ $disableScroll }) => $disableScroll && css`
    overflow: hidden;
  `}
`;

import { ConvertedListsFiltersData, ListsFiltersType } from '@/shared/types/lists';

export const INIT_FILTERS_STATE: ListsFiltersType = {
  fullName: null,
  status: [],
  classification: [],
  officePrimaryAdvisor: [],
  listOwner: [],
};

export const INIT_FILTERS_DATA_STATE: ConvertedListsFiltersData = {
  status: [],
  classification: [],
  officePrimaryAdvisor: [],
  listOwner: [],
};

import { tagsTypes } from '@modules/AddContactModal/addContactStore/data/constantData';
import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { removeFalseValues } from '@/shared/utils/removeFalseValues';
import { removeDuplicates as rd } from '@/shared/utils/removeDuplicates';
import { getIsOnlyCountryCode } from '@modules/HookFormComponents/HFPhoneField/validation';

import { FIELD_IDS } from '@constants/contactsData';

export const formatContacts = (data) => {
  const fields = [
    {
      fieldId: FIELD_IDS.MAILING_LOCATION_ID,
      country: data.address.country || null,
      state: data.address.state || null,
      zip: data.address.zip || null,
      city: data.address.city || null,
      street: data.mailingLocation || null,
      street2: data.addressLine2 || null,
    },
    {
      fieldId: FIELD_IDS.MOBILE_PHONE_ID,
      // TODO by default the phone number must be set NULL if only the country code is entered
      value: getIsOnlyCountryCode(data.mobilePhone) ? null : data.mobilePhone
    },
    {
      fieldId: FIELD_IDS.WORK_EMAIL_ID,
      value: data.email || null
    },
    {
      fieldId: FIELD_IDS.CONTACT_PREFERENCE_ID,
      value: data.preference || null
    }
  ];
  return fields.filter(({ fieldId, ...otherProps }) => Object.values(otherProps).some(prop => prop !== null));
};

export const formatRules = (rules) => {
  for (let key in rules) {
    rules[key] = Number(!rules[key]);
  }
  return rules;
};

export const formatHouseholderContacts = (main, spouse, communicateWith, contacts) => {

  const getCommunicate = (index) => {
    if(communicateWith === 'Both'){
      return 'Yes';
    }

    return booleanToYesNo(index + 1 === communicateWith);
  };

  return [main, spouse].map((contact, index) => ({
    ...removeFalseValues(contact),
    householdOwner: booleanToYesNo(contact.householdOwner),
    contacts: contact.householdOwner ? contacts : [],
    communicateWith: getCommunicate(index),
  }));
};

export const formatRelatedContacts = (store) => {
  const { linkedContact, relatedContacts, spouseContact } = store;

  const formatedContacts = relatedContacts.map(({ id, relationType }) => ({
    relativeId: id,
    relationType
  }));

  if (spouseContact) {
    formatedContacts.push({
      relativeId: spouseContact.id,
      relationType: 'Spouse'
    });
  }

  if (linkedContact) {
    formatedContacts.push({
      relativeId: linkedContact.id,
      relationType: 'Current Employee'
    });
  }

  return formatedContacts;
};

export const formatNewContact = (data) => {
  const relatedEmployeesContact = [];

  if(!getIsOnlyCountryCode(data.phoneNumber)) {
    relatedEmployeesContact.push({
      fieldId: data.phoneType,
      // TODO by default the phone number must be set NULL if only the country code is entered
      value: data.phoneNumber
    });
  }

  if(data.email) {
    relatedEmployeesContact.push({
      fieldId: data.emailType,
      value: data.email
    });
  }

  const fields = [{
    firstName: data.firstName || null,
    lastName: data.lastName || null,
    primaryContact: 'Yes' || null,
    relatedEmployeesContact
  }];
  return fields.filter(({ fieldId, ...otherProps }) => Object.values(otherProps).some(prop => prop !== null));
};

export const formatTags = ({ interest, general }) => {
  const formattedTags = [];

  [rd(interest, 'label'), rd(general, 'label')].forEach((tagsArray, index) => {
    tagsArray.forEach(({ type, label, value }) => {
      // eslint-disable-next-line eqeqeq
      if (type == tagsTypes.new) {
        const typeFormat = index ? tagsTypes.general : tagsTypes.interest;
        return formattedTags.push({ type: typeFormat, label });
      }
      formattedTags.push({ id: value });
    });
  });

  return formattedTags;
};

import React from 'react';

import { useController } from 'react-hook-form';

import { PasswordInput } from '@components/PasswordInput/PasswordInput';

import { HFPasswordInputProps } from './types';

export const HFPasswordInput = ({
  control,
  ControllerProps = {},
  helperTextNodeElement = '',
  helperTextMessage = '',
  name,
  placeholder,
  showError = true,
  TextFieldProps = {}
}: HFPasswordInputProps) => {

  const {
    field: {
      value,
      onChange
    },
    fieldState:{ invalid, error }
  } = useController({
    name,
    control,
    ...ControllerProps
  });

  return (
    <PasswordInput
      error={ invalid }
      helperText={ (
        <>
          { helperTextNodeElement }
          { (invalid && error?.message) || helperTextMessage }
        </>
      ) }
      onChange={ onChange }
      placeholder={ placeholder }
      showError = { showError }
      TextFieldProps={ TextFieldProps }
      value={ value }
    />
  );
};
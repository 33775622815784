import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';

import { TAG_BUTTON_VARIANTS } from './data';

import { OuterWrapperProps, ViewContentWrapperProps, WrapperProps } from './types';
import { ERROR_CONTAINER_STYLES, ERROR_SPACE_VALUE } from '@constants/style';

export const OuterWrapper  = styled.div<OuterWrapperProps>`
  ${({ $showError }) => $showError && css`
    position: relative;
    padding-bottom: ${ERROR_SPACE_VALUE}px;
  `}
`;

export const ErrorMessageWrapper  = styled.div`
  ${ERROR_CONTAINER_STYLES}
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  height: 24px;
  padding: 3px 15px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 4px;
  max-width: 100%;
  
  ${({ $isError }) => $isError && css`
    outline: 1px solid ${COLORS.red};
  `}
  
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
  
  ${({ $isClickable }) => $isClickable && css`
    cursor: pointer;
  `}

  ${({ $variant, $isActive }) => {
    if($variant === TAG_BUTTON_VARIANTS.custom) {
      const bgColor = $isActive ? COLORS.tagCustomBg : COLORS.tag;
    
      return css`
        color: ${COLORS.tagText};
        background-color: ${bgColor};
      
        &:hover{
          background-color: ${bgColor};
        }

        & svg path {
          fill: ${COLORS.tagText};
        }
    `;
    }

    if($variant === TAG_BUTTON_VARIANTS.system) {
      return css`
      color: ${COLORS.helpText};
      background-color: ${COLORS.background};

      &:hover{
        color: ${COLORS.tagText};
        background-color: ${COLORS.tag};
      }
    `;
    }

    if($variant === TAG_BUTTON_VARIANTS.interest) {
      const bgColor = $isActive ? COLORS.interestTagHoverBg : COLORS.interestTag;
      return css`
        color: ${COLORS.interestTagText};
        background-color: ${bgColor};

        &:hover{
          color: ${COLORS.interestTagText};
          background-color: ${bgColor};
        }

        & svg path {
          fill: ${COLORS.interestTagText};
        }
      `;
    }

    if($variant === TAG_BUTTON_VARIANTS.systemInterest) {
      return css`
        color: ${COLORS.helpText};
        background-color: ${COLORS.background};

        &:hover{
          color: ${COLORS.interestTagText};
          background-color:${COLORS.interestTag};
        }
      `;
    }
  }};
`;

export const ViewContentWrapper = styled.div<ViewContentWrapperProps>`
  ${({ $viewContentMaxWidth }) => $viewContentMaxWidth && css`
    max-width: ${$viewContentMaxWidth};
    text-overflow: ellipsis;  
    overflow: hidden;
    white-space: nowrap;
  `};
`;

import styled from 'styled-components';
import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

export const RelatedListContainer = styled(MuiBox)`
  display: flex;
  align-items: center;
  padding: 0 20px 0 15px;
  border-bottom: 1px solid ${COLORS.dividingLines};
`;

export const CustomSelectContainer = styled(MuiBox)`
  margin: 0 100px 0 auto;
  
  & div {
    width: 200px;
  }
`;

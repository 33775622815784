import { useEffect, useRef } from 'react';

export const useTriggerRef = () => {
  const triggerValidationRef = useRef();
  const useSetTriggerRefOnce = (trigger) => {
    useEffect(() => {
      if (!triggerValidationRef.current) {
        triggerValidationRef.current = trigger;
      }
    }, [ trigger ]);
  };

  return {
    useSetTriggerRefOnce,
    triggerRef: triggerValidationRef?.current,
  };
};

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import Table from '@components/Table';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';

import { SalesClientOpportunitiesRow } from './components';

import { COLUMNS } from './data';

import { SalesClientOpportunitiesTableProps } from './types';


export const SalesClientOpportunitiesTable = observer(({
  onRemove,
  onAddEdit
}: SalesClientOpportunitiesTableProps) => {
  const { isLoad } = useStore().SalesCycleStore;
  const {
    table,
    isPageActive,
    updateFlagsSingle,
    onRowChange,
  } = useStore().SalesCycleStore.SalesClientOpportunitiesTable;

  const onAddWrapper = useCallback(() => onAddEdit(), [onAddEdit]);

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: table.globalCheckboxState.checked,
            indeterminate: table.globalCheckboxState.indeterminate,
            onChange: table.onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isLoad && table.items.length > 0 &&
            table.items.map(item => (
              <SalesClientOpportunitiesRow
                isChecked={ table.getItemCheckboxState(item) }
                item={ item }
                key={ item.id }
                onBookmark={ updateFlagsSingle }
                onChange={ onRowChange }
                onCheckboxClick={ table.onCheckBoxClick }
                onDoubleClick={ onAddEdit }
                onEdit={ onAddEdit }
                onRemove={ onRemove }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isLoad && table.items.length === 0 &&
          <ContentCenter>
            <NoDataItemsView
              addActionButtonClickHandler={ onAddWrapper }
              addActionButtonLabel={ 'Add Opportunity' }
              withAction={ true }
            />
          </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>);
});

import { CRITERIA_TYPES, CRITERIA_TYPES_LABELS } from '@constants/listsCriteria';
import { ValueLabelObj } from '@/shared/types/commonTypes';

export const LABEL_WIDTH = 73;
export const COMMON_WRAPPER_MAX_WIDTH = 210;
export const WIDE_WRAPPER_MAX_WIDTH = 290;

export const NAME_OPTIONS:Array<ValueLabelObj> = Object.values(CRITERIA_TYPES).map(item => ({
  value: item,
  label: CRITERIA_TYPES_LABELS?.[item] || ''
}));

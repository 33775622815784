import { createContext, useContext } from 'react';
import { flow, makeAutoObservable } from 'mobx';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { getFoldersTree } from '@services/api/lists/lists';

import { AxiosResponse } from 'axios';
import {
  GetNamesAccountType,
  NewFolderTypeWithChildrens,
  NewFolderPopupFolderIndex,
  TreeItemWithChildren,
  TreeResponse,
  TreeRoot, MoveToAnotherFolderProps, NewFolderType
} from '@/shared/types/lists';
import {
  DEFAULT_NEW_FOLDER_NAME,
  NEW_FOLDER_FRONT_ID_KEY,
  NEW_FOLDER_STATIC_INDEX, TREE_ROOT_MOCK,
  TYPE_FIELDS
} from '@constants/lists';

import { getEscapedStringForRegexp } from '@/shared/utils/getEscapedStringForRegexp';


class MoveListStore {
  scrollTargetRef: HTMLDivElement | null = null;
  editIndex: NewFolderPopupFolderIndex = NEW_FOLDER_STATIC_INDEX;
  isLoad: boolean = true;
  selectedIndex: NewFolderPopupFolderIndex = NEW_FOLDER_STATIC_INDEX;
  //if we need several level for nest new folder, need to implement setter method
  // for target, and when we add new folder we search will search by target place to nest
  targetForNewFolder: null = null;
  treeItems: Array<NewFolderTypeWithChildrens | TreeItemWithChildren> = [];
  treeRoot: TreeRoot = TREE_ROOT_MOCK;


  constructor() {
    makeAutoObservable(this, {
      init: flow
    });
  }

  *init() {
    try {
      this.isLoad = true;
      const treeResponse: AxiosResponse<TreeResponse> = yield getFoldersTree();
      // Attention we don't have root at the response, it injected at view
      this.treeItems = treeResponse.data.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  getNameAndPostfix(str: string) {
    const counterMatches = str.match(/\((\d+)\)/g);
    let prevCounter: number | undefined = undefined;

    if (counterMatches && counterMatches.length > 0) {
      const lastMatch = counterMatches.pop()?.match(/\d+/);
      prevCounter = lastMatch ? Number(lastMatch[0]) : undefined;
    }

    return {
      nameWithoutCounter: str.replace(/\((\d+)\)/g, '').trim(),
      prevCounter
    };
  }

  getName(name: string,  tempId: string): string {
    const regexPattern = new RegExp(`^${getEscapedStringForRegexp(name)}\\((\\d+)\\)$`);

    // if we have more then one level, we need to find childrens of target,
    // convert them to string, and check
    // Attention if root inject in tree, not at view, refactor it to search and map childrens
    // at root children property
    const {
      names,
      defaultNamePrefixCount,
      prevName,
      isNewNameEqualToPrevWithoutCounter,
    } = this.treeItems.reduce((acc:GetNamesAccountType, item,) => {
      if(name.startsWith(DEFAULT_NEW_FOLDER_NAME)){
        acc.defaultNamePrefixCount = acc.defaultNamePrefixCount + 1;
      }

      if(NEW_FOLDER_FRONT_ID_KEY in item && item[NEW_FOLDER_FRONT_ID_KEY] === tempId){
        const { nameWithoutCounter, prevCounter } = this.getNameAndPostfix(item.name);
        acc.isNewNameEqualToPrevWithoutCounter = name === nameWithoutCounter && Boolean(prevCounter);
        acc.prevName = item.name;
        // acc.names.push(name);
      } else {
        acc.names.push(item.name);
      }


      return acc;
    }, {
      names: [],
      defaultNamePrefixCount: 0
    });

    const matchingNames = names.filter(str => str === name || regexPattern.test(str));

    if (matchingNames.length === 0 || defaultNamePrefixCount === 1) {
      return name;
    }

    if(isNewNameEqualToPrevWithoutCounter && prevName) {
      return prevName;
    }

    let maxPostfix = 0;

    matchingNames.forEach(matchingName => {
      let postfix = matchingName.slice(name.length);
      let postfixToNumber = Number(postfix.replace(/\(|\)/g, ''));

      if (!isNaN(postfixToNumber)) {
        maxPostfix = Math.max(maxPostfix, postfixToNumber);
      }
    });

    return name + '(' + (maxPostfix + 1) + ')';
  }

  getSaveData = (): Omit<MoveToAnotherFolderProps, 'closeModal' | 'listItem'> => {
    const newFolders = this.treeItems.filter(item => NEW_FOLDER_FRONT_ID_KEY in item) as Array<NewFolderType>;
    const index = this.treeItems.findIndex((item) => {
      if(NEW_FOLDER_FRONT_ID_KEY in item){
        return item[NEW_FOLDER_FRONT_ID_KEY] === this.selectedIndex;
      }

      return item.id === this.selectedIndex;
    });

    let saveTarget;

    if(this.selectedIndex === null){
      saveTarget = TREE_ROOT_MOCK;
    } else {
      saveTarget = this.treeItems[index];
    }

    return {
      newFolders,
      saveTarget,
    };
  };

  onFolderAdd = () => {
    const tempId = uuidv4();

    if(this.targetForNewFolder === null){
      this.editIndex = tempId;
      const newFolder: NewFolderTypeWithChildrens = {
        [NEW_FOLDER_FRONT_ID_KEY]: tempId,
        childrens: [],
        id: -1000000000,
        name: DEFAULT_NEW_FOLDER_NAME,
        parentId: -1000000000,
        typeField: TYPE_FIELDS.Folder,
      };
      this.treeItems = [...this.treeItems, newFolder];
    }

    const onAddScroll = () => {
      if(this.scrollTargetRef){
        this.scrollTargetRef.scrollIntoView({ behavior: 'smooth' });
      }
    };

    setTimeout(() => {
      onAddScroll();
    }, 0);

  };

  onBlurAccept = (newValue: string, tempId: string) => {
    // if you need more than one level need to improve algorithm of path search
    const editedItemIndex = this.treeItems.findIndex((item => {
      if(NEW_FOLDER_FRONT_ID_KEY in item){
        return item[NEW_FOLDER_FRONT_ID_KEY] === this.editIndex;
      }
      return false;
    }));

    if(editedItemIndex >= 0){
      if(this.treeItems[editedItemIndex].name !== newValue || newValue === DEFAULT_NEW_FOLDER_NAME){
        const name = this.getName(newValue, tempId);
        this.treeItems[editedItemIndex] = {
          ...this.treeItems[editedItemIndex],
          name,
        };
        this.treeItems = this.treeItems.slice();
      }
      this.editIndex = NEW_FOLDER_STATIC_INDEX;
    }
  };

  setSelectedIndex = (newIndex:NewFolderPopupFolderIndex) => {
    this.selectedIndex = newIndex;
  };

  setEditIndex = (newIndex:NewFolderPopupFolderIndex) => {
    this.editIndex = newIndex;
  };

  setScrollTargetRef = (ref: HTMLDivElement) => {
    this.scrollTargetRef = ref;
  };

  resetStore = () => {
    this.isLoad = false;
    this.selectedIndex = NEW_FOLDER_STATIC_INDEX;
    this.editIndex = NEW_FOLDER_STATIC_INDEX;
    this.treeItems = [];
    this.scrollTargetRef = null;
  };
}

const store = new MoveListStore();

export const MoveListStoreContext = createContext<MoveListStore>(store);

export const useMoveListStore = () => useContext(MoveListStoreContext);

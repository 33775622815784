import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  & .MuiButton-startIcon {
    margin-right: 15px;
  }
  
  & .MuiButton-label {
    text-align: left;
  }
  
  & svg {
    width: 30px;
    height: 30px;
  }
`;

import React, { useCallback } from 'react';

import BookmarkIcon  from '@components/BookmarkIcon';
import ContentHeader from '@components/BasicLayoutComponents/ContentHeader';

import {
  BookmarkIconWrapper,
} from './styles';
import { PreTableWrapperProps } from './types';


export const PreTableWrapper = ({
  children,
  bookmarkIconProps
}: PreTableWrapperProps) => {

  const onGlobalBookmarkClick = useCallback((state: boolean) => {
    if(bookmarkIconProps) {
      bookmarkIconProps.onStateChange(state);
    }
  },[bookmarkIconProps]);

  return (
    <ContentHeader>
      {
        bookmarkIconProps &&
          <BookmarkIconWrapper>
            <BookmarkIcon
              isActive={ bookmarkIconProps.isActive }
              onStateChange={ onGlobalBookmarkClick }
            />
          </BookmarkIconWrapper>
      }
      {children}
    </ContentHeader>
  );
};

import { RestorePasswordFieldNames } from './types';

export const FORM_FIELD_NAMES: RestorePasswordFieldNames = {
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword',
};

export const FORM_DEFAULTS_VALUES = {
  [FORM_FIELD_NAMES.newPassword]: '',
  [FORM_FIELD_NAMES.confirmPassword]: '',
};

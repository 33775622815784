import * as yup from 'yup';

import { dateOutputValidation } from '@/shared/constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';
import { isDateBefore, isDateAfter } from '@/shared/utils/dateValidationUtils';
import { yupTestMethodValidationTrigger } from '@/shared/utils/yupTestMethodValidationTrigger';

import { DateFilters } from '@/shared/services/store/trashStore/types';
import { UseFormTrigger } from 'react-hook-form/dist/types';


const prevValues = {};

export const getValidationScheme = (names: DateFilters, trigger?: UseFormTrigger<DateFilters>) => (
  yup.object().shape({
    [names.startDate]: yup.string()
      .nullable()
      .matches(dateOutputValidation, validationMessages.invalidDate)
      .test(
        'mast be equal or less than endDate',
        validationMessages.invalidDate,
        (value, context) => {
          const result = isDateBefore({
            dateStringToCompare: context.parent.endDate,
            dateString: value,
            withEqual: true,
          });

          yupTestMethodValidationTrigger({
            prevValues,
            fieldName: names.startDate,
            currentValue: result,
            triggerFunction: trigger
          });

          return result;
        }
      ),
    [names.endDate]: yup.string()
      .nullable()
      .matches(dateOutputValidation, validationMessages.invalidDate)
      .test(
        'mast be equal or grater than startDate',
        validationMessages.invalidDate,
        (value, context) => {
          const result = isDateAfter({
            dateStringToCompare: context.parent.startDate,
            dateString: value,
            withEqual: true,
          });

          yupTestMethodValidationTrigger({
            prevValues,
            fieldName: names.endDate,
            currentValue: result,
            triggerFunction: trigger
          });

          return result;
        }
      )
  })
)
;




import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { InformationBlockStyledProps } from './types';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px #101C5B12;
  background: ${COLORS.white};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
`;

export const IconWrapper = styled.div<InformationBlockStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  ${({ colors: { second } }) => second && `background: ${second}`};

  & svg path {
    ${({ colors: { main } }) => main && `fill: ${main}`};
  }
`;

export const Divider = styled.div<InformationBlockStyledProps>`
  width: 30px;
  height: 2px;
  border-radius: 2px;
  margin: 10px 0 15px 0;
  ${({ colors: { main } }) => main && `background: ${main}`};
`;

export const CountWrapper = styled.div`
  font-size: 46px;
  font-weight: 300;
  line-height: 54px;
  color: ${COLORS.primaryText};
`;

export const ContentTextWrapper = styled.div`
  line-height: 20px;
  color: ${COLORS.helpText};
`;

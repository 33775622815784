import React from 'react';

import { CustomCheckboxWithoutLabel } from './CustomCheckboxWithoutLabel';

import { LABEL_PLACEMENT } from './data';

import { StyledFormControlLabel } from './styles';

import { CustomCheckboxProps } from './types';

export const CustomCheckbox = ({
  $distanceFromLabel = '10px',
  $size,
  $withPaddings,
  CheckboxProps,
  checked,
  disabled,
  indeterminate,
  label,
  labelPlacement = LABEL_PLACEMENT.end,
  onChange,
}: CustomCheckboxProps) => {
  return (
    <StyledFormControlLabel
      $disabled={ disabled }
      $distanceFromLabel={ $distanceFromLabel }
      control={
        <CustomCheckboxWithoutLabel
          $size={ $size }
          $withPaddings={ $withPaddings }
          CheckboxProps={ CheckboxProps }
          checked={ checked }
          disabled={ disabled }
          indeterminate={ indeterminate }
          onChange={ onChange }
        />
      }
      label={ label }
      labelPlacement={ labelPlacement }
    />
  );
};

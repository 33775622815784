import { TagData } from '@components/TagButton/types';

export const calculateValue = (value: string, count?: number) =>  {
  if(typeof count === 'number'){
    return count > 0 ? `${value} (${count})` : value;
  }

  if(typeof value === 'number' || typeof  value === 'string'){
    return value;
  }

  return 'New Tag';
};

export const getValue = (tagData: TagData): string => {
  const value = 'value' in tagData ? tagData.value : tagData.label;
  return calculateValue(value, tagData?.count);
};

import React, { useCallback, useMemo } from 'react';
import { format, getYear } from 'date-fns';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { COLORS } from '@constants/colors';
import {
  ALL_SELECT_OPTION,
  DASHBOARD_FILTER_NAMES,
  PERIOD_FILTER_NAMES,
  PERIOD_OPTIONS,
  QUARTERS_SCHEME
} from '@constants/dashboard';

import { ChevronLeft, ChevronRight } from '@/assets';
import { UserSelectFilter } from '@modules/UserSelectFilter';
import CustomIconButton, { SIZES } from '@components/CustomIconButton';
import CustomSelect from '@components/CustomSelect';

import {
  AdvisorLabelWrapper,
  AdvisorPeriodFilter,
  Divider,
  PeriodWrapper,
  SubTitle,
  Wrapper,
  YearFilter,
} from './styles';

import { IdType } from '@/shared/types/commonTypes';
import { Period } from '@/shared/types/dashboard';


export const Header = observer(() => {
  const {
    filters,
    isFetching,
    period,
    primaryAdvisors,
    quarter,
    year,
    setFilters,
    setPeriod,
    setRange,
  } = useStore().DashboardStore;

  const onChangePeriod = useCallback((event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setPeriod(event.target.value as Period);
  }, [setPeriod]);

  const onAdvisorFilterChange = useCallback((value: Array<IdType>) => {
    setFilters({ [DASHBOARD_FILTER_NAMES.adviserId]: value });
  }, [setFilters]);

  const range = useMemo(() => {
    if(period === PERIOD_FILTER_NAMES.year) {
      return getYear(year);
    }
    const start = format(new Date(getYear(year), QUARTERS_SCHEME[quarter].firstMonthNumber), 'LLLL');
    const end = format(new Date(getYear(year), QUARTERS_SCHEME[quarter].endMonthNumber), 'LLLL');

    const result = `${start} - ${end} ${getYear(year)}`;

    return result;
  }, [year, period, quarter]);

  return (
    <Wrapper>
      <SubTitle>
        My Business Snapshot
      </SubTitle>
      <YearFilter>
        <CustomIconButton
          $iconColor={ COLORS.primaryText }
          $iconHoverColor={ COLORS.orange }
          $size={ SIZES.small }
          icon={ ChevronLeft }
          onClick={ () => setRange(-1) }
        />
        <SubTitle>
          { range }
        </SubTitle>
        <CustomIconButton
          $iconColor={ COLORS.primaryText }
          $iconHoverColor={ COLORS.orange }
          $size={ SIZES.small }
          icon={ ChevronRight }
          onClick={ () => setRange(1) }
        />
      </YearFilter>
      <AdvisorPeriodFilter>
        <AdvisorLabelWrapper>Advisor</AdvisorLabelWrapper>
        <UserSelectFilter
          allUserOption={ ALL_SELECT_OPTION }
          closeTrigger={ isFetching }
          onChange={ onAdvisorFilterChange }
          usersOptions={ primaryAdvisors }
          value={ filters?.[DASHBOARD_FILTER_NAMES.adviserId] || [] }
          withAllUserOption={ true }
        />
        <Divider/>
        <PeriodWrapper>
          <CustomSelect
            onChange={ onChangePeriod }
            options={ PERIOD_OPTIONS }
            value={ period }
          />
        </PeriodWrapper>
      </AdvisorPeriodFilter>
    </Wrapper>
  );
});

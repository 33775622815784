import { RootFieldNames, FieldNames } from './types';
export const STAGES_DATA = [
  [ 'Confirm Contact 1', 'Information' ],
  [ 'Confirm Contact 2', 'Information' ],
  [ 'Conversion', 'Details' ],
];

export const ROOT_FIELD_NAMES: RootFieldNames = {
  createSpouse: 'createSpouse',
  householderContacts: 'householderContacts',
};

export const FIELD_NAMES: FieldNames = {
  birthdate: 'birthdate',
  classification: 'classification',
  firstName: 'firstName',
  gender: 'gender',
  id: 'id',
  lastName: 'lastName',
  nickname: 'nickname',
  officePrimaryAdvisor: 'officePrimaryAdvisor',
  rating: 'rating',
  sourceCategory:  'sourceCategory',
  sourceId: 'sourceId',
  status: 'status',
};

export const FIELD_NAMES_TO_VALIDATE = [
  FIELD_NAMES.firstName,
  FIELD_NAMES.lastName,
  FIELD_NAMES.classification,
  FIELD_NAMES.officePrimaryAdvisor,
  FIELD_NAMES.status,
  FIELD_NAMES.birthdate
];
import { parse } from 'date-fns';

import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';
import { StartEndDateItem } from '@/shared/types/commonTypes';

export const convertStartEndDateToUTCFormat = (item: StartEndDateItem): StartEndDateItem => {
  const { startDate, startTime, endDate, endTime, isAllDay } = item;

  if(isAllDay) {
    return item;
  }

  const { date: convertedStartDate, time: convertedStartTime } = convertDateToUTCFormat(startDate, startTime);
  const { date: convertedEndDate, time: convertedEndTime } = convertDateToUTCFormat(endDate, endTime);

  return {
    startDate: convertedStartDate,
    startTime: convertedStartTime,
    endDate: convertedEndDate,
    endTime: convertedEndTime
  };
};

export const convertDateToUTCFormat = (date: string, time: string) => {
  const parseDate = parse(`${date} ${time}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date());

  const dateToISO = parseDate.toISOString().slice(0, 19).replace('T', ' ').split(' ');

  return {
    date: dateToISO[0],
    time: dateToISO[1]
  };
};

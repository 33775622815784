import React from 'react';

import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { AllContacts, ContactsDetails } from './subpages';

export const Contacts = observer(() => (
  <Switch>
    <Route
      component={ AllContacts }
      exact={ true }
      path={ ROUTES.contactsAll }
    />
    <Route
      component={ ContactsDetails }
      exact={ false }
      path={ ROUTES.contactDetails }
    />
  </Switch>
));
import { useMemo, useCallback } from 'react';

import get from 'lodash/get';

import { IdType } from '@/shared/types/commonTypes';
// TODO replace it with commontable store
type UseGlobalCheckboxProps = {
  selectedIds: Array<IdType>,
  pageItemIdPath?: string,
  onGlobalCheckboxClickCallback: (arrOfIds: Array<IdType>) => void,
  pageItems: Array<object>,
}

export const useGlobalCheckbox = ({
  onGlobalCheckboxClickCallback,
  pageItemIdPath = 'id',
  pageItems,
  selectedIds,
}: UseGlobalCheckboxProps) => {
  const pageItemsIdsArray = useMemo(() => {
    return pageItems.map(item => get(item, pageItemIdPath));
  }, [ pageItems, pageItemIdPath ]);

  const globalCheckboxState = useMemo(() => {
    const isAllCheckedOnCurrentPage = pageItemsIdsArray.every(id => selectedIds.includes(id));
    const checked = isAllCheckedOnCurrentPage && selectedIds?.length > 0 && pageItemsIdsArray.length > 0;
    const indeterminate = !isAllCheckedOnCurrentPage && pageItemsIdsArray.some(id => selectedIds.includes(id));
    return {
      checked,
      indeterminate,
    };
  }, [ pageItemsIdsArray, selectedIds]);


  const onGlobalCheckboxClick = useCallback((event: any, state: boolean) => {
    if (state) {
      const mergePrevStateWithCurrent = [ ...selectedIds, ...pageItemsIdsArray ];
      const uniqIdsArray = Array.from(new Set(mergePrevStateWithCurrent));
      onGlobalCheckboxClickCallback(uniqIdsArray);
    } else {
      const withoutSelectedIDs = selectedIds.filter(itemId => !pageItemsIdsArray.includes(itemId));
      onGlobalCheckboxClickCallback(withoutSelectedIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pageItemsIdsArray, selectedIds ]);

  return {
    isGlobalCheckboxChecked: globalCheckboxState.checked,
    isGlobalCheckboxIndeterminate: globalCheckboxState.indeterminate,
    onGlobalCheckboxClick,
  };
};

import React, { useCallback, useContext } from 'react';

import { useToggle } from '@customHooks';

import { Calendar } from 'react-feather';

import { InputAdornment } from '@material-ui/core';

import { DatePickerContext } from '@components/CustomDatePicker/components/DatePickerMain/DatePickerMain';

import { CalendarIconWrapper, StyledFormControl, StyledFormHelperText, StyledOutlinedInput } from './styles';

import { DatePickerInputPropsType } from '@components/CustomDatePicker/types';

export const DatePickerInput = ({
  disabled,
  inputRef,
  onChange,
  placeholder,
  value,
  InputProps,
}: DatePickerInputPropsType) => {
  const {
    errorMessage,
    isOpen,
    showError,
  } = useContext(DatePickerContext);
  const [ isFocused, toggleFocused ] = useToggle();

  const onBlur = useCallback(() => toggleFocused(false), [toggleFocused]);
  const onFocus = useCallback(() => toggleFocused(true), [toggleFocused]);

  return (
    <StyledFormControl
      $showError={ showError }
      error={ Boolean(errorMessage) }
      focused={ isOpen || isFocused }
    >
      <StyledOutlinedInput
        InputProps={ InputProps }
        disabled={ disabled }
        endAdornment={
          <InputAdornment
            disablePointerEvents={ disabled }
            position={ 'end' }
          >
            <CalendarIconWrapper
              $disabled={ disabled }
              $error={ Boolean(errorMessage) }
              $focused={ isOpen || isFocused }
            >
              <Calendar
                //@ts-ignore
                onClick={ InputProps?.endAdornment?.props?.children?.props?.onClick }
                size={ 16 }
                style={ {
                  cursor: 'pointer',
                  color: 'inherit'
                } }
              />
            </CalendarIconWrapper>
          </InputAdornment>
        }
        inputRef={ inputRef }
        onBlur={ onBlur }
        onChange={ onChange }
        onFocus={ onFocus }
        placeholder={ placeholder }
        value={ value }
      />
      {
        showError &&
        <StyledFormHelperText>
          { errorMessage }
        </StyledFormHelperText>
      }
    </StyledFormControl>
  );
};

import { CSSProperties } from 'react';
import { ColumnsType } from '@components/Table/components/Header/types';

export const ADD_BUTTON_STYLE: CSSProperties = {
  marginLeft: 'auto'
};

export const LINK_NEW_CUSTOM_FIELD = 'Add New Employee/Contact';

export const COLUMNS: ColumnsType = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '31%',
    },
    padding: 'checkbox',
    sortable: true,
  },
  {
    id: 'title',
    label: 'Title',
    style: {
      width: '11%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: 'phone',
    label: 'Phone',
    style: {
      width: '11%'
    },
    separator: true,
  },
  {
    id: 'email',
    label: 'Email',
    style: {
      width: '12%'
    },
    separator: true,
  },
  {
    id: 'businessRelationship',
    label: 'Business Relationship',
    style: {
      width: '190px'
    },
    separator: true,
  },
  {
    id: 'key',
    label: 'Key',
    style: {
      width: '5%'
    },
    separator: true,
  },
  {
    id: 'primary',
    label: 'Primary',
    style: {
      width: '13%'
    },
    separator: true,
  },
];


export const RELATIONSHIP_OPTIONS = [
  {
    value: 'Current Employee',
    label: 'Current Employee'
  },
  {
    value: 'Former Employee',
    label: 'Former Employee'
  },
  {
    value: 'Business Associate',
    label: 'Business Associate'
  },
  {
    value: 'Advisor',
    label: 'Advisor'
  },
  {
    value: 'Referral',
    label: 'Referral'
  },
  {
    value: 'Other',
    label: 'Other'
  },
];

export const PHONE_TYPE_OPTIONS = [
  {
    value: 1,
    label: 'Mobile Phone',
  },
  {
    value: 11,
    label: 'Work Phone',
  },
  {
    value: 12,
    label: 'Home Phone',
  },
  {
    value: 14,
    label: 'Other Phone',
  },
];

export const EMAIL_TYPE_OPTIONS = [
  {
    value: 2,
    label: 'Work Email',
  },
  {
    value: 3,
    label: 'Home Email',
  },
  {
    value: 17,
    label: 'Other Email',
  },
];

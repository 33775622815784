import { EMPLOYEE_RELATIONSHIPS } from '@constants/common';
import { EmployeeItem, EmployeeOwnFields } from '@/shared/types/employee';
import { FIELD_IDS } from '@constants/contactsData';

export const getDefaultState = (dataToEdit?: EmployeeItem): EmployeeOwnFields => {
  if(!dataToEdit){
    return {
      contacts: [],
      email: '',
      emailType: FIELD_IDS.WORK_EMAIL_ID,
      employeeId: '',
      firstName: '',
      id: '',
      keyContact: 'No',
      lastName: '',
      phone: '',
      phoneType: FIELD_IDS.MOBILE_PHONE_ID,
      primaryContact: 'No',
      relationshipType: EMPLOYEE_RELATIONSHIPS.CURRENT_EMPLOYEE,
      title: '',
    };
  }

  return {
    contacts: dataToEdit.contact.contacts,
    email: dataToEdit.email,
    emailType: dataToEdit.emailType || FIELD_IDS.WORK_EMAIL_ID,
    employeeId: dataToEdit.employeeId,
    firstName: '',
    id: dataToEdit.id,
    keyContact: dataToEdit.keyContact,
    lastName: '',
    phone: dataToEdit.phone,
    phoneType: dataToEdit.phoneType || FIELD_IDS.MOBILE_PHONE_ID,
    primaryContact: dataToEdit.primaryContact,
    relationshipType: dataToEdit.relationshipType,
    title: dataToEdit.title,
  };
};

import styled, { css } from 'styled-components';

import { ContentWrapperProps } from './types';

export const OuterWrapper = styled.div`
  width: 200px;
  height: 250px;
  transform: translateZ(0);
`;

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  transform: translateZ(0);
  
  ${({ $preventPointerEvents }) => $preventPointerEvents && css`
    pointer-events: none;
  `}
`;

export const PreloaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  
  & > * {
    flex-grow: 1;
  }
`;

export const Trigger = styled.div`
  margin-top: -30px;
  width: 100%;
  height: 30px;
`;

import styled from 'styled-components';

export const SelectMenuWrapper = styled.div`
  margin-left: 14px;
`;

export const TypeFiltersWrapper = styled.div`
  margin-left: 20px;
`;

export const ToggleWrapper = styled.div`
  margin-left: auto;
  margin-right: 25px;
`;
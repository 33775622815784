import React from 'react';

import { useWatch } from 'react-hook-form';

import { SaveButtonProps } from './types';

import { CustomSaveButton } from './styles';

export const SaveButton = ({
  control,
  noteTextInputName,
  noteTitleInputName,
  onSaveClick,
  title,
}: SaveButtonProps) => {

  const [noteTitleValue, noteTextValue] = useWatch({
    control,
    name: [noteTitleInputName ,noteTextInputName]
  });

  return (
    <CustomSaveButton
      color='primary'
      disabled={ !noteTitleValue && !noteTextValue }
      onClick={ onSaveClick }
      variant={ 'contained' }
    >
      {title}
    </CustomSaveButton>
  );
};

import React from 'react';
import { observer } from 'mobx-react';

import { OutOfSightCommon } from '@components/OutOfSight';
import CoreClassificationSelectOption from '@components/CoreClassificationSelectOption';
import FormBlock from '@components/FormBlock';
import Rank from '@components/Rank';
import TextWithTooltip from '@components/TextWithTooltip';

import { getBirthdate, getContactImage } from './utils';

import { COLORS } from '@constants/colors';
import { CONTACT_TYPES } from '@constants/common';

import {
  ContactImage,
  ContactSpouse,
  SpouseText,
  StyledSpouseImage,
  Wrapper
} from './styles';

import { MainInformationProps } from './types';


export const MainInformation = observer(({
  adviserData,
  contact,
  contactSpouse,
  isBlocked = false,
  isBorderBottom = true,
  isGlobalDisabled = false,
  onRankChange = () => {},
  onSpouseClick,
  renderHeaderTitle,
  size = 'medium'
}: MainInformationProps) => {
  
  return (
    <Wrapper>
      <FormBlock isBorderBottom={ isBorderBottom } paddingBottom={ isBorderBottom ? 20 : 0 }>
        <FormBlock.Header title={ renderHeaderTitle }></FormBlock.Header>
        <FormBlock.RowWrapper>
          <FormBlock.VerticalCenteredWrapper>
            <ContactImage
              src={ getContactImage(contact) }
              size={ size }
            />
            {
              contactSpouse &&
              <ContactSpouse
                onClick={ onSpouseClick }
                size={ size }
              >
                <StyledSpouseImage
                  src={ getContactImage(contactSpouse) }
                  size={ size }
                />
                <TextWithTooltip
                  $fontColor={ COLORS.primaryText }
                  $lineHeight={ '140%' }
                  text={ [contactSpouse.firstName, contactSpouse.lastName].join(' ') }
                />
                <SpouseText>
                  { contactSpouse.relationType }
                </SpouseText>
                {
                  isBlocked && <OutOfSightCommon onClick={ onSpouseClick } />
                }
              </ContactSpouse>
            }
          </FormBlock.VerticalCenteredWrapper>
        </FormBlock.RowWrapper>

        { contact.type !== CONTACT_TYPES.BUSINESS && 
          <>
            <FormBlock.RowWrapper>
              <FormBlock.FormField
                $labelHeight={ 20 }
                $labelWidth={ 140 }
                label={ 'Date of Birth:' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  { 'birthdate' in contact && contact.birthdate ? getBirthdate(contact.birthdate) : '-' }
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>

            <FormBlock.RowWrapper>
              <FormBlock.FormField
                $labelHeight={ 20 }
                $labelWidth={ 140 }
                label={ 'Lead Planner:' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  { adviserData?.secondaryAdviser ?
                    <TextWithTooltip
                      text={
                        [adviserData?.secondaryAdviser.firstName, adviserData?.secondaryAdviser.lastName].join(' ')
                      }
                    /> :
                    '-'
                  }
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
          </>
        }

        { contact.type === CONTACT_TYPES.BUSINESS && 
          <>
            <FormBlock.RowWrapper>
              <FormBlock.FormField
                $labelHeight={ 20 }
                $labelWidth={ 140 }
                label={ 'Company Type:' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  { contact.companyType ? contact.companyType : '-' }
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>

            <FormBlock.RowWrapper>
              <FormBlock.FormField
                $labelHeight={ 20 }
                $labelWidth={ 140 }
                label={ '#Employees:' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  <TextWithTooltip
                    text={ contact.employeeCount ? contact.employeeCount : '-' }
                  />
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
          </>
        }

        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelHeight={ 20 }
            $labelWidth={ 140 }
            label={ 'Classification:' }
          >
            <FormBlock.VerticalCenteredWrapper>
              <CoreClassificationSelectOption
                label={ contact.classification }
                value={ contact.classification }
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>

        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelHeight={ 20 }
            $labelWidth={ 140 }
            label={ 'Rank:' }
          >
            <FormBlock.VerticalCenteredWrapper>
              <Rank
                disabled={ isGlobalDisabled }
                initRank={ contact.rating }
                onRankChange={ onRankChange }
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>

        <FormBlock.RowWrapper>
          <FormBlock.FormField
            $labelHeight={ 20 }
            $labelWidth={ 140 }
            label={ 'Advisor:' }
          >
            <FormBlock.VerticalCenteredWrapper>
              { adviserData?.primaryAdviser ?
                <TextWithTooltip
                  text={ [adviserData?.primaryAdviser.firstName, adviserData?.primaryAdviser.lastName].join(' ') }
                /> :
                '-'
              }
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </FormBlock.RowWrapper>

      </FormBlock>
    </Wrapper>
  );
});

/* eslint-disable */
import { makeStyles, SvgIcon } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
    root: {
        height: '1.5rem',
        width: '1.5rem',
        stroke: 'currentColor',
        strokeWidth: '0.5'
    }
})

export default function CustomIcon({ children, ...other }) {

    const classes = useStyles();

    return (
        <SvgIcon
            classes={classes}
            viewBox='0 0 24 24'
            {...other}
        >
            {children}
        </SvgIcon>
    )
}

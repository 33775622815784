import ApiService from '@services/api/api.service';
// TODO migrate to typescipt

export const getAccountAndPlans = (params) => {
  return ApiService.get('/api/accountPlans/getGrid', { params });
};

export const updateFlags = (data) => {
  return ApiService.patch('api/accountPlans/updateFlags', data);
};

export const deleteAccountAndPlans = (params) => {
  return ApiService.delete('api/accountPlans/delete', { params });
};

export const getAccountAndPlansPopupSettings = (params) => {
  return ApiService.get('api/setting/accountPlans/getPopupSetting', { params });
};

export const saveAccountOrPoliceOrPlan = (data) => {
  return ApiService.post('api/accountPlans/create', data);
};

export const getByIdAccountAndPlans = (params) => {
  return ApiService.get('api/accountPlans/getById', { params });
};

export const updateAccountAndPlans = (data) => {
  return ApiService.patch('api/accountPlans/update', data);
};
import { action, makeAutoObservable, flow } from 'mobx';

import { Store } from '../store';

import { LinkedContact } from '@/shared/types/linkedContact';

class AppHeaderStore {
  contact: LinkedContact | null = null;
  coreStore: Store;
  isLoad: boolean = false;
  recentContactsLists: Array<LinkedContact> | null = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getRecentContacts: flow.bound,
      setContact: action.bound
    });
    this.coreStore = coreStore;
  }

  // TODO
  *getRecentContacts() {
    try {
      this.setIsLoad(true);
      yield;
      this.recentContactsLists = [];
    } catch(error) {
      console.log(error);
    } finally {
      this.setIsLoad(false);
    }
  }

  setContact(contact: LinkedContact | null) {
    this.contact = contact;
  }

  setIsLoad(state: boolean) {
    this.isLoad = state;
  }
}

export default AppHeaderStore;

import React, { ElementType, useCallback } from 'react';

import { ViewSwitcherProps, ViewSwitcherItemProps } from './types';

import { KANBAN_TABEL_CONFIG_VIEW } from './data';

import { ViewSwitcherWrapper, ViewSwitcherItemWrapper } from './styles';

const ViewSwitcherItem = ({
  Icon,
  onChange,
  value,
  $isActive,
}: ViewSwitcherItemProps) => {
  const IconToRender = Icon as ElementType;

  const onClick = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <ViewSwitcherItemWrapper
      $isActive={ $isActive }
      onClick={ onClick }
    >
      <IconToRender/>
    </ViewSwitcherItemWrapper>
  );
};

export const ViewSwitcher = ({
  config = KANBAN_TABEL_CONFIG_VIEW,
  onChange,
  currentValue
}: ViewSwitcherProps) => {

  return (
    <ViewSwitcherWrapper>
      {
        config?.map(item => (
          <ViewSwitcherItem
            $isActive={ currentValue === item.value }
            Icon={ item.Icon }
            key={ item.value }
            onChange={ onChange }
            value={ item.value }
          />
        ))
      }
    </ViewSwitcherWrapper>);
};

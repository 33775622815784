import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';
import { BOX_SHADOW, ERROR_CONTAINER_STYLES, ERROR_SPACE_VALUE } from '@constants/style';

import { ButtonsWrapperProps, ItemsWrapperProps, ItemWrapperProps } from './types';

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: ${ERROR_SPACE_VALUE}px;
`;

export const ErrorWrapper = styled.div`
  ${ERROR_CONTAINER_STYLES}
`;

export const ItemsWrapper = styled.div<ItemsWrapperProps>`
  height: 230px;
  overflow-y: auto;
  border: 1px solid ${({ $isError }) => $isError ? COLORS.red : COLORS.borders};
  border-radius: 6px;
  
  ${({ $isScrollBlocked }) => $isScrollBlocked && css`
    overflow-y: hidden;
  `}
`;


export const NotEditButtonsWrapper = styled.div<ButtonsWrapperProps>`
  display: grid;
  grid-template-columns: auto auto;

  ${({ $withGap }) => $withGap && css`
    grid-column-gap: 15px;
  `}
  
  ${({ $isShowOnParentHover }) => $isShowOnParentHover && css`
    visibility: hidden;
  `}

  margin-left: auto;
`;


export const ItemWrapper = styled.div<ItemWrapperProps>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  
  ${({ $isDisabled }) => $isDisabled && css`
    pointer-events: none;
    opacity: 0.6;
  `}
  
  &:hover {
    box-shadow: ${ BOX_SHADOW };
    
    & ${NotEditButtonsWrapper} {
      visibility: visible;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 200px;
`;








import React, { useCallback, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import ImageUploader, { ImageFile } from '@components/ImageUploader';

import { ImageUploaderCategories, ImageUploaderTypes } from '@constants/common';

import { HFImageUploaderProps } from './types';

export const HFImageUploader = ({
  control,
  headshotBackgroundType = ImageUploaderCategories.User,
  imageType = ImageUploaderTypes.Headshot,
  isAvatar,
  name,
  title = '',
}: HFImageUploaderProps) => {

  const { formState: { defaultValues }, trigger } = useFormContext();

  const {
    field: {
      value,
      onChange,
    },
    fieldState: { error, }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  const [ image, setImage ] = useState<string | ArrayBuffer>('');

  const successCallback = useCallback((croppedImg: string | ArrayBuffer, file: ImageFile) => {
    setImage(croppedImg);
    onChange(file);
    trigger(name);
  }, [ name, onChange, trigger ]);

  const onInvalidFile = useCallback((file: ImageFile) => {
    setImage('');
    onChange(file);
    trigger(name);
  }, [name, onChange, trigger]);

  useEffect(() => {
    if(defaultValues && value === get(defaultValues, name)) {
      setImage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value ]);

  return (
    <ImageUploader
      error={ error }
      headshotBackgroundType={ headshotBackgroundType }
      imageType={ imageType }
      img={ image || value }
      isAvatar={ isAvatar }
      onInValidFile={ onInvalidFile }
      successCallback={ successCallback }
      title={ title }
    />
  );
};

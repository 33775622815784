import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  LeftColumnWrapper,
  RightColumnWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { BusinessContactDetailsProps } from './types';
import {
  BUSINESS_FIELDS
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

export const BusinessContactDetails = ({
  employeeOptions,
  companyOptions,
}: BusinessContactDetailsProps) => {
  const { control } = useFormContext();

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 5 }
    >
      <FormBlock.Header title={ 'Contact Details' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            isRequired={ true }
            label={ 'Business Name' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Name' ,
                maxLength: 50,
              } }
              name={ BUSINESS_FIELDS.occupationCompany }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ '#Employees' }
          >
            <HFSelect
              control={ control }
              name={ BUSINESS_FIELDS.employeeCount }
              options={ employeeOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Website' }
          >
            <HFTextInput
              control={ control }
              name={ BUSINESS_FIELDS.website }
              placeholder={ 'Enter Website Name' }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Company Type' }
          >
            <HFSelect
              control={ control }
              name={ BUSINESS_FIELDS.companyType }
              options={ companyOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Date Founded' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              name={ BUSINESS_FIELDS.birthdate }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_COLUMN_WIDTH }
            label={ 'Annual Revenue' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Annual Revenue' ,
                maxLength: 50,
              } }
              name={ BUSINESS_FIELDS.annualRevenue }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_COLUMN_WIDTH }
            label={ 'Tax ID Number' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Tax ID Number' ,
                maxLength: 50,
              } }
              name={ BUSINESS_FIELDS.taxIdNumber }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 0;
  
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${COLORS.dividingLines};
  }
`;

export const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const HeaderIconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  
  & > svg {
    width: 22px;
    height: 22px;

    & > path {
      fill: ${COLORS.grey}
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  visibility: hidden;
  margin-left: 10px;
`;

export const TitleAndButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  
  &:hover ${Buttons} {
      visibility: visible;
  }
`;

export const Tags = styled.div`
  margin-left: auto;
`;

export const NoteContent = styled.div`
  position: relative;
  padding-left: 32px;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 20px;
    width: 2px;
    background-color: ${COLORS.purpleSecond};
    border-radius: 2px;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const ActionButtonsWrapper = styled.div`
  margin-right: 10px;
  
    &:last-child{
      margin-right: 0;
    }
`;

export const NoteType = styled.div`
  margin-left: 10px;
  padding: 3px 12px;
  border-radius: 12px;
  background: ${COLORS.lightBlue};
  color: ${COLORS.blue};
  font-size: 12px;
  line-height: 150%;
`;

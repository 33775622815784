import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Table, { useGlobalCheckbox, useRowCheckbox } from '@components/Table';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import { ListsTableRow } from './components';

import { getFolderLink, getListItemLink, getPrevParentLink } from '@/shared/utils/listsRoutesUtils';

import { COLUMNS } from './data';
import { LISTS_TYPES } from '@constants/lists';

import { ListsGridItemType } from '@/shared/types/lists';
import { ListsTableProps } from './types';


export const ListsTable = observer(({
  isFolderView,
  onAddContacts,
  onClone,
  onDropList,
  onExport,
  onFolderDelete,
  onFolderRename,
  onListAdd,
  onMove,
  onPageChange,
  onRemove,
}: ListsTableProps) => {
  const routerStore = useStore().RouterStore;

  const store = isFolderView
    ? useStore().ListsStore.FolderStore
    : useStore().ListsStore.AllListsStore;

  const {
    isAllListsLoad,
    parent,
    table,
    isPageActive,
  } = store;

  const {
    isGlobalCheckboxChecked,
    isGlobalCheckboxIndeterminate,
    onGlobalCheckboxClick
  } = useGlobalCheckbox({
    pageItems: table.items,
    selectedIds: table.selectedIDs,
    onGlobalCheckboxClickCallback: table.setSelectedIds
  });

  const {
    getCheckboxState,
    onRowCheckboxClick
  } = useRowCheckbox({
    onGlobalCheckboxClickCallback: table.setSelectedIds,
    selectedIds: table.selectedIDs,
  });

  const onRowBookmark = useCallback((id: number, state: boolean) => {
    store.updateFlags([{ id }], state);
  },[store]);

  const moveToFolder = useCallback((id: number) => {
    routerStore.customPush(getFolderLink(id.toString()));
  }, [routerStore]);

  const moveToPrevParent = useCallback(() => {
    routerStore.customPush(getPrevParentLink(parent?.parentId));
  }, [parent, routerStore]);

  const onViewContacts = useCallback((item: ListsGridItemType) => {
    if(item.typeList === LISTS_TYPES.Dynamic || item.typeList === LISTS_TYPES.Manual){
      routerStore.customPush(getListItemLink(item.typeList, String(item.id))!.contacts);
    }
  }, [routerStore]);

  const onEditSetup = useCallback((item: ListsGridItemType) => {
    if(item.typeList === LISTS_TYPES.Dynamic || item.typeList === LISTS_TYPES.Manual){
      routerStore.customPush(getListItemLink(item.typeList, String(item.id))!.setup);
    }
  }, [routerStore]);

  return (
    <DndProvider backend={ HTML5Backend }>
      <Table>
        <Table.Container triggerScrollToTop={ table.currentPage }>
          <Table.Header
            columns={ COLUMNS }
            globalCheckboxProps={ {
              checked: isGlobalCheckboxChecked,
              indeterminate: isGlobalCheckboxIndeterminate,
              onChange: onGlobalCheckboxClick
            } }
            onSortChangeCb={ store.table.setMultipleSorting }
            sortingState={ table.multipleSorting }
            withBookmarkPlaceholder
            withGlobalCheckbox={ true }
          />
          <Table.Body>
            {
              isFolderView  &&
              <ListsTableRow
                isLink={ true }
                name={ parent?.name || '' }
                onLinkClick={ moveToPrevParent }
              />
            }
            {
              table.items.map(item => {
                if(item.typeList === LISTS_TYPES.Folder){
                  return (
                    <ListsTableRow
                      isFolder={ true }
                      item={ item }
                      key={ item.id }
                      moveToFolder={ moveToFolder }
                      onDelete={ onFolderDelete }
                      onDropList={ onDropList }
                      onRename={ onFolderRename }
                    />
                  );
                } else {
                  return (
                    <ListsTableRow
                      isCheckboxActive={ getCheckboxState(item) }
                      isItem={ true }
                      item={ item }
                      key={ item.id }
                      onAddContacts={ onAddContacts }
                      onCheckboxClickCb={ onRowCheckboxClick }
                      onClone={ onClone }
                      onEditSetup={ onEditSetup }
                      onExport={ onExport }
                      onMove={ onMove }
                      onRemove={ onRemove }
                      onRowBookmarkCb={ onRowBookmark }
                      onViewContacts={ onViewContacts }
                    />
                  );
                }
              })
            }
          </Table.Body>
          {
            isPageActive && !isAllListsLoad && table.items.length === 0 &&
            <ContentCenter>
              <NoDataItemsView
                addActionButtonClickHandler={ onListAdd }
                addActionButtonLabel={ 'Add New List' }
                withAction={ true }
              />
            </ContentCenter>
          }
        </Table.Container>
        <Table.Footer
          currentPage={ table.currentPage }
          onPageChangeCb={ onPageChange }
          pagesCount={ table.totalPages }
        >
          <Table.FooterCountInfo
            from={ table.itemsFrom }
            to={ table.itemsTo }
            total={ table.totalItems }
          />
        </Table.Footer>
      </Table>
    </DndProvider>
  );
});

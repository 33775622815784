import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { getSortedUserOption } from '@/shared/utils/filterUtils';
import {
  TO_DOS_LINKED_ITEMS_TYPES_SCHEME,
  TO_DOS_FIELDS_NAMES,
  TODOS_FILTER_NAMES,
  YES_NO_TO_BOOLEAN_REQUIRRING_FIELD,
} from '@constants/todosData';
import { LINKED_ITEM_DATA_NAMES } from '@constants/linkedItem';

const NUMBER_CONVERT_TO_BOOLEAN = ['flagged'];

const filterDataConverter = (filterData, currentUserId) => {
  if(!filterData) {
    return {};
  }

  return {
    [TODOS_FILTER_NAMES.classification]: optionsFromValue(filterData.classification),
    [TODOS_FILTER_NAMES.priority]: optionsFromValue(filterData.priority),
    [TODOS_FILTER_NAMES.stage]: filterData.stage,
    [TODOS_FILTER_NAMES.users]: getSortedUserOption(filterData.clients, currentUserId)
  };
};

const todoConverter = todo => Object.entries(todo).reduce((acc, [name, value]) => {
  if(YES_NO_TO_BOOLEAN_REQUIRRING_FIELD.includes(name)){
    acc[name] = yesNoToBoolean(value);
    return acc;
  }

  if(NUMBER_CONVERT_TO_BOOLEAN.includes(name)){
    acc[name] = Boolean(value);
    return acc;
  }

  const isLinkedContact = Object.values(TO_DOS_LINKED_ITEMS_TYPES_SCHEME).some((linkedItemName) => {
    return linkedItemName.dataName === name;
  });

  if(name === TO_DOS_FIELDS_NAMES.isNew){
    acc[name] = Number(value);
    return acc;
  }

  if(isLinkedContact && value) {
    const data = name === LINKED_ITEM_DATA_NAMES.CONTACT_DATA ? value : value.contactData;
    acc[TO_DOS_FIELDS_NAMES.linkedItemData] = {
      ...data,
      linkedContactType: TO_DOS_LINKED_ITEMS_TYPES_SCHEME[name].type
    };
    return acc;
  }
  acc[name] = value;
  return acc;
}, {});

const todosConverter = todos => {
  if (!todos) {
    return [];
  }

  return Object.entries(todos).reduce((acc, [key, item]) => {
    if (isNaN(key)) {
      return acc;
    }
    const convertedContactItem = todoConverter(item);

    acc.push(convertedContactItem);
    return acc;
  }, []);
};

export const todosNormalizer = (resp, currentUserId) => {
  return {
    filtersData: filterDataConverter(resp.data.filterData, currentUserId),
    todos: todosConverter(resp.data),
  };
};

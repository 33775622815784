import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import omit from 'lodash/omit';

import { NotesUtilsStore } from '@modules/NotesAndHistory';

import { LinkedContact } from '@/shared/types/linkedContact';
import { UseFormMethods, InitProps } from './types';

import { NoteTagItem } from '@/shared/types/tags';
import {
  CloseModal,
  GetNextAppointmentDateResponse,
  TagsBackendResponse,
  ValueLabelObj
} from '@/shared/types/commonTypes';
import {
  ClientOpportunityGetByIdResponse,
  ClientOpportunityPopupSettings,
  ClientOpportunityQuickCompleteStatuses,
  OnRemoveClientOpportunities,
  OnSaveCallback,
  SavedClientOpportunityItem
} from '@/shared/types/salesCycleOpportunity';
import { BackendTodoFormFields, DeleteTodoParams, GridResponse, TodoItem } from '@/shared/types/todos';

import {
  getClientOpportunityItem,
  getClientOpportunityNextApptDate,
  getClientOpportunityPopupSettings
} from '@services/api/salesPipeline/clientOpportunities';
import { deleteTodo, saveTodo, updateTodo } from '@services/api/addAndEditTask/addAndEditTask';
import { getGridForContact } from '@services/api/todos/todos';
import { getTags } from '@services/api/tags/tags';

import CommonTableStore from '@services/store/commonTableStore';

import { getFilterParams, getSortParams } from '@/shared/utils/filterUtils';
import { sourceConverter } from '@/shared/utils/getOptionsUtils';
import { todoItemNormalizer } from '@/shared/utils/toDosNormalizers';
import { todoItemsResponseNormalizer } from '@/shared/utils/todoItemsResponseNormalizer';

import { LINKED_TASK_FILTERS_NAME } from './data';
import { OPPORTUNITY_FIELD_NAMES } from '@constants/salesCycleOpportunity';
import { SortDirectionNames } from '@constants/common';


class SalesActivePopupStore {
  closeTrigger: CloseModal | null = null;
  isHideModal: boolean = false;
  isLoad: boolean = true;
  itemId: SavedClientOpportunityItem['id'] | null = null;
  linkedContactData: LinkedContact | null  = null;
  linkedTasks: Array<TodoItem> | null = null;
  nextAppointmentDate: string | null = null;
  notesUtilsStore: NotesUtilsStore;
  onDeleteCallback?: OnRemoveClientOpportunities | null = null;
  onSaveCallback: OnSaveCallback | null = null;
  predefinedTags: Array<NoteTagItem> = [];
  quickStatusSubmit: ClientOpportunityQuickCompleteStatuses | null = null;
  sourceOptions: Array<ValueLabelObj> = [];
  table: CommonTableStore<TodoItem>;
  todoTableActivePage: number = 1;
  useFormMethods: UseFormMethods = {} as UseFormMethods;

  onLinkedContactDataChangeReaction: IReactionDisposer;
  onPageChangeReaction: IReactionDisposer;

  constructor() {
    makeAutoObservable(this, {
      getLinkedTasks: flow.bound,
      init: flow.bound,
      onCancel: action.bound,
      onConfirm: action.bound,
      onDelete: action.bound,
      removeTodo: flow.bound,
      resetState: action.bound,
      saveTodo: flow.bound,
      setIsHideModal: action.bound,
      setTodoTableActivePage: action.bound,
    });

    this.notesUtilsStore = new NotesUtilsStore();
    this.table = new CommonTableStore<TodoItem>();

    this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
    this.onPageChangeReaction = this.createOnPageChangeReaction();
  }

  *init({
    closeTrigger,
    id,
    onDeleteCallback,
    onSaveCallback,
    useFormMethods,
  }: InitProps){
    try {
      this.isLoad = true;
      this.useFormMethods = useFormMethods;
      this.onSaveCallback = onSaveCallback;
      this.onDeleteCallback = onDeleteCallback;
      this.closeTrigger = closeTrigger;

      const tagsResp: TagsBackendResponse = yield getTags({
        type: 'Sales Pipe Line Note',
        searchPhrase: ''
      });
      this.predefinedTags = tagsResp.data.data;

      const settings: ClientOpportunityPopupSettings = yield getClientOpportunityPopupSettings();
      this.sourceOptions = sourceConverter(settings.data.data.source);


      if(id) {
        this.itemId = id;
        this.onLinkedContactDataChangeReaction();

        const itemResponse: ClientOpportunityGetByIdResponse = yield getClientOpportunityItem({ id });
        this.linkedContactData = itemResponse.data.data.contactData;

        yield this.getLinkedTasks();

        this.notesUtilsStore.setCurrentLinkedContact(itemResponse.data.data.contactData);

        this.nextAppointmentDate = itemResponse.data.data.nextAppointmentDate || null;

        this.useFormMethods.reset(
          omit(itemResponse.data.data, ['contactData', 'nextAppointmentDate'])
        );
        this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *onLinkedContactDataChange() {
    if(this.linkedContactData){
      try {
        this.isLoad = true;
        const nextAppointmentDateResponse: GetNextAppointmentDateResponse = yield getClientOpportunityNextApptDate({
          id: this.linkedContactData.id
        });

        const nextAppointmentDate = nextAppointmentDateResponse.data.data.nextAppointmentDate;
        this.nextAppointmentDate = nextAppointmentDate;

        if(this.nextAppointmentDate){
          this.useFormMethods.setValue(OPPORTUNITY_FIELD_NAMES.meetingStatus, null);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }
    }else {
      this.nextAppointmentDate = null;
    }
  }

  *getLinkedTasks() {
    this.table.items = [];
    this.todoTableActivePage = 1;

    if(this.linkedContactData) {
      this.isLoad = true;
      try {
        yield this.getLinkedTasksWithoutLoading();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoad = false;
      }
    }
  }

  *getLinkedTasksWithoutLoading() {
    const response: GridResponse = yield getGridForContact({
      ...getFilterParams({ [LINKED_TASK_FILTERS_NAME.opportunityId]: this.itemId }),
      ...getSortParams(LINKED_TASK_FILTERS_NAME.createdDate, SortDirectionNames.Desc),
      page: this.todoTableActivePage,
    });
    
    const normalized = todoItemsResponseNormalizer(response.data.data.data);
    this.table.items = this.table.items.concat(normalized.items.map((item: TodoItem) => todoItemNormalizer(item)));
    this.table.setPaginationData(response.data.data);
  }

  *saveTodo(data: BackendTodoFormFields) {
    this.isLoad = true;
    try {
      if(data.id) {
        yield updateTodo(data);
      } else {
        yield saveTodo(data);
      }
      yield this.getLinkedTasks();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  *removeTodo(params: DeleteTodoParams) {
    this.isLoad = true;
    try {
      yield deleteTodo(params);
      yield this.getLinkedTasks();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoad = false;
    }
  }

  createOnPageChangeReaction() {
    return reaction(
      () => this.todoTableActivePage,
      () => {
        if(this.todoTableActivePage !== 1 && this.linkedContactData) {
          this.getLinkedTasksWithoutLoading();
        }
      },
    );
  }

  setTodoTableActivePage() {
    if(this.todoTableActivePage < this.table.totalPages) {
      this.todoTableActivePage = this.todoTableActivePage + 1;
    }
  }

  setIsHideModal(state: boolean) {
    this.isHideModal = state;
  }

  createOnLinkedContactDataReaction() {
    return reaction(
      () => this.linkedContactData,
      () => this.onLinkedContactDataChange()
    );
  }

  onLinkedContactChange = (newLinkedContact: LinkedContact | null) => {
    this.notesUtilsStore.setPrevLinkedContact(this.linkedContactData);
    this.notesUtilsStore.setCurrentLinkedContact(newLinkedContact);

    this.linkedContactData = newLinkedContact;
  };

  onConfirm(status?: ClientOpportunityQuickCompleteStatuses) {
    this.useFormMethods.handleSubmit((data) => {
      if(typeof status ==='string'){
        this.quickStatusSubmit = status;
      }

      let calculatedData =  {
        ...data,
        [OPPORTUNITY_FIELD_NAMES.opportunityStatus]: this.quickStatusSubmit || data.opportunityStatus
      };

      if(this.notesUtilsStore.alertType) {
        calculatedData = {
          ...calculatedData,
          [OPPORTUNITY_FIELD_NAMES.notes]: this.notesUtilsStore.convertNotesByAlertType(
            calculatedData.notes,
            this.linkedContactData
          )
        };
      } else {
        if(this.notesUtilsStore.detectAndSetAlert(calculatedData.notes, this.linkedContactData)){
          return;
        }
      }

      if(this.onSaveCallback && this.closeTrigger){
        this.onSaveCallback(this.closeTrigger, calculatedData);
        this.quickStatusSubmit = null;
      }
    })();
  }

  onCancel() {
    if(this.notesUtilsStore.alertType){
      this.notesUtilsStore.resetAlertType();
      this.quickStatusSubmit = null;
    } else {
      this.closeTrigger && this.closeTrigger();
    }
  }

  onDelete() {
    if(this.itemId && this.onDeleteCallback) {
      this.closeTrigger && this.closeTrigger();
      this.onDeleteCallback(this.itemId);
    }
  }

  resetState() {
    this.closeTrigger = null;
    this.isHideModal = false;
    this.isLoad = true;
    this.itemId = null;
    this.nextAppointmentDate = null;
    this.notesUtilsStore.resetNotesState();
    this.onDeleteCallback = null;
    this.onSaveCallback = null;
    this.predefinedTags = [];
    this.sourceOptions  = [];
    this.table.resetTable();
    this.useFormMethods = {} as UseFormMethods;


    this.onLinkedContactDataChangeReaction();
    this.linkedContactData = null;
    this.onLinkedContactDataChangeReaction =  this.createOnLinkedContactDataReaction();
  }
}

export default SalesActivePopupStore;

import React, { useCallback, useEffect } from 'react';

import { useStore } from '@services/store/store';

import { MODAL_TYPE } from '@constants/modalTypes';

import Surface from '@components/BasicLayoutComponents/Surface';
/* eslint-disable */
import {
  AccountsAndPlansTable
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsAccountsAndPlans/components';
/* eslint-enable */
import { AccountsAndPlansPopup } from '@modules/AccountsAndPlansPopup';
import { DeleteAccountsAndPlansPopup } from '@modules/DeleteAccountsAndPlansPopup';

import { ContactDetailsAccountsAndPlansProps } from './types';
import { IdType } from '@/shared/types/commonTypes';

export const ContactDetailsAccountsAndPlans = ({
  contactId,
}: ContactDetailsAccountsAndPlansProps) => {

  const contactDetailsAccountAndPlanStore = useStore().ContactDetailsAccountAndPlanStore;
  const { table } = contactDetailsAccountAndPlanStore;
  const modalStore = useStore().ModalStore;
  const { currentContact } = useStore().ContactDetailsStore;

  const openAccountsAndPlanPopup = useCallback((id?: IdType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ACCOUNTS_AND_PLANS_MODAL,
      component: AccountsAndPlansPopup,
      modalProps: {
        outerOnSubmit: contactDetailsAccountAndPlanStore.saveAccountsAndPlans.bind(contactDetailsAccountAndPlanStore),
        id,
        linkedContact: currentContact,
      },
    });
  }, [ currentContact, contactDetailsAccountAndPlanStore, modalStore ]);

  const onAddClick = useCallback(() => openAccountsAndPlanPopup(), [openAccountsAndPlanPopup]);
  const onEdit = useCallback((id: IdType) => openAccountsAndPlanPopup(id), [openAccountsAndPlanPopup]);

  const deleteTodoHandler = useCallback((arrayOfIds: Array<IdType>) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_ACCOUNT_AND_PLANS_CONFIRMATION,
      modalProps: {
        arrayOfIds,
        deleteAccountsAndPlans:
        contactDetailsAccountAndPlanStore.deleteAccountsAndPlans.bind(contactDetailsAccountAndPlanStore)
      },
      component: DeleteAccountsAndPlansPopup
    });
  }, [contactDetailsAccountAndPlanStore, modalStore, table.selectedIDs]);

  useEffect(() => {
    contactDetailsAccountAndPlanStore.init(contactId);
    return () => {
      contactDetailsAccountAndPlanStore.reset();
    };
  }, [ contactDetailsAccountAndPlanStore, contactId ]);

  return (
    <Surface>
      <Surface.Content>
        <Surface.Main>
          <AccountsAndPlansTable
            onAddAccountOrPlan={ onAddClick }
            onDeleteAccountAndPlan={ deleteTodoHandler }
            onEditAccountOrPlan={ onEdit }
          />
        </Surface.Main>
      </Surface.Content>
    </Surface>
  );
};

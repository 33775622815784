import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStore } from '@store';

import { OutOfSightTD } from '@components/OutOfSight';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import LinkedContactCell from '@/modules/LinkedContactCell';
import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';
import SalesCycleDotsMenu from '@modules/SalesPipline/SalesCycleDotsMenu';
import SalesPipelineNextApptDateCellContent from '@modules/SalesPipline/SalesPipelineNextApptDateCellContent';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { MeetingStatusWrapper, StatusWrapper } from './styles';

import { COLORS } from '@constants/colors';
import { MODAL_TYPE } from '@constants/modalTypes';

import {
  SALES_ACTIVE_CYCLE_FIELD_NAMES,
  SALES_ACTIVE_CYCLE_MEETING_STATUS_OPTIONS,
  SALES_ACTIVE_CYCLE_STATUS_OPTIONS,
} from '@constants/salesActiveCycle';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { SalesActiveTableRowProps } from './types';
import { SalesCycleRowFormState } from '@/shared/types/salesActiveCycle';


export const SalesActiveTableRow = memo(({
  isChecked,
  item,
  onBookmark,
  onChange,
  onCheckboxClick,
  onDelete,
  onDoubleClick,
  onEdit,
}: SalesActiveTableRowProps) => {
  const modalStore = useStore().ModalStore;
  const {
    control,
    reset,
    formState,
    getValues
  } = useForm<SalesCycleRowFormState>({
    defaultValues: {
      [SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus]: item.salesCycleStatus,
      [SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus]: item.meetingStatus
    }
  });
  const allFields = useWatch({
    control
  });

  const amountConverted = useMemo(() => formatNumberToAmount(item?.amount), [item?.amount]);

  const onRowDoubleClick = useCallback(() => {
    onDoubleClick(item.id);
  }, [item.id, onDoubleClick]);

  const onCheckboxClickWrapper = useCallback(() => {
    onCheckboxClick(item);
  }, [item, onCheckboxClick]);

  const onItemBookmarkWrapper = useCallback(() => {
    onBookmark(item.id);
  }, [item.id, onBookmark]);

  const isBlocked = typeof item?.contactData?.editAllow === 'number' && !item?.contactData?.editAllow;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  useEffect(() => {
    if(Object.keys(formState.dirtyFields).length > 0){
      const { meetingStatus: meetingStatusValue, salesCycleStatus } = getValues();
      onChange(
        {
          id: item.id,
          meetingStatus: meetingStatusValue || null,
          salesCycleStatus,
        },
        reset
      );
    }
  },[formState.dirtyFields, allFields, getValues, onChange, item, reset]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      onDoubleClick={ onRowDoubleClick }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: Boolean(item.flagged),
          onStateChange: onItemBookmarkWrapper
        } }
        checkboxProps={ {
          checked: isChecked,
          onChange: onCheckboxClickWrapper
        } }
      />
      <LinkedContactCell
        $color={ COLORS.primaryText }
        alternativeClickHandler={ onRowDoubleClick }
        contact={ item.contactData }
      />
      <Table.Cell>
        <StatusWrapper>
          <HFSelect
            control={ control }
            name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus }
            options={ SALES_ACTIVE_CYCLE_STATUS_OPTIONS }
            renderItem={ SaleCycleStatusOption }
            showError={ false }
          />
        </StatusWrapper>
      </Table.Cell>
      <Table.Cell>
        { item.stage }
      </Table.Cell>
      <Table.Cell>
        {
          item.nextAppointmentDate &&
          <SalesPipelineNextApptDateCellContent date={ item?.nextAppointmentDate }/>
        }
        {
          !item.nextAppointmentDate &&
          <MeetingStatusWrapper>
            <HFSelect
              control={ control }
              name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus }
              options={ SALES_ACTIVE_CYCLE_MEETING_STATUS_OPTIONS }
              resetValue={ '' }
              resettable={ true }
              showError={ false }
            />
          </MeetingStatusWrapper>
        }
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ amountConverted }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip
          $paddingRight={ '20px' }
          text={ item.keyNote || '-' }
        />
        <SalesCycleDotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          id={ item.id }
          onDelete={ onDelete }
          onEdit={ onEdit }
        />
      </Table.Cell>
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});

SalesActiveTableRow.displayName = 'SalesActiveTableRow';

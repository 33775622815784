import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const ToDosWrapper = styled.div`
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  & > div:first-child {
    padding: 20px;
  }
`;

export const TextChunk = styled.span`
  color: ${COLORS.helpText};
`;

export const MarkedTextChunk = styled.span`
  font-weight: 300;
  color: ${COLORS.grey};
`;

export const Content = styled.div`
  width: 100%;
  height: 164px;
  padding: 70px;
  position: absolute;
  top: 100px;
  left: 0;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: 10px;
`;

import React, { ElementType, MouseEventHandler, useCallback, useState } from 'react';

import BlockButton from '@components/BlockButton';
import Triangle, { TRIANGLE_TYPE } from '@components/Triangle';
import { PlusWithCircle } from '@/assets';

import { COLORS } from '@constants/colors';

import { AddFieldProps } from './types';

import { 
  GroupHeader,
  GroupIcon,
  GroupItem,
  GroupName,
  GroupWrapper,
  GroupsWrapper,
  StyledPopover,
} from './styles';

export const AddField = ({
  config,
  onOptionClick
}: AddFieldProps) => {
  const [ anchor, setAnchor ] = useState<HTMLButtonElement |null>(null);

  const onAddClick:MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    setAnchor(event.currentTarget);
  },[]);
  
  const resetAnchor = useCallback(() => {
    setAnchor(null);
  },[]);

  const onOptionClickWrapper = useCallback((value: number) => {
    resetAnchor();
    onOptionClick(value);
  },[onOptionClick, resetAnchor]);
  
  const triangleType = anchor
    ? TRIANGLE_TYPE.TOP
    : TRIANGLE_TYPE.BOTTOM;

  return (
    <>
      <BlockButton
        $iconSize={ '16px' }
        endIcon={
          <Triangle
            $color={ COLORS.orange }
            $type={ triangleType }
          />
        }
        onClick={ onAddClick }
        startIcon={ <PlusWithCircle/> }
      >
        Add Contact Details
      </BlockButton>
      <StyledPopover 
        anchorEl={ anchor }
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'right',
        } }
        onClose={ resetAnchor }
        open={ Boolean(anchor) }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }
      >
        <GroupsWrapper>
          {
            config.map(group => {
              const Icon = group.icon as ElementType;
              const groupName = group.groupName;
              return(
                <GroupWrapper key={ group.groupName }>
                  <GroupHeader>
                    <GroupIcon>
                      <Icon />
                    </GroupIcon>
                    <GroupName>
                      {group.groupName}
                    </GroupName>
                  </GroupHeader>
                  {
                    group.options.map((option) => {
                      const optionValue = 'value' in option
                        ? option.value
                        : option.fieldId;
                      const clickHandler = () => onOptionClickWrapper(optionValue);
                      return (
                        <GroupItem
                          key={ optionValue }
                          onClick={ clickHandler }
                        >
                          {
                            'label' in option
                              ?  option.label
                              :  option.fieldName
                          }
                        </GroupItem>
                      );
                    })
                  }
                </GroupWrapper>
              );
            })
          }
        </GroupsWrapper>
      </StyledPopover>
    </>
  );
};

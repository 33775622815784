import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { AccountAndPlanItemShort } from '@/shared/types/accountsAndPlan';
import { ACCOUNTS_AND_PLANS_TYPES } from '@constants/accountsAndPlanData';

export const getBalanceValue = (
  accountType: ACCOUNTS_AND_PLANS_TYPES,
  balance: AccountAndPlanItemShort['balance']
) => {
  if(accountType === ACCOUNTS_AND_PLANS_TYPES.PLAN){
    return '-';
  }

  return formatNumberToAmount(balance);
};

import styled from 'styled-components';

export const LeftColumnWrapper = styled.div`
  width: 50%;
  padding-right: 26px;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  width: 50%;
  padding-left: 48px;
  box-sizing: border-box;
`;


export const LeftColumnWrapperVariantTwo = styled.div`
  width: 50%;
  padding-right: 14px;
  box-sizing: border-box;
`;

export const RightColumnWrapperVariantTwo = styled.div`
  width: 50%;
  padding-left: 61px;
  box-sizing: border-box;
`;

export const LeftColumnWrapperVariantThree = styled.div`
  width: 50%;
  padding-right: 23px;
  box-sizing: border-box;
`;

export const RightColumnWrapperVariantThree = styled.div`
  width: 50%;
  padding-left: 24px;
  box-sizing: border-box;
`;

import * as yup from 'yup';

import { validationMessages } from '@constants/validationMessages';

import { NotesAndHistoryInputNames } from '../../../../types';

export const getValidationScheme = (names: NotesAndHistoryInputNames) => {
  return yup.object().shape({
    [names.title]: yup.string()
      .nullable()
      .test(
        'required if no note text',
        validationMessages.required,
        (value, context) => {
          if(value){
            return true;
          }

          return context.parent?.[names.noteText]?.length > 0;
        }),
  });
};

import { BookmarkDataItem, ItemWithId } from '@/shared/types/commonTypes';

export const getDataForBookmark = (items: Array<ItemWithId>, state: boolean) => (
  items.reduce((acc: Array<BookmarkDataItem>, item: ItemWithId) => {
    acc.push({
      id: item.id,
      flagged: Number(state)
    });

    return acc;
  }, [])
);

import { observer } from 'mobx-react';

import { TabsComponentRenderProps } from './types';

import { useNotesAndHistoryLocalState } from '../../NotesAndHistoryLocalState';

import { NOTES_TAB_VALUE } from '../../data';

export const TabsComponentRender = observer(({
  components,
  renderNotes
}: TabsComponentRenderProps) => {
  const { currentTab } = useNotesAndHistoryLocalState();

  if(currentTab === NOTES_TAB_VALUE){
    return renderNotes();
  }

  const componentRender = components[currentTab];

  return componentRender ? componentRender() : null;
});



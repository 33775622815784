import { format } from 'date-fns';

import { YEAR_MONTH_DAY, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

export const getFormatedDateNow = (withTime?: boolean): string => {
  if(withTime){
    return format(new Date(), YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS);
  } else {
    return format(new Date(), YEAR_MONTH_DAY);
  }
};

import * as yup from 'yup';
import {
  USER_CUSTOM_FIELD_FORM_FIELDS,
  USER_CUSTOM_FIELDS_TYPES,
  USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES
} from '@constants/userSettingsUserCustomFields';
import { validationMessages } from '@constants/validationMessages';
import { UserCustomFieldDropDown, UserCustomFieldType } from '@/shared/types/userSettingsUserCustomFields';
import { TestContextExtended } from '@/shared/types/commonTypes';


export const validationSchema = yup.object().shape({
  [USER_CUSTOM_FIELD_FORM_FIELDS.name]: yup.string()
    .required(validationMessages.required),
  [USER_CUSTOM_FIELD_FORM_FIELDS.type]: yup.string()
    .required(validationMessages.required),
  [USER_CUSTOM_FIELD_FORM_FIELDS.entity]: yup.string()
    .required(validationMessages.required),
  [USER_CUSTOM_FIELD_FORM_FIELDS.options]: yup.array().when(
    USER_CUSTOM_FIELD_FORM_FIELDS.type,
    {
      is: (type:UserCustomFieldType) => type === USER_CUSTOM_FIELDS_TYPES.dropDownList,
      then: yup.array().min(1, validationMessages.required).of(
        yup.object().shape({
          [USER_CUSTOM_FIELD_DROP_OPT_FIELD_NAMES.label]: yup.string()
            .required(validationMessages.invalidOption)
            .test(
              'search for duplicates',
              validationMessages.duplicateCustomFieldOption,
              (value, context) => {
                const { from } =
                  context as yup.TestContext & TestContextExtended<UserCustomFieldDropDown>;
                const allOptions = from[1].value.options;
                const allLabels = allOptions.map(opt => opt.label);
                return allLabels.length === new Set(allLabels).size;
              }
            )
        })
      )
    }
  )
});

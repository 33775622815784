import styled from 'styled-components';

export const ControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0 20px;
  flex-grow: 1;
  
  & .MuiFormGroup-root{
    width: 400px;
  }
`;

export const RadioButtonsWrapper = styled.div`
  margin-top: 24px;
  width: 180px;
  overflow: hidden;
`;

export const SelectWrapper = styled.div`
  margin-top: 20px;
  width: 180px;
  flex-grow: 1;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
`;


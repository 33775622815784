import React from 'react';

import { HeaderRow, HeaderTitle, Wrapper } from './styles';

import { HeaderProps } from './types';

export const Header = ({ children }: HeaderProps) => {
  return <Wrapper>{ children }</Wrapper>;
};

Header.Tiltle = HeaderTitle;
Header.Row = HeaderRow;

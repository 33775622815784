import styled from 'styled-components';

import TableContainer from '@material-ui/core/TableContainer';
import { StyledProps } from './types';

export const StyledTableContainer = styled(TableContainer)<StyledProps>`
  position: relative;
  flex-grow: 1;
  height: ${({ $height }) => $height || 'calc(100vh - 328px)'};
  overflow-x: hidden;
`;

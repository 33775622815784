import * as yup from 'yup';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';
import { isDateBefore } from '@/shared/utils/dateValidationUtils';

import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation } from '@constants/validationPatterns';

import {
  ACTION_TYPES,
  ADD_SPOUSE_FIELD_NAMES,
  ADD_SPOUSE_TYPES,
} from './data';

import { FieldsType } from './types';

const nameValidation = {
  [ADD_SPOUSE_FIELD_NAMES.firstName]: yup.string()
    .required(validationMessages.invalidFirstName),
  [ADD_SPOUSE_FIELD_NAMES.lastName]: yup.string()
    .required(validationMessages.invalidLastName),
};

const birthDateValidation = {
  [ADD_SPOUSE_FIELD_NAMES.birthdate]: yup.string()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than Today',
      validationMessages.invalidDate,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    )
};

const headOfHouseHold = {
  [ADD_SPOUSE_FIELD_NAMES.headOfHouseHoldId]: yup.string()
    .required(validationMessages.required),
};

const spouseIdValidation = {
  [ADD_SPOUSE_FIELD_NAMES.spouseId]: yup.number()
    .nullable()
    .required(validationMessages.required),
};

const commonValidation = {
  ...nameValidation,
  ...birthDateValidation,
};

export const getValidationSchema = yup.lazy((fields: FieldsType) => {
  if(fields.addType === ACTION_TYPES.new){
    if(fields.spouseType === ADD_SPOUSE_TYPES.combineHousehold){
      return yup.object().shape({
        ...commonValidation,
        ...headOfHouseHold,
      });
    } else {
      return yup.object().shape({
        ...commonValidation,
        [ADD_SPOUSE_FIELD_NAMES.status]: yup.string()
          .required(validationMessages.required),
        [ADD_SPOUSE_FIELD_NAMES.classification]: yup.string()
          .required(validationMessages.required),
        [ADD_SPOUSE_FIELD_NAMES.officePrimaryAdvisor]: yup.string()
          .required(validationMessages.required),
      });
    }
  }

  if(fields.addType === ACTION_TYPES.existed){
    if(fields.spouseType === ADD_SPOUSE_TYPES.combineHousehold){
      return yup.object().shape({
        ...spouseIdValidation,
        ...headOfHouseHold,
      });
    } else {
      return yup.object().shape(spouseIdValidation);
    }
  }

  return yup.object().shape({});
});

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

import {
  USER_ICON_DIMENSIONS,
  USER_ICON_FONT_SIZES,
} from './data';

import { UserIconWrapperProps, AbbrWrapperProps } from './types';

export const Wrapper = styled.div<UserIconWrapperProps>`
  display: grid;
  place-items: center;
  width: ${ ({ $size }) => `${USER_ICON_DIMENSIONS[$size]}px`};
  height: ${ ({ $size }) => `${USER_ICON_DIMENSIONS[$size]}px`};
  border-radius: 50%;
  font-size: ${ ({ $size }) => `${USER_ICON_FONT_SIZES[$size]}px`};
  color: ${ COLORS.white };
  background-color: ${ COLORS.search };
  overflow: hidden;
  
  ${ ({ $withOutline }) => $withOutline && css `
    border: 1px solid ${ COLORS.white }
  `};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledAbbr = styled.div<AbbrWrapperProps>`
  color: ${({ $color }) => $color ? $color : COLORS.white};
`;

import React from 'react';

import NextApptDate from '@modules/NextApptDate';

import { getAppointmentDateLabel } from '@/shared/utils/getApptDateLabel';

import { SalesPipelineNextApptDateCellProps } from './types';

import { NextApptWrapper, NextApptHeader, NextApptDateWrapper } from './styles';

export const SalesPipelineNextApptDateCellContent = ({
  date,
}: SalesPipelineNextApptDateCellProps) => {

  const appointmentDateLabel = getAppointmentDateLabel(date);

  return (
    <NextApptWrapper>
      <NextApptHeader>
        { appointmentDateLabel }
      </NextApptHeader>
      <NextApptDateWrapper>
        <NextApptDate date={ date }/>
      </NextApptDateWrapper>
    </NextApptWrapper>
  );
};

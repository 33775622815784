import React, {
  useCallback,
  useMemo
} from 'react';
import { useWatch } from 'react-hook-form';

import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Table from '@components/Table';
import TextWithTooltip from '@/components/TextWithTooltip';

import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import {
  STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

import { CommonStandingInstructionRowProps } from './types';

import {
  STANDING_INSTRUCTIONS_TYPES_OPTIONS
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonStandingInstructions/data';

import { convert_string_YY_MM_DD_to_MM_DD_YY } from '@/shared/utils/convert_string_YY_MM_DD_to_MM_DD_YY';
import { getTimeStampInMs } from '@/shared/utils/getTimeStampInMs';
import { getBalanceValue } from '@modules/AccountAndPlansRow/utils';

import { SelectWrapper } from './styles';

const getFieldsNames = (fieldPath: string) => ({
  amountFieldName: `${fieldPath}.${STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES.amount}`,
  endDateFieldName: `${fieldPath}.${STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES.endDate}`,
  sortFieldName: `${fieldPath}.${STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES.sort}`,
  startDateFieldName: `${fieldPath}.${STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES.startDate}`,
  typeFieldName: `${fieldPath}.${STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES.type}`,
});

export const CommonStandingInstructionRow = ({
  control,
  disable,
  disableEdit,
  disableDelete,
  field,
  fieldPath,
  originalIndex,
  isEdit,
  onDelete,
  onEdit,
  onSave,
  setValue,
  onRowClick,
}: CommonStandingInstructionRowProps) => {
  const {
    amountFieldName,
    endDateFieldName,
    sortFieldName,
    startDateFieldName,
    typeFieldName
  } = useMemo(() => getFieldsNames(fieldPath), [fieldPath]);

  const onDeleteWrapper = useCallback(() => onDelete(originalIndex),[onDelete, originalIndex]);
  const onEditWrapper = useCallback(() => onEdit(originalIndex),[onEdit, originalIndex]);
  const onSaveWrapper = useCallback(async () => {
    const timeStamp = getTimeStampInMs(new Date());
    await setValue(sortFieldName, timeStamp);
    await onSave(fieldPath);
  }, [setValue, sortFieldName, onSave, fieldPath]);
  const onClick = useCallback(() => onRowClick(originalIndex),[onRowClick,originalIndex]);

  const [amountValue, endDateValue, startDateValue, typeValue] = useWatch({
    control,
    name: [ amountFieldName, endDateFieldName, startDateFieldName, typeFieldName]
  });

  const amount = useMemo(() => getBalanceValue(typeValue, amountValue), [amountValue]);

  if(!isEdit) {
    return (
      <Table.Row
        $preventHover={ true }
        onClick={ onClick }
      >
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.startDate }
            name={ startDateFieldName }
          />
          {
            startDateValue
              ? convert_string_YY_MM_DD_to_MM_DD_YY({
                dateString: startDateValue,
                separatorOut: '/'
              })
              :'-'
          }
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.endDate }
            name={ endDateFieldName }
          />
          {
            endDateValue
              ? convert_string_YY_MM_DD_to_MM_DD_YY({
                dateString: endDateValue,
                separatorOut: '/'
              })
              :'-'
          }
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.amount }
            name={ amountFieldName }
          />
          <TextWithTooltip text={ amount }/>
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.type }
            name={ typeFieldName }
          />
          {typeValue}
          <PopupTable.Actions
            $alignSelf={ 'center' }
            $marginTop={ '0px' }
            disableDelete={ disableDelete }
            disableEdit={ disableEdit }
            isEdit={ isEdit }
            onDeleteClick={ onDeleteWrapper }
            onEditClick={ onEditWrapper }
            onSaveClick={ onSaveWrapper }
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <Table.Row $preventHover={ true }>
      <PopupTable.CellWrapper>
        <HFDatePicker
          control={ control }
          disabled={ disable }
          name={ startDateFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFDatePicker
          control={ control }
          disabled={ disable }
          name={ endDateFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFTextInput
          inputProps={ {
            maxLength: 25,
            placeholder: '0.00',
            step: '1.00',
          } }
          TextFieldProps={ {
            type: 'number',
          } }
          control={ control }
          disabled={ disable }
          name={ amountFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <SelectWrapper>
          <HFSelect
            control={ control }
            disabled={ disable }
            name={ typeFieldName }
            options={ STANDING_INSTRUCTIONS_TYPES_OPTIONS }
          />
        </SelectWrapper>
        <PopupTable.Actions
          $marginLeft={ 'auto' }
          disableDelete={ disableDelete }
          disableEdit={ disableEdit }
          isEdit={ isEdit }
          onDeleteClick={ onDeleteWrapper }
          onEditClick={ onEditWrapper }
          onSaveClick={ onSaveWrapper }
        />
      </PopupTable.CellWrapper>
    </Table.Row>
  );
};

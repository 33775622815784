import React from 'react';
import { useController } from 'react-hook-form';

import LinkedContact from '@modules/LinkedContact';

import { HFLinkedContactProps } from './types';

export const HFLinkedContact = <T,>({
  AutocompleteProps,
  disabled,
  ControllerProps,
  FetchProps,
  TextFieldProps,
  UserItemOptionProps,
  UserItemProps,
  control,
  initLinkedContact,
  name,
  onLinkedContactChange,
  showError = true,
  withoutQueryMode,
  withRemoveButton,
  withEditButton,
}: HFLinkedContactProps<T>) => {
  const {
    field:{ onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    ...ControllerProps,
  });
  return(
    <LinkedContact
      AutocompleteProps={ AutocompleteProps }
      FetchProps={ FetchProps }
      TextFieldProps={ {
        ...TextFieldProps,
        error: Boolean(error?.message),
        helperText: error?.message || ''
      } }
      UserItemOptionProps={ UserItemOptionProps }
      UserItemProps={ UserItemProps }
      disabled={ disabled }
      initLinkedContact={ initLinkedContact }
      onChange={ onChange }
      onLinkedContactChange={ onLinkedContactChange }
      showError={ showError }
      value={ value }
      withEditButton={ withEditButton }
      withRemoveButton={ withRemoveButton }
      withoutQueryMode={ withoutQueryMode }
    />
  );

};

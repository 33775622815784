import { useCallback } from 'react';
import get from 'lodash/get';

import { IdType } from '@/shared/types/commonTypes';
import { TodoItem } from '@/shared/types/todos';

type UseRowCheckboxProps = {
  selectedIds: any,
  onGlobalCheckboxClickCallback: (arrOfIds: Array<IdType>) => void,
}

export const useToDosRowCheckbox = ({
  selectedIds,
  onGlobalCheckboxClickCallback
}: UseRowCheckboxProps) => {
  const getCheckboxState = useCallback((item: TodoItem) => (
    Boolean(selectedIds.find((task: TodoItem) => task.id === item.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [ selectedIds ]);

  const onRowCheckboxClick = useCallback((item: any) => (e: any, state: boolean) => {
    const itemId = get(item, 'id');
    const itemRecurring = get(item, 'requirring');

    if (state) {
      onGlobalCheckboxClickCallback([ ...selectedIds, {
        id: itemId, withRecurring: itemRecurring
      }]);
    } else {
      const withoutSelectedID = selectedIds.filter((task: TodoItem) => task.id !== itemId);
      onGlobalCheckboxClickCallback(withoutSelectedID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedIds ]);

  return {
    getCheckboxState,
    onRowCheckboxClick
  };
};

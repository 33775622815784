import React from 'react';

import { Controller } from 'react-hook-form';

import Rank from '@components/Rank';

import { HFRankProps } from './types';

export const HFRank = ({
  ControllerProps,
  control,
  name,
  ranksOptions,
}: HFRankProps) => (
  <Controller
    control={ control }
    name={ name }
    render={ ({
      field:{ value, onChange }
    }) => (
      <Rank
        initRank={ value || '' }
        onRankChange={ onChange }
        ranksOptions={ ranksOptions }
      />
    )
    }
    { ...ControllerProps }
  />
);

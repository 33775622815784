import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useListCriteriaFieldsRowState } from './useListCriteriaFieldsRowState';

import CustomIconButton, { ICON_BUTTON_TYPES } from '@components/CustomIconButton';
import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import { ValueFields } from './ValueFields';

import { LABEL_WIDTH, NAME_OPTIONS } from './data';

import { Wrapper, ItemWrapper, DeleteWrapper } from './styles';

import { CriteriaFormWrapper } from '@/shared/types/lists';
import { ListCriteriaFieldProps } from './types';

export const ListCriteriaFieldsRow = observer(({
  namePath,
  index,
  onDelete,
}: ListCriteriaFieldProps) => {
  const { control } = useFormContext<CriteriaFormWrapper>();

  const {
    fieldOptions,
    fieldSettings,
    fieldValue,
    helperText,
    isFieldDisabled,
    isOperandDisabled,
    names,
    operandOptions,
    operandValue,
  } = useListCriteriaFieldsRowState({ namePath });

  const onDeleteWrapper = useCallback(() => {
    onDelete(index);
  },[index, onDelete]);

  return (
    <Wrapper>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH }
        label={ 'Type' }
      >
        <ItemWrapper>
          <HFSelect
            control={ control }
            name={ names.type }
            options={ NAME_OPTIONS }
          />
        </ItemWrapper>
      </FormBlock.FormField>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH }
        label={ 'Field' }
      >
        <ItemWrapper>
          <HFSelect
            control={ control }
            disabled={ isFieldDisabled }
            helperText={ helperText }
            name={ names.field }
            options={ fieldOptions }
            showError={ isFieldDisabled }
          />
        </ItemWrapper>
      </FormBlock.FormField>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH }
        label={ 'Operand' }
      >
        <ItemWrapper>
          <HFSelect
            control={ control }
            disabled={ isOperandDisabled }
            name={ names.operand }
            options={ operandOptions }
            showError={ isOperandDisabled }
          />
        </ItemWrapper>
      </FormBlock.FormField>
      <FormBlock.FormField
        $labelWidth={ LABEL_WIDTH }
        isWrap={ false }
        label={ 'Value' }
      >
        <ItemWrapper>
          <ValueFields
            field={ fieldValue }
            isMultiple={ fieldSettings.isMultiple }
            maxChars={ fieldSettings.maxChars }
            namePath={ names.values }
            operand={ operandValue }
            options={ fieldSettings.valueOptions }
          />
        </ItemWrapper>
        <DeleteWrapper>
          <CustomIconButton
            onClick={ onDeleteWrapper }
            type={ ICON_BUTTON_TYPES.close }
          />
        </DeleteWrapper>
      </FormBlock.FormField>
    </Wrapper>
  );
});

import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import UserCustomFieldPopup from '@modules/UserSettings/UserCustomFieldPopup';
import UserCustomFieldAlertPopup from '@modules/UserSettings/UserCustomFieldAlertPopup';
import UserCustomFieldDeletePopup from '@modules/UserSettings/UserCustomFieldDeletePopup';

import { MODAL_TYPE } from '@constants/modalTypes';
import { DELETE_MESSAGE, DELETE_NOTIFICATION_MESSAGE, EDIT_NOTIFICATION_MESSAGE } from './data';

import { UserCustomFieldsPreHeader, UserCustomFieldsTable } from './components';

import { UserCustomField, UserCustomFieldAlertPopupProps } from '@/shared/types/userSettingsUserCustomFields';


export const UserCustomFields = observer(() => {
  const { init, resetStore, onSave, onDelete } = useStore().UserSettingsUserCustomFieldsStore;
  const modalStore = useStore().ModalStore;

  const openAlert = useCallback((modalProps: UserCustomFieldAlertPopupProps) => (
    modalStore.openModal({
      component: UserCustomFieldAlertPopup,
      modalProps,
      modalType: MODAL_TYPE.USER_CUSTOM_FIELD_ALERT
    })
  ), [modalStore]);

  const onAddEditClick = useCallback((item?: UserCustomField) => {
    const openAddEditModal = () => (
      modalStore.openModal({
        component: UserCustomFieldPopup,
        modalType: MODAL_TYPE.ADD_USER_CUSTOM_FIELD,
        modalProps: {
          onSaveCallback: onSave,
          id: item?.id,
          isUsedBefore: item?.isUsed
        }
      })
    );

    if(item?.isUsed) {
      openAlert({
        message: EDIT_NOTIFICATION_MESSAGE,
        onConfirmCallback: openAddEditModal
      });
    } else {
      openAddEditModal();
    }
  }, [modalStore, onSave, openAlert]);


  const onDeleteClick = useCallback((item: UserCustomField) => {
    const openDeletePopup = () => (
      modalStore.openModal({
        component: UserCustomFieldDeletePopup,
        modalProps: {
          message: DELETE_MESSAGE,
          onConfirmCallback: () => onDelete({ id: item.id })
        },
        modalType: MODAL_TYPE.DELETE_USER_CUSTOM_FIELD
      })
    );

    if(item.isUsed) {
      openAlert({
        message: DELETE_NOTIFICATION_MESSAGE,
        onConfirmCallback: openDeletePopup
      });
    } else {
      openDeletePopup();
    }

  },[modalStore, onDelete, openAlert]);


  useEffect(() => {
    init();
    return resetStore;
  }, [init, resetStore]);

  return (
    <>
      <UserCustomFieldsPreHeader onAddClick={ onAddEditClick }/>
      <UserCustomFieldsTable
        onAddEdit={ onAddEditClick }
        onRemove={ onDeleteClick }
      />
    </>
  );
});


import React from 'react';

import { Wrapper, WrapperWithStylesToChildren } from './styles';
import { ContentCenterProps } from '@components/ContentCenter/types';

export const ContentCenter = ({
  $height = '20px',
  $width = '100px',
  acceptStylesToChild,
  children,
}: ContentCenterProps) => {
  if(!acceptStylesToChild) {
    return <Wrapper>{children}</Wrapper>;
  }
  
  return (
    <WrapperWithStylesToChildren
      $height={ $height }
      $width={ $width }
    >
      {children}
    </WrapperWithStylesToChildren>
  );
};

import styled from 'styled-components';
import { BOX_SHADOW } from '@constants/style';
import { COLORS } from '@/shared/constants/colors';

export const ContentWrapper  = styled.div`
  height: calc(100vh - 168px);
  margin: 0 20px;
  overflow-y: auto;
`;

export const MainWrapper = styled.div`
  width: 855px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${COLORS.white};
  box-shadow: ${ BOX_SHADOW };
`;

export const WrapperImageUploader = styled.div`
  display: flex;
  gap: 20px;
  padding-right: 16px;
`;

export const CityWrapper = styled.div`
  padding: 0 32px 0 5px;
  box-sizing: border-box;
  flex: 1;
`;

export const ZipWrapper = styled.div`
  width: 114px;
  box-sizing: border-box;
`;

export const EmailWrapper = styled.div`
  padding: 0 23px 0 5px;
  box-sizing: border-box;
  flex: 1;
`;

export const VerifiedWrapper = styled.div`
  display: flex;
  width: 83px;
  height: 30px;
  align-items: center;
`;

export const LeftColumnWrapper = styled.div`
  width: 49%;
  padding-right: 65px;
  box-sizing: border-box;
`;

export const RightColumnWrapper = styled.div`
  width: 51%;
  padding-left: 5px;
  box-sizing: border-box;
`;

export const TitleAndStateWrapper = styled.div`
  width: 49%;
  padding-right: 65px;
  box-sizing: border-box;
`;

export const HeaderWrapper = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;
import { addWeeks, isAfter, isBefore, isEqual, parse } from 'date-fns';
import { OverdueFilter, TodoGridFormField } from '@/shared/types/todos';
import { compareTwoObjectsAndFindDiffFieldNames } from '@/shared/utils/compareTwoObjectsAndFindDiffFieldNames';
import {
  ALL_OVERDUE_AND_DUE_OPTION,
  ALL_TASKS_OPTION,
  DUE_TODAY_OPTION, DUE_WITHIN_ONE_MONTH_OPTION,
  DUE_WITHIN_ONE_WEEK_OPTION, DUE_WITHIN_ONE_YEAR_OPTION,
  NO_DUE_DATE,
  OVERDUE_AND_DUE_TODAY_OPTION,
  OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION,
  STAGES,
  TODOS_COLUMNS_IDS,
  TO_DOS_FIELDS_NAMES,
  TODOS_FILTER_NAMES
} from '@constants/todosData';
import { MultipleSortingState } from '@/shared/types/commonTypes';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';
import { addYears } from 'date-fns';
import { addMonths } from 'date-fns';
import { startOfDay } from 'date-fns';

type Props = {
  updateData: TodoGridFormField
  tableItems: Array<any>
  sortingState: MultipleSortingState
  overDueFilter: OverdueFilter
  previousCompleteToggleState: boolean
  filtersState: any
}

export const isNeedToUpdatePage = ({
  updateData,
  tableItems,
  sortingState,
  filtersState,
  overDueFilter,
  previousCompleteToggleState,
}: Props): boolean => {
  const sortKeys = Object.keys(sortingState);

  const prevState = tableItems.filter((item) => item.id === updateData.id)?.[0] || {};
  const diffFields = compareTwoObjectsAndFindDiffFieldNames(updateData, prevState);


  if(diffFields.includes(TO_DOS_FIELDS_NAMES.stage)){
    const isCompletedStages = updateData.stage === STAGES.complete || updateData.stage === STAGES.skipped;
    return sortKeys.includes(TODOS_COLUMNS_IDS.stage) || isCompletedStages || previousCompleteToggleState;
  }

  if(diffFields.includes(TO_DOS_FIELDS_NAMES.priority)){
    const priorityFilterValue = filtersState?.[TODOS_FILTER_NAMES.priority] || [];
    return sortKeys.includes(TODOS_COLUMNS_IDS.priority) ||
      (priorityFilterValue.length > 0 && !priorityFilterValue.includes(updateData.priority));
  }

  if(diffFields.includes(TO_DOS_FIELDS_NAMES.dueDate)){
    if(sortKeys.includes(TODOS_COLUMNS_IDS.dueDate) ||
      (overDueFilter.type === ALL_OVERDUE_AND_DUE_OPTION.value && !updateData.dueDate)){
      return true;
    }

    if(overDueFilter.type === ALL_TASKS_OPTION.value ||
      (overDueFilter.type === NO_DUE_DATE.value && !updateData.dueDate)){
      return false;
    }

    const today = startOfDay(new Date());
    const parsedDate = parse(updateData.dueDate as string, YEAR_MONTH_DAY, today);

    if(overDueFilter.type === OVERDUE_AND_DUE_TODAY_OPTION.value){
      return isAfter(parsedDate, today);
    }

    if(overDueFilter.type === OVERDUE_AND_DUE_WITH_ONE_WEEK_OPTION.value){
      const todayWithOneWeek = addWeeks(today, 1);
      return isAfter(parsedDate, todayWithOneWeek);
    }

    if(overDueFilter.type === DUE_TODAY_OPTION.value){
      return !isEqual(parsedDate, today);
    }

    if(overDueFilter.type === DUE_WITHIN_ONE_WEEK_OPTION.value){
      const todayWithOneWeek = addWeeks(today, 1);
      return isBefore(parsedDate, today) || isAfter(parsedDate, todayWithOneWeek);
    }

    if(overDueFilter.type === DUE_WITHIN_ONE_MONTH_OPTION.value){
      const todayWithOneMonth = addMonths(today, 1);
      return isBefore(parsedDate, today) || isAfter(parsedDate, todayWithOneMonth);
    }

    if(overDueFilter.type === DUE_WITHIN_ONE_YEAR_OPTION.value){
      const todayWithOneYear = addYears(today, 1);
      return isBefore(parsedDate, today) || isAfter(parsedDate, todayWithOneYear);
    }

    return true;
  }

  return false;
};

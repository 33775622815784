import {
  EVENT_TYPES_BACKEND,
  EVENT_TYPES
} from '@/shared/constants/colorsByEventTypes';

export const typeConverter = ({ isBackend, type }) => {
  const targetFrom = isBackend
    ? EVENT_TYPES_BACKEND
    : EVENT_TYPES;

  const targetTo = isBackend
    ? EVENT_TYPES
    : EVENT_TYPES_BACKEND;

  const [propName, ] = Object.entries(targetFrom).find(([, value]) => value === type);

  return targetTo[propName];
};

const RULES_NAMES: Record<string, string> = {
  allowCall: 'allowCall',
  allowEmail: 'allowEmail',
  allowMail: 'allowMail',
  allowText: 'allowText',
};

export const RULES_LABELS: Record<string, string> = {
  [RULES_NAMES.allowCall]: 'Do Not Call',
  [RULES_NAMES.allowEmail]: 'Do Not Email',
  [RULES_NAMES.allowMail]: 'Do Not Mail',
  [RULES_NAMES.allowText]: 'Do Not Text',
};

export const RULES:Array<{
  name: string,
  label: string
}> = [
  {
    name: RULES_NAMES.allowCall,
    label: RULES_LABELS.allowCall,
  },
  {
    name: RULES_NAMES.allowEmail,
    label: RULES_LABELS.allowEmail,
  },
  {
    name: RULES_NAMES.allowMail,
    label: RULES_LABELS.allowMail,
  },
  {
    name: RULES_NAMES.allowText,
    label: RULES_LABELS.allowText,
  },
];

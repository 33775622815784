import React from 'react';

import {
  FormFieldChildrenWrapper,
  FormFieldLabel,
  FormFieldLabelContent,
  FormFieldWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  Row,
  VerticalCenteredWrapper,
  Wrapper
} from './styles';

import {
  FormBlocHeaderProps,
  FormBlockProps,
  FormFieldProps,
  VerticalCenteredBlockProps,
} from './types';

const FormBlockHeader = ({
  title,
  children
}: FormBlocHeaderProps) => (
  <HeaderWrapper>
    <HeaderTitleWrapper> { title } </HeaderTitleWrapper>
    { children && children }
  </HeaderWrapper>
);


const VerticalCenteredBlock = ({
  $disabled,
  children
}: VerticalCenteredBlockProps) => (
  <VerticalCenteredWrapper $disabled={ $disabled }>
    { children && children }
  </VerticalCenteredWrapper>
);


export const FormBlock = ({
  children,
  isBorderBottom = true,
  marginBottom,
  paddingBottom,
  style = {}
}: FormBlockProps) => (
  <Wrapper
    isBorderBottom={ isBorderBottom }
    marginBottom={ marginBottom }
    paddingBottom={ paddingBottom }
    style={ style }
  >
    {children}
  </Wrapper>
);


export const FormField = ({
  $isDisabled,
  $labelHeight = 30,
  $labelHeightUnits = 'px',
  $labelWidth = 150,
  $labelWidthUnits = 'px',
  children,
  isFluid = true,
  isRequired,
  isWrap = true,
  label,
  renderLabelContent,
  childrenWrapperProps
}: FormFieldProps) => {
  return (
    <FormFieldWrapper>
      {
        (label || renderLabelContent) &&
        <FormFieldLabel
          $isDisabled={ $isDisabled }
          $labelHeight={ $labelHeight }
          $labelHeightUnits={ $labelHeightUnits }
          $labelWidth={ $labelWidth }
          $labelWidthUnits={ $labelWidthUnits }
        >
          {
            label &&
            <FormFieldLabelContent
              $isRequired={ isRequired }>
              { label }
            </FormFieldLabelContent>
          }
          {
            renderLabelContent && renderLabelContent()
          }
        </FormFieldLabel>
      }
      <FormFieldChildrenWrapper
        $isFluid={ isFluid }
        $isWrap={ isWrap }
        { ...childrenWrapperProps }
      >
        { children }
      </FormFieldChildrenWrapper>
    </FormFieldWrapper>
  );
};


FormBlock.FormField = FormField;
FormBlock.VerticalCenteredWrapper = VerticalCenteredBlock;
FormBlock.RowWrapper = Row;
FormBlock.Header = FormBlockHeader;

import React from 'react';

import TextWithTooltip from '@components/TextWithTooltip';

import { INDICATOR_TYPES } from './data';

import { Wrapper } from './styles';

export const ListItemWithColorIndicator = ({
  indicatorColor,
  label,
  type = INDICATOR_TYPES.round,
}) => (
  <Wrapper
    $indicatorBgColor={ indicatorColor }
    $indicatorType={ type }
  >
    <TextWithTooltip text={ label } />
  </Wrapper>
);

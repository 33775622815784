import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const TextContent = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: ${COLORS.primaryText};
`;

import React, { useCallback, useMemo } from 'react';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';
import TextWithTooltip from '@components/TextWithTooltip';

import { RelatedContactSpouseChangeConfirmationProps } from './types';

export const RelatedContactSpouseChangeConfirmation = ({
  closeModal,
  modalProps,
}: RelatedContactSpouseChangeConfirmationProps) => {
  const { onCancel = () => {}, onSave, data, currentContact } = modalProps;

  const message = useMemo(() => {
    const { firstName, lastName } = data;

    return (
      <>
        This will remove the current spouses
        <TextWithTooltip
          text={ `${firstName} ${lastName} and ${data.spouseContacts?.firstName} ${data.spouseContacts?.lastName}` }
        />
        { data.spouseContacts?.id !== currentContact.id && !data.spouseContacts?.isHousehold &&
          <>
            and change it to:
            <TextWithTooltip
              text={ `${firstName} ${lastName} and ${currentContact.firstName} ${currentContact.lastName}` }
            />
          </>
        }
        { Boolean('isHousehold' in data.spouseContacts && data.spouseContacts.isHousehold) && 
          <>
            The record of Household
            <TextWithTooltip
              text={ `${firstName} ${lastName} & ${data.spouseContacts?.firstName} ${data.spouseContacts?.lastName}` }
            />
            will be deleted permanently.
          </>
        }
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onCancelWrapper = useCallback(() => {
    closeModal();
    if(onCancel) {
      onCancel();
    }
  },[closeModal, onCancel]);

  const onSaveWrapper = useCallback(() => {
    closeModal();
    onSave(data, onCancel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[closeModal, onSave]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message }
      onCancelClick={ onCancelWrapper }
      onClose={ onCancelWrapper }
      onConfirmClick={ onSaveWrapper }
      type={ POPUP_TYPES.CONFIRM }
    />
  );
};

import { isEmpty } from 'lodash';
import { differenceInYears, format, parse } from 'date-fns';

import { ImageUploaderBusinessContactBgrnd, ImageUploaderDefaultContactBgrnd } from '@/assets';

import { CONTACT_TYPES } from '@constants/common';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { ContactItem, OccupationItemType } from '@/shared/types/contact';
import { RelatedContact } from '@/shared/types/contactOverview';

export const getBirthdate = (birthdate: string) => {
  if(birthdate) {
    const parseDate = parse(birthdate, YEAR_MONTH_DAY, new Date());
    const age = differenceInYears(new Date(), parseDate);
    return `${format(parseDate, 'LLLL dd, yyyy')} (age ${age})`;
  }
  return '-';
};

export const getOccupationTitle = (occupations: Array<OccupationItemType>) => {
  if(!isEmpty(occupations)) {
    return occupations.filter((occupation) => occupation.current === 'Yes')[0]?.title || occupations[0].title;
  }
  return '-';
};

export const getContactImage = (contact: ContactItem | RelatedContact): string => {
  if('photo' in contact && contact.photo) {
    return contact.photo;
  }
  
  if('type' in contact && contact.type === CONTACT_TYPES.BUSINESS) {
    return ImageUploaderBusinessContactBgrnd;
  }
  return ImageUploaderDefaultContactBgrnd;
};

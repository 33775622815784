import { defaultCountries, parseCountry } from 'react-international-phone';

const PREFERRED_CODE_OF_COUNTRY = ['us', 'ca'];

const preferredCountries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return PREFERRED_CODE_OF_COUNTRY.includes(iso2);
}).reverse();

export const actualCountry = preferredCountries.concat(defaultCountries);

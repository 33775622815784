import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';

import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import {
  ColumnTypeId,
  IdType,
  MultipleSortingState,
  PaginationData,
  UpdateFlagsParams
} from '@/shared/types/commonTypes';
import { ConstructorProps, UpdateMassiveFlagCallbackData, UpdateSingleFlagCallbackData } from './types';


class CommonTableStore<T> {
  checkboxItemsProcessor?: ((items: Array<T>) => Array<T>) | null;
  currentPage: number = 1;
  globalFlagged: boolean = false;
  itemIdPath: string;
  items: Array<T> = [];
  itemsFrom: number = 0;
  itemsPerPage: number = 0;
  itemsTo: number = 0;
  multipleSorting: MultipleSortingState = {};
  selectedIDs: Array<IdType> = [];
  totalItems: number = 0;
  totalPages: number = 1;

  onGlobalFlaggedChangeReaction: IReactionDisposer;
  onPageChangeReaction: IReactionDisposer;
  onSortingChangeReaction: IReactionDisposer;

  onGlobalFlaggedChangeReactionCallback?: () => void;
  onSortReactionCallback?: () => void;
  onPageChangeReactionCallback?: () => void;

  constructor({
    onGlobalFlaggedChangeReactionCallback,
    onSortReactionCallback,
    onPageChangeReactionCallback,
    itemIdPath = 'id',
    checkboxItemsProcessor
  }: ConstructorProps<T> = {}){
    makeAutoObservable(this,{
      items: true,
      clearItems: action.bound,
      setGlobalFlaggedFilters: action.bound
    });

    this.checkboxItemsProcessor = checkboxItemsProcessor;

    this.onGlobalFlaggedChangeReactionCallback = onGlobalFlaggedChangeReactionCallback;
    this.onSortReactionCallback = onSortReactionCallback;
    this.onPageChangeReactionCallback = onPageChangeReactionCallback;
    this.itemIdPath = itemIdPath;

    this.onGlobalFlaggedChangeReaction = this.createOnGlobalFlaggedChangeReaction();
    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.onSortingChangeReaction = this.createOnSortingChangeReaction();
  }

  clearItems(isItemsFull?: boolean){
    if(this.items.length > 0 && isItemsFull){
      this.items = [];
    }

    if(!isItemsFull) {
      this.items = [];
    }
  }

  createOnGlobalFlaggedChangeReaction() {
    return reaction(
      () => this.globalFlagged,
      () => {
        //when the current page changes we need to blocked double refresh 
        this.onPageChangeReaction();
        this.setCurrentPage(1);
        this.onPageChangeReaction = this.createOnPageChangeReaction();

        this.onGlobalFlaggedChangeReactionCallback && this.onGlobalFlaggedChangeReactionCallback();
      }
    );
  }

  createOnPageChangeReaction() {
    return reaction(
      () => ({
        page: this.currentPage,
        items: true
      }),
      () =>{
        this.setSelectedIds([]);
        this.onPageChangeReactionCallback && this.onPageChangeReactionCallback();
      }
    );
  }

  createOnSortingChangeReaction() {
    return reaction(
      () => this.multipleSorting,
      () => {
        this.onSortReactionCallback && this.onSortReactionCallback();
      }
    );
  }

  getItemsIdsArray = () => {
    return this.items.map(item => get(item, this.itemIdPath));
  };

  get globalCheckboxState() {
    const itemsIdsArray = this.checkboxItemsProcessor
      ? this.checkboxItemsProcessor(this.items).map(item => get(item, this.itemIdPath))
      : this.getItemsIdsArray();
    const isAllCheckedOnCurrentPage = itemsIdsArray.every(id => this.selectedIDs.includes(id));
    const checked = isAllCheckedOnCurrentPage && this.selectedIDs?.length > 0 && itemsIdsArray.length > 0;
    const indeterminate = itemsIdsArray.length > 0 &&
      !isAllCheckedOnCurrentPage &&
      itemsIdsArray.some(id => this.selectedIDs.includes(id));
    return {
      checked,
      indeterminate,
    };
  }

  getItemCheckboxState = (item: T) => {
    return this.selectedIDs.includes(get(item, this.itemIdPath));
  };

  getItemAndItemIndex = (id: any): {item: T | null, index: number | null} => {
    const index = findIndex(this.items, set({}, this.itemIdPath, id));

    return {
      index: index >= 0 ? index : null,
      item: index >= 0 ? this.items[index]: null
    };
  };

  onCheckBoxClick = (item: T) => {
    const itemId = get(item, this.itemIdPath);
    const isSelected = this.selectedIDs.includes(itemId);

    if(isSelected) {
      this.setSelectedIds(this.selectedIDs.filter(id => id !== itemId));
    } else {
      this.setSelectedIds([...this.selectedIDs, itemId]);
    }
  };

  onGlobalCheckboxClick = () => {
    const itemsIdsArray = this.checkboxItemsProcessor
      ? this.checkboxItemsProcessor(this.items).map(item => get(item, this.itemIdPath))
      : this.getItemsIdsArray();
    
    const isAllSelected = itemsIdsArray.every(id => this.selectedIDs.includes(id));

    if(isAllSelected) {
      this.setSelectedIds([]);
    } else {
      const uniqIdsArray = Array.from(new Set([...itemsIdsArray, ...this.selectedIDs]));
      this.setSelectedIds(uniqIdsArray);
    }
  };


  resetTable (){
    this.onPageChangeReaction();
    this.onSortingChangeReaction();

    this.currentPage = 1;
    this.globalFlagged = false;
    this.items = [];
    this.itemsFrom = 0;
    this.itemsPerPage = 0;
    this.itemsTo = 0;
    this.multipleSorting = {};
    this.selectedIDs = [];
    this.totalItems = 0;
    this.totalPages = 1;

    this.onPageChangeReaction = this.createOnPageChangeReaction();
    this.onSortingChangeReaction = this.createOnSortingChangeReaction();
  }
  setCurrentPage = (newCurrentPageValue: number)=>  {
    this.currentPage = newCurrentPageValue;
  };

  setCurrentPageWithoutReaction = (newCurrentPageValue: number)=>  {
    this.onPageChangeReaction();
    this.currentPage = newCurrentPageValue;
    this.onPageChangeReaction = this.createOnPageChangeReaction();
  };

  setGlobalFlaggedFilters(state: boolean) {
    this.globalFlagged = state;
  }

  setMultipleSorting = (columnId: ColumnTypeId) => {
    const sortingObjectCopy = { ...this.multipleSorting };
    if (!(columnId in sortingObjectCopy)) {
      sortingObjectCopy[columnId] = 'asc';
    } else if (sortingObjectCopy[columnId] === 'asc') {
      sortingObjectCopy[columnId] = 'desc';
    } else {
      delete sortingObjectCopy[columnId];
    }

    this.multipleSorting = sortingObjectCopy;
  };

  setPaginationData(data: PaginationData) {
    this.itemsPerPage = data.per_page;
    this.itemsFrom = data?.from || 0;
    this.itemsTo = data?.to || 0;
    this.totalPages = data.last_page;
    this.totalItems = data.total;
  }

  setSelectedIds = (idsArray:Array<IdType>) => {
    this.selectedIDs = idsArray;
  };

  updateItemById = (id: IdType, updatedFields: Partial<T>) => {
    const { index, item } = this.getItemAndItemIndex(id);
    if (item && index !== null) {
      let newItem: T = cloneDeep(item);

      for (const field in updatedFields) {
        if (Object.prototype.hasOwnProperty.call(updatedFields, field)) {
          set(newItem as object , field, updatedFields[field]);
        }
      }
      this.items[index] = newItem as T;
    }
  };

  checkAndSetIfPageOutOfRange() {
    if(this.currentPage > this.totalPages) {
      this.setCurrentPage(this.totalPages);
    }
  }

  refreshSelectedIds(arrayOfIds: Array<IdType>) {
    const isSelectedIdsIncludesItemsFromArrayOfIds = this.selectedIDs.some(id => arrayOfIds.includes(id));

    if (isSelectedIdsIncludesItemsFromArrayOfIds) {
      this.selectedIDs = this.selectedIDs.filter(id => !arrayOfIds.includes(id));
    }
  }

  async updateSingleItemFlag(id: number, sendCallback: UpdateSingleFlagCallbackData){
    const { index, item } = this.getItemAndItemIndex(id);
    if(index !== null && item && typeof item === 'object' && 'flagged' in item) {
      const newFlaggedState = {
        flagged:  item.flagged === 1 ? 0 : 1
      };
      const id = get(item, this.itemIdPath);
      await sendCallback({ id, ...newFlaggedState });
      this.updateItemById(id, newFlaggedState as any);
    }
  }

  async updateMassiveItemFlag(isAllFlagged: boolean, sendCallback: UpdateMassiveFlagCallbackData){
    const data = isAllFlagged
      ? this.items.map(item => ({
        id: get(item, this.itemIdPath),
        flagged: 0
      }))
      : this.items.reduce((acc: UpdateFlagsParams, item) => {
        if(item && typeof item === 'object' && 'flagged' in item && !item.flagged){
          acc.push({
            id: get(item, this.itemIdPath),
            flagged: 1
          });
        }
        return acc;
      }, []);

    await sendCallback(data);
    data.forEach(item => {
      this.updateItemById(item.id, { flagged: item.flagged } as any);
    });
  }
}

export default CommonTableStore;

import { format, isEqual, startOfDay } from 'date-fns';

import { getDates } from '@/shared/utils/dateValidationUtils';

import { NextAppointment } from '@modules/ContactDetailsSideBarPanel/store/types';

export const getStatusName = (nextAppointment: NextAppointment | null) => {
  if(nextAppointment) {
    return `[${nextAppointment.option}]`;  
  }
  return 'No Calendar Activities Scheduled';
};

export const getDuration = (nextAppointment: NextAppointment | null) => {
  if(nextAppointment) {
    const { start, end } = getDates({
      endDateString: nextAppointment.endDate,
      endTimeString: nextAppointment.endTime,
      startDateString: nextAppointment.startDate,
      startTimeString: nextAppointment.startTime,
    });
  
    const isEqualDate = isEqual(startOfDay(start), startOfDay(end));
  
    if(isEqualDate) {
      return `
        ${format(start, 'LLLL dd, KK:mm aaa - ')}
        ${format(end, 'KK:mm aaa')}
      `;
    }
  
    return `
      ${format(start, 'LLLL dd, KK:mm aaa - ')}
      ${format(end, 'LLLL dd, KK:mm aaa')}
    `;
  }
  return '';
};

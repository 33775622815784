import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;

  & > div > div {
    margin-bottom: 20px;
  }
  & > div > div:last-child {
    margin-bottom: 0px;
  }
`;

export const LabelWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    width: 18px;
    height: 18px;
  }
`;
import React from 'react';

import { Controller } from 'react-hook-form';

import { HFSelectProps } from '@modules/HookFormComponents/HFSelect/types';

import CustomSelect from '@components/CustomSelect';

export const HFSelect = ({
  ControllerProps,
  MenuListProps,
  MenuProps,
  SelectProps,
  control,
  disabled,
  displayEmpty = true,
  helperText,
  isMenuEqualToInputWidth,
  name,
  options,
  placeholder = 'Please Select',
  renderItem,
  resetLabel,
  resetValue,
  resettable,
  showError = true,
}: HFSelectProps) => (
  <Controller
    control={ control }
    name={ name }
    render={ ({
      field:{ value, onChange, onBlur },
      fieldState:{ invalid, error }
    }) => {
      let checkedValue;
      if(SelectProps?.multiple){
        checkedValue = Array.isArray(value) ? value : [];

      } else {
        checkedValue = value || '';
      }

      return (
        <CustomSelect
          MenuListProps={ MenuListProps }
          MenuProps={ MenuProps }
          disabled={ disabled }
          displayEmpty={ displayEmpty }
          errorMessage={ error?.message }
          helperText={ helperText }
          invalid={ invalid }
          isMenuEqualToInputWidth={ isMenuEqualToInputWidth }
          onBlur={ onBlur }
          onChange={ onChange }
          options={ options }
          placeholder={ placeholder }
          renderItem={ renderItem }
          resetLabel={ resetLabel }
          resetValue={ resetValue }
          resettable={ resettable }
          showError={ showError }
          value={ checkedValue }
          { ...SelectProps }
        />
      );
    } }
    { ...ControllerProps }
  />
);

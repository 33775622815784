import React from 'react';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { DESCRIPTION_FIELD_TEXT_PROPS, DESCRIPTION_FIELD_INPUT_PROPS } from './data';

import { ListDescriptionProps } from './types';

export const ListDescription = ({
  name,
  labelWidth,
  control,
  label = 'Description'
}: ListDescriptionProps) => {

  return (

    <FormBlock.FormField
      $labelWidth={ labelWidth }
      label={ label }
    >
      <HFTextInput
        inputProps={ DESCRIPTION_FIELD_INPUT_PROPS }
        TextFieldProps={ DESCRIPTION_FIELD_TEXT_PROPS }
        control={ control }
        name={ name }
        placeholder={ 'Enter Description' }
      />
    </FormBlock.FormField>
  );
};

import { ContactProfileTab } from '../types';
import {
  BUSINESS_PROFILE_LINKS,
  BusinessProfileLinksType,
  CONTACT_DETAILS_TAB,
  HOUSEHOLD_PROFILE_LINKS,
  HouseHoldProfileLinksType,
  INDIVIDUAL_PROFILE_LINKS,
  IndividualProfileLinksType,
} from '@constants/routes/contacts';
import { getContactDetailsLink } from '@/shared/utils/contactRoutesUtils';
import { CONTACT_TYPES } from '@constants/common';
import { IdType } from '@/shared/types/commonTypes';

type HouseHoldTabsType = Array<ContactProfileTab<HouseHoldProfileLinksType>>;
type BusinessTabsType = Array<ContactProfileTab<BusinessProfileLinksType>>;
type IndividualTabsType = Array<ContactProfileTab<IndividualProfileLinksType>>;

const generateBusiness = (id: IdType, subTab: BusinessProfileLinksType) => {
  return getContactDetailsLink(id, CONTACT_TYPES.BUSINESS)[CONTACT_DETAILS_TAB.profile]({
    contactType: CONTACT_TYPES.BUSINESS,
    subTab
  });
};
const generateIndividual = (id: IdType, subTab: IndividualProfileLinksType) => {
  return getContactDetailsLink(id, CONTACT_TYPES.INDIVIDUAL)[CONTACT_DETAILS_TAB.profile]({
    contactType: CONTACT_TYPES.INDIVIDUAL,
    subTab
  });
};

const generateHousehold = (id: IdType, subTab: HouseHoldProfileLinksType) => {
  return getContactDetailsLink(id, CONTACT_TYPES.HOUSEHOLD)[CONTACT_DETAILS_TAB.profile]({
    contactType: CONTACT_TYPES.HOUSEHOLD,
    subTab
  });
};

const INDIVIDUAL_TABS: IndividualTabsType = [
  {
    label: 'Individual Details',
    value: INDIVIDUAL_PROFILE_LINKS.individualDetails,
    getLink: (id) => generateIndividual(id, INDIVIDUAL_PROFILE_LINKS.individualDetails),
  },
  {
    label: 'Personal Details',
    value: INDIVIDUAL_PROFILE_LINKS.personalDetails,
    getLink: (id) => generateIndividual(id, INDIVIDUAL_PROFILE_LINKS.personalDetails)
  },
  {
    label: 'Custom Fields',
    value: INDIVIDUAL_PROFILE_LINKS.customFields,
    getLink: (id) => generateIndividual(id, INDIVIDUAL_PROFILE_LINKS.customFields)
  },
  {
    label: 'Related Contacts',
    value: INDIVIDUAL_PROFILE_LINKS.relatedContacts,
    getLink: (id) => generateIndividual(id, INDIVIDUAL_PROFILE_LINKS.relatedContacts)
  },
  {
    label: 'System Details',
    value: INDIVIDUAL_PROFILE_LINKS.systemDetails,
    getLink: (id) => generateIndividual(id, INDIVIDUAL_PROFILE_LINKS.systemDetails)
  },
];

const BUSINESS_TABS: BusinessTabsType = [
  {
    label: 'Business Details',
    value: BUSINESS_PROFILE_LINKS.businessDetails,
    getLink: (id) => generateBusiness(id, BUSINESS_PROFILE_LINKS.businessDetails)
  },
  {
    label: 'Custom Fields',
    value: BUSINESS_PROFILE_LINKS.customFields,
    getLink: (id) => generateBusiness(id, BUSINESS_PROFILE_LINKS.customFields)
  },
  {
    label: 'Employees/Contacts',
    value: BUSINESS_PROFILE_LINKS.employees,
    getLink: (id) => generateBusiness(id, BUSINESS_PROFILE_LINKS.employees)
  },
  {
    label: 'System Details',
    value: INDIVIDUAL_PROFILE_LINKS.systemDetails,
    getLink: (id) => generateBusiness(id, BUSINESS_PROFILE_LINKS.systemDetails)
  },
];

const HOUSEHOLD_TABS: HouseHoldTabsType = [
  {
    label: 'Household Details',
    value: HOUSEHOLD_PROFILE_LINKS.householdDetails,
    getLink: (id) => generateHousehold(id, HOUSEHOLD_PROFILE_LINKS.householdDetails)
  },
  {
    label: 'Personal Details',
    value: HOUSEHOLD_PROFILE_LINKS.personalDetails,
    getLink: (id) => generateHousehold(id, HOUSEHOLD_PROFILE_LINKS.personalDetails)
  },
  {
    label: 'Custom Fields',
    value: HOUSEHOLD_PROFILE_LINKS.customFields,
    getLink: (id) => generateHousehold(id, HOUSEHOLD_PROFILE_LINKS.customFields)
  },
  {
    label: 'Related Contacts',
    value: HOUSEHOLD_PROFILE_LINKS.relatedContacts,
    getLink: (id) => generateHousehold(id, HOUSEHOLD_PROFILE_LINKS.relatedContacts)
  },
  {
    label: 'System Details',
    value: HOUSEHOLD_PROFILE_LINKS.systemDetails,
    getLink: (id) => generateHousehold(id, HOUSEHOLD_PROFILE_LINKS.systemDetails)
  },
];

type ContactDetailsProfileTab = {
  [CONTACT_TYPES.INDIVIDUAL]: IndividualTabsType,
  [CONTACT_TYPES.BUSINESS]: BusinessTabsType,
  [CONTACT_TYPES.HOUSEHOLD]: HouseHoldTabsType,
}
export const CONTACT_DETAILS_PROFILE_TABS: ContactDetailsProfileTab = {
  [CONTACT_TYPES.INDIVIDUAL]: INDIVIDUAL_TABS,
  [CONTACT_TYPES.BUSINESS]: BUSINESS_TABS,
  [CONTACT_TYPES.HOUSEHOLD]: HOUSEHOLD_TABS,
};

export const LEFT_COLUMN_WIDTH = 166;
export const RIGHT_COLUMN_WIDTH = 144;
export const RIGHT_COLUMN_SYSTEM_DETAILS = 80;


import {
  format,
  intervalToDuration,
  parse,
  isValid,
} from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const getIntervalBetweenTodayAndData = (dateString: string) => {
  const today = new Date();
  const parsedDate = parse(dateString, YEAR_MONTH_DAY, today);
  
  if(!isValid(parsedDate)){
    return null;
  }
  
  return intervalToDuration({
    end: today,
    start: parsedDate
  });
};
import React, { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { getActivityStartDateFields } from './utils';
import { convertSecondStringToMinutes, getTimeDataBasedOnView } from '@modules/ActivityPopup';

import { MODAL_TYPE } from '@constants/modalTypes';

import { ActivityPopup } from '@modules/ActivityPopup';
import { AddActivity, ChevronLeft, ChevronRight } from '@/assets';
import { ComponentHeader } from '@modules/ContactComponents';
import TextWithTooltip from '@components/TextWithTooltip';
import Tooltip from '@components/Tooltip';

import {
  ActivityDate,
  ActivityName,
  Button,
  CalendarActivityWrapper,
  Content,
  Counter,
  IconWrapper,
  MarkedTextChunk,
  NextActivity,
  TextChunk,
} from './styles';

import { CalendarActivityProps } from './types';

// When the number of displayed activities comes to an end,
// you need to get a new list of activities from the server
const ACTIVITY_NUMBER_FROM_THE_END = 20;

export const CalendarActivity = observer(({
  activity,
  contact,
  getMoreActivity,
  onSaveActivity,
  onViewAllClick,
}: CalendarActivityProps) => {

  const modalStore = useStore().ModalStore;
  const { settings: { activityDefaultTime } } = useStore().SettingsStore;

  const [ activityNumber, setActivityNumber ] = useState<number>(0);
  const numberOfActivity = activity.length;

  const formattedActivityDate = useMemo(() => {
    if(!isEmpty(activity)) {
      const {
        activityDate,
        numberOfDaysBeforeActivity,
        typeName
      } = getActivityStartDateFields(`${activity[activityNumber].startDate} ${activity[activityNumber].startTime}`);
      
      return `${activityDate} (+${numberOfDaysBeforeActivity} ${typeName})`;  
    }
  }, [activity, activityNumber]);

  const onAddActivityClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        // @ts-ignore TODO after fix calendar type
        initData: {
          ...getTimeDataBasedOnView({
            startDateObj: new Date(),
            duration: convertSecondStringToMinutes(activityDefaultTime),
          }),
          primaryContact: contact as any,
        },
        onConfirm: onSaveActivity,
        onDelete: () => {},
      },
      component: ActivityPopup
    });
  }, [modalStore, contact]);

  const onArrowClick = (direction: number) => {
    if(activityNumber === numberOfActivity - ACTIVITY_NUMBER_FROM_THE_END && direction > 0) {
      getMoreActivity();
    }
    setActivityNumber((prev) => prev + direction);
  };
  
  return(
    <CalendarActivityWrapper>
      <ComponentHeader
        title={ 'Next Calendar Activity' }
        onViewAllClick={ onViewAllClick }
      >
        { activity && <Counter>{ activity.length }</Counter> }
        <IconWrapper>
          <Tooltip title={ 'Add Activity' }>
            <AddActivity onClick={ onAddActivityClick } height={ 20 }/>
          </Tooltip>
        </IconWrapper>
      </ComponentHeader>
      <NextActivity>
        { !isEmpty(activity) ? 
          <>
            <Button onClick={ () => onArrowClick(-1) } isShow={ Boolean(activityNumber) }>
              <ChevronLeft/>
            </Button>
            <Content>
              <ActivityName>
                <TextWithTooltip text={ activity[activityNumber].name }/>
              </ActivityName>
              <ActivityDate>
                { formattedActivityDate }
              </ActivityDate>
            </Content>
            <Button onClick={ () => onArrowClick(1) } isShow={ Boolean(activityNumber < numberOfActivity - 1) }>
              <ChevronRight/>
            </Button>
          </> :
          <Content>
            <TextChunk>NO CALENDAR ACTIVITIES </TextChunk>
            <MarkedTextChunk>TO VIEW</MarkedTextChunk>
          </Content>
        }
      </NextActivity>
    </CalendarActivityWrapper>
  );
});

import styled, { css } from 'styled-components';
import { COLORS } from '@constants/colors';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    padding-left: 20px;

    ${({ $selected }) => $selected && css` background-color: ${COLORS.select};`};
`;
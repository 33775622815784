import { ChartType, ScriptableContext } from 'chart.js';
import { format } from 'date-fns';

import { getGradient } from './utils';

import { COLORS } from '@constants/colors';

export const GENERAL_TOOLTIP_CONFIG = {
  axis: 'r' as const,
  backgroundColor: COLORS.white,
  bodyAlign: 'center' as const,
  bodyColor: COLORS.helpText,
  borderColor: '#101C5B1F',
  borderWidth: 1,
  caretPadding: 15,
  displayColors: false,
  enabled: true,
  padding: 12,
  titleAlign: 'center' as const,
  titleColor: COLORS.header,
  xAlign: 'center' as const,
  yAlign: 'bottom' as const,
};

export const GENERAL_Y_SCALES_CONFIG = {
  border: {
    color: COLORS.borders,
    display: true,
    width: 1,
  },
  grid: {
    display: true,
    lineWidth: 1,
  },
  min: 0,
  ticks: {
    precision: 0,
    callback: (value: string | number) => {
      const valueToNumber = Number(value);
      if (valueToNumber >= 1000) {
        return `$${valueToNumber / 1000}k`;
      }
      return `$${value}`;
    },
  },
};

export const DATASETS_CONFIG = {
  borderColor: COLORS.tagText,
  borderWidth: 1,
  fill: true,
  pointBackgroundColor: COLORS.white,
  pointBorderWidth: 1,
  pointHoverBackgroundColor: COLORS.tagText,
  pointHoverRadius: 7,
  pointRadius: 5,
  showLine: true,
  type: 'line' as const,
  backgroundColor: (context: ScriptableContext<ChartType>) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    if (!chartArea) {
      return 'transparent';
    }
    return getGradient(chart);
  },
  pointStyle: (context: ScriptableContext<ChartType>) => {
    if(context.raw !== 0) {
      return 'circle';
    } else {
      return 'line';
    }
  },
};

export const HOVER_CONFIG = {
  axis: 'xy' as const,
  intersect: false,
  mode: 'nearest' as const,
};

export const GENERAL_PLUGINS_CONFIG = {
  legend: {
    display: false,
  },
};

export const GENERAL_X_SCALES_CONFIG = {
  display: true,
  grid: {
    display: false,
  },
  type: 'time' as const,
};

export const YEAR_PERIOD_X_SCALES_CONFIG = {
  time: {
    unit: 'month' as const,
  },
  ticks: {
    autoSkip: false,
    maxRotation: 0,
    stepSize: 1,
    callback: (value: any) => format(new Date(value), 'MMM'),
  },
};

export const QUARTER_PERIOD_X_SCALES_CONFIG = {
  time: {
    unit: 'day' as const,
  },
  ticks: {
    autoSkip: false,
    maxRotation: 0,
    stepSize: 5,
    callback: (value: any) => format(new Date(value), 'MMM d'),
  },
};
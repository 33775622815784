import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';

import { COLORS } from '@constants/colors';

export const NavigateWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${ props => (
    props.$isWide 
      ? '325px' 
      : '250px'
  )};
  
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const LabelWrapper = styled.div`
  color: ${ COLORS.header };
  font-weight: 400;
  font-size: 18px;
  margin: 0 auto;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 5px;
`;

export const ViewWrapper = styled.div`
  width: 90px;
  margin-left: -5px;
`;

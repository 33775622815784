import { ExportFieldNames } from './types';
import { ValueLabelObj } from '@/shared/types/commonTypes';
import { LIST_EXPORT_TYPE, LIST_EXPORT_FILE_TYPE } from '@constants/lists';
import { ExportData } from '@/shared/types/lists';

export const LABEL_WIDTH = 145;

export const EXPORT_FIELD_NAMES: ExportFieldNames = {
  exportType: 'exportType',
  fileType: 'fileType',
  id: 'id',
  splitHousehold: 'splitHousehold'
};

export const OPTIONS_FOR_FILE_TYPE: Array<ValueLabelObj> = [
  {
    value: LIST_EXPORT_FILE_TYPE.excel,
    label: 'Excel'
  },
  {
    value: LIST_EXPORT_FILE_TYPE.csv,
    label: 'Csv'
  },
];

export const EXPORT_CONTACTS_DEFAULT_FORM_STATE: Omit<ExportData, 'id' | 'splitHousehold'> ={
  exportType: LIST_EXPORT_TYPE.primaryContactInformation,
  fileType: LIST_EXPORT_FILE_TYPE.excel,
};

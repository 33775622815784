import React from 'react'
import CustomIcon from '../CustomIcon'

export default function AddActivity() {
    return (
        <CustomIcon
            viewBox='0 0 20 20'
        >
            <path d="M6.5 11H2.5C0.625 11 0 11.625 0 13.5V17.5C0 19.375 0.625 20 2.5 20H6.5C8.375 20 9 19.375 9 17.5V13.5C9 11.625 8.375 11 6.5 11ZM8 18.125C8 18.6086 7.60863 19 7.12501 19H1.87503C1.39141 19 1.00004 18.6086 1.00004 18.125V12.875C1.00004 12.3914 1.39141 12 1.87503 12H7.12501C7.60863 12 8 12.3914 8 12.875V18.125Z" fill="#EA8213"/>
            <path d="M6.5 0H2.5C0.625 0 0 0.625 0 2.5V6.5C0 8.375 0.625 9 2.5 9H6.5C8.375 9 9 8.375 9 6.5V2.5C9 0.625 8.375 0 6.5 0ZM8 7.12501C8 7.60863 7.60863 8 7.12501 8H1.87503C1.39141 8 1.00004 7.60863 1.00004 7.12501V1.87499C1.00004 1.39137 1.39141 1 1.87503 1H7.12501C7.60863 1 8 1.39137 8 1.87499V7.12501Z" fill="#EA8213"/>
            <path d="M17.5 0H13.5C11.625 0 11 0.625 11 2.5V6.5C11 8.375 11.625 9 13.5 9H17.5C19.375 9 20 8.375 20 6.5V2.5C20 0.625 19.375 0 17.5 0ZM19 7.12501C19 7.60863 18.6086 8 18.125 8H12.875C12.3914 8 12 7.60863 12 7.12501V1.87499C12 1.39137 12.3914 1 12.875 1H18.125C18.6086 1 19 1.39137 19 1.87499V7.12501Z" fill="#EA8213"/>
            <path d="M15 11.5C15 11.2239 15.2239 11 15.5 11C15.7761 11 16 11.2239 16 11.5V19.5C16 19.7761 15.7761 20 15.5 20C15.2239 20 15 19.7761 15 19.5V11.5Z" fill="#EA8213"/>
            <path d="M19.5 15C19.7761 15 20 15.2239 20 15.5C20 15.7761 19.7761 16 19.5 16H11.5C11.2239 16 11 15.7761 11 15.5C11 15.2239 11.2239 15 11.5 15H19.5Z" fill="#EA8213"/>
        </CustomIcon>
    )
}

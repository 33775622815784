import React, { useCallback, useMemo } from 'react';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';
import TextWithTooltip from '@components/TextWithTooltip';

import { ChangePrimaryContactPopupProps } from './types';


export const ChangePrimaryContactPopup = ({
  closeModal,
  modalProps,
}: ChangePrimaryContactPopupProps) => {
  const { onSaveEmployee, data } = modalProps;
  
  const message = useMemo(() => {
    const { firstName, lastName } = data;

    return (
      <>
        This will remove the current primary contact and change it to: 
        <TextWithTooltip text={ `${firstName} ${lastName}` } />
      </>
    );
  }, [ data ]);

  // When we click Cancel, we still should save New Employee, but replace primaryContact to value 'No'
  const onCancelWrapper = useCallback(() => {
    data.primaryContact = 'No';
    closeModal();
    onSaveEmployee(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[ closeModal, onSaveEmployee ]);

  // changePrimary = 1 means that the old primary contact will be overwritten by the new one
  const onSaveWrapper = useCallback(() => {
    closeModal();
    data.changePrimary = 1;
    onSaveEmployee(data);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[ closeModal, onSaveEmployee ]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ message }
      onCancelClick={ onCancelWrapper }
      onClose={ onCancelWrapper }
      onConfirmClick={ onSaveWrapper }
      type={ POPUP_TYPES.CONFIRM }
    />
  );
};

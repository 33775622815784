import { ValueLabelObj, Country } from '@/shared/types/commonTypes';
import { Adviser, ConvertedPrimaryAdvisor, Source, SourceCategory } from '@/shared/types/contactOptionsTypes';

export const getCountriesOptions = (arrayOfCountries: Array<Country>): Array<ValueLabelObj> => {
  return arrayOfCountries.map((country) => ({ label: country.fullName, value: country.id }));
};

export const sourceCategoryConverter = (categories: Array<SourceCategory>): Array<ValueLabelObj> => (
  categories.map(category => ({
    label: category.option,
    value: category.id
  }))
);

export const advisersConverter = (advisers: Array<Adviser>): Array<ConvertedPrimaryAdvisor> => (
  advisers.map(adviser => ({
    id: adviser.id,
    firstName: adviser.firstName,
    lastName: adviser.lastName,
    photo: adviser.headshot,
  }))
);

export const sourceConverter = (sources: Array<Source>): Array<ValueLabelObj> => (
  sources.map(source => ({
    label: source.option,
    value: source.id
  }))
);

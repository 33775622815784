import React from 'react';

import { USER_ICON_SIZES } from './data';

import {
  Wrapper,
  StyledImage,
  StyledAbbr,
} from './styles';

import { UserIconProps } from './types';

export const UserIcon = ({
  imgSrc,
  abbr,
  $size = USER_ICON_SIZES.medium,
  $withOutline = false,
  $color,
}: UserIconProps) => {
  return (
    <Wrapper
      $size={ $size }
      $withOutline={ $withOutline }
    >
      {
        imgSrc &&  <StyledImage src={ imgSrc } />
      }

      {
        (abbr && !imgSrc) && <StyledAbbr $color={ $color } >{abbr}</StyledAbbr>
      }
    </Wrapper>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';

import Box from '@material-ui/core/Box';
import PopupActionsButtons from '@components/PopupActionsButtons';
import PopupSingleActionButton, { POPUP_BUTTON_TYPES } from '@components/PopupSingleActionButton';
import SalesPipelineQuickActionButton from '@modules/SalesPipline/SalesPipelineQuickActionButton';
import { NotesAndHistoryNotificationWrapper } from '@modules/NotesAndHistory';

import { SALES_PIPELINE_QUICK_ACTION_UI_CONF } from '@constants/salesPipeline';

import { SalesPipelinePopupFooterProps } from './types';
import { SalesPipelineStatusTypeWithoutActive } from '@/shared/types/salesPipline';

export const SalesPipelinePopupFooter = observer(({
  alertType,
  isDisabled,
  isEdit,
  onCancel,
  onConfirm,
  onDelete,
  statusFieldName,
  quickActionsConfig = SALES_PIPELINE_QUICK_ACTION_UI_CONF
}: SalesPipelinePopupFooterProps) => {
  const { control } = useFormContext();

  const statusValue = useWatch({
    name: statusFieldName,
    control
  });

  return (
    <>
      <Box
        alignItems={ 'center' }
        display={ 'flex' }
      >
        <NotesAndHistoryNotificationWrapper
          alertType={ alertType }
          boxProps={ {
            paddingLeft: '10px'
          } }
        >
          <>
            {
              isEdit &&
              <PopupSingleActionButton
                disabled={ isDisabled }
                onClick={ onDelete }
                type={ POPUP_BUTTON_TYPES.delete }
              >
                Delete
              </PopupSingleActionButton>
            }
            {
              quickActionsConfig && Object.entries(quickActionsConfig).map(([key, value]) =>(
                <SalesPipelineQuickActionButton
                  $bgHoverColor={ value.$bgHoverColor }
                  $color={ value.$color }
                  Icon={ value.Icon }
                  disabled={ isDisabled || statusValue === key }
                  key={ key }
                  label={ value.label }
                  onClick={ onConfirm }
                  value={ key as SalesPipelineStatusTypeWithoutActive }
                />
              ))
            }
          </>
        </NotesAndHistoryNotificationWrapper>
      </Box>
      <PopupActionsButtons
        CancelProps={ {
          disabled: isDisabled
        } }
        SaveProps={ {
          disabled: isDisabled
        } }
        cancelLabel={ alertType ? 'No' : 'Cancel' }
        onCancelClick={ onCancel }
        onSaveClick={ onConfirm }
        saveLabel={ alertType ? 'Yes' : 'Save' }
        wrapperProps={ {
          marginLeft: 'auto',
        } }
      />
    </>);
});

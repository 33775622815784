import styled, { css } from 'styled-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MuiIconButton from '@material-ui/core/IconButton';

import { COLORS } from '@constants/colors';
import { ERROR_SPACE_VALUE } from '@constants/style';

import { PlaceholdersProps, AvatarImageUploaderProps } from './types';



export const ImageUploaderWrapper = styled.div`
  position: relative;
  padding-bottom: ${ERROR_SPACE_VALUE}px;
  font-family: sans-serif;
  text-align: center;
`;

export const AvatarLogoImageUploader = styled.div<AvatarImageUploaderProps>`
  position: relative;
  box-sizing: border-box;
  height: 130px;
  width: ${({ isAvatar }) => isAvatar ? 130 : 255}px;
  border-radius: 6px;

  ${({ img }) => img && css`
    &:hover button {
      visibility: visible;
      background-color: ${COLORS.background};
    }
  `};
`;

export const AvatarTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
  color: ${COLORS.grey}; 
  visibility: hidden;
`;

export const LogoTitle = styled.div`
  margin-top: 12px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: ${COLORS.grey};
`;

export const StyledInput = styled.input`
  display: none;
`;

export const ListItemIconDragEnter = styled(ListItemIcon)`
  width: 40px;
  min-width: 37px;
  height: 40px;
  margin-top: 41px;
  visibility: hidden;
`;

export const ListItemIconUpload = styled(ListItemIcon)`
  width: 36px;
  min-width: 36px;
  height: 26px;
  margin-top: 21px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: 1px;
  text-align: left;
  color: ${COLORS.red};
  font-size: 10px;
`;

export const LogoPlaceholder = styled.div<PlaceholdersProps>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: ${({ img }) => !img && css`1px dashed ${COLORS.grey}`};
  border-radius: 6px;

  ${({ $isError }) => $isError && css`
    outline: 1px solid ${COLORS.red};
  `}

  ${({ img }) => img && css`
    background: url(${img}) no-repeat center;
    background-size: cover;

    &:hover * {
      color: ${COLORS.white};
      visibility: visible;

      path {
        fill: ${COLORS.white};
      }
    }
  `};

  ${({ img }) => !img && css`
    & * {
      color: ${COLORS.grey};
      visibility: visible;
    }
  
    &:hover * {
      color: ${COLORS.helpText};
      visibility: visible;

      path {
        fill: ${COLORS.helpText};
      }
    }

    &:hover > div {
      visibility: hidden;
    }
  `};

`;

export const AvatarPlaceholder = styled.div<PlaceholdersProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: url(${({ img }) => img && css `${img}`}) no-repeat center;
  background-size: cover;

  ${({ $isError }) => $isError && css`
    outline: 1px solid ${COLORS.red};
  `}

  &:hover {
    & * {
      color: ${COLORS.white};
      visibility: visible;
    }
  }

  &:active {
    & * {
      visibility: hidden;
    }
  }
`;

export const PlaceholderInnerWrapper = styled.div`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  box-sizing: border-box;
  background: ${COLORS.imageUploaderHover};
  visibility: hidden;
`;

export const IconButton = styled(MuiIconButton)`
  width: 33px;
  height: 33px;
  border: 3px solid ${COLORS.white};
  position: absolute;
  top: -17px;
  right: -17px;
  background-color: ${COLORS.background};
  visibility: hidden;

  svg {
    path {
      fill: ${COLORS.primaryText};
    }
  }
`;

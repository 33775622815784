import { POPUP_RADIO_VALUES } from '@constants/common';

export const RADIO_BUTTONS = [
  {
    value: POPUP_RADIO_VALUES.current,
    label: 'Only This Due Date'
  },
  {
    value: POPUP_RADIO_VALUES.all,
    label: 'This and All Future Dates'
  }
];

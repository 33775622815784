import React, { useCallback, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@services/store/store';

import { CATEGORIES, OFFICIAL_REQUEST_SUBCATEGORIES } from '@constants/todosData';
import { FILTER_ALL_BTN_LABEL } from '@constants/common';

import { ColorStarInCircle } from '@/assets';
import { TwoLevelFilter, TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES } from '@modules/TwoLevelFilter';
import BubbleButton from '@components/BubbleButton';
import RowFilter from '@components/RowFilter';

import { StarIconWrapper, WrapperFilter } from './styles';

import { Item, RenderItemProps } from '@components/RowFilter/types';
import { TypeFiltersProps } from './types';


export const TypeFilters = observer(({
  onCategoryFilterChange
}: TypeFiltersProps) => {

  const { isFiltersOpen, table } = useStore().ToDosStore;

  const onCategoryFilterChangeWrapper = useCallback((value: Item) => {
    onCategoryFilterChange(value);
  }, [ onCategoryFilterChange ]);

  const onTwoLevelFilterChange = useCallback(
    (setCurrentSelected: RenderItemProps['setCurrentSelected']) => (_: MouseEvent, value: Item) => {
      onCategoryFilterChange(value);
      if(setCurrentSelected) {
        setCurrentSelected(value);
      }
    }, [ onCategoryFilterChange ]);

  const limitVisibleButtons = isFiltersOpen || table.selectedIDs.length > 0 ? 5 : 7;

  return (
    <WrapperFilter>
      <RowFilter
        allButtonLabel={ FILTER_ALL_BTN_LABEL }
        items={ Object.values(CATEGORIES) }
        limit={ limitVisibleButtons }
        onChange={ onCategoryFilterChangeWrapper }
        renderItem={ ({
          content,
          currentSelected,
          indexForKey,
          isSelected,
          isSelectedFromMore,
          isShowMore,
          onClick,
          setCurrentSelected
        }) => (
          content === CATEGORIES.officialRequests ? (
            <TwoLevelFilter
              $isBeforeLine={ true }
              categoryName={ content }
              colorSchema={ TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.headerFilter }
              colorSchemaSelected={ TWO_LEVEL_FILTER_STYLE_SCHEME_TYPES.headerFilterSelected }
              onTypeFilterChange={ onTwoLevelFilterChange(setCurrentSelected) }          
              selectedCategory={ currentSelected || null }
              startAdornment={ (
                <StarIconWrapper>
                  <ColorStarInCircle/>
                </StarIconWrapper>
              ) }
              subCategoryOptions={ OFFICIAL_REQUEST_SUBCATEGORIES }
            />
          ) : (
            <BubbleButton
              $isActive={ (isSelected && !isShowMore) || isSelectedFromMore }
              $isSecondActiveStyle={ isSelected && isShowMore }
              key={ indexForKey }
              onClick={ onClick }
            >
              {content}
            </BubbleButton>
          ))
        }
      />
    </WrapperFilter>
  );
});

import { isBefore, isValid, parse, startOfDay } from 'date-fns';

import { YEAR_MONTH_DAY } from '@constants/dateFormats';

export const getIsDueDateExpired = (dueDate: string) => {
  const today = startOfDay(new Date());
  const selectedDueDate = parse(dueDate, YEAR_MONTH_DAY, today);

  if(isValid(selectedDueDate)){
    return isBefore(selectedDueDate, today);
  }

  return false;
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const StatusWrapper = styled.div`
  max-width: 150px;
  padding-right: 20px;
`;

export const MeetingStatusWrapper = styled.div`
  max-width: 100%;
  padding-right: 20px;
`;


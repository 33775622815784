import { UseFormReturn } from 'react-hook-form';
import { UserProfileType } from '@/shared/services/store/userSettingsStore/types';

export enum TabsValues {
  AddUser = 'Add User',
  UserProfile = 'User Profile',
}

export type AddUserPopupProps = {
  closeModal: () => void;
  modalProps: {
    updateUsersList: () => void,
  }
};

export type UseFormMethods = UseFormReturn<UserProfileType>;

import React from 'react';
import { Controller } from 'react-hook-form';

import FormBlock from '@components/FormBlock';

import {
  LeftColumnWrapperVariantThree,
  RightColumnWrapperVariantThree,
} from '@modules/AddContactModal/components/Columns';

import { Container, Radio } from './styles';

import { CheckedRadio, UnCheckedRadio } from '@/assets';

import { LEFT_COLUMN_WIDTH_V_THREE, RIGHT_COLUMN_WIDTH_V_THREE } from '@modules/AddContactModal/data';

function HouseholdHead({ control, firstName, secondName, setValue }) {
  const checkHandler = (event) => {
    const selectedName = event.target.value;
    const unselectedName = selectedName === secondName ? firstName : secondName;

    setValue(selectedName, true);
    setValue(unselectedName, false);
  };

  return (
    <>
      <LeftColumnWrapperVariantThree>
        <FormBlock.FormField
          $labelWidth={ LEFT_COLUMN_WIDTH_V_THREE }
          label={ 'Head of Household' }
        >
          <Container>
            <Controller
              control={ control }
              defaultValue={ true }
              name={ firstName }
              render={ ({ field }) => {
                return (
                  <Radio
                    checked={ field.value }
                    checkedIcon={ <CheckedRadio /> }
                    icon={ <UnCheckedRadio /> }
                    onChange={ checkHandler }
                    size='small'
                    value={ firstName }
                  />
                );
              } }
            />
          </Container>
        </FormBlock.FormField>
      </LeftColumnWrapperVariantThree>
      <RightColumnWrapperVariantThree>
        <FormBlock.FormField
          $labelWidth={ RIGHT_COLUMN_WIDTH_V_THREE }
          label={ 'Head of Household' }
        >
          <Container>
            <Controller
              control={ control }
              defaultValue={ false }
              name={ secondName }
              render={ ({ field }) => {
                return (
                  <Radio
                    checked={ field.value }
                    checkedIcon={ <CheckedRadio /> }
                    icon={ <UnCheckedRadio /> }
                    onChange={ checkHandler }
                    size='small'
                    value={ secondName }
                  />
                );
              } }
            />
          </Container>
        </FormBlock.FormField>
      </RightColumnWrapperVariantThree>

    </>
  );
}

export default HouseholdHead;

import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import AvatarLine from '@components/AvatarLine';
import BaseBlockWrapper from '@components/BasicLayoutComponents/BaseBlockWrapper';
import BlockButton from '@components/BlockButton';
import FormBlock from '@components/FormBlock';
import RemoveContactsPopup from '@modules/Lists/RemoveContactsPopup';
import ViewAllContacts from '@modules/Lists/ViewAllContacts';

import { LISTS_TYPES } from '@constants/lists';
import { MODAL_TYPE } from '@constants/modalTypes';
import { DRAW_CONFIG } from './data';
import { AvatarData, getAvatarData } from '@/shared/utils/getAvatarData';
import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

import { RowWrapperProps } from './types';

import { Delimiter, HeaderWrapper, Row, RowTitle } from './styles';
import { ListsContactsGroupTypes } from '@/shared/types/lists';

const RowWrapper = ({
  data,
  label,
  listId,
  listType,
  onRemove,
  value,
}: RowWrapperProps) => {
  const itemsCount = data?.count || 0;

  const contactAvatarData = useMemo(() => {
    if(!data) {
      return [];
    }
    return convertObjectWithNumericKeysToArray<AvatarData>(data, getAvatarData);
  }, [data]);

  const onRemoveClick = useCallback(() => {
    const numberAtCount =  data?.count &&  data?.count >= 0 ? data.count : 0;
    const countToRemove = numberAtCount + contactAvatarData.length;
    onRemove(countToRemove, value);
  }, [contactAvatarData.length, data?.count, onRemove, value]);

  return (
    <Row key={ label }>
      <RowTitle>{label}</RowTitle>
      <AvatarLine
        count={ itemsCount }
        isHideCounter={ itemsCount <= 0 && contactAvatarData.length > 0 }
        items={ contactAvatarData  }
      />
      {
        itemsCount > 0 &&
        <ViewAllContacts
          contactGroupTypes={ [value] }
          listId={ listId }
        />
      }
      {
        Boolean(contactAvatarData.length) &&
        listType === LISTS_TYPES.Manual &&
        <>
          <Delimiter/>
          <BlockButton
            onClick={ onRemoveClick }
          >
            Remove From List
          </BlockButton>
        </>
      }
    </Row>
  );
};


export const ListSetupContactsByType = observer(() => {
  const listSetupStore = useStore().ListsStore.ListSetupStore;
  const modalStore = useStore().ModalStore;

  const { contactsByType, currentListDetails } = listSetupStore;

  const memoizedOnRemove = useCallback((contactsCount: number, type: ListsContactsGroupTypes) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.REMOVE_CONTACTS_FROM_LIST,
      modalProps: {
        onSave: listSetupStore.removeContactsByGroupType.bind(listSetupStore),
        contactsCount,
        type,
      },
      component: RemoveContactsPopup,
    });
  }, [listSetupStore, modalStore]);

  return (
    <BaseBlockWrapper
      padding={ '20px 0px' }
    >
      <FormBlock isBorderBottom={ false }>
        <HeaderWrapper>
          <FormBlock.Header title={ 'Contact Rules' }/>
        </HeaderWrapper>
        {
          Object.entries(DRAW_CONFIG).map(([key, { label, value }]) => (
            <RowWrapper
              data={ contactsByType?.[value] || null }
              key={ value }
              label={ label }
              listId={ String(currentListDetails?.id) || '' }
              listType={ currentListDetails?.typeList || null }
              onRemove={ memoizedOnRemove }
              value={ value }
            />
          ))
        }
      </FormBlock>
    </BaseBlockWrapper>);
});

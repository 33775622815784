import React from 'react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';
import { getUTCFormattedString } from '@/shared/utils/getUTCFormattedString';

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';

import TextWithTooltip from '@components/TextWithTooltip';

import { LabelWrapper, WrapperWithLabel } from './styles';

import { EntityProps } from './types';
import { LinkedPlan } from '@/shared/types/accountsAndPlan';


export const Plan = ({ data }: EntityProps<LinkedPlan>) => {

  const amountConverted = data?.linkedContactType === LINKED_ITEM_TYPES.plan && data.feeAmount ?
    formatNumberToAmount(data.feeAmount) : '-';
  
  const formattedStartDate = data?.linkedContactType === LINKED_ITEM_TYPES.plan &&
    data.startDate ? getUTCFormattedString(data.startDate, MONTH_DAY_YEAR_BACKSLASH) : '-';

  return (
    <>
      <WrapperWithLabel>
        <LabelWrapper>Start Date</LabelWrapper>
        <TextWithTooltip text={ formattedStartDate }/>
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Status</LabelWrapper>
        <TextWithTooltip text={ data.feeStatus || '-' }/>
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Fee Amount</LabelWrapper>
        <TextWithTooltip text={ amountConverted }/>
      </WrapperWithLabel>        
    </>
  );
};

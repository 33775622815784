import {
  addDays,
  endOfMonth,
  format,
  getMonth,
  startOfMonth,
  subDays,
} from 'date-fns';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

const NUMBER_OF_DAYS_IN_WEEK = 7;

export const getMonthRangeWithIndents = date => {
  const firstDayMonth = startOfMonth(date);
  const lastDayMonth =  endOfMonth(date);

  const firstDayMonthWithOneWeekIndent =  subDays(firstDayMonth, NUMBER_OF_DAYS_IN_WEEK);
  const lastDayMonthWithOneWeekIndent =  addDays(lastDayMonth, NUMBER_OF_DAYS_IN_WEEK);

  return {
    start: format(firstDayMonthWithOneWeekIndent, YEAR_MONTH_DAY),
    end: format(lastDayMonthWithOneWeekIndent, YEAR_MONTH_DAY),
    monthNumber: getMonth(date)
  };
};

import ApiService from '@services/api/api.service';
import { AddOldTagParams, GetTagsListParams, TagListItem } from '@/shared/types/tags';

export const getTags = (params: any) => {
  return ApiService.get('/api/tag/searchTagNote', { params });
};
export const getAllTags = () => {
  return ApiService.get('/api/tag/getAllTags',);
};

export const getTagsList = (params?: GetTagsListParams) => {
  return ApiService.post('/api/tag/list', params);
};

export const deleteTag = (id: number) => {
  return ApiService.delete('/api/tag/delete', { params: { id } });
};

export const deleteContactTag = (params: any) => {
  return ApiService.post('/api/contact/deleteTag', params);
};

export const updateTag = (params: Pick<TagListItem, 'id' | 'label'>) => {
  return ApiService.patch('/api/tag/update', params);
};

export const createTag = (params: Pick<TagListItem, 'type' | 'label' | 'contactId'>) => {
  return ApiService.post('/api/tag/create', params);
};

export const addToContactOldTag = (params: AddOldTagParams) => {
  return ApiService.post('/api/contact/addToContactOldTag', params);
};
export const validationMessages = {
  duplicateCustomFieldOption: 'The Option already exists',
  enterRequired: 'Enter the required field',
  facebook: 'Invalid Facebook Link',
  invalidCycleOpenDate: 'Invalid Cycle Open Date',
  invalidWonDate: 'Invalid Won Date',
  invalid: 'Invalid Field',
  invalidAnniversaryDate: 'Invalid Anniversary Date',
  invalidBirthPlace: 'Invalid Birth Place',
  invalidBirthday: 'Invalid Birthday',
  invalidBriefDescription: 'Invalid Brief Description',
  invalidBusinessName: 'Invalid Business Name',
  invalidConfirmPassword: 'Passwords are not matched',
  invalidCoreClassification: 'Invalid Core Classification',
  invalidDate: 'Invalid Date',
  invalidDlNumber: 'Invalid DL Number',
  invalidEmail: 'Invalid Email Address',
  invalidFax: 'Invalid Fax Number',
  invalidFirstName: 'Invalid First Name',
  invalidLastName: 'Invalid Last name',
  invalidListSelectedContacts: 'No contacts have been selected',
  invalidMaidenName: 'Invalid Maiden Name',
  invalidMiddleName: 'Invalid Middle Name',
  invalidNickName: 'Invalid Nick Name',
  //eslint-disable-next-line
  invalidNewPassword: 'Invalid Password. Password must be not less than 8 characters long, include uppercase, numbers and symbols.',
  invalidOption: 'Invalid Option',
  invalidPassword: 'Invalid Password',
  invalidPetsInfo: 'Invalid Pets Information',
  invalidPhone: 'Invalid Phone Number',
  invalidPrimaryAdvisor: 'Invalid Primary Advisor',
  invalidRetirementDate: 'Invalid Retirement Date',
  invalidSsn: 'Invalid Social Security Number',
  invalidTaxIdNumber: 'Invalid Tax ID Number”',
  invalidTime: 'Invalid Time',
  invalidTitle: 'Invalid Title',
  invalidValue: 'Invalid Value',
  invalidWebsite: 'Invalid Website Address',
  invalidImageSize: 'Max image size 3 MB',
  invalidImageFormat: 'Invalid Format',
  invalidImage: 'The format or size is invalid',
  linkedin: 'Invalid Linkedin Link',
  onlyInvalid: 'Invalid',
  onlyRequired: 'Required',
  required: 'Required Field',
  twitter: 'Invalid Twitter Link',

};


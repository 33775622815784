import React, { useCallback } from 'react';

import { ListSetupConfirmationPopupProps } from './types';

import ConfirmationPopup, { POPUP_TYPES } from '@modules/ConfirmationPopup';

export const ListSetupConfirmationPopup = ({
  modalProps,
  closeModal
}: ListSetupConfirmationPopupProps) => {

  const { message, onCancel, onConfirm } = modalProps;

  const onCancelClick = useCallback(() => {
    closeModal();
    onCancel();
  },[closeModal, onCancel]);

  const onConfirmClick = useCallback(() => {
    closeModal();
    onConfirm();
  }, [closeModal, onConfirm]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'Cancel' }
      confirmLabel={ 'Ok' }
      isOpen={ true }
      message={ message }
      onCancelClick={ onCancelClick }
      onClose={ onCancelClick }
      onConfirmClick={ onConfirmClick }
      type={ POPUP_TYPES.CONFIRM }
    />
  );
};

import styled from 'styled-components';
import { COLORS } from '@constants/colors';

export const NextApptWrapper = styled.div``;

export const NextApptHeader = styled.div`
  font-size: 10px;
  color: ${COLORS.helpText}
`;

export const NextApptDateWrapper = styled.div`
  font-size: 14px;
`;

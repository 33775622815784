/* eslint-disable */
import Tag from "./Tag";
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
	tag: {
		height: 24,
		backgroundColor: theme.palette.custom.tagBackground,
		color: theme.palette.custom.tagText,
		width: 'fit-content',
		float: 'left',
		borderRadius: 4,
		paddingLeft: 15,
		paddingRight: 15,

		'& input': {
			border: 0,
			backgroundColor: theme.palette.custom.tagBackground,
			width: 100,
			color: theme.palette.custom.tagText,
			'&:focus': {
				outline: 'none',
			}
		}
	},
	tagTypography: {
		width: 'auto',
		float: 'left',
		fontSize: 12,
		marginTop: 3,
	},
	tagCloseBtn: {
		width: 8,
		height: 8,
		padding: 0,
		marginLeft: 8,
		'&:hover': {
			backgroundColor: 'inherit',
		}
	},
	tagViewBtn: {
		width: 16,
		height: 16,
		padding: 0,
		marginLeft: 8,
		'&:hover': {
			backgroundColor: 'inherit',
		}
	},
	tagCloseIcon: {
		fill: theme.palette.custom.tagText,
	},
});

export default withStyles(styles)(Tag);

import styled from 'styled-components';

export const LeftWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 40px;
`;

export const LeftWrapperWide = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-right: 5px;
`;

export const RightWrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  padding-left: 10px;
`;


import { TableRowProps } from '@material-ui/core/TableRow';

export type RowProps = TableRowProps & {
  $backgroundColor?: string
  $preventHover?: boolean,
  $preventPointerEvents?: boolean;
  $typeSelectedRow?: TypeSelectedRow,
}

export enum TypeSelectedRow { // When 'selected = true', you may choose type of fill. Solid by default.
  Solid = 'Solid',
  Stripes = 'Stripes',
}

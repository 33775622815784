import { KanbanView, TableView } from '@/assets';

import { ConfigItem } from './types';
import { VIEW_TYPES } from '@constants/common';

export const KANBAN_TABEL_CONFIG_VIEW: Array<ConfigItem> = [
  {
    Icon: TableView,
    value: VIEW_TYPES.table
  },
  {
    Icon: KanbanView,
    value: VIEW_TYPES.kanban
  },
];

import React, { useCallback, useEffect, useState, Fragment } from 'react';

import { getColumn } from './utils';
import { getFullName } from '@/shared/utils/fetchAutocompleteUtils';

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import { LinkedItemOption } from '@modules/LinkedItem/components';
import FetchAutoComplete from '@modules/FetchAutoComplete';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import {
  GroupChildren,
  GroupHeader,
  HeaderColumn,
  LinkedContactOuterWrapper,
  LinkedContactWrapper,
} from './styles';

import { LinkedItem as LinkedItemType } from '@/shared/types/commonTypes';
import { LinkedItemProps } from './types';


export const LinkedItem = ({
  AutocompleteProps,
  FetchProps,
  inputProps,
  PopperStyledProps,
  TextFieldProps,
  UserItemProps,
  disabled,
  initLinkedContact,
  isEntity,
  onChange,
  onLinkedContactChange = () => {},
  refreshData,
  showError,
  value,
  withEditButton = false,
  withoutQueryMode,
  withRemoveButton = true,
}: LinkedItemProps) => {
  const [ linkedContact, setLinkedContact ] = useState<LinkedItemType | null>(initLinkedContact || null);

  const onSelectContact = useCallback((data: LinkedItemType) => {
    data.id = data.linkedContactType === LINKED_ITEM_TYPES.contact ? data.contactData.id : data.id;
    setLinkedContact(data);
    onLinkedContactChange(data);
    onChange(data.id);
  },[onChange, onLinkedContactChange]);

  const removeContactFromState = useCallback(() => {
    setLinkedContact(null);
    onLinkedContactChange(null);
    onChange(null);
  }, [onChange, onLinkedContactChange]);

  const onEditContact = useCallback(() => {
    removeContactFromState();
  },[removeContactFromState]);

  useEffect(() => {
    if(String(value) === String(initLinkedContact?.id)){
      setLinkedContact(initLinkedContact);
    }
  }, [initLinkedContact, value]);

  return(
    <>
      {
        linkedContact && (
          <LinkedContactOuterWrapper $showError={ showError }>
            <LinkedContactWrapper>
              <UserItem
                innerPadding={ '0px' }
                isLinkedContactType={ true }
                onClose={ withRemoveButton ? removeContactFromState : undefined }
                onEdit={ withEditButton ? onEditContact : undefined }
                user={ {
                  ...linkedContact.contactData,
                  linkedContactType: linkedContact.linkedContactType
                } }
                withCoreClassification={ true }
                { ...UserItemProps }
              />
            </LinkedContactWrapper>
          </LinkedContactOuterWrapper>
        )
      }
      {
        !linkedContact &&
        <FetchAutoComplete
          AutocompleteProps={ {
            ListboxProps: {
              style: {
                padding: '1px 0',
                maxHeight: '50vh'
              }
            },
            groupBy: (params: any) => params.linkedContactType,
            renderGroup: (params) => {
              const columns = getColumn(params.group);
              return (
                <Fragment key={ params.key }>
                  <GroupHeader>
                    { columns.map((column) => {
                      return(
                        <HeaderColumn
                          key={ column.name }
                          $width={ column.width }
                          $align={ column.align }
                        >
                          <TextWithTooltip text={ column.name }/>
                        </HeaderColumn>
                      );
                    })}
                  </GroupHeader>
                  <GroupChildren>{params.children}</GroupChildren>
                </Fragment>
              );
            },
            renderOption: (option: any) => (
              <LinkedItemOption data={ option } isEntity={ isEntity }/>
            ),
            getOptionLabel: (option: any) => (
              option.contactData.fullName || getFullName(option.contactData) || 'label'
            ),
            ...AutocompleteProps
          } }
          FetchProps={ FetchProps }
          inputProps={ inputProps }
          PopperStyledProps={ PopperStyledProps }
          TextFieldProps={ TextFieldProps }
          disabled={ disabled }
          onChange={ onSelectContact }
          refreshData={ refreshData }
          showError={ showError }
          withoutQueryMode={ withoutQueryMode }
        />
      }
    </>
  );
};

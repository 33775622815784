import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import TextWithTooltip from '@components/TextWithTooltip';

import { useResizeObserver } from '@customHooks';

import { SelectOption, SelectPropsType, RenderItemArg } from './types';

import { getMenuProps } from './data';
import { COLORS } from '@constants/colors';

import {
  Placeholder,
  StyledFormControl,
  StyledFormErrorText,
  StyledFormHelperText,
  StyledMenuItem,
  StyledSelect
} from './styles';

export const CustomSelect = ({
  $fontSize,
  $menuItemFontSize,
  MenuListProps,
  MenuProps,
  displayEmpty=true,
  errorMessage,
  helperText,
  invalid,
  multiple = false,
  onBlur,
  onChange,
  options = [],
  placeholder = 'Please Select',
  renderItem,
  resetLabel,
  resetValue = '',
  resettable,
  showError = false,
  value,
  isMenuEqualToInputWidth = true,
  ...SelectProps
}: SelectPropsType) => {
  const [ menuWidth, setMenuWidth ] = useState<null | number>(null);
  const selectRef = useRef<null | HTMLElement>(null);

  const memoizedMenuProps = useMemo(() => (
    getMenuProps(MenuListProps, MenuProps, menuWidth)
  ), [MenuListProps, MenuProps, menuWidth]);

  const renderValue = useCallback((value: unknown): ReactNode => {
    const isMultipleValue = multiple && Array.isArray(value);

    const isValueEmpty = isMultipleValue
      ? value.length === 0
      : !value;
    if (isValueEmpty && placeholder) {
      return <Placeholder>{placeholder}</Placeholder>;
    }

    const selected = isMultipleValue
      ? options.filter((option: SelectOption) => value.includes(option.value))
      : options.find((option: SelectOption) => option.value === value);

    const valueForRender = Array.isArray(selected)
      ? selected?.map(opt => opt.label).join(',')
      : selected?.label;

    return renderItem
      ? renderItem(selected as RenderItemArg)
      :  <TextWithTooltip text={ valueForRender || '' }/> ;
  }, [multiple, options, placeholder, renderItem]);


  useResizeObserver({
    elementRef: selectRef,
    onResizeCb: ({ contentRect }) =>{
      if(!isMenuEqualToInputWidth) { return; }
      if(menuWidth !== contentRect.width){
        setMenuWidth(contentRect.width);
      }
    }
  });


  return (
    <StyledFormControl
      $showError={ showError }
      error={ invalid }
    >
      <StyledSelect
        $fontSize={ $fontSize }
        MenuProps={ memoizedMenuProps }
        displayEmpty={ displayEmpty }
        innerRef={ selectRef }
        multiple={ multiple }
        onBlur={ onBlur }
        onChange={ onChange }
        renderValue={ renderValue }
        value={ value }
        { ...SelectProps }
      >
        {
          resettable &&
          <StyledMenuItem
            $menuItemFontSize={ $menuItemFontSize }
            key={ -1 }
            value={ resetValue }
          >
            <TextWithTooltip
              $fontColor={ COLORS.grey }
              //@ts-ignore
              text={ resetLabel || placeholder }
            />
          </StyledMenuItem>
        }
        {
          options.map((option) => (
            <StyledMenuItem
              $menuItemFontSize={ $menuItemFontSize }
              disabled={ option?.disabled }
              key={ option.value }
              value={ option.value }
            >
              {
                renderItem
                  ? renderItem(option)
                  : <TextWithTooltip text={ option.label }/>
              }
            </StyledMenuItem>
          ))
        }
      </StyledSelect>
      {
        showError && errorMessage &&
        <StyledFormErrorText>{ errorMessage }</StyledFormErrorText>
      }
      {
        helperText &&
        <StyledFormHelperText>{ helperText }</StyledFormHelperText>
      }
    </StyledFormControl>
  );
};

import styled from 'styled-components';

export const OuterWrapper = styled.div``;

export const InnerWrapper = styled.div`
  overflow-y: scroll;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

export const CommonWrapper = styled.div``;

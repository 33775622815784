import React from 'react';

import Radio from '@material-ui/core/Radio';
import { CheckedRadio, UnCheckedRadio } from '@/assets';

import { CustomRadioProps } from './types';

export const CustomRadio = (props: CustomRadioProps) => {

  return (
    <Radio
      checkedIcon={ <CheckedRadio /> }
      color={ 'primary' }
      icon={ <UnCheckedRadio /> }
      size='small'
      { ...props }
    />
  );
};

import React from 'react';

import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import BlockButton from '@components/BlockButton';

import { PlusWithCircle } from '@/assets';

import { UserCustomFieldsPreHeaderProps } from './types';

import { TitleWrapper } from './styles';

export const UserCustomFieldsPreHeader = ({ onAddClick }: UserCustomFieldsPreHeaderProps) => {
  const onAddClickWrapper = () => { onAddClick (); };

  return (
    <PreTableWrapper>
      <TitleWrapper>
        User-Defined Custom Fields
      </TitleWrapper>
      <BlockButton
        $iconSize={ '16px' }
        onClick={ onAddClickWrapper }
        startIcon={ <PlusWithCircle/> }
      >
        Add Custom Field
      </BlockButton>
    </PreTableWrapper>
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const InnerRow = styled.div`
    display: flex;
    width: 617px;
    height: 45px;
    align-items: start;
`;

export const DatePickerWrapper = styled.div`
    width: 137px;
`;

export const EndDateWrapper = styled.div`
    width: 316px;
`;

export const TimePickerWrapper = styled.div`
    width: 98px;
    margin-left: 10px;
`;

export const Divider = styled.div`
  color: ${ COLORS.helpText };
  padding: 0 20px;
  margin-top: 7px;
`;

export const RecurringFrequencyWrapper = styled.div`
  margin-right: 54px;
`;

export const AllDayAndRecurringWrapper = styled.div`
    display: flex;
    margin-right: 35px;
    height: 26px;
`;

export const TimeButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 69px;
`;

import * as yup from 'yup';


import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation, fromOneToOneHundred, numberWithTwoDecimal } from '@constants/validationPatterns';

import { isDateAfter, isDateBefore } from '@/shared/utils/dateValidationUtils';
import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';

import {
  BASIC_INFORMATION_FIELDS_NAMES,
  BENEFICIARIES_ITEM_NAMES,
  BENEFICIARIES_NAMES,
  COMMON_FIELD_NAMES,
  STANDING_INSTRUCTIONS_FIELDS_NAMES,
  STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES,
  BALANCE_HISTORY_ITEM_NAMES,
  BALANCE_HISTORY_NAMES
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

import {
  BALANCE_HISTORY_TYPES
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

import { MANAGED_STATUSES } from '@modules/AccountsAndPlansPopup/data/data';

const { accountType, name, contactId } = COMMON_FIELD_NAMES;

const {
  heldAwayStatus,
  managedStatus,
  registrationType,
  applicationDate,
  submittedDate,
  effectiveDate,
  deliveryDate,
  deadlineDate,
  reviewDate,
} = BASIC_INFORMATION_FIELDS_NAMES;

const {
  amount: standingInstructionAmount,
  endDate: standingInstructionEndDate,
  startDate: standingInstructionStartDate,
  type: standingInstructionType,
} = STANDING_INSTRUCTIONS_ITEM_FIELDS_NAMES;

const { standingInstructions } = STANDING_INSTRUCTIONS_FIELDS_NAMES;

const {
  name: beneficiaryName,
  percent: beneficiaryPercent,
  relationType: beneficiaryRelationType,
  type: beneficiaryType,
} = BENEFICIARIES_ITEM_NAMES;

const { beneficiaries } = BENEFICIARIES_NAMES;

const {
  type: balanceHistoryType,
  updated: balanceHistoryUpdated,
  value: balanceHistoryValue,
  newBusinessAmount: balanceHistoryNewBusinessAmount,
} = BALANCE_HISTORY_ITEM_NAMES;

const { balanceHistory } = BALANCE_HISTORY_NAMES;

export const commonValidation = {
  [contactId]: yup.number().nullable().required(validationMessages.enterRequired),
  [name]: yup.string().required(validationMessages.enterRequired),
};

export const standingInstructionsScheme = yup.object().shape({
  [standingInstructionStartDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than standingInstructionEndDate',
      validationMessages.invalidDate,
      (value, context) => (
        isDateBefore({
          dateStringToCompare: context.parent[standingInstructionEndDate],
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [standingInstructionEndDate]: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or grater than standingInstructionStartDate',
      validationMessages.invalidDate,
      (value, context) => (
        isDateAfter({
          dateStringToCompare: context.parent[standingInstructionStartDate],
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [standingInstructionType]: yup.string().required(validationMessages.required),
  [standingInstructionAmount]: yup.string()
    .required(validationMessages.required)
    .matches(numberWithTwoDecimal, validationMessages.invalid),
});

export const beneficiariesScheme = yup.object().shape({
  [beneficiaryName]: yup.string().required(validationMessages.required),
  [beneficiaryPercent]: yup.string()
    .required(validationMessages.onlyRequired)
    .matches(fromOneToOneHundred, validationMessages.onlyInvalid),
  [beneficiaryRelationType]: yup.string().required(validationMessages.required),
  [beneficiaryType]: yup.string().required(validationMessages.required),
});

export const balanceHistoryScheme = yup.object().shape({
  [balanceHistoryType]: yup.string().required(validationMessages.required),
  [balanceHistoryNewBusinessAmount]: yup.string()
    .when(balanceHistoryType, (currenBalanceHistoryType, schema) => {
      return currenBalanceHistoryType !== BALANCE_HISTORY_TYPES.newBusiness
        ? schema.transform((value: any) => (isNaN(value) ? undefined : value)).nullable()
        : schema
          .required(validationMessages.required)
          .matches(numberWithTwoDecimal, validationMessages.invalid);
    }),
  [balanceHistoryUpdated]: yup.string()
    .required(validationMessages.required)
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .test(
      'mast be equal or less than Today',
      validationMessages.invalidDate,
      (value) => (
        isDateBefore({
          dateStringToCompare: getFormatedDateNow(),
          dateString: value,
          withEqual: true,
        })
      )
    ),
  [balanceHistoryValue]: yup.string()
    .required(validationMessages.required)
    .matches(numberWithTwoDecimal, validationMessages.invalid),
});

export const accountAndPolicyValidation = {
  ...commonValidation,
  [applicationDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [submittedDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [effectiveDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [deliveryDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [deadlineDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [reviewDate]: yup.string().nullable().matches(dateOutputValidation, validationMessages.invalidDate),
  [heldAwayStatus]: yup.string().nullable()
    .when(managedStatus, {
      is: (currentManagedStatus: string) => currentManagedStatus === MANAGED_STATUSES.heldAway,
      then: (schema) => schema.required(validationMessages.required)
    }),
  [managedStatus]: yup.string().required(validationMessages.required),
  [registrationType]: yup.string().required(validationMessages.required),
  [accountType]: yup.string().required(validationMessages.required),
  [standingInstructions]: yup.array().of(standingInstructionsScheme),
  [beneficiaries]: yup.array().of(beneficiariesScheme),
  [balanceHistory]: yup.array().of(balanceHistoryScheme)
};


import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFselect from '@modules/HookFormComponents/HFSelect';

import { useStore } from '@/shared/services/store/store';
import { calendarTimesOptions } from './helpers/helpers';

import { LABELS_WIDTH } from '@/pages/NewUserSettings/data';
import {
  AccountSettingsInformationInputNames as InputNames
} from '@pages/NewUserSettings/components/Tabs/AccontSettings/data';

import { LeftColumnWrapper, RightColumnWrapper } from '../Layout';

export function CalendarPreferences() {
  const userSettingsStore = useStore().UserSettingsStore;
  const { configData } = userSettingsStore;

  const { control } = useFormContext();

  const memoizedCalendarTimesOptions = useMemo(() => {
    return calendarTimesOptions();
  },[]);

  return (
    <FormBlock isBorderBottom={ false }>
      <FormBlock.Header title={ 'Calendar Preferences' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingLeftLabelWidth }
            label={ 'Default Calendar View' }
          >
            <HFselect
              control={ control }
              name={ InputNames.defaultCalendarView }
              options={ configData.defaultCalendarView }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
        
        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingRightLabelWidth }
            label={ 'New Activity Default Time' }
          >
            <HFselect
              control={ control }
              name={ InputNames.activityDefaultTime }
              options={ configData.activityDefaultTime }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>

      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingLeftLabelWidth }
            label={ 'Working Start Time' }
          >
            <HFselect
              control={ control }
              name={ InputNames.calendarStartTime }
              options={ memoizedCalendarTimesOptions }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingRightLabelWidth }
            label={ 'Working End Time ' }
          >
            <HFselect
              control={ control }
              name={ InputNames.calendarEndTime }
              options={ memoizedCalendarTimesOptions }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}
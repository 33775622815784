import ApiService from '@services/api/api.service';

import { GetDashboardForContactsGraph } from '@/shared/types/dashboard';

export const rebaseDashboardCache = () => {
  return ApiService.get('api/contact/rebaseDashboardCache');
};

export const getDashboardTodayActivityTask = () => {
  return ApiService.get('api/contact/getDashboardTodayActivityTask');
};

export const getDashboardForContactsGraph = (params: GetDashboardForContactsGraph) => {
  return ApiService.get('api/contact/getDashboardForContactsGraph', { params });
};

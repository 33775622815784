import { useCallback, useEffect } from 'react';

import { useStore } from '@store';

import { UseSnackbarLogicProps, UseSnackbarReturn } from '../types';

export const useSnackbarLogic = ({
  key,
  autoHideDuration
}: UseSnackbarLogicProps): UseSnackbarReturn => {
  const { removeNotification } = useStore().NotificationsStore;

  const closeNotification  = useCallback(() => {
    removeNotification(key);
  },[key, removeNotification]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (autoHideDuration !== null) {
      timeoutId = setTimeout(() => closeNotification(), autoHideDuration);
    }

    return () => timeoutId && clearTimeout(timeoutId);
  }, [closeNotification, autoHideDuration, key]);

  return {
    closeNotification
  };
};

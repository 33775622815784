import React from 'react';

import { useController } from 'react-hook-form';

import { NOTE_TYPES } from '@constants/common';

import TypeButton from '@components/TypeButton';
import RowFilter from '@components/RowFilter';

const TypeButtonStyle = {
  style: {
    padding: '5px 15px',
  }
};

export const TypeSelect = ({
  name,
  control
}) => {
  const {
    field: {
      value,
      onChange
    }
  } = useController({
    name,
    control,
  });

  return (
    <RowFilter
      initState={ value }
      items={ Object.values(NOTE_TYPES) }
      limit={ 4 }
      onChange={ onChange }
      renderItem={ ({
        content,
        isSelected,
        isSelectedFromMore,
        onClick,
        indexForKey,
      }) => (
        <TypeButton
          BtnProps={ TypeButtonStyle }
          isActive={ isSelected || isSelectedFromMore }
          key={ indexForKey }
          onClick={ onClick }
          value={ content }
        >
          {content}
        </TypeButton>
      ) }
    />
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { Contacts, Lists } from './subpages';

const NewContactsPage = () => (
  <Switch>
    <Route
      component={ Contacts }
      path={ ROUTES.contactsAll }
    />
    <Route
      component={ Lists }
      path={ ROUTES.lists }
    />
  </Switch>
);

export default observer(NewContactsPage);

import { useMemo, useCallback } from 'react';
import get from 'lodash/get';

import { DeleteTodoItem, TodoItem } from '@/shared/types/todos';
import { IdType } from '@/shared/types/commonTypes';

type UseGlobalCheckboxProps = {
  selectedIds: any,
  onGlobalCheckboxClickCallback: (arrOfIds: Array<IdType>) => void,
  pageItems: Array<TodoItem>,
}

export const useToDosRowGlobalCheckbox = ({
  onGlobalCheckboxClickCallback,
  pageItems,
  selectedIds,
}: UseGlobalCheckboxProps) => {
  const pageItemsIdsArray = useMemo(() => {
    return pageItems.filter((item) => item?.editAllow === 1).map((item: TodoItem) => {
      const itemId = get(item, 'id');
      const itemRecurring = get(item, 'requirring');
      return {
        id: itemId, withRecurring: itemRecurring
      };
    });
  }, [ pageItems ]);

  const globalCheckboxState = useMemo(() => {
    const isAllCheckedOnCurrentPage = pageItemsIdsArray.every((item: DeleteTodoItem) => {
      return selectedIds.find((task: TodoItem) => task.id === item.id);
    });
    const checked = isAllCheckedOnCurrentPage && selectedIds?.length > 0 && pageItemsIdsArray.length > 0;
    const indeterminate = !isAllCheckedOnCurrentPage && pageItemsIdsArray.some((item: DeleteTodoItem) => {
      return selectedIds.find((task: TodoItem) => task.id === item.id);
    });
    return {
      checked,
      indeterminate,
    };
  }, [ pageItemsIdsArray, selectedIds]);


  const onGlobalCheckboxClick = useCallback((event: any, state: boolean) => {
    if (state) {
      const mergePrevStateWithCurrent = [ ...selectedIds, ...pageItemsIdsArray ];
      const uniqIdsArray = Array.from(new Set(mergePrevStateWithCurrent));
      onGlobalCheckboxClickCallback(uniqIdsArray);
    } else {
      const withoutSelectedIDs = selectedIds.filter((itemId: TodoItem) => {
        return !pageItemsIdsArray.find((task: DeleteTodoItem) => task.id === itemId.id);
      });
      onGlobalCheckboxClickCallback(withoutSelectedIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pageItemsIdsArray, selectedIds ]);

  return {
    isGlobalCheckboxChecked: globalCheckboxState.checked,
    isGlobalCheckboxIndeterminate: globalCheckboxState.indeterminate,
    onGlobalCheckboxClick,
  };
};

import { StandingInstructionsItem } from '@modules/AccountsAndPlansPopup/types';

import {
  STANDING_INSTRUCTIONS_TYPES
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonStandingInstructions/data';

export const generateNewStandingInstructionItem = (sortValue: number): StandingInstructionsItem => {
  return {
    amount: '',
    endDate: '',
    sort: sortValue,
    startDate: '',
    type: STANDING_INSTRUCTIONS_TYPES.premiumPayment,
  };
};
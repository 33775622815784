import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarWrapper = styled.div`
  margin-right: 10px;
`;

export const ClassificationWrapper = styled.div`
  margin-top: 3px;
  margin-right: 5px;
  margin-left: 10px;
  
    & .MuiListItemIcon-root{
      min-width: unset;
    }
`;
export const ContactName = styled.span`
  max-width: 200px;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

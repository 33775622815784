import React from 'react';

import { Wrapper } from './styles';

import { FooterCountInfoProps } from './types';

export const FooterCountInfo = ({
  from = 0,
  to = 0,
  total = 0
}: FooterCountInfoProps) => (
  <Wrapper>
    { `Displaying ${ from } - ${ to } of ${ total }` }
  </Wrapper>
);
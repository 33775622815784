import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    position: relative;
    width: 740px;
    padding: 30px 30px 15px 30px;
    overflow-x: hidden;
`;

export const DisableOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: ${ COLORS.white };
    opacity: 0.5;
`;

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';

import { SalesActiveCycleFormFields } from './types';

import { SALES_ACTIVE_CYCLE_STAGES, SALES_ACTIVE_CYCLE_STATUS } from '@constants/salesActiveCycle';

export const LEFT_LABEL_WIDTH = 144;
export const RIGHT_LABEL_WIDTH = 112;

export const SALES_ACTIVE_POPUP_DEFAULT_VALUES:SalesActiveCycleFormFields = {
  amount: null,
  contactId: null,
  cycleOpenDate: getFormatedDateNow(),
  id: null,
  keyNote: null,
  meetingStatus: null,
  notes: [],
  salesCycleStatus: SALES_ACTIVE_CYCLE_STATUS.active,
  sourceId: null,
  stage: SALES_ACTIVE_CYCLE_STAGES.firstAppt,
  wonDate: null
};

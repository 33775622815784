export const DESCRIPTION_INPUT_PROPS = {
  style: {
    maxHeight: '200px',
    minHeight: '50px',
    margin: '-9px -5px',
    resize: 'vertical' as any
  },
  placeholder: 'Enter Description',
  maxLength: 2000,
};

export const TASK_NAME_INPUT_PROPS = {
  placeholder: 'Enter Task Name',
  maxLength: 150,
};

export const DESCRIPTION_TEXT_FIELD__PROPS = {
  multiline: true,
  rows: 3
};

/* eslint-disable */
export default {
    PRIMARY: {
        LIGHT: '#FFB24A',
        MAIN: '#EA8213',
        DARK: '#b15400',
        CONTRAST_TEXT: '#fff'
    },
    SECONDARY: {
        LIGHT: '#4f5a6b',
        MAIN: '#263140',
        DARK: '#00081a',
        CONTRAST_TEXT: '#fff'
    },
    ERROR: {
        MAIN: '#E24141'
    },
    HIGHLIGHTED_CARD: {
        NEXT: '#9B93EF'
    },
    INFO_POPUP: {
        MAIN: '#7D77D4'
    }
};
import {
  EVENT_VIEW_TYPE,
  EVENT_CARD_BREAKPOINTS,
} from './data';

export const getEventViewType = (width, height) => {
  if(width > EVENT_CARD_BREAKPOINTS.minColumnWidth && height > EVENT_CARD_BREAKPOINTS.minColumnHeight) {
    return  EVENT_VIEW_TYPE.column;
  }


  if(width > EVENT_CARD_BREAKPOINTS.minLineWidth) {
    if(height >= EVENT_CARD_BREAKPOINTS.minLineHeight && height < EVENT_CARD_BREAKPOINTS.maxLineHeight) {
      return  EVENT_VIEW_TYPE.line;
    }

    if(height >= EVENT_CARD_BREAKPOINTS.minHeightLineHeight && height < EVENT_CARD_BREAKPOINTS.maxHeightLineHeight) {
      return  EVENT_VIEW_TYPE.highLine;
    }
  }


  return EVENT_VIEW_TYPE.empty;
};

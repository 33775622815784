import { ColumnType } from '@components/Table/components/Header/types';


export const COLUMNS: Array<ColumnType> = [
  {
    id: 'name',
    label: 'Name',
    style: {
      width: '40%'
    },
    sortable: true,
  },
  {
    id: 'typeList',
    label: 'List Type',
    style: {
      width: '16%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'listOwner',
    label: 'List Owner',
    style: {
      width: '16%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'listContacts',
    label: 'Linked Contacts',
    style: {
      width: '24%'
    },
    separator: true,
  },
];

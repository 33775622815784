import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import SalesActiveCycleDeletePopup from '@modules/SalesPipline/SalesActiveCycleDeletePopup';
import SalesActiveCycleFilters from '@modules/SalesPipline/SalesActiveCycleFilters';
import SalesActivePopup from '@modules/SalesPipline/SalesActivePopup';
import SalesActivePreHeader from '@modules/SalesPipline/SalesActivePreHeader';
import Surface from '@components/BasicLayoutComponents/Surface';

import { SalesActiveCycleTable, SalesActiveCycleKanban } from './components';

import { MODAL_TYPE } from '@constants/modalTypes';
import { VIEW_TYPES } from '@constants/common';

import {
  NestedStoreTableOrKanban,
  SalesCycleDeleteData,
  SavedSalesActiveCycleType
} from '@/shared/types/salesActiveCycle';

export const SalesActiveCycles = observer(() => {
  const { currentView, nestedStore, resetStore } = useStore().SalesCycleStore;
  const {
    isFiltersOpen,
    onSave,
    onRemove
  } = nestedStore as unknown as NestedStoreTableOrKanban;

  const modalStore = useStore().ModalStore;

  const onDeleteWrapper = useCallback((data: SalesCycleDeleteData) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_SALES_ACTIVE_CYCLE,
      component: SalesActiveCycleDeletePopup,
      modalProps: {
        onConfirm: onRemove,
        deleteData: data
      }
    });
  }, [modalStore, onRemove]);


  const openSalesActiveCyclePopup = useCallback((id?: SavedSalesActiveCycleType['id']) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_SALES_ACTIVE_CYCLE,
      component: SalesActivePopup,
      modalProps: {
        id,
        onSaveCallback: onSave,
        onDeleteCallback: onDeleteWrapper
      }
    });
  }, [modalStore, onDeleteWrapper, onSave]);

  useEffect(() => {
    return resetStore;
  }, [resetStore]);

  return (
    <Surface.Content $isWrap={ false }>
      <Surface.Main>
        <SalesActivePreHeader
          onAddClick={ openSalesActiveCyclePopup }
          onDelete={ onDeleteWrapper }
        />
        {
          currentView === VIEW_TYPES.table &&
          <SalesActiveCycleTable
            onDelete={ onDeleteWrapper }
            onEdit={ openSalesActiveCyclePopup }
          />
        }
        {
          currentView === VIEW_TYPES.kanban &&
          <SalesActiveCycleKanban
            onDelete={ onDeleteWrapper }
            onEdit={ openSalesActiveCyclePopup }
          />
        }
      </Surface.Main>
      {
        isFiltersOpen && (
          <Surface.Side>
            <SalesActiveCycleFilters/>
          </Surface.Side>
        )
      }
    </Surface.Content>
  );
});

import { ValueLabelObj } from '@/shared/types/commonTypes';
import {
  ActivityCreationSettings,
  OptionIdConverter,
  ActivityCreationSettingsResponseData
} from '@modules/ActivityPopup/store/types';

const optionIdConverter = (data: OptionIdConverter): ValueLabelObj => ({
  value: data.id,
  label: data.option,
});

const recurringFrequencyConverter = (rule: string): ValueLabelObj => ({
  value: rule,
  label: rule,
});

export const activityCreationSettingsNormalizer = (
  data: ActivityCreationSettingsResponseData
): ActivityCreationSettings => ({
  recurringFrequency: data.recurringFrequency.map(recurringFrequencyConverter),
  statuses: data.statusId.map(optionIdConverter),
  subCategories: data.subcategoryId.map(optionIdConverter),
});
import React, { useState, useCallback, useMemo } from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { CustomCheckboxWithoutLabel, SIZES } from '@components/CustomCheckbox';

import { CaretDown, CaretUp } from '@/assets';

import {
  GlobalCheckboxWrapper,
  HeadCellSeparator,
  StyledTableCell,
  StyledTableCellWithControls,
  TableHeadCellContentWrapper,
  TableSortLabel
} from './styles';

import { HeaderPropsType, TableSortLabelWrapperProps } from './types';
import { MultipleSortingState } from '@/shared/types/commonTypes';

const TableSortLabelWrapper = ({
  label,
  id,
  onSortChangeCb,
  isActive,
  sortDirection,
}: TableSortLabelWrapperProps) => {
  const onClick = useCallback(() => {
    onSortChangeCb && onSortChangeCb(id);
  },[id, onSortChangeCb]);

  const Icon = useCallback(() => {
    if(!sortDirection){
      return <CaretUp />;
    }

    return  sortDirection === 'asc' ? <CaretUp /> : <CaretDown />;
  }, [sortDirection]);


  return(
    <TableSortLabel
      IconComponent={ Icon }
      active={ isActive }
      onClick={ onClick }
    >
      { label }
    </TableSortLabel>
  );
};

export const Header = ({
  columns,
  globalCheckboxProps,
  onSortChangeCb,
  sortingState,
  withGlobalCheckbox,
  withBookmarkPlaceholder
}: HeaderPropsType) => {

  return (
    <TableHead>
      <TableRow>
        {
          withGlobalCheckbox && globalCheckboxProps &&
          <StyledTableCellWithControls padding={ 'none' }>
            <GlobalCheckboxWrapper>
              <CustomCheckboxWithoutLabel
                $size={ SIZES.medium }
                $withPaddings={ false }
                checked={ globalCheckboxProps.checked }
                indeterminate={ globalCheckboxProps.indeterminate }
                onChange={ globalCheckboxProps.onChange }
              />
            </GlobalCheckboxWrapper>
          </StyledTableCellWithControls >
        }
        {
          withBookmarkPlaceholder && <StyledTableCellWithControls padding={ 'none' }/>
        }
        {
          columns.map((column) => (
            <StyledTableCell
              align={ column.align }
              key={ column.id }
              padding={ column.padding || 'none' }
              style={ column.style || {} }
            >
              <TableHeadCellContentWrapper>
                {
                  column.separator &&
                  <HeadCellSeparator/>
                }
                {
                  column.sortable
                    ? (
                      <TableSortLabelWrapper
                        id={ column.id }
                        isActive={ Boolean(sortingState && column.id in sortingState) }
                        key={ column.id }
                        label={ column.label }
                        onSortChangeCb={ onSortChangeCb }
                        sortDirection={ sortingState?.[column.id as keyof MultipleSortingState] }
                      />
                    )
                    : column.label
                }
              </TableHeadCellContentWrapper>
            </StyledTableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import InfoIcon, { INFO_COLOR_SCHEME, INFO_ICON_TYPES } from '@components/InfoIcon';

import { ADD_SPOUSE_FIELD_NAMES, ADD_SPOUSE_TYPES, SPOUSE_TYPE_OPTIONS } from '../../data';

import { SpouseTypeFieldProps } from './types';

const INFO_ICON_TEXT = `Two spouses who handle their finances together as a Household, 
combined and viewed as a single record.`;

export const SpouseTypeField = ({ labelWidth }: SpouseTypeFieldProps) => {
  const { control } = useFormContext();

  const renderLabelContent = useCallback(() => (
    <InfoIcon
      $tooltipMaxWidth={ '340px' }
      colorScheme={ INFO_COLOR_SCHEME.mainReversed }
      helpText={ INFO_ICON_TEXT }
      type={ INFO_ICON_TYPES.alertInfo }
      wrapperProps={ {
        marginLeft: '8px'
      } }
    />
  ),[]);

  return (
    <FormBlock.FormField
      $labelWidth={ labelWidth }
      label={ 'Spouse Type' }
      renderLabelContent={ renderLabelContent }
    >
      <HFSelect
        ControllerProps={ {
          defaultValue: ADD_SPOUSE_TYPES.combineHousehold
        } }
        control={ control }
        name={ ADD_SPOUSE_FIELD_NAMES.spouseType }
        options={ SPOUSE_TYPE_OPTIONS }
      />
    </FormBlock.FormField>
  );
};

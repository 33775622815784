import { BeneficiariesItem } from '@modules/AccountsAndPlansPopup/types';

import { BENEFICIARIES_TYPES } from './data';

export const generateBeneficiariesItem= (sortValue: number): BeneficiariesItem => {
  return {
    name: '',
    percent: 0,
    relationType: '',
    type: BENEFICIARIES_TYPES.primary,
    sort: sortValue,
  };
};

import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFPhoneField from '@modules/HookFormComponents/HFPhoneField';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { maxInputLength } from '@/shared/utils/maxInputLength';

import {
  CONTACTS_CONTACT_PREFERENCE_IDS,
  CONTACTS_DATA_FIELD_NAMES,
  CONTACTS_EMAIL_IDS,
  CONTACTS_LINK_IDS,
  CONTACTS_LOCATION_IDS,
  CONTACTS_PHONE_IDS,
  CONTACT_PREFERENCE_OPTIONS,
  FIELD_IDS
} from '@constants/contactsData';


import { LocationWrapper } from './styles';

import { FieldProps } from './types';

export const Field = ({
  field,
  index,
  parentName,
}: FieldProps) => {
  const { states } = useStore().SettingsStore;

  const { control } = useFormContext();

  const generateName = useCallback((index: number, chunk: string) => {
    return `${parentName}.${index}.${chunk}`;
  }, [parentName]);

  return (
    <>
      {
        field.id &&
        <HFHiddenInput
          control={ control }
          defaultValue={ field.id }
          name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.id) }
        />
      }
      <HFHiddenInput
        control={ control }
        defaultValue={ field.fieldId }
        name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.fieldId) }
      />
      {
        CONTACTS_EMAIL_IDS.includes(field.fieldId as FIELD_IDS) &&
        <HFTextInput
          control={ control }
          name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.value) }
          placeholder={ 'Enter Email Address' }
        />
      }
      {
        CONTACTS_PHONE_IDS.includes(field.fieldId as FIELD_IDS) &&
        <HFPhoneField
          control={ control }
          countryListWidth={ 260 }
          name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.value) }
        />
      }
      {
        CONTACTS_LINK_IDS.includes(field.fieldId as FIELD_IDS) &&
        <HFTextInput
          control={ control }
          name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.value) }
          placeholder={ 'Enter Link' }
        />
      }
      {
        CONTACTS_LOCATION_IDS.includes(field.fieldId as FIELD_IDS) &&
        <>
          <HFTextInput
            control={ control }
            inputProps={ maxInputLength(50) }
            name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.street) }
            placeholder={ 'Address Line 1' }
          />
          <HFTextInput
            control={ control }
            inputProps={ maxInputLength(50) }
            name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.street2) }
            placeholder={ 'Address Line 2' }
          />
          <LocationWrapper>
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(50) }
              name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.city) }
              placeholder={ 'Enter City' }
            />
            <HFSelect
              MenuListProps={ {
                style: {
                  minWidth: '200px'
                }
              } }            
              control={ control }
              isMenuEqualToInputWidth={ false }
              name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.state) }
              options={ states }
              placeholder={ 'State' }
            />
            <HFTextInput
              control={ control }
              inputProps={ maxInputLength(9) }
              name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.zip) }
              placeholder={ 'Zip' }
            />
          </LocationWrapper>
        </>
      }
      {
        CONTACTS_CONTACT_PREFERENCE_IDS.includes(field.fieldId as FIELD_IDS) &&
        <HFSelect
          control={ control }
          name={ generateName(index, CONTACTS_DATA_FIELD_NAMES.value) }
          options={ CONTACT_PREFERENCE_OPTIONS }
          resettable={ true }
        />
      }
    </>
  );
};

import React from 'react';
import CustomTextField from '@components/CustomTextField';
import { Search } from '@/assets';

export const SearchField = (props) => {
  return (
    <CustomTextField
      { ...props }
      TextFieldProps={ {
        InputProps: {
          endAdornment: <Search/>
        }
      } }
    />
  );
};

import React, { useCallback } from 'react';

import PopupSingleActionButton from '@components/PopupSingleActionButton';

import { SalesPipelineQuickActionButtonProps } from './types';

const QUICK_ACTION_BUTTON_STYLE = {
  minWidth: 'auto',
  marginRight: '12px'
};

export const SalesPipelineQuickActionButton = ({
  $color,
  $bgHoverColor,
  disabled,
  Icon,
  label,
  onClick,
  value,
}: SalesPipelineQuickActionButtonProps) => {

  const onClickWrapper = useCallback(() => {
    onClick(value);
  },[onClick, value]);

  return (
    <PopupSingleActionButton
      $bgHoverColor={ $bgHoverColor }
      $color={ $color }
      Icon={ Icon }
      disabled={ disabled }
      onClick={ onClickWrapper }
      style={ QUICK_ACTION_BUTTON_STYLE }
    >
      {label}
    </PopupSingleActionButton>
  );
};

import React from 'react';

import { getActiveClientByName } from '@services/api/common/common';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';

import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';

import { HFUserSingleSelectProps } from './types';

const LISTBOX_PROPS = {
  style: {
    maxHeight: '240px'
  }
};


export const HFUserSingleSelect = ({
  ControllerProps,
  UserItemProps,
  control,
  initLinkedContact,
  name,
  placeholder = 'Start type to search',
}: HFUserSingleSelectProps) => {
  return (
    <HFLinkedContact
      AutocompleteProps={ {
        ListboxProps: LISTBOX_PROPS
      } }
      ControllerProps={ ControllerProps }
      FetchProps={ {
        getParams: getNameParam,
        requestSender: getActiveClientByName,
        responseConverter: usersResponseConverter,
      } }
      TextFieldProps={ {
        placeholder,
      } }
      UserItemProps={ {
        disabled: false,
        ...UserItemProps
      } }
      control={ control }
      disabled={ false }
      initLinkedContact={ initLinkedContact }
      name={ name }
      withEditButton={ true }
      withoutQueryMode={ true }
      withRemoveButton={ false }
    />
  );
};

import { addDecimalToNumString } from '@/shared/utils/addDecimalToNumString';
import { numberStringToLocaleString } from '@/shared/utils/numberStringToLocaleString';

export const formatNumberToAmount = (amount?: string | number | null): string => {
  if(!amount){
    return '$0.00';
  }

  const withDecimal = addDecimalToNumString(amount);

  return `$${numberStringToLocaleString({ value: withDecimal.toString() })}`;
};

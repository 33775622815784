import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useSalesClientOpportunityPopupStore } from '../../store';

import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFLinkedContact from '@modules/HookFormComponents/HFLinkedContact';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import NextApptDate from '@modules/NextApptDate';
import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';

import { LeftWrapper,RightWrapper } from '../../styles';

import { LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH } from '../../data';
import {
  AMOUNT_INPUT_PROP,
  AMOUNT_TEXT_FIELD_PROP,
  getTextAreaProps,
  TEXT_FIELD_KEY_NOTE_PROP
} from '@constants/salesPipeline';
import { ERROR_SPACE_VALUE } from '@constants/style';

import {
  OPPORTUNITY_FIELD_NAMES,
  OPPORTUNITY_STATUS_OPTIONS,
  OPPORTUNITY_STAGES_OPTIONS,
  OPPORTUNITY_SALE_TYPES_OPTIONS,
  OPPORTUNITY_MEETING_STATUS_OPTIONS
} from '@constants/salesCycleOpportunity';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getAppointmentDateLabel } from '@/shared/utils/getApptDateLabel';
import { getContactWithSpouseHousehold } from '@services/api/common/common';
import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';


const INPUT_TEXT_AREA_PROPS  = getTextAreaProps(3);

const LinkedContactFetchProps = {
  getParams: getNameParam,
  requestSender: getContactWithSpouseHousehold,
  responseConverter: usersResponseConverter,
};

export const PrimaryDetails = observer(() => {
  const { control } = useFormContext();

  const {
    isLoad,
    linkedContactData,
    nextAppointmentDate,
    onLinkedContactChange,
    notesUtilsStore,
  } = useSalesClientOpportunityPopupStore();

  const isDisabled = notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad;

  const appointmentDateLabel = getAppointmentDateLabel(nextAppointmentDate);

  return (
    <FormBlock
      marginBottom={ 20 }
      paddingBottom={ 15 }
    >
      <FormBlock.Header title={ 'Primary Details' } />
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_LABEL_WIDTH }
          isRequired={ true }
          label={ 'Linked Contact' }
        >
          <HFLinkedContact
            FetchProps={ LinkedContactFetchProps }
            UserItemProps={ {
              disabled: notesUtilsStore.isNotesPanelInAddOrEditMode,
            } }
            control={ control }
            disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode }
            initLinkedContact={ linkedContactData }
            name={ OPPORTUNITY_FIELD_NAMES.contactId }
            onLinkedContactChange={ onLinkedContactChange }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Opportunity Status' }
          >
            <HFSelect
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.opportunityStatus }
              options={ OPPORTUNITY_STATUS_OPTIONS }
              placeholder={ 'Please Select' }
              renderItem={ SalesClientOpportunitiesStatusOption }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            isRequired={ true }
            label={ 'Stage' }
          >
            <HFSelect
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.stage }
              options={ OPPORTUNITY_STAGES_OPTIONS }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Sale Type' }
          >
            <HFSelect
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.saleType }
              options={ OPPORTUNITY_SALE_TYPES_OPTIONS }
              resettable={ true }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Amount ($)' }
          >
            <HFTextInput
              inputProps={ AMOUNT_INPUT_PROP }
              TextFieldProps={ AMOUNT_TEXT_FIELD_PROP }
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.amount }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Opp. Open Date' }
          >
            <HFDatePicker
              control={ control }
              disabled={ isDisabled }
              name={ OPPORTUNITY_FIELD_NAMES.opportunityOpenDate }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Meeting Status' }
          >
            <HFSelect
              control={ control }
              disabled={ isDisabled || Boolean(nextAppointmentDate) }
              name={ OPPORTUNITY_FIELD_NAMES.meetingStatus }
              options={ OPPORTUNITY_MEETING_STATUS_OPTIONS }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper paddingBottom={ `${ERROR_SPACE_VALUE}px` }>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ appointmentDateLabel }
          >
            <FormBlock.VerticalCenteredWrapper>
              <NextApptDate
                date={ nextAppointmentDate }
                disabled={ isDisabled }
                fallBack={ '-' }
              />
            </FormBlock.VerticalCenteredWrapper>
          </FormBlock.FormField>
        </LeftWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LEFT_LABEL_WIDTH }
          label={ 'Key Note/Next Step ' }
        >
          <HFTextInput
            inputProps={ INPUT_TEXT_AREA_PROPS }
            TextFieldProps={ TEXT_FIELD_KEY_NOTE_PROP }
            control={ control }
            disabled={ isDisabled }
            name={ OPPORTUNITY_FIELD_NAMES.keyNote }
            placeholder={ 'Key Note/Next Step' }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Onboarding' }
          >
            <HFToggle
              control={ control }
              disabled={ isDisabled }
              labelPlacement={ 'end' }
              name={ OPPORTUNITY_FIELD_NAMES.onboarding }
              valueInConverter={ yesNoToBoolean }
              valueOutConverter={ booleanToYesNo }
            />
          </FormBlock.FormField>
        </LeftWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
});



import React from 'react';
import { observer } from 'mobx-react';
import { Popover } from '@material-ui/core';

import {
  anchorOriginProps,
  paperProps,
  transformOriginProps
} from './data';

import {
  SubcategoryItem,
  SubcategoryItems,
} from './styles';

import { SubcategoriesListProps } from './types';

export const SubcategoriesList = observer(({
  allButtonLabel,
  anchorEl,
  onClickSubcategory,
  onClose,
  selectedCategory,
  selectedSubcategory,
  subCategoryOptions,
}: SubcategoriesListProps) => {

  const isOpen = Boolean(anchorEl);

  return (
    <Popover
      PaperProps={ paperProps }
      anchorEl={ anchorEl }
      anchorOrigin={ anchorOriginProps }
      onClose={ onClose }
      open={ isOpen }
      transformOrigin={ transformOriginProps }
    >
      <SubcategoryItems>
        {
          Object.values(subCategoryOptions).map((value, index) => {
            return (
              <SubcategoryItem
                $isActive={ value === selectedCategory || value === selectedSubcategory }
                $isBorderBottom={ Boolean(allButtonLabel) && index === 0 }
                key={ index }
                onClick={ (event) => onClickSubcategory(event, value) }
              >
                { value }
              </SubcategoryItem>
            );
          })
        }
      </SubcategoryItems>
    </Popover>
  );
});

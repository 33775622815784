export const DESCRIPTION_FIELD_INPUT_PROPS = {
  maxLength: 250,
  rows: 4,
  style: {
    margin: '-10px -5px'
  }
};


export const DESCRIPTION_FIELD_TEXT_PROPS = {
  multiline: true,
};

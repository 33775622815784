import React, { useCallback } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { useStore } from '@store';
import { observer } from 'mobx-react';

import CustomTabs from '@components/CustomTabs';
import Surface from '@components/BasicLayoutComponents/Surface';

import { TRASH_TABS } from '@pages/Trash/data';

import { PageHeaderProps } from '@pages/Trash/types';


export const PageHeader = observer(({
  isFiltersBtnActive,
  onFiltersClick
}: PageHeaderProps) => {
  const trashStore = useStore().TrashStore;
  const { selectedFiltersCount } = trashStore;

  const match = useRouteMatch();

  const onBadgeClick = useCallback(() => trashStore.resetFilters(), [ trashStore ]);

  return (
    <Surface.PreHeader
      $height={ '64px' }
      badgeClickHandler={ onBadgeClick }
      badgeCount={ selectedFiltersCount }
      buttonCLickHandler={ onFiltersClick }
      enableFilterMode={ !isFiltersBtnActive }
      withFilterButton={ true }
    >
      <CustomTabs  value={ `${match.url}` }>
        {
          TRASH_TABS.map(tab => (
            <CustomTabs.Tab
              $fontSize={ '16px' }
              $minHeight={ '64px' }
              component={ Link }
              icon={ <tab.icon/> }
              key={ tab.link }
              label={ tab.label }
              to={ tab.link }
              value={ tab.link }
            />
          ))
        }
      </CustomTabs>
    </Surface.PreHeader>
  );
});


import { IdType } from '@/shared/types/commonTypes';

import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import {
  ClientOpportunityFilterDataType,
  ConvertedClientOpportunityFilterDataType
} from '@/shared/types/salesCycleOpportunity';
import { OPPORTUNITY_FILTER_NAMES } from '@constants/salesCycleOpportunity';

export const convertFilterData = (
  data: ClientOpportunityFilterDataType,
  currentUserId: IdType
): ConvertedClientOpportunityFilterDataType => {
  return {
    [OPPORTUNITY_FILTER_NAMES.users]: getSortedUserOption(data.createdBy, currentUserId),
    [OPPORTUNITY_FILTER_NAMES.saleType]: optionsFromValue(data.saleType),
    [OPPORTUNITY_FILTER_NAMES.meetingStatus]: optionsFromValue(data.meetingStatus),
    [OPPORTUNITY_FILTER_NAMES.opportunityStatus]: optionsFromValue(data.opportunityStatus),
  };
};

import React from 'react';

import MuiRadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { Label } from './styles';

export const RadioGroup = ({
  RadioGroupProps,
  FormControlLabelProps,
  RadioProps,
  options,
  onChange,
  value,
}) => {
  return (
    <MuiRadioGroup
      onChange={ onChange }
      value={ value }
      { ...RadioGroupProps }
    >
      {
        options.map(({ label, value }) => (
          <Label
            control={
              <Radio
                color='primary'
                { ...RadioProps }
              />
            }
            key={ label }
            label={ label }
            labelPlacement={ 'end' }
            value={ value }
            { ...FormControlLabelProps }
          />
        ))
      }
    </MuiRadioGroup>
  );
};

import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';

import { useStore } from '@store';

import { getBalanceValue } from './utils';

import { ACCOUNTS_AND_PLANS_STATUSES_COLOR_MAP } from '@constants/accountsAndPlanData';
import { COLORS } from '@constants/colors';
import { MODAL_TYPE } from '@constants/modalTypes';

import { OutOfSightTD } from '@components/OutOfSight';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import LimitAccessPopup from '@modules/LimitAccessPopup';
import LinkedContactCell from '@modules/LinkedContactCell';
import ListItemWithColorIndicator, { INDICATOR_TYPES } from '@components/ListItemWithColorIndicator';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { AccountAndPlansRowProps } from './types';
import { DotsMenuItemTypes } from '@components/DotsMenu/types';

const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'accounts-and-plans-name';

export const AccountAndPlansRow = memo(({
  accountAndPlansItem,
  isCheckboxActive,
  isLinkedContactClickable,
  onCheckboxClickCb,
  onDeleteAction,
  onEditAction,
  onRowBookmarkCb
}: AccountAndPlansRowProps) => {
  const modalStore = useStore().ModalStore;

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.delete ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.delete ]
    })
  ), []);

  const onBookmarkClick = useCallback((state: boolean) => {
    onRowBookmarkCb(accountAndPlansItem.id, state);
  }, [ onRowBookmarkCb, accountAndPlansItem ]);

  const onEditWrapper = useCallback(() => {
    onEditAction(accountAndPlansItem.id);
  }, [ onEditAction, accountAndPlansItem.id ]);

  const balanceValue = useMemo(() => (
    getBalanceValue(accountAndPlansItem.accountType, accountAndPlansItem.balance)
  ), [accountAndPlansItem]);

  const onMenuSelect = useCallback((action: DotsMenuItemTypes) => {
    if (action === DOTS_MENU_ITEM_TYPES.edit) {
      onEditAction(accountAndPlansItem.id);
    }

    if (action === DOTS_MENU_ITEM_TYPES.delete) {
      onDeleteAction([accountAndPlansItem.id]);
    }
  }, [accountAndPlansItem.id, onDeleteAction, onEditAction]);

  const onCheckboxClick = useCallback(() => {
    onCheckboxClickCb(accountAndPlansItem);
  }, [ onCheckboxClickCb, accountAndPlansItem ]);

  const isBlocked = typeof accountAndPlansItem?.contactData?.editAllow === 'number' &&
    !accountAndPlansItem?.contactData?.editAllow;

  const onOutOfSightClick = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
      component: LimitAccessPopup,
      modalProps: {
        withPushToContacts: false
      }
    });
  }, [ modalStore ]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ onEditWrapper }
      role='checkbox'
      tabIndex={ -1 }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: accountAndPlansItem.flagged,
          onStateChange: onBookmarkClick,
        } }
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClick
        } }
      />
      <Table.Cell
        dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
        onClick={ onEditWrapper }
      >
        <TextWithTooltip
          dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
          text={ accountAndPlansItem.name }
        />
      </Table.Cell>
      <LinkedContactCell
        $color={ isLinkedContactClickable ? COLORS.orange : COLORS.primaryText }
        $isClickable={ isLinkedContactClickable }
        contact={ accountAndPlansItem.linkedContact }
      />
      <Table.Cell>
        <TextWithTooltip text={ accountAndPlansItem?.accountNumber || '-' } />
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ balanceValue } />
      </Table.Cell>
      <Table.Cell>{ accountAndPlansItem.managedStatus || '-' }</Table.Cell>
      <Table.Cell>
        {
          accountAndPlansItem.status
            ? (
              <ListItemWithColorIndicator
                indicatorColor={ ACCOUNTS_AND_PLANS_STATUSES_COLOR_MAP[accountAndPlansItem.status] }
                label={ accountAndPlansItem.status }
                type={ INDICATOR_TYPES.square }
              />
            )
            : '-'
        }
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});

AccountAndPlansRow.displayName = 'AccountAndPlansRow';

import React from 'react';
import { useController } from 'react-hook-form';

import TextEditor from '@components/TextEditor';

import { TextEditorProps } from './types';

export const HFTextEditor = ({
  control,
  name,
  textEditorProps = {}
}: TextEditorProps) => {
  const {
    field: {
      value,
      onChange
    }
  } = useController({
    name,
    control,
  });

  return (
    <TextEditor
      onChange={ onChange }
      value={ value || '' }
      { ...textEditorProps }
    />
  );
};

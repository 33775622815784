import ApiService from '@services/api/api.service';

const getTodos = (params) => {
  return ApiService.get('api/task/getForGrid', { params });
};

const getGridForContact = (params) => {
  return ApiService.get('api/task/getGridContact', { params });
};

const massFlagUpdate = (data) => {
  return ApiService.patch('api/task/updateFlags', data);
};

const updateFromGrid = (data) => {
  return ApiService.patch('/api/task/updateGrid', data);
};

export {
  getTodos,
  getGridForContact,
  massFlagUpdate,
  updateFromGrid,
};

import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { FieldsFactory } from '../FieldsFactory';
import FormBlock from '@components/FormBlock';

import { KEY_NAME } from '../../data';
import {
  LEFT_COLUMN_WIDTH,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/data';

import { ForceFieldsType } from '../../types';
import { FieldsFactoryWrapperProps } from './types';


export const FieldsFactoryWrapper = ({
  headOfHouseholdOptions,
  householdMembers,
  onPrimaryClick,
  parentName
}: FieldsFactoryWrapperProps) => {

  const { control } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    keyName: KEY_NAME,
    name: parentName
  });

  const onDeleteClick = useCallback((value: number) => {
    remove(value);
  }, [remove]);

  return (
    <FormBlock isBorderBottom={ false }>
      <FieldsFactory
        fields={ fields as ForceFieldsType }
        headOfHouseholdOptions={ headOfHouseholdOptions }
        householdMembers={ householdMembers }
        isHousehold={ true }
        labelWidth={ LEFT_COLUMN_WIDTH }
        onDeleteClick={ onDeleteClick }
        onPrimaryClick={ onPrimaryClick }
        parentName={ parentName }
      />
    </FormBlock>
  );
};

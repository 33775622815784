import { ColumnType } from '@components/Table/components/Header/types';

export const COLUMNS: Array<ColumnType> = [
  {
    id: 'name',
    label: 'Linked Contact',
    style: {
      width: '16%',
      boxSizing: 'border-box'
    },
    sortable: true,
  },
  {
    id: 'saleType',
    label: 'Sale Type',
    style: {
      width: '10%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'opportunityStatus',
    label: 'Opportunity Status',
    style: {
      width: '9%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'stage',
    label: 'Stage',
    style: {
      width: '105px',
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'meetingStatus',
    label: 'Meeting Status',
    style: {
      width: '9%'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'amount',
    label: 'Amount',
    style: {
      width: '70px'
    },
    separator: true,
    sortable: true,
  },
  {
    id: 'tasksCount',
    label: '# Tasks',
    style: {
      width: '5%'
    },
    separator: true,
  },
  {
    id: 'keyNote',
    label: 'Key Note / Next Step',
    style: {
      width: '26%'
    },
    separator: true,
  },
];

import styled from 'styled-components';

import MuiRadio from '@material-ui/core/Radio';

export const Container = styled.span`
    margin-bottom: 15px;
`;

export const Radio = styled(MuiRadio)`
    &.MuiRadio-root {
        margin-top: 7px;
        padding: unset;
    }
`;
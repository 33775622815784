import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import CustomTextField, { useTextFieldState } from '@components/CustomTextField';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip/Tooltip';

import { FolderStroke } from '@/assets';

import { NEW_FOLDER_FRONT_ID_KEY } from '@constants/lists';

import {
  InputWrapper,
  ItemIconWrapper,
  ItemNameWrapper,
  ItemWrapper
} from './styles';

import {
  ItemProps,
  ItemsProps
} from './types';
import { Simulate } from 'react-dom/test-utils';

export const Item = ({
  $isActive,
  $level,
  editIndex,
  item,
  listItem,
  onBlurAccept,
  setEditIndex,
  setSelectedIndex
}:ItemProps) => {
  const { textFieldValue, setTextFieldValue, setForceTextFieldValue } = useTextFieldState({
    initValue: item.name,
  });

  const [inputRefState, setInputRefState] = useState<HTMLInputElement | null>(null);

  const isEditable = editIndex === item?.[NEW_FOLDER_FRONT_ID_KEY];

  const onNameClick = useCallback(() => {
    if(item?.[NEW_FOLDER_FRONT_ID_KEY]){
      setEditIndex(item[NEW_FOLDER_FRONT_ID_KEY]);
    }
  }, [item, setEditIndex]);

  const onBlur = useCallback(() =>{
    const name = textFieldValue || item.name;
    onBlurAccept(name, item[NEW_FOLDER_FRONT_ID_KEY] as string);
  }, [item, onBlurAccept, textFieldValue]);

  useEffect(() => {
    if(isEditable){
      inputRefState?.focus();
    }
  },[inputRefState, isEditable]);

  useEffect(() => {
    if(!isEditable){
      setForceTextFieldValue(item.name);
    }
  },[isEditable, item.name, setForceTextFieldValue]);

  const onItemClick = useCallback(() => {
    const index = item?.[NEW_FOLDER_FRONT_ID_KEY] || item.id;
    setSelectedIndex(index);
  }, [item, setSelectedIndex]);

  const onChangeWrapper = useCallback((e: ChangeEvent<HTMLInputElement>)=> {
    setTextFieldValue(e);
  }, [setTextFieldValue]);


  if(isEditable){
    return (
      <ItemWrapper
        $isActive={ $isActive }
        $level={ $level }
        onClick={ onItemClick }
      >
        <ItemIconWrapper>
          <FolderStroke />
        </ItemIconWrapper>
        <InputWrapper>
          <CustomTextField
            TextFieldProps={ {
              inputRef: setInputRefState
            } }
            inputProps={ {
              maxlength: 150
            } }
            onBlur={ onBlur }
            onChange={ onChangeWrapper }
            placeholder={ 'Please Input Folder Name' }
            showErrorText={ false }
            value={ textFieldValue }
          />
        </InputWrapper>
      </ItemWrapper>
    );
  }

  return(
    <ItemWrapper
      $isActive={ $isActive }
      $isDisabled={ item.id === listItem.parentId }
      $level={ $level }
      onClick={ onItemClick }
    >
      <ItemIconWrapper>
        <FolderStroke />
      </ItemIconWrapper>
      <ItemNameWrapper
        $isEditable={ Boolean(item?.[NEW_FOLDER_FRONT_ID_KEY]) }
        onClick={ onNameClick }
      >
        <Tooltip
          displayOnlyOnOverflow={ true }
          title={ item.name }
        >
          <TextEllipsis
            $maxWidth={ 685 }
          >
            {item.name}
          </TextEllipsis>
        </Tooltip>
      </ItemNameWrapper>
    </ItemWrapper>
  );
};

export const Items = ({
  $isActive,
  $level,
  editIndex,
  item,
  listItem,
  onBlurAccept,
  setEditIndex,
  setSelectedIndex,
}:ItemsProps) => (
  <>
    <Item
      $isActive={ $isActive }
      $level={ $level }
      editIndex={ editIndex }
      item={ item }
      listItem={ listItem }
      onBlurAccept={ onBlurAccept }
      setEditIndex={ setEditIndex }
      setSelectedIndex={ setSelectedIndex }
    />
    {
      item?.childrens && item?.childrens?.length > 0  &&
      item.childrens.map(children => (
        <Items

          $level={ $level + 1 }
          editIndex={ editIndex }
          item={ item }
          key={ item.name }
          listItem={ listItem }
          onBlurAccept={ onBlurAccept }
          setEditIndex={ setEditIndex }
          setSelectedIndex={ setSelectedIndex }
        />
      ))
    }
  </>
);

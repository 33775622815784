import React from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import HFArrowStepper from '@modules/HookFormComponents/HFArrowStepper';

import { SalesPipelinePopupTopPanelProps } from './types';

export const SalesPipelinePopupTopPanel = observer(({
  name,
  disabled,
  options,
}: SalesPipelinePopupTopPanelProps) => {
  const { control } = useFormContext();

  return (
    <HFArrowStepper
      control={ control }
      disabled={ disabled }
      name={ name }
      options={ options }
    />
  );
});

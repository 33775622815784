import { Envelope, ListCriteria, ListSetup } from '@/assets';

import { ListItemTabs } from '@pages/NewContacts/subpages/Lists/subpages/ListsDetails/types';

import { getListItemLink } from '@/shared/utils/listsRoutesUtils';

import { LISTS_DYNAMIC_TABS } from '@constants/routes/contacts';
import { LISTS_TYPES } from '@constants/lists';

export const DYNAMIC_LIST_TABS: Array<ListItemTabs> = [
  {
    getLink: (id) => getListItemLink(LISTS_TYPES.Dynamic, id)!.contacts,
    icon: Envelope,
    label: 'List Contacts',
    value: LISTS_DYNAMIC_TABS.contacts,
  },
  {
    getLink: (id) => getListItemLink(LISTS_TYPES.Dynamic, id)!.setup,
    icon: ListSetup,
    label: 'List Setup',
    value: LISTS_DYNAMIC_TABS.setup,
  },
  {
    getLink: (id) => getListItemLink(LISTS_TYPES.Dynamic, id)!.criteria as string,
    icon: ListCriteria,
    label: 'List Criteria',
    value: LISTS_DYNAMIC_TABS.criteria,
  }
];
import { useRef, useEffect } from 'react';

export const useDidUpdate = (callback, depsArray = []) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      callback();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depsArray);
};

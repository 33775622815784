import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Popover } from '@material-ui/core';

import { checkInclusion, excludeOption } from './utils';

import { Cross } from '@/assets';
import PopupActionsButtons from '@components/PopupActionsButtons';
import RowFilter from '@components/RowFilter';
import TagButton, { TAG_BUTTON_TYPES } from '@components/TagButton';

import { ANCHOR_ORIGIN_PROPS, PAPER_PROPS, TRANSFORM_ORIGIN_PROPS } from './data';
import { TAG_BUTTON_COLOR_SCHEME_VARIANTS } from '@modules/ContactComponents/Tags/data';

import { Wrapper, Header, CloseIconButton, SubTitle, Divider, InfoText, AdminTagsWrapper } from './styles';

import { TagListItem } from '@/shared/types/tags';
import { GlobalTagListProps } from './types';


export const GlobalTagList = observer(({
  adminTags,
  anchorEl,
  ButtonStyles = {},
  InputStyles = {},
  isOpen,
  isStoreFetching,
  onClose,
  onSave,
  type
}: GlobalTagListProps) => {

  const [ selectedAdminTags, setSelectedAdminTags ] = useState<Array<TagListItem>>([]);

  const onAdminTagClick = useCallback((tag: TagListItem) => () => {
    if (!checkInclusion(tag, selectedAdminTags)) {
      setSelectedAdminTags([...selectedAdminTags, tag]);
    }
  }, [selectedAdminTags]);

  const onDeleteSelectedAdminTag = useCallback((tag: TagListItem) => {
    setSelectedAdminTags(excludeOption(tag, selectedAdminTags));
  }, [selectedAdminTags]);

  const onSaveSelectedAdminTags = useCallback(() => {
    onSave(selectedAdminTags, onClose);
  }, [selectedAdminTags]);

  const isApplyButtonDisabled = useMemo(() => {
    return selectedAdminTags.length === 0 || isStoreFetching ? true : false;
  }, [selectedAdminTags, isStoreFetching]);

  return(
    <Popover
      anchorEl={ anchorEl }
      anchorOrigin={ ANCHOR_ORIGIN_PROPS }
      onClose={ onClose }
      open={ isOpen }
      PaperProps={ PAPER_PROPS }
      transformOrigin={ TRANSFORM_ORIGIN_PROPS }
    >
      <Wrapper>
        <Header>
          Tags
          <CloseIconButton onClick={ onClose }>
            <Cross/>
          </CloseIconButton>
        </Header>
        <SubTitle>
          SELECTED TAGS
        </SubTitle>

        <AdminTagsWrapper>
          <RowFilter
            items={ selectedAdminTags as any }
            limit={ 7 }
            renderItem={ ({
              content,
              isShowMore,
              onClick,
            }) => {
              return isShowMore ? 
                <TagButton
                  $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
                  content={ content }
                  key={ content?.id }
                  onClickCb={ onClick }
                  tagData={ content }
                  type={ TAG_BUTTON_TYPES.button }
                /> :
                <TagButton
                  $isDynamicInputWidth={ false }
                  $showError={ false }
                  $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].button }
                  content={ content?.label }
                  inputStyle={ InputStyles }
                  isNotEditable={ true }
                  key={ content.id }
                  onDeleteCb={ () => onDeleteSelectedAdminTag(content) }
                  onSaveCb={ () => {} }
                  style={ {
                    ...ButtonStyles,
                    width: '100px',
                  } }
                  tagData={ content }
                  type={ TAG_BUTTON_TYPES.edit }
                />;
            } }
          />
        </AdminTagsWrapper>

        <Divider></Divider>

        <SubTitle>
          ALL TAGS
          <InfoText>
            (Click for select)
          </InfoText>
        </SubTitle>

        <AdminTagsWrapper>
          { adminTags && adminTags.length > 0 && adminTags.map(tag => (
            <TagButton
              $variant={ TAG_BUTTON_COLOR_SCHEME_VARIANTS[type].adminButton }
              $viewContentMaxWidth={ '170px' }
              content={ tag.label }
              key={ tag.id }
              onClickCb={ onAdminTagClick(tag) }
              style={ {
                marginRight: '4px',
              } }
              type={ TAG_BUTTON_TYPES.button }
            />
          ))
          }
        </AdminTagsWrapper>
        <PopupActionsButtons
          onCancelClick={ onClose }
          onSaveClick={ onSaveSelectedAdminTags }
          saveLabel={ 'Apply' }
          SaveProps={ {
            disabled: isApplyButtonDisabled
          } }
          wrapperProps={ {
            marginLeft: 'auto',
            marginTop: '10px'
          } }
        />
      </Wrapper>
    </Popover>
  );
});

import { CSSProperties, ElementType } from 'react';

import { FullFilledList, PartiallyFilledList, FullFilledListWithPhoto } from '@/assets';

import { LIST_EXPORT_TYPE } from '@constants/lists';
import { COLORS } from '@constants/colors';

type ButtonsConfigItem = {
  value: LIST_EXPORT_TYPE
  icon: ElementType
  label: string
}

export const BUTTONS_CONFIG: Record<LIST_EXPORT_TYPE, ButtonsConfigItem> = {
  [LIST_EXPORT_TYPE.primaryContactInformation]:{
    value: LIST_EXPORT_TYPE.primaryContactInformation,
    icon: PartiallyFilledList,
    label: 'Primary Contact Information'
  },
  [LIST_EXPORT_TYPE.fullContactInformation]: {
    value: LIST_EXPORT_TYPE.fullContactInformation,
    icon: FullFilledList,
    label: 'Full Contact Information'
  },
  [LIST_EXPORT_TYPE.fullContactProfile]:{
    value: LIST_EXPORT_TYPE.fullContactProfile,
    icon: FullFilledListWithPhoto,
    label: 'Full Contact Profile'
  },
};


export const ButtonStyle: CSSProperties = {
  width: '185px',
  padding: '20px 15px',
  boxSizing: 'border-box',
  color: COLORS.helpText,
};

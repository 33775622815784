import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 20px;
  height: fit-content;

  & > div {
    width: 50%;
  }
`;

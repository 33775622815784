export const LABEL_PLACEMENT = {
  bottom: 'bottom',
  end: 'end',
  start: 'start',
  top: 'top',
} as const;

export const MARGIN_RULES_MAP = {
  [LABEL_PLACEMENT.bottom]: 'margin-top',
  [LABEL_PLACEMENT.end]: 'margin-left',
  [LABEL_PLACEMENT.start]: 'margin-right',
  [LABEL_PLACEMENT.top]: 'margin-bottom',
};

export const MARGIN_FROM_SWITCH = '10px';
import {
  ASSIGNED_TO_CURRENT_USER_EDIT_MODE_SELECT_PROPS,
  ASSIGNED_TO_CURRENT_USER_SELECT_PROPS,
  ASSIGNED_TO_OTHER_USER_EDIT_MODE_SELECT_PROPS,
  ASSIGNED_TO_OTHER_USER_SELECT_PROPS
} from './data';

import { IdType } from '@/shared/types/commonTypes';

export const getAssignedToCurrentUserSelectProps = (id: IdType | null) => {
  if(id) {
    return ASSIGNED_TO_CURRENT_USER_EDIT_MODE_SELECT_PROPS;
  }
  return ASSIGNED_TO_CURRENT_USER_SELECT_PROPS;
};

export const getAssignedToOtherUserSelectProps = (id: IdType | null) => {
  if(id) {
    return ASSIGNED_TO_OTHER_USER_EDIT_MODE_SELECT_PROPS;
  }
  return ASSIGNED_TO_OTHER_USER_SELECT_PROPS;
};

import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { TimePickerMain } from './components';

import { getValidator } from './utils';

export const TimePicker = ({
  ControllerProps,
  $width = '98px',
  control,
  disabled,
  isRequired,
  name,
}) => {
  const validate = useMemo(() => getValidator({ isRequired }), [isRequired]);

  return (
    <Controller
      control={ control }
      name={ name }
      render={ ({
        field: {
          value,
          onChange
        },
        fieldState: { error }
      }) => (
        <TimePickerMain
          $width={ $width }
          disabled={ disabled }
          errorMessage={ error?.message }
          forcedState={ value }
          initValue={ value }
          onChange={ onChange }
        />
      ) }
      rules={ {
        validate,
      } }
      { ...ControllerProps }
    />
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const TaskDetailsWrapper = styled.div`
  width: 790px;
  padding: 25px 20px 20px 20px;
  position: relative;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};
  overflow-y: auto;
`;
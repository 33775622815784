import React from 'react';

import { COLORS } from '@constants/colors';

import { TriangleProps } from './types';

import { TRIANGLE_TYPE } from './data';

import { Wrapper } from './styles';

export const Triangle = ({
  $color = COLORS.orange,
  $type = TRIANGLE_TYPE.TOP,
  $size = 8,
}: TriangleProps) => (
  <Wrapper 
    $color={ $color }
    $size={ $size }
    $type={ $type }
  />
);

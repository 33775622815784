import { EmployeeOwnFieldsNames } from '@/shared/types/employee';
import { ValueLabelObj } from '@/shared/types/commonTypes';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { EMPLOYEE_RELATIONSHIPS } from '@constants/common';
export const LEFT_COLUMN_WIDTH = 150;

export const RIGHT_COLUMN_WIDTH = 110;

export const FIELDS_NAMES: Omit<EmployeeOwnFieldsNames, 'changePrimary'> ={
  contacts: 'contacts',
  email: 'email',
  emailType: 'emailType',
  employeeId: 'employeeId',
  firstName: 'firstName',
  id: 'id',
  keyContact: 'keyContact',
  lastName: 'lastName',
  phone: 'phone',
  phoneType: 'phoneType',
  primaryContact: 'primaryContact',
  relationshipType: 'relationshipType',
  title: 'title',
};

export const EMPLOYEE_RELATIONSHIP_OPTIONS: Array<ValueLabelObj> = optionsFromValue(
  Object.values(EMPLOYEE_RELATIONSHIPS));

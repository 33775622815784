import { useState, useCallback } from 'react';

export const useRadioBtn = (intiValue) => {
  const [ radioValue, setRadioValue ] = useState(intiValue);
  const onChange = useCallback((e) => setRadioValue(e.target.value), []);

  return {
    onChange,
    radioValue,
  };
};

import { DOTS_MENU_ITEMS_TEMPLATES } from '@components/DotsMenu/data';
import { GetDotsMenuOptionProps } from './types';

export const getDotsMenuOptions = ({
  arrayOfTypes = [],
  withTopSeparators = []
}: GetDotsMenuOptionProps) => (
  arrayOfTypes.map(type => ({
    ...(DOTS_MENU_ITEMS_TEMPLATES[type] || {}),
    topSeparator: withTopSeparators.includes(type)
  }))
);
import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { DeletePopupProps } from './types';

export const DeleteNotePopup = ({
  closeModal,
  modalProps: {
    onConfirm,
    message = 'Are you sure you want to delete note?',
  }
}: DeletePopupProps) => {

  const onConfirmClick = useCallback(() => {
    onConfirm();
    closeModal();
  }, [ closeModal ]);

  return (
    <ConfirmationPopup
      cancelLabel={ 'No' }
      confirmLabel={ 'Yes' }
      isOpen={ true }
      message={ message }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmClick }
    />
  );
};

import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useDidUpdate } from '@customHooks';

import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import {
  checkAndSetTypeAndSort
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/components/CommonBalanceHistoryRow/utils';

import { generalInputProps, generalTextFieldProps } from './data';

import {
  BALANCE_HISTORY_TYPES,
  BALANCE_HISTORY_TYPES_OPTIONS, BALANCE_HISTORY_TYPES_OPTIONS_WITHOUT_INIT
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBalanceHistory/data';

import { BalanceHistoryRowEditProps, UseWatchType } from './types';

export const BalanceHistoryRowEdit = ({
  disable,
  control,
  field,
  fields,
  fieldsNames,
  fieldPath,
  getSiblingPath,
  index,
  onDeleteClick,
  onSaveClick,
  originalIndex,
  setValue,
}: BalanceHistoryRowEditProps) => {

  const actualStateOfField:UseWatchType  = useWatch({
    control,
    name: fieldPath,
  });

  const { type,newBusinessAmount } = actualStateOfField;

  const typeOptions = useMemo(() => {
    if(type === BALANCE_HISTORY_TYPES.initialValue){
      return  BALANCE_HISTORY_TYPES_OPTIONS;
    } else {
      return BALANCE_HISTORY_TYPES_OPTIONS_WITHOUT_INIT;
    }
  }, [type]);

  const onSaveWrapper = useCallback(async () => {
    const fieldWithActualData = {
      ...field,
      ...actualStateOfField
    };

    await checkAndSetTypeAndSort({
      field: fieldWithActualData,
      fields,
      getSiblingPath,
      index,
      originalIndex,
      setValue,
      sortFieldName: fieldsNames.sortFieldName,
      saveCallback: onSaveClick
    });
  }, [
    actualStateOfField, field, fields, fieldsNames.sortFieldName, getSiblingPath,
    index, onSaveClick, originalIndex, setValue
  ]);

  const onEditStab = useCallback(() => { }, []);

  useDidUpdate(() => {
    if(type !== BALANCE_HISTORY_TYPES.newBusiness && Number(newBusinessAmount) >= 0){
      setValue(fieldsNames.newBusinessAmountFieldName, '');
    }
  },[type, newBusinessAmount]);

  return (
    <>
      <PopupTable.CellWrapper>
        <HFHiddenInput
          control={ control }
          defaultValue={ field.sort }
          name={ fieldsNames.sortFieldName }
        />
        <HFDatePicker
          DatePickerProps={ {
            disableFuture: true
          } }
          control={ control }
          disabled={ disable }
          name={ fieldsNames.updatedFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFTextInput
          inputProps={ generalInputProps }
          TextFieldProps={ generalTextFieldProps }
          control={ control }
          disabled={ disable }
          name={ fieldsNames.valueFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFSelect
          control={ control }
          disabled={ type === BALANCE_HISTORY_TYPES.initialValue || disable }
          name={ fieldsNames.typeFieldName }
          options={ typeOptions }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFTextInput
          inputProps={ generalInputProps }
          TextFieldProps={ generalTextFieldProps }
          control={ control }
          disabled={ type !== BALANCE_HISTORY_TYPES.newBusiness || disable }
          name={ fieldsNames.newBusinessAmountFieldName }
        />
        <PopupTable.Actions
          $marginLeft={ '10px' }
          disableDelete={ false }
          disableEdit={ false }
          isEdit={ true }
          onDeleteClick={ onDeleteClick }
          onEditClick={ onEditStab }
          onSaveClick={ onSaveWrapper }
        />
      </PopupTable.CellWrapper>
    </>
  );
};

import styled, { css } from 'styled-components';

import { BOX_SHADOW } from '@constants/style';

import { NotificationIconWrapperProps, NotificationItemWrapperProps, NotificationTextWrapperProps } from './types';

export const Wrapper = styled.div<NotificationItemWrapperProps>`
  display: flex;
  position: relative;
  width: 420px;
  box-sizing: border-box;
  padding: 12px  12px  12px 23px;
  border-radius: 4px;
  box-shadow: ${ BOX_SHADOW };
  overflow: hidden;
  cursor: pointer;
  
  ${({ $bgColor }) => $bgColor && css`
    background-color: ${($bgColor)};
  `}
  
  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    
    width: 3px;

    ${({ $indicatorColor }) => $indicatorColor && css`
      background-color: ${($indicatorColor)};
    `}
  }
`;

export const IconWrapper = styled.div<NotificationIconWrapperProps>`
  width: 40px;
  height: 40px;
  margin: auto 15px auto 0;
`;

export const MessageWrapper = styled.p<NotificationTextWrapperProps>`
  margin: auto auto auto 0;
  word-break: break-word;
  font-size: 16px;
  cursor: pointer;

  ${({ $fontColor }) => $fontColor && css`
    color: ${$fontColor};
  `}
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
`;

import {
  OPPORTUNITY_FIELD_NAMES,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_STAGES
} from '@constants/salesCycleOpportunity';

import { getFormatedDateNow } from '@/shared/utils/getFormatedDateNow';

export const getDefaultValues = (isEdit?: boolean) => {
  if (isEdit) {
    return {};
  }

  return {
    [OPPORTUNITY_FIELD_NAMES.opportunityStatus]: OPPORTUNITY_STATUSES.active,
    [OPPORTUNITY_FIELD_NAMES.stage]: OPPORTUNITY_STAGES.identified,
    [OPPORTUNITY_FIELD_NAMES.onboarding]: 'No',
    [OPPORTUNITY_FIELD_NAMES.opportunityOpenDate]: getFormatedDateNow(),
  };
};

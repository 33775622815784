/* eslint-disable */
import React from 'react';

import { FlexGrowElement } from "./styles";

function GrowElement() {
  return <FlexGrowElement />;
}

export default GrowElement;

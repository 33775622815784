import { SALES_ACTIVE_CYCLE_FILTER_NAMES } from '@constants/salesActiveCycle';

export const USERS = {
  name: SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor,
  label: 'Users',
  values: []
};

export const SALES_CYCLE_STATUS =   {
  name: SALES_ACTIVE_CYCLE_FILTER_NAMES.salesCycleStatus,
  label: 'Cycle Status',
  values: []
};

export const MEETING_STATUS =   {
  name: SALES_ACTIVE_CYCLE_FILTER_NAMES.meetingStatus,
  label: 'Meeting Status',
  values: []
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const NoDataWrapper = styled.div`
  margin: auto;
`;

export const NoteItemsWrapper = styled.div`
  overflow-y: auto;
`;

export const NoDataSignature = styled.span`
  color: ${COLORS.helpText}
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { CONTACT_TYPE } from '@modules/AddContactModal/data';

import { removeFalseValues } from '@/shared/utils/removeFalseValues';

import cloneData from '@/shared/utils/cloneData';

import {
  formatContacts,
  formatHouseholderContacts,
  formatNewContact,
  formatRelatedContacts,
  formatRules,
  formatTags
} from './helpers';

const formatIndividualType = (data, store) => {
  const { 1: firstStepData, 2: secondStepData } = cloneData(data);

  const contacts = formatContacts(secondStepData);
  const contactTags = formatTags(store.selectedTags);
  const relatedContact = formatRelatedContacts(store);

  return {
    type: 'Individual',
    contacts,
    contactTags,
    relatedContact,
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(firstStepData)
  };
};

const formatHouseholdType = (data, store) => {
  const { 1: firstStepData, 2: secondStepData } = cloneData(data);
  const { main, spouse, ...restFirstStepData } = firstStepData;
  const { communicateWith, ...restSecondStepData } = secondStepData;

  const contacts = formatContacts(restSecondStepData);
  const contactTags = formatTags(store.selectedTags);
  const relatedContact = formatRelatedContacts(store);
  const householderContacts = formatHouseholderContacts(
    main,
    spouse,
    communicateWith,
    contacts
  );

  return {
    type: 'Household',
    contactTags,
    householderContacts,
    relatedContact,
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(restFirstStepData)
  };
};

const formatBusinessType = (data, store) => {
  const { linkedContact } = store;
  const { 1: firstStepData, 2: secondStepData, 3: thirdStepData } = cloneData(data);

  const contacts = formatContacts(secondStepData);
  const contactTags = formatTags(store.selectedTags);

  const payload = {
    type: 'Business',
    contacts,
    contactTags,
    relatedEmployees: thirdStepData.newContact ? formatNewContact(thirdStepData) : [],
    ...formatRules(secondStepData.rules),
    ...removeFalseValues(firstStepData)
  };

  if (linkedContact) {
    payload.relatedEmployees = [{
      employeeId: linkedContact.id,
      primaryContact: 'Yes'
    }];
  }

  return payload;
};

export const saveContactNormilizer = (data, store) => {
  switch (store.selectedType) {
  case CONTACT_TYPE.Individual:
    return formatIndividualType(data, store);
  case CONTACT_TYPE.Household:
    return formatHouseholdType(data, store);
  case CONTACT_TYPE.Business:
    return formatBusinessType(data, store);
  default:
    return {};
  }
};

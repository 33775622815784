import styled, { css } from 'styled-components';
import MuiBox from '@material-ui/core/Box';

import { COLORS } from '@constants/colors';

import {
  FormBlockWrapperProps,
  FormFieldChildrenWrapperProps,
  FormFieldLabelContentProps,
  FormFieldLabelProps,
  VerticalCenteredBlockProps,
} from './types';

export const Wrapper = styled.div<FormBlockWrapperProps>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${ ({ paddingBottom }) => paddingBottom && css`
        padding-bottom: ${ paddingBottom }px;
    `}

    ${ ({ isBorderBottom }) => isBorderBottom && css`
        border-bottom: 1px solid ${ COLORS.dividingLines };
    `}

    ${ ({ marginBottom }) => marginBottom && css`
        margin-bottom: ${ marginBottom }px;
    `}
`;

export const HeaderTitleWrapper = styled.div`
    margin-right: auto;
    font-size: 16px;
    color: ${ COLORS.header };
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;
`;

export const Row = styled(MuiBox)`
  display: flex;
  width: 100%;
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FormFieldLabel = styled.div<FormFieldLabelProps>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  
  ${({ $labelWidth, $labelWidthUnits }) => $labelWidth && $labelWidthUnits && css`
      width: ${$labelWidth}${$labelWidthUnits};
  `};

  ${({ $labelHeight, $labelHeightUnits }) => $labelHeight && $labelHeightUnits && css`
      height:${$labelHeight}${$labelHeightUnits};
  `};
  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${COLORS.helpText};

  ${({ $isDisabled }) => $isDisabled && css`
    color: ${COLORS.borders};
  `}
`;

export const FormFieldLabelContent = styled.span<FormFieldLabelContentProps>`
  position: relative;
  
  ${({ $isRequired }) => $isRequired && css`
      &:after {
        position: absolute;
        content: '*';
        top: -6px;
        right: -8px;
        color: ${COLORS.red};
      }
  `};
`;

export const FormFieldChildrenWrapper = styled(MuiBox)<FormFieldChildrenWrapperProps>`
  display: flex;
  width: 100%;
  min-width: 1%;

  ${({ $isWrap }) => $isWrap && css`
      flex-wrap: wrap;
  `};
  
  ${({ $isFluid }) => $isFluid && css`
    flex-grow: 1;

      & > *:first-child {
        flex-grow: 1;
        min-width: auto;
      }
  `};
`;

export const VerticalCenteredWrapper = styled.div<VerticalCenteredBlockProps>`
  display: flex;
  align-items: center;
  max-width: 100%;

  ${ ({ $disabled }) => $disabled && 'opacity: 0.3'};
`;

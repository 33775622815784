import React from 'react';
import { observer } from 'mobx-react';

import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import { CommonTrashNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';


export const AccountAndPlansCells = observer(({
  item,
}: TrashCellsProps<CommonTrashNormalizerItem['item']>) => (
  <>
    <Table.Cell>
      <TextWithTooltip text={ item.name || '' } />
    </Table.Cell>

    <Table.Cell>
      <TextWithTooltip text={ item.type || '' } />
    </Table.Cell>

    <Table.Cell>
      {
        !Array.isArray(item?.contact) ?
          (
            <UserItem
              labelWidth={ 250 }
              user={ item?.contact }
              withCoreClassification={ true }
            />
          )
          : '-'
      }
    </Table.Cell>
  </>
));

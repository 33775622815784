import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { getTimeStampInMs } from '@/shared/utils/getTimeStampInMs';

import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Table from '@components/Table';
import TextEllipsis from '@components/TextEllipsis';
import Tooltip from '@components/Tooltip';
import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import { BeneficiariesRowProps } from './types';

import { getFieldsNames, nameFieldInputProps, percentFieldInputProps } from './data';
import {
  BENEFICIARIES_TYPES_OPTIONS,
  BENEFICIARIES_RELATION_TYPES_OPTIONS
// eslint-disable-next-line max-len
} from '@modules/AccountsAndPlansPopup/components/Content/components/CommonBasicInformation/components/Beneficiaries/data';

export const BeneficiariesRow = ({
  control,
  disable,
  disableEdit,
  disableDelete,
  field,
  fieldPath,
  originalIndex,
  isEdit,
  onDelete,
  onEdit,
  onSave,
  setValue,
  onRowClick,
}: BeneficiariesRowProps) => {

  const {
    nameFieldName,
    percentFieldName,
    relationTypeFieldName,
    sortFieldName,
    typeFieldName
  } = useMemo(() => getFieldsNames(fieldPath), [fieldPath]);

  const onDeleteWrapper = useCallback(() => onDelete(originalIndex),[onDelete, originalIndex]);
  const onEditWrapper = useCallback(() => onEdit(originalIndex),[onEdit, originalIndex]);
  const onSaveWrapper = useCallback(async () => {
    const timeStamp = getTimeStampInMs(new Date());
    await setValue(sortFieldName, timeStamp);
    await onSave(fieldPath);
  }, [setValue, sortFieldName, onSave, fieldPath]);
  const onClick = useCallback(() => {
    if(!disableEdit){
      onRowClick(originalIndex);
    }
  },[disableEdit, onRowClick, originalIndex]);

  const [ nameFieldValue, percentFieldValue, relationTypeFieldValue, typeFieldValue ] = useWatch({
    control,
    name: [nameFieldName, percentFieldName, relationTypeFieldName, typeFieldName]
  });

  if(!isEdit) {
    return (
      <Table.Row 
        $preventHover={ true }
        onClick={ onClick }
      >
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.name }
            name={ nameFieldName }
          />
          <Tooltip
            displayOnlyOnOverflow={ true }
            title={ nameFieldValue }
          >
            <TextEllipsis $maxWidth={ 230 }>
              { nameFieldValue }
            </TextEllipsis>
          </Tooltip>
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.relationType }
            name={ relationTypeFieldName }
          />
          { relationTypeFieldValue }
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.type }
            name={ typeFieldName }
          />
          { typeFieldValue }
        </Table.Cell>
        <Table.Cell>
          <HFHiddenInput
            control={ control }
            defaultValue={ field.type }
            name={ typeFieldName }
          />
          {`${percentFieldValue}%`}
          <PopupTable.Actions
            $alignSelf={ 'center' }
            $marginTop={ '0px' }
            disableDelete={ disableDelete }
            disableEdit={ disableEdit }
            isEdit={ isEdit }
            onDeleteClick={ onDeleteWrapper }
            onEditClick={ onEditWrapper }
            onSaveClick={ onSaveWrapper }
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  return(
    <Table.Row $preventHover={ true }>
      <PopupTable.CellWrapper>
        <HFTextInput
          inputProps={ nameFieldInputProps }
          control={ control }
          disabled={ disable }
          name={ nameFieldName }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFSelect
          control={ control }
          disabled={ disable }
          name={ relationTypeFieldName }
          options={ BENEFICIARIES_RELATION_TYPES_OPTIONS }
          placeholder={ 'Select' }
          resetLabel={ 'Select' }
          resettable={ true }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFSelect
          control={ control }
          disabled={ disable }
          name={ typeFieldName }
          options={ BENEFICIARIES_TYPES_OPTIONS }
        />
      </PopupTable.CellWrapper>
      <PopupTable.CellWrapper>
        <HFTextInput
          inputProps={ percentFieldInputProps }
          TextFieldProps={ {
            type: 'number'
          } }
          control={ control }
          disabled={ disable }
          name={ percentFieldName }
        />
        <PopupTable.Actions
          $marginLeft={ '10px' }
          disableDelete={ disableDelete }
          disableEdit={ disableEdit }
          isEdit={ isEdit }
          onDeleteClick={ onDeleteWrapper }
          onEditClick={ onEditWrapper }
          onSaveClick={ onSaveWrapper }
        />
      </PopupTable.CellWrapper>
    </Table.Row>
  );
};

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const BtnPanelWrapper = styled.div`
  display: flex;
  & > div {
    gap: 8px;

    & > div {
      margin: 0px;
    }
  }
`;

export const StarIconWrapper = styled.div`
  width: 20px;
  height: 20px;

  & svg path{
      fill: ${COLORS.blue} !important;
    };
  }
`;

import React, { useCallback } from 'react';

import ConfirmationPopup from '@modules/ConfirmationPopup';

import { DeleteContactCustomFieldPopupProps } from './types';

export const DeleteContactCustomFieldPopup = ({
  closeModal,
  modalProps
}: DeleteContactCustomFieldPopupProps) => {
  const { onConfirm } = modalProps;

  const onConfirmWrapper =  useCallback(() => {
    closeModal();
    onConfirm();
  },[closeModal, onConfirm]);

  return (
    <ConfirmationPopup
      isOpen={ true }
      message={ 'If you delete the option, all its references across the application will be deleted permanently.' }
      onCancelClick={ closeModal }
      onClose={ closeModal }
      onConfirmClick={ onConfirmWrapper }
    />
  );
};

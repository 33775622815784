import React from 'react';

import Button from '@material-ui/core/Button';

import { PlusCircle } from 'react-feather';

import { NoDataViewImage } from '@/assets';

import {
  ActionBlockWrapper,
  Image,
  ImageWrapper, InfoLink, InfoText, InfoWrapper,
  MainBlockWrapper,
  MarkedTextChunk,
  TextChunk,
  TextWrapper,
  Wrapper
} from './styles';

export const NoDataItemsView = ({
  $imageMarginBottom = '64px',
  $infoTextFontSize = '22px',
  $imageWidth = 'auto',
  addActionButtonClickHandler = () => {},
  addActionButtonLabel = 'Add',
  imgSrc = NoDataViewImage,
  markedText = 'Items',
  signature = '',
  withAction = false,
  withInfo = true,
}) => (
  <Wrapper>
    <MainBlockWrapper>
      <ImageWrapper $marginBottom={ $imageMarginBottom }>
        <Image
          $width={ $imageWidth }
          src={ imgSrc }
        />
      </ImageWrapper>
      <TextWrapper $fontSize={ $infoTextFontSize }>
        { signature ?
          signature :
          <>
            <TextChunk>{'There are currently NO '}</TextChunk>
            <MarkedTextChunk>{ markedText }</MarkedTextChunk>
            <TextChunk>{' to view'}</TextChunk>
          </>
        }
      </TextWrapper>
    </MainBlockWrapper>
    {
      withAction &&
      <ActionBlockWrapper>
        <Button
          color='primary'
          onClick={ addActionButtonClickHandler }
          startIcon={ <PlusCircle /> }
          variant='contained'
        >
          {addActionButtonLabel}
        </Button>
        {
          withInfo &&
          <InfoWrapper>
            <InfoText>
              {'Something missing? '}
            </InfoText>
            <InfoText>
              {'Check Out Our '}
              <InfoLink
                href={ 'https://visionfinancialmarketing.groovehq.com/help_center' }
                rel={ 'noopener noreferrer' }
                target={ '_blank' }
              >
                Knowledge Library
              </InfoLink>
            </InfoText>
          </InfoWrapper>
        }
      </ActionBlockWrapper>
    }
  </Wrapper>
);

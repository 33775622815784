import React from 'react';

import { AddNote } from '@/assets';
import BlockButton from '@components/BlockButton';
import CustomIconButton, { ICON_BUTTON_TYPES, CustomIconButtonOnClick } from '@components/CustomIconButton';

type AddNoteButtonProps = {
  disabled?: boolean
  onClick: CustomIconButtonOnClick
  withButtonTitle: boolean
}

export const AddNoteButton = ({ disabled, onClick, withButtonTitle }: AddNoteButtonProps) => (
  <>
    { withButtonTitle ?
      <BlockButton
        onClick={ onClick }
        style={ {
          minWidth: 90,
          padding: 0
        } }
        $iconSize={ '18px' }
        startIcon={ <AddNote /> }
      >
        Add Note
      </BlockButton> :
      <CustomIconButton
        disabled={ disabled }
        onClick={ onClick }
        type={ ICON_BUTTON_TYPES.note }
      />
    }
  </>
);

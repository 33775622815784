import React from 'react';

import FormBlock from '@components/FormBlock';
import HFSelect from '@modules/HookFormComponents/HFSelect';

import { useStore } from '@store';
import { getIsAbleToSetUserPermission } from '@/pages/NewUserSettings/utils';

import { LABELS_WIDTH } from '@/pages/NewUserSettings/data';
import {
  AccountSettingsInformationInputNames as InputNames
} from '@pages/NewUserSettings/components/Tabs/AccontSettings/data';

import { LeftColumnWrapper, RightColumnWrapper } from '../Layout';
import { useFormContext } from 'react-hook-form';

export function GeneralInformation() {

  const { roles } = useStore().SettingsStore;
  const { configData } = useStore().UserSettingsStore;
  const isAbleRole = getIsAbleToSetUserPermission(roles);

  const { control } = useFormContext();

  return (
    <FormBlock marginBottom={ 20 }>
      <FormBlock.Header title={ 'General Information' }/>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingLeftLabelWidth }
            label={ 'Time Zone' }
          >
            <HFSelect
              control={ control }
              name={ InputNames.timezone }
              options={ configData?.timezone }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingRightLabelWidth }
            label={ 'Default Login Tab' }
          >
            <HFSelect
              control={ control }
              disabled
              name={ InputNames.defaultLoginTab }
              options={ configData?.defaultLoginTab }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>

      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingLeftLabelWidth }
            label={ 'Display List By' }
          >
            <HFSelect
              control={ control }
              name={ InputNames.fullNameOrder }
              options={ configData?.fullNameOrder }
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.accountSettingRightLabelWidth }
            label={ 'Affiliated Organization' }
          >
            <HFSelect
              control={ control }
              disabled={ !isAbleRole }
              name={ InputNames.organizationId }
              options={ configData?.organizations }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>
    </FormBlock>
  );
}
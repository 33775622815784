import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import { omit } from 'lodash';

import { Store } from '@store';

import { CommonStoreForKanbanAndTable } from '../../commonStoreForKanbanAndTable';
import { CommonStore } from '../../commonStore';

import { BookmarkDataItem, CloseModal } from '@/shared/types/commonTypes';
import {
  ConvertedGridAndKanbanFilterDataType,
  GridAndKanbanFiltersState,
  SalesActiveCycleByStage,
  SalesActiveCycleStageType,
  SalesCycleDeleteData,
  SalesCycleKanbanSettingResponse,
  SalesCycleKanbanSettings,
  SalesCycleKanbanWebsocketData,
  SalesCycleKanbanWebsocketType,
  SalesKanbanBackendResponse,
  SalesKanbanDropDataType,
  SaveSalesActiveCyclePipelineData
} from '@/shared/types/salesActiveCycle';
import {
  deleteSalesCycle,
  getKanban,
  getKanbanSettings,
  partialUpdateSalesCycle,
  saveSalesActiveCyclePipeline, updateSalesCycleFlags
} from '@services/api/salesPipeline/salesActiveCycle';

import { MODAL_TYPE } from '@constants/modalTypes';
import { EMPTY_STAGE_COLUMNS, INIT_FILTERS_DATA, INIT_FILTERS_STATE, INIT_KANBAN_SETTINGS_STATE } from '../../data';
import { convertFilterData } from '@services/store/salesCycle/utils';
import { getFilterParams, getFiltersCount, getGlobalFlagged } from '@/shared/utils/filterUtils';
import { getMassFlagsParams } from './utils';

import SalesActiveCycleKanbanNotification from '@modules/SalesPipline/SalesActiveCycleKanbanNotification';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';


export class SalesActiveCycleKanban implements CommonStore, CommonStoreForKanbanAndTable {
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  globalFlagged: boolean = false;
  filterData: ConvertedGridAndKanbanFilterDataType = INIT_FILTERS_DATA;
  filters: GridAndKanbanFiltersState = INIT_FILTERS_STATE;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  kanbanSettings: SalesCycleKanbanSettings = INIT_KANBAN_SETTINGS_STATE;
  notificationHelper: NotificationHelper;
  onFiltersChangeReaction: IReactionDisposer;
  onGlobalFlaggedChangeReaction: IReactionDisposer;
  onWebsocketNotificationChange: IReactionDisposer;
  stageColumns: SalesActiveCycleByStage = EMPTY_STAGE_COLUMNS;
  websocket: WebSocket | null = null;
  websocketNotificationCallback: (() => void) | null = null;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getKanbanSalesCycles: flow,
      getKanbanSalesCyclesWithLoad: flow.bound,
      init: flow.bound,
      onDrop: flow.bound,
      onRemove: flow.bound,
      onSave: flow.bound,
      setGlobalFlaggedFilters: action.bound,
      updateFlagsMassive: flow.bound,
      updateFlagsSingle: flow.bound,
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.salesCycle
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.isFiltersOpen = false;

    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onGlobalFlaggedChangeReaction = this.createOnGlobalFlaggedChangeReaction();
    this.onWebsocketNotificationChange = this.createOnWebsocketNotificationChange();
  }

  createOnGlobalFlaggedChangeReaction() {
    return reaction(
      () => this.globalFlagged,
      () => this.getKanbanSalesCyclesWithLoad()
    );
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive){
          this.getKanbanSalesCyclesWithLoad();
        }
      }, 1500),
    );
  }

  createOnWebsocketNotificationChange() {
    return reaction(
      () => ({
        callback: this.websocketNotificationCallback,
        loadState: this.coreStore.SalesCycleStore?.isLoad
      }),
      () => {
        if(this.isPageActive && this.websocketNotificationCallback){
          const loadState = this.coreStore.SalesCycleStore?.isLoad;
          if(!loadState && typeof loadState === 'boolean'){
            this.websocketNotificationCallback && this.websocketNotificationCallback();
            this.websocketNotificationCallback = null;
          }
        }
      }
    );
  }

  get filtersCount () {
    return getFiltersCount(this.filters);
  }

  get isAllItemsFlagged ()  {
    let result = true;
    let isColumnsEmpty = true;

    iterate: for(let key in this.stageColumns){
      const stage = this.stageColumns[key as SalesActiveCycleStageType];
      for(let index in stage){
        isColumnsEmpty = false;

        const isItemNotFlagged = stage[index].flagged === 0;
        if(isItemNotFlagged){
          result = false;
          break iterate;
        }
      }
    }

    return isColumnsEmpty ? false : result;
  }

  *updateFlagsMassive(){
    try {
      const newData = getMassFlagsParams(this.stageColumns, Number(!this.isAllItemsFlagged));
      const params = Object.values(newData).reduce((acc: Array<BookmarkDataItem>, item) => {
        acc = [...acc, ...item];
        return acc;
      }, []);

      yield updateSalesCycleFlags(params);

      Object.entries(newData).forEach(([key, value]) => {
        const stage = key as keyof SalesActiveCycleByStage;
        this.stageColumns[stage] = this.stageColumns[stage].map((item) => {
          const valueIndex = value.findIndex(paramData => paramData.id === item.id);

          if(valueIndex >= 0) {
            return {
              ...item,
              flagged: value[valueIndex].flagged
            };
          }
          return item;
        });
      });

    } catch (error) {
      console.log(error);
    }
  }

  *updateFlagsSingle(id: number, stage: SalesActiveCycleStageType){
    try {
      const column = this.stageColumns[stage];
      const index = column.findIndex(item => item.id === id);

      if(index < 0){ return; }

      const item = column[index];

      const flagged = item.flagged === 1 ? 0 : 1;

      yield updateSalesCycleFlags([{
        id: item.id,
        flagged
      }]);

      column[index] = {
        ...item,
        flagged
      };
      if(this.globalFlagged) {
        this.getKanbanSalesCyclesWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  *getKanbanSalesCycles(){
    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => {

          const response: SalesKanbanBackendResponse = await getKanban({
            ...getFilterParams(this.filters),
            ...getGlobalFlagged(this.globalFlagged),
          });
          this.stageColumns = omit(response.data.data.data, 'filterData');

          const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
          this.filterData = convertFilterData(response.data.data.data.filterData, currentUserId);
        },
        onError: () => this.notificationHelper.load({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: Math.random() * 10000
        }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getKanbanSalesCyclesWithLoad(){
    try {
      this.coreStore.SalesCycleStore.isLoad = true;
      yield this.getKanbanSalesCycles();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *init() {
    this.isPageActive = true;
    try {
      this.coreStore.SalesCycleStore.isLoad = true;

      const kanbanSettingsResp: SalesCycleKanbanSettingResponse = yield getKanbanSettings();
      this.kanbanSettings = kanbanSettingsResp.data.data;

      this.websocket = new WebSocket(this.kanbanSettings.websockets.url);

      this.websocket.onopen = this.onWebsocketOpen.bind(this);
      this.websocket.onmessage = this.onWebsocketMessage.bind(this);

      yield this.getKanbanSalesCyclesWithLoad();

    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onRemove(data: SalesCycleDeleteData) {
    try {
      this.coreStore.SalesCycleStore.isLoad = true;

      const idsToDelete = Array.isArray(data) ? data : [data];

      const countOfEntities = idsToDelete.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => (
          deleteSalesCycle({
            ids: idsToDelete
          })
        ),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities
        })
      });

      yield this.getKanbanSalesCycles();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onDrop(data: SalesKanbanDropDataType) {
    const { newStage, prevStage, id } = data;
    if(newStage === prevStage){
      return;
    }

    const draggedItemIndex = this.stageColumns[prevStage].findIndex(item => item.id === id);

    if(draggedItemIndex < 0){
      return;
    }

    const item = this.stageColumns[prevStage][draggedItemIndex];

    try {
      const newData = {
        ...item,
        stage: newStage
      };

      this.stageColumns[newStage].push(newData);
      this.stageColumns[prevStage].splice(draggedItemIndex, 1);

      const uniqueKey = data?.id || new Date().getTime();
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => (
          partialUpdateSalesCycle({
            id: newData.id,
            stage: newData.stage
          })
        ),
        onError: () => this.notificationHelper.createUpdateNotification({
          isError: true,
          isUpdate: true,
          uniqueKey
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isError: false,
          isUpdate: true,
          uniqueKey
        })
      });

    } catch (error) {
      // return if error
      this.stageColumns[newStage].pop();
      this.stageColumns[prevStage].splice(draggedItemIndex, 0, item);
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onSave(closeModal: CloseModal, data: SaveSalesActiveCyclePipelineData) {
    try {
      closeModal();
      this.coreStore.SalesCycleStore.isLoad = true;


      const uniqueKey = data?.id || new Date().getTime();
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveSalesActiveCyclePipeline(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isError: true,
          isUpdate: Boolean(data.id),
          uniqueKey
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isError: false,
          isUpdate: Boolean(data.id),
          uniqueKey
        })
      });

      yield this.getKanbanSalesCycles();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  onWebsocketOpen() {
    // this trick used for avoid to install third-party libraries
    const payload = {
      // @ts-ignore
      event: 'pusher:subscribe',
      data: {
        channel: this.kanbanSettings.websockets.channelName
      }
    };
    this.websocket?.send(JSON.stringify(payload));
  }

  onWebsocketMessage(event: MessageEvent<string>) {
    try {
      const resp: SalesCycleKanbanWebsocketType = JSON.parse(event?.data);

      if(resp?.data){
        const respData: SalesCycleKanbanWebsocketData = JSON.parse(resp?.data);
        if(respData?.message && respData.message === 'updated'){
          this.websocketNotificationCallback = () => {
            this.coreStore.ModalStore.openModal({
              modalType: MODAL_TYPE.KANBAN_IS_UPDATED,
              component: SalesActiveCycleKanbanNotification,
              modalProps: {
                action: this.getKanbanSalesCyclesWithLoad
              }
            });
          };
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  setGlobalFlaggedFilters(state: boolean) {
    this.globalFlagged = state;
  }

  setFilterState = (name: string, value: Array<string> | string) =>{
    this.filters = {
      ...this.filters,
      [name]: value
    };
  };

  resetFilters = () => {
    this.filters = INIT_FILTERS_STATE;
  };

  resetStore = () => {
    this.isPageActive = false;
    this.setGlobalFlaggedFilters(false);
    this.websocketNotificationCallback = null;
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }

    this.isFiltersOpen = false;
    this.stageColumns = EMPTY_STAGE_COLUMNS;

    this.onFiltersChangeReaction();
    this.resetFilters();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  };

  toggleFiltersIsOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };

}

import styled, { css } from 'styled-components';
import { Tab, Tabs, Orientation } from '@material-ui/core';
import { COLORS } from '@/shared/constants/colors';

import { CustomTabProps, CustomTabsProps, StyledRealWorldTabProps } from '@components/CustomTabs/types';

const getVerticalTabsStyles = (orientation: Orientation | undefined) => {
  if(orientation && orientation === 'vertical'){
    return css`
      & .MuiTab-wrapper{
        color: ${COLORS.primaryText};
        font-weight: 300;
        padding-left: 30px;
        justify-content: flex-start;
      }

      & .Mui-selected{
        background-color: ${COLORS.select};
        color: ${COLORS.orange};

        & .MuiTab-wrapper{
          color: ${COLORS.orange};
        }
      }
    `;
  }
  return '';
};

export const StyledTabs = styled(Tabs)<CustomTabsProps>`
  ${({ orientation }) => getVerticalTabsStyles(orientation)}

    & .MuiTabs-indicator{
      ${({ $hideIndicator }) => $hideIndicator && css`
        display: none;
      `}
    }

  ${({ $hideIndicator, $height }) => $hideIndicator && css`
    min-height: auto;
  `}
  
  ${({ $height }) => $height && css`
    height: ${$height};
  `}

  ${({ $overFlow }) => $overFlow && css`
    overflow: ${$overFlow};
    
      & .MuiTabs-fixed{
        overflow: ${$overFlow} !important;
      }
  `}
`;

export const StyledTab = styled(Tab)<CustomTabProps>`
  color: ${COLORS.silver};
  
  padding: 6px 20px;
  
  &:focus {
    color: ${COLORS.header};
  }
  & svg {
    fill: currentColor;

    & path{
      fill: currentColor;
    };
  }
  ${({ $minHeight }) => css`
    min-height: ${$minHeight || '64px'};
  `}
  
  ${({ $fontSize }) => $fontSize && css`
    font-size: ${$fontSize};
  `}
`;

const SLANTED_PART_SIZE_FOR_FIRST = 10;
const SLANTED_BORDER_RADIUS_FOR_FIRST = 10;
const MARGIN_FOR_FIRST_AND_LAST = SLANTED_PART_SIZE_FOR_FIRST;
const COMMON_SLANTED_PART_SIZE = 20;
const COMMON_MARGIN = 25;
const COMMON_OFFSET= 13;
const SHADOW_COLOR = 'rgba(16, 28, 91, 0.07)';
const BORDER_COLOR = COLORS.greyLight;


export const StyledRealWorldTab = styled(Tab)<StyledRealWorldTabProps>`
  min-width: unset;
  min-height: 46px;
  padding: unset;
  margin-right: ${COMMON_MARGIN}px;
  position: relative;
  overflow: visible;
  background: ${COLORS.white};
  font-size: 16px;
  font-weight: 400;
  
  ${({ $withBorders }) => 
    $withBorders 
      ? css`
              border-top: 1px solid ${BORDER_COLOR};
              box-shadow: inset 0px -4px 3px -3px ${SHADOW_COLOR};
            `
      : css`box-shadow:
                      -2px -6px 5px -5px ${SHADOW_COLOR},
                      inset 0px -4px 3px -3px ${SHADOW_COLOR};
          `
  // eslint-disable-next-line
   }
  
  ${({ $height }) => $height && css` height: ${$height};`}
  
  ${({ $zIndex }) => $zIndex && css` z-index: ${$zIndex};`}
  
  ${({ $isFirst }) => $isFirst && css` margin-left: ${MARGIN_FOR_FIRST_AND_LAST}px;`}
  
  &:before,
  &:after {
    position: absolute;
    content: '' ;
    height: 100%;
    top: ${({ $withBorders }) => $withBorders ? '-1px' : '0px' };
    background: ${COLORS.white};
  }
  
  &:before{
    ${({ $isFirst, $withBorders }) => {
    const onlyShadowStyle = css`
        box-shadow: 
                  -3px -4px 5px -3px ${SHADOW_COLOR},
                  inset 1px -4px 3px -3px ${SHADOW_COLOR};
    `;
    const bordersAndShadow = css`
      border-left: 1px solid ${BORDER_COLOR};
      border-top: 1px solid ${BORDER_COLOR};
      box-shadow: inset 1px -4px 3px -3px ${SHADOW_COLOR};
    `;

    return $isFirst
      ? css`
            left: -${MARGIN_FOR_FIRST_AND_LAST}px;
            width: ${SLANTED_PART_SIZE_FOR_FIRST + 5}px;
            border-top-left-radius: ${SLANTED_BORDER_RADIUS_FOR_FIRST}px;
            ${$withBorders ? bordersAndShadow : onlyShadowStyle};
          `
      : css`
            left: -${COMMON_OFFSET}px;
            width: ${COMMON_SLANTED_PART_SIZE}px;
            border-top-left-radius: 5px;
            transform: skew(-17deg, 0);
            ${$withBorders ? bordersAndShadow : onlyShadowStyle};
          `;
  }}
  }

  &:after {
    right: -${COMMON_OFFSET}px;
    width: ${COMMON_SLANTED_PART_SIZE}px;
    border-top-right-radius: 5px;
    transform: skew(17deg, 0);

    ${({ $withBorders }) =>
    $withBorders
      ? css`
              border-right: 1px solid ${BORDER_COLOR};
              border-top: 1px solid ${BORDER_COLOR};
              box-shadow: inset -1px -4px 3px -3px ${SHADOW_COLOR};
            `
      : css`
              box-shadow:
                        5px -4px 5px -3px ${SHADOW_COLOR},
                        inset -1px -4px 3px -3px ${SHADOW_COLOR};
             `
  // eslint-disable-next-line
    }
  }
  
  & .MuiTab-wrapper{
    padding: 0 10px;
  }

  &.Mui-selected {
    font-weight: 400;
    color: ${COLORS.header};
    
    
  ${({ $withBorders }) => !$withBorders && css`
    box-shadow: 0px -7px 5px -5px ${SHADOW_COLOR};
    
    &:after{
      box-shadow: 5px -4px 5px -3px ${SHADOW_COLOR};
    }
    
    
    &:before {
      box-shadow: -5px -4px 5px -3px ${SHADOW_COLOR};
    }
  `}

  ${({ $withBorders }) => $withBorders && css`
    box-shadow: none;
    
    &:after, &:before {
      box-shadow: none;
    }
  `}
  
    ${({ $maxZIndex }) => $maxZIndex && css`
      z-index: ${$maxZIndex};
    `}
  }
`;



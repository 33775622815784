import styled from 'styled-components';
import { COLORS } from '@constants/colors';
import { CSS_VARIABLE_NAMES } from '@constants/style';

import { HeaderProps, WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  
  width: var(${CSS_VARIABLE_NAMES.menuWidth});
  align-self: stretch;
  
  box-sizing: border-box;

  background-color: ${COLORS.menuBck};
`;

export const HeaderWrapper = styled.div<HeaderProps>`
  margin-bottom: ${({ $isOpen }) => $isOpen ? 20 : 28}px;
`;

export const SideMenuItems = styled.div``;

export const SideMenuBottomItems = styled.div`
  margin-top: auto;
`;

import { LinkedContact } from '@/shared/types/linkedContact';
import { NOTE_LINKED_CONTACT_ACTIONS } from '@modules/NotesAndHistory/data';
import { NoteItem } from '@/shared/types/note';
import { NoteLinkedContactActionValue } from './types';
import { ValueLabelObjWidthDisable } from '@/shared/types/commonTypes';
import { CONTACT_TYPES } from '@constants/common';

const getLinkedContactActionForHousehold = (notes: Array<NoteItem>, linkedContactFullData: any) => {
  const memberOfHouseHoldIds = linkedContactFullData?.householderContacts.map((item: any) => item.id) || [];
  const arrayOfAvailableIds = [ linkedContactFullData.id, ...memberOfHouseHoldIds ];

  const isSomeOfNotesLinkedToAnotherContact = notes?.some((item) => {
    const noteContactId = item.noteContactId;
    return noteContactId !== null && !arrayOfAvailableIds.includes(noteContactId);
  });

  if (isSomeOfNotesLinkedToAnotherContact) {
    return NOTE_LINKED_CONTACT_ACTIONS.reconnect;
  }

  return null;
};


export const getNoteLinkedContactAction = (
  notes: Array<NoteItem>,
  linkedContactFullData: LinkedContact | null
): NoteLinkedContactActionValue => {
  const isNotesNotExist = !notes || (notes && notes.length === 0);
  if (isNotesNotExist) {
    return null;
  }

  if (
    linkedContactFullData &&
    'householderContacts' in linkedContactFullData &&
    linkedContactFullData?.householderContacts?.length > 0
  ) {
    return getLinkedContactActionForHousehold(notes, linkedContactFullData);
  }
  // checks for individual and business;
  const isSomeOfNotesLinkedToContact = notes.some((item) => {
    return item.noteContactId !== null;
  });

  if (!linkedContactFullData && isSomeOfNotesLinkedToContact) {
    return NOTE_LINKED_CONTACT_ACTIONS.disconnect;
  }

  const isSomeOfNotesLinkedToAnotherContact = notes.some((item) => {
    const noteContactId = item.noteContactId;
    // guard for empty string
    if(typeof noteContactId === 'string' && noteContactId.length === 0){
      return false;
    }
    return noteContactId !== null && noteContactId !== linkedContactFullData?.id;
  });

  if (linkedContactFullData && isSomeOfNotesLinkedToAnotherContact) {
    return NOTE_LINKED_CONTACT_ACTIONS.reconnect;
  }

  return null;
};

export const convertContactToSelectOptions = (
  data: LinkedContact | null,
  isOptionsDisabled?: boolean
):Array<ValueLabelObjWidthDisable> => {
  if(!data) {
    return  [];
  }

  const disableState = typeof isOptionsDisabled === 'boolean' ? { disabled: isOptionsDisabled } : {};

  if(data.type === CONTACT_TYPES.BUSINESS){
    return [{
      value: data.id,
      label: data.occupationCompany,
      ...(disableState)
    }];
  }

  if(data.type === CONTACT_TYPES.INDIVIDUAL){
    return [{
      value: data.id,
      label: `${data?.firstName} ${data?.lastName}`,
      ...(disableState)
    }];
  }

  if(data.type === CONTACT_TYPES.HOUSEHOLD) {
    const onlyHousehold = {
      value: data?.id,
      label: data.householderContacts.map(item => `${item?.firstName} ${item?.lastName}`).join(' & '),
      ...(disableState)
    };

    const individuals = data.householderContacts.map(item => ({
      value: item.contactId || item.id,
      label: `${item?.firstName} ${item?.lastName}`,
      ...(disableState)
    }));

    return [onlyHousehold, ...individuals];
  }

  return  [];
};

export const getInitNoteContactIdValue = (linkedContact: LinkedContact | null) => {
  const contactType = linkedContact?.type;
  if(contactType && (contactType === CONTACT_TYPES.INDIVIDUAL || contactType === CONTACT_TYPES.BUSINESS)){
    return linkedContact.id;
  }

  return null;
};

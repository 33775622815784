import styled, { css } from 'styled-components';

import { PopupTableActionsWrapperProps } from './types';

export const Wrapper = styled.div<PopupTableActionsWrapperProps>`
  display: flex;
  width: 75px;
  justify-content: end;
  align-items: center;

  ${({ $alignSelf }) => $alignSelf && css`
    align-self: ${$alignSelf};
  `}
  
  ${({ $marginLeft }) => $marginLeft && css`
    margin-left: ${$marginLeft};
  `}

  ${({ $marginRight }) => $marginRight && css`
    margin-right: ${$marginRight};
  `}

  ${({ $marginTop }) => $marginTop && css`
    margin-top: ${$marginTop};
  `}
`;

export const SaveButtonWrapper = styled.div`
  margin-right: 3px;
  
    & > * {
      min-width: auto;
    }
`;

export const EditButtonWrapper = styled.div`
  margin-right: 15px;
`;

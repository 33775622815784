import { Edit, Trash } from '@/assets';

import { DOTS_MENU_COMMON_COLORS, DOTS_MENU_DELETE_COLORS } from '@components/DotsMenu';

import { TemplatesType } from '@components/DotsMenu/types';
import { UserCustomFieldsItemDotsMenuTypes } from './types';


export const USER_CUSTOM_FIELDS_DOTS_MENU_TYPES = {
  remove: 'REMOVE',
  edit: 'EDIT',
} as const ;

export const USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS_CONFIG: TemplatesType<UserCustomFieldsItemDotsMenuTypes> = {
  [USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.remove]: {
    type: USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.remove,
    icon: Trash,
    label: 'Delete',
    topSeparator: true,
    ...DOTS_MENU_DELETE_COLORS,
  },
  [USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.edit]: {
    icon: Edit,
    label: 'Edit',
    type: USER_CUSTOM_FIELDS_DOTS_MENU_TYPES.edit,
    ...DOTS_MENU_COMMON_COLORS,
  },
};

export const USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS = [
  USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS_CONFIG.EDIT,
  USER_CUSTOM_FIELDS_DOTS_MENU_OPTIONS_CONFIG.REMOVE
];

import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { AlertOctagon } from '@/assets';
import { List } from './components';
import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import CustomSelect from '@components/CustomSelect';
import FormBlock from '@components/FormBlock';
import TextWithTooltip from '@components/TextWithTooltip';

import { COLORS } from '@constants/colors';

import { getLastSyncDate } from './utils';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import {
  AlertIcon,
  Block,
  HelperText,
  InfoText,
  IntegrationWrapper,
  NoteWrapper,
  SignificantText,
  Wrapper,
} from './styles';

import { MailChimpProps } from './types';
import { MailChimpList } from '@/shared/types/lists';


export const MailChimp = ({
  isLoad,
  labelWidth,
  mailChimpListData,
  mailChimpLists,
  onDisconnectClick,
  onMailChimpClick,
  onSyncNowClick,
  onViewAllClick,
}: MailChimpProps) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
  const [ selectedListItem, setSelectedListItem ] = useState<MailChimpList | null>(null);

  const mailChimpListsOptions = useMemo(() => {
    if(mailChimpLists && mailChimpLists.length > 0) {
      return mailChimpLists.map(list => {
        return {
          value: list as any,
          label: list.mailchimpListName!
        };
      });  
    }
    return [];
  }, [mailChimpLists]);

  const onViewAllClickWrapper = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
    onViewAllClick();
  }, [setAnchorEl]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onClickListItem = useCallback((list: MailChimpList) => {
    setAnchorEl(null);
    setSelectedListItem(list);
  }, [setSelectedListItem, setAnchorEl]);

  const onSelectListItem = useCallback((event: ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    const { target: { value } } = event;
    setSelectedListItem(value as MailChimpList);
  }, [setSelectedListItem]);

  const onSyncNowClickWrapper = useCallback(() => {
    if(selectedListItem?.mailChimpListId) {
      onSyncNowClick(selectedListItem.mailChimpListId);
    } else if(mailChimpListData?.mailChimpListId) {
      onSyncNowClick(mailChimpListData?.mailChimpListId);
    }
  }, [onSyncNowClick, selectedListItem, mailChimpListData]);

  const onMailChimpClickWrapper = useCallback(() => {
    if(selectedListItem?.mailChimpListId) {
      onSyncNowClick(selectedListItem.mailChimpListId);
    }
  }, [onSyncNowClick, selectedListItem]);

  const lastSyncDate = useMemo(() => getLastSyncDate(mailChimpListData), [mailChimpListData]);

  const isSyncWithMailChimp = yesNoToBoolean(mailChimpListData?.syncWithMailChimp);

  return (
    <FormBlock.FormField
      $labelWidth={ labelWidth }
      $labelHeight={ 34 }
      label={ 'MailChimp Integration' }
    >
      {
        !isSyncWithMailChimp &&
        <>
          <Wrapper>
            <CustomSelect
              options={ mailChimpListsOptions }
              onChange={ onSelectListItem }
              placeholder={ 'Select Mailchimp Audience' }
              value={ selectedListItem }
            />
            <BlockButton
              colorSchema={ COLOR_SCHEME_TYPES.contained }
              onClick={ onMailChimpClickWrapper }
              disabled={ !selectedListItem }
            >
              Mailchimp
            </BlockButton>
          </Wrapper>
          <HelperText>
            Before Mailchimp Integration please select the Mailchimp Audience
          </HelperText>
        </>
      }
      {
        isSyncWithMailChimp && mailChimpListData &&
        <IntegrationWrapper>
          <Block>
            <InfoText>
              <SignificantText>CONNECTED! </SignificantText>
              This list is connected to MailChimp with the account SGL Financial
            </InfoText>
            <BlockButton onClick={ onDisconnectClick }>
              Disconnect
            </BlockButton>
          </Block>

          <Block>
            <TextWithTooltip
              $fontColor={ COLORS.primaryText }
              $lineHeight={ 30 }
              text={ selectedListItem?.mailchimpListName || mailChimpListData.mailchimpListName }
            />
            <BlockButton onClick={ onViewAllClickWrapper }>
              { anchorEl ? 'Hide All' : 'View All' }
            </BlockButton>
          </Block>

          <Block>
            <TextWithTooltip
              $fontColor={ COLORS.primaryText }
              text={ lastSyncDate }
            />
            <BlockButton onClick={ onSyncNowClickWrapper }>
              Sync Now
            </BlockButton>
          </Block>

          {
            mailChimpListData.syncWithMailChimpStatus && 
            <Block>
              <TextWithTooltip
                $fontColor={ COLORS.primaryText }
                text={ mailChimpListData.syncWithMailChimpStatus }
              />
            </Block>
          }
          
          <NoteWrapper>
            <AlertIcon>
              <AlertOctagon/>
            </AlertIcon>
            <InfoText>
              <SignificantText>NOTE: </SignificantText>
              Lists automatically sync every hour
            </InfoText>
          </NoteWrapper>
        </IntegrationWrapper>
      }
      { !isLoad && isSyncWithMailChimp && mailChimpListData &&
        <List
          anchorEl={ anchorEl }
          mailChimpLists={ mailChimpLists }
          onClickListItem={ onClickListItem }
          onClose={ onClose }
          selectedListItem={ selectedListItem }
        />
      }
    </FormBlock.FormField>
  );
};

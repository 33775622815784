import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import Table from '@components/Table';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';

import { SalesActiveTableRow } from './components';

import { COLUMNS } from './data';

import { SalesActiveCycleTableProps } from './types';

export const SalesActiveCycleTable = observer(({
  onEdit,
  onDelete,
}: SalesActiveCycleTableProps) => {
  const { isLoad } = useStore().SalesCycleStore;
  const {
    init,
    isPageActive,
    onRowChange,
    resetStore,
    table,
    updateFlagsSingle ,
  } = useStore().SalesCycleStore.SalesActiveCycleTable;

  const addNewItem = useCallback(() => onEdit(), [onEdit]);

  useEffect(() => {
    init();
    return resetStore;
  }, [ init, resetStore ]);

  return (
    <Table>
      <Table.Container triggerScrollToTop={ table.currentPage }>
        <Table.Header
          columns={ COLUMNS }
          globalCheckboxProps={ {
            checked: table.globalCheckboxState.checked,
            indeterminate: table.globalCheckboxState.indeterminate,
            onChange: table.onGlobalCheckboxClick,
          } }
          onSortChangeCb={ table.setMultipleSorting }
          sortingState={ table.multipleSorting }
          withBookmarkPlaceholder
          withGlobalCheckbox
        />
        <Table.Body>
          {
            isPageActive && !isLoad && table.items.length > 0 &&
            table.items.map(item => (
              <SalesActiveTableRow
                isChecked={ table.getItemCheckboxState(item) }
                item={ item }
                key={ item.id }
                onBookmark={ updateFlagsSingle }
                onChange={ onRowChange }
                onCheckboxClick={ table.onCheckBoxClick }
                onDelete={ onDelete }
                onDoubleClick={ onEdit }
                onEdit={ onEdit }
              />
            ))
          }
        </Table.Body>
        {
          isPageActive && !isLoad && table.items.length === 0 &&
            <ContentCenter>
              <NoDataItemsView
                addActionButtonClickHandler={ addNewItem }
                addActionButtonLabel={ 'Add Cycle' }
                withAction={ true }
              />
            </ContentCenter>
        }
      </Table.Container>
      <Table.Footer
        currentPage={ table.currentPage }
        onPageChangeCb={ table.setCurrentPage }
        pagesCount={ table.totalPages }
      >
        <Table.FooterCountInfo
          from={ table.itemsFrom }
          to={ table.itemsTo }
          total={ table.totalItems }
        />
      </Table.Footer>
    </Table>
  );
});

import { DotsMenu } from './DotsMenu';

export { getDotsMenuOptions } from './utils';

export {
  DOTS_MENU_ITEMS_TEMPLATES,
  DOTS_MENU_ITEM_TYPES,
  COMMON_COLORS as DOTS_MENU_COMMON_COLORS,
  COMMON_DELETE_COLORS as DOTS_MENU_DELETE_COLORS
} from './data';
export default DotsMenu;
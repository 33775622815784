import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import FormBlock from '@components/FormBlock';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import { PopupTable } from '@modules/AccountsAndPlansPopup/components/Content/components';

import { CommonStandingInstructionRow } from './components';

import { generateNewStandingInstructionItem } from './utils';

import { COLUMNS } from './data';

import {
  STANDING_INSTRUCTIONS_FIELDS_NAMES,
} from '@modules/AccountsAndPlansPopup/data/fieldNames';

import {
  FormFieldsValues,
  StandingInstructionsItem,
  StandingInstructionsType,
} from '@modules/AccountsAndPlansPopup/types';
import {
  RenderRow
} from '@modules/AccountsAndPlansPopup/components/Content/components/PopupTable/types';

const inputProps = {
  placeholder: 'Enter Text',
  maxLength: 500,
  rows: 6,
  style: {
    margin: '-9px -6px'
  }
};

const textFieldProps = {
  multiline: true,
};

export const CommonStandingInstructions = observer(() => {
  const store = useAccountAndPlansPopupLocalStore();
  const { isNotesPanelInAddOrEditMode } = store.notesUtilsStore;
  const { control } = useFormContext<FormFieldsValues>();

  const renderRow: RenderRow<StandingInstructionsItem> = useCallback(({
    control,
    disable,
    disableDelete,
    disableEdit,
    field,
    fieldPath,
    fields,
    getSiblingPath,
    index,
    isEdit,
    onDelete,
    onEdit,
    onRowClick,
    onSave,
    originalIndex,
    setValue,
  }) => (
    <CommonStandingInstructionRow
      control={ control }
      disable={ disable }
      disableDelete={ disableDelete }
      disableEdit={ disableEdit }
      field={ field }
      fieldPath={ fieldPath }
      fields={ fields }
      getSiblingPath={ getSiblingPath }
      index={ index }
      isEdit={ isEdit }
      key={ field.useFieldArrayCustomId }
      onDelete={ onDelete }
      onEdit={ onEdit }
      onRowClick={ onRowClick }
      onSave={ onSave }
      originalIndex={ originalIndex }
      setValue={ setValue }
    />
  ), []);

  return (
    <>
      <PopupTable<Omit<StandingInstructionsType, 'standingInstructionsNotes'>, StandingInstructionsItem>
        addButtonLabel={ 'Add Update' }
        columns={ COLUMNS }
        disable={ isNotesPanelInAddOrEditMode }
        generateNewItem={ generateNewStandingInstructionItem }
        marginBottom={ 20 }
        name={ STANDING_INSTRUCTIONS_FIELDS_NAMES.standingInstructions }
        onRowAddEditEnd={ store.resetStandingInstructionsRowState.bind(store) }
        onRowAddEditStart={ store.setAddEditStandingInstructionsRowState.bind(store) }
        renderRow={ renderRow }
        title={ 'Standing Instructions' }
      />
      <FormBlock isBorderBottom={ false }>
        <FormBlock.Header  title={ 'Standing Instruction Notes' }/>
        <FormBlock.RowWrapper>
          <HFTextInput
            inputProps={ inputProps }
            TextFieldProps={ textFieldProps }
            control={ control }
            disabled={ isNotesPanelInAddOrEditMode }
            name={ STANDING_INSTRUCTIONS_FIELDS_NAMES.standingInstructionsNotes }
          />
        </FormBlock.RowWrapper>
      </FormBlock>
    </>
  );
});

import { ColumnType } from '@components/Table/components/Header/types';

export const CONTACTS_TABLE_COLUMNS_IDS = {
  name: 'name',
  type: 'type',
  rating: 'rating',
  primaryEmail: 'primaryEmail',
  primaryPhone: 'primaryPhone',
  editAllow: 'editAllow',
};

export const COLUMNS: Array<ColumnType> = [
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.name,
    label: 'Name',
    style: {
      width: '34%'
    },
    sortable: true,
  },
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.type,
    label: 'Contact Type',
    style: {
      width: '13%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.rating,
    label: 'Contact Rank',
    style: {
      width: '14%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.primaryEmail,
    label: 'Primary Email',
    style: {
      width: '18%'
    },
    sortable: true,
    separator: true,
  },
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.primaryPhone,
    label: 'Primary Phone',
    style: {
      width: '18%'
    },
    sortable: true,
    separator: true,
  },
  // it is a stub for edit allow overlay
  {
    id: CONTACTS_TABLE_COLUMNS_IDS.editAllow,
    label: '',
    style: {
      width: '0px'
    },
  },
];

import { Roles } from '@constants/common';
import { ROUTES } from '@constants/routes';
import { UserSettingsTabType } from './data';

const ROLES_WITH_PERMISSIONS_TO_USERS_AT_ACCOUNT_SETTINGS = [
  Roles.Master,
  Roles.Admin,
  Roles.Owner
];

const ROLES_WITH_PERMISSIONS_TO_ADD_USER_POPUP = [
  Roles.Admin,
  Roles.Advisor,
  Roles.Limited,
  Roles.Standart,
];

export const getIsAbleToDeleteUser = (role: Roles) => {
  return ROLES_WITH_PERMISSIONS_TO_ADD_USER_POPUP.includes(role);
};

export const getIsAbleToSetUserPermission = (roles: Array<Roles>) => (
  roles.some((role: Roles) => ROLES_WITH_PERMISSIONS_TO_USERS_AT_ACCOUNT_SETTINGS.includes(role))
);

export const getFilteredRoleOptions = (excludeArray: Array<Roles> = []) => {
  const optionValues = Object.values(Roles);

  const filtered = optionValues.filter((value: Roles) => !excludeArray.includes(value));

  return convertArrayOfValuesToObjectValueLabel(filtered);
};

const convertArrayOfValuesToObjectValueLabel = (array: Array<Roles>) => (
  array.map((val: Roles) => (
    {
      value: val,
      label: val,
    }
  ))
);

export const userRolesOptions = convertArrayOfValuesToObjectValueLabel(ROLES_WITH_PERMISSIONS_TO_ADD_USER_POPUP);

export const getUserSettingsTabsByRoles = (roles: Array<Roles>, tabs: Array<UserSettingsTabType>) => {
  const isSomeRole = getIsAbleToSetUserPermission(roles);
  if (isSomeRole) {
    return tabs;
  }

  return tabs.filter((tab: UserSettingsTabType) => tab.link !== ROUTES.userSettingsUsers);
};

export const getRolesOptionToSetUpBasedOnUserRoles = (roles: string) => {
  if (!roles) {
    return [];
  }

  if (roles.includes(Roles.Master) || roles.includes(Roles.Owner)) {
    return convertArrayOfValuesToObjectValueLabel([
      Roles.Admin,
      Roles.Advisor,
      Roles.Standart,
      Roles.Limited,
    ]);
  }

  if (roles.includes(Roles.Admin)) {
    return convertArrayOfValuesToObjectValueLabel([
      Roles.Advisor,
      Roles.Standart,
      Roles.Limited,
    ]);
  }

  if (roles.includes(Roles.Advisor)) {
    return convertArrayOfValuesToObjectValueLabel([
      Roles.Standart,
      Roles.Limited,
    ]);
  }
};
import React, { useMemo } from 'react';

import { Controller } from 'react-hook-form';

import CustomToggle from '@components/CustomToggle';

import { CustomToggleOnChange } from '@components/CustomToggle/types';
import { HFToggleProps } from './types';

export const HFToggle = ({
  $labelMargin,
  ControllerProps,
  LabelProps,
  SwitchProps,
  control,
  disabled,
  label,
  labelForFalse,
  labelForTrue,
  labelPlacement,
  name,
  value,
  valueInConverter,
  valueOutConverter,
}: HFToggleProps) => (
  <Controller
    control={ control }
    name={ name }
    render={ ({
      field:{ value: fieldValue, onChange }
    }) => {
      const processedFieldValue = valueInConverter ? valueInConverter(fieldValue) : fieldValue;
      const onChangeWrapper: CustomToggleOnChange = (event, checked) => {
        if(valueOutConverter){
          const processValue = valueOutConverter(checked);
          onChange(processValue);
        } else {
          onChange(event);
        }
      };

      const computedLabel = labelForFalse && labelForTrue
        ? (processedFieldValue ? labelForTrue : labelForFalse)
        : label;

      return (
        <CustomToggle
          $labelMargin={ $labelMargin }
          LabelProps={ LabelProps }
          SwitchProps={ SwitchProps }
          checked={ processedFieldValue }
          disabled={ disabled }
          label={ computedLabel }
          labelPlacement={ labelPlacement }
          onChange={ onChangeWrapper }
          value={ value }
        />
      );
    } }
    { ...ControllerProps }
  />
);

import React, { useCallback } from 'react';

import Popover from '@material-ui/core/Popover';

import InfiniteVerticalScrollLoop from '@components/InfiniteVerticalScrollLoop';

import {
  Button,
  Column,
  MeridianWrapper,
  Wrapper
} from './styles';

const BUTTONS_MARGIN = 5;

export const TimePickerPopover = ({
  anchorEl,
  isOpen,
  onClickAway,
  hourOptions,
  meridianOptions,
  minutesOptions,
  meridian,
  setState,
  hours,
  minutes,
}) => {

  const onHourClick = useCallback((value) => () => {
    setState({
      hoursValue: value,
      minutesValue: minutes,
      meridianValue: meridian,
    });
  },[minutes, meridian]);

  const onMinuteClick = useCallback((value) => () => {
    setState({
      hoursValue: hours,
      minutesValue: value,
      meridianValue: meridian,
    });
  },[hours, meridian]);

  const onMeridianClick = useCallback((value) => () => {
    setState({
      hoursValue: hours,
      minutesValue: minutes,
      meridianValue: value,
    });
  },[hours, minutes]);

  return (
    <Popover
      PaperProps={ {} }
      anchorEl={ anchorEl }
      anchorOrigin={ {
        horizontal: 'left',
        vertical: 'bottom',
      } }
      onClose={ onClickAway }
      open={ isOpen }
      transformOrigin={ {
        vertical: -4,
      } }
    >
      <Wrapper>
        <Column>
          <InfiniteVerticalScrollLoop
            initScrollPositionCorrector={ BUTTONS_MARGIN }
            innerStyle={ {
              height: '219px'
            } }
          >
            {
              hourOptions.map(hourValue => (
                <Button
                  $isActive={ hourValue === hours }
                  $marginBottom={ BUTTONS_MARGIN }
                  key={ hourValue }
                  onClick={ onHourClick(hourValue) }
                >
                  {hourValue}
                </Button>
              ))
            }
          </InfiniteVerticalScrollLoop>
        </Column>
        <Column>
          <InfiniteVerticalScrollLoop
            initScrollPositionCorrector={ BUTTONS_MARGIN }
            innerStyle={ {
              height: '219px'
            } }
          >
            {
              minutesOptions.map(minuteValue => (
                <Button
                  $isActive={ minuteValue === minutes }
                  $marginBottom={ BUTTONS_MARGIN }
                  key={ minuteValue }
                  onClick={ onMinuteClick(minuteValue) }
                >
                  {minuteValue}
                </Button>
              ))
            }
          </InfiniteVerticalScrollLoop>
        </Column>
        <Column>
          <MeridianWrapper>
            {
              meridianOptions.map(meridianValue => (
                <Button
                  $isActive={ meridianValue === meridian }
                  $marginBottom={ BUTTONS_MARGIN }
                  key={ meridianValue }
                  onClick={ onMeridianClick(meridianValue) }
                >
                  {meridianValue}
                </Button>
              ))
            }

          </MeridianWrapper>
        </Column>
      </Wrapper>
    </Popover>
  );
};

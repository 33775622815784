import React, { useEffect, useRef, useState } from 'react';

import { TextWrapper, MoreBtn, Wrapper, ClickableText, ElipsisText, GradientFadeOut } from './styles';
import { TruncatedTextProps } from '@components/TruncatedText/types';

export const TruncatedText = ({
  text,
  seeMoreText = 'See More',
}: TruncatedTextProps) => {
  const textRef = useRef<null | HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if(textRef.current){
      setShowButton(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, []);

  const handleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <TextWrapper
        $isExpand={ isExpanded }
        ref={ textRef }
      >
        {text}
      </TextWrapper>
      <MoreBtn
        $isShow={ showButton }
        onClick={ handleShowMore }
      >
        {
          !isExpanded &&
          <ElipsisText>
            ...
          </ElipsisText>
        }
        {
          !isExpanded &&
          <ClickableText>
            {seeMoreText}
          </ClickableText>
        }
      </MoreBtn>
    </Wrapper>
  );
};

import { GeneralFiltersValue } from './types';

export const SEARCH_BUTTON_MODE = {
  active: 'active',
  inactive: 'inactive',
  withResult: 'withResult',
} as const;

export const ROW_FILTER_ITEMS_NAME = {
  notes: 'Notes',
  communications: 'Communications',
  tags: 'Tags'
} as const;

export const ROW_FILTER_ITEMS = [
  ROW_FILTER_ITEMS_NAME.notes,
  ROW_FILTER_ITEMS_NAME.communications
];

export const FILTER_OPTIONS = [{
  value: ROW_FILTER_ITEMS_NAME.notes, 
  label: ROW_FILTER_ITEMS_NAME.notes,
}, {
  value: ROW_FILTER_ITEMS_NAME.tags,
  label: ROW_FILTER_ITEMS_NAME.tags,
}];

export const FORM_NAMES = {
  notes: 'notes',
  communications: 'communications',
  date_from: 'date_from',
  date_to: 'date_to',
  text: 'text',
  selectFilter: 'selectFilter',
} as const;

export const FORM_DEFAULT_FIRST_LEVEL_FILTERS_VALUES = {
  [FORM_NAMES.notes]: 'All Notes',
  [FORM_NAMES.communications]: 'All Communications',
};

export const FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES = {
  [FORM_NAMES.date_from]: '',
  [FORM_NAMES.date_to]: '',
  [FORM_NAMES.text]: '',
  [FORM_NAMES.selectFilter]: ROW_FILTER_ITEMS_NAME.notes,
};

export const FORM_DEFAULT_FILTERS_VALUES: GeneralFiltersValue = {
  ...FORM_DEFAULT_FIRST_LEVEL_FILTERS_VALUES,
  ...FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES,
};

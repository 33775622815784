import { Calendar, CheckCircle, Case, Coin, List, StarInCircle, Users } from '@/assets';
import { TabDataType } from '@components/CustomTabs/types';
import { ROUTES } from '@constants/routes';


type IconType = Users | Calendar | CheckCircle | Case | List;

type TrashTab = TabDataType<{
  value: string;
  link: string;
  icon: IconType;
}>

export const TRASH_TABS: TrashTab[] = [
  {
    label: 'Contacts',
    value: ROUTES.trashContacts,
    icon: Users,
    link: ROUTES.trashContacts
  },
  {
    label: 'Lists',
    value: ROUTES.trashLists,
    icon: List,
    link: ROUTES.trashLists
  },
  {
    label: 'Activities',
    value: ROUTES.trashActivities,
    icon: Calendar,
    link: ROUTES.trashActivities
  },
  {
    label: 'Tasks',
    value: ROUTES.trashTodos,
    icon: CheckCircle,
    link: ROUTES.trashTodos
  },
  {
    label: 'Active Sales Cycles',
    value: ROUTES.trashSalesCycles,
    icon: Coin,
    link: ROUTES.trashSalesCycles
  },
  {
    label: 'Client Opportunities',
    value: ROUTES.trashClientOpportunities,
    icon: StarInCircle,
    link: ROUTES.trashClientOpportunities
  },
  {
    label: 'Accounts & Plans',
    value: ROUTES.trashAccountsAndPlans,
    icon: Case,
    link: ROUTES.trashAccountsAndPlans
  },
];

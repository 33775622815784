import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { convertSecondStringToMinutes, getTimeDataBasedOnView } from '@/modules/ActivityPopup/utils';

import { ActivitiesTable, PreTablePanel } from './components';
import { ActivityPopup } from '@modules/ActivityPopup';
import { DeleteActivityConfirmPopup } from '@modules/ActivityPopup/components';
import Surface from '@components/BasicLayoutComponents/Surface';

import { ContactDetailsActivitiesProps } from './types';
import { IdType } from '@/shared/types/commonTypes';
import { MODAL_TYPE } from '@constants/modalTypes';

export const ContactDetailsActivities = observer(({
  contactId,
}: ContactDetailsActivitiesProps) => {

  const contactDetailsActivitiesStore = useStore().ContactDetailsActivitiesStore;
  const { settings: { activityDefaultTime },  } = useStore().SettingsStore;
  const modalStore = useStore().ModalStore;
  const contactDetailsStore = useStore().ContactDetailsStore;
  const { currentContact } = contactDetailsStore;
  const { saveOrUpdateActivity, deleteActivity } = contactDetailsActivitiesStore;

  const addActivityHandler = useCallback(() => {
    modalStore.openModal({
      modalType: MODAL_TYPE.ADD_CALENDAR_ACTIVITY,
      modalProps: {
        // @ts-ignore
        initData: {
          ...getTimeDataBasedOnView({
            startDateObj: new Date(),
            duration: convertSecondStringToMinutes(activityDefaultTime),
          }),
          primaryContact: currentContact as any,
        },
        onConfirm: saveOrUpdateActivity,
        onDelete: deleteActivity
      },
      component: ActivityPopup
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ modalStore, currentContact ]);

  const deleteActivityHandler = useCallback((id: IdType, recurring: boolean) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.DELETE_ACTIVITY_CONFIRMATION,
      modalProps: {
        removeData: {
          isRecurring: recurring,
          activityData: { id }
        },
        onDelete: deleteActivity
      },
      component: DeleteActivityConfirmPopup
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ modalStore ]);

  useEffect(() => {
    contactDetailsActivitiesStore.init(contactId);
    return () => {
      contactDetailsActivitiesStore.reset();
    };
  }, [ contactDetailsActivitiesStore, contactId ]);

  return (
    <Surface>
      <Surface.Content>
        <Surface.Main>
          <PreTablePanel
            onAddActivityClick={ addActivityHandler }
          />
          <ActivitiesTable
            onAddActivityClick={ addActivityHandler }
            onDeleteActivityClick={ deleteActivityHandler }
          />
        </Surface.Main>
      </Surface.Content>
    </Surface>
  );
});

import { makeAutoObservable } from 'mobx';
import { Store } from '@store';

import {
  AllListsStore,
  ListContactsStore,
  ListCriteriaStore,
  ListSetupStore,
} from './subStores';

class ListsStore {

  AllListsStore: AllListsStore;
  FolderStore: AllListsStore;
  ListContactsStore: ListContactsStore;
  ListCriteriaStore: ListCriteriaStore;
  ListSetupStore: ListSetupStore;
  coreStore: Store;
  listName: string = '';

  constructor(coreStore: Store) {
    makeAutoObservable(this, {});

    this.AllListsStore = new AllListsStore(coreStore);
    this.FolderStore = new AllListsStore(coreStore);
    this.ListContactsStore = new ListContactsStore(coreStore);
    this.ListCriteriaStore = new ListCriteriaStore(coreStore);
    this.ListSetupStore = new ListSetupStore(coreStore);
    this.coreStore = coreStore;
  }

  setListName(name: string) {
    this.listName = name;
  }

  resetState = () => {
    this.listName = '';
  };
}

export default ListsStore;

import React, { ChangeEvent, useCallback, useMemo } from 'react';

import CheckboxDropDown from '@components/CheckboxDropDown';
import SearchField from '@components/SearchField';
import SideFilters from '@modules/SideFilters';

import { addFilterOptions } from '@/shared/utils/filterUtils';

import { CLASSIFICATION, STATUS, USERS, LIST_OWNER } from './data';
import { CREATED_BY_CHECKBOX_FILTER_MAX_HEIGHT, LISTS_TABLE_FILTER_NAMES } from '@constants/lists';

import { DropDownFilterDataType, ValueOf } from '@/shared/types/commonTypes';
import { ListsFiltersProps } from './types';

export const ListsFilters = ({
  selectedFiltersCount,
  onClearClick,
  isLoad,
  filtersState,
  filtersData,
  setFilterState,
}: ListsFiltersProps) => {

  const onNameChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFilterState(LISTS_TABLE_FILTER_NAMES.name, value || null);
  }, [setFilterState]);

  const onChangeDrop = useCallback((fieldName: string, newValue: Array<string>) => {
    setFilterState(fieldName as ValueOf<typeof LISTS_TABLE_FILTER_NAMES>, newValue);
  }, [setFilterState]);

  const usersDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      USERS,
      filtersData.officePrimaryAdvisor
    )
  ), [filtersData]);

  const listOwnerDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      LIST_OWNER,
      filtersData.listOwner
    )
  ), [filtersData]);

  const classificationDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      CLASSIFICATION,
      filtersData.classification
    )
  ), [filtersData]);

  const statusDropDownData = useMemo<DropDownFilterDataType>(() => (
    addFilterOptions<DropDownFilterDataType>(
      STATUS,
      filtersData.status
    )
  ), [filtersData]);

  return (
    <SideFilters
      count={ selectedFiltersCount }
      isNonePointerEvents={ isLoad }
      onClearClick={ onClearClick }
    >
      <SideFilters.ItemWrapperWithDelimiter>
        <SearchField
          onChange={ onNameChange }
          placeholder={ 'Search Contacts' }
          value={ filtersState?.fullName || '' }
        />
      </SideFilters.ItemWrapperWithDelimiter>
      <SideFilters.InnerWrapper>
        <CheckboxDropDown
          label={ usersDropDownData.label }
          name={ usersDropDownData.name }
          setState={ onChangeDrop }
          state={ filtersState?.officePrimaryAdvisor || [] }
          values={ usersDropDownData.values }
        />
        <CheckboxDropDown
          label={ listOwnerDropDownData.label }
          name={ listOwnerDropDownData.name }
          setState={ onChangeDrop }
          state={ filtersState?.listOwner || [] }
          values={ listOwnerDropDownData.values }
        />
        <CheckboxDropDown
          label={ classificationDropDownData.label }
          name={ classificationDropDownData.name }
          setState={ onChangeDrop }
          state={ filtersState?.classification || [] }
          values={ classificationDropDownData.values }
        />
        <CheckboxDropDown
          label={ statusDropDownData.label }
          name={ statusDropDownData.name }
          setState={ onChangeDrop }
          state={ filtersState?.status || [] }
          values={ statusDropDownData.values }
        />
      </SideFilters.InnerWrapper>
    </SideFilters>
  );
};

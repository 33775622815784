
export const ASSIGNED_TO = {
  name: 'clients',
  label: 'Assigned To',
  values: []
};

export const CLASSIFICATION =   {
  name: 'classification',
  label: 'Core Classification',
  values: [ ]
};

export const CATEGORY =   {
  name: 'type',
  label: 'Category',
  values: [ ]
};

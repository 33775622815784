
export const LEFT_COLUMN_WIDTH = 150;
export const RIGHT_COLUMN_WIDTH = 110;


export const DESCRIPTION_INPUT_PROPS = {
  placeholder: 'Enter Description Text',
  maxLength: 250,
  rows: 2,
  style: {
    margin: '-9px -6px'
  }
};

export const DESCRIPTION_TEXTFIELD_PROPS = {
  multiline: true,
};

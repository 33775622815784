import React, { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm,FormProvider } from 'react-hook-form';

import ControlledHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import FormBlock from '@components/FormBlock';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';

import { Users } from './components';

import { AddContactToListProps, FormType } from './types';

import { FIELD_NAMES, LABEL_WIDTH } from './data';

import { validationSchema } from './validationSchema';

export const AddContactToList = ({
  closeModal,
  modalProps,
}: AddContactToListProps) => {
  const { listId, listName, onSave } = modalProps;

  const methods = useForm<FormType>({
    defaultValues:{
      [FIELD_NAMES.id]: listId,
      [FIELD_NAMES.contacts]: []
    },
    resolver: yupResolver(validationSchema)
  });

  const onSaveClick = useCallback(() => {
    methods.handleSubmit((data) => {
      onSave(
        {
          id: data.id,
          contacts: data.contacts.map((contact) => contact.id)
        },
        closeModal
      );
    })();
  }, [closeModal, methods, onSave]);

  return (
    <Modal
      onClose={ closeModal }
      title={ 'Add Contacts to List' }
    >
      <Modal.Content
        padding={ '30px' }
        width={ '800px' }
      >
        <FormProvider { ...methods }>
          <ControlledHiddenInput
            control={ methods.control }
            defaultValue={ listId }
            name={ FIELD_NAMES.id }
          />
          <FormBlock
            isBorderBottom={ false }
          >
            <FormBlock.Header title={ 'Add Contacts' }/>
            <FormBlock.RowWrapper marginBottom={ '15px' }>
              <FormBlock.FormField
                $labelWidth={ LABEL_WIDTH }
                label={ 'Select List' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  {listName}
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </FormBlock.RowWrapper>
            <Users listId={ listId }/>
          </FormBlock>
        </FormProvider>
      </Modal.Content>
      <Modal.Footer>
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onSaveClick }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};

import { USER_ICON_SIZES } from './data';

export const getSubImageSize = (mainImageSize?: USER_ICON_SIZES): USER_ICON_SIZES => {

  if(mainImageSize === USER_ICON_SIZES.large){
    return USER_ICON_SIZES.small;
  }

  return USER_ICON_SIZES.exSmall;
};

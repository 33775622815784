
import { COLORS } from '@constants/colors';

export const NEXT_APPOINTMENT_COLORS = {
  none: COLORS.grey,
  exist: COLORS.yellow,
};

export const CONTACT_DETAILS_SIDE_BAR_PANEL_TAB = {
  contactDetails: 'Contact Details',
  toDos: 'To-Dos',
  notes: 'Notes',
} as const;

export const CONTACT_DETAILS_SIDE_BAR_PANEL_TABS = [
  {
    value: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails,
    label: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.contactDetails,
  },
  {
    value: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.toDos,
    label: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.toDos,
  },
  {
    value: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.notes,
    label: CONTACT_DETAILS_SIDE_BAR_PANEL_TAB.notes,
  },
];

import { CloneList, Edit, Export, Eye, MoveList, Trash, UserPlus, } from '@/assets';

import { TemplatesType } from '@components/DotsMenu/types';
import { ListsItemDotsMenuItemTypes } from './types';

import { DOTS_MENU_COMMON_COLORS, DOTS_MENU_DELETE_COLORS } from '@components/DotsMenu';
import { ListsGridItemType } from '@/shared/types/lists';
import { LISTS_TYPES } from '@constants/lists';
import { Roles } from '@constants/common';

export const LIST_ITEM_DOTS_MENU_TYPES = {
  add: 'ADD',
  clone: 'CLONE',
  remove: 'REMOVE',
  edit: 'EDIT',
  export: 'EXPORT',
  move: 'MOVE',
  view: 'VIEW',
} as const ;

const OPTIONS_CONFIG: TemplatesType<ListsItemDotsMenuItemTypes> = {
  [LIST_ITEM_DOTS_MENU_TYPES.add]: {
    icon: UserPlus,
    label: 'Add Contacts to List',
    type: LIST_ITEM_DOTS_MENU_TYPES.add,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.clone]: {
    icon: CloneList,
    label: 'Clone List',
    type: LIST_ITEM_DOTS_MENU_TYPES.clone,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.remove]: {
    type: LIST_ITEM_DOTS_MENU_TYPES.remove,
    icon: Trash,
    label: 'Delete List',
    topSeparator: true,
    ...DOTS_MENU_DELETE_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.edit]: {
    icon: Edit,
    label: 'Edit List Setup',
    type: LIST_ITEM_DOTS_MENU_TYPES.edit,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.move]: {
    icon: MoveList,
    label: 'Move to Folder',
    type: LIST_ITEM_DOTS_MENU_TYPES.move,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.view]: {
    icon: Eye,
    label: 'View Contacts in List',
    type: LIST_ITEM_DOTS_MENU_TYPES.view,
    ...DOTS_MENU_COMMON_COLORS,
  },
  [LIST_ITEM_DOTS_MENU_TYPES.export]: {
    icon: Export,
    label: 'Export List',
    type: LIST_ITEM_DOTS_MENU_TYPES.export,
    ...DOTS_MENU_COMMON_COLORS,
  }
};

const ROLES_WITH_PERMISSIONS_TO_EXPORT_LIST = [
  Roles.Admin,
  Roles.Master,
  Roles.Owner
];

export const getIsAbleToExportList = (role: Roles) => (
  ROLES_WITH_PERMISSIONS_TO_EXPORT_LIST.includes(role)
);

export const getMenuOptions = (item: ListsGridItemType, role: Roles) => {
  const isContactsExist = (item.typeList === LISTS_TYPES.Dynamic || item.typeList === LISTS_TYPES.Manual) &&
    // at least one contact exist
    item.contactData?.['0'];

  const isAbleToExportList = getIsAbleToExportList(role);

  let commonOptions = [
    OPTIONS_CONFIG.EDIT,
    OPTIONS_CONFIG.VIEW,
    ...isContactsExist && isAbleToExportList ? [OPTIONS_CONFIG.EXPORT] : [],
    OPTIONS_CONFIG.CLONE,
    OPTIONS_CONFIG.MOVE,
    OPTIONS_CONFIG.REMOVE,
  ];


  if(item.typeList === LISTS_TYPES.Dynamic){
    return commonOptions;
  } else {
    return [
      OPTIONS_CONFIG.ADD,
      ...commonOptions,
    ];
  }
};

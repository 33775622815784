import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import SaleCycleStatusOption from '@modules/SalesPipline/SaleCycleStatusOption';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import UserItem from '@components/NewUserItem';

import { SalesCyclesNormalizerItem } from '@services/store/trashStore/types';
import { TrashCellsProps } from '../types';


export const SalesCyclesCells = observer(({
  item,
}: TrashCellsProps<SalesCyclesNormalizerItem['item']>) => {

  const amountConverted = useMemo(() => formatNumberToAmount(item.amount), [item.amount]);

  return (
    <>
      <Table.Cell>
        {
          !Array.isArray(item.contactData) ?
            (
              <UserItem
                labelWidth={ 250 }
                user={ item.contactData }
                withCoreClassification={ true }
              />
            )
            : '-'
        }
      </Table.Cell>


      <Table.Cell>
        { item.salesCycleStatus &&
          <SaleCycleStatusOption
            label={ item.salesCycleStatus }
            value={ item.salesCycleStatus }
          />
        }
      </Table.Cell>
      <Table.Cell>{ item.stage || '' }</Table.Cell>

      <Table.Cell>
        <TextWithTooltip text={ amountConverted }/>
      </Table.Cell>
    </>
  );
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { COLUMNS } from '@constants/accountsAndPlanData';

import { AccountAndPlansRow } from '@modules/AccountAndPlansRow';
import { PreTablePanelWrapper } from '../../components';
import ContentCenter from '@components/ContentCenter';
import NoDataItemsView from '@modules/NoDataItemsView';
import Table, { useGlobalCheckbox, useRowCheckbox } from '@components/Table';

import { AccountAndPlanItemShort } from '@/shared/types/accountsAndPlan';
import { AccountsAndPlansTableProps } from './types';
import { IdType } from '@/shared/types/commonTypes';

export const AccountsAndPlansTable = observer(({
  onAddAccountOrPlan,
  onDeleteAccountAndPlan,
  onEditAccountOrPlan
}: AccountsAndPlansTableProps) => {

  const contactDetailsAccountAndPlanStore = useStore().ContactDetailsAccountAndPlanStore;
  const { isLoad } = useStore().ContactDetailsStore;
  const { table, isPageActive } = contactDetailsAccountAndPlanStore;

  const {
    getCheckboxState,
    onRowCheckboxClick
  } = useRowCheckbox({
    onGlobalCheckboxClickCallback: table.setSelectedIds,
    selectedIds: table.selectedIDs,
  });

  const {
    isGlobalCheckboxChecked,
    isGlobalCheckboxIndeterminate,
    onGlobalCheckboxClick
  } = useGlobalCheckbox({
    pageItems: table.items,
    selectedIds: table.selectedIDs,
    onGlobalCheckboxClickCallback: table.setSelectedIds
  });

  const onRowBookmark = useCallback((id: IdType, state: boolean) => {
    contactDetailsAccountAndPlanStore.updateFlags([{ id }], state);
  }, [ contactDetailsAccountAndPlanStore ]);

  return (
    <>
      <PreTablePanelWrapper
        onAddAccountOrPlan={ onAddAccountOrPlan }
        onDeleteAccountAndPlan={ onDeleteAccountAndPlan }
      />
      <Table>
        <Table.Container triggerScrollToTop={ table.currentPage }>
          <Table.Header
            columns={ COLUMNS }
            globalCheckboxProps={ {
              checked: isGlobalCheckboxChecked,
              indeterminate: isGlobalCheckboxIndeterminate,
              onChange: onGlobalCheckboxClick,
            } }
            onSortChangeCb={ table.setMultipleSorting }
            sortingState={ table.multipleSorting }
            withBookmarkPlaceholder
            withGlobalCheckbox
          />
          <Table.Body>
            {
              isPageActive && !isLoad && table.items.length > 0 &&
              table.items.map((item: AccountAndPlanItemShort) => (
                <AccountAndPlansRow
                  accountAndPlansItem={ item }
                  isCheckboxActive={ getCheckboxState(item) }
                  isLinkedContactClickable={ false }
                  key={ item.id }
                  onCheckboxClickCb={ onRowCheckboxClick }
                  onDeleteAction={ onDeleteAccountAndPlan }
                  onEditAction={ onEditAccountOrPlan }
                  onRowBookmarkCb={ onRowBookmark }
                />
              ))
            }
          </Table.Body>
          {
            isPageActive && !isLoad && table.items.length === 0 && (
              <ContentCenter>
                <NoDataItemsView
                  addActionButtonClickHandler={ onAddAccountOrPlan }
                  addActionButtonLabel={ 'Add Account or Plan' }
                  withAction={ true }
                />
              </ContentCenter>
            )
          }
        </Table.Container>
        <Table.Footer
          currentPage={ table.currentPage }
          onPageChangeCb={ table.setCurrentPage }
          pagesCount={ table.totalPages }
        >
          <Table.FooterCountInfo
            from={ table.itemsFrom }
            to={ table.itemsTo }
            total={ table.totalItems }
          />
        </Table.Footer>
      </Table>
    </>
  );
});

import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { ACTIVITY_COLORS, TASK_COLORS } from './data';
import { CLIENT_SPECIFIC_TASKS_PATH } from '@constants/routes/toDos';
import { ROUTES } from '@constants/routes';

import { Calendar, Task } from '@/assets';
import { InformationBlock } from '@pages/Dashboard/components';

import { Wrapper } from './styles';


export const ActivityAndTaskInfo = observer(() => {
  const { RouterStore, DashboardStore } = useStore();

  const { activityCount, taskCount } = DashboardStore;

  const onViewAllActivityClick = useCallback(() => {
    RouterStore.customPush(ROUTES.calendar);
  }, [RouterStore]);

  const onViewAllTaskClick = useCallback(() => {
    RouterStore.customPush(CLIENT_SPECIFIC_TASKS_PATH);
  }, [RouterStore]);

  return (
    <Wrapper>
      <InformationBlock
        colors={ ACTIVITY_COLORS }
        contentText={ 'Calendar Activities Today' }
        count={ activityCount }
        Icon={ Calendar }
        onViewAllButtonClick={ onViewAllActivityClick }
      />
      <InformationBlock
        colors={ TASK_COLORS }
        contentText={ 'Tasks Due Today or Overdue' }
        count={ taskCount }
        Icon={ Task }
        onViewAllButtonClick={ onViewAllTaskClick }
      />
    </Wrapper>
  );
});



import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import { TagsList } from './components';
import { ComponentHeader } from '@modules/ContactComponents';

import {
  COMMON_BUTTON_STYLES,
  COMMON_INPUT_STYLES,
  TAG_TYPES
} from './data';
import { CONTACT_TYPES } from '@constants/common';

import { TagsWrapper } from './styles';

import { TagsProps } from './types';


export const Tags = observer(({
  contactType,
  headerTitle,
}: TagsProps) => {

  const {
    general,
    interest,
  } = useStore().ContactDetailsOverview;

  return(
    <TagsWrapper>
      <ComponentHeader
        title={ headerTitle }
        isShowViewAllButton={ false }
      />
      <TagsList
        ButtonStyles={ COMMON_BUTTON_STYLES }
        InputStyles={ COMMON_INPUT_STYLES }
        store={ general }
        tagName={ 'Tags:' }
        type={ TAG_TYPES.general }
      />
      { contactType === CONTACT_TYPES.INDIVIDUAL &&
        <TagsList
          ButtonStyles={ COMMON_BUTTON_STYLES }
          InputStyles={ COMMON_INPUT_STYLES }
          store={ interest }
          tagName={ 'Interests:' }
          type={ TAG_TYPES.interest }
        />
      }
    </TagsWrapper>
  );
});

import { action, flow, makeAutoObservable } from 'mobx';

import { Store } from '@store';

import TagStore from './tagStore';

import { getTagsList } from '@services/api/tags/tags';

import { GetListTags, TagListItem,TagTypeWithoutOpportunities } from '@/shared/types/tags';
import { TAGS_TYPES } from '@constants/common';


class UserSettingsManageTagsStore {
  coreStore: Store;
  interest: TagStore;
  general: TagStore;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow.bound,
      resetState: action.bound
    });

    this.coreStore = coreStore;

    this.interest = new TagStore(
      TAGS_TYPES.interest,
      this.coreStore.UserSettingsStore.setIsFetching
    );
    this.general = new TagStore(
      TAGS_TYPES.general,
      this.coreStore.UserSettingsStore.setIsFetching
    );
  }

  *init() {
    try {
      this.coreStore.UserSettingsStore.setIsFetching(true);
      const response: GetListTags = yield getTagsList();
      const sortedResp = response.data.data.reduce((acc, item) => {
        const group = acc[item.type];
        if(group){
          group.push(item);
        }

        return acc;
      },{
        [TAGS_TYPES.interest]: [],
        [TAGS_TYPES.general]: [],
      } as Record<TagTypeWithoutOpportunities, TagListItem[]>);

      yield this.interest.setTags(sortedResp.Interest);
      yield this.general.setTags(sortedResp.General);

    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.setIsFetching(false);
    }
  }

  resetState() {
    this.interest.resetState();
    this.general.resetState();
  }
}

export default UserSettingsManageTagsStore;

import * as yup from 'yup';

import { password } from '@constants/validationPatterns';
import { validationMessages } from '@constants/validationMessages';

export const newPasswordValidation = yup.string()
  .required(validationMessages.invalidNewPassword)
  .matches(password, validationMessages.invalidNewPassword);

export const confirmPasswordValidation = ((newPasswordFormFieldName: string) => {
  return yup.string()
    .oneOf([yup.ref(newPasswordFormFieldName), null], validationMessages.invalidConfirmPassword)
    .required(validationMessages.required);
});

export const oldPasswordValidation = yup.string()
  .required(validationMessages.required);

import * as yup from 'yup';
import { differenceInMinutes, isEqual, isValid, isBefore } from 'date-fns';

import { validationMessages } from '@constants/validationMessages';
import { dateOutputValidation, twentyFourTimeFormat } from '@/shared/constants/validationPatterns';

import { getDates, isDateAfter } from '@/shared/utils/dateValidationUtils';

const isTimeBefore = (context: yup.TestContext) => {
  const { start, end } = getDates({
    endDateString: context.parent.endDate,
    endTimeString: context.parent.endTime,
    startDateString: context.parent.startDate,
    startTimeString: context.parent.startTime,
  });
  return isBefore(start, end);
};

const isSameDates = (endDateString: string | null | undefined, startDateString: string) => {
  if(!endDateString) {
    return true;
  }
  const { start, end } = getDates({
    startDateString,
    endDateString
  });

  if(isValid(start) && isValid(end)){
    return isEqual(end, start);
  }

  return true;
};

const MIN_DIFFERENCE_IN_MINUTES_BETWEEN_START_TIME_AND_END_TIME = 5;

const isActivityDurationPositive = (context: yup.TestContext): boolean => {
  const { start, end } = getDates({
    endDateString: context.parent.endDate,
    endTimeString: context.parent.endTime,
    startDateString: context.parent.startDate,
    startTimeString: context.parent.startTime,
  });

  if(isValid(start) && isValid(end)) {
    return differenceInMinutes(end, start) >= MIN_DIFFERENCE_IN_MINUTES_BETWEEN_START_TIME_AND_END_TIME;
  }

  return true;
};

export const validationSchema = yup.object().shape({
  name: yup.string().required(validationMessages.required),
  clients: yup.array().required(validationMessages.required).min(1,validationMessages.required),
  startDate: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .required(validationMessages.required),
  startTime: yup.string()
    .nullable()
    .when('allDay', {
      is: (allDay: boolean) => !allDay,
      then: (schema) => schema
        .matches(twentyFourTimeFormat, validationMessages.invalidTime)
        .required(validationMessages.required),
    }),
  endTime:yup.string()
    .nullable()
    .when('allDay', {
      is: (allDay: boolean) => !allDay,
      then: (schema) => schema
        .matches(twentyFourTimeFormat, validationMessages.invalidTime)
        .required(validationMessages.required)
        .test(
          'mast be grater than startDate',
          validationMessages.invalidTime,
          (value, context) => isTimeBefore(context)
        )
        .test(
          'minimal duration of activity need to be grater then 5 minutes',
          'must be more start time',
          (value, context) => isActivityDurationPositive(context)
        )
    }),
  endDate: yup.string()
    .nullable()
    .matches(dateOutputValidation, validationMessages.invalidDate)
    .required(validationMessages.required)
    .test(
      'mast be equal or grater than startDate',
      validationMessages.invalidDate,
      (value, context) => isDateAfter({
        dateStringToCompare: context.parent.startDate,
        dateString: value,
        withEqual: true,
      })
    )
    .when('recurringFrequency', {
      is: (recurringFrequency: string) => recurringFrequency === 'Daily',
      then: schema => schema
        .test(
          'when daily recurring frequency, startDate and endDate must be the same',
          validationMessages.invalidDate,
          (value, context) => isSameDates(value, context.parent.startDate)
        )
    }),
  recurringFrequency: yup.string()
    .nullable()
    .test(
      'if recurring true',
      validationMessages.required,
      (value, context) => {
        if(!context.parent.recurring) {
          return true;
        }

        return Boolean(value);
      }
    ),
  recurringEndDate: yup.string()
    .nullable()
    .when('recurring', {
      is: (recurring: boolean) => recurring,
      then: (schema) => schema
        .matches(dateOutputValidation, validationMessages.invalidDate)
        .required(validationMessages.required)
        .test(
          'mast be equal or grater than endDate',
          validationMessages.invalidDate,
          (value, context) => isDateAfter({
            dateStringToCompare: context.parent.endDate,
            dateString: value,
            withEqual: true,
          })
        ),
    })

});

import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { TagsStylesProps } from './types';

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 39px;
`;

export const TagTypeName = styled.div`
  flex: none;
  width: 80px;
  padding-top: 4px;
  color: ${COLORS.helpText};
`;

export const AdditionalButtonWrapper = styled.div<TagsStylesProps>`
  width: auto;
  padding-bottom: 15px;

  & svg path {
    ${({ color }) => color && `fill: ${color};` }
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > div {
    flex-wrap: wrap;
    justify-content: normal;
    align-items: normal;
  }

  & > div > div {
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  font-size: 18px;
  width: 100%;
  margin-bottom: 20px;

  & svg {
    width: 14px;
    height: 14px;

    & path {
      fill: #3A3A46;
    }
  }
`;

export const CloseIconButton = styled.div`
  margin-left: auto;
`;

export const SubTitle = styled.div`
  font-size: 10px;
  color: #838390;
  margin-bottom: 12px;
`;

export const InfoText = styled.div`
  display: inline-block;
  color: #9D9DB2;
  margin-left: 4px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: #E1E3EC;
`;

import React, { useCallback, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import { observer } from 'mobx-react';
import { useForm, useWatch } from 'react-hook-form';

import { useDidUpdate } from '@customHooks';
import { useStore } from '@store';

import { OutOfSightTD } from '@components/OutOfSight';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFToggle from '@modules/HookFormComponents/HFToggle';
import LinkedContactCell from '@modules/LinkedContactCell';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';

import { booleanToYesNo } from '@/shared/utils/booleanToYesNo';
import { getIntervalBetweenTodayAndData } from '@/shared/utils/getIntervaleBetweenTodayAndData';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { DotsMenuItemTypes } from '@components/DotsMenu/types';
import { RelatedContactRowProps } from './types';

import { ABSENT_VALUE } from './data';
import { CONTACT_DETAILS_TAB } from '@constants/routes/contacts';
import {
  FIELD_NAMES
// eslint-disable-next-line max-len
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/subtabs/components/CommonRelatedContacts/data';
import { MONTH_DAY_YEAR_BACKSLASH } from '@constants/dateFormats';
import { CONTACT_TYPES, RELATION_TYPES } from '@constants/common';
import {
  RELATION_OPTIONS,
  RELATION_OPTIONS_WITHOUT_SPOUSE,
  RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE
} from '@constants/relatedContacts';

import { TextContent } from './styles';

export const RelatedContactRow = observer(({
  data,
  onDelete,
  onEdit,
  onOutOfSightClick,
  onView,
}: RelatedContactRowProps) => {
  const { isSpouseExists, updateFromGrid } = useStore().RelatedContactsStore;
  const { currentContact } = useStore().ContactDetailsStore;

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: data
  });

  const allFields = useWatch({ control });

  const { age, formattedBirthdate }  = useMemo(() => {
    if(!data.clientContact.birthdate){
      return {
        age: ABSENT_VALUE,
        formattedBirthdate: ABSENT_VALUE
      };
    }
    const duration = getIntervalBetweenTodayAndData(data.clientContact.birthdate);
    return {
      age: duration?.years || ABSENT_VALUE,
      formattedBirthdate: format(new Date(data.clientContact.birthdate), MONTH_DAY_YEAR_BACKSLASH)
    };
  },[data.clientContact.birthdate]);

  const formattedReferralDate = useMemo(() =>{
    if(!data.referralDate){
      return ABSENT_VALUE;
    }

    return format(new Date(data.referralDate), MONTH_DAY_YEAR_BACKSLASH);
  },[data.referralDate]);

  const menuOptions = useMemo(() => (
    getDotsMenuOptions({
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.edit, DOTS_MENU_ITEM_TYPES.view, DOTS_MENU_ITEM_TYPES.unlink ],
      withTopSeparators: [ DOTS_MENU_ITEM_TYPES.unlink ]
    })
  ),[]);

  const onEditWrapper = useCallback(() => onEdit(data),[data, onEdit]);

  const onMenuSelectCb = useCallback((action: DotsMenuItemTypes) => {
    if(action === DOTS_MENU_ITEM_TYPES.unlink){
      onDelete(data);
    }
    if(action === DOTS_MENU_ITEM_TYPES.edit){
      onEditWrapper();
    }
    if(action === DOTS_MENU_ITEM_TYPES.view){
      onView(data);
    }
  },[data, onDelete, onEditWrapper, onView]);

  const relationOptions = useMemo(() => {
    if(isSpouseExists) {
      return RELATION_OPTIONS_WITHOUT_SPOUSE;
    }
    if(currentContact?.type === CONTACT_TYPES.HOUSEHOLD || data.clientContact.type !== CONTACT_TYPES.INDIVIDUAL) {
      return RELATION_OPTIONS_WITHOUT_SPOUSE_ANS_EX_SPOUSE;
    }
    return RELATION_OPTIONS;
  }, [data, currentContact, isSpouseExists]);

  const isBlocked = typeof data?.clientContact.editAllow === 'number' && !data?.clientContact.editAllow;

  useEffect(() => {
    if(Object.keys(formState.dirtyFields).length > 0) {
      handleSubmit((newData) => {
        const resetToInit = () => reset(data);
        updateFromGrid(newData, resetToInit);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, formState.dirtyFields, getValues, handleSubmit, updateFromGrid, reset]);

  useDidUpdate(() => reset(data),[data]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ onEditWrapper }
      role='checkbox'
      tabIndex={ -1 }
    >
      <LinkedContactCell
        contact={ data.clientContact }
      />
      <Table.Cell>
        <HFHiddenInput
          control={ control }
          defaultValue={ data.relativeId }
          name={ FIELD_NAMES.relativeId }
        />
        {
          data.relationType === RELATION_TYPES.SPOUSE &&
          <>
            <HFHiddenInput
              control={ control }
              defaultValue={ data.relationType }
              name={ FIELD_NAMES.relationType }
            />
            <TextContent>{data.relationType}</TextContent>
          </>
        }
        {
          data.relationType !== RELATION_TYPES.SPOUSE &&
          <HFSelect
            control={ control }
            name={ FIELD_NAMES.relationType }
            options={ relationOptions }
            showError={ false }
          />
        }
      </Table.Cell>
      <Table.Cell>
        { age }
      </Table.Cell>
      <Table.Cell>
        { formattedBirthdate }
      </Table.Cell>
      <Table.Cell>
        <HFToggle
          control={ control }
          disabled={ true }
          labelForFalse={ 'No' }
          labelForTrue={ 'Yes' }
          labelPlacement={ 'end' }
          name={ FIELD_NAMES.referral }
          valueInConverter={ yesNoToBoolean }
          valueOutConverter={ booleanToYesNo }
        />
      </Table.Cell>
      <Table.Cell>
        { formattedReferralDate }
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ data?.relativeDescription || '' } />
        <DotsMenu
          $marginLeft={ 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelectCb }
        />
      </Table.Cell>
      {
        isBlocked && <OutOfSightTD onClick={ onOutOfSightClick } />
      }
    </Table.Row>
  );
});

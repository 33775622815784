import styled, { css } from 'styled-components';
import { PopoverOrigin } from '@material-ui/core';

import { COLORS } from '@constants/colors';

import { ListItemProps } from './types';


export const transformOriginProps: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const anchorOriginProps: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const paperProps =  {
  style: {
    width: '150px',
    borderRadius: '5px',
    boxShadow: '0px 2px 10px 0px #101C5B1F',
  }
};

export const ListItem = styled.div<ListItemProps>`
  margin: 5px 0;
  padding: 5px 10px;
  line-height: 20px;
  font-size: 16px;
  cursor: pointer;

  ${({ $isActive }) => $isActive && css`
    border-left: 2px solid ${COLORS.orange};
    background-color: ${COLORS.select};
  `}
`;
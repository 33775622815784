import { validationMessages } from '@constants/validationMessages';
import {
  getSeparatedHourAndMinuteValues,
  numberToOption,
} from '@/shared/utils/timeConverter';
import {
  MERIDIAN,
  TIME_STRING_LENGTH,
  MAX_HOUR_VALUE,
  MAX_MINUTES_VALUE
} from './data';

const isTimeNotValid = (hours, minutes) => {
  if(hours > 12 || minutes > 59){
    return true;
  }

  return false;
};

export const getSeparatedPopoverValues = (timeString) => {
  const values = getSeparatedHourAndMinuteValues(timeString);

  const { hours, minutes } = values || {};
  if(!values || isTimeNotValid(hours, minutes)) {
    return {
      hours: '12',
      minutes: '00'
    };
  }

  return {
    hours,
    minutes
  };
};

export const getMeridian = (timeString) => {
  if (!timeString) {
    return null;
  }

  const [hours] = timeString.split(':');
  return Number(hours) >= 12 ? MERIDIAN.pm : MERIDIAN.am;
};

export const convertTwentyFourFormat = (timeString, meridian) => {
  const values = getSeparatedHourAndMinuteValues(timeString);

  if (!values) {
    return null;
  }

  let { hours, minutes } = values;
  let hoursInNumber = Number(hours);
  let minutesInNumber = Number(minutes);

  if (isTimeNotValid(hoursInNumber, minutesInNumber)) {
    return null;
  }

  if (meridian === MERIDIAN.pm && hoursInNumber < 12) {
    hours = numberToOption(hoursInNumber + 12);
  } else if (meridian === MERIDIAN.am && hoursInNumber === 12) {
    hours = '00';
  } else {
    hours = numberToOption(hoursInNumber);
  }

  return `${hours}:${minutes}:00`;
};


const isTimeStringInvalid = (timeString) => {
  const values = getSeparatedHourAndMinuteValues(timeString);
  let { hours, minutes } = values || {};

  if(Number(hours) > MAX_HOUR_VALUE || Number(minutes) > MAX_MINUTES_VALUE) {
    return true;
  }

  return false;
};

export const getValidator = ({ isRequired }) => (value) => {
  if(!value && isRequired) {
    return validationMessages.required;
  }

  if(value?.length && value.length < TIME_STRING_LENGTH) {
    return validationMessages.invalidTime;
  }

  if(isTimeStringInvalid(value)) {
    return validationMessages.invalidTime;
  }

  return true;
};

import styled from 'styled-components';

export const CounterWrapper = styled.div`
  margin-left: 12px;
`;

export const UserList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
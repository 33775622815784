import { action, flow, makeAutoObservable } from 'mobx';

import { Store } from '@store';

import ContactCustomField from './ContactCustomField';

import {
  BackedContactCustomFieldItem,
  ContactCustomFieldListResponse,
  ContactCustomFieldType,
  OnSaveContactCustomFieldArgs,
  SavedContactCustomFieldItem
} from '@/shared/types/userSettingsContactCustomFields';

import { CONTACT_CUSTOM_FIELDS_TYPES } from '@constants/userSettingsContactCustomFields';

import { getContactCustomFields } from '@services/api/userSettings/userSettingsContactCustomFields';

type SortAccType = Record<ContactCustomFieldType, BackedContactCustomFieldItem[]>;

class UserSettingsContactCustomFields {
  coreStore: Store;

  activityStatus: ContactCustomField;
  activitySubCategory: ContactCustomField;
  appointmentType: ContactCustomField;
  contactSource: ContactCustomField;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      init: flow.bound,
      resetState: action.bound,
      onSave: action.bound,
      onDelete: action.bound
    });

    this.coreStore = coreStore;


    this.activityStatus = new ContactCustomField(
      CONTACT_CUSTOM_FIELDS_TYPES.activityStatus,
      this.coreStore.UserSettingsStore.setIsFetching
    );
    this.activitySubCategory = new ContactCustomField(
      CONTACT_CUSTOM_FIELDS_TYPES.activitySubCategory,
      this.coreStore.UserSettingsStore.setIsFetching
    );
    this.appointmentType = new ContactCustomField(
      CONTACT_CUSTOM_FIELDS_TYPES.appointmentType,
      this.coreStore.UserSettingsStore.setIsFetching
    );
    this.contactSource = new ContactCustomField(
      CONTACT_CUSTOM_FIELDS_TYPES.contactSource,
      this.coreStore.UserSettingsStore.setIsFetching
    );
  }

  getEntityManager(type: ContactCustomFieldType){
    if(type === CONTACT_CUSTOM_FIELDS_TYPES.activityStatus){
      return this.activityStatus;
    }

    if(type === CONTACT_CUSTOM_FIELDS_TYPES.activitySubCategory){
      return this.activitySubCategory;
    }

    if(type === CONTACT_CUSTOM_FIELDS_TYPES.appointmentType){
      return this.appointmentType;
    }

    if(type === CONTACT_CUSTOM_FIELDS_TYPES.contactSource){
      return this.contactSource;
    }

    return this.activityStatus;
  }

  *init() {
    try {
      this.coreStore.UserSettingsStore.setIsFetching(true);
      const response: ContactCustomFieldListResponse = yield getContactCustomFields();

      const sorted =  response.data.data.reduce((acc: SortAccType, item) => {
        const group = acc?.[item.field];
        if(group){
          group.push(item);
        }
        return acc;
      },
      {
        [CONTACT_CUSTOM_FIELDS_TYPES.activityStatus]: [],
        [CONTACT_CUSTOM_FIELDS_TYPES.activitySubCategory]: [],
        [CONTACT_CUSTOM_FIELDS_TYPES.appointmentType]: [],
        [CONTACT_CUSTOM_FIELDS_TYPES.contactSource]: [],
      });

      Object.values(CONTACT_CUSTOM_FIELDS_TYPES).forEach(type =>{
        this.getEntityManager(type).setItems(sorted[type]);
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.setIsFetching(false);
    }
  }

  onDelete(item: SavedContactCustomFieldItem, index: number) {
    const entityManager = this.getEntityManager(item.field);
    entityManager.onDelete(item.id, index);
  }

  onSave(args: OnSaveContactCustomFieldArgs, index?: number) {
    const entityManager = this.getEntityManager(args.data.field);
    entityManager.onSave(args, index);
  }

  resetState() {
    this.activityStatus.resetState();
    this.activitySubCategory.resetState();
    this.appointmentType.resetState();
    this.contactSource.resetState();
  }
}

export default UserSettingsContactCustomFields;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';
import Preloader from '@components/Preloader';

import { ContactCustomFieldsPopupProps } from './types';
import { ContactCustomFieldAddEditItem } from '@/shared/types/userSettingsContactCustomFields';

import { COLORS } from '@constants/colors';
import { TYPES_LABEL_MAP } from './data';
import { CONTACT_CUSTOM_FIELD_NAMES } from '@constants/userSettingsContactCustomFields';
import { validationMessages } from '@constants/validationMessages';



export const ContactCustomFieldsPopup = ({
  closeModal,
  modalProps
}: ContactCustomFieldsPopupProps) => {
  const [ isLoading, setIsLoading ] = useState(false);

  const { data, index, onSave } = modalProps;
  const { field } = data;
  const isEdit = 'id' in data;

  const {
    control,
    handleSubmit,
    reset,
    setError
  } = useForm<ContactCustomFieldAddEditItem>();

  const onConfirm = () => {
    setIsLoading(true);
    const errorSetter = (message: string) => {
      setError(CONTACT_CUSTOM_FIELD_NAMES.option, {
        type: 'custom',
        message,
      });
      setIsLoading(false);
    };

    handleSubmit((data) => {
      onSave({
        data,
        errorSetter,
        closeModal
      },
      index
      );
    }, () => setIsLoading(false))();
  };

  useEffect(() => {
    if(isEdit){
      reset(data);
    }
  }, [data, isEdit, reset]);

  return (
    <Modal
      onClose={ closeModal }
      title={ `${ isEdit ? 'Edit' : 'Add'} Custom Field` }
    >
      <Modal.Content
        padding={ '30px 30px 15px 30px' }
        width={ '800px' }
      >
        <HFHiddenInput
          control={ control }
          defaultValue={ field }
          name={ CONTACT_CUSTOM_FIELD_NAMES.field }
        />
        <HFHiddenInput
          control={ control }
          defaultValue={ null }
          name={ CONTACT_CUSTOM_FIELD_NAMES.id }
        />
        <FormBlock isBorderBottom={ false }>
          <FormBlock.Header title={ `${ isEdit ? 'Edit' : 'Add'} ${TYPES_LABEL_MAP[field]}` } />
          <FormBlock.RowWrapper>
            <FormBlock.FormField
              $labelWidth={ 90 }
              isRequired={ true }
              label={ 'Type Name' }
            >
              <HFTextInput
                ControllerProps={ {
                  rules: {
                    required: validationMessages.required
                  }
                } }
                inputProps={ {
                  maxLength: 50,
                } }
                control={ control }
                name={ CONTACT_CUSTOM_FIELD_NAMES.option }
                placeholder={ 'Enter Name' }
              />
            </FormBlock.FormField>
          </FormBlock.RowWrapper>
        </FormBlock>

        {
          isLoading && <Preloader/>
        }
      </Modal.Content>
      <Modal.Footer>
        <Modal.FooterContentWrapper
          alertMessage={ isEdit ? 'All the options\' references will be edited correspondingly' : null }
          boxProps={ {
            color: COLORS.helpText,
          } }
        />
        <PopupActionsButtons
          onCancelClick={ closeModal }
          onSaveClick={ onConfirm }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};

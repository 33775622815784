import { ObjectLike } from '@/shared/types/commonTypes';
import ApiService from '@services/api/api.service';

import {
  AccountSettingsType,
  MainPasswordProperty,
} from '@services/store/userSettingsStore/types';

export const deleteUser = (params: ObjectLike<string | number>) => {
  return ApiService.delete('api/crm/client/delete', { params });
};

export const getSettings = () => {
  return ApiService.get('api/crm/client/getSettings');
};

export const getProfile = () => {
  return ApiService.get('api/crm/client/getProfile');
};

export const getListUsers = (params: ObjectLike<string | number>) => {
  return ApiService.get('api/crm/client/list', { params });
};

export const getVerifiedEmail = (params: {id: number}) => {
  return ApiService.get('api/crm/client/verified_email', { params });
};

export const saveSettings = (params: AccountSettingsType) => {
  return ApiService.patch('api/crm/client/saveSettings', {}, { params });
};

export const saveUserProfile = (params: FormData) => {
  return ApiService.post('api/crm/client/saveUserProfile', params);
};

export const saveProfile = (params: FormData) => {
  return ApiService.post('api/crm/client/saveProfile', params);
};

export const getUserById = (params: {id: number}) => {
  return ApiService.get('api/crm/client/edit', { params });
};

export const savePassword = (params: MainPasswordProperty) => {
  return ApiService.patch('api/crm/client/savePassword', {}, { params });
};

export const createSubClient = (params: FormData) => {
  return ApiService.post('api/crm/client/createSubClient', params);
};

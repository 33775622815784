import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
import MuiBox from '@material-ui/core/Box';

import { WrapperProps } from './types';

export const Wrapper = styled(MuiBox)<WrapperProps>`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  background-color: ${ COLORS.white };
  border-bottom: 1px solid ${ COLORS.dividingLines };
  
  ${({ $height }) => $height && css`
    height: ${$height};
    min-height: ${$height};
  `}
`;

export const FiltersBtnWrapper = styled.div`
    margin-left: auto;
`;

import * as yup from 'yup';

import { oldPasswordValidation } from '@modules/HookFormComponents/HFPasswordInput';

import { validationMessages } from '@constants/validationMessages';
import { FORM_FIELD_NAMES } from './data';

export const validationSchema = yup.object().shape({
  [FORM_FIELD_NAMES.email]: yup.string()
    .email(validationMessages.invalid)
    .required(validationMessages.invalidEmail),
  [FORM_FIELD_NAMES.password]: oldPasswordValidation,
});

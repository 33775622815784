import React, { MouseEventHandler, useCallback } from 'react';

import Tooltip from '@components/Tooltip';

import { TagButtonInputWithControls } from './components';

import { TAG_BUTTON_VARIANTS, TAG_BUTTON_TYPES } from './data';

import { ViewContentWrapper, Wrapper, OuterWrapper, ErrorMessageWrapper } from './styles';

import { TagButtonProps, TagData } from './types';
import { Simulate } from 'react-dom/test-utils';
import error = Simulate.error;

export const TagButton = <T extends TagData>({
  $isActive,
  $isDynamicInputWidth,
  $showError = false,
  $variant = TAG_BUTTON_VARIANTS.custom,
  $viewContentMaxWidth,
  content,
  disabled,
  errorMessage,
  initEditState,
  inputStyle,
  isNotEditable = false,
  maxLength = 15,
  onClickCb,
  onDeleteCb,
  onEditEnd,
  onEditStart,
  onSaveCb,
  style,
  tagData,
  type,
  validator,
}: TagButtonProps<T>) => {

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    onClickCb && onClickCb(e, {
      tagData,
      content
    });
  }, [content, onClickCb, tagData]);


  if(type === TAG_BUTTON_TYPES.edit) {
    return (
      <OuterWrapper $showError={ $showError }>
        <Wrapper
          $disabled={ disabled }
          $isError={ Boolean(errorMessage) }
          $variant={ $variant }
          style={ style }
        >
          <TagButtonInputWithControls
            $isDynamicInputWidth={ $isDynamicInputWidth }
            initEditState={ initEditState }
            inputStyle={ inputStyle }
            isNotEditable={ isNotEditable }
            maxLength={ maxLength }
            onDeleteCb={ onDeleteCb }
            onEditEnd={ onEditEnd }
            onEditStart={ onEditStart }
            onSaveCb={ onSaveCb }
            tagData={ tagData }
            validator={ validator }
            withCount={ 'count' in tagData }
          />
        </Wrapper>
        {
          errorMessage &&
          <ErrorMessageWrapper>
            { errorMessage }
          </ErrorMessageWrapper>
        }
      </OuterWrapper>
    );
  }

  if(type === TAG_BUTTON_TYPES.view) {
    return (
      <Wrapper
        $variant={ $variant }
        style={ style }
      >
        {
          $viewContentMaxWidth
            ? (
              <Tooltip displayOnlyOnOverflow title={ content } >
                <ViewContentWrapper $viewContentMaxWidth={ $viewContentMaxWidth }>
                  { content }
                </ViewContentWrapper>
              </Tooltip>
            )
            : content
        }
      </Wrapper>
    );
  }

  if(type === TAG_BUTTON_TYPES.button) {
    return (
      <Wrapper
        $isActive={ $isActive }
        $isClickable={ true }
        $variant={ $variant }
        $disabled={ disabled }
        onClick={ onClick }
        style={ style }
      >
        {
          $viewContentMaxWidth
            ? (
              <Tooltip displayOnlyOnOverflow title={ content } >
                <ViewContentWrapper $viewContentMaxWidth={ $viewContentMaxWidth }>
                  { content }
                </ViewContentWrapper>
              </Tooltip>
            )
            : content
        }
      </Wrapper>
    );
  }

  return null;
};

import ApiService from '@services/api/api.service';

export const getFilterOptions = () => {
  return ApiService.get('/api/setting/activity/getCalendarSettings');
};

export const getActivitiesList = (params) => {
  return ApiService.get('/api/activity/gridList', { params });
};

export const getCalendarList = (params) => {
  return ApiService.get('/api/activity/getCalendarList', { params });
};

export const updateActivityFlags = (data) => {
  return ApiService.post('/api/activity/updateFlags', data);
};

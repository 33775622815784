import React from 'react';
import { observer } from 'mobx-react';

import BlockButton from '@components/BlockButton';

import { Content, ContentTextWrapper, CountWrapper, Divider, Header, IconWrapper, Wrapper } from './styles';

import { InformationBlockProps } from './types';

const BLOCK_BUTTON_STYLES = {
  minWidth: 50,
  height: '25px',
  padding: '5px',
  marginLeft: 'auto'
};

export const InformationBlock = observer(({
  colors,
  contentText,
  count,
  Icon,
  onViewAllButtonClick
}: InformationBlockProps) => {

  return (
    <Wrapper>
      <Header>
        <IconWrapper onClick={ onViewAllButtonClick } colors={ colors }>
          <Icon/>
        </IconWrapper>
        <BlockButton
          $isNoneFill={ true }
          onClick={ onViewAllButtonClick }
          style={ BLOCK_BUTTON_STYLES }
        >
          View All
        </BlockButton>
      </Header>
      <Content>
        <CountWrapper>{ count }</CountWrapper>
        <Divider colors={ colors }/>
        <ContentTextWrapper>{ contentText }</ContentTextWrapper>
      </Content>
    </Wrapper>
  );
});

import { getSortedUserOption } from '@/shared/utils/filterUtils';
import { optionsFromValue } from '@/shared/utils/optionsFromValue';
import { TODOS_FILTER_NAMES } from '@constants/todosData';

import { IdType } from '@/shared/types/commonTypes';
import { TodoData, FilterData, FilterDataResponse } from './types';
import { TodoItem } from '@/shared/types/todos';

export const normalizer = (
  response: TodoData,
  currentUserId: IdType,
): {
  filterData: FilterData,
  items: Array<TodoItem>,
} => {
  const { filterData, ...todosItems } = response;

  const items = Object.values(todosItems) as Array<TodoItem>;

  return {
    filterData: filterDataConverter(filterData, currentUserId),
    items,
  };
};

const filterDataConverter = (
  filterData: FilterDataResponse, currentUserId: IdType
): FilterData => {
  if (!filterData) {
    return {} as FilterData;
  }

  return {
    [TODOS_FILTER_NAMES.users]: getSortedUserOption(filterData.clients, currentUserId),
    [TODOS_FILTER_NAMES.category]: optionsFromValue(filterData.category),
    [TODOS_FILTER_NAMES.stage]: filterData.stage,
  };
};

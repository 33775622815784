import { Edit } from '@/assets';

import { TemplatesType } from '@components/DotsMenu/types';
import { NotesTaskAction } from './types';

import { DOTS_MENU_COMMON_COLORS } from '@components/DotsMenu';

export const NOTES_TASK_DOTS_MENU_TYPES = {
  viewEdit: 'viewEdit',
} as const ;

export const NOTES_TASK_DOTS_MENU_CONFIG : TemplatesType<NotesTaskAction> = {
  [NOTES_TASK_DOTS_MENU_TYPES.viewEdit]: {
    type: NOTES_TASK_DOTS_MENU_TYPES.viewEdit,
    icon: Edit,
    label: 'View/Edit',
    ...DOTS_MENU_COMMON_COLORS,
  }
};

export const NOTES_TASK_DOTS_MENU_OPTIONS = Object.values(NOTES_TASK_DOTS_MENU_CONFIG);

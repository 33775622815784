import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  background-color: ${ COLORS.background };
`;

export const LeftColumn = styled.div`
  width: 200px;
  padding-top: 13px;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};

  & .MuiTab-wrapper{
    padding-left: 10px;
  }
`;

export const MiddleColumn = styled.div`
  width: 791px;
  min-width: 791px;
  height: 620px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-right: 8px;
  padding: 20px 20px 20px 20px;
  background-color: ${COLORS.white};
  border-top: 1px solid ${COLORS.greyLight};
  border-right: 1px solid ${COLORS.greyLight};

  @media screen and (min-height: 1080px) {
    height: 764px;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  width: 500px;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
   display: flex;
   flex-grow: 1;
`;

export const LeftContentWrapper = styled.div`
    width: 130px;
    height: 130px;
    margin-right: 20px;
`;

export const MiddleContentWrapper = styled.div`
    flex-grow: 1;

    & .MuiInputBase-root {
        padding: 7px 10px 7px;
    }
`;

export const RightContentWrapper = styled.div`
    display: flex;
    width: 170px;
    margin-left: 20px;
    margin-bottom: 16px;
`;

export const NameWrapper = styled.div`
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
`;

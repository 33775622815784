import styled, { css } from 'styled-components';

import { COLORS } from '@constants/colors';
import { BOX_SHADOW } from '@constants/style';

import { ItemWrapperProps, TabbContentWrapperInnerProps } from './types';

export const Wrapper = styled.form`
    display: flex;
    position: relative;
    height: calc(100vh - 208px);
    padding-bottom: 0px;
    overflow-y: auto;
`;

export const InnerWrapper = styled.div<TabbContentWrapperInnerProps>`
    min-width: 895px;
    ${ props => props.isFluid && css`flex-grow: 1`};
`;

export const Item = styled.div<ItemWrapperProps>`
    ${ ({ $withPadding }) => $withPadding && css`padding: 20px`};
    ${ ({ $noBottomPadding }) => $noBottomPadding && css`padding-bottom: 0`};
    border-radius: 0 5px 5px;
    background-color: ${ COLORS.white };
    box-shadow: ${ BOX_SHADOW };
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
`;

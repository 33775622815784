import { COLORS } from '@constants/colors';

import { Close, DoneMarkWithCircle } from '@/assets';

import { hexToRgba } from '@/shared/utils/colorConverter';

import {
  QuickActionDataType,
  SalesPipelineStatusType,
  SalesPipelineStatusTypeWithoutActive
} from '@/shared/types/salesPipline';
import { TextFieldProps } from '@material-ui/core';

export const SALES_PIPELINE_BASE_STATUSES = {
  won :'Won',
  lost :'Lost',
  dead :'Dead',
  dQ :'DQ',
  active :'Active',
} as const;

export const SALES_PIPELINE_BASE_STATUSES_COLOR_MAP: Record<SalesPipelineStatusType, string> = {
  [SALES_PIPELINE_BASE_STATUSES.dQ]: COLORS.yellow,
  [SALES_PIPELINE_BASE_STATUSES.dead]: COLORS.red,
  [SALES_PIPELINE_BASE_STATUSES.lost]: COLORS.purple,
  [SALES_PIPELINE_BASE_STATUSES.won]: COLORS.green,
  [SALES_PIPELINE_BASE_STATUSES.active]: COLORS.blue,
};

export const SALES_PIPELINE_MEETING_STATUSES = {
  scheduleReschedule: 'Schedule/Reschedule',
  noShowReschedule: 'No Show Reschedule',
  shortHold: 'Short Hold',
  sendFollowUp: 'Send Follow-Up',
} as const;


export const SALES_PIPELINE_QUICK_ACTION_UI_CONF: Record<SalesPipelineStatusTypeWithoutActive, QuickActionDataType> = {
  [SALES_PIPELINE_BASE_STATUSES.won]:{
    label: SALES_PIPELINE_BASE_STATUSES.won,
    Icon: DoneMarkWithCircle,
    $color: SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.won],
    $bgHoverColor: hexToRgba(SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.won], 0.1) as string,
  },
  [SALES_PIPELINE_BASE_STATUSES.lost]:{
    label: SALES_PIPELINE_BASE_STATUSES.lost,
    Icon: Close,
    $color: SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.lost],
    $bgHoverColor: hexToRgba(SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.lost], 0.1) as string,
  },
  [SALES_PIPELINE_BASE_STATUSES.dead]:{
    label: SALES_PIPELINE_BASE_STATUSES.dead,
    Icon: Close,
    $color: SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.dead],
    $bgHoverColor: hexToRgba(SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.dead], 0.1) as string,
  },
  [SALES_PIPELINE_BASE_STATUSES.dQ]:{
    label: SALES_PIPELINE_BASE_STATUSES.dQ,
    Icon: Close,
    $color: SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.dQ],
    $bgHoverColor: hexToRgba(SALES_PIPELINE_BASE_STATUSES_COLOR_MAP[SALES_PIPELINE_BASE_STATUSES.dQ], 0.1) as string,
  },
};

export const AMOUNT_INPUT_PROP = {
  placeholder: 'Enter Amount',
  maxLength: 25,
};

export const AMOUNT_TEXT_FIELD_PROP: TextFieldProps  = {
  type: 'number',
};

export const TEXT_FIELD_KEY_NOTE_PROP: TextFieldProps = {
  multiline: true
};

export const getTextAreaProps = (rowsNumber?: number) => {
  return {
    placeholder: 'Enter Key Note/Next Step',
    maxLength: 500,
    rows: rowsNumber || 5,
    style: {
      margin: '-9px -6px'
    }
  };
};

import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import {
  CommonWrapper,
  InnerWrapper,
  OuterWrapper,
} from './styles';

export const InfiniteVerticalScrollLoop = (
  {
    surroundingBackup = 2,
    outerStyle,
    innerStyle,
    children,
    initScrollPositionCorrector = 0
  }
) => {
  const contentRef = useRef(null);
  const scrollRef = useRef(null);
  const [height, setHeight] = useState(0);

  const backupHeight = height * surroundingBackup;

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const scroll = scrollRef.current.scrollTop;
      if (scroll < backupHeight || scroll >= backupHeight + height) {
        scrollRef.current.scrollTop = backupHeight + (scroll % height);
      }
    }
  }, [height]);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.offsetHeight);
      scrollRef.current.scrollTop = contentRef.current.offsetHeight * surroundingBackup + initScrollPositionCorrector;
    }
  }, []);

  return (
    <OuterWrapper style={ outerStyle }>
      <InnerWrapper
        onScroll={ handleScroll }
        ref={ scrollRef }
        style={ {
          height,
          ...innerStyle
        } }
      >
        {
          Array(surroundingBackup).fill().map((item, id) => (
            <CommonWrapper key={ id }>{ children }</CommonWrapper>
          ))
        }

        <CommonWrapper ref={ contentRef }>{ children }</CommonWrapper>

        {
          Array(surroundingBackup).fill().map((item, id) => (
            <CommonWrapper key={ id }>{ children }</CommonWrapper>
          ))
        }
      </InnerWrapper>
    </OuterWrapper>
  );
};

import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CoreClassificationSelectOption from '@components/CoreClassificationSelectOption';
import FormBlock from '@components/FormBlock';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFRank from '@modules/HookFormComponents/HFRank';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';
import HFUserSingleSelect from '@modules/HookFormComponents/HFUserSingleSelect';

import { CONTACT_STATUSES } from '@constants/common';

import { LeftWrapper, RightWrapper } from '../../styles';

import { HeadOfHouseHoldField, SpouseTypeField } from '..';

import {
  ADD_SPOUSE_FIELD_NAMES,
  ADD_SPOUSE_TYPES,
  LEFT_LABEL_WIDTH,
  RIGHT_LABEL_WIDTH,
} from '../../data';

import { RenderItemArg } from '@components/CustomSelect/types';
import { NewContactProps } from './types';

const PRIMARY_ADVISOR_LABEL_WIDTH = {
  labelWidth: LEFT_LABEL_WIDTH
};

export const NewContact = ({
  classificationOptions,
  contactId,
  currentContact,
  currentUserId,
  existedContact,
  genderOptions,
  sourceCategoryOptions,
  sourceOptions,
  statusOptions,
}: NewContactProps) => {
  const { control } = useFormContext();

  const spouseType = useWatch({
    control,
    name: ADD_SPOUSE_FIELD_NAMES.spouseType
  });

  const renderCoreClassification = useCallback((arg: RenderItemArg) => {
    if(!Array.isArray(arg)){
      return (
        <CoreClassificationSelectOption label={ arg.label } value={ arg.value } />
      );
    }
  }, []);

  return (
    <>
      <FormBlock.Header title={ 'Create Spouse' } />
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <SpouseTypeField
            labelWidth={ LEFT_LABEL_WIDTH }
          />
        </LeftWrapper>
        <RightWrapper>
          {
            spouseType === ADD_SPOUSE_TYPES.combineHousehold &&
            <HeadOfHouseHoldField
              contactId={ contactId }
              currentContact={ currentContact }
              existedContact={ existedContact }
              labelWidth={ RIGHT_LABEL_WIDTH }
            />
          }
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            isRequired={ true }
            label={ 'First Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter First Name'
              } }
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.firstName }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            isRequired={ true }
            label={ 'Last Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Last Name'
              } }
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.lastName }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Middle Name' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Middle Name'
              } }
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.middleName }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Nickname' }
          >
            <HFTextInput
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter Nickname'
              } }
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.nickname }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      <FormBlock.RowWrapper>
        <LeftWrapper>
          <FormBlock.FormField
            $labelWidth={ LEFT_LABEL_WIDTH }
            label={ 'Birth Date' }
          >
            <HFDatePicker
              DatePickerProps={ {
                disableFuture: true
              } }
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.birthdate }
            />
          </FormBlock.FormField>
        </LeftWrapper>
        <RightWrapper>
          <FormBlock.FormField
            $labelWidth={ RIGHT_LABEL_WIDTH }
            label={ 'Gender' }
          >
            <HFSelect
              control={ control }
              name={ ADD_SPOUSE_FIELD_NAMES.gender }
              options={ genderOptions }
              resettable={ true }
            />
          </FormBlock.FormField>
        </RightWrapper>
      </FormBlock.RowWrapper>
      {
        spouseType === ADD_SPOUSE_TYPES.linkedSeparateRecord &&
        <>
          <FormBlock.RowWrapper>
            <LeftWrapper>
              <FormBlock.FormField
                $labelWidth={ LEFT_LABEL_WIDTH }
                isRequired={ true }
                label={ 'Status' }
              >
                <HFSelect
                  ControllerProps={ {
                    defaultValue: CONTACT_STATUSES.active
                  } }
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.status }
                  options={ statusOptions }
                />
              </FormBlock.FormField>
            </LeftWrapper>
            <RightWrapper>
              <FormBlock.FormField
                $labelWidth={ RIGHT_LABEL_WIDTH }
                isRequired={ true }
                label={ 'Core Classification' }
              >
                <HFSelect
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.classification }
                  options={ classificationOptions }
                  renderItem={ renderCoreClassification }
                />
              </FormBlock.FormField>
            </RightWrapper>
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper>
            <LeftWrapper>
              <FormBlock.FormField
                $labelWidth={ LEFT_LABEL_WIDTH }
                label={ 'Source' }
              >
                <HFSelect
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.sourceId }
                  options={ sourceOptions }
                  resettable={ true }
                />
              </FormBlock.FormField>
            </LeftWrapper>
            <RightWrapper>
              <FormBlock.FormField
                $labelWidth={ RIGHT_LABEL_WIDTH }
                label={ 'Source Category' }
              >
                <HFSelect
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.sourceCategory }
                  options={ sourceCategoryOptions }
                  resettable={ true }
                />
              </FormBlock.FormField>
            </RightWrapper>
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper>
            <LeftWrapper>
              <FormBlock.FormField
                $labelWidth={ LEFT_LABEL_WIDTH }
                isRequired={ true }
                label={ 'Primary Advisor' }
              >
                <HFUserSingleSelect
                  ControllerProps={ {
                    defaultValue: currentUserId
                  } }
                  UserItemProps={ PRIMARY_ADVISOR_LABEL_WIDTH }
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.officePrimaryAdvisor }
                />
              </FormBlock.FormField>
            </LeftWrapper>
            <RightWrapper>
              <FormBlock.FormField
                $labelWidth={ RIGHT_LABEL_WIDTH }
                label={ 'Rank' }
              >
                <HFRank
                  control={ control }
                  name={ ADD_SPOUSE_FIELD_NAMES.rating }
                />
              </FormBlock.FormField>
            </RightWrapper>
          </FormBlock.RowWrapper>
        </>
      }
    </>
  );
};

import React from 'react';

import isSameDay from 'date-fns/isSameDay';

import {
  LabelWrapper,
  Wrapper
} from './styles';

export const MonthDateHeader = ({
  date,
  isOffRange,
  label,
}) => {
  const isToday = isSameDay(new Date(), new Date(date));

  return (
    <Wrapper>
      <LabelWrapper
        $active={ isToday }
        $isOffRange={ isOffRange }
      >
        {label}
      </LabelWrapper>
    </Wrapper>
  );
};

import React from 'react';

import { EyeSash } from '@/assets';

import { Item, ItemIcon, ItemText, Wrapper } from './styles';

export const OutOfSight = () => (
  <Wrapper>
    <Item>
      <ItemIcon>
        <EyeSash />
      </ItemIcon>
      <ItemText>
        {'Out of sight'}
      </ItemText>
    </Item>
  </Wrapper>
);

import React, { ChangeEvent, useCallback } from 'react';

import { useAccountAndPlansPopupLocalStore } from '@modules/AccountsAndPlansPopup/store';

import CustomTabs from '@components/CustomTabs';

import { ContentWrapperWithTabsProps } from './types';

import { LeftColumn, MiddleColumn, RightColumn, Wrapper } from './styles';

export const ContentWrapperWithTabs = ({
  currentTab,
  onTabChange,
  renderMiddleColumnContent,
  renderRightColumnContent,
  tabs,
}: ContentWrapperWithTabsProps) => {
  const accountsAndPlansLocalStore = useAccountAndPlansPopupLocalStore();

  const handleChange = useCallback((e: ChangeEvent<{}>, value: any) => {
    onTabChange(value);
  }, [onTabChange]);

  return (
    <Wrapper>
      <LeftColumn>
        <CustomTabs
          onChange={ handleChange }
          orientation={ 'vertical' }
          value={ currentTab }
        >
          {
            tabs.map(({ value, label }) => (
              <CustomTabs.Tab
                $minHeight={ '40px' }
                key={ value }
                label={ label }
                value={ value }
              />
            ))
          }
        </CustomTabs>
      </LeftColumn>
      <MiddleColumn ref={ accountsAndPlansLocalStore.setMiddleColumnRef.bind(accountsAndPlansLocalStore) }>
        {renderMiddleColumnContent()}
      </MiddleColumn>
      <RightColumn>
        {renderRightColumnContent()}
      </RightColumn>
    </Wrapper>);
};

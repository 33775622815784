import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { KeyboardDatePickerOwnProps } from '@components/CustomDatePicker/types';

export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)<KeyboardDatePickerOwnProps>`
  &.MuiInputBase-root{
    width: 100%;
  }
`;

export const useStyles = makeStyles((theme)=>({
  paperRoot: {
    '& .MuiPickersBasePicker-pickerView': {
      minHeight: '290px',
      overflow: 'hidden',
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: '196px',
      marginTop: 0,
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      padding: '5px',

      '&:first-child': {
        marginLeft: '8px'
      },

      '&:last-child': {
        marginRight: '5px'
      },

      '&:hover':{
        // @ts-ignore
        backgroundColor: theme.palette.custom.selectedMenuItem
      }
    }
  }
}));

import React from 'react';

import { formatNumberToAmount } from '@/shared/utils/formatNumberToAmount';

import { LINKED_ITEM_TYPES } from '@constants/linkedItem';

import SalesClientOpportunitiesStatusOption from '@modules/SalesPipline/SalesClientOpportunitiesStatusOption';
import TextWithTooltip from '@components/TextWithTooltip';

import { LabelWrapper, WrapperWithLabel } from './styles';

import { EntityProps } from './types';
import { LinkedSalesCycle } from '@/shared/types/salesActiveCycle';


export const SalesCycle = ({ data }: EntityProps<LinkedSalesCycle>) => {

  const amountConverted = data?.linkedContactType === LINKED_ITEM_TYPES.salesCycle && data.amount ?
    formatNumberToAmount(data.amount) : '-';
  
  return (
    <>
      <WrapperWithLabel>
        <LabelWrapper>Stage</LabelWrapper>
        <TextWithTooltip text={ data.stage || '-' }/>
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Status</LabelWrapper>
        { data.salesCycleStatus ?
          <SalesClientOpportunitiesStatusOption
            label={ data.salesCycleStatus }
            value={ data.salesCycleStatus }
          /> : '-'
        }
      </WrapperWithLabel>

      <WrapperWithLabel>
        <LabelWrapper>Amount</LabelWrapper>
        <TextWithTooltip text={ amountConverted }/>
      </WrapperWithLabel>
    </>
  );
};

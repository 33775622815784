import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    color: ${ COLORS.header };
`;

export const Header = styled.div`
    display: flex;
    padding: 10px 0;
    box-sizing: border-box;
    border-bottom: 1px solid ${ COLORS.dividingLines };
`;

export const ClickArea = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    margin-right: 10px;

    & > svg {
        align-self: center;
    }
`;

export const CounterWrapper = styled.div`
    margin-left: 8px;
`;

export const CheckboxWrapper = styled.div`
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
`;

import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';

import FetchAutoComplete from '@modules/FetchAutoComplete';
import FormBlock from '@components/FormBlock';
import SelectItemWithCheckbox from '@components/SelectItemWithCheckbox';
import UserItem from '@components/NewUserItem';

import { getContactByNameForRelationWithoutContactId } from '@services/api/relatedContacts/relatedContacts';
import { RELATION_TYPES } from '@constants/common';

import { getNameParam, usersResponseConverter } from '@/shared/utils/fetchAutocompleteUtils';

import { SearchBarWrapper } from './styles';

import { ContactRelatedItem } from '@/shared/types/contact';
import { SearchBarProps } from './types';


export const SearchBar = ({
  addContact,
  deleteContact,
  relatedContacts,
  spouseContact,
}: SearchBarProps) => {

  const handleChange = useCallback((relatedContact: ContactRelatedItem) => {
    const selectedIndex = relatedContacts.findIndex((item: ContactRelatedItem) => item.id === relatedContact.id);

    if(selectedIndex >= 0) {
      deleteContact(relatedContact);
    } else {
      addContact({
        ...relatedContact,
        relationType: RELATION_TYPES.OTHER
      });
    }
  }, [ addContact, relatedContacts, deleteContact ]);

  const responseConverter = useCallback((response: AxiosResponse) => {
    const usersData = usersResponseConverter(response);
    return usersData.filter((option: ContactRelatedItem) => spouseContact ? option.id !== spouseContact.id : option);
  }, [ spouseContact ]);

  return (
    <SearchBarWrapper>
      <FormBlock.FormField
        $labelWidth={ 145 }
        label={ 'Related Contacts' }
      >
        <FetchAutoComplete<ContactRelatedItem>
          AutocompleteProps={ {
            disableCloseOnSelect: true,
            getOptionLabel: (option: any) => option.fullName || 'label',
            renderOption: (option: any) => {
              const index = relatedContacts.findIndex((item: ContactRelatedItem) => item.id === option.id);
              return (
                <SelectItemWithCheckbox
                  checked={ index >= 0 }
                  onClick={ () => handleChange(option) }
                >
                  <UserItem
                    labelWidth={ 440 }
                    user={ option }
                  />
                </SelectItemWithCheckbox>
              );
            },
          } }
          FetchProps={ {
            getParams: getNameParam,
            requestSender: getContactByNameForRelationWithoutContactId,
            responseConverter
          } }
          placeholder={ 'Find family member or related contact by name' }
        />
      </FormBlock.FormField>
    </SearchBarWrapper>
  );
};

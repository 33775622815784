import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '@store';

import BlockButton from '@components/BlockButton';
import {
  ContactDetails,
  ImportantInfo,
  Rules,
  ServicingDetails,
  Source,
  Status,
  TabContentWrapper,
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/components';

import {
  COMMON_FIELDS,
  COMMON_INDIVIDUAL_AND_HOUSEHOLD_FIELDS_NAMES
} from '@pages/NewContacts/subpages/Contacts/subpages/ContactsDetails/components/ContactDetailsProfile/data/fieldNames';

import { openAddSousePopup } from '@/shared/utils/modalHelpers';
import { RELATION_TYPES } from '@constants/common';

export const IndividualIndividualDetails = observer(() => {
  const { optionsForIndividual, contact } = useStore().ContactDetailsProfileStore;
  const { id: currentUserId } = useStore().SettingsStore.profile;

  const renderContactTypeBlock = useCallback(() => {
    const isHaveSpouse = contact &&
      contact.relatedContact.find((relatedContact) => relatedContact.relationType === RELATION_TYPES.SPOUSE);
    const onClick = () => {
      openAddSousePopup(currentUserId);
    };

    return (
      <BlockButton
        disabled={ Boolean(isHaveSpouse) }
        onClick={ onClick }
        style={ {
          transform: 'translate(10px, -1px)',
        } }
      >
        Convert to Household
      </BlockButton>
    );
  }, [contact, currentUserId]);

  return (
    <TabContentWrapper>
      <TabContentWrapper.Item $noBottomPadding={ true }>
        <ImportantInfo name={ COMMON_INDIVIDUAL_AND_HOUSEHOLD_FIELDS_NAMES.importantInformation }/>
        <ContactDetails name={ COMMON_FIELDS.contacts }/>
        <Status
          classificationName={ COMMON_FIELDS.classification }
          contactType={ contact?.type }
          ratingName={ COMMON_FIELDS.rating }
          renderContactTypeBlock={ renderContactTypeBlock }
          statusName={ COMMON_FIELDS.status }
        />
        <Rules/>
        <Source
          sourceCategoryName={ COMMON_FIELDS.sourceCategory }
          sourceCategoryOptions={ optionsForIndividual?.sourceCategory || [] }
          sourceName={ COMMON_FIELDS.sourceId }
          sourceOptions={ optionsForIndividual?.source || [] }
        />
        <ServicingDetails
          clientCreationDateName={ COMMON_FIELDS.clientCreationDate }
          officeLocationName={ COMMON_FIELDS.officeLocation }
          primaryAdviserName={ COMMON_FIELDS.officePrimaryAdvisor }
          primaryCsrName={ COMMON_FIELDS.officePrimaryCsr }
          secondaryAdviserName={ COMMON_FIELDS.officeSecondaryAdvisor }
          secondaryCsrName={ COMMON_FIELDS.officeSecondaryCsr }
        />
      </TabContentWrapper.Item>
    </TabContentWrapper>
  );
});

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '@/shared/services/store/store';

import FormBlock from '@/components/FormBlock';
import HFPhoneField from '@/modules/HookFormComponents/HFPhoneField';
import HFSelect from '@/modules/HookFormComponents/HFSelect';
import HFTextInput from '@/modules/HookFormComponents/HFTextInput';

import { CityWrapper, LeftColumnWrapper, RightColumnWrapper, StateWrapper, ZipWrapper } from './styles';
import {
  FORM_BLOCK_MARGIN_BOTTOM,
  LABELS_WIDTH,
  UserProfileFormInputNames
} from '@/pages/NewUserSettings/data';


export const AdditionalUserInformation = observer(() => {
  
  const { states } = useStore().SettingsStore;

  const { control } = useFormContext();

  return (
    <FormBlock marginBottom={ FORM_BLOCK_MARGIN_BOTTOM }>
      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
            label={ 'Title' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                maxLength: 100,
                placeholder: 'Enter Title'
              } }
              name={ UserProfileFormInputNames.title }    
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabRightLabelWidth }
            label={ 'Website' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                placeholder: 'Enter Website Address'
              } }
              name={ UserProfileFormInputNames.website }       
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
            label={ 'Business Name' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                maxLength: 100,
                placeholder: 'Enter Business Name'
              } }
              name={ UserProfileFormInputNames.businessName }    
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabRightLabelWidth }
            label={ 'Phone Number' }
          >
            <HFPhoneField
              control={ control }
              countryListWidth={ 250 }
              name={ UserProfileFormInputNames.phoneNumber }
            />
          </FormBlock.FormField>
        </RightColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <LeftColumnWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
            label={ 'Fax Number ' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                maxLength: 20,
                placeholder: 'Enter Fax Number'
              } }
              name={ UserProfileFormInputNames.faxNumber }    
            />
          </FormBlock.FormField>
        </LeftColumnWrapper>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
          label={ 'Address Line 1 ' }
        >
          <HFTextInput
            control={ control }
            inputProps={ {
              maxLength: 50,
              placeholder: 'Enter Address'
            } }
            name={ UserProfileFormInputNames.addressLine }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>


      <FormBlock.RowWrapper>
        <FormBlock.FormField
          $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
          label={ 'Address Line 2 ' }
        >
          <HFTextInput
            control={ control }
            inputProps={ {
              maxLength: 50,
              placeholder: 'Enter Address'
            } }
            name={ UserProfileFormInputNames.addressLine2 }
          />
        </FormBlock.FormField>
      </FormBlock.RowWrapper>
      

      <FormBlock.RowWrapper>
        <StateWrapper>
          <FormBlock.FormField
            $labelWidth={ LABELS_WIDTH.addUserPopupUserProfileTabLeftLabelWidth }
            label={ 'State' }
          >
            <HFSelect
              MenuListProps={ {
                style: {
                  maxHeight: '265px'
                }
              } }
              control={ control }
              name={ UserProfileFormInputNames.state }
              options={ states || [] }
            />
          </FormBlock.FormField>
        </StateWrapper>
        
        <CityWrapper>
          <FormBlock.FormField
            $labelWidth={ 50 }
            label={ 'City' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                maxLength: 50,
                placeholder: 'Enter City'
              } }
              name={ UserProfileFormInputNames.city }
            />
          </FormBlock.FormField>
        </CityWrapper>

        <ZipWrapper>
          <FormBlock.FormField
            $labelWidth={ 42 }
            label={ 'ZIP' }
          >
            <HFTextInput
              control={ control }
              inputProps={ {
                maxLength: 9,
                placeholder: 'Enter Zip'
              } }
              name={ UserProfileFormInputNames.zip }
            />
          </FormBlock.FormField>
        </ZipWrapper>
      </FormBlock.RowWrapper>


    </FormBlock>
  );
});